import styled from 'styled-components';
import { Card, Input, Chip } from 'react-rainbow-components';
import { FloatingBar } from '@rainbow-modules/layout';
import LifePreserver from '../../../components/Icons/lifePreserver';
import { RED, GRAY, LIGHT_WHITE } from '../../../colors';

const getLikeButtonColor = (props) => (props.isHighlighted ? RED : GRAY);

export const Content = styled.div`
    display: flex;
    margin: 20px 0 0 0;
    width: 100%;
    box-sizing: border-box;
`;

export const CategoriesContainer = styled.div`
    max-width: 220px;
    min-width: 220px;
    padding-right: 30px;
    position: fixed;
    top: 0;
    left: 100;
    margin-top: 112px;
`;

export const RightContent = styled.div`
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 200px;
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 16px 8px 0;
`;

export const HeaderDescription = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    letter-spacing: 0.25px;
`;

export const Description = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 6px;
    line-height: 1.5;
    height: 3em;
    overflow: hidden;
`;

export const StyledInput = styled(Input)`
    width: ${(props) => (props.width ? props.width : '40%')};
    z-index: 1;

    div {
        height: 44px;
        line-height: 44px;
    }

    input {
        height: ${(props) => (props.height ? props.height : '44px')};
        line-height: 44px;
    }
`;

export const StyledChip = styled(Chip)`
    background: ${(props) => props.theme.rainbow.palette.background.highlight};
    border: 1px solid ${(props) => props.theme.rainbow.palette.background.highlight};
    margin-left: 4px;
`;

export const FunctionsContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    padding: 12px 8px;
    z-index: 0;
`;

export const StyledCard = styled(Card)`
    width: 360px;
    height: 166px;
    margin: 0;
    border: transparent;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledLink = styled.div`
    padding: 16px;
    cursor: pointer;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

export const InformationContainer = styled.div`
    display: flex;
`;

export const FunctionInfoContainer = styled.div`
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const FunctionName = styled.h3`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const FunctionAuthor = styled.h4`
    font-size: 13px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const InformationTextContainer = styled.div`
    padding-left: 20px;
    width: 95%;
`;

export const HighLightedText = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const StyledFooter = styled.footer`
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    padding: 20px 0;
    width: 100%;
`;

export const StyledLifePreserver = styled(LifePreserver)`
    color: ${(props) => props.theme.rainbow.palette.border.disabled};
    width: 68px;
    height: 68px;
    margin-right: 16px;
`;

export const FooterCard = styled.div`
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 16px;
    padding: 16px 20px;
    box-shadow: 0 0 1px 2px ${(props) => props.theme.rainbow.palette.background.highlight};
    display: flex;
    align-items: center;
`;

export const FooterQuestion = styled.h4`
    font-size: 18px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 12px;
`;

export const StatsContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: stretch;
    align-items: center;
    height: 15px;
    margin: 6px 12px 7px;
`;

export const Space = styled.span`
    flex: 1;
`;

export const LikeButton = styled.button`
    background: none;
    border: none;
    outline: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: ${(props) => getLikeButtonColor(props)};
    font-size: 12px;
    font-weight: normal;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    appearance: button;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    ::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    &:hover,
    &:active,
    &:focus {
        outline: 0;
    }

    span {
        margin-left: 3px;
    }

    svg {
        height: 12px;
    }
`;

export const StatItem = styled.div`
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    color: ${GRAY};
    font-size: 12px;
    letter-spacing: 0.25px;
    font-family: 'Lato Regular', Arial, Helvetica, sans-serif;
`;

export const CardContainer = styled.div`
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    margin: 4px 8px 8px 8px;

    :last-of-type {
        margin: 4px 8px 32px 8px;
    }
`;

export const StyledBarContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
`;

export const StyledInputContainer = styled.div`
    flex-grow: 1;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 50px 0 8px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;
export const FloatingBarTitle = styled.h1`
    font-size: 20px;
    margin-top: 6px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    margin-right: 36px;
`;

export const MainContainer = styled.div`
    padding: 32px 28px 0 28px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    background-color: ${LIGHT_WHITE};
    border-radius: 22px 0 0 0;
`;

export const MainDescription = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 0 8px;
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${LIGHT_WHITE};
    margin: 0 32px 0 100px;
    padding: 14px 0 6px 0;
`;

export const RequestLink = styled.a`
    text-decoration: none;

    :active, :hover, :visited {
        text-decoration: none;
    }
`;
