import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import { StepDescription, StyledImage } from '../styled';
import NamingFunctionImage from '../images/naming-function.png';

const NameYourFunction = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header="Name your function and select a region."
        className="rainbow-m-top_xx-large"
    >
        <StepDescription>
            The name does not necessarily have to match the
            name of the function in the Function Store that you want to install.
        </StepDescription>
        <StyledImage src={NamingFunctionImage} />
    </Step>
);

NameYourFunction.propTypes = {
    stepNumber: PropTypes.number,
};

NameYourFunction.defaultProps = {
    stepNumber: undefined,
};

export default NameYourFunction;
