import Ajv from 'ajv';

const ajv = new Ajv({
    allErrors: false,
    coerceTypes: true,
    useDefaults: true,
    strictTypes: true,
});

const isValidAccordingToSchema = (jsonValue, jsonSchema) => {
    try {
        const validate = ajv.compile(jsonSchema);
        const dataValidation = validate(jsonValue);
        return Boolean(dataValidation);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
    }
};

export default isValidAccordingToSchema;
