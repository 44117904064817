/* eslint-disable camelcase */
import { Button, RenderIf } from 'react-rainbow-components';
import { Open } from '@rainbow-modules/icons';
import Message from '../../components/Message';
import GlobalSpinner from '../../components/GlobalSpinner';
import PayoutIcon from '../../components/Icons/payout';
import useConnectedAccount from '../../data/hooks/useConnectedAccount';
import useAccountOnboardingLink from '../../data/hooks/useAccountOnboardingLink';
import useAccountLoginLink from '../../data/hooks/useAccountLoginLink';
import {
    StyledPaymentIcon, Link,
} from './styled';

export default function Payout() {
    const [account, isLoadingAccount] = useConnectedAccount();
    const { requirements: { disabled_reason } = {} } = account;
    const isVerifiedAccount = disabled_reason === null;
    const [{ url: onboardingUrl }, isLoadingOnboardingLink] = useAccountOnboardingLink({
        disabledReason: disabled_reason,
    });
    const [{ url: loginLink }, isLoadingLoginLink] = useAccountLoginLink({
        isVerifiedAccount,
    });
    const isLoading = isLoadingAccount || isLoadingOnboardingLink || isLoadingLoginLink;

    return (
        <>
            <RenderIf isTrue={isLoading}>
                <GlobalSpinner />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <RenderIf isTrue={isVerifiedAccount}>
                    <div className="rainbow-flex rainbow-m-bottom_large rainbow-m-left_small">
                        <PayoutIcon className="rainbow-m-top_small rainbow-m-right_small" />
                        <Link
                            href={loginLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View all payouts
                            <Open className="rainbow-m-left_x-small" />
                        </Link>
                    </div>
                </RenderIf>
                <RenderIf isTrue={!isVerifiedAccount}>
                    <Message
                        className="rainbow-m-left_xx-large rainbow-m-right_xx-large"
                        title="You need to set up your payment account"
                        description="To start receiving payments, we need to verify your identity. Press the button below to start the verification process."
                        icon={<StyledPaymentIcon />}
                        action={(
                            <Button
                                label="Set Up Payouts"
                                variant="brand"
                                className="rainbow-m-top_small"
                                onClick={() => window.location.assign(onboardingUrl)}
                            />
                        )}
                    />
                </RenderIf>
            </RenderIf>
        </>
    );
}
