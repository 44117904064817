import styled from 'styled-components';
import {
    ArrowRight, Function, ChevronRight, KeyFilled,
} from '@rainbow-modules/icons';
import { RecordSection } from '@rainbow-modules/record';
import { Badge } from 'react-rainbow-components';
import StepFlow from '../../../../../../../components/Icons/stepFlow';
import FxIcon from '../../../../../../../components/Icons/fxIcon';
import { WHITE, LIGHT_WHITE, DARK_WHITE } from '../../../../../../../colors';
import Message from '../../../../../../../components/Message';

export const ModalHeader = styled.h1`
    font-size: 28px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 16px;
    margin-left: 12px;
    padding: 16px 46px 0 16px;
`;

export const Bold = styled.span`
    display: inline-flex;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 0 8px;
`;

export const ModalDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 24px;
    margin-left: 12px;
    padding: 0 16px;
`;

export const CompareContainer = styled.div`
    background: ${LIGHT_WHITE};
    border-radius: 12px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 12px;
`;

export const EvironmentItem = styled.div`
    background: ${WHITE};
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    flex: 2;
`;

export const ArrowNextIcon = styled(ArrowRight)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    width: 20px;
    height: 20px;
    margin: 0 12px;
    flex-shrink: 0;

    ${(props) => props.size === 'small' && `
        width: 12px;
        height: 12px;
    `}
`;

export const ItemIcon = styled(StepFlow)`
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 8px;
    flex-shrink: 0;

    ${(props) => props.size === 'small' && `
        width: 12px;
        height: 12px;
        margin-right: 2px;
    `}
`;

export const ItemLabel = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-transform: uppercase;
    margin: 0 8px 0 0;
    font-size: 16px;

    ${(props) => props.size === 'small' && `
        font-size: 13px;
    `}
`;

export const ItemValue = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 16px;
    white-space: nowrap;

    ${(props) => props.size === 'small' && `
        font-size: 14px;
    `}
`;

export const ModalFooter = styled.footer`
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const ModalTitle = styled.h1`
    display: flex;
    align-items: flex-start;
`;

export const ChangesTitle = styled.h2`
    font-size: 18px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const ChangesHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 12px 12px 12px;
`;

export const FunctionInfoContainer = styled.div`
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const FunctionIcon = styled(FxIcon)`
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    margin-left: 2px;
`;

export const FunctionName = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 16px;
    margin: 0 0 0 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ChangesContainer = styled.div`
    background: ${WHITE};
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;
`;

export const FunctionCompareContainer = styled.li`
    background: ${LIGHT_WHITE};
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 4px;
    display: flex;
    flex-direction: column;
    border: none;
    width: 100%;

    :hover {
        cursor: pointer;
    }
`;

export const Changes = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
`;

export const StyledBadge = styled(Badge)`
    color: ${WHITE};
    margin-top: 4px;
`;

export const BadgeFunctionIcon = styled(Function)`
    width: 14px;
    height: 14px;
    color: ${WHITE};
    margin-right: 4px;
    padding-bottom: 2px;
`;

export const StyledChevronRight = styled(ChevronRight)`
    width: 12px;
    height: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 6px;
    margin-left: 4px;
    transition: transform 0.1s ease;
    transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ConfigurationContainer = styled.div`
    padding: 16px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ConfigurationHeader = styled.h4`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-top: 8px;
    margin-left: 2px;
`;

export const ConfigurationSectionHeader = styled.h5`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const ChangesContent = styled.ul`
    margin: 0 12px;
`;

export const StyledRecordSection = styled(RecordSection)`
    width: 100%;
    background: ${WHITE};
    padding: 0 16px;
    border-radius: 8px;
    margin-top: 12px;
`;

export const SecretIcon = styled(KeyFilled)`
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    background: ${DARK_WHITE};
    padding: 3px;
    border-radius: 24px;
`;

export const RowContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid ${LIGHT_WHITE};
    border-radius: 4px;
    margin: 0 12px 4px 12px;
`;

export const LoadingImage = styled.div`
    width: 26px;
    min-width: 26px;
    height: 26px;
    margin-right: 12px;
    opacity: 0.5;

    div {
        min-width: 26px;
        border-radius: 8px;
    }
`;

export const LoadingInfoContainer = styled.div`
    width: 30%;
    opacity: 0.5;

    ${(props) => props.size === 'large' && `
        width: 50%;
    `}
`;

export const StyledMessage = styled(Message)`
    width: 70%;
    margin: 0 auto;
    margin-bottom: 32px;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 16px 16px 0 16px;
`;

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;
