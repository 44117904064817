import semver from 'semver';
import Tag from '../../../components/Icons/tag';
import canPublishVersion from './canPublishVersion';
import isPublishedVersion from './isPublishedVersion';
import VersionLabel from '../workspace/functions/versionLabel';

const formatVersionsData = ({
    functionId, versions, workspace, marketplaceData, hideBadges = false, ...rest
}) => versions.map(
    (version) => {
        const publishedVersion = (marketplaceData && marketplaceData.version) || null;
        const publishedBuild = (marketplaceData && marketplaceData.buildId) || null;
        const isPublished = !hideBadges && isPublishedVersion(version.id, publishedVersion);
        const canBePublished = canPublishVersion(version.id, publishedVersion);

        return {
            label: <VersionLabel versionId={version.id} isPublished={isPublished} />,
            id: version.id,
            icon: <Tag />,
            functionId,
            type: 'version',
            isPublished,
            canBePublished,
            publishedBuild,
            workspace,
            marketplaceData,
            ...rest,
        };
    },
).sort(
    (version1, version2) => semver.compare(version2.id, version1.id),
);

export default formatVersionsData;
