import styled from 'styled-components';
import Folder from '../Icons/folder';

export const InfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    min-width: 0;
`;

export const WorkspacesName = styled.h2`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const WorkspaceUserRole = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const StyledFolderClose = styled(Folder)`
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;
