import { useCallback } from 'react';
import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import makeRequest from '../services/makeRequest';

const useCreateSubscriptionSession = () => {
    const currentUser = useCurrentUser();
    const openCreateSubscriptionSession = useCallback(async ({ priceId }) => {
        showAppSpinner();
        const idToken = await currentUser.getIdToken();
        const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/xStripe_subscriptions/subscriptions/sessions`;
        const response = await makeRequest({
            url,
            method: 'POST',
            token: idToken,
            body: {
                successUrl: window.location.href,
                cancelUrl: window.location.href,
                items: [
                    {
                        price: priceId,
                        quantity: 1,
                    },
                ],
            },
        });
        if (response.ok) {
            const { redirectTo } = await response.json();
            window.location.href = redirectTo;
        }
        hideAppSpinner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return openCreateSubscriptionSession;
};

export default useCreateSubscriptionSession;
