import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Card, Badge } from 'react-rainbow-components';
import { Lock } from '@rainbow-modules/icons';
import { FloatingBar } from '@rainbow-modules/layout';
import {
    DARK_WHITE, WHITE, GREEN, LIGHT_WHITE,
} from '../../../colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 16px 0 16px;
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
    background-color: ${LIGHT_WHITE};
    border-radius: 22px 0 0 0;
`;

export const Header = styled.header`
    padding: 12px 0 0;
    margin: 0 16px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LeftContent = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 12px;
    margin-right: 16px;
`;

export const FunctionName = styled.h2`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Description = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 16px 0 16px;
    box-sizing: border-box;
    flex: 1;
`;

export const HighLightedName = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const CardTitle = styled.h3`
    font-size: 18px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    padding: 15px;
    border-bottom: 1px dashed ${DARK_WHITE};
`;

export const CardRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px;
`;

export const AmountText = styled.p`
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const InstallationHelpText = styled.p`
    font-size: 11px;
    font-family: 'Lato Light', Arial, Helvetica, sans-serif;
`;

export const StyledLink = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const TermsLink = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const PricingCard = styled(Card)`
    width: 40%;
    margin-top: 20px;
`;

export const TabContent = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const FreeBadge = styled(Badge)`
    color: ${WHITE};
    background: ${GREEN};
`;

export const BoughtBadge = styled(Badge)`
    color: ${WHITE};
    background: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const SourceCodeContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    height: 100%;
    padding: 12px 0;
    overflow: hidden;
    justify-content: center;
`;

export const LeftColumn = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 340px;
    border-right: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const MessageContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    height: 100%;
    padding: 12px 0;
    overflow: hidden;
    justify-content: center;
`;

export const LockIcon = styled(Lock)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 12px;
    width: 100px;
    height: 100px;
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${LIGHT_WHITE};
    margin: 0 30px 0 100px;
    padding: 14px 0 0;
`;

export const ZipContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    height: 100%;
    padding: 12px 0;
    overflow: hidden;
    justify-content: center;
`;
