import PropTypes from 'prop-types';
import { MessageTitle, MessageDescription } from './styled';

const CheckingMessage = ({ projectId }) => (
    <>
        <MessageTitle>{`Checking “${projectId}” project`}</MessageTitle>
        <MessageDescription>
            We are verifying the necessary permissions and requirements in
            your project. The process should take some minutes.
        </MessageDescription>
    </>
);

CheckingMessage.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default CheckingMessage;
