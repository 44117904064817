import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import FunctionIcon from '../FunctionIcon';
import getFunctionType from '../../helpers/getFunctionType';
import {
    ModalInfoContainer,
    Title,
    ModalInfoDescription,
    ModalTextContainer,
    WarningText,
} from './styled';

const UninstallModalBody = (props) => {
    const {
        name, description, environmentVariables, iconUrl,
    } = props;
    const functionType = getFunctionType(environmentVariables);
    return (
        <>
            <WarningText>
                This function will be uninstalled immediately. You can&apos;t undo this action.
            </WarningText>
            <ModalInfoContainer>
                <FunctionIcon
                    functionType={functionType}
                    url={iconUrl}
                    width="50px"
                    height="50px"
                />
                <ModalTextContainer>
                    <Title title={name}>{name}</Title>
                    <RenderIf isTrue={functionType === 'marketplace'}>
                        <ModalInfoDescription title={description}>
                            {description}
                        </ModalInfoDescription>
                    </RenderIf>
                </ModalTextContainer>
            </ModalInfoContainer>
        </>
    );
};

UninstallModalBody.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    environmentVariables: PropTypes.object,
    iconUrl: PropTypes.string,
};

UninstallModalBody.defaultProps = {
    name: undefined,
    description: undefined,
    environmentVariables: undefined,
    iconUrl: '',
};

export default UninstallModalBody;
