import styled from 'styled-components';
import { ButtonGroup, ButtonIcon } from 'react-rainbow-components';

export const DropdownContainer = styled.div`
    width: 230px;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    margin-left: 1px !important;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
    width: max-content;
`;
