import PropTypes from 'prop-types';

export default function RemoteConfig(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="192px" height="192px" viewBox="0 0 192 192">
            <defs>
                <circle id="path-1" cx="96" cy="96" r="96" />
            </defs>
            <g id="functions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="remote-config">
                    <g id="Path-+-Path-Mask">
                        <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                        </mask>
                        <g id="Mask" />
                        <polygon id="Path" fill="#F88300" fillRule="nonzero" mask="url(#mask-2)" points="0 0 192 0 192 192 0 192" />
                        <polygon id="Path" fill="#EF6C00" fillRule="nonzero" mask="url(#mask-2)" points="54 124 54 114.667 54.805 114.667 55.726 114.665 56.921 114.665 59.911 114.663 63.337 114.661 70.945 114.661 71.865 114.663 72.457 114.664 72.667 114.667 72.667 77.333 72.857 75.466 73.4 73.721 74.26 72.138 75.4 70.756 76.78 69.612 78.366 68.746 80.118 68.196 82 68 119.333 68 119.333 54 192 126.667 192 192 122 192" />
                    </g>
                    <path d="M119.333,114.667 L119.333,100.667 L138,119.333 L119.333,138 L119.333,124 L91.333,124 L91.333,114.667 L119.333,114.667 Z M114.667,68 L82,68 C76.843,68.014 72.667,72.223 72.667,77.333 L72.667,114.667 C72.667,114.653 54.009,114.667 54,114.667 L54,124 L72.667,124 C77.823,124 82,119.79 82,114.667 L82,77.333 C82,77.366 119.324,77.347 119.333,77.333 L119.333,91.333 L138,72.667 L119.333,54 L119.333,68 L114.667,68 Z" id="Shape" fill="#FFFFFF" />
                </g>
            </g>
        </svg>
    );
}

RemoteConfig.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

RemoteConfig.defaultProps = {
    style: {},
    className: undefined,
};
