import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'react-rainbow-components';
import formatFunctionsData from '../../helpers/formatFunctionsData';
import { TreeContainer, StyledTree } from './styled';

const FunctionsTree = ({
    functions, selectedNode, onNodeExpand, onNodeSelect, showWorkspaceName, ...rest
}) => {
    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        setTreeData(formatFunctionsData(functions, showWorkspaceName));
    }, [functions, showWorkspaceName]);

    const updateNodeChild = (nodePath, child) => {
        const newData = [...treeData];
        newData[nodePath] = child;
        setTreeData(newData);
    };

    const expandCollapseNode = async (node) => {
        const { nodePath } = node;
        const child = Tree.getNode(treeData, nodePath);
        if (!child.isExpanded) {
            try {
                const { functionId } = child;
                child.isLoading = !child.isLoading;
                updateNodeChild(nodePath, child);
                child.children = await onNodeExpand(functionId);
                child.isExpanded = !child.isExpanded;
            } catch (error) {
                //
            }
            child.isLoading = !child.isLoading;
        } else {
            child.isExpanded = !child.isExpanded;
        }
        updateNodeChild(nodePath, child);
    };

    const handleNodeSelect = (node) => {
        const { name: nodeName, nodePath } = node;
        const child = Tree.getNode(treeData, nodePath);
        if (Array.isArray(child.children)) {
            expandCollapseNode(node);
        } else {
            onNodeSelect({ name: nodeName, nodePath, ...child });
        }
    };

    const { name } = selectedNode;

    return (
        <TreeContainer>
            <StyledTree
            // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                data={treeData}
                ariaLabel="tree-icons"
                selectedNode={name}
                id="functions-tree"
                onNodeExpand={expandCollapseNode}
                onNodeSelect={handleNodeSelect}
            />
        </TreeContainer>
    );
};

FunctionsTree.propTypes = {
    functions: PropTypes.array,
    selectedNode: PropTypes.object,
    onNodeExpand: PropTypes.func,
    onNodeSelect: PropTypes.func,
    showWorkspaceName: PropTypes.bool,
};

FunctionsTree.defaultProps = {
    functions: undefined,
    selectedNode: undefined,
    showWorkspaceName: false,
    onNodeExpand: () => {},
    onNodeSelect: () => {},
};

export default FunctionsTree;
