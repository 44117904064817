import PropTypes from 'prop-types';
import {
    NameColumnContainer,
    StyledFlowIcon,
    StyledLink,
    FlowDescription,
} from './styled';

const NameColumn = ({ row, workspace }) => {
    const { name, description, id } = row;
    return (
        <NameColumnContainer>
            <StyledFlowIcon />
            <div>
                <StyledLink to={`/app/developer/workspaces/${workspace}/deployments/flows/${id}`}>
                    {name}
                </StyledLink>
                <FlowDescription>
                    {description}
                </FlowDescription>
            </div>
        </NameColumnContainer>
    );
};

NameColumn.propTypes = {
    row: PropTypes.object.isRequired,
    workspace: PropTypes.string.isRequired,
};

export default NameColumn;
