/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import SecretFormCreateMode from './createMode';
import SecretFormAddVersionMode from './addVersionMode';

export default function CreateSecretForm(props) {
    const { mode, ...rest } = props;

    if (mode === 'add-version') {
        return <SecretFormAddVersionMode {...rest} />;
    }

    return <SecretFormCreateMode {...rest} />;
}

CreateSecretForm.propTypes = {
    id: PropTypes.string,
    mode: PropTypes.string,
};

CreateSecretForm.defaultProps = {
    id: undefined,
    mode: 'install',
};
