import useCallableQuery from './useCallableQuery';

const useFunctionSupportedRegions = ({ projectId }) => {
    const { data = [], isLoading } = useCallableQuery(
        ['functions-listSupportedRegions', projectId],
        {
            projectId,
        },
        {
            enabled: Boolean(projectId),
        },
    );
    return [data, isLoading];
};

export default useFunctionSupportedRegions;
