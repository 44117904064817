import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Table, Column, RenderIf } from 'react-rainbow-components';
import usePermissions from '../../../../data/hooks/usePermissions';
import AlignLeftContent from '../../../../components/AlignLeftContent';
import Message from '../../../../components/Message';
import { MessageIcon } from '../styled';

const getPermissions = ({ customClaims, permissions }) => {
    if (customClaims && Array.isArray(customClaims.permissions)) {
        return permissions
            .filter((permission) => !!customClaims.permissions.includes(permission.name));
    }
    return [];
};

const Permissions = (props) => {
    const {
        isLoading: isLoadingUser, customClaims,
    } = props;
    const { projectId } = useParams();
    const [data, isLoadingPermissions] = usePermissions(projectId);
    const permissions = getPermissions({ customClaims, permissions: data });
    const isLoading = isLoadingUser || isLoadingPermissions;

    return (
        <>
            <RenderIf isTrue={isLoading || permissions.length > 0}>
                <Table data={permissions} keyField="id" variant="listview" isLoading={isLoading}>
                    <Column header="Name" field="name" component={AlignLeftContent} width={250} />
                    <Column header="Description" field="description" component={AlignLeftContent} />
                </Table>
            </RenderIf>
            <RenderIf isTrue={!isLoading && permissions.length === 0}>
                <Message
                    className="rainbow-m-vertical_xx-large"
                    icon={<MessageIcon />}
                    title="This project has no user permissions to display."
                    description="First, start assigning roles."
                />
            </RenderIf>
        </>
    );
};

Permissions.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    customClaims: PropTypes.object,
};

Permissions.defaultProps = {
    customClaims: {},
};

export default Permissions;
