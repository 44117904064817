import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import {
    MessageContainer,
    MessageTitle,
    MessageDescription,
    MessageIcon,
} from './styled';

export default function EnableApiMessage({ onRequestEnableApi }) {
    return (
        <MessageContainer data-cy="enable-api-message">
            <MessageIcon />
            <MessageTitle>
                Enable the Secret Manager API
            </MessageTitle>
            <MessageDescription>
                To be able to select your secrets you have
                to enable the API in your project.
            </MessageDescription>
            <Button variant="brand" label="Enable" onClick={onRequestEnableApi} />
        </MessageContainer>
    );
}

EnableApiMessage.propTypes = {
    onRequestEnableApi: PropTypes.func,
};

EnableApiMessage.defaultProps = {
    onRequestEnableApi: () => {},
};
