import { useDoc, useCurrentUser } from '@rainbow-modules/firebase-hooks';

const useWorkspaceAccess = (workspaceName) => {
    const { uid } = useCurrentUser();
    const [data, isLoading] = useDoc({
        path: `workspaces/${workspaceName}`,
        flat: true,
    });
    const isOwner = Boolean(
        data
        && Array.isArray(data.owners)
        && data.owners.some((owner) => owner === uid),
    );
    return [isOwner, isLoading];
};

export default useWorkspaceAccess;
