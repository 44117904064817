/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import useCheckProject from '../../data/hooks/useCheckProject';
import useCheckProjectSAPermissions from '../../data/hooks/useCheckProjectSAPermissions';
import CheckingMessage from '../projects/checkingMessage';
import AvailableProjectsList from './projectsList';
import ProjectPermissions from './projectPermissions';
import ModalFooter from './modalFooter';
import {
    ModalContainer,
    ModalTitle,
    StyledModal,
} from './styled';

const stages = {
    LIST_PROJECTS: 'list-projects',
    CHECK_PROJECT: 'check-project',
};

export default function CheckModal({ onRequestClose, ...modalProps }) {
    const [stage, setStage] = useState();
    const [activeProject, setActiveProject] = useState();
    const { projectId: activeProjectId } = activeProject || {};
    const {
        isLoading: isCheckingPermissions, permissions, checkPermissions,
    } = useCheckProjectSAPermissions();
    const checkProject = useCheckProject();
    const { isOpen } = modalProps;

    const { ungranted } = permissions || {};
    const hasPassedCheck = Boolean(
        !isCheckingPermissions && ungranted && ungranted.length === 0,
    );

    const checkOrGo = () => {
        if (hasPassedCheck) {
            return checkProject({
                projectId: activeProjectId,
                spinnerMessage: <CheckingMessage projectId={activeProjectId} />,
            });
        }

        return checkPermissions(activeProjectId);
    };

    const showList = () => setStage(stages.LIST_PROJECTS);

    const selectProject = (project) => {
        const { projectId } = project;
        setActiveProject(project);
        setStage(stages.CHECK_PROJECT);
        return checkPermissions(projectId);
    };

    const cleanUp = () => {
        setStage();
        setActiveProject();
    };

    const title = (stage === stages.CHECK_PROJECT ? 'Check Project Permission' : 'Project Check');
    const primaryFooterLabel = (
        hasPassedCheck
            ? 'Go to Project'
            : 'Check Again'
    );

    const footer = (
        stage === stages.CHECK_PROJECT && !isCheckingPermissions
            ? (
                <ModalFooter
                    primaryButtonLabel={primaryFooterLabel}
                    secondaryButtonLabel="Go Back"
                    onPrimaryButtonClick={checkOrGo}
                    onSecondaryButtonClick={showList}
                />
            )
            : undefined
    );

    useEffect(() => {
        if (isOpen) {
            setStage(stages.LIST_PROJECTS);
        }
        return () => {
            if (isOpen) {
                cleanUp();
            }
        };
    }, [isOpen]);

    return (
        <StyledModal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...modalProps}
            id="add-projects-modal"
            size="large"
            title={<ModalTitle>{title}</ModalTitle>}
            onRequestClose={onRequestClose}
            footer={footer}
        >
            <ModalContainer className="rainbow-flex rainbow-justify_end">
                <RenderIf isTrue={stage === stages.LIST_PROJECTS}>
                    <AvailableProjectsList onCheck={selectProject} />
                </RenderIf>
                <RenderIf isTrue={stage === stages.CHECK_PROJECT}>
                    <ProjectPermissions
                        {...activeProject}
                        isLoading={isCheckingPermissions}
                        permissions={permissions}
                    />
                </RenderIf>
            </ModalContainer>
        </StyledModal>
    );
}

CheckModal.propTypes = {
    onRequestClose: PropTypes.func,
};

CheckModal.defaultProps = {
    onRequestClose: () => {},
};
