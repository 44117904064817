/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Modal } from 'react-rainbow-components';
import Policy from '../../Icons/policyIcon';

export const Content = styled.div`
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin: -16px;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    height: calc(100% + 32px);
`;

export const LeftContent = styled.div`
    min-width: 240px;
    max-width: 240px;
    border-right: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin: 0 12px 0 0;
    padding: 20px 0 12px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const RightContent = styled.div`
    padding: 20px 20px 12px 12px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const PolicyHeader = styled.header`
    display: flex;
    align-items: start;
`;

export const PolicyIcon = styled(Policy)`
    width: 40px;
    height: 40px;
    min-width: 40px;
    margin-right: 12px;
    margin-top: 6px;
`;

export const PolicyName = styled.h2`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const PolicyDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const EmptyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const EmptyTitle = styled.h1`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 4px;
`;

export const EmptyDescription = styled.h2`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

// eslint-disable-next-line import/prefer-default-export
export const AddPolicyModalContainer = styled(Modal)`
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;

    > div {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: auto;
        height: 100%;
    }
`;
