import {
    ButtonMenu,
} from 'react-rainbow-components';
import {
    More,
} from '@rainbow-modules/icons';
import PropTypes from 'prop-types';
import {
    ButtonMenuContainer,
} from './styled';
import MenuAction from './menuAction';

const ButtonAction = ({
    onViewDetails,
    onEdit,
    onEnable,
    onDisable,
    onDelete,
    status,
    disabled,
}) => (
    <ButtonMenuContainer>
        <ButtonMenu
            id="button-menu-2"
            icon={<More className="rainbow-m-bottom_xx-small" />}
            disabled={disabled}
            buttonVariant="base"
        >
            <MenuAction
                onViewDetails={onViewDetails}
                onEdit={onEdit}
                onEnable={onEnable}
                onDisable={onDisable}
                onDelete={onDelete}
                status={status}
            />
        </ButtonMenu>
    </ButtonMenuContainer>

);

ButtonAction.propTypes = {
    onViewDetails: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onEnable: PropTypes.func.isRequired,
    onDisable: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

ButtonAction.defaultProps = {
    disabled: false,
};

export default ButtonAction;
