import PropTypes from 'prop-types';

const FolderClose = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="23px"
            height="18px"
            viewBox="0 0 23 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="marketplace" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="marketplace/functions-source-code/download"
                    transform="translate(-181.000000, -357.000000)"
                    fillRule="nonzero"
                >
                    <g id="folderClose-close" transform="translate(180.997242, 356.999973)">
                        <path
                            d="M22.5707755,3.37304556 L22.5707755,16.6392021 C22.5710598,16.7293176 22.5613999,16.8191968 22.5418506,16.9071673 L22.4557636,17.1942282 C22.2418986,17.6848905 21.7563577,18.0010337 21.2211202,17.9981437 L1.34974626,17.9981437 C0.727051838,17.9963951 0.185870392,17.5700646 0.0384071253,16.9650803 C0.0109920859,16.8586823 -0.00185560363,16.7490557 1.33458462e-14,16.6392021 L1.33458462e-14,1.15826295 C-0.00036734308,0.850894666 0.121476085,0.555947531 0.338818676,0.338604939 C0.556161268,0.121262348 0.851108403,-0.00058108033 1.15847669,1.11215385e-15 L7.06779843,1.11215385e-15 C7.48046745,0.00143844408 7.86204066,0.219565189 8.07266799,0.574436866 L9.47009408,2.21353252 L21.421468,2.21353252 C21.7276339,2.21485789 22.0207286,2.33777592 22.2362518,2.55523651 C22.4517749,2.7726971 22.5720652,3.06688002 22.5707755,3.37304556 Z"
                            id="Path"
                            fill="#93DDFE"
                        />
                        <path
                            d="M21.1384892,3.20249404 C21.9395759,3.20249404 22.5706631,3.70051351 22.5706631,4.71699839 L22.5412245,16.9071673 L22.4551376,17.1942282 C22.2412725,17.6848905 21.7557316,18.0010337 21.2204941,17.9981634 L1.34974626,17.9981634 C0.727051838,17.9963951 0.185870392,17.5700646 0.0384071253,16.9650803 L5.4050677e-13,4.38339124 C5.4050677e-13,3.43384934 0.721546314,3.24082843 1.26282987,3.24082843 L21.1384892,3.20249404 Z"
                            id="Path"
                            fill="#67CAFB"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

FolderClose.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

FolderClose.defaultProps = {
    className: undefined,
    style: undefined,
};

export default FolderClose;
