import {
    SuccessIcon, FailedIcon, StatusPendingIcon, SkipIcon, MinusIcon,
} from '../styled';
import StatusInProgressIcon from '../progressIcon';
import {
    SUCCEEDED, FAILED, IN_PROGRESS, QUEUED, DONE, SKIPPED, NOT_RUN,
} from '../../status';

const iconsMap = {
    [DONE]: {
        [SUCCEEDED]: SuccessIcon,
        [FAILED]: FailedIcon,
        [SKIPPED]: SkipIcon,
        [NOT_RUN]: MinusIcon,
    },
    [IN_PROGRESS]: StatusInProgressIcon,
    [QUEUED]: StatusPendingIcon,
};

export default function getIconForStatus(check = {}, props) {
    const { status, conclusion } = check;
    const mapResult = iconsMap[status];
    const Icon = status === DONE ? mapResult[conclusion] : mapResult;
    if (Icon) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Icon {...props} />;
    }
    return null;
}
