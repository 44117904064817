import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import MarkdownOutputDark from '../../MarkdownOutputDark';
import {
    Content,
    LeftContent,
    RightContent,
    PolicyHeader,
    PolicyIcon,
    PolicyName,
    PolicyDescription,
    EmptyContainer,
    EmptyTitle,
    EmptyDescription,
} from './styled';
import PolicyPicker from '../../PolicyPicker';

const AddPolicyModalContent = (props) => {
    const {
        value, onChange, policies, isLoading, policy,
    } = props;
    const policyIconAsImg = policy.iconUrl ? 'img' : undefined;
    const hasPolicySelected = Boolean(value);
    return (
        <Content>
            <LeftContent>
                <PolicyPicker
                    value={value}
                    onChange={onChange}
                    policies={policies}
                    isLoading={isLoading}
                />
            </LeftContent>
            <RightContent>
                <RenderIf isTrue={!isLoading && !hasPolicySelected}>
                    <EmptyContainer>
                        <EmptyTitle>
                            Find the policies that you need
                        </EmptyTitle>
                        <EmptyDescription>
                            Select the policy that you want to add to your function
                        </EmptyDescription>
                    </EmptyContainer>
                </RenderIf>
                <RenderIf isTrue={!isLoading && hasPolicySelected}>
                    <PolicyHeader>
                        <PolicyIcon as={policyIconAsImg} src={policy.iconUrl} />
                        <div>
                            <PolicyName>
                                {policy.name}
                            </PolicyName>
                            <PolicyDescription>
                                {policy.description}
                            </PolicyDescription>
                        </div>
                    </PolicyHeader>
                    <MarkdownOutputDark value={policy.docs} />
                </RenderIf>
            </RightContent>
        </Content>
    );
};

AddPolicyModalContent.propTypes = {
    value: PropTypes.string,
    policy: PropTypes.object,
    onChange: PropTypes.func,
    policies: PropTypes.array,
    isLoading: PropTypes.bool,
};

AddPolicyModalContent.defaultProps = {
    value: undefined,
    policy: {},
    onChange: () => {},
    policies: [],
    isLoading: true,
};

export default AddPolicyModalContent;
