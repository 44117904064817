import styled from 'styled-components';
import { Card } from 'react-rainbow-components';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronRight, Function } from '@rainbow-modules/icons';
import { InputSearch } from '@rainbow-modules/search';
import FolderClose from '../../../components/Icons/fileTree/folderClose';
import Folder from '../../../components/Icons/folder';

export const CardContainer = styled.div`
    width: 350px;
    margin: 0 0 8px 10px;
`;

export const StyledCard = styled(Card)`
    padding: 0 0 12px 4px;
    border: 1px solid transparent;
`;

export const WorkspaceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 16px;
`;

export const StatsContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0 0 16px;
`;

export const StatContent = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
`;

export const StatIcon = styled(Function)`
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-right: 4px;
`;

export const StatValue = styled.h5`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Link = styled(RouterLink)`
    &:hover, &:active, &:focus {
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
    }
`;

export const MessageIcon = styled(FolderClose)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;

export const StyledAnchor = styled.a`
    padding: 0 0 0 3px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const StyledChevronRight = styled(ChevronRight)`
    width: 10px;
    height: 10px;
    margin-left: 2px;
`;

export const StyledInputSearch = styled(InputSearch)`
    margin: 0 0 20px 8px;
    width: 50%;
`;

export const HeaderSectionContainer = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 0 12px 12px 12px;
`;

export const HeaderSectionTitle = styled.h1`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const StyledFolderIcon = styled(Folder)`
    width: 64px;
    height: 64px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const CardTitle = styled.h2`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const CardDescription = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-bottom: 8px;
`;

export const ResultsMessage = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-left: 16px;
    text-align: center;
`;
