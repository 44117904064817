import {
    HelpText, ZoomableImage, Modal, Spinner,
    Card, LoadingShape,
} from 'react-rainbow-components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorCircleFilled, CheckCircleFilled, ChevronRight } from '@rainbow-modules/icons';
import { InputSearch } from '@rainbow-modules/search';
import { RecordSection, CopyToClipboardButton } from '@rainbow-modules/record';
import { FloatingBar } from '@rainbow-modules/layout';
import {
    LIGHT_WHITE, RED, GREEN, GRAY, WHITE,
} from '../../colors';
import Project from '../../components/Icons/project';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    background-color: ${LIGHT_WHITE};
    height: 100vh;
    padding: 32px 28px 0 32px;
    border-radius: 22px 0 0 0;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    @media (max-width: 400px) {
        flex-wrap: wrap;

        & > div {
            margin-bottom: 12px;
        }
    }
`;

export const LeftContent = styled.div`
    display: flex;
    overflow: hidden;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.5px;
    font-weight: 900;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const FloatingBarTitle = styled(Title)`
    font-size: 20px;
`;

export const Highlight = styled.span`
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    padding: 0 8px;
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
    margin-left: 12px;
    flex: 1;
    justify-content: flex-end;
`;

export const ServiceAccountLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.25px;
    margin-bottom: 2px;
`;

export const StyledHelpText = styled(HelpText)`
    width: 14px;
    height: 14px;
    margin-right: 4px;
    margin-bottom: 6px;

    > svg {
        width: 14px;
        height: 14px;

        > path {
            fill: ${(props) => props.theme.rainbow.palette.text.header};
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px 0;
`;

export const ContentTitle = styled.h3`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const ContentDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StepDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const StyledImage = styled(ZoomableImage)`
    margin-left: -12px;
    margin-top: 8px;
`;

export const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 8px;
    margin: 4px 4px 4px 0;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    width: 100%;
    max-width: 570px;
`;

export const Line = styled.div`
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
    position: absolute;
    top: 8px;
    bottom: 8px;
    border: 2px solid ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ItemLabel = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 14px;
    margin-left: 16px;
`;

export const ItemDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 12px;
    margin-left: 16px;
`;

export const ModalContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    box-sizing: border-box;
    flex: 1;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 24px;
    height: 100%;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    padding: 16px 0;
`;

export const ModalTitle = styled.h1`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    display: flex;
    padding: 16px 36px 12px 32px;
    justify-content: center;
`;

export const ModalDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 14px;
    margin-bottom: 8px;
`;

export const CheckItemContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
`;

export const CheckItemLabel = styled.h4`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const CheckItemDescription = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const CheckItemIconError = styled(ErrorCircleFilled)`
    width: 20px;
    height: 20px;
    color: ${RED};
    margin-right: 12px;
    margin-top: 4px;
`;

export const CheckItemIconDone = styled(CheckCircleFilled)`
    width: 20px;
    height: 20px;
    color: ${GREEN};
    margin-right: 12px;
    margin-top: 4px;
`;

export const ButtonLink = styled.a`
    color: white;
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    background: ${(props) => props.theme.rainbow.palette.brand.main};
    padding: 0 14px;
    border-radius: 40px;

    :hover,
    :focus,
    :active {
        text-decoration: none;
        background: ${(props) => props.theme.rainbow.palette.brand.dark};
        color: white;
    }
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-bottom: 2px;
`;

export const ProjectNameContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const ProjectIcon = styled(Project)`
    width: 30px;
    height: 30px;
    margin-right: 8px;
    color: ${GRAY};
`;

export const StyledModal = styled(Modal)`
    height: 100%;
    display: flex;
    padding: 0;

    > div {
        margin: -12px 0;
        height: 100%;
    }
`;

export const MessageContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HelpTextContent = styled.p`
    width: 220px;

    > strong {
        font-family: 'Lato Black', Arial, Helvetica, sans-serif;
        padding: 0 4px;
    }
`;

export const GoToProjectsButton = styled(Link)`
    color: ${WHITE};
    font-size: 16px;
    line-height: 38px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.rainbow.palette.brand.main};
    padding: 0 14px;
    border-radius: 40px;
    background: ${(props) => props.theme.rainbow.palette.brand.main};
    margin-left: 16px;

    :hover,
    :focus,
    :active {
        text-decoration: none;
        border: 1px solid ${(props) => props.theme.rainbow.palette.brand.dark};
        background: ${(props) => props.theme.rainbow.palette.brand.dark};
        color: ${WHITE};
    }
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${LIGHT_WHITE};
    margin: 0 20px 0 90px;
    padding: 14px 0 0;
`;

export const StyledInputSearch = styled(InputSearch)`
    width: 50%;
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    transform: unset;
`;

export const ButtonCheckIcon = styled(ChevronRight)`
    width: 14px;
    height: 14px;
    margin-right: -4px;
    margin-left: 4px;
`;

export const StyledCard = styled(Card)`
    width: 100%;
    margin: 8px 0 0;
    border: none;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_10};
    border-radius: 8px;
    padding: 8px 20px 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
        margin-bottom: 16px;
    }
`;

export const ProjectId = styled.h4`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-weight: normal;
`;

export const ProjectTitle = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const ProjectContent = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledRecordSection = styled(RecordSection)`
    display: flex;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 8px;
    margin-bottom: 8px;
    height: 100%;

    header {
        padding: 10px 0;
    }
`;

export const UngrantedPermissionsLabel = styled.span`
    display: inline-flex;
    color: ${RED};
    align-items: center;
    margin-right: 20px;

    svg {
        margin: 0 10px 0 0;
        width: 20px;
        height: 20px;
    }
`;

export const ProjectsListContainer = styled.div`
    padding: 0 16px;
    margin: 0 -16px 8px;
    overflow: auto;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
`;

export const LoadingCircleShape = styled(LoadingShape)`
    width: 20px;
    height: 20px;

    [shape="circle"] {
        min-width: 20px;
        min-height: 20px;
    }
`;

export const Spacer = styled.div`
    min-height: 70px;
`;
