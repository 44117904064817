import styled from 'styled-components';
import {
    CheckCircleFilled, CloseCircleFilled, ErrorCircleFilled, InfoBorder,
} from '@rainbow-modules/icons';
import { Spinner } from 'react-rainbow-components';
import LabelWithDescription from '../../components/LabelWithDescription';
import { LIGHT_WHITE } from '../../colors';

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: ${LIGHT_WHITE};
    height: 100vh;
    border-radius: 22px 0 0 0;
    padding: 32px 0;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 980px;
    min-width: 980px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow-x: auto;
    height: 100%;

    @media (max-width: 1024px) {
        max-width: 100%;
        min-width: 100%;
        padding: 0 24px;
    }
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.5px;
    margin: 0 0 4px 0;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Highlight = styled.span`
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    padding: 0 6px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
`;

export const StyledLink = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 14px;
    text-decoration: underline;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }

    :focus {
        outline: none;
    }
`;

export const Description = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const SectionTitle = styled.h2`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    font-size: 22px;
`;

export const Label = styled(LabelWithDescription)`
    flex: 1;

    span {
        font-weight: normal;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`;

export const ItemLabel = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 16px;
`;

export const ItemDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 14px;
`;

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0 0 20px 12px;
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const MessageTitle = styled.h2`
    font-size: 18px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 8px;
    text-align: center;
`;

export const MessageDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 12px;
    max-width: 450px;
    text-align: center;
`;

export const SectionContainer = styled.div`
    margin: 16px 0 8px 0;
`;

export const StyledSpinner = styled(Spinner)`
    margin-bottom: 32px;
    display: flex;
    box-sizing: border-box;
    z-index: unset;
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
`;

export const ServicesContainer = styled.div`
    margin-bottom: 20px;
`;

export const BillingContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 8px;
    margin: 16px 4px;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
`;

export const Line = styled.div`
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
    position: absolute;
    top: 8px;
    bottom: 8px;

    ${(props) => props.variant === 'warning' && `
        border: 2px solid ${props.theme.rainbow.palette.warning.main};
    `}

    ${(props) => props.variant === 'success' && `
        border: 2px solid ${props.theme.rainbow.palette.success.main};
    `}

    ${(props) => props.variant === 'error' && `
        border: 2px solid ${props.theme.rainbow.palette.error.dark};
    `}
`;

export const BillingNotGranted = styled(ErrorCircleFilled)`
    color: ${(props) => props.theme.rainbow.palette.warning.main};
    margin-right: 24px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-left: 20px;
`;

export const ServiceContainer = styled.div`
    display: flex;
    padding: 12px 16px 12px 8px;
    margin: 16px 4px;
    align-items: start;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
`;

export const StateLabel = styled.span`
    text-transform: capitalize;
    margin-left: 4px;
    color: ${(props) => props.theme.rainbow.palette.error.dark};
    margin-top: 8px;

    ${(props) => props.state === 'enabled' && `
        color: ${props.theme.rainbow.palette.success.main};
    `}
`;

export const PermissionContainer = styled.div`
    display: flex;
    padding: 12px 16px 12px 8px;
    margin: 16px 4px;
    align-items: center;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
`;

export const StyledGranted = styled(CheckCircleFilled)`
    color: ${(props) => props.theme.rainbow.palette.success.main};
    margin-right: 24px;
    margin-left: 24px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
`;

export const StyledNotGranted = styled(CloseCircleFilled)`
    color: ${(props) => props.theme.rainbow.palette.error.dark};
    margin-right: 24px;
    margin-left: 24px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
`;

export const InfoContainer = styled.div`
    display: flex;
    align-items: flex-start;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 16px;
    font-size: 14px;
`;

export const InfoIcon = styled(InfoBorder)`
    width: 18px;
    height: 18px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    margin: 2px 8px;
    flex-shrink: 0;
`;
