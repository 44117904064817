import { Option } from 'react-rainbow-components';
import getOptionLabel from '../helpers/getOptionLabel';

const RegionOptions = ({ regions }) => regions.map((region) => {
    const { locationId } = region;
    return (
        <Option
            key={locationId}
            name={locationId}
            label={getOptionLabel(region)}
            value={locationId}
        />
    );
});

export default RegionOptions;
