import { useState, useMemo } from 'react';
import { RenderIf, Spinner } from 'react-rainbow-components';
import { filterByFields } from '@rainbow-modules/listview';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import useWorkspaces from '../../../data/hooks/useWorkspaces';
import usePlanServiceCount from '../../../data/hooks/usePlanServiceCount';
import useUpgradeToast from '../../../data/hooks/useUpgradeToast';
import getUserRoleInWorkspace from '../../../helpers/getUserRoleInWorkspace';
import WorkspaceCard from './workspaceCard';
import HeaderSection from './headerSection';
import ServiceLimiteInfo from '../../../components/ServiceLimiteInfo';
import {
    WorkspaceContainer,
    Link,
    StyledAnchor,
    StyledChevronRight,
    StyledInputSearch,
    StyledCard,
    StyledFolderIcon,
    CardTitle,
    CardDescription,
    ResultsMessage,
} from './styled';

const InfoMessage = () => (
    <StyledCard className="rainbow-flex rainbow-p-top_small rainbow-p-left_medium rainbow-p-right_medium rainbow-m-left_x-small">
        <StyledFolderIcon />
        <div className="rainbow-p-left_medium rainbow-p-top_x-small rainbow-p-right_large">
            <CardTitle>
                Start creating workspaces
            </CardTitle>
            <CardDescription>
                <p className="rainbow-m-bottom_x-small">
                    Here&apos;s a quick guide on how to get
                    started developing in the Function Store.
                </p>
                <StyledAnchor
                    href="https://docs.functions.store/functions/developers/start-developing"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Getting Started
                    <StyledChevronRight />
                </StyledAnchor>
            </CardDescription>
        </div>
    </StyledCard>
);

const Workspaces = () => {
    const [{ owner, invited }, isLoading] = useWorkspaces();
    const [searchValue, setSearchValue] = useState('');
    const { uid } = useCurrentUser();

    const {
        isLoading: isLoadingServiceCount, count, limit, hasReachedLimit, product,
    } = usePlanServiceCount({ serviceName: 'workspaces' });
    useUpgradeToast({
        hasReachedLimit,
        serviceName: 'workspaces',
        isLoadingServiceCount,
    });

    const ownerFilteredData = useMemo(
        () => filterByFields({
            data: owner,
            query: searchValue,
            fields: ['id'],
        }),
        [searchValue, owner],
    );

    const invitedFilteredData = useMemo(
        () => filterByFields({
            data: invited,
            query: searchValue,
            fields: ['id'],
        }),
        [searchValue, invited],
    );

    const hasData = !isLoading && (owner.length > 0 || invited.length > 0);
    const hasNoData = !isLoading && (owner.length === 0 && invited.length === 0);
    const hasInvitedWorkspaces = invitedFilteredData.length > 0;

    return (
        <>
            <RenderIf isTrue={isLoading}>
                <Spinner size="x-large" type="arc" variant="brand" />
            </RenderIf>
            <RenderIf isTrue={hasData}>
                <StyledInputSearch id="workspaces-search" value={searchValue} onChange={setSearchValue} variant="realtime" placeholder="Search" />
                <HeaderSection
                    title="Your Workspaces"
                    actions={(
                        <RenderIf isTrue={!isLoadingServiceCount}>
                            <ServiceLimiteInfo
                                label={`${count} of ${limit} Workspaces`}
                                helpTextTitle="Workspaces in your current plan"
                                featureUsageName="Workspaces"
                                featureUsageSummaryCounter={`${count} of ${limit} workspaces`}
                                featureUsageCounter={`${count} workspaces`}
                                upgradeText="If you need more workspaces, you have to upgrade your plan."
                                limit={limit}
                                count={count}
                                product={product}
                            />
                        </RenderIf>
                    )}
                />
                <WorkspaceContainer>
                    <RenderIf isTrue={owner.length === 0}>
                        <InfoMessage />
                    </RenderIf>
                    <RenderIf isTrue={owner.length > 0 && ownerFilteredData.length === 0}>
                        <ResultsMessage>
                            We did not find any match
                        </ResultsMessage>
                    </RenderIf>
                    <RenderIf isTrue={owner.length > 0 && ownerFilteredData.length > 0}>
                        {ownerFilteredData.map((workspace) => {
                            const { id } = workspace;
                            const role = getUserRoleInWorkspace({ workspace, uid });
                            return (
                                <Link key={id} to={`/app/developer/workspaces/${id}`}>
                                    <WorkspaceCard name={id} userRole={role} />
                                </Link>
                            );
                        })}
                    </RenderIf>
                </WorkspaceContainer>
                <RenderIf isTrue={hasInvitedWorkspaces}>
                    <HeaderSection title="Others" />
                    <WorkspaceContainer>
                        {invitedFilteredData.map((workspace) => {
                            const { id } = workspace;
                            const role = getUserRoleInWorkspace({ workspace, uid });
                            return (
                                <Link key={id} to={`/app/developer/workspaces/${id}`}>
                                    <WorkspaceCard name={id} userRole={role} />
                                </Link>
                            );
                        })}
                    </WorkspaceContainer>
                </RenderIf>
            </RenderIf>
            <RenderIf isTrue={hasNoData}>
                <InfoMessage />
            </RenderIf>
        </>
    );
};

export default Workspaces;
