import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Tabset, Tab } from 'react-rainbow-components';
import { Step } from '@rainbow-modules/content';
import CopyToClipboardServiceAccount from '../../components/CopyToClipboardServiceAccount';
import Step1 from './images/step1.png';
import Step2 from './images/step2.png';
import Step3 from './images/step3.png';
import Step5 from './images/step5.png';
import SlowAssignRole from './slowAssignRole';
import FastAssignRole from './fastAssignRole';
import {
    Content,
    Header,
    ContentTitle,
    ContentDescription,
    StepDescription,
    ButtonLink,
    Highlight,
    StyledImage,
} from './styled';

const tabContentMap = {
    fast: FastAssignRole,
    slow: SlowAssignRole,
};

export default function Steps({ onCheck }) {
    const [selected, setSelected] = useState('slow');
    const TabContent = tabContentMap[selected];

    const handleOnSelect = (event, value) => {
        setSelected(value);
    };

    return (
        <Content>
            <Header>
                <div>
                    <ContentTitle>
                        Add new projects to the Project list
                    </ContentTitle>
                    <ContentDescription>
                        The following steps will guide you to assign roles to your project
                        so that you can add it to the Project list and start installing
                        functions with Function Store.
                    </ContentDescription>
                </div>
                <Button
                    className="rainbow-m-left_small"
                    label="Check"
                    variant="brand"
                    onClick={onCheck}
                />
            </Header>

            <Step
                number="1"
                header="In the GCP Console, go to the Resource Manager page."
            >
                <ButtonLink
                    href="https://console.cloud.google.com/cloud-resource-manager"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Go to the Resource Manager page
                </ButtonLink>
            </Step>

            <Step
                number="2"
                header="Select the project to which you want to add a principal."
            >
                <StepDescription>
                    In the resource selector, select the project name. You will see
                    a menu where you can edit or delete permissions or add a principal to grant new.
                </StepDescription>
                <StyledImage src={Step1} width={600} height={600} />
            </Step>

            <Step
                number="3"
                header="In the Permissions tab, click the Add Principal button."
            >
                <StepDescription>
                    In this section, you can add principals and then roles
                    to grant them access to your resources.
                    <br />
                    Note that in this case, you will be adding a service account as a principal.
                </StepDescription>
                <StyledImage src={Step2} width={600} height={600} />
            </Step>

            <Step
                number="4"
                header="Add the Service Account."
            >
                <StepDescription>
                    You can find the Service account info in the upper right
                    corner of this page, or copy it from below.
                </StepDescription>
                <CopyToClipboardServiceAccount />
                <StepDescription className="rainbow-m-top_large">
                    Paste it in the
                    <Highlight>
                        New principals
                    </Highlight>
                    box.
                </StepDescription>
                <StyledImage src={Step3} width={600} height={600} />
            </Step>

            <Step
                number="5"
                header="Assign Roles to the Service account."
            >
                <StepDescription>
                    The
                    <Highlight>
                        Select a role
                    </Highlight>
                    drop-down menu, contains the roles with a short description
                    of the permissions they grant.
                </StepDescription>
                <Tabset
                    variant="line"
                    onSelect={handleOnSelect}
                    activeTabName={selected}
                >
                    <Tab label="Restrictive Mode" name="slow" />
                    <Tab label="Fast Mode" name="fast" />
                </Tabset>
                <TabContent />
            </Step>

            <Step
                number="6"
                header="Click the Save button."
            >
                <StepDescription>
                    Verify that the member and the corresponding role
                    are listed.
                </StepDescription>
                <StyledImage src={Step5} width={600} height={600} />
            </Step>

            <Step
                number="7"
                header="Click the Check button."
            >
                <StepDescription>
                    Then click the
                    <Highlight>
                        Check
                    </Highlight>
                    button and wait until the system verifies
                    the permissions again.
                </StepDescription>
                <Button
                    label="Check"
                    variant="brand"
                    onClick={onCheck}
                    className="rainbow-m-top_medium"
                />
            </Step>
        </Content>
    );
}

Steps.propTypes = {
    onCheck: PropTypes.func,
};

Steps.defaultProps = {
    onCheck: () => {},
};
