import PropTypes from 'prop-types';
import { StyledBadge } from './styled';

export default function StatusBadge({ value }) {
    return <StyledBadge label={value} size="small" />;
}

StatusBadge.propTypes = {
    value: PropTypes.string,
};

StatusBadge.defaultProps = {
    value: undefined,
};
