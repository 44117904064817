import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RainbowThemeContainer, RenderIf } from 'react-rainbow-components';
import UpgradePlanButton from '../UpgradePlanButton';
import Usage from '../FeatureUsage';
import {
    Container,
    StyledHelpText,
    Label,
    Title,
    HelpTextContent,
    CurrentPlanLabel,
    CurrentPlanContainer,
    CurrentPlanName,
    CurrentPlanDescription,
    Divider,
    UpgradeText,
} from './styled';

function ServiceLimiteInfo({
    label,
    helpTextTitle,
    featureUsageName,
    featureUsageSummaryCounter,
    featureUsageCounter,
    upgradeText,
    limit,
    count,
    product: { name, description, metadata },
    className,
    style,
}) {
    const planColor = metadata?.color;
    const usageTheme = useMemo(() => ({
        rainbow: {
            palette: {
                brand: planColor,
            },
        },
    }), [planColor]);

    return (
        <Container className={className} style={style}>
            <Label>
                {label}
            </Label>
            <StyledHelpText
                className="rainbow-m-left_small"
                text={(
                    <HelpTextContent>
                        <Title>{helpTextTitle}</Title>
                        <CurrentPlanLabel>
                            Your current plan
                        </CurrentPlanLabel>
                        <CurrentPlanContainer color={planColor}>
                            <CurrentPlanName>
                                {name}
                            </CurrentPlanName>
                            <RenderIf isTrue={description}>
                                <CurrentPlanDescription>
                                    {description}
                                </CurrentPlanDescription>
                            </RenderIf>
                            <Divider />
                            <RainbowThemeContainer theme={usageTheme}>
                                <Usage
                                    feature={featureUsageName}
                                    summaryCounter={featureUsageSummaryCounter}
                                    counter={featureUsageCounter}
                                    limit={limit}
                                    count={count}
                                />
                            </RainbowThemeContainer>
                        </CurrentPlanContainer>

                        <Divider className="rainbow-m-bottom_small" />
                        <UpgradeText>
                            {upgradeText}
                        </UpgradeText>
                        <UpgradePlanButton size="small" className="rainbow-m-bottom_small rainbow-m-left_medium" />
                    </HelpTextContent>
                )}
            />
        </Container>
    );
}

ServiceLimiteInfo.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    helpTextTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    featureUsageSummaryCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    featureUsageCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    featureUsageName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    upgradeText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    limit: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    product: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
};

ServiceLimiteInfo.defaultProps = {
    label: undefined,
    helpTextTitle: undefined,
    featureUsageSummaryCounter: undefined,
    featureUsageCounter: undefined,
    featureUsageName: undefined,
    upgradeText: undefined,
    product: {},
    className: undefined,
    style: undefined,
};

export default ServiceLimiteInfo;
