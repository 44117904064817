import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import Step3 from '../images/3-create-function.png';
import { StyledImage } from '../styled';

const CreateFunction = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header='In the main content area, click the "Create function" button.'
        className="rainbow-m-top_xx-large"
    >
        <StyledImage src={Step3} />
    </Step>
);

CreateFunction.propTypes = {
    stepNumber: PropTypes.number,
};

CreateFunction.defaultProps = {
    stepNumber: undefined,
};

export default CreateFunction;
