import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { RenderIf, ButtonMenu, MenuItem } from 'react-rainbow-components';
import { More, Logout, TrashFilled } from '@rainbow-modules/icons';
import UninstallModalBody from '../../../../components/UninstallModalBody';
import useDeleteFunction from '../../../../data/hooks/useDeleteFunction';
import { ActionsContainer } from './styled';

export default function FunctionActionsColumn({ row, onDelete }) {
    const { projectId } = useParams();
    const { push } = useHistory();
    const {
        region, name, description, environmentVariables, iconUrl, status,
    } = row;

    const deleteFunction = useDeleteFunction({
        onSuccess: onDelete,
    });

    const viewFuncDetails = () => push(`/app/project/${projectId}/functions/installed/${region}/${name}`);

    const isBeingDeleted = status === 'DELETE_IN_PROGRESS';
    const canUninstall = ['ACTIVE', 'UNKNOWN', 'OFFLINE'].includes(status);

    return (
        <ActionsContainer>
            <ButtonMenu
                icon={<More />}
                disabled={isBeingDeleted}
                buttonSize="small"
            >
                <RenderIf isTrue={!isBeingDeleted}>
                    <MenuItem
                        label="View Details"
                        icon={<Logout />}
                        onClick={viewFuncDetails}
                    />
                    <MenuItem
                        label="Uninstall Function"
                        icon={<TrashFilled />}
                        disabled={!canUninstall}
                        onClick={() => deleteFunction({
                            region,
                            name,
                            projectId,
                            children: (
                                <UninstallModalBody
                                    name={name}
                                    description={description}
                                    environmentVariables={environmentVariables}
                                    iconUrl={iconUrl}
                                />
                            ),
                        })}
                    />
                </RenderIf>
            </ButtonMenu>
        </ActionsContainer>
    );
}

FunctionActionsColumn.propTypes = {
    row: PropTypes.object,
    onDelete: PropTypes.func,
};

FunctionActionsColumn.defaultProps = {
    row: undefined,
    onDelete: () => {},
};
