import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

const FormattedCurrency = ({ value }) => (
    <FormattedNumber
        value={value / 100}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD"
        minimumFractionDigits={2}
        maximumFractionDigits={2}
    />
);

FormattedCurrency.propTypes = {
    value: PropTypes.number,
};

FormattedCurrency.defaultProps = {
    value: 0,
};

export default FormattedCurrency;
