import styled from 'styled-components';
import { ErrorCircleFilled } from '@rainbow-modules/icons';
import { RED } from '../../colors';

export const FileName = styled.h3`
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 700;
    word-break: break-word;
`;

export const Bold = styled.span`
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    margin-right: 2px;
`;

export const Console = styled.div`
    background: #282c34;
    color: #e3e5ec;
    padding: 2px 16px;
    border-radius: 6px;
    margin-top: 8px;
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
`;

export const Severity = styled.span`
    max-width: 90px;
    min-width: 90px;
    color: ${RED};

    ${(props) => props.severity === 1
        && `
            color: ${props.theme.rainbow.palette.warning.main};
        `};
`;

export const Icon = styled(ErrorCircleFilled)`
    width: 14px;
    height: 14px;
    margin-right: 6px;
    color: ${RED};

    ${(props) => props.severity === 1
        && `
            color: ${props.theme.rainbow.palette.warning.main};
        `};
`;
