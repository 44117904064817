import PropTypes from 'prop-types';

export default function PayoutIcon(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="24" height="24" viewBox="0 0 44 44">
            <defs>
                <polygon id="path-1" points="0 0.00359183673 43.1240408 0.00359183673 43.1240408 43.1018211 0 43.1018211" />
            </defs>
            <g id="payout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="payout/showing-information" transform="translate(-784.000000, -126.000000)">
                    <g id="Group-27" transform="translate(735.000000, 0.000000)">
                        <g id="Group-23" transform="translate(31.000000, 109.000000)">
                            <g id="payout" transform="translate(18.000000, 17.000000)">
                                <g id="Group-3" transform="translate(0.000000, 0.445388)">
                                    <mask id="mask-2" fill="white">
                                        <use xlinkHref="#path-1" />
                                    </mask>
                                    <g id="Clip-2" />
                                    <path d="M32.3348633,21.5249347 C32.3023755,15.5728122 27.473151,10.7790571 21.5241714,10.7942861 C15.5307429,10.8091388 10.753151,15.6715878 10.7949061,21.7130571 C10.8353143,27.5646082 15.7018041,32.3471388 21.6009469,32.3336978 C27.5342122,32.319302 32.3670286,27.4528122 32.3348633,21.5249347 M21.5035184,43.101098 C9.82017143,43.1904449 -0.194318367,33.3564449 0.00278367347,21.1662 C0.186865306,9.80162857 9.72813061,-0.23935102 22.0741714,0.00803673469 C33.3269469,0.23342449 43.4339265,9.71677143 43.1169469,22.1858327 C42.8309469,33.4368122 33.4387429,43.1985265 21.5035184,43.101098" id="Fill-1" fill="#E3DCF9" mask="url(#mask-2)" />
                                </g>
                                <path d="M21.5433878,13.2681727 C16.8115918,13.288 12.8426122,17.2619184 12.8214298,22 C12.8008571,26.7156327 16.7617551,30.7806939 21.5258776,30.7236735 C26.3506122,30.6662041 30.1956735,27.0752653 30.2895102,22.0727347 C30.3797551,17.2870612 26.3021224,13.2484898 21.5433878,13.2681727 M32.3347735,21.9703673 C32.3669388,27.8977959 27.5341224,32.7647347 21.6008571,32.7791304 C15.7017143,32.7925714 10.8352245,28.0095918 10.7948163,22.1584898 C10.7530612,16.1170204 15.5306531,11.2545714 21.5245306,11.2392719 C27.4730612,11.2244898 32.3022857,16.0182449 32.3347735,21.9703673" id="Fill-4" fill="#6B4CBB" />
                                <path d="M20.2164286,19.9856531 C20.2164286,20.6941429 20.2339388,21.4030816 20.2110408,22.1106735 C20.1917347,22.7019796 20.4103878,23.1253673 20.9630816,23.3489592 C21.9979796,23.7687551 23.0342245,24.1854082 24.0749592,24.5908367 C24.7668367,24.8602245 25.2503878,24.5751224 25.509,23.9348776 C25.7873673,23.2447959 25.3509592,22.8941429 24.783,22.6615714 C24.1975306,22.4222653 23.6134082,22.1708367 23.0104286,21.9840612 C22.4258571,21.8031224 22.1883469,21.4946735 22.2242653,20.8544286 C22.2826327,19.8136939 22.2516531,18.7671224 22.231449,17.7232449 C22.2188776,17.0767143 21.8965102,16.6335714 21.2140612,16.6416531 C20.5307143,16.6497347 20.2321429,17.1032041 20.2204694,17.7488367 C20.207449,18.4941429 20.2177755,19.239898 20.2164286,19.9856531 M21.5436122,13.2683939 C26.301898,13.2482653 30.3795306,17.2872857 30.2897347,22.0725102 C30.195449,27.0754898 26.3508367,30.6664286 21.526102,30.723898 C16.7615306,30.7809184 12.8006327,26.7154082 12.8216508,21.9997755 C12.8428367,17.2616939 16.8118163,13.2882245 21.5436122,13.2683939" id="Fill-6" fill="#E3DCF9" />
                                <path d="M20.2170571,19.9856531 C20.2175061,19.239898 20.2076286,18.4941429 20.220649,17.7488367 C20.2318735,17.1032041 20.5308939,16.6497347 21.2137918,16.6416531 C21.8962408,16.6335714 22.2190571,17.0767143 22.2316286,17.7232449 C22.2513837,18.7671224 22.2823633,19.8136939 22.2239959,20.8544286 C22.1880776,21.4946735 22.4260367,21.8031224 23.0101592,21.9840612 C23.6131388,22.1708367 24.1972612,22.4222653 24.7827306,22.6615714 C25.3506898,22.8941429 25.787098,23.2447959 25.5087306,23.9348776 C25.2505673,24.5751224 24.7670163,24.8602245 24.0746898,24.5908367 C23.0339551,24.1854082 21.9981592,23.7687551 20.9628122,23.3489592 C20.4105673,23.1253673 20.1919143,22.7019796 20.2107714,22.1106735 C20.2336694,21.4030816 20.2166082,20.6941429 20.2170571,19.9856531" id="Fill-8" fill="#6B4CBB" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

PayoutIcon.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

PayoutIcon.defaultProps = {
    style: {},
    className: undefined,
};
