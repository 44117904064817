import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import FxIcon from '../../../../../../components/Icons/fxIcon';
import {
    RED, GREEN, WHITE, DARK_GRAY, EBONY, DARK_WHITE,
} from '../../../../../../colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100%;
`;

export const TestHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 24px 0 24px;
`;

export const TestRow = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 6px;
`;

export const SummaryTestRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
`;

export const FunctionInfoHeaderContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    align-items: center;
`;

export const TestHeaderRowTitle = styled.h3`
    font-size: 15px;
    line-height: 1;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const TestSummaryRowTitle = styled.h3`
    font-size: 15px;
    line-height: 1;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    min-width: 110px;
    max-width: 110px;
`;

export const TestHeaderSecondary = styled.h5`
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 2px;
    line-height: 1;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-transform: cappitalize;
`;

export const FunctionIcon = styled(FxIcon)`
    width: 30px;
    height: 30px;
    margin-right: 12px;
    flex-shrink: 0;
`;

export const StyledBadge = styled(Badge)`
    background: ${(props) => (props.variant === 'error' ? RED : GREEN)};
    color: ${WHITE};
    margin-right: 12px;
    flex-shrink: 0;
`;

export const SecondaryCodeText = styled.div`
    color: ${DARK_GRAY};
    font-size: 13px;
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
    font-weight: 600;
    word-break: break-all;
`;

export const SecondaryCodeTextLight = styled.div`
    color: ${DARK_GRAY};
    font-size: 13px;
    line-height: 1.5;
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
`;

export const PrimaryCodeText = styled.div`
    color: ${EBONY};
    font-size: 13px;
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
    font-weight: 300;
    margin-top: 20px;
`;

export const PrimaryCodeTextBold = styled(PrimaryCodeText)`
    font-weight: 800;
    margin-top: 0;
    padding-left: ${(props) => props.indentationFactor * 16}px;
`;

export const StyledCodeBlock = styled.pre`
    background-color: ${EBONY};
    color: ${DARK_WHITE};
    padding: 12px;
    border-radius: 6px;
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
    margin: 12px 32px 24px 0;
    word-break: break-all;
`;

export const TestItem = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${(props) => props.indentationFactor * 16}px;
`;

export const FileTestResults = styled.div`
    flex-grow: 1;
`;
