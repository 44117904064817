/* eslint-disable react/jsx-props-no-spreading */
import { Button, RenderIf } from 'react-rainbow-components';
import FunctionIcon from '../../../components/FunctionIcon';
import PaidIcon from '../../../components/Icons/paid';
// import FunctionStats from './functionStats';
import {
    CardContainer,
    StyledCard,
    InformationContainer,
    FunctionInfoContainer,
    Footer,
    Description,
    FunctionName,
    FunctionAuthor,
    ButtonsContainer,
    StyledLink,
} from './styled';

const CardFunctions = ({ data, onInstall, onLearnMore }) => data.map((func) => {
    const {
        objectID: id, name, description, iconUrl, price = {}, workspace,
    } = func;

    const handleLearnMore = (e) => {
        e.stopPropagation();
        onLearnMore({ id, workspace });
    };

    const handleInstall = (e) => {
        e.stopPropagation();
        onInstall({ functionId: id, workspace });
    };

    return (
        <CardContainer data-cy={`${workspace}-${id}`} key={`${workspace}-${id}`}>
            <StyledCard>
                <StyledLink onClick={handleLearnMore}>
                    <div>
                        <InformationContainer>
                            <FunctionIcon
                                width="48px"
                                height="48px"
                                url={iconUrl}
                                functionType="marketplace"
                            />
                            <FunctionInfoContainer>
                                <FunctionName title={name}>{name}</FunctionName>
                                <FunctionAuthor>{workspace}</FunctionAuthor>
                            </FunctionInfoContainer>
                        </InformationContainer>
                        <Description>{description}</Description>
                    </div>
                    <Footer>
                        <RenderIf isTrue={price}>
                            <span title="Paid Function">
                                <PaidIcon />
                            </span>
                        </RenderIf>
                        <ButtonsContainer>
                            <Button
                                label="Learn More"
                                variant="base"
                                size="small"
                                className="rainbow-m-horizontal_small"
                                onClick={handleLearnMore}
                            />
                            <Button
                                label="Install"
                                variant="brand"
                                size="small"
                                onClick={handleInstall}
                            />
                        </ButtonsContainer>
                    </Footer>
                </StyledLink>
            </StyledCard>
            {/* <FunctionStats functionId={id} workspace={workspace} {...stats} /> */}
        </CardContainer>
    );
});

export default CardFunctions;
