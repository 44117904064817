import PropTypes from 'prop-types';
import { Table, Column, RenderIf } from 'react-rainbow-components';
import FunctionName from './functionName';
import UsageTotal from './usageTotal';
import countTotalInstallations from './helpers/countTotalInstallations';
import formatInstallationsData from './helpers/formatInstallationsData';
import { ColumnHeader, InstallationsContainer, HelpTextInformation } from '../styled';

const InstallationSummary = ({ data, isLoading }) => {
    const total = countTotalInstallations(data);
    const tableData = formatInstallationsData(data);
    const existData = data.length > 0;
    return (
        <InstallationsContainer>
            <RenderIf isTrue={isLoading || existData}>
                <div>
                    <Table
                        keyField="id"
                        data={tableData}
                        className="rainbow-m-bottom_large rainbow-m-right_xx-small"
                        emptyDescription="Our robots did not find any installation this month."
                        isLoading={isLoading}
                        variant="listview"
                        hideTableHeader
                    >
                        <Column
                            header={<ColumnHeader>Function</ColumnHeader>}
                            field="function"
                            component={FunctionName}
                        />
                        <Column
                            header={<ColumnHeader>Installations</ColumnHeader>}
                            field="installations"
                            width="120"
                            cellAlignment="right"
                        />
                    </Table>
                </div>
            </RenderIf>
            <RenderIf isTrue={!isLoading && existData}>
                <UsageTotal total={total} />
            </RenderIf>
            <RenderIf isTrue={!isLoading && !existData}>
                <HelpTextInformation>
                    There are no monthly installations to show.
                </HelpTextInformation>
            </RenderIf>
        </InstallationsContainer>
    );
};

InstallationSummary.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

InstallationSummary.defaultProps = {
    data: [],
    isLoading: true,
};

export default InstallationSummary;
