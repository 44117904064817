import styled from 'styled-components';
import { AvatarMenu, Avatar } from 'react-rainbow-components';
import { Billing } from '@rainbow-modules/icons';

export const StyledAvatarMenu = styled(AvatarMenu)`
    height: 40px;
`;

export const UserInfoContainer = styled.li`
    display: flex;
    align-items: flex-start;
    margin: 4px 0 12px 0;
`;

export const StyledAvatar = styled(Avatar)`
    margin: 0 12px;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
`;

export const UserFullName = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const UserEmail = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StyledIcon = styled(Billing)`
    width: 25px;
`;
