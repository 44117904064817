import PropTypes from 'prop-types';
import {
    ColumnContainer,
    StyledFlowIcon,
    StyledValue,
} from '../styled';

const FlowColumn = ({ value, deploymentIdNameMap }) => (
    <ColumnContainer>
        <StyledFlowIcon />
        <StyledValue>
            {deploymentIdNameMap[value]}
        </StyledValue>
    </ColumnContainer>
);

FlowColumn.propTypes = {
    value: PropTypes.string.isRequired,
    deploymentIdNameMap: PropTypes.object.isRequired,
};

export default FlowColumn;
