import useProducts from './useProducts';

const getCurrentSubscribedProduct = (products) => products.find((product) => product.current);

const getFreemiumProduct = (products) => products.find((product) => product.metadata.freemium === 'true');

const useCurrentPlan = () => {
    const [products, isLoading] = useProducts();
    if (!isLoading) {
        const currentPlan = getCurrentSubscribedProduct(products) || getFreemiumProduct(products);
        return [currentPlan, isLoading];
    }
    return [undefined, isLoading];
};

export default useCurrentPlan;
