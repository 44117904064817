import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextValue from '../TextValue';

export const Content = styled.div`
    text-align: left;
    margin-left: 12px;
    margin-right: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

export const CellContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const AlignLeftContent = ({ value }) => (
    <CellContainer>
        <Content><TextValue>{value}</TextValue></Content>
    </CellContainer>
);

AlignLeftContent.propTypes = {
    value: PropTypes.string,
};

AlignLeftContent.defaultProps = {
    value: undefined,
};

export default AlignLeftContent;
