import styled from 'styled-components';
import { Avatar } from 'react-rainbow-components';

export const StyledAvatar = styled(Avatar)`
    margin: 0 10px;
    flex-shrink: 0;
`;

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
`;
