import PropTypes from 'prop-types';
import CopyToClipboard from '../../../components/CopyToClipboard';

export default function SecretValue({ value, disabled }) {
    const text = value || 'Not available';
    return <CopyToClipboard value={text} disabled={disabled} />;
}

SecretValue.propTypes = {
    value: PropTypes.string,
    disabled: PropTypes.bool,
};

SecretValue.defaultProps = {
    value: undefined,
    disabled: false,
};
