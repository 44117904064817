import styled, { keyframes } from 'styled-components';
import { ResizableColumns, StackedAccordion } from '@rainbow-modules/layout';
import {
    AccordionSection, Tree, Badge, HelpText, Table, Modal,
} from 'react-rainbow-components';
import { Function as FunctionIcon, ChevronRight, Store } from '@rainbow-modules/icons';
import Message from '../../../../components/Message';
import Import from '../../../../components/Icons/import';
import FxIcon from '../../../../components/Icons/fxIcon';
import {
    WHITE, GREEN, LIGHT_GRAY, ROMAN_SILVER,
} from '../../../../colors';

export const FunctionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100%;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    padding: 20px 0 0 24px;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const TreeContainer = styled.div`
    background: #fff;
    height: 100%;
    overflow: auto;
`;

export const StyledTree = styled(Tree)`
    padding: 12px 0;
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 0 8px;
`;

export const MessageDescription = styled.p`
    margin: 0 auto 12px auto;
`;

export const VersionsContainer = styled.div`
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    margin: 0;
    overflow: auto;
    height: 100%;
`;

export const VersionsHeaderContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 1;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    padding: 16px 0 16px 16px;
    background: #fff;
    margin-left: 1px;
`;

export const VersionsBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding: 20px;
`;

export const VersionsTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding-right: 20px;
`;

export const VersionsTitle = styled.h5`
    font-size: 20px;
    letter-spacing: 0.25px;
    margin: 0 0 0 8px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const VersionsDescription = styled.p`
    margin: 2px 0 0 8px;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const AccordionLabelContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    width: 100%;
    justify-content: space-between;
`;

export const MessageIcon = styled(FunctionIcon)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;

export const EmptyMessage = styled(Message)`
    width: 100%;
`;

export const StyledResizableColumns = styled(ResizableColumns)`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin-top: 16px;
`;

export const RightColumn = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const StyledAnchor = styled.a`
    padding: 0 0 0 3px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    font-size: 14px;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const StyledAccordionSection = styled(AccordionSection)`
    [data-id="accordion-section-content"] {
        padding-left: 17px;
        padding-right: 17px;
    }
`;

export const StyledChevronRight = styled(ChevronRight)`
    width: 10px;
    height: 10px;
    margin-left: 2px;
`;

export const MarketplaceIcon = styled(Store)`
    color: #fff;
    width: 20px;
    height: 20px;
    margin-left: 8px;
`;

export const VersionTreeLabelContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 5px;
`;

export const VersionTreeBadge = styled(Badge)`
    font-weight: normal;
    font-size: 10px;
`;

const fadeInOpacity = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

export const PublishedBadge = styled(Badge)`
    color: ${WHITE};
    background-color: ${GREEN};
    animation: ${fadeInOpacity} 0.5s ease-in-out;
`;

export const ImportedBadge = styled(Badge)`
    color: ${WHITE};
    background-color: ${ROMAN_SILVER};
    animation: ${fadeInOpacity} 0.5s ease-in-out;
`;

export const StyledHelpText = styled(HelpText)`
    border-radius: 0;
`;

export const StyledStackedAccordion = styled(StackedAccordion)`
    height: 100%;
    background: ${WHITE};
    padding: 12px 0 0;
`;

export const ImportIcon = styled(Import)`
    color: ${LIGHT_GRAY};
    margin-bottom: 8px;
    opacity: 0.7;
`;

export const ImportModalTitle = styled.h1`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 26px;
`;

export const ImportModalDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 15px;
    margin: 12px 0;
`;

export const ImportModalFooter = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const SelectedFunctionsCounter = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Counter = styled.div`
    background: ${LIGHT_GRAY};
    border-radius: 12px;
    padding: 0 8px;
    margin-right: 8px;
`;

export const AccordionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const FunctionColumnContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 6px 0;
    box-sizing: border-box;
`;

export const StyledFlowIcon = styled(FxIcon)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 12px;
    width: 38px;
    height: 38px;
    flex-shrink: 0;
`;

export const StyledLink = styled.span`
    font-size: 15px;
    line-height: 1.5em;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: left;
    margin-right: 6px;
    display: block;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const WorkspaceName = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0;
    line-height: 1.1;
`;

export const FunctionInfoContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    align-items: start;
    padding: 0 8px;
`;

export const CheckboxColumnContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 8px;
    padding: 2px 0 0;

    :focus {
        outline: none;
    }
`;

export const FunctionCheckInfo = styled.div`
    display: flex;
    margin: 0 8px;
    align-items: center;
`;

export const VersionsTitleButtonContainer = styled.div`
    display: flex;
    flex-shrink: 0;
`;

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    box-sizing: border-box;
`;

export const TableContainer = styled(Table)`
    overflow-y: auto;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 32px;
`;

export const StyledModal = styled(Modal)`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    > div {
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
`;

export const TopContent = styled.div`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
