import styled from 'styled-components';
import GoogleCloudLogo from '../../../../components/Icons/googleCloudLogo';
import FxIcon from '../../../../components/Icons/fxIcon';

export const TextTooltip = styled.p`
    font-size: 14px;
    text-align: start;
    text-overflow: ellipsis;
    margin-bottom: 4px;
`;

export const StyledLogo = styled(FxIcon)`
    width: 20px;
    height: 20px;
`;

export const StyledGCPLogo = styled(GoogleCloudLogo)`
    width: 20px;
    height: 20px;
`;

export const FunctionNameContainer = styled.div`
    height: 100%;
`;

export const ActionsContainer = styled.div`
    box-sizing: border-box;
    padding: 0;
    border: none;
    line-height: unset;
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    :focus {
        outline: none;
    }
`;
