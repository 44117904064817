import { useMutationFlow as useRainbowMutationFlow } from '@rainbow-modules/hooks';
import ErrorMessage from '../../components/ErrorMessage';

export default function useMutationFlow({ errorMessage, ...mutationFlowProps }) {
    return useRainbowMutationFlow({
        type: {
            success: 'notification',
            error: 'message',
        },
        errorMessage: (error) => ({
            message: <ErrorMessage error={errorMessage || error} />,
            timeout: null,
        }),
        ...mutationFlowProps,
    });
}
