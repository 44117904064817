const getFunctionType = (enviromentsVariables = {}) => {
    if (enviromentsVariables.FUNCTION_ID) {
        return 'marketplace';
    }
    if (enviromentsVariables.FIREBASE_CONFIG) {
        return 'firebase';
    }
    return 'GCP';
};
export default getFunctionType;
