import {
    Table, Column,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import AlignLeftContent from '../../../components/AlignLeftContent';
import {
    Info, Highlight, InfoContainer, Container,
} from './styled';
import usePermissions from '../../../data/hooks/usePermissions';

const AssignPermission = (props) => {
    const { roleName, currentPermsIds, onPermSelection } = props;
    const { projectId } = useParams();
    const [data, isLoading] = usePermissions(projectId);
    const perms = data.filter((perm) => currentPermsIds.indexOf(perm.id) === -1);
    return (
        <Container>
            <InfoContainer>
                <Info>Select the permissions you want to assign to:</Info>
                <Highlight>{roleName}</Highlight>
            </InfoContainer>
            {/* <Input
                icon={<Search />}
                placeholder="Search
                permission" variant="bare" className="rainbow-m-bottom_large" /> */}
            <Table id="add-permissions-table" keyField="id" data={perms} showCheckboxColumn variant="listview" className="rainbow-m-bottom_large" onRowSelection={onPermSelection} isLoading={isLoading}>
                <Column header="Permission" field="name" component={AlignLeftContent} />
            </Table>
        </Container>
    );
};

AssignPermission.propTypes = {
    roleName: PropTypes.string,
    currentPermsIds: PropTypes.arrayOf(PropTypes.string),
    onPermSelection: PropTypes.func,
};

AssignPermission.defaultProps = {
    roleName: undefined,
    currentPermsIds: [],
    onPermSelection: () => {},
};

export default AssignPermission;
