import PropTypes from 'prop-types';
import { TagsBadge } from './styled';

const Tags = (props) => {
    const { tags = [] } = props;
    return (
        tags.map((tag, index) => (
            <TagsBadge
                // eslint-disable-next-line react/no-array-index-key
                key={`tag-${index}`}
                className="rainbow-m-top_x-small"
                label={tag}
                size="small"
                variant="inverse"
            />
        ))
    );
};

Tags.propTypes = {
    tags: PropTypes.array,
};

Tags.defaultProps = {
    tags: undefined,
};

export default Tags;
