import PropTypes from 'prop-types';
import formatDate from '../../../helpers/formatDate';
import AlignLeftContent from '../../../components/AlignLeftContent';

const FormattedDate = (props) => {
    const { value } = props;
    const date = formatDate(value * 1000);
    return (
        <AlignLeftContent value={date} />
    );
};

FormattedDate.propTypes = {
    value: PropTypes.string,
};
FormattedDate.defaultProps = {
    value: undefined,
};

export default FormattedDate;
