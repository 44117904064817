import createDownloadLink from '../../helpers/createDownloadLink';
import useCallableMutation from './useCallableMutation';

export default function useDownloadFunctionBundle({
    functionId, workspace, version,
}) {
    const { mutateAsync: getInstallableZip, isLoading } = useCallableMutation('functions-getInstallableZip');
    const download = async () => {
        if (!isLoading) {
            const result = await getInstallableZip({
                functionId,
                workspace,
                version,
            });
            return createDownloadLink(result);
        }
        return undefined;
    };
    return {
        isDownloading: isLoading,
        download,
    };
}
