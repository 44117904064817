import { useHttpAuthMutation } from '@rainbow-modules/firebase-hooks';
import useMutationFlow from './useMutationFlow';

const useWorkspaceFunctionsImport = ({ workspace, ...mutationProps }) => {
    const { mutateAsync } = useHttpAuthMutation({
        functionName: 'workspaces-api',
        pathname: `/${workspace}/importedFunctions`,
        method: 'POST',
    });
    const { mutate: importFunctions } = useMutationFlow({
        mutation: (functions) => mutateAsync({
            from: 'marketplace',
            functions,
        }),
        ...mutationProps,
    });

    return {
        importFunctions,
    };
};

export default useWorkspaceFunctionsImport;
