import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import { StepDescription, Highlight } from '../styled';

const UploadZip = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header='In the "Source code" field, select "ZIP upload".'
        className="rainbow-m-top_xx-large"
    >
        <StepDescription>
            Click
            <Highlight>Browse</Highlight>
            in the ZIP file field to upload the
            function ZIP file from your local file system.
        </StepDescription>
    </Step>
);

UploadZip.propTypes = {
    stepNumber: PropTypes.number,
};

UploadZip.defaultProps = {
    stepNumber: undefined,
};

export default UploadZip;
