import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import EslintSummary from '../EslintSummary';
import {
    Container,
    Title,
    Description,
} from './styled';

export default function SummaryError(props) {
    const {
        className, style, description, title,
    } = props;
    const isEslintCheck = Array.isArray(description);
    return (
        <Container className={className} style={style} data-cy="summary-error">
            <Title data-cy="summary-error-title">{title}</Title>
            <RenderIf isTrue={isEslintCheck}>
                <EslintSummary errors={description} />
            </RenderIf>
            <RenderIf isTrue={!isEslintCheck}>
                <Description>{description}</Description>
            </RenderIf>
        </Container>
    );
}

SummaryError.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SummaryError.defaultProps = {
    className: undefined,
    style: undefined,
    description: undefined,
    title: undefined,
};
