import styled from 'styled-components';
import { Users } from '@rainbow-modules/icons';

export const Container = styled.div`
    margin: 0 28px 8px 28px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 0 8px 8px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 12px 8px;
`;

export const StyledTabLabel = styled.div`
    font-size: 14px;
    letter-spacing: 0.46px;
`;

export const StyledIcon = styled(Users)`
    height: 16px;
    margin-right: 5px;
    margin-bottom: 2px;
`;
