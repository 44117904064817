const getDeploymentHistoryStatus = (deploymentHistory) => {
    const { installationsSummary = {}, installationsTotal } = deploymentHistory;
    const isPending = Object.keys(installationsSummary).length !== installationsTotal
        || Object.values(installationsSummary).every(
            ({ status: installationStatus }) => installationStatus === 'pending',
        );
    if (isPending) {
        return 'pending';
    }
    const isInProgress = Object.values(installationsSummary).some(
        ({ status: installationStatus }) => installationStatus === 'inProgress',
    );
    if (isInProgress) {
        return 'inProgress';
    }
    const isError = Object.values(installationsSummary).some(
        ({ status: installationStatus }) => installationStatus === 'error',
    );
    if (isError) {
        return 'error';
    }
    const isSuccess = Object.values(installationsSummary).every(
        ({ status: installationStatus }) => installationStatus === 'success',
    );
    if (isSuccess) {
        return 'success';
    }
    return 'error';
};

export default getDeploymentHistoryStatus;
