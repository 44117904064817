import firebase from '../../../firebase';

const getDoc = async (path) => {
    const doc = await firebase.firestore().doc(path).get();
    return {
        id: doc.id,
        ...doc.data(),
    };
};

export default getDoc;
