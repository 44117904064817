import PropTypes from 'prop-types';
import { WorkspaceContainer, WorkspaceIcon } from './styled';

const Workspace = ({ value }) => (
    <WorkspaceContainer>
        <WorkspaceIcon />
        {value}
    </WorkspaceContainer>
);

Workspace.propTypes = {
    value: PropTypes.string,
};

Workspace.defaultProps = {
    value: undefined,
};

export default Workspace;
