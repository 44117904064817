/* eslint-disable react/prop-types */
import { UsersFilled, Function } from '@rainbow-modules/icons';
import { Avatar, RenderIf } from 'react-rainbow-components';
import FeatureCard from './featureCard';
import FolderClose from '../../../components/Icons/fileTree/folderClose';
import Build from '../../../components/Icons/build';
import UsageItem from './usageItem';
import {
    UsageDetailsContainer,
    UsageDetailsHeader,
    FeatureIcon,
    UsageItemIcon,
    // UsageDetailsFooter,
} from './styled';
import {
    SectionTitleContainer,
    SectionTitle,
    SectionSubtitle,
} from '../styled';

const Usage = (props) => {
    const {
        installations,
        projects: {
            count: projectsCount,
            limit: projectLimit,
            actives: activesProjects,
        },
        workspaces: {
            count: workspacesCount,
            limit: workspacesLimit,
            list: workspacesList,
        },
        teamMembers,
        pushes: {
            count: pushesCount,
            limit: pushesLimit,
        },
    } = props;
    const hasTeamMembersService = Boolean(teamMembers);

    return (
        <>
            <SectionTitleContainer>
                <SectionTitle>Usage this month</SectionTitle>
                <SectionSubtitle>
                    On this section, you’ll find usage details by billing period.
                </SectionSubtitle>
            </SectionTitleContainer>
            <FeatureCard
                title="Active Projects"
                description="This section shows a summary of your active projects."
                count={projectsCount}
                limit={projectLimit}
                featureName="Projects"
                featureSummaryCounter={`${projectsCount} of ${projectLimit} active projects`}
                featureCounter={`${projectsCount} active projects`}
            >
                <UsageDetailsContainer>
                    <UsageDetailsHeader>
                        Projects
                    </UsageDetailsHeader>
                    {
                        activesProjects.map(({ id, name }) => (
                            <UsageItem
                                itemTitle={name}
                                itemDescription={id}
                            />
                        ))
                    }
                </UsageDetailsContainer>
            </FeatureCard>
            <FeatureCard
                icon={<FeatureIcon as={FolderClose} />}
                title="Workspaces"
                description="This section shows a summary of the workspaces of which you are the owner."
                count={workspacesCount}
                limit={workspacesLimit}
                featureName="Workspaces"
                featureSummaryCounter={`${workspacesCount} of ${workspacesLimit} workspaces`}
                featureCounter={`${workspacesCount} workspaces`}
            >
                <UsageDetailsContainer>
                    <UsageDetailsHeader>
                        Workspaces
                    </UsageDetailsHeader>
                    {
                        workspacesList.map(({ id }) => (
                            <UsageItem
                                itemTitle={id}
                                icon={<UsageItemIcon as={FolderClose} />}
                            />
                        ))
                    }
                </UsageDetailsContainer>
            </FeatureCard>
            <RenderIf isTrue={hasTeamMembersService}>
                <FeatureCard
                    icon={<FeatureIcon as={UsersFilled} />}
                    title="Team Members"
                    description="This section shows your team members summary."
                    count={teamMembers?.count}
                    limit={teamMembers?.limit}
                    featureName="Team Members"
                    featureSummaryCounter={`${teamMembers?.count} of ${teamMembers?.limit} members`}
                    featureCounter={`${teamMembers?.count} members`}
                >
                    <RenderIf isTrue={hasTeamMembersService && teamMembers?.list?.length > 0}>
                        <UsageDetailsContainer>
                            <UsageDetailsHeader>
                                <span>
                                    Members
                                </span>
                            </UsageDetailsHeader>
                            {
                                teamMembers?.list?.map((teamMember) => (
                                    <UsageItem
                                        itemTitle={teamMember.displayName}
                                        itemDescription={teamMember.email}
                                        icon={<Avatar className="rainbow-m-right_small" src={teamMember.photoURL} />}
                                    />
                                ))
                            }
                        </UsageDetailsContainer>
                    </RenderIf>
                </FeatureCard>
            </RenderIf>
            <FeatureCard
                icon={<FeatureIcon as={Build} />}
                title="Pushes"
                description="Pushes quota resets in every subscription period."
                count={pushesCount}
                limit={pushesLimit}
                featureName="Pushes"
                featureSummaryCounter={`${pushesCount} of ${pushesLimit} pushes`}
                featureCounter={`${pushesCount} pushes`}
            >
                {/* <UsageDetailsContainer>
                    <UsageDetailsHeader>
                        <span>
                            Workspaces
                        </span>
                        <span>
                            Pushes
                        </span>
                    </UsageDetailsHeader>
                    <UsageItem
                        itemTitle="rainbow"
                        icon={<UsageItemIcon as={FolderClose} />}
                        itemCounter="48"
                    />
                    <UsageItem
                        itemTitle="learning"
                        icon={<UsageItemIcon as={FolderClose} />}
                        itemCounter="103"
                    />
                    <UsageDetailsFooter>
                        <span>
                            Total
                        </span>
                        <span>
                            210
                        </span>
                    </UsageDetailsFooter>
                </UsageDetailsContainer> */}
            </FeatureCard>
            <FeatureCard
                icon={<FeatureIcon as={Function} />}
                title="Function Installations"
                description="Function installations count for each function. Function installations resets in every month."
            >
                <UsageDetailsContainer>
                    <UsageDetailsHeader>
                        <span>
                            Functions
                        </span>
                        <span>
                            Installations
                        </span>
                    </UsageDetailsHeader>
                    {installations}
                </UsageDetailsContainer>
            </FeatureCard>
        </>
    );
};

export default Usage;
