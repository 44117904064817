import { useRef } from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import Workspaces from './workspaces';
import {
    DeveloperContainer,
    Content,
    Title,
    Description,
    StyledFloatingBar,
    FloatingBarTitle,
} from './styled';

const formStyles = { width: 0, height: 0 };

const Developer = () => {
    const requestDeveloperFormRef = useRef();
    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });

    return (
        <DeveloperContainer onScroll={handleScrollEvent}>
            <ReactTypeformEmbed
                url="https://form.typeform.com/to/rxBqd7sV?typeform-medium=embed-snippet"
                ref={requestDeveloperFormRef}
                style={formStyles}
                popup
            />
            <StyledFloatingBar isVisible={showBar}>
                <FloatingBarTitle data-cy="page-title">Workspaces</FloatingBarTitle>
            </StyledFloatingBar>
            <Content>
                <Title>Workspaces</Title>
                <Description>
                    Find here all your workspaces and functions that you have been creating.
                </Description>
                <Workspaces />
            </Content>
        </DeveloperContainer>
    );
};

export default Developer;
