import PropTypes from 'prop-types';
import { RenderIf, Button } from 'react-rainbow-components';
import { RecordField } from '@rainbow-modules/record';
import BackButton from '../../components/BackButton';
import CopyToClipboardServiceAccount from '../../components/CopyToClipboardServiceAccount';
import {
    Header,
    LeftContent,
    RightContent,
    ServiceAccountLabel,
    StyledHelpText,
    HelpTextContent,
} from './styled';

const AddProjectHeader = ({ onCheck, title }) => (
    <Header>
        <LeftContent>
            <BackButton to="/app/projects" className="rainbow-m-right_xx-small" />
            {title}
        </LeftContent>
        <RightContent>
            <RecordField
                name="service-account"
                label={(
                    <ServiceAccountLabel>
                        <StyledHelpText
                            text={(
                                <HelpTextContent>
                                    This is the
                                    <strong>
                                        service account
                                    </strong>
                                    associated to your Function Store account.
                                </HelpTextContent>
                            )}
                        />
                        SERVICE ACCOUNT
                    </ServiceAccountLabel>
                )}
                value={(
                    <CopyToClipboardServiceAccount />
                )}
            />
            <RenderIf isTrue={onCheck}>
                <Button
                    label="Check"
                    variant="brand"
                    onClick={onCheck}
                    className="rainbow-m-left_medium"
                    size="small"
                />
            </RenderIf>
        </RightContent>
    </Header>
);

AddProjectHeader.propTypes = {
    onCheck: PropTypes.func,
    title: PropTypes.node.isRequired,
};

AddProjectHeader.defaultProps = {
    onCheck: undefined,
};

export default AddProjectHeader;
