import getVersionLabel from '../../../helpers/getVersionLabel';

const getVersionInitialValue = (version, lastVersionFunction) => {
    const lastVersion = lastVersionFunction?.version;
    if (version) {
        if (version === lastVersion) {
            return {
                name: version,
                label: getVersionLabel(version),
            };
        }
        return {
            name: version,
            label: `v ${version}`,
        };
    }
    return {
        name: lastVersion,
        label: getVersionLabel(lastVersion),
    };
};

export default getVersionInitialValue;
