import { useHistory, useParams } from 'react-router-dom';
import {
    VerticalSection, RainbowThemeContainer, Picklist, Option, RenderIf,
} from 'react-rainbow-components';
import { Function, Settings } from '@rainbow-modules/icons';
import useWorkspaces from '../../data/hooks/useWorkspaces';
import { LIGHTER_BLACK, WHITE } from '../../colors';
import getActiveMenuName from './helpers/getActiveMenuName';
import Build from '../../components/Icons/build';
import Folders from '../../components/Icons/folders';
import PlayCircle from '../../components/Icons/playCircle';
import {
    Container, Bar, IconBar, StyledOption, Divider, Content, FolderIcon,
} from './styled';

const theme = {
    rainbow: {
        palette: {
            mainBackground: LIGHTER_BLACK,
        },
    },
};

const lightTheme = {
    rainbow: {
        palette: {
            mainBackground: WHITE,
        },
    },
};

export const ALL_WORKSPACES_OPTION = 'all-workspaces';

const WorkspaceList = ({ data }) => data.map((item) => {
    const { id } = item;
    return <Option key={id} name={id} label={id} icon={<FolderIcon />} />;
});

const findWorkspace = (data, value) => {
    const workspace = data.find((item) => item.id === value);
    if (workspace) {
        const { id } = workspace;
        return {
            name: id,
            label: id,
            icon: <FolderIcon />,
        };
    }
    return undefined;
};

// eslint-disable-next-line react/prop-types
const WorkspacePicker = ({ onChange, value }) => {
    const [{ owner, invited }, isLoading] = useWorkspaces();
    const currentValue = findWorkspace([...owner, ...invited], value);
    const hasOwnerWorkspaces = owner.length > 0;
    const hasInvitedWorkspaces = invited.length > 0;
    const hasWorkspaces = hasOwnerWorkspaces || hasInvitedWorkspaces;

    return (
        <RainbowThemeContainer theme={lightTheme}>
            <Picklist
                id="workspace-picker"
                className="rainbow-m-left_x-small rainbow-m-right_small rainbow-m-top_medium rainbow-m-bottom_medium"
                variant="inverse"
                label="Select Workspace"
                hideLabel
                placeholder="Select Workspace"
                isLoading={isLoading}
                onChange={(option) => onChange(option.name)}
                value={currentValue}
                enableSearch={hasWorkspaces}
            >
                <Option name={ALL_WORKSPACES_OPTION} label="See all workspaces" icon={<FolderIcon as={Folders} />} />
                <RenderIf isTrue={hasOwnerWorkspaces}>
                    <Option name="header" label="Your Workspaces" variant="header" />
                    <WorkspaceList data={owner} />
                </RenderIf>
                <RenderIf isTrue={hasInvitedWorkspaces}>
                    <Option name="header" label="Others" variant="header" />
                    <WorkspaceList data={invited} />
                </RenderIf>
            </Picklist>
        </RainbowThemeContainer>
    );
};

export default function DeveloperNavigationBar() {
    const { push: navigate, location: { pathname } } = useHistory();
    const { workspace } = useParams();
    const activeSection = getActiveMenuName(pathname);

    const handleChange = (value) => {
        if (value === ALL_WORKSPACES_OPTION) {
            return navigate('/app/developer');
        }
        return navigate(`/app/developer/workspaces/${value}/${activeSection}`);
    };

    return (
        <Container theme={theme}>
            <Content>
                <WorkspacePicker onChange={handleChange} value={workspace} />
                <Divider className="rainbow-m-top_xx-small rainbow-m-bottom_medium" />
                <Bar selectedItem={activeSection}>
                    <VerticalSection>
                        <StyledOption
                            label="Build History"
                            name="build"
                            onClick={() => navigate(`/app/developer/workspaces/${workspace}/build`)}
                            icon={<IconBar as={Build} />}
                        />
                        <StyledOption
                            label="Functions"
                            name="functions"
                            onClick={() => navigate(`/app/developer/workspaces/${workspace}/functions`)}
                            icon={<IconBar as={Function} />}
                        />
                        <StyledOption
                            label="Deployments"
                            name="deployments"
                            onClick={() => navigate(`/app/developer/workspaces/${workspace}/deployments`)}
                            icon={<IconBar as={PlayCircle} />}
                        />
                        <StyledOption
                            label="Settings"
                            name="settings"
                            onClick={() => navigate(`/app/developer/workspaces/${workspace}/settings`)}
                            icon={<IconBar as={Settings} />}
                        />
                    </VerticalSection>
                </Bar>
            </Content>
        </Container>
    );
}
