import styled from 'styled-components';
import Tests from '../Icons/tests';
import { WHITE, LIGHT_GRAY } from '../../colors';

export const Container = styled.div`
    padding: 24px;
`;

export const CoverageColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100%;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${WHITE};

    ${(props) => props.value < 0.75
        && `
            background-image: linear-gradient(to bottom, #dab432, #c7a217);
        `};

    ${(props) => props.value > 0.75
        && `
            background-image: linear-gradient(to bottom, #7bbf91, #649c76);
        `};

    ${(props) => props.value < 0.5
        && `
            background-image: linear-gradient(to bottom, #dc6851, #c9543d);
        `};
`;

export const TotalRowContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 12px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin-top: 8px;
`;

export const TotalRowLabel = styled.div`
    flex-grow: 1;
`;

export const TotalCoverage = styled.div`
    width: 98px;
    color: ${WHITE};
    display: flex;
    justify-content: center;

    ${(props) => props.coverage < 0.75
        && `
            background-image: linear-gradient(to bottom, #dab432, #c7a217);
        `};

    ${(props) => props.coverage > 0.75
        && `
            background-image: linear-gradient(to bottom, #7bbf91, #649c76);
        `};

    ${(props) => props.coverage < 0.5
        && `
            background-image: linear-gradient(to bottom, #dc6851, #c9543d);
        `};
`;

export const TotalFuncs = styled.div`
    width: 86px;
    display: flex;
    justify-content: flex-end;
`;

export const TotalLines = styled.div`
    width: 100px;
    display: flex;
    justify-content: flex-end;
`;

export const TotalUncoveredLines = styled.div`
    width: 128px;
    display: flex;
    justify-content: flex-end;
`;

export const TestIcon = styled(Tests)`
    color: ${LIGHT_GRAY};
    margin-bottom: 16px;
`;
