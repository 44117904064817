import PropTypes from 'prop-types';
import FunctionIcon from '../../../components/FunctionIcon';
import { TypeContainer, StyledType } from './styled';

const type = {
    marketplace: 'Marketplace Function',
    firebase: 'Firebase Function',
    GCP: 'GCP Function',
};

const FunctionType = ({ value }) => (
    <TypeContainer>
        <FunctionIcon
            url={value.iconUrl}
            functionType={value.functionType}
            width="18px"
            height="18px"
        />
        <StyledType>
            {type[value.functionType]}
        </StyledType>
    </TypeContainer>
);

FunctionType.propTypes = {
    value: PropTypes.object.isRequired,
};

export default FunctionType;
