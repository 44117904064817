import { useState, useRef, useEffect } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import Solid from './arrows/solid';
import EndPoint from './arrows/endPoint';
import StartPoint from './arrows/startPoint';
import Enviroment from './environment';
import ConfirmDeployment from './confirmDeployModal';
import {
    Container,
    Trigger,
    Line,
    BuildIcon,
    TriggerContainer,
    TriggerDescription,
    TriggerName,
    TriggerInfo,
    PlusIcon,
    StyledButtonIcon,
    StyledDeployButton,
    DeployIcon,
    DeployModal,
} from './styled';
import CreateEditEnvModal from './createEditEnvModal';
import useUpdateDeployment from '../../hooks/useUpdateDeployment';
import useDeploymentAddedFunctions from '../../../../../../data/hooks/deployments/useDeploymentAddedFunctions';

const TargetProjects = (props) => {
    const {
        projects, selectedProject, onSelectProject, onEdit, baseProjectName, disabled,
    } = props;
    const [openDeployment, setOpenDeployment] = useState();
    const { updateDeployment } = useUpdateDeployment();
    const { index: environmentIndex, name: targetProjectName } = openDeployment || {};

    const removeEnv = async (index) => {
        if (await confirmModal({
            variant: 'destructive',
            header: 'Remove enviroment',
            question: 'Are you sure you want to remove this environment?',
        })) {
            const updateProjects = projects.slice(0, index)
                .concat(projects.slice(index + 1, projects.length));
            await updateDeployment({
                targetProjects: updateProjects,
            });
            onSelectProject({ index: undefined });
        }
    };

    if (Array.isArray(projects) && projects.length > 0) {
        return projects.map((project, index) => (
            <>
                <Line><StartPoint /></Line>
                <RenderIf isTrue={!disabled}>
                    <StyledDeployButton onClick={() => setOpenDeployment({ ...project, index })}>
                        <DeployIcon />
                        Deploy
                    </StyledDeployButton>
                </RenderIf>
                <Line><EndPoint /></Line>
                <Enviroment
                    name={project.name}
                    projectId={project.projectId}
                    isSelected={selectedProject.index === index}
                    onClick={() => onSelectProject({
                        index,
                        name: project.name,
                        projectId: project.projectId,
                    })}
                    onEdit={onEdit}
                    onRemove={() => removeEnv(index)}
                    disabled={disabled}
                />
                <DeployModal
                    isOpen={Boolean(openDeployment)}
                    onRequestClose={() => setOpenDeployment()}
                    size="medium"
                >
                    <ConfirmDeployment
                        baseProjectName={
                            environmentIndex === 0
                                ? baseProjectName
                                : projects[environmentIndex - 1]?.name
                        }
                        targetProjectName={targetProjectName}
                        environmentIndex={environmentIndex}
                        onRequestClose={() => setOpenDeployment()}
                    />
                </DeployModal>
            </>
        ));
    }
    return null;
};

const FlowDiagram = (props) => {
    const [isOpenCreateEditEnv, setOpenCreateEditEnv] = useState(false);
    const {
        deployment, selectedProject, onSelectProject, disabled,
    } = props;
    const targetProjects = useRef(deployment.targetProjects);
    const container = useRef();
    useEffect(() => {
        if (targetProjects.current.length < deployment.targetProjects.length) {
            container.current.scrollTop = container.current.scrollHeight;
            const lastEnvIndex = deployment.targetProjects.length - 1;
            onSelectProject({
                index: lastEnvIndex,
                name: deployment.targetProjects[lastEnvIndex].name,
            });
        }
        targetProjects.current = deployment.targetProjects;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployment.targetProjects]);
    const name = deployment.baseProject && deployment.baseProject.name;
    const projectId = deployment.baseProject && deployment.baseProject.projectId;
    const createEnv = () => {
        onSelectProject({ index: undefined });
        setOpenCreateEditEnv(true);
    };
    const { workspace, deploymentId } = useParams();
    const [addedFunctions] = useDeploymentAddedFunctions({ workspace, deploymentId });

    return (
        <Container ref={container}>
            <TriggerContainer>
                <Trigger>
                    <BuildIcon />
                </Trigger>
                <TriggerInfo>
                    <TriggerName>
                        New Build
                    </TriggerName>
                    <TriggerDescription>
                        When a new build is pushed.
                    </TriggerDescription>
                </TriggerInfo>
            </TriggerContainer>
            <Line><Solid /></Line>
            <Enviroment
                name={name}
                projectId={projectId}
                isSelected={selectedProject.index === -1}
                isBase
                addedFunctions={addedFunctions.length}
                onClick={() => onSelectProject({ index: -1, name, projectId })}
                disabled={disabled}
            />
            <TargetProjects
                projects={deployment.targetProjects}
                baseProjectName={name}
                selectedProject={selectedProject}
                onSelectProject={onSelectProject}
                onEdit={() => setOpenCreateEditEnv(true)}
                disabled={disabled}
            />
            <Line><Solid /></Line>
            <StyledButtonIcon
                shaded
                variant="neutral"
                icon={<PlusIcon />}
                onClick={createEnv}
            />
            <RenderIf isTrue={isOpenCreateEditEnv}>
                <CreateEditEnvModal
                    isOpen
                    index={selectedProject.index}
                    targetProjects={deployment.targetProjects}
                    onRequestClose={() => setOpenCreateEditEnv(false)}
                />
            </RenderIf>
        </Container>
    );
};

FlowDiagram.propTypes = {
    deployment: PropTypes.object.isRequired,
    selectedProject: PropTypes.shape({
        index: PropTypes.number,
        name: PropTypes.string,
        projectId: PropTypes.string,
    }),
    onSelectProject: PropTypes.func,
    disabled: PropTypes.bool,
};

FlowDiagram.defaultProps = {
    selectedProject: {
        index: undefined,
        name: undefined,
        projectId: undefined,
    },
    onSelectProject: () => {},
    disabled: false,
};

export default FlowDiagram;
