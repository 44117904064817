import { useDoc } from '@rainbow-modules/firebase-hooks';

const usePublishedMarketplaceFunction = (workspace, functionId) => {
    const [data, isLoading] = useDoc({
        path: `workspaces/${workspace}/marketplace/${functionId}`,
        flat: true,
    });
    return [data, isLoading];
};

export default usePublishedMarketplaceFunction;
