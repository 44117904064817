/* eslint-disable default-case */
import { useRef, useCallback, useEffect } from 'react';
import { includes } from 'lodash';

const INPUT_BUTTON_TYPES = ['button', 'submit'];

function isFocusNormalizedButton(eventTarget) {
    if (!(eventTarget instanceof window.HTMLElement)) {
        return false;
    }
    switch (eventTarget.nodeName) {
        case 'A':
        case 'BUTTON':
            return true;
        case 'INPUT':
            return includes(INPUT_BUTTON_TYPES, eventTarget.type);
    }

    return false;
}

export default function useFocusOutside(onFocusOutside) {
    const currentOnFocusOutside = useRef(onFocusOutside);
    useEffect(() => {
        currentOnFocusOutside.current = onFocusOutside;
    }, [onFocusOutside]);
    const preventBlurCheck = useRef(false);

    const blurCheckTimeoutId = useRef();

    const cancelBlurCheck = useCallback(() => {
        clearTimeout(blurCheckTimeoutId.current);
    }, []);

    useEffect(() => () => cancelBlurCheck(), [cancelBlurCheck]);

    useEffect(() => {
        if (!onFocusOutside) {
            cancelBlurCheck();
        }
    }, [onFocusOutside, cancelBlurCheck]);

    const normalizeButtonFocus = useCallback((event) => {
        const { type, target } = event;
        const isInteractionEnd = includes(['mouseup', 'touchend'], type);

        if (isInteractionEnd) {
            preventBlurCheck.current = false;
        } else if (isFocusNormalizedButton(target)) {
            preventBlurCheck.current = true;
        }
    }, []);

    const queueBlurCheck = useCallback((event) => {
        event.persist();
        if (preventBlurCheck.current) {
            return;
        }

        blurCheckTimeoutId.current = setTimeout(() => {
            if (!document.hasFocus()) {
                event.preventDefault();
                return;
            }

            if (typeof currentOnFocusOutside.current === 'function') {
                currentOnFocusOutside.current(event);
            }
        }, 0);
    }, []);

    return {
        onFocus: cancelBlurCheck,
        onMouseDown: normalizeButtonFocus,
        onMouseUp: normalizeButtonFocus,
        onTouchStart: normalizeButtonFocus,
        onTouchEnd: normalizeButtonFocus,
        onBlur: queueBlurCheck,
    };
}
