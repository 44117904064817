import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { CheckCircleFilled } from '@rainbow-modules/icons';
import {
    StyledContainer,
    Icon,
} from './styled';

const BadgeText = (props) => {
    const {
        label, variant, style, className,
    } = props;

    return (
        <StyledContainer variant={variant} style={style} className={className}>
            <RenderIf isTrue={variant === 'error' || variant === 'warning'}>
                <Icon />
            </RenderIf>
            <RenderIf isTrue={variant === 'success'}>
                <Icon as={CheckCircleFilled} />
            </RenderIf>
            {label}
        </StyledContainer>
    );
};

export default BadgeText;

BadgeText.propTypes = {
    variant: PropTypes.oneOf(['default', 'success', 'error', 'warning']),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    style: PropTypes.object,
    className: PropTypes.string,
};

BadgeText.defaultProps = {
    variant: 'neutral',
    label: undefined,
    style: undefined,
    className: undefined,
};
