import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { Button, RenderIf } from 'react-rainbow-components';
import { useHistory } from 'react-router-dom';
import { useOpenModal } from '@rainbow-modules/hooks';
import UninstallModalBody from '../../../components/UninstallModalBody';
import useReconfigIntentsInProgress from './hooks/useReconfigIntentsInProgress';
import usePrevValue from '../../../data/hooks/usePrevValue';
import { RECONFIG_FUNCTION_DRAWER } from '../../../constants';
import useDeleteFunction from '../../../data/hooks/useDeleteFunction';
import { StyledSpinner, ReconfiguringLabel } from './styled';
import logEvent from '../../../analytics/logEvent';
import events from '../../../analytics/events';

const HeaderActions = (props) => {
    const {
        region,
        projectId,
        functionId,
        functionName,
        functionType,
        description,
        environmentVariables,
        status,
        iconUrl,
        isPreview,
    } = props;

    const { push } = useHistory();
    const queryClient = useQueryClient();
    const [openModal] = useOpenModal(RECONFIG_FUNCTION_DRAWER);
    const installedFunctionsPath = `/app/project/${projectId}/functions/installed`;
    const deleteFunction = useDeleteFunction({
        onSuccess: () => {
            logEvent(events.functions.UNINSTALLED);
            return push(installedFunctionsPath);
        },
    });
    const isActive = status === 'ACTIVE';
    const canUninstall = isActive || status === 'UNKNOWN' || status === 'OFFLINE';
    const isMarketplaceFunction = functionType === 'marketplace';

    const [reconfigIntentInProgress, isLoadingReconfigIntents] = useReconfigIntentsInProgress({
        projectId,
        functionId,
    });
    const isReconfiguring = Array.isArray(reconfigIntentInProgress)
    && reconfigIntentInProgress.length > 0;

    const wasReconfiguring = usePrevValue(isReconfiguring);

    useEffect(() => {
        if (wasReconfiguring) {
            queryClient.invalidateQueries(['functions-get', projectId, region, functionName], {
                refetchInactive: false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReconfiguring]);

    if (isLoadingReconfigIntents) return null;

    const disableReconfigureButton = !isActive || isReconfiguring || isPreview;

    return (
        <div className="rainbow-flex rainbow-align_center">
            <RenderIf isTrue={isReconfiguring}>
                <StyledSpinner variant="brand" type="arc" size="x-small" />
                <ReconfiguringLabel>Reconfiguring...</ReconfiguringLabel>
            </RenderIf>
            <RenderIf isTrue={!isReconfiguring}>
                <RenderIf isTrue={isMarketplaceFunction}>
                    <Button
                        id="reconfigure-button"
                        className="rainbow-m-right_medium"
                        label="Reconfigure"
                        variant="outline-brand"
                        disabled={disableReconfigureButton}
                        onClick={() => {
                            logEvent(events.functions.OPEN_RECONFIGURE_DRAWER);
                            return openModal({
                                mode: 'config',
                                functionData: {
                                    id: functionId,
                                },
                            });
                        }}
                    />
                </RenderIf>
                <Button
                    label="Uninstall"
                    variant="base"
                    disabled={!canUninstall || isReconfiguring}
                    onClick={() => deleteFunction({
                        region,
                        name: functionName,
                        projectId,
                        children: (
                            <UninstallModalBody
                                name={functionName}
                                description={description}
                                environmentVariables={environmentVariables}
                                iconUrl={iconUrl}
                            />
                        ),
                    })}
                />
            </RenderIf>
        </div>
    );
};

export default HeaderActions;

HeaderActions.propTypes = {
    projectId: PropTypes.string,
    functionId: PropTypes.string,
    functionType: PropTypes.string,
    iconUrl: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    region: PropTypes.string,
    functionName: PropTypes.string,
    environmentVariables: PropTypes.object,
    isPreview: PropTypes.bool,
};

HeaderActions.defaultProps = {
    projectId: undefined,
    functionId: undefined,
    iconUrl: undefined,
    status: undefined,
    description: undefined,
    region: undefined,
    functionName: undefined,
    functionType: undefined,
    environmentVariables: undefined,
    isPreview: false,
};
