import { isEmpty } from '@rainbow-modules/validation';

const getTriggerInitialValue = (trigger = {}) => {
    const { provider, resource, topic } = trigger;

    if (provider === 'firestore' || provider === 'storage') {
        return isEmpty(resource) ? {} : { trigger: { resource } };
    }

    if (provider === 'https') {
        return { trigger: { authenticated: false } };
    }

    if (provider === 'pubsub') {
        return isEmpty(topic) ? {} : { trigger: { topic } };
    }

    return {};
};

export default getTriggerInitialValue;
