import styled from 'styled-components';
import { Button, Table } from 'react-rainbow-components';
import { Roles } from '@rainbow-modules/icons';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const StyledText = styled.p`
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 0 8px 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 20px 16px 8px 16px;
`;

export const StyledTable = styled(Table)`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const FieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 32px;
`;

export const CreateButton = styled(Button)`
    margin-top: 20px;
`;

export const RolesEmptyIcon = styled(Roles)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 12px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;
