import { useHttpAuthQuery } from '@rainbow-modules/firebase-hooks';

const useTeamMembersSummary = () => {
    const { data, isLoading } = useHttpAuthQuery({
        functionName: 'workspaces-api',
        pathname: '/teamMembers',
    });
    return [data, isLoading];
};

export default useTeamMembersSummary;
