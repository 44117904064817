import PropTypes from 'prop-types';
import InformationIcon from './informationIcon';
import {
    TotalContainer, CardFooterTitle,
} from '../styled';

const UsageTotal = ({ total }) => (
    <TotalContainer>
        <CardFooterTitle>
            Total
            <InformationIcon />
        </CardFooterTitle>
        <CardFooterTitle>
            {total}
        </CardFooterTitle>
    </TotalContainer>
);

UsageTotal.propTypes = {
    total: PropTypes.number,
};

UsageTotal.defaultProps = {
    total: undefined,
};

export default UsageTotal;
