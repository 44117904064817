import getIconForStatus from './getIconForStatus';
import MarkdownLabel from '../../../../../../components/MarkdownLabel';

const getChildren = (data, parentName) => {
    if (Array.isArray(data)) {
        return data.map((node) => {
            const {
                name, description, conclusion, duration,
            } = node;
            return {
                label: <MarkdownLabel value={name} />,
                icon: getIconForStatus({ status: 'done', conclusion }),
                duration,
                status: 'done',
                description,
                conclusion,
                parentName,
                name,
            };
        });
    }
    return undefined;
};

const buildFunctionsTree = (data) => {
    if (data) {
        return data.map((entry) => {
            const {
                name, checksResults, status, conclusion, duration, description,
            } = entry;
            return {
                label: <MarkdownLabel value={name} />,
                icon: getIconForStatus(entry),
                isExpanded: false,
                status,
                conclusion,
                description,
                duration,
                children: getChildren(checksResults, name),
                name,
            };
        });
    }
    return [];
};

export default buildFunctionsTree;
