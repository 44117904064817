import { LoadingShape } from 'react-rainbow-components';
import {
    LoadingContainer,
    LoadingSmall,
    LoadingHigh,
} from './styled';

export default function Loading() {
    return (
        <LoadingContainer>
            <LoadingSmall>
                <LoadingShape />
            </LoadingSmall>
            <LoadingShape />
            <LoadingHigh>
                <LoadingShape />
            </LoadingHigh>
        </LoadingContainer>
    );
}
