import { Field } from 'react-final-form';
import { isRequired } from '@rainbow-modules/forms';
import {
    Input, Textarea,
} from 'react-rainbow-components';
import { FieldsContainer } from './styled';

const PermissionFields = () => (
    <FieldsContainer>
        <Field
            component={Input}
            name="name"
            label="Permission Name"
            required
            placeholder="Enter the permission name"
            className="rainbow-m-bottom_large"
            validate={isRequired()}
            id="permission-name-input"
        />
        <Field
            component={Textarea}
            name="description"
            label="Permission Description"
            required
            placeholder="Enter the permission description"
            className="rainbow-m-bottom_xx-large"
            validate={isRequired()}
            id="permission-description-input"
        />
    </FieldsContainer>
);

export default PermissionFields;
