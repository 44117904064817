import PropTypes from 'prop-types';

export default function EndPoint(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="14px"
            height="47px"
            viewBox="0 0 14 47"
        >
            <title>finishPoint</title>
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="flow-details/new-promote-setted-hover" transform="translate(-535.000000, -475.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M549,512 L541.99386,521.333333 L535,512 L549,512 Z M542.974411,505 L542.974411,509 L540.974411,509 L540.974411,505 L542.974411,505 Z M542.974411,495 L542.974411,499 L540.974411,499 L540.974411,495 L542.974411,495 Z M542.974411,485 L542.974411,489 L540.974411,489 L540.974411,485 L542.974411,485 Z M542.974411,475 L542.974411,479 L540.974411,479 L540.974411,475 L542.974411,475 Z" id="finishPoint" />
                </g>
            </g>
        </svg>
    );
}

EndPoint.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

EndPoint.defaultProps = {
    style: {},
    className: undefined,
};
