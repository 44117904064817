import PropTypes from 'prop-types';

export default function Folder(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="61px"
            height="48px"
            viewBox="0 0 61 48"
        >
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="developer/workspaces-limit-exceded" transform="translate(-125.000000, -217.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="Group-13" transform="translate(108.000000, 170.000000)">
                        <g id="Group-14" transform="translate(0.000000, 30.000000)">
                            <g id="Group" transform="translate(17.014252, 17.000000)">
                                <path d="M25.9261977,0.502501875 L7.122542,0.500387069 C3.44921198,0.527621892 0.485748219,3.51300047 0.485748219,7.18635682 L0.485748219,40.8206572 C0.490206413,44.508491 3.47725723,47.4955418 7.16327727,47.5 L54.1563131,47.5 C57.844147,47.4955418 60.8311978,44.508491 60.835656,40.822471 L60.835656,12.9314046 L60.8313428,12.6969846 C60.7037728,9.11781241 57.765721,6.2564251 54.1581269,6.25206284 L31.724,6.252 L27.571978,1.31786482 C27.1645715,0.828116568 26.5646082,0.532367525 25.9261977,0.502501875 Z M7.144784,3.5 L25.488,3.5 L30.3270257,9.25206174 L54.1563131,9.25206174 C56.1864989,9.25451772 57.8332,10.9012188 57.8356571,12.9332184 L57.8356571,40.8206572 C57.8332,42.850843 56.1864989,44.497544 54.1544994,44.5 L7.16509105,44.5 C5.13490524,44.497544 3.4882042,42.850843 3.48574712,40.8188434 L3.48574712,7.18635682 C3.48574712,5.16120629 5.11955243,3.5153201 7.144784,3.5 Z" id="folder-copy" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

Folder.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Folder.defaultProps = {
    style: {},
    className: undefined,
};
