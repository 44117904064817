// eslint-disable-next-line consistent-return
const formatAlgoliaCategories = (filterKey, filterValues) => {
    if (filterValues.length > 0) {
        const formatedFilter = filterValues.join(`" OR ${filterKey}:"`);
        const result = `${filterKey}:"${formatedFilter}"`;
        return filterValues.length > 1 ? `(${result})` : result;
    }
};

export default formatAlgoliaCategories;
