const errorMessageMap = {
    'secret-not-found': 'Secret "SECRET_NAME" doens\'t exists.',
    'permission-denied': 'Your don\'t have enough permissions to access secret "SECRET_NAME".',
};

export default function getErrorMessage(error, secretName) {
    if (error && error.code) {
        const message = errorMessageMap[error.code]
        || 'Unable to access value for secret "SECRET_NAME".';

        return message.replace('SECRET_NAME', secretName);
    }
    return null;
}
