import { useCallback } from 'react';
import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import makeRequest from '../services/makeRequest';

const useCustomerPortal = () => {
    const currentUser = useCurrentUser();
    const openCustomerPortal = useCallback(async () => {
        showAppSpinner();
        const idToken = await currentUser.getIdToken();
        const url = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/xStripe_subscriptions/billingPortals/sessions`;
        const response = await makeRequest({
            url,
            method: 'POST',
            token: idToken,
            body: {
                returnUrl: window.location.href,
            },
        });
        if (response.ok) {
            const { redirectTo } = await response.json();
            window.location.href = redirectTo;
        }
        hideAppSpinner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return openCustomerPortal;
};

export default useCustomerPortal;
