import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { WHITE, GREEN } from '../../../../colors';
import {
    VersionTreeLabelContainer,
    VersionTreeBadge,
} from './styled';

const VersionLabel = (props) => {
    const { versionId, isPublished } = props;

    return (
        <VersionTreeLabelContainer>
            <span>{`Version ${versionId}`}</span>
            <RenderIf isTrue={isPublished}>
                <VersionTreeBadge
                    className="rainbow-m-left_x-small"
                    label="Published"
                    size="small"
                    style={{ color: WHITE, backgroundColor: GREEN }}
                />
            </RenderIf>
        </VersionTreeLabelContainer>
    );
};

VersionLabel.propTypes = {
    versionId: PropTypes.string,
    isPublished: PropTypes.bool,
};

VersionLabel.defaultProps = {
    versionId: undefined,
    isPublished: false,
};

export default VersionLabel;
