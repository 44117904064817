import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
    Modal, Button, Table, Column,
} from 'react-rainbow-components';
import firebase from 'firebase';
import { useSetDoc, useCurrentUser } from '@rainbow-modules/firebase-hooks';
import AlignLeftContent from '../../../components/AlignLeftContent';
import useMutationFlow from '../../../data/hooks/useMutationFlow';
import { InfoContainer, Info, Highlight } from './styled';

const getRoles = (selectedRoles) => firebase.firestore.FieldValue.arrayUnion(
    ...selectedRoles.map((role) => role.id),
);

const AssignRole = (props) => {
    const {
        projectRoles, userRoles, userIdentifier, isOpen, onRequestClose, isLoading, uid,
    } = props;
    const { projectId } = useParams();
    const [selectedRoles, setSelectedRoles] = useState([]);
    const modalTitle = `Assign to "${userIdentifier}" roles`;
    const isAssignDisabled = selectedRoles.length === 0;
    const userRoleIds = userRoles.map((role) => role.id);
    const availableRoles = projectRoles.filter((role) => userRoleIds.indexOf(role.id) === -1);
    const { uid: userId } = useCurrentUser();
    const queryClient = useQueryClient();

    const closeModal = () => {
        setSelectedRoles([]);
        onRequestClose();
    };

    const { mutate: addRoles } = useMutationFlow({
        mutation: useSetDoc().mutateAsync,
        submitSpinnerMessage: 'Adding roles',
        onSuccess: () => {
            closeModal();
            setTimeout(() => queryClient.invalidateQueries(['users-getUser', projectId, uid]), 2000);
        },
        successMessage: 'The roles were added sucessfully.',
    });

    const assignRoles = () => addRoles({
        path: `projects/${projectId}/users/${uid}`,
        data: {
            roles: getRoles(selectedRoles),
            userId,
        },
        options: { merge: true },
    });

    return (
        <Modal
            title={modalTitle}
            isOpen={isOpen}
            onRequestClose={closeModal}
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        className="rainbow-m-right_large"
                        label="Cancel"
                        variant="base"
                        onClick={closeModal}
                    />
                    <Button label="Assign" disabled={isAssignDisabled} variant="brand" onClick={assignRoles} />
                </div>
            )}
        >
            <>
                <InfoContainer>
                    <Info>Select roles you want to assign to:</Info>
                    <Highlight>{userIdentifier}</Highlight>
                </InfoContainer>
                <Table
                    keyField="id"
                    data={availableRoles}
                    showCheckboxColumn
                    variant="listview"
                    className="rainbow-m-bottom_large"
                    onRowSelection={setSelectedRoles}
                    isLoading={isLoading}
                >
                    <Column header="Roles" field="name" component={AlignLeftContent} />
                </Table>
            </>
        </Modal>
    );
};

AssignRole.propTypes = {
    projectRoles: PropTypes.array,
    userRoles: PropTypes.array,
    userIdentifier: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    uid: PropTypes.string.isRequired,
};

AssignRole.defaultProps = {
    projectRoles: [],
    userRoles: [],
    userIdentifier: '',
};

export default AssignRole;
