import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import Upgrade from '../Icons/upgrade';
import { WHITE } from '../../colors';

export const UpgradeIcon = styled(Upgrade)`
    color: ${WHITE};
    margin-left: -4px;
    margin-right: 8px;
    height: 18px;
    width: 18px;
`;

export const StyledButton = styled(Button)`
    color: ${WHITE};

    :hover, :active, :focus {
        color: ${WHITE};
    }
`;
