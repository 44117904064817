import {
    Picklist, Option, Input,
} from 'react-rainbow-components';
import {
    Field, composeValidators, min, max, isRequired,
} from '@rainbow-modules/forms';
import { Number as NumberIcon } from '@rainbow-modules/icons';
import LabelWithDescription from '../../LabelWithDescription';
import Sec from '../../Icons/sec';
import memoryOptions from './memoryOptions';
import { StyledMemoryIcon } from '../styled';
import {
    InstancesContainer, AutoScalingContainer, InstancesLabel, InstanceInput, StyledHelpText,
} from './styled';

const isLessThanMaxInstance = (value, allValues) => {
    if (value) {
        const newValue = Number(value);
        const { runtimeConfig } = allValues;
        const newMaxInstance = Number(runtimeConfig.maxInstances);
        if (newMaxInstance < newValue) {
            return 'The minimum function instances limit must be less than or equal maximum instances limit';
        }
    }
    return undefined;
};

const isBiggerThanMinInstance = (value, allValues) => {
    if (value) {
        const newValue = Number(value);
        const { runtimeConfig } = allValues;
        const newMinInstance = Number(runtimeConfig.minInstances);
        if (newMinInstance > newValue) {
            return 'The maximum function instances limit must be bigger than or equal minimum instances limit';
        }
    }
    return undefined;
};

const RuntimeFields = () => (
    <>
        <Field
            id="runtime-memory-picker"
            name="runtimeConfig.memory"
            component={Picklist}
            label={(
                <LabelWithDescription
                    name="Memory Allocated"
                    description="Amount of memory to allocate to the function.
                        You can select any of the next valid options:
                        '128 MB', '256 MB', '512 MB', '1 GB', '2GB', '4 GB', '8 GB'."
                />
            )}
            labelAlignment="left"
            className="rainbow-m-bottom_large"
            validate={isRequired('Memory is required')}
            required
        >
            {memoryOptions.map((item) => (
                <Option
                    key={item.name}
                    name={item.name}
                    label={item.label}
                    icon={<StyledMemoryIcon />}
                />
            ))}
        </Field>
        <Field
            id="runtime-timeoutSeconds"
            name="runtimeConfig.timeoutSeconds"
            label={(
                <LabelWithDescription
                    name="Timeout"
                    description="If the Cloud Function has not
                        completed by the timeout duration,
                        then the Function will be terminated. The default is 60 seconds and the maximum time allowed is 540 seconds."
                />
            )}
            labelAlignment="left"
            component={Input}
            type="number"
            min={1}
            max={540}
            icon={<Sec />}
            iconPosition="right"
            validate={
                composeValidators(
                    isRequired('Timeout is required'),
                    min(1, 'Timeout must be an integer between 1 and 540 (9 minutes)'),
                    max(540, 'Timeout must be an integer between 1 and 540 (9 minutes)'),
                )
            }
            required
        />
        <AutoScalingContainer>
            <div className="rainbow-m-horizontal_medium">
                <LabelWithDescription
                    name={(
                        <span>
                            Autoscaling
                            <StyledHelpText
                                variant="question"
                                text="To improve performance, you can set the minimum number of instances greater than 0. You can also set a maximum number of instances for use cases like limiting costs. Sudden traffic spikes may cause the limit to be temporarily exceeded. Leave empty or set to 0 to clear this control and automatically create new instances as needed."
                            />
                        </span>
                    )}
                    description="Bounds the number of instances that are created in response to the request load received by your function."
                />
            </div>
            <InstancesContainer>
                <Field
                    id="runtime-minInstances"
                    name="runtimeConfig.minInstances"
                    component={InstanceInput}
                    type="number"
                    className="rainbow-m-right_xx-small"
                    label={<InstancesLabel>Minimum number of instances</InstancesLabel>}
                    min={0}
                    max={2147483647}
                    icon={<NumberIcon />}
                    validate={
                        composeValidators(
                            min(0, 'The minimum function instances limit must be a positive integer'),
                            max(2147483647, 'The minimum function instances limit must be less than or equal 2147483647'),
                            isLessThanMaxInstance,
                        )
                    }
                />
                <Field
                    id="runtime-maxInstances"
                    name="runtimeConfig.maxInstances"
                    component={InstanceInput}
                    type="number"
                    className="rainbow-m-left_xx-small"
                    label={<InstancesLabel>Maximum number of instances</InstancesLabel>}
                    max={2147483647}
                    min={1}
                    validate={
                        composeValidators(
                            min(1, 'The maximum function instances limit must be bigger than 0'),
                            max(2147483647, 'The maximum function instances limit must be less than or equal 2147483647'),
                            isBiggerThanMinInstance,
                        )
                    }
                    icon={<NumberIcon />}
                />
            </InstancesContainer>
        </AutoScalingContainer>
    </>
);

export default RuntimeFields;
