import styled from 'styled-components';
import { ZoomableImage } from 'react-rainbow-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';

export const ZipHeader = styled.div`
    margin-bottom: 50px;
`;

export const ContentTitle = styled.h3`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 15px;
`;

export const ContentDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ButtonLink = styled.a`
    color: white;
    font-size: 16px;
    line-height: 36px;
    height: 36px;
    background: ${(props) => props.theme.rainbow.palette.brand.main};
    padding: 0 14px;
    border-radius: 40px;

    :hover,
    :focus,
    :active {
        text-decoration: none;
        background: ${(props) => props.theme.rainbow.palette.brand.dark};
        color: white;
    }
`;

export const StepDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const StyledImage = styled(ZoomableImage)`
    margin-top: 8px;
    width: 600px;
`;

export const Line = styled.div`
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
    position: absolute;
    top: 8px;
    bottom: 8px;
    border: 2px solid ${(props) => props.theme.rainbow.palette.text.header};
`;

export const EnvContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
`;

export const ItemContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 16px 8px 8px;
    margin: 4px 4px 4px 0;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
`;

export const ItemLabel = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 14px;
    margin-left: 16px;
`;

export const ItemDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 12px;
    margin-left: 16px;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-bottom: 2px;
`;

export const Highlight = styled.span`
    font-family: 'Lato Black', Arial, Helvetica, sans-serif;
    margin: 0 4px;
    font-size: 13px;
`;

export const StyledAnchor = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;
