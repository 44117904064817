import styled from 'styled-components';
import { Check } from '@rainbow-modules/icons';
import { GREEN } from '../../colors';

export const FeatureContainer = styled.div`
    display: flex;
    padding-bottom: 4px;
`;

export const StyledFeature = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-left: 4px;
`;

export const StyledCheck = styled(Check)`
    color: ${GREEN};
    width: 10px;
    height: 10px;
    margin-right: 5px;
    margin-top: 4px;
    flex-shrink: 0;
`;
