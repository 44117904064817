import { useState, useRef, useEffect } from 'react';
import { RenderIf } from 'react-rainbow-components';
import RuntimeLabel from './label';
import RuntimeFields from './fields';
import { OpenIcon, DocsLink } from '../styled';
import {
    AccordionSection, Accordion, AccordionContainer, FieldsContainer,
} from './styled';

const Runtime = () => {
    const [active, setActive] = useState(false);
    const fieldsRef = useRef();

    useEffect(() => {
        if (active) {
            fieldsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [active]);

    const toggleAccordion = () => {
        setActive(!active);
    };

    return (
        <AccordionSection className="rainbow-m-top_large">
            <AccordionContainer>
                <Accordion type="button" onClick={toggleAccordion} data-cy="runtime-section">
                    <RuntimeLabel active={active} />
                </Accordion>
                <DocsLink href="https://docs.functions.store/functions/resources/runtime-configuration" target="_blank">
                    View Docs
                    <OpenIcon />
                </DocsLink>
            </AccordionContainer>
            <RenderIf isTrue={active}>
                <FieldsContainer ref={fieldsRef}>
                    <RuntimeFields />
                </FieldsContainer>
            </RenderIf>
        </AccordionSection>
    );
};

export default Runtime;
