import {
    IN_PROGRESS, DONE,
} from '../../status';

const getStatusLabel = (status, conclusion) => {
    if (status === DONE) {
        return conclusion;
    }
    if (status === IN_PROGRESS) {
        return 'running';
    }
    return status;
};

export default getStatusLabel;
