import PropTypes from 'prop-types';
import { Tabset, Tab, RenderIf } from 'react-rainbow-components';

const TabsetInstalledFunctionDetails = ({
    onSelect,
    activeTabName,
    details,
    installedFunction,
}) => (
    <Tabset variant="line" onSelect={onSelect} activeTabName={activeTabName}>
        <RenderIf isTrue={details}>
            <Tab name="details" label="Details" />
        </RenderIf>
        <RenderIf isTrue={installedFunction}>
            <Tab name="logs" label="Logs" />
            <Tab name="configuration" label="Configuration" />
            <Tab name="docs" label="How this function works" />
        </RenderIf>
    </Tabset>
);

TabsetInstalledFunctionDetails.propTypes = {
    onSelect: PropTypes.func.isRequired,
    activeTabName: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
    installedFunction: PropTypes.object.isRequired,
};

export default TabsetInstalledFunctionDetails;
