import styled from 'styled-components';

export const Header = styled.header`
    padding: 8px 16px 0 0;
`;

export const FunctionTitle = styled.h1`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

export const FunctionInfoContainer = styled.div`
    display: flex;
    margin-top: 12px;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 12px;
`;

export const FunctionName = styled.h2`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const FunctionDescription = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const FunctionCreator = styled.h4`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;
