const createDownloadLink = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    if (fileName) {
        link.setAttribute('download', fileName);
    }
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

export default createDownloadLink;
