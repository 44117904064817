import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Label from '../LabelWithDescription';
import {
    ItemContainer, Item, StyledIcon,
} from './styled';
import Firestore from './providers/firestore';
import Pubsub from './providers/pubsub';
import Storage from './providers/storage';
import Https from './providers/https';
import iconMap from './iconMap';

const providerMap = {
    firestore: Firestore,
    storage: Storage,
    pubsub: Pubsub,
    https: Https,
};

export default function Trigger(props) {
    const {
        provider, event, resource, editMode, topic,
    } = props;
    const Icon = iconMap[provider];
    const resourceExist = Boolean(resource);
    const renderInput = editMode || !resourceExist;

    const ProviderContent = providerMap[provider];
    return (
        <>
            <ItemContainer>
                <Label
                    name="Trigger Type"
                    description="The trigger type when deploying your function"
                    className="rainbow-m-left_medium"
                />
                <Item className="rainbow-p-left_small">
                    <StyledIcon as={Icon} />
                    {provider}
                </Item>
            </ItemContainer>
            <ItemContainer>
                <Label
                    name="Event"
                    description="The event that will be monitored to trigger your function"
                    className="rainbow-m-left_medium"
                />
                <Item>
                    {event}
                </Item>
            </ItemContainer>
            <RenderIf isTrue={ProviderContent}>
                <ProviderContent topic={topic} resource={resource} renderInput={renderInput} />
            </RenderIf>
        </>
    );
}

Trigger.propTypes = {
    provider: PropTypes.string,
    event: PropTypes.string,
    resource: PropTypes.string,
    topic: PropTypes.string,
    editMode: PropTypes.bool,
};

Trigger.defaultProps = {
    provider: undefined,
    event: undefined,
    resource: undefined,
    topic: undefined,
    editMode: false,
};
