import styled from 'styled-components';
import { Plus } from '@rainbow-modules/icons';
import { Table } from 'react-rainbow-components';
import { Link } from 'react-router-dom';
import On from '../../../../../components/Icons/on';
import Flow from '../../../../../components/Icons/flow';

export const Content = styled.article`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 8px;
    overflow: auto;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Subtitle = styled.h2`
    font-size: 16px;
    letter-spacing: 0.25px;
    margin: 12px 0 4px 0;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const SmallDescription = styled.p`
    font-size: 12px;
    margin: 0 0 16px 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const NameColumnContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;
`;

export const StyledFlowIcon = styled(Flow)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 12px;
    width: 30px;
    height: 30px;
`;

export const StyledLink = styled(Link)`
    font-size: 15px;
    line-height: 1.5;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: none;
    text-align: left;
    margin-right: 6px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
        text-decoration: underline;
    }
`;

export const FlowDescription = styled.p`
    text-align: left;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const FormContainer = styled.div`
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

export const FormHeader = styled.header`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.title};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    margin-bottom: 24px;
`;

export const FormFooter = styled.footer`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    padding: 20px 20px 4px;
    margin-right: -32px;
    margin-left: -32px;
`;

export const StyledTable = styled(Table)`
    overflow: auto;
`;

export const PlusIcon = styled(Plus)`
    width: 14px;
    height: 14px;
    margin-right: 8px;
`;

export const OnIcon = styled(On)`
    width: 17px;
    height: 17px;
`;

export const ButtonMenuContainer = styled.div`
    display: inline-flex;
    height: 100%;
    align-items: center;
`;
