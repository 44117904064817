import { RenderIf, LoadingShape } from 'react-rainbow-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import useServiceAccount from '../../data/hooks/useServiceAccount';
import { LoadingShapeStyle, ServiceAccountValue } from './styled';

const CopyToClipboardServiceAccount = () => {
    const [serviceAccount, isLoading] = useServiceAccount();
    return (
        <>
            <RenderIf isTrue={isLoading}>
                <LoadingShapeStyle>
                    <LoadingShape />
                </LoadingShapeStyle>
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <ServiceAccountValue className="rainbow-m-top_x-small">
                    <span>{serviceAccount}</span>
                    <CopyToClipboardButton
                        value={serviceAccount}
                        variant="border-filled"
                        shaded
                        size="small"
                        className="rainbow-m-left_x-small"
                    />
                </ServiceAccountValue>
            </RenderIf>
        </>
    );
};

export default CopyToClipboardServiceAccount;
