import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    RenderIf, Column, ButtonGroup,
} from 'react-rainbow-components';
import { Filter, Plus } from '@rainbow-modules/icons';
import { FloatingSearchButtonIcon, filterByFields } from '@rainbow-modules/listview';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { showAppNotification, showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import AlignLeftContent from '../../../components/AlignLeftContent';
import ButtonIconTooltip from '../../../components/ButtonIconTooltip';
import Message from '../../../components/Message';
import PermissionFields from './PermissionFields';
import {
    Container,
    Header,
    StyledText,
    StyledTable,
    MessageIcon,
    CreateButton,
} from './styled';
import usePermissions from '../../../data/hooks/usePermissions';
import useCallableMutation from '../../../data/hooks/useCallableMutation';
import events from '../../../analytics/events';
import logEvent from '../../../analytics/logEvent';

export default function Permissions() {
    const [searchValue, setSearchValue] = useState('');
    const { projectId } = useParams();
    const [data, isLoading] = usePermissions(projectId);
    const [isOpenModal, setOpenModal] = useState(false);
    const { mutateAsync: createPerm } = useCallableMutation('permissions-create');

    const createPermission = async (values) => {
        showAppSpinner({ message: 'Creating permission' });
        try {
            await createPerm({ ...values, projectId });
            showAppNotification({
                title: 'Success!',
                description: `The "${values.name}" permission was created successfully.`,
                icon: 'success',
                timeout: 5000,
            });
            hideAppSpinner();
            logEvent(events.auth.CREATE_PERMISSION);
        } catch (error) {
            hideAppSpinner();
            showAppNotification({
                title: 'Error!',
                description: `The "${values.name}" permission could not be created.`,
                icon: 'error',
                timeout: 5000,
            });
        }
        setOpenModal(false);
    };
    const filteredData = useMemo(
        () => filterByFields({
            data,
            query: searchValue,
            fields: ['name', 'description'],
        }),
        [searchValue, data],
    );

    const handleChange = (value) => {
        setSearchValue(value);
    };

    const hasPermissions = filteredData && filteredData.length > 0;

    return (
        <Container>
            <RenderIf isTrue={isLoading || hasPermissions}>
                <Header>
                    <StyledText>
                        Create and manage Permissions for your applications.
                        Permissions can be assigned to roles and users.
                    </StyledText>
                    <ButtonGroup className="rainbow-m-left_medium" variant="shaded">
                        <FloatingSearchButtonIcon
                            id="permissions-search-button"
                            icon={<Filter />}
                            onChange={handleChange}
                            value={searchValue}
                        />
                        <ButtonIconTooltip
                            id="permission-add-button"
                            icon={<Plus />}
                            text="Create Permission"
                            onClick={() => setOpenModal(true)}
                        />
                    </ButtonGroup>
                </Header>
                <StyledTable data={filteredData} isLoading={isLoading} keyField="id" variant="listview">
                    <Column header="Name" field="name" width={250} component={AlignLeftContent} />
                    <Column header="Description" field="description" component={AlignLeftContent} />
                </StyledTable>
            </RenderIf>
            <RenderIf isTrue={!isLoading && !hasPermissions}>
                <Message
                    className="rainbow-m-bottom_xx-large"
                    icon={<MessageIcon />}
                    title="Start creating and managing Permissions for your applications"
                    description="Permissions can be assigned to roles and users."
                    action={(
                        <CreateButton
                            variant="brand"
                            label="Create Permission"
                            onClick={() => setOpenModal(true)}
                        />
                    )}
                />
            </RenderIf>
            <UniversalFormModal
                id="create-permission-modal"
                title="Create Permission"
                isOpen={isOpenModal}
                onRequestClose={() => setOpenModal(false)}
                fields={PermissionFields}
                submitButtonLabel="Create"
                onSubmit={createPermission}
            />
        </Container>
    );
}
