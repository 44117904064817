import PropTypes from 'prop-types';
import { RadioGroup } from 'react-rainbow-components';
import { RecordSection } from '@rainbow-modules/record';
import { authOptions } from '../../../constants';

const Authentication = ({ trigger }) => {
    const { authenticated } = trigger;
    const radioGroupValue = authenticated ? 'authenticated' : 'unauthenticated';

    const newOptions = authOptions.map((item) => {
        if (item.value !== radioGroupValue) {
            return { ...item, disabled: true };
        }
        return item;
    });

    return (
        <RecordSection label="Authentication">
            <RadioGroup options={newOptions} value={radioGroupValue} />
        </RecordSection>
    );
};

Authentication.propTypes = {
    trigger: PropTypes.string,
};

Authentication.defaultProps = {
    trigger: undefined,
};

export default Authentication;
