import styled from 'styled-components';
import { Badge, Spinner } from 'react-rainbow-components';
import {
    WHITE, LIGHT_GRAY, GRAY, YELLOW, GREEN, RED,
} from '../../colors';

export const StyledSpinner = styled(Spinner)`
    left: 12px;
    z-index: unset;
`;

export const StyledBadge = styled(Badge)`
    background: ${LIGHT_GRAY};
    color: ${GRAY};
    position: relative;

    ${(props) => props.value === 'CREATED'
        && `
            color: ${WHITE};
            background: ${props.theme.rainbow.palette.brand.main};
        `};

    ${(props) => props.value === 'BUILDING'
        && `
            background: ${YELLOW};
            color: ${WHITE};
            padding-left: 26px;
        `};

    ${(props) => props.value === 'INSTALLING'
        && `
            background: ${YELLOW};
            color: ${WHITE};
            padding-left: 26px;
        `};

    ${(props) => props.value === 'ACTIVE'
        && `
            background: ${GREEN};
            color: ${WHITE};
        `};

    ${(props) => props.value === 'DEPLOY_IN_PROGRESS'
        && `
            background: ${YELLOW};
            color: ${WHITE};
            padding-left: 26px;
        `};

    ${(props) => props.value === 'DELETE_IN_PROGRESS'
        && `
            background: ${RED};
            color: ${WHITE};
        `};

    ${(props) => props.value === 'CLOUD_FUNCTION_STATUS_UNSPECIFIED'
        && `
            background: ${LIGHT_GRAY};
            color: ${GRAY};
        `};

    ${(props) => props.value === 'OFFLINE'
        && `
            color: ${WHITE};
            background: ${props.theme.rainbow.palette.text.label};
        `};

    ${(props) => props.value === 'UNKNOWN'
        && `
            background: ${LIGHT_GRAY};
            color: ${GRAY};
        `};
`;
