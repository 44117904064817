import styled from 'styled-components';

const StyledValue = styled.span`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 5px 0 0;

    ${(props) => props.children === 'None' && `
        font-style: italic;
        color: ${props.theme.rainbow.palette.text.header};
    `}
`;

export default StyledValue;
