/* eslint-disable react/prop-types */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, RenderIf } from 'react-rainbow-components';
import {
    FunctionsLabel,
    StatusColumnContainer,
    Row,
    FunctionsListContainer,
    FunctionItem,
    FunctionItemDivider,
    FunctionDeploySuccessStatus,
    FunctionDeployErrorStatus,
    FunctionDeployPendingStatus,
    FunctionDeployRunningStatus,
    StyledQuestionIcon,
    FunctionItemLabel,
    StyledBadge,
    StyledChevronRight,
    VerticalDivider,
    RunningStatusSpinner,
} from '../styled';
import getDeploymentHistoryStatus from '../../../../../../helpers/getDeploymentHistoryStatus';
import statusLabelsMap from '../statusLabelsMap';
import Context from '../context';

const statusFunctionDeployIconMap = {
    pending: FunctionDeployPendingStatus,
    inProgress: () => <FunctionDeployRunningStatus variant="brand" type="arc" size="xx-small" />,
    success: FunctionDeploySuccessStatus,
    error: FunctionDeployErrorStatus,
};

const FunctionDeployStatus = ({ status }) => {
    const FunctionCurrentStatus = statusFunctionDeployIconMap[status] || StyledQuestionIcon;
    return (<FunctionCurrentStatus />);
};

const DeploymentHistoryFunctionsList = ({ deploymentHistory }) => {
    const { installationsSummary = {} } = deploymentHistory;
    const functions = Object.values(installationsSummary)
        .sort((a, b) => a.installationName.localeCompare(b.installationName));

    return (
        <FunctionsListContainer>
            <VerticalDivider />
            <div>
                {
                    functions.map((functionDeploy) => {
                        const { installationName, installationRegion, status } = functionDeploy;
                        return (
                            <FunctionItem>
                                <FunctionItemDivider />
                                <FunctionDeployStatus status={status} />
                                <FunctionItemLabel>
                                    {installationName}
                                    (
                                    {installationRegion}
                                    )
                                </FunctionItemLabel>
                            </FunctionItem>
                        );
                    })
                }
            </div>
        </FunctionsListContainer>
    );
};

const StatusColumn = ({ row, onOpen }) => {
    const status = getDeploymentHistoryStatus(row);
    const deploymentId = useContext(Context);
    const isOpen = deploymentId === row.id;
    return (
        <StatusColumnContainer>
            <Row className="rainbow-m-top_x-small">
                <ButtonIcon
                    icon={<StyledChevronRight isOpen={isOpen} />}
                    size="small"
                    className="rainbow-m-right_small rainbow-m-left_small"
                    onClick={() => onOpen(row.id)}
                />
                <StyledBadge status={status}>
                    <RenderIf isTrue={status === 'inProgress'}>
                        <RunningStatusSpinner size="xx-small" variant="inverse" type="arc" />
                    </RenderIf>
                    {statusLabelsMap[status]}
                </StyledBadge>
            </Row>
            <RenderIf isTrue={isOpen}>
                <FunctionsLabel>Functions</FunctionsLabel>
                <DeploymentHistoryFunctionsList deploymentHistory={row} />
            </RenderIf>
        </StatusColumnContainer>
    );
};

StatusColumn.propTypes = {
    row: PropTypes.object.isRequired,
};

export default StatusColumn;
