import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button } from 'react-rainbow-components';
import { ChevronDown } from '@rainbow-modules/icons';
import InternalOverlay from 'react-rainbow-components/components/InternalOverlay';
import InternalDropdown from 'react-rainbow-components/components/InternalDropdown';
import { useUniqueIdentifier } from 'react-rainbow-components/libs/hooks';
import { useOutsideClick } from '@rainbow-modules/hooks';
import useFunction from '../../data/hooks/useFunction';
import VersionsPickerOptions from '../VersionsPickerOptions';
import { DropdownContainer, StyledButtonIcon, StyledButtonGroup } from './styled';

const VersionsButtonGroup = ({
    versions, onInstallFunction, onSelectVersion,
    selectedVersion, installButtonLabel,
}) => {
    const dropdownId = useUniqueIdentifier('dropdown');
    const [isOpen, setIsOpen] = useState(false);
    const triggerRef = useRef();
    const dropdownRef = useRef();
    const searchRef = useRef();
    const { functionId, workspace } = useParams();
    const [lastVersionFunction] = useFunction(functionId, workspace);

    useOutsideClick(
        dropdownRef,
        (event) => {
            if (triggerRef.current.htmlElementRef.current.contains(event.target)) return;
            setIsOpen(false);
        },
        isOpen,
    );

    const handleChange = (value) => {
        onSelectVersion(value);
        setIsOpen(false);
    };

    const handleKeyDown = (event) => {
        const { keyCode } = event;
        if (keyCode === 27) {
            setIsOpen(false);
            triggerRef.current.focus();
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div onKeyDown={handleKeyDown}>
            <StyledButtonGroup>
                <Button
                    label={installButtonLabel}
                    variant="brand"
                    onClick={onInstallFunction}
                />
                <StyledButtonIcon
                    variant="brand"
                    icon={<ChevronDown />}
                    ref={triggerRef}
                    onClick={() => setIsOpen(!isOpen)}
                    ariaControls={dropdownId}
                    id="show-function-versions"
                />
            </StyledButtonGroup>
            <InternalOverlay
                isVisible={isOpen}
                triggerElementRef={() => triggerRef.current.htmlElementRef}
                onOpened={() => searchRef.current.focus()}
            >
                <DropdownContainer ref={dropdownRef}>
                    <InternalDropdown
                        id={dropdownId}
                        value={selectedVersion}
                        onChange={handleChange}
                        enableSearch
                        ref={searchRef}
                    >
                        <VersionsPickerOptions
                            versions={versions}
                            lastVersionFunction={lastVersionFunction}
                        />
                    </InternalDropdown>
                </DropdownContainer>
            </InternalOverlay>
        </div>
    );
};

VersionsButtonGroup.propTypes = {
    versions: PropTypes.array.isRequired,
    onSelectVersion: PropTypes.func.isRequired,
    onInstallFunction: PropTypes.func.isRequired,
    installButtonLabel: PropTypes.string.isRequired,
    selectedVersion: PropTypes.shape({
        icon: PropTypes.node,
        label: PropTypes.node,
        name: PropTypes.string,
    }).isRequired,
};

export default VersionsButtonGroup;
