import styled from 'styled-components';

export const FailedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const FailedInfo = styled.div`
    margin-top: 20px;
`;

export const FailedTitle = styled.h2`
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: center;
`;

export const Highlight = styled.span`
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 24px 8px 0 8px;
`;

export const HighlightDescription = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 24px 4px 0 4px;
`;

export const FailedDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 16px;
    text-align: center;
    margin-top: 8px;
`;
