import PropTypes from 'prop-types';
import {
    StyledIcon, TriggerContent, TriggerDescription, TriggerTitle, IconContainer,
} from './styled';

const TriggerComponent = ({ value }) => {
    const { title, description, icon } = value;
    return (
        <div className="rainbow-flex rainbow-m-horizontal_small rainbow-p-vertical_small">
            <IconContainer>
                <StyledIcon as={icon} />
            </IconContainer>
            <TriggerContent>
                <TriggerTitle>
                    {title}
                </TriggerTitle>
                <TriggerDescription>
                    {description}
                </TriggerDescription>
            </TriggerContent>
        </div>
    );
};

TriggerComponent.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default TriggerComponent;
