import PropTypes from 'prop-types';
import { RenderIf, LoadingShape } from 'react-rainbow-components';
import BackButton from '../../../components/BackButton';
import StatusBadge from '../../../components/StatusBadge';

import {
    StyledHeader,
    FunctionInfoContainer,
    DetailsContainer,
    FunctionName,
    FunctionDescription,
    LoadingContainer,
    NameContainer,
    LeftContent,
} from './styled';
import HeaderActions from './headerActions';

export default function Header(props) {
    const {
        projectId,
        functionId,
        icon,
        iconUrl,
        type,
        isLoading,
        region,
        name,
        status,
        description,
        environmentVariables,
        isPreview,
    } = props;
    const installedFunctionsPath = `/app/project/${projectId}/functions/installed`;
    return (
        <>
            <StyledHeader>
                <LeftContent>
                    <BackButton to={installedFunctionsPath} />
                    <FunctionInfoContainer>
                        {icon}
                        <DetailsContainer>
                            <RenderIf isTrue={!isLoading}>
                                <NameContainer>
                                    <FunctionName>{name}</FunctionName>
                                    <StatusBadge className="rainbow-m-left_large" value={status} />
                                </NameContainer>
                                <FunctionDescription>{description}</FunctionDescription>
                            </RenderIf>
                            <RenderIf isTrue={isLoading}>
                                <LoadingContainer width="300px" height="26px">
                                    <LoadingShape />
                                </LoadingContainer>
                                <LoadingContainer
                                    width="500px"
                                    height="16px"
                                    className="rainbow-m-top_x-small"
                                >
                                    <LoadingShape />
                                </LoadingContainer>
                            </RenderIf>
                        </DetailsContainer>
                    </FunctionInfoContainer>
                </LeftContent>
                <RenderIf isTrue={!isLoading}>
                    <HeaderActions
                        projectId={projectId}
                        region={region}
                        functionId={functionId}
                        functionName={name}
                        functionType={type}
                        description={description}
                        environmentVariables={environmentVariables}
                        status={status}
                        iconUrl={iconUrl}
                        isPreview={isPreview}
                    />
                </RenderIf>
            </StyledHeader>
        </>
    );
}

Header.propTypes = {
    projectId: PropTypes.string,
    functionId: PropTypes.string,
    iconUrl: PropTypes.string,
    type: PropTypes.string,
    isLoading: PropTypes.bool,
    isPreview: PropTypes.bool,
    name: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    region: PropTypes.string,
    environmentVariables: PropTypes.object,
    icon: PropTypes.node,
};

Header.defaultProps = {
    projectId: undefined,
    functionId: undefined,
    iconUrl: undefined,
    type: undefined,
    isLoading: false,
    isPreview: false,
    name: undefined,
    status: undefined,
    description: undefined,
    region: undefined,
    environmentVariables: undefined,
    icon: undefined,
};
