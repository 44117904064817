import { CheckboxGroup } from 'react-rainbow-components';
import { useReduxForm } from 'react-rainbow-components/libs/hooks';

const MultiSelectInput = (props) => {
    const {
        value: { value }, onChange, label, className, options, required, error,
    } = useReduxForm(props);

    const newOptions = options?.map((item) => ({
        label: item,
        value: item,
    }));

    const handleChange = (params) => {
        if (params?.length > 0) {
            return onChange({ value: params, type: 'multiselect' });
        }
        return onChange(undefined);
    };

    return (
        <CheckboxGroup
            required={required}
            error={error}
            label={label}
            options={newOptions}
            value={value}
            onChange={handleChange}
            className={className}
        />
    );
};

export default MultiSelectInput;
