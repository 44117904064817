/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Warning } from '@rainbow-modules/icons';

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const MessageTitle = styled.h3`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: center;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const MessageDescription = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-align: center;
    margin-top: 8px;
`;

export const StyledInstallationError = styled(Warning)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 12px;
    width: 64px;
    height: 64px;
`;
