import PropTypes from 'prop-types';
import BuildPickerOptions from './BuildPickerOptions';
import StyledPicklist from './styled';

const getBuildPicklistValue = (build) => {
    if (build) {
        return { name: build?.buildId, label: build?.buildId };
    }
    return undefined;
};

const BuildPicker = ({
    builds, onChange, value, isLoading,
}) => (
    <StyledPicklist
        id="builds-picker"
        isLoading={isLoading}
        onChange={(selectedBuildOption) => onChange(selectedBuildOption.value)}
        value={getBuildPicklistValue(value)}
        placeholder="Select Build"
        enableSearch
    >
        <BuildPickerOptions builds={builds} />
    </StyledPicklist>
);

BuildPicker.propTypes = {
    builds: PropTypes.array.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

BuildPicker.defaultProps = {
    isLoading: false,
};

export default BuildPicker;
