import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'react-rainbow-components';
import { CheckboxColumnContainer } from '../styled';

const CheckboxColumn = ({
    row, selectedRows, onSelect, onDeselect,
}) => {
    const { canImport } = row;

    const isSelected = useMemo(() => selectedRows && selectedRows.some(
        ({ name, workspace }) => row.name === name && row.workspace === workspace,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [selectedRows]);

    const handleMouseDown = (event) => {
        if (event.shiftKey) {
            event.preventDefault();
        }
    };

    const handleClick = (event) => {
        if (event.target.checked) { return onSelect(row); }
        return onDeselect(row);
    };

    return (
        <CheckboxColumnContainer role="presentation" onMouseDown={handleMouseDown}>
            <Input
                label="select function"
                hideLabel
                type="checkbox"
                checked={isSelected}
                tabIndex="-1"
                disabled={!canImport}
                onClick={handleClick}
            />
        </CheckboxColumnContainer>
    );
};

CheckboxColumn.propTypes = {
    row: PropTypes.object.isRequired,
    selectedRows: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
};

CheckboxColumn.defaultProps = {
    selectedRows: [],
    onSelect: () => {},
    onDeselect: () => {},
};

export default CheckboxColumn;
