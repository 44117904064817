import {
    useDoc, useCurrentUser, useHttpAuthQuery,
} from '@rainbow-modules/firebase-hooks';
import { useEffect } from 'react';

const usePurchasedFunction = ({
    functionId, workspace, version, buildId,
}) => {
    const { uid } = useCurrentUser();
    const [purchasedFunctionData, isLoadingPurchasedFunctionData] = useDoc({
        path: `users/${uid}/functions/${workspace}:${functionId}`,
        flat: true,
    });
    let query = version ? `?version=${version}` : '';
    if (query && buildId) {
        query = `${query}&buildId=${buildId}`;
    }
    const { data: canAccess, isLoading: isLoadingFunctionAccess, refetch } = useHttpAuthQuery({
        functionName: 'functions-api',
        pathname: `workspaces/${workspace}/functions/${functionId}/canAccess${query}`,
    });
    useEffect(() => {
        refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchasedFunctionData]);
    const isLoading = isLoadingPurchasedFunctionData || isLoadingFunctionAccess;
    return [canAccess, isLoading];
};

export default usePurchasedFunction;
