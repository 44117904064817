import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { Icon } from './styled';

const PolicyIcon = (props) => {
    const { url } = props;

    return (
        <>
            <RenderIf isTrue={url}>
                <Icon as="img" src={url} />
            </RenderIf>
            <RenderIf isTrue={!url}>
                <Icon />
            </RenderIf>
        </>
    );
};

export default PolicyIcon;

PolicyIcon.propTypes = {
    url: PropTypes.string,
};

PolicyIcon.defaultProps = {
    url: undefined,
};
