import styled from 'styled-components';
import { MoneyBorder } from '@rainbow-modules/icons';
import { HelpText } from 'react-rainbow-components';

export const StyledPaymentIcon = styled(MoneyBorder)`
    color: ${(props) => props.theme.rainbow.palette.border.disabled};
    width: 64px;
    height: 64px;
    margin-bottom: 16px;
`;

export const Insights = styled.div`
    display: flex;
    margin-top: 12px;
    margin-left: 8px;
`;

export const CardContainer = styled.div`
    background: ${(props) => props.theme.rainbow.palette.background.main};
    padding: 16px 20px;
    border-radius: 14px;
    border: solid 1px ${(props) => props.theme.rainbow.palette.border.disabled};
    width: 40%;
    margin-right: 12px;
`;

export const Label = styled.h3`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 4px;
`;

export const Value = styled.h2`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 26px;
`;

export const Link = styled.a`
    display: flex;
    font-size: 15px;
    text-decoration: underline;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    align-items: center;
    margin-top: 12px;

    :hover, :focus {
        text-decoration: underline;
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }

    svg {
        width: 14px;
        height: 14px;
    }
`;

export const Date = styled.h4`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-top: 12px;
`;

export const StyledHelpText = styled(HelpText)`
    width: 15px;
    height: 15px;
    margin-left: 6px;

    svg {
        width: 15px;
        height: 15px;
        margin-bottom: 4px;
    }
`;
