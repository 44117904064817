const getUserRoleInWorkspace = ({ workspace, uid }) => {
    const { owners = [], editors = [], viewers = [] } = workspace;
    if (owners.includes(uid)) {
        return 'owner';
    }
    if (editors.includes(uid)) {
        return 'editor';
    }
    if (viewers.includes(uid)) {
        return 'viewer';
    }
    return '';
};

export default getUserRoleInWorkspace;
