import {
    UsersFilled, QuestionFilled,
} from '@rainbow-modules/icons';
import Http from '../components/Icons/http';
import PubSub from '../components/Icons/pubSub';
import Firestore from '../components/Icons/firestore';
import GoogleAnalitics from '../components/Icons/googleAnalitics';
import RemoteConfig from '../components/Icons/remoteConfig';
import GoogleCloudStorage from '../components/Icons/googleCloudStorage';
import RealtimeDatabase from '../components/Icons/realtimeDatabase';

const getTriggerTitle = (eventTrigger) => {
    const eventTypeItems = eventTrigger.eventType.split('/');
    return eventTypeItems[eventTypeItems.length - 1];
};

const getTriggerDescription = (eventTrigger) => {
    const resource = eventTrigger.resource.split('/');
    const lastItemResource = resource[resource.length - 1];
    const triggerMap = {
        'pubsub.googleapis.com': lastItemResource,
        'storage.googleapis.com': lastItemResource,
        'firestore.googleapis.com': resource.slice(resource.indexOf('documents') + 1).join('/'),
        'app-measurement.com': lastItemResource,
        'firebaseauth.googleapis.com': resource.slice(2).join('/'),
        'firebaseio.com': resource.slice(resource.indexOf('refs') + 1).join('/'),
        'firebaseremoteconfig.googleapis.com': resource.slice(2).join('/'),
    };
    return triggerMap[eventTrigger.service] || eventTrigger.resource;
};

const serviceToIconMap = {
    'pubsub.googleapis.com': PubSub,
    'storage.googleapis.com': GoogleCloudStorage,
    'firestore.googleapis.com': Firestore,
    'app-measurement.com': GoogleAnalitics,
    'firebaseauth.googleapis.com': UsersFilled,
    'firebaseio.com': RealtimeDatabase,
    'firebaseremoteconfig.googleapis.com': RemoteConfig,
};

const getTriggerIcon = (eventTrigger) => {
    const mappedIcon = serviceToIconMap[eventTrigger.service];
    return mappedIcon || QuestionFilled;
};

const getFunctionTrigger = (item) => {
    if (item) {
        if (item.httpsTrigger) {
            return {
                title: 'Request', description: item.httpsTrigger.url, icon: Http,
            };
        }
        const title = getTriggerTitle(item.eventTrigger);
        const description = getTriggerDescription(item.eventTrigger);
        const icon = getTriggerIcon(item.eventTrigger);

        return {
            title,
            description,
            icon,
        };
    }
    return {};
};

export default getFunctionTrigger;
