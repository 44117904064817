import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import { Step } from '@rainbow-modules/content';
import useDownloadFunctionBundle from '../../../../../../data/hooks/useDownloadFunctionBundle';

const DownloadZip = ({ functionData, stepNumber }) => {
    const { name, workspace, version } = functionData;
    const {
        isDownloading,
        download,
    } = useDownloadFunctionBundle({ functionId: name, workspace, version });
    return (
        <Step
            number={stepNumber}
            header="Download the ZIP that contains all the elements of the function."
            className="rainbow-m-top_xx-large"
        >
            <Button id="download-zip-button" isLoading={isDownloading} label="Download ZIP" variant="brand" onClick={download} />
        </Step>
    );
};

DownloadZip.propTypes = {
    stepNumber: PropTypes.number,
    functionData: PropTypes.object,
};

DownloadZip.defaultProps = {
    stepNumber: undefined,
    functionData: undefined,
};

export default DownloadZip;
