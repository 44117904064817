import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import BackButton from '../../../../../components/BackButton';
import { Title, Description } from '../styled';
import EnvConfig from './EnvConfig';
import {
    Container, Content, RightContent, StyledSpinner, StyledBadge,
} from './styled';
import FlowDiagram from './FlowDiagram';
import useCurrentUserRoleInWorkspace from '../../../../../data/hooks/useCurrentUserRoleInWorkspace';

export default function FlowDetails() {
    const [selectedProject, setSelectedProject] = useState({});
    const { workspace, deploymentId } = useParams();
    const [currentUserRole, isLoadingCurrentUserRole] = useCurrentUserRoleInWorkspace({
        workspace,
    });
    const currentUserIsViewer = currentUserRole === 'viewer';
    const [deployment, isLoading] = useDoc({
        path: `workspaces/${workspace}/deployments/${deploymentId}`,
        flat: true,
    });
    useEffect(() => {
        const { index } = selectedProject;
        const thereIsAProjectSelected = typeof index === 'number';
        if (thereIsAProjectSelected) {
            setSelectedProject({
                index,
                name: index === -1
                    ? deployment.baseProject.name
                    : deployment.targetProjects[index].name,
                projectId: index === -1
                    ? deployment.baseProject.projectId
                    : deployment.targetProjects[index].projectId,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployment]);
    useEffect(() => {
        if (!isLoading) {
            setSelectedProject({
                index: -1,
                name: deployment.baseProject.name,
                projectId: deployment.baseProject.projectId,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    if (isLoading || isLoadingCurrentUserRole) {
        return <StyledSpinner type="arc" size="x-large" variant="brand" />;
    }

    return (
        <Container>
            <BackButton
                className="rainbow-m-bottom_small"
                label="Back to deployments page"
                to={`/app/developer/workspaces/${workspace}/deployments/flows`}
            />
            <Title>
                {deployment.name}
                <StyledBadge label={deployment?.status} />
            </Title>
            <Description>
                {deployment.description}
            </Description>
            <Content>
                <FlowDiagram
                    deployment={deployment}
                    selectedProject={selectedProject}
                    onSelectProject={setSelectedProject}
                    disabled={currentUserIsViewer}
                />
                <RightContent>
                    <EnvConfig
                        workspace={workspace}
                        selectedProject={selectedProject}
                        projects={deployment.targetProjects}
                        disabled={currentUserIsViewer}
                    />
                </RightContent>
            </Content>
        </Container>
    );
}
