import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabset, Tab, RenderIf } from 'react-rainbow-components';
import { Avatar } from '@rainbow-modules/icons';
import {
    RecordHeader, RecordPrimaryDetails, RecordField, RecordSection,
} from '@rainbow-modules/record';
import ReactJson from 'react-json-view';
import useCallableQuery from '../../../data/hooks/useCallableQuery';
import getUserProviders from '../../../helpers/getUserProviders';
import MetadataLoading from './metadataLoading';
import Details from './tabs/details';
import Roles from './tabs/roles';
import Permissions from './tabs/permissions';
import CopyUidToClipboard from './copyUidToClipboard';
import DescriptionInfo from '../descriptionInfo';
import {
    Container,
    Content,
    MetadataTitle,
    MetadataSubtitle,
    StyledUsersFilled,
    StyledAvatar,
} from './styled';

const tabContentMap = {
    details: Details,
    roles: Roles,
    permissions: Permissions,
};

export default function UserDetails() {
    const { projectId, userId } = useParams();
    const { data = {}, isLoading } = useCallableQuery(
        ['users-getUser', projectId, userId],
        { projectId, uid: userId },
    );
    const {
        uid,
        email,
        phoneNumber,
        displayName,
        metadata,
        providerData,
        customClaims,
        isAnonymous,
    } = data;
    const { creationTime, lastSignInTime } = metadata || {};
    const providers = getUserProviders(providerData, isAnonymous);
    const [activeTab, setActiveTab] = useState('details');

    const handleOnSelect = (event, value) => {
        setActiveTab(value);
    };

    const TabContent = tabContentMap[activeTab];

    return (
        <Container>
            <Content>
                <RecordHeader
                    className="rainbow-m-bottom_large"
                    label="USERS"
                    isLoading={isLoading}
                    description={(
                        <DescriptionInfo
                            projectId={projectId}
                            description={email || phoneNumber || uid}
                        />
                    )}
                    icon={<StyledUsersFilled />}
                >
                    <RecordPrimaryDetails>
                        <RecordField label="uid" value={uid} isLoading={isLoading} component={CopyUidToClipboard} />
                        <RenderIf isTrue={displayName}>
                            <StyledAvatar size="medium" icon={<Avatar />} backgroundColor="transparent" />
                            <RecordField label="displayName" value={displayName} isLoading={isLoading} />
                        </RenderIf>
                        <RenderIf isTrue={email}>
                            <RecordField label="email" value={email} isLoading={isLoading} />
                        </RenderIf>
                        <RenderIf isTrue={phoneNumber}>
                            <RecordField label="phoneNumber" value={phoneNumber} isLoading={isLoading} />
                        </RenderIf>
                    </RecordPrimaryDetails>
                </RecordHeader>
                <Tabset variant="line" className="rainbow-m-bottom_large" onSelect={handleOnSelect} activeTabName={activeTab}>
                    <Tab
                        name="details"
                        label="Details"
                    />
                    <Tab
                        name="roles"
                        label="User Roles"
                    />
                    <Tab
                        name="permissions"
                        label="User Permissions"
                    />
                </Tabset>
                <TabContent
                    uid={uid}
                    displayName={displayName}
                    email={email}
                    creationTime={creationTime}
                    lastSignInTime={lastSignInTime}
                    providers={providers}
                    isLoading={isLoading}
                    customClaims={customClaims}
                />
                <RecordSection label="Metadata" className="rainbow-m-top_large">
                    <RenderIf isTrue={isLoading}>
                        <MetadataLoading />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        <MetadataTitle>
                            user_claims
                        </MetadataTitle>
                        <MetadataSubtitle>
                            <ReactJson src={customClaims} theme="hopscotch" collapsed={false} name={false} />
                        </MetadataSubtitle>
                    </RenderIf>
                </RecordSection>
            </Content>
        </Container>
    );
}
