/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 28px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 0 8px 8px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 0 8px;
`;
