import semver from 'semver';

export default function compareVersions(versionA, versionB) {
    if (semver.valid(versionB)) {
        if (semver.gt(versionA, versionB)) return 1;
        if (semver.lt(versionA, versionB)) return -1;
        return 0;
    }
    return 1;
}
