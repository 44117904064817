import PropTypes from 'prop-types';
import { Avatar } from 'react-rainbow-components';
import { MemberColumnContainer, Email, Name } from './styled';

const MemberColumn = ({ row }) => {
    const { displayName, email, photoURL } = row;
    return (
        <MemberColumnContainer>
            <Avatar src={photoURL} className="rainbow-m-right_x-small" />
            <div>
                <Name>{displayName}</Name>
                <Email>{email}</Email>
            </div>
        </MemberColumnContainer>
    );
};

MemberColumn.propTypes = {
    row: PropTypes.object.isRequired,
};

export default MemberColumn;
