import { useState, useMemo, useEffect } from 'react';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { confirmModal } from '@rainbow-modules/app';
import { filterByFields } from '@rainbow-modules/listview';
import { Button, Column, RenderIf } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import GlobalSpinner from '../../../../components/GlobalSpinner';
import ServiceLimiteInfo from '../../../../components/ServiceLimiteInfo';
import UpgradePlanButton from '../../../../components/UpgradePlanButton';
import useCallableQuery from '../../../../data/hooks/useCallableQuery';
import useCurrentUserRoleInWorkspace from '../../../../data/hooks/useCurrentUserRoleInWorkspace';
import useMutationFlow from '../../../../data/hooks/useMutationFlow';
import usePlanServiceCount from '../../../../data/hooks/usePlanServiceCount';
import useUpgradeToast from '../../../../data/hooks/useUpgradeToast';
import firebaseCallable from '../../../../data/services/firebaseCallable';
import logEvent from '../../../../analytics/logEvent';
import events from '../../../../analytics/events';
import MemberColumn from './memberColumn';
import RoleColumn from './roleColumn';
import ActionsColumn from './actionsColumn';
import AddMemberForm from './addMemberForm';
import useWorkspaceOwnership from '../../../../data/hooks/useWorkspaceOwnership';
import {
    Container,
    Header,
    Title,
    Subtitle,
    Row,
    StyledInputSearch,
    StyledTable,
    Description,
    BoldText,
} from './styled';

export default function Settings() {
    const [isOpenModal, setOpenModal] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const { workspace } = useParams();
    const [isWorkspaceOwner] = useWorkspaceOwnership(workspace);

    const {
        isLoading: isLoadingServiceCount, count, limit, hasReachedLimit, product, hasService,
    } = usePlanServiceCount({ serviceName: 'teamMembers' });
    useUpgradeToast({
        hasReachedLimit,
        serviceName: 'teamMembers',
        isLoadingServiceCount,
    });

    const { data = {}, isLoading: isLoadingMembers, refetch } = useCallableQuery('workspaces-listMembers', {
        workspace,
    });

    const [currentUserRole, isLoadingCurrentUserRole] = useCurrentUserRoleInWorkspace({
        workspace,
    });
    const currentUserIsViewer = currentUserRole === 'viewer';

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => refetch(), [workspace]);

    const filteredData = useMemo(
        () => filterByFields({
            data,
            query: searchValue,
            fields: ['displayName', 'email'],
        }),
        [searchValue, data],
    );

    const { mutate: addMember } = useMutationFlow({
        mutation: (values) => firebaseCallable('workspaces-addMember', values),
        successMessage: 'The member was added successfully',
        onSuccess: () => {
            logEvent(events.workspaces.ADD_MEMBER);
            refetch();
            return setOpenModal(false);
        },
    });

    const { mutate: removeMember } = useMutationFlow({
        mutation: (values) => firebaseCallable('workspaces-removeMember', values),
        successMessage: 'The member was removed successfully',
        onSuccess: () => {
            logEvent(events.workspaces.REMOVE_MEMBER);
            refetch();
        },
    });

    const handleRemoveMember = async (values) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Remove Member',
            okButtonLabel: 'Remove',
            question: 'Are you sure you want to remove this member?',
        });
        if (result) {
            removeMember(values);
        }
    };

    if (isLoadingCurrentUserRole) {
        return <GlobalSpinner />;
    }

    return (
        <Container>
            <Header>
                <Title>Settings</Title>
                <Row className="rainbow-m-right_large">
                    <div>
                        <Subtitle>
                            Members and Permissions
                        </Subtitle>
                        <Description>
                            Manage permissions and invite people to your workspace.
                            {' '}
                            <RenderIf isTrue={!hasService}>
                                <BoldText>Upgrade your plan to access.</BoldText>
                            </RenderIf>
                        </Description>
                    </div>
                    <RenderIf isTrue={isWorkspaceOwner && !isLoadingServiceCount && hasService}>
                        <ServiceLimiteInfo
                            label={`${count} of ${limit} Members`}
                            helpTextTitle="Team Members on your current plan"
                            featureUsageName="Members"
                            featureUsageSummaryCounter={`${count} of ${limit} members`}
                            featureUsageCounter={`${count} members`}
                            upgradeText="If you need to add more members you have to upgrade your plan."
                            limit={limit}
                            count={count}
                            product={product}
                        />
                    </RenderIf>
                </Row>
                <Row className="rainbow-m-right_large">
                    <StyledInputSearch
                        id="members-inputsearch"
                        variant="realtime"
                        value={searchValue}
                        onChange={setSearchValue}
                        placeholder="Search member"
                    />
                    <RenderIf isTrue={!isLoadingServiceCount && hasService}>
                        <Button
                            id="add-member-button"
                            label="Add Member"
                            variant="brand"
                            onClick={() => setOpenModal(true)}
                            disabled={currentUserIsViewer}
                        />
                    </RenderIf>
                    <RenderIf isTrue={!isLoadingServiceCount && !hasService}>
                        <UpgradePlanButton />
                    </RenderIf>
                </Row>
            </Header>
            <StyledTable
                id="workspace-members-table"
                keyField="uid"
                data={filteredData}
                isLoading={isLoadingMembers}
                variant="listview"
                className="rainbow-m-right_large rainbow-m-left_large"
            >
                <Column
                    header="Member"
                    field="email"
                    component={MemberColumn}
                />
                <Column
                    header="Role"
                    field="role"
                    component={RoleColumn}
                />
                <Column
                    component={ActionsColumn}
                    onRemove={handleRemoveMember}
                    width={60}
                    currentUserIsViewer={currentUserIsViewer}
                />
            </StyledTable>
            <UniversalFormModal
                id="add-member-modal"
                title="Add Member"
                isOpen={isOpenModal}
                onRequestClose={() => setOpenModal(false)}
                fields={AddMemberForm}
                submitButtonLabel="Add Member"
                onSubmit={(values) => addMember({
                    ...values,
                    workspace,
                })}
            />
        </Container>
    );
}
