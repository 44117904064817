import { useEffect } from 'react';
import { useStorageState, useOpenModal } from '@rainbow-modules/hooks';
import { UPGRADE_TOAST } from '../../constants';

const textMap = {
    projects: {
        title: 'Your projects setup has been frozen.',
        description: 'You have exceeded the number of projects you can have active in your current plan. To activate more project you need to upgrade your plan.',
    },
    workspaces: {
        title: 'Your workspaces creation has been frozen.',
        description: 'You have exceeded the number of workspaces you can have in your current plan. To have more workspaces you need to upgrade your plan.',
    },
    pushes: {
        title: 'Your builds submission has been frozen.',
        description: 'You have exceeded the number of builds you can push by month in your current plan. To push more builds you need to upgrade your plan',
    },
    teamMembers: {
        title: 'Your team members has been frozen.',
        description: 'You have exceeded the number of members you can have in your current plan. To have more members you need to upgrade your plan',
    },
};

const useUpgradeToast = ({ hasReachedLimit, serviceName, isLoadingServiceCount }) => {
    const [state, setState] = useStorageState({ key: `can-show-upgrade-toast-${serviceName}` });
    const [open, close] = useOpenModal(UPGRADE_TOAST);

    useEffect(() => {
        if (!isLoadingServiceCount && !hasReachedLimit) {
            setState(true);
        }
        if (hasReachedLimit === true && state !== false) {
            open({
                serviceName,
                ...textMap[serviceName],
            });
        }
        return () => close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasReachedLimit, serviceName, state]);
};

export default useUpgradeToast;
