import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { RecordSection } from '@rainbow-modules/record';
import TestFailed from './testFailed';
import TestGroup from './testGroup';
import SummaryRow from './summaryRow';
import getMessageForStatus from '../helpers/getMessageForStatus';
import getVariantForStatus from '../helpers/getVariantForStatus';
import { IN_PROGRESS } from '../../status';
import { StyledInfoCard } from '../styled';
import {
    Container,
    TestHeader,
} from './styled';

const getTotals = (results = []) => results.reduce((acc, item) => {
    const {
        numFailedTestSuites = 0,
        numFailedTests = 0,
        numPassedTestSuites = 0,
        numPassedTests = 0,
        numTotalTestSuites = 0,
        numTotalTests = 0,
    } = item?.tests?.results;
    return {
        numFailedTestSuites: acc.numFailedTestSuites + numFailedTestSuites,
        numFailedTests: acc.numFailedTests + numFailedTests,
        numPassedTestSuites: acc.numPassedTestSuites + numPassedTestSuites,
        numPassedTests: acc.numPassedTests + numPassedTests,
        numTotalTestSuites: acc.numTotalTestSuites + numTotalTestSuites,
        numTotalTests: acc.numTotalTests + numTotalTests,
    };
}, {
    numFailedTestSuites: 0,
    numFailedTests: 0,
    numPassedTestSuites: 0,
    numPassedTests: 0,
    numTotalTestSuites: 0,
    numTotalTests: 0,
});

const hasTests = (results = []) => results.some((item) => {
    const { numTotalTests, testResults } = item?.tests?.results;
    return numTotalTests > 0 && Array.isArray(testResults) && testResults.length > 0;
});

export default function TestCheck({ results, status, conclusion }) {
    const {
        numFailedTestSuites,
        numFailedTests,
        numPassedTestSuites,
        numPassedTests,
        numTotalTestSuites,
        numTotalTests,
    } = getTotals(results);
    const message = getMessageForStatus({ status, conclusion });
    const messageStatus = getVariantForStatus({ status, conclusion });
    const hasFailingTests = numFailedTests > 0;

    return (
        <>
            <RenderIf isTrue={status === IN_PROGRESS}>
                <StyledInfoCard
                    variant={messageStatus}
                    label={message}
                    size="small"
                    hideIcon
                />
            </RenderIf>
            <RenderIf isTrue={status !== IN_PROGRESS}>
                <Container>
                    <TestHeader data-cy="test-header">
                        <SummaryRow
                            name="Test Suite"
                            numFailed={numFailedTestSuites}
                            numPassed={numPassedTestSuites}
                            numTotal={numTotalTestSuites}
                        />
                        <SummaryRow
                            name="Tests"
                            numFailed={numFailedTests}
                            numPassed={numPassedTests}
                            numTotal={numTotalTests}
                        />
                    </TestHeader>
                    <RenderIf isTrue={hasFailingTests}>
                        <RecordSection label="Failed Test" className="rainbow-m-horizontal_large rainbow-m-top_medium">
                            <TestFailed results={results} />
                        </RecordSection>
                    </RenderIf>
                    <RenderIf isTrue={hasTests(results)}>
                        <RecordSection label="All Test" className="rainbow-m-horizontal_large">
                            <TestGroup results={results} />
                        </RecordSection>
                    </RenderIf>
                </Container>
            </RenderIf>
        </>
    );
}

TestCheck.propTypes = {
    status: PropTypes.oneOf(['done', 'queued', 'in_progress']),
    conclusion: PropTypes.oneOf(['success', 'failed', 'skipped', 'cancelled']),
    results: PropTypes.array.isRequired,
};

TestCheck.defaultProps = {
    status: undefined,
    conclusion: undefined,
};
