import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import PermisionsLoading from './permissionsLoading';
import {
    ModalContent,
    ModalDescription,
    ProjectNameContainer,
    ProjectIcon,
    ProjectTitle,
    ProjectId,
    CheckItemContainer,
    CheckItemLabel,
    CheckItemDescription,
    CheckItemIconError,
    CheckItemIconDone,
    StyledRecordSection,
    UngrantedPermissionsLabel,
    Spacer,
} from './styled';

const descriptionMap = {
    'cloudfunctions.functions.create': 'Create new functions',
    'cloudfunctions.functions.update': 'Update existing functions',
    'cloudfunctions.functions.list': 'List functions',
    'cloudfunctions.functions.get': 'View functions, excluding IAM policies',
    'cloudfunctions.functions.delete': 'Delete functions',
    'cloudfunctions.functions.setIamPolicy': 'Update IAM policies associated with a function',
    'serviceusage.services.get': 'Ability to inspect service states',
    'serviceusage.services.enable': 'Ability to enable services',
    'iam.serviceAccounts.actAs': 'Ability to impersonate service account',
    'secretmanager.secrets.list': 'List project secrets',
    'secretmanager.versions.get': 'View secrets metadata',
    'secretmanager.versions.access': 'Access secrets value',
    'resourcemanager.projects.get': 'List projects details and resources',
    'firebaseauth.users.get': 'List existing users',
    'firebaseauth.users.update': 'Update users information',
};

// eslint-disable-next-line react/prop-types
const Description = ({ permission }) => {
    const description = descriptionMap[permission];
    return (
        <div>
            <CheckItemLabel>
                {permission}
            </CheckItemLabel>
            <RenderIf isTrue={description}>
                <CheckItemDescription>
                    {description}
                </CheckItemDescription>
            </RenderIf>
        </div>
    );
};

const GrantedPermissions = ({ data }) => data.map((permission) => (
    <CheckItemContainer key={permission}>
        <CheckItemIconDone />
        <Description permission={permission} />
    </CheckItemContainer>
));

const UngrantedPermissions = ({ data }) => data.map((permission) => (
    <CheckItemContainer key={permission}>
        <CheckItemIconError />
        <Description permission={permission} />
    </CheckItemContainer>
));

export default function ProjectPermissionsModal({
    projectId, projectName, isLoading, permissions,
}) {
    const { granted = [], ungranted = [] } = permissions || {};
    const hasUngrantedPermissions = Boolean(ungranted.length > 0);

    const name = projectName || projectId;
    const showProjectId = Boolean(projectName);

    return (
        <>
            <ModalDescription className="rainbow-m-right_large rainbow-m-left_large">
                Here is a list of the permissions needed to use the functions.store
                and all its functionality in this project.
                The permissions markerd with the red icon must be granted before continuing.
                Please add them and click the Check button again.
            </ModalDescription>
            <ModalContent>
                <StyledRecordSection
                    className="rainbow-flex"
                    label={(
                        <ProjectNameContainer className="rainbow-m-left_large">
                            <ProjectIcon />
                            <div>
                                <ProjectTitle>{name}</ProjectTitle>
                                <RenderIf isTrue={showProjectId}>
                                    <ProjectId>{projectId}</ProjectId>
                                </RenderIf>
                            </div>
                        </ProjectNameContainer>
                    )}
                    actions={(
                        <RenderIf isTrue={!isLoading && hasUngrantedPermissions}>
                            <UngrantedPermissionsLabel>
                                <CheckItemIconError />
                                <span>{`${ungranted.length} permissions failed`}</span>
                            </UngrantedPermissionsLabel>
                        </RenderIf>
                    )}
                >
                    <RenderIf isTrue={isLoading}>
                        <PermisionsLoading />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        <div className="rainbow-m-left_large">
                            <UngrantedPermissions data={ungranted} />
                            <GrantedPermissions data={granted} />
                        </div>
                    </RenderIf>
                </StyledRecordSection>
                <RenderIf isTrue={isLoading}>
                    <Spacer />
                </RenderIf>
            </ModalContent>
        </>
    );
}

ProjectPermissionsModal.propTypes = {
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    permissions: PropTypes.shape({
        granted: PropTypes.array,
        ungranted: PropTypes.array,
    }),
    isLoading: PropTypes.bool,
};

ProjectPermissionsModal.defaultProps = {
    projectId: undefined,
    projectName: undefined,
    permissions: undefined,
    isLoading: false,
};
