import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { InformationTextContainer, HighLightedText, StyledChip } from './styled';

const InformationText = (props) => {
    const {
        data,
        query,
        categories,
        filter,
        onDelete,
    } = props;
    const results = data.length !== 1 ? `${data.length} results` : `${data.length} result`;
    const filters = categories.concat(filter);
    const haveQuery = query !== '';
    const haveFilters = filters.length !== 0;
    const hasFiltersWithoutQuery = haveFilters && !haveQuery;

    return (
        <InformationTextContainer>
            <RenderIf isTrue={haveQuery}>
                <HighLightedText>
                    {results}
                </HighLightedText>
                {' '}
                for “
                <HighLightedText>{query}</HighLightedText>
                ”
            </RenderIf>
            <RenderIf isTrue={hasFiltersWithoutQuery}>
                <HighLightedText>
                    {results}
                </HighLightedText>
            </RenderIf>
            {' '}
            <RenderIf isTrue={haveFilters}>
                filtered by:
                {filters.map((filterValue, index) => (
                    <StyledChip
                        // eslint-disable-next-line react/no-array-index-key
                        key={`filter-${index}`}
                        label={filterValue}
                        onDelete={() => onDelete(filterValue)}
                    />
                ))}
            </RenderIf>

        </InformationTextContainer>
    );
};

InformationText.propTypes = {
    data: PropTypes.array,
    query: PropTypes.string,
    categories: PropTypes.array,
    filter: PropTypes.array,
    onDelete: PropTypes.func,
};

InformationText.defaultProps = {
    data: undefined,
    query: undefined,
    categories: undefined,
    filter: [],
    onDelete: () => {},
};

export default InformationText;
