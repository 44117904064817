const tryParseJson = (value) => {
    try {
        const parsedValue = JSON.parse(value);
        return parsedValue;
    } catch (error) {
        return undefined;
    }
};

export default tryParseJson;
