import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import GlobalSpinner from '../../../../components/GlobalSpinner';
import {
    FunctionsContainer,
    Header,
    Title,
    MessageIcon,
    EmptyMessage,
    StyledAnchor,
    MessageDescription,
    StyledChevronRight,
} from './styled';
import Functions from './functions';

const WorkspaceFunctions = () => {
    const { workspace } = useParams();
    const [data, isLoadingFunctions] = useCollection({
        path: `/workspaces/${workspace}/functions`,
        flat: true,
    });

    const [importedData, isLoadingImportedFunctions] = useCollection({
        path: `/workspaces/${workspace}/importedFunctions`,
        flat: true,
    });

    const isLoading = isLoadingFunctions || isLoadingImportedFunctions;
    const hasFunctions = data && data.length > 0;
    const hasImportedFunctions = importedData && importedData.length > 0;
    const hasData = hasFunctions || hasImportedFunctions;

    return (
        <FunctionsContainer>
            <Header>
                <Title>Functions</Title>
            </Header>
            <RenderIf isTrue={isLoading}>
                <GlobalSpinner message="Loading workspace functions..." />
            </RenderIf>
            <RenderIf isTrue={!isLoading && !hasData}>
                <EmptyMessage
                    icon={<MessageIcon />}
                    title="Start writing functions."
                    description={(
                        <MessageDescription>
                            Build your Cloud Functions on the Function Store platform.
                            <br />
                            Here&apos;s a quick guide on how to get started.
                        </MessageDescription>
                    )}
                    action={(
                        <StyledAnchor
                            href="https://docs.functions.store/functions/developers/writing-functions"
                            target="blank"
                            rel="noopener"
                        >
                            How to start writing functions?
                            <StyledChevronRight className="rainbow-m-left_xx-small" />
                        </StyledAnchor>
                    )}
                />
            </RenderIf>
            <RenderIf isTrue={!isLoading && hasData}>
                <Functions
                    workspace={workspace}
                    functions={data}
                    importedFunctions={importedData}
                />
            </RenderIf>
        </FunctionsContainer>
    );
};

export default WorkspaceFunctions;
