import styled from 'styled-components';
import { Badge, Spinner, Table } from 'react-rainbow-components';
import {
    CheckCircleFilled, ErrorCircleFilled, ClockFilled, ChevronRight,
    QuestionFilled,
} from '@rainbow-modules/icons';
import Flow from '../../../../../components/Icons/flow';
import StepFlow from '../../../../../components/Icons/stepFlow';
import { GRAY, GREEN, RED } from '../../../../../colors';

export const Header = styled.div`
    display: flex;
    padding: 20px 0 8px 0;
`;

export const ColumnContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0;
`;

export const CenterColumnContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
`;

export const StyledFlowIcon = styled(Flow)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 12px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
`;

export const StyledValue = styled.p`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: none;
    text-align: left;
    margin-right: 6px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const StyledEnvironmentIcon = styled(StepFlow)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 12px;
    width: 20px;
    height: 20px;
    margin-left: 12px;
    flex-shrink: 0;
`;

export const FunctionsLabel = styled.h4`
    font-size: 14px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-left: 52px;
    margin-top: 4px;
`;

export const StatusColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 0;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
`;

export const FunctionsListContainer = styled.div`
    display: flex;
    align-items: stretch;
    margin-left: 54px;
`;

export const StyledChevronRight = styled(ChevronRight)`
    transition: transform 0.1s ease;
    transform: ${(props) => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

export const VerticalDivider = styled.div`
    display: flex;
    width: 2px;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 19px;
`;

export const FunctionItem = styled.div`
    display: flex;
    align-items: center;
`;

export const FunctionItemDivider = styled.div`
    height: 2px;
    background: ${(props) => props.theme.rainbow.palette.border.divider};
    width: 16px;
`;

export const FunctionDeployErrorStatus = styled(ErrorCircleFilled)`
    color: ${RED};
    width: 16px;
    height: 16px;
    margin: 0 6px;
`;

export const FunctionDeploySuccessStatus = styled(CheckCircleFilled)`
    color: ${GREEN};
    width: 16px;
    height: 16px;
    margin: 0 6px;
`;

export const StyledQuestionIcon = styled(QuestionFilled)`
    color: ${GRAY};
    width: 16px;
    height: 16px;
    margin: 0 6px;
`;

export const FunctionDeployPendingStatus = styled(ClockFilled)`
    color: ${GRAY};
    width: 16px;
    height: 16px;
    margin: 0 6px;
`;

export const FunctionDeployRunningStatus = styled(Spinner)`
    position: unset;
    top: unset;
    left: unset;
    margin-top: 16px;
    margin-left: 14px;
    z-index: unset;
`;

export const RunningStatusSpinner = styled(Spinner)`
    top: 11px;
    left: 12px;
    z-index: unset;
`;

export const FunctionItemLabel = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const StyledBadge = styled(Badge)`
    position: relative;

    ${(props) => props.status === 'success' && `
        background: ${GREEN};
        color: #fff;
    `}

    ${(props) => props.status === 'error' && `
        background: ${RED};
        color: #fff;
    `}

    ${(props) => props.status === 'inProgress' && `
        background: ${props.theme.rainbow.palette.brand.main};
        color: #fff;
        padding-left: 26px;
    `}
`;

export const HistoryContent = styled.article`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const StyledTable = styled(Table)`
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    margin-bottom: 12px;
`;
