import PropTypes from 'prop-types';
import { Tabset, Tab } from 'react-rainbow-components';
import { BillingHeader } from './styled';

const BillingTabset = ({ activeTabName, onSelect }) => (
    <>
        <BillingHeader>Billing</BillingHeader>
        <Tabset variant="line" onSelect={onSelect} activeTabName={activeTabName}>
            <Tab name="overview" label="Overview" />
            <Tab name="payments" label="Payments" />
            <Tab name="payment" label="Payment Method" />
            <Tab name="usage" label="Plan Usage" />
        </Tabset>
    </>
);

BillingTabset.propTypes = {
    activeTabName: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default BillingTabset;
