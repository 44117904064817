import PropTypes from 'prop-types';
import { LoadingShape } from 'react-rainbow-components';
import {
    CardContainer,
    LoadingPlanName,
    LoadingPlanDescription,
    LoadingPlanPrice,
    LoadingFeatureHeader,
    LoadingFeatureItem,
    LoadingFeatureItemMedium,
    LoadingFeatureItemSmall,
} from './styled';

const LoadingProduct = ({ color }) => (
    <CardContainer style={{ height: 436 }} color={color}>
        <div>
            <LoadingPlanName>
                <LoadingShape />
            </LoadingPlanName>
            <LoadingPlanDescription>
                <LoadingShape />
            </LoadingPlanDescription>
            <LoadingPlanPrice>
                <LoadingShape />
            </LoadingPlanPrice>
            <LoadingFeatureHeader>
                <LoadingShape />
            </LoadingFeatureHeader>
            <LoadingFeatureItem>
                <LoadingShape />
            </LoadingFeatureItem>
            <LoadingFeatureItemMedium>
                <LoadingShape />
            </LoadingFeatureItemMedium>
            <LoadingFeatureItemSmall>
                <LoadingShape />
            </LoadingFeatureItemSmall>
        </div>
    </CardContainer>
);

LoadingProduct.propTypes = {
    color: PropTypes.string,
};

LoadingProduct.defaultProps = {
    color: '',
};

export default LoadingProduct;
