import React from 'react';
import PropTypes from 'prop-types';
import JsonInput from '@rainbow-modules/forms/lib/components/JsonInput';
import Label from '../LabelWithDescription';
import MarkdownOutputDark from '../MarkdownOutputDark';

const Configuration = React.forwardRef(
    ({
        name, description, error, config, defaultConfig, readOnly, onChange,
    }, ref) => {
        const value = config || defaultConfig;
        const title = readOnly ? `${name} configuration` : `Configure ${name}`;
        const label = <Label name={title} description={description} />;

        const handleChange = (newValue) => {
            onChange(newValue);
        };

        if (readOnly) {
            const code = `\`\`\`json\n${JSON.stringify(value, null, 2)}\n\`\`\``;
            return (
                <div ref={ref}>
                    {label}
                    <MarkdownOutputDark value={code} />
                </div>
            );
        }

        return (
            <div ref={ref}>
                <JsonInput label={label} value={value} error={error} onChange={handleChange} />
            </div>
        );
    },
);

Configuration.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    error: PropTypes.string,
    readOnly: PropTypes.bool,
    defaultConfig: PropTypes.object,
    config: PropTypes.object,
    onChange: PropTypes.func,
};

Configuration.defaultProps = {
    name: undefined,
    description: undefined,
    error: undefined,
    readOnly: false,
    defaultConfig: undefined,
    config: undefined,
    onChange: () => {},
};

export default Configuration;
