import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import Step7 from '../images/7-runtime-and-entry-point.png';
import { StepDescription, StyledImage, Highlight } from '../styled';

const ClickNext = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header='Click "Next".'
        className="rainbow-m-top_xx-large"
    >
        <StepDescription>
            In the
            <Highlight>Runtime</Highlight>
            field, select the runtime
            <Highlight>Node.js 10</Highlight>
            for
            the function. In the
            <Highlight>Entry point</Highlight>
            field, write “handler” because this
            one is what we’re using by default.
        </StepDescription>
        <StyledImage src={Step7} />
    </Step>
);

ClickNext.propTypes = {
    stepNumber: PropTypes.number,
};

ClickNext.defaultProps = {
    stepNumber: undefined,
};

export default ClickNext;
