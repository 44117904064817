import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';
import Build from '../../../../../../components/Icons/build';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    margin: 8px 0 12px;
`;

export const StatusColumnContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    text-transform: ${(props) => props.textTransform};
    color: ${(props) => props.color};
    background: ${(props) => props.backgroundColor};
`;

export const Label = styled.span`
    margin-left: 11px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.53px;
`;

export const LinkColumnContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BuildIcon = styled(Build)`
    width: 20px;
    height: 20px;
    margin-right: 8px;
`;

export const SpinnerIcon = styled(Spinner)`
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    z-index: unset;
`;

export const IconContainer = styled.div`
    width: 20px;
    margin-left: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 18px;
        height: 18px;
    }
`;
