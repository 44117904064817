import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Content = styled.div`
    text-align: right;
    margin-left: 6px;
    margin-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

export const CellContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const AlignRightContent = ({ value }) => (
    <CellContainer>
        <Content>{value}</Content>
    </CellContainer>
);

AlignRightContent.propTypes = {
    value: PropTypes.string.isRequired,
};

export default AlignRightContent;
