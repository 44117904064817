import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSetDoc, useRemoveDoc } from '@rainbow-modules/firebase-hooks';
import { CheckboxToggle } from 'react-rainbow-components';
import firebase from 'firebase';

const Action = ({ row }) => {
    const {
        isAdded, isImported, name, workspace: functionWorkspace, id,
    } = row;
    const [isLoading, setLoading] = useState(false);
    const { workspace, deploymentId } = useParams();
    const { mutate: addFunction } = useSetDoc();
    const { mutate: removeFunction } = useRemoveDoc();
    const onChange = async (event) => {
        setLoading(true);
        if (event.target.checked) {
            const additionalData = (isImported ? {
                name,
                workspace: functionWorkspace,
            } : {});
            await addFunction({
                path: `/workspaces/${workspace}/deployments/${deploymentId}/functions/${id}`,
                data: {
                    ...additionalData,
                    createdAt: firebase.database.ServerValue.TIMESTAMP,
                },
            });
        } else {
            await removeFunction(`/workspaces/${workspace}/deployments/${deploymentId}/functions/${id}`);
        }
        setLoading(false);
    };
    return <CheckboxToggle className="rainbow-m-top_small" value={isAdded} onChange={onChange} disabled={isLoading} />;
};

Action.propTypes = {
    row: PropTypes.any.isRequired,

};

export default Action;
