import styled from 'styled-components';
import { HelpText, Input } from 'react-rainbow-components';
import { ChevronDown } from '@rainbow-modules/icons';
import { WHITE } from '../../../colors';

export const AccordionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: ${WHITE};
    z-index: 10;
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const RuntimeLabelContainer = styled.div`
    display: flex;
    padding: 12px 0;
    align-items: center;
`;

export const LabelTitle = styled.span`
    font-size: 22px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const AutoScalingContainer = styled.div`
    margin-top: 20px;
`;

export const InstancesContainer = styled.div`
    margin: 20px 0 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
`;

export const InstancesLabel = styled.span`
    font-size: 12px;
`;

export const InstanceInput = styled(Input)`
    width: 48%;
`;

export const StyledHelpText = styled(HelpText)`
    margin-left: 5px;
    width: 14px;
    height: 14px;

    > svg {
        margin-bottom: 3px;
        width: 14px;
        height: 14px;
    }
`;

export const AccordionSection = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Accordion = styled.button`
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    width: 80%;
`;

export const AccordionIcon = styled(ChevronDown)`
    margin-right: 10px;
    transition: transform 0.6s ease;
    transform: ${(props) => (props.active === true ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;

export const FieldsContainer = styled.div`
    margin-top: 20px;
    max-width: 90%;
`;
