const convertibleTypesArray = [
    'text|secret',
    'text|select',
    'text|string',
    'firestoreDocumentPath|secret',
    'firestoreCollectionPath|secret',
    'firestoreDocumentPath|text',
    'firestoreCollectionPath|text',
    'firestoreDocumentPath|string',
    'firestoreCollectionPath|string',
    'undefined|secret',
    'undefined|text',
    'undefined|string',
    'undefined|select',
    'undefined|firestoreDocumentPath',
    'undefined|firestoreCollectionPath',
    'email|string',
    'url|string',
    'email|text',
    'url|text',
    'text|email',
    'text|url',
    'undefined|email',
    'undefined|url',
];

const isConvertibleType = ({ envSchemaType, currentValueType }) => {
    const combinedTypes = `${String(envSchemaType)}|${currentValueType}`;
    const isConvertibleEnvType = convertibleTypesArray.includes(combinedTypes);
    return isConvertibleEnvType;
};

export default isConvertibleType;
