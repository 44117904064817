import PropTypes from 'prop-types';
import FormattedRelativeDate from '../../../../../../components/FormattedRelativeDate';
import { CenterColumnContainer } from '../styled';

const StartDateColumn = ({ value }) => (
    <CenterColumnContainer>
        <FormattedRelativeDate value={value} />
    </CenterColumnContainer>
);

StartDateColumn.propTypes = {
    value: PropTypes.string.isRequired,
};

export default StartDateColumn;
