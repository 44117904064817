import { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner, RenderIf } from 'react-rainbow-components';
import { filterByFields } from '@rainbow-modules/listview';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import useCallableQuery from '../../data/hooks/useCallableQuery';
import usePlanServiceCount from '../../data/hooks/usePlanServiceCount';
import useUpgradeToast from '../../data/hooks/useUpgradeToast';
import Empty from '../../components/Icons/empty';
import ProjectItem from './projectItem';
import ProjectsHeader from './header';
import ServiceLimiteInfo from '../../components/ServiceLimiteInfo';
import {
    Container,
    ProjectsContainer,
    SpinnerContainer,
    EmptyMessageContainer,
    EmptyMessageTitle,
    EmptyMessageDescription,
    Title,
    StyledFloatingBar,
    StyledInputSearch,
    ProjectSubheader,
} from './styled';

const ProjectList = ({ data, hasReachedLimit }) => data
    .map((item) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ProjectItem {...item} key={item.projectId} hasReachedLimit={hasReachedLimit} />
    ));

ProjectList.propTypes = {
    data: PropTypes.array.isRequired,
};

export default function Projects() {
    const [query, setQuery] = useState('');
    const { data: projects = [], isLoading: isLoadingProjects } = useCallableQuery('projects-list');
    const {
        isLoading: isLoadingServiceCount, count, limit, hasReachedLimit, product,
    } = usePlanServiceCount({ serviceName: 'projects' });
    useUpgradeToast({
        hasReachedLimit,
        serviceName: 'projects',
        isLoadingServiceCount,
    });
    const isAddProjectDisabled = isLoadingServiceCount || hasReachedLimit;

    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });

    const filteredProjects = filterByFields({
        data: projects,
        query,
        fields: ['name', 'projectId'],
    });

    if (isLoadingProjects) {
        return (
            <SpinnerContainer>
                <Spinner size="x-large" type="arc" variant="brand" />
            </SpinnerContainer>
        );
    }

    return (
        <Container onScroll={handleScrollEvent}>
            <StyledFloatingBar isVisible={showBar}>
                <ProjectsHeader
                    projects={projects}
                    query={query}
                    onChangeQuery={setQuery}
                    title={<Title fontSize="20px">Your Projects</Title>}
                    showSearch
                    disableAddProject={isAddProjectDisabled}
                />
            </StyledFloatingBar>
            <ProjectsHeader
                id="static-projects-header"
                title={<Title>Your Projects</Title>}
                projects={projects}
                description="Select one of your projects. You will only see
                the Firebase/Google Cloud Platform projects that you have access to."
                disableAddProject={isAddProjectDisabled}
            />
            <ProjectSubheader>
                <StyledInputSearch
                    id="projects-search"
                    variant="realtime"
                    value={query}
                    placeholder="Search"
                    onChange={setQuery}
                />
                <RenderIf isTrue={!isLoadingServiceCount}>
                    <ServiceLimiteInfo
                        label={`${count} of ${limit} Active Projects`}
                        helpTextTitle="Active Projects in your current plan"
                        featureUsageName="Active projects"
                        featureUsageSummaryCounter={`${count} of ${limit} projects`}
                        featureUsageCounter={`${count} projects`}
                        upgradeText="If you need more active projects, you have to upgrade your plan."
                        limit={limit}
                        count={count}
                        product={product}
                    />
                </RenderIf>
            </ProjectSubheader>

            <RenderIf isTrue={projects.length > 0}>
                <ProjectsContainer>
                    <ProjectList data={filteredProjects} hasReachedLimit={hasReachedLimit} />
                </ProjectsContainer>
            </RenderIf>
            <RenderIf isTrue={filteredProjects.length === 0 && projects.length > 0}>
                <EmptyMessageContainer>
                    <EmptyMessageTitle>Your search did not match any project.</EmptyMessageTitle>
                </EmptyMessageContainer>
            </RenderIf>
            <RenderIf isTrue={projects.length === 0}>
                <EmptyMessageContainer>
                    <Empty className="rainbow-m-bottom_large" />
                    <EmptyMessageTitle>
                        We couldn&apos;t find any Firebase or GCP projects associated with your
                        account.
                    </EmptyMessageTitle>
                    <EmptyMessageDescription>
                        Please click the &quot;Add Project&quot; button to set up the projects you
                        want us to have access to.
                    </EmptyMessageDescription>
                </EmptyMessageContainer>
            </RenderIf>
        </Container>
    );
}
