import PropTypes from 'prop-types';
import { Container, Content, StyledCopyToClipboardButton } from './styled';

const CopyToClipboard = (props) => {
    const { value, disabled } = props;
    return (
        <>
            <Container>
                <StyledCopyToClipboardButton
                    variant="border-filled"
                    size="small"
                    value={value}
                    shaded
                    disabled={disabled}
                />
                <Content>{value}</Content>
            </Container>
        </>
    );
};

CopyToClipboard.propTypes = {
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

CopyToClipboard.defaultProps = {
    disabled: false,
};

export default CopyToClipboard;
