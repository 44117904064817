import { useCollection } from '@rainbow-modules/firebase-hooks';

const useVersions = (functionId, workspace) => {
    const [data, isLoading] = useCollection({
        path: `workspaces/${workspace}/marketplace/${functionId}/versions`,
        flat: true,
    });
    const versions = data?.map((item) => ({ ...item, isPublished: true }));
    return [versions, isLoading];
};

export default useVersions;
