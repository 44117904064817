/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import { CHECK_PROJECT_PERMISSIONS_MODAL } from '../../constants';
import useCheckProject from '../../data/hooks/useCheckProject';
import useMutationFlow from '../../data/hooks/useMutationFlow';
import firebaseCallable from '../../data/services/firebaseCallable';
import CheckingMessage from '../projects/checkingMessage';
import Console from './console';
import CheckProjectModal from './check';
import ProjectSetupHeader from './header';
import {
    Container,
    Title,
    ProjectIcon,
    Highlight,
    StyledFloatingBar,
    FloatingBarTitle,
    FloatingBarIcon,
} from './styled';

// eslint-disable-next-line no-unused-vars
export default function ProjectSetup({ history: { push } }) {
    const modalProps = useConnectModal(CHECK_PROJECT_PERMISSIONS_MODAL);
    const [openModal, closeModal] = useOpenModal(CHECK_PROJECT_PERMISSIONS_MODAL);
    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });
    const { state: locationState } = useLocation();
    const { projectId } = useParams();
    const checkProject = useCheckProject(projectId);
    const { mutate: checkPermissions } = useMutationFlow({
        mutation: () => firebaseCallable('projects-checkProjectPermissions', {
            projectId,
        }),
        submitSpinnerMessage: `Checking permissions for project "${projectId}"`,
        successMessage: null,
        errorMessage: `An error occurred while checking project "${projectId}". Please try again later.`,
        onSuccess: (res) => {
            if (res && Array.isArray(res.ungranted) && res.ungranted.length === 0) {
                return checkProject({
                    spinnerMessage: <CheckingMessage projectId={projectId} />,
                });
            }
            return openModal({ ...locationState, ...res, projectId });
        },
    });

    return (
        <Container onScroll={handleScrollEvent}>
            <StyledFloatingBar isVisible={showBar}>
                <ProjectSetupHeader
                    onCheck={checkPermissions}
                    icon={<FloatingBarIcon />}
                    title={(
                        <FloatingBarTitle>
                            <Highlight>
                                &quot;
                                {projectId}
                                &quot;
                            </Highlight>
                            Set Up
                        </FloatingBarTitle>
                    )}
                />
            </StyledFloatingBar>
            <ProjectSetupHeader
                icon={<ProjectIcon />}
                title={(
                    <Title>
                        <Highlight>
                            &quot;
                            {projectId}
                            &quot;
                        </Highlight>
                        Set Up
                    </Title>
                )}
            />
            <Console
                onCheck={checkPermissions}
            />
            <CheckProjectModal
                {...modalProps}
                onCheck={checkPermissions}
                onRequestClose={closeModal}
            />
        </Container>
    );
}

ProjectSetup.propTypes = {
    history: PropTypes.object.isRequired,
};
