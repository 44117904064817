import PropTypes from 'prop-types';
import LinkColumn from '../../../../components/LinkColumn';

const UidColumn = ({ projectId, value: userId }) => {
    const to = `/app/project/${projectId}/auth/user/${userId}`;
    return <LinkColumn to={to} value={userId} />;
};

UidColumn.propTypes = {
    projectId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default UidColumn;
