import PropTypes from 'prop-types';
import { Title, Highlight } from './styled';

const FunctionTitle = (props) => {
    const { projectId, mode } = props;
    const action = mode === 'config' ? 'Reconfigure' : 'Install';
    return (
        <Title title={`${action} function in “${projectId}” project`}>
            {action}
            {' '}
            function in
            <Highlight>
                “
                {projectId}
                ”
            </Highlight>
            project
        </Title>
    );
};

FunctionTitle.propTypes = {
    projectId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
};

export default FunctionTitle;
