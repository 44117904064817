import useCurrentUserPayments from '../../data/hooks/useCurrentUserPayments';
import Transactions from './transactions';
import getThirtyDaysRange from './helpers/getThirtyDaysRange';
import {
    SectionTitleContainer,
    SectionTitle,
} from './styled';

const Payments = () => {
    const { today, dateThirtyDaysAgo } = getThirtyDaysRange();
    const { payments, isLoading } = useCurrentUserPayments({
        gte: dateThirtyDaysAgo,
        lte: today,
    });
    return (
        <>
            <SectionTitleContainer>
                <SectionTitle>Payments</SectionTitle>
            </SectionTitleContainer>
            <Transactions data={payments} isLoading={isLoading} />
        </>
    );
};

export default Payments;
