import PropTypes from 'prop-types';
import GoToCloudFunctions from './stepsComponents/goToCloudFunctions';
import SelectProject from './stepsComponents/selectProject';
import CreateFunction from './stepsComponents/createFunction';
import NameYourFunction from './stepsComponents/nameYourFunction';
import SelectTrigger from './stepsComponents/selectTrigger';
import AddEnvVariables from './stepsComponents/addEnvVariables';
import ClickNext from './stepsComponents/clickNext';
import UploadZip from './stepsComponents/uploadZip';
import DownloadZip from './stepsComponents/downloadZip';
import BrowseBucket from './stepsComponents/browseBucket';
import Deploy from './stepsComponents/deploy';

const stepComponentsMap = {
    goToCloudFunctions: GoToCloudFunctions,
    selectProject: SelectProject,
    createFunction: CreateFunction,
    nameYourFunction: NameYourFunction,
    selectTrigger: SelectTrigger,
    addEnvVariables: AddEnvVariables,
    clickNext: ClickNext,
    downloadZip: DownloadZip,
    uploadZip: UploadZip,
    browseBucket: BrowseBucket,
    deploy: Deploy,
};

const stepsOrder = [
    'goToCloudFunctions',
    'selectProject',
    'createFunction',
    'nameYourFunction',
    'selectTrigger',
    'addEnvVariables',
    'clickNext',
    'downloadZip',
    'uploadZip',
    'browseBucket',
    'deploy',
];

const Steps = ({ data }) => (
    stepsOrder.map((item, index) => {
        const StepComponent = stepComponentsMap[item];
        const stepNumber = index + 1;
        return <StepComponent key={item} stepNumber={stepNumber} functionData={data} />;
    })
);

Steps.propTypes = {
    data: PropTypes.object,
};

Steps.defaultProps = {
    data: undefined,
};

export default Steps;
