import { useAuthState } from '@rainbow-modules/firebase-hooks';
import { QueryClientProvider } from 'react-query';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthRoutes from './pages';
import queryClient from './queryClient';
import Authenticate from './pages/authenticate';
import Login from './pages/login';
import usePageTracking from './data/hooks/usePageTracking';

const App = () => {
    usePageTracking();
    const isAuth = useAuthState();
    if (isAuth === undefined) {
        return null;
    }
    if (isAuth) {
        return (
            <QueryClientProvider client={queryClient}>
                <AuthRoutes />
            </QueryClientProvider>
        );
    }
    return (
        <Switch>
            <Route path="/authenticate/:idToken" component={Authenticate} />
            <Route path="/login" component={Login} />
            <Redirect from="/" to="/login" />
        </Switch>
    );
};

export default App;
