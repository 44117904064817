import { useReduxForm } from 'react-rainbow-components/libs/hooks';
import { RadioGroup } from 'react-rainbow-components';
import { Field } from '@rainbow-modules/forms';
import { authOptions } from '../../../constants';
import { FormHeader, FormTitle } from '../styled';

const CustomRadioGroup = (props) => {
    const {
        value, onChange, options, name,
    } = useReduxForm(props);
    const newValue = value ? 'authenticated' : 'unauthenticated';

    const handleChange = (event) => {
        if (event.target.value === 'authenticated') {
            return onChange(true);
        }
        return onChange(false);
    };

    return (
        <RadioGroup
            id="auth-radio-group"
            onChange={handleChange}
            options={options}
            value={newValue}
            name={name}
        />
    );
};

const Https = () => (
    <>
        <FormHeader>
            <FormTitle>Authentication</FormTitle>
        </FormHeader>
        <Field
            component={CustomRadioGroup}
            name="trigger.authenticated"
            options={authOptions}
        />
    </>
);

export default Https;
