import PropTypes from 'prop-types';
import { RenderIf, HelpText } from 'react-rainbow-components';
import FunctionIcon from '../../../../../components/FunctionIcon';
import {
    FunctionColumnContainer,
    FunctionInfoContainer,
    FunctionCheckInfo,
    ImportedBadge,
    StyledLink,
    WorkspaceName,
} from '../styled';

const FunctionColumn = ({ row }) => {
    const {
        name, workspace, iconUrl, wasImported, canImport, reason,
    } = row;

    const shouldRenderHint = Boolean(!wasImported && !canImport && !!reason);

    return (
        <FunctionColumnContainer>
            <FunctionIcon url={iconUrl} functionType="marketplace" width="30px" height="30px" />
            <FunctionInfoContainer>
                <StyledLink>
                    {name}
                </StyledLink>
                <WorkspaceName>
                    {workspace}
                </WorkspaceName>
            </FunctionInfoContainer>
            <FunctionCheckInfo>
                <RenderIf isTrue={wasImported}>
                    <ImportedBadge size="small" label="Imported" />
                </RenderIf>
                <RenderIf isTrue={shouldRenderHint}>
                    <HelpText
                        title="Function cannot be imported"
                        text={reason}
                        variant="warning"
                        className="rainbow-flex"
                    />
                </RenderIf>
            </FunctionCheckInfo>
        </FunctionColumnContainer>
    );
};

FunctionColumn.propTypes = {
    row: PropTypes.object.isRequired,
};

export default FunctionColumn;
