import { FormattedPlural } from 'react-intl';
import { RenderIf } from 'react-rainbow-components';
import BadgeText from '../BadgeText';
import {
    FileName,
    Bold,
    Console,
    Severity,
    Icon,
} from './styled';

const severityMap = {
    1: 'warning',
    2: 'error',
};

const Errors = ({ messages }) => messages.map((item, index) => {
    const {
        severity, line, column, message, ruleId,
    } = item;
    const key = `message-${index}`;
    const severityText = severityMap[severity];

    return (
        <div className="rainbow-flex rainbow-m-bottom_small rainbow-m-top_small" key={key} data-cy="eslint-message">
            <Severity severity={severity} data-cy="eslint-message-severity">
                <Icon severity={severity} />
                {severityText}
            </Severity>
            <span className="rainbow-m-horizontal_small" data-cy="eslint-message-line">{`${line}:${column}`}</span>
            <span className="rainbow-flex rainbow-m-right_small" data-cy="eslint-message-content">{message}</span>
            -
            <span className="rainbow-flex rainbow-m-left_small">
                {ruleId}
            </span>
        </div>
    );
});

// eslint-disable-next-line react/prop-types
const CounterLabel = ({ value, labelOne, labelMany }) => (
    <FormattedPlural
        value={value}
        one={(
            <>
                <Bold>{value}</Bold>
                {' '}
                {labelOne}
            </>
        )}
        other={(
            <>
                <Bold>{value}</Bold>
                {' '}
                {labelMany}
            </>
        )}
    />
);

const EslintSummary = ({ errors }) => errors.map((item, index) => {
    const {
        filePath, errorCount, warningCount, messages,
    } = item;
    const showData = filePath || errorCount || warningCount || messages;
    return (
        <>
            <RenderIf isTrue={showData}>
                <div
                // eslint-disable-next-line react/no-array-index-key
                    key={`${filePath}-${index}`}
                    className="rainbow-m-vertical_large rainbow-m-horizontal_large"
                    data-cy="eslint-summary"
                >
                    <div className="rainbow-flex rainbow-justify_spread rainbow-align_start" data-cy="eslint-summary-header">
                        <RenderIf isTrue={filePath}>
                            <FileName>{filePath}</FileName>
                        </RenderIf>
                        <div className="rainbow-flex" data-cy="eslint-summary-errors">
                            <RenderIf isTrue={errorCount}>
                                <BadgeText
                                    variant="error"
                                    label={
                                        <CounterLabel value={errorCount} labelOne="error" labelMany="errors" />
                                    }
                                />
                            </RenderIf>
                            <RenderIf isTrue={warningCount}>
                                <BadgeText
                                    variant="warning"
                                    label={
                                        <CounterLabel value={warningCount} labelOne="warning" labelMany="warnings" />
                                    }
                                />
                            </RenderIf>
                        </div>
                    </div>
                    <RenderIf isTrue={messages}>
                        <Console>
                            <Errors messages={messages} />
                        </Console>
                    </RenderIf>
                </div>
            </RenderIf>
        </>
    );
});

export default EslintSummary;
