import { useMemo } from 'react';
import {
    useDoc, useCurrentUser,
} from '@rainbow-modules/firebase-hooks';
import useProducts from './useProducts';

const getProduct = ({ products = [], productId }) => {
    if (productId) {
        return products.find((product) => product.id === productId);
    }
    return products.find((product) => product.metadata?.freemium);
};

const getUserCurrentProductId = (subscription) => {
    const isSubscriptionCanceled = subscription?.productId && subscription?.subscriptionStatus === 'canceled';
    if (!isSubscriptionCanceled) {
        return subscription?.productId;
    }
    return undefined;
};

const usePlanServiceCount = ({ serviceName }) => {
    const { uid } = useCurrentUser();
    const [subscription, isLoadingSubscription] = useDoc({
        path: `subscriptions/${uid}`,
        flat: true,
    });
    const [service, isLoadingServiceCount] = useDoc({
        path: `subscriptions/${uid}/services/${serviceName}`,
        flat: true,
    });
    const [products, isLoadingProducts] = useProducts();
    const isLoading = isLoadingSubscription || isLoadingServiceCount || isLoadingProducts;
    return useMemo(() => {
        const count = service?.count || 0;
        const userProductId = getUserCurrentProductId(subscription);
        const product = getProduct({ products, productId: userProductId });
        const limit = product?.features?.[serviceName]?.limit || 'Unlimited';
        const hasService = Boolean(product?.features?.[serviceName]);
        return {
            isLoading,
            count,
            limit,
            product,
            hasService,
            hasReachedLimit: count >= limit,
        };
    }, [isLoading, service, products, serviceName, subscription]);
};

export default usePlanServiceCount;
