const memoryOptions = [
    {
        name: 128, label: '128 MB',
    },
    {
        name: 256, label: '256 MB',
    },
    {
        name: 512, label: '512 MB',
    },
    {
        name: 1024, label: '1 GB',
    },
    {
        name: 2048, label: '2 GB',
    },
    {
        name: 4096, label: '4 GB',
    },
    {
        name: 8192, label: '8 GB',
    },
];

export default memoryOptions;
