import PropTypes from 'prop-types';
import ElapsedTime from '../../../../../../components/ElapsedTime';
import getDeploymentHistoryDuration from '../../../../../../helpers/getDeploymentHistoryDuration';
import { CenterColumnContainer } from '../styled';

const DurationColumn = ({ row: deploymentHistory }) => {
    const duration = getDeploymentHistoryDuration(deploymentHistory);
    return (
        <CenterColumnContainer>
            <ElapsedTime value={duration} />
        </CenterColumnContainer>
    );
};

DurationColumn.propTypes = {
    row: PropTypes.object.isRequired,
};

export default DurationColumn;
