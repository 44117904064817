/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { RecordField } from '@rainbow-modules/record';
import { Badge, Modal } from 'react-rainbow-components';
import {
    GRAY, LIGHT_GRAY, DARK_GREEN, WHITE, RED,
} from '../../../colors';

export const StyledBadge = styled(Badge)`
    background: ${LIGHT_GRAY};
    color: ${GRAY};
    position: relative;

    ${(props) => props.label === 'ENABLED'
        && `
            background: ${DARK_GREEN};
            color: ${WHITE};
        `};

    ${(props) => props.label === 'DISABLED'
        && `
            background: ${RED};
            color: ${WHITE};
        `};

    ${(props) => props.label === 'DESTROYED'
        && `
            background: ${RED};
            color: ${WHITE};
        `};

    ${(props) => props.label === 'UNKNOWN'
        && `
            background: ${props.theme.rainbow.palette.text.label};
            color: ${LIGHT_GRAY};
        `};
`;

export const StyledRecordField = styled(RecordField)`
    flex: 1;
`;

export const StyledModal = styled(Modal)`
    width: 600px;
`;
