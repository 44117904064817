import PropTypes from 'prop-types';
import { Option, Badge, RenderIf } from 'react-rainbow-components';
import { GREEN, WHITE } from '../../colors';
import StyledVersionIcon from './styled';

const badgeStyles = { color: GREEN, backgroundColor: WHITE, border: `1px solid ${GREEN}` };

// eslint-disable-next-line react/prop-types
const OptionLabel = ({ version, isLastVersion }) => (
    <span>
        {`v ${version}`}
        <RenderIf isTrue={isLastVersion}>
            <Badge
                className="rainbow-m-left_x-small"
                label="Latest"
                size="small"
                style={badgeStyles}
            />
        </RenderIf>
    </span>
);

const VersionOptions = ({ versions, lastVersionFunction }) => {
    const { version: lastVersion } = lastVersionFunction || {};
    const reversedVersions = [...versions].reverse();
    return reversedVersions?.map((item) => {
        const { version, build, isPublished } = item;
        const isLastVersion = version === lastVersion;
        return (
            <Option
                key={build}
                name={version}
                label={<OptionLabel version={version} isLastVersion={isLastVersion} />}
                searchableText={version}
                icon={<StyledVersionIcon isPublished={isPublished} />}
                value={{ version, isLastVersion }}
            />
        );
    });
};

const VersionsPickerOptions = ({ versions, lastVersionFunction }) => (
    <>
        <Option name="header" label="All Versions" variant="header" />
        <VersionOptions versions={versions} lastVersionFunction={lastVersionFunction} />
    </>
);

VersionsPickerOptions.propTypes = {
    versions: PropTypes.array.isRequired,
    lastVersionFunction: PropTypes.object.isRequired,
};

export default VersionsPickerOptions;
