/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import useMutationFlow from '../../data/hooks/useMutationFlow';
import useUserInstallableVersions from '../../data/hooks/useUserInstallableVersions';
import useUserInstallableBuilds from '../../data/hooks/useUserInstallableBuilds';
import useUserWorkspaceAccess from '../../data/hooks/useUserWorkspaceAccess';
import useFunction from '../../data/hooks/useFunction';
import firebaseCallable from '../../data/services/firebaseCallable';
import { RECONFIG_FUNCTION_DRAWER } from '../../constants';
import Loading from './loading';
import FunctionDrawerHeader from '../FunctionDrawerHeader';
import useMarketplaceInstalledFunction from '../../data/hooks/useMarketplaceInstalledFunction';
import getVersionInitialValue from './helpers/getVersionInitialValue';
import Form from './form';
import FunctionTitle from './functionTitle';
import FunctionIcon from '../FunctionIcon';
import logEvent from '../../analytics/logEvent';
import events from '../../analytics/events';
import VersionsPicker from '../VersionsPicker';
import getVersionLabel from '../../helpers/getVersionLabel';
import Label from '../LabelWithDescription';
import getReconfigInitialsValues from './helpers/getReconfigInitialsValues';
import {
    Content, Footer, Container, Header, BottomGradient,
    PickerContainer, FormProject, FormHeader, FormTitle,
    MessageIcon,
} from './styled';
import BuildPicker from '../BuildPicker';
import useCanReconfigure from '../../data/hooks/useCanReconfigure';
import Message from '../Message/index';

const FORM_ID = 'config-function';
const NO_RECONFIGURE_ACCESS_INFO = 'To reconfigure an installed function, you must have access to the specific build of that function.';

export default function FunctionConfigMode({ id: functionId }) {
    const { projectId } = useParams();
    const [installedFunctionData, isLoadingInstalled] = useMarketplaceInstalledFunction({
        projectId,
        functionId,
    });
    const {
        name, description, iconUrl, workspace, version, buildId: installedBuildId,
    } = installedFunctionData || {};
    const {
        canAccess: hasWorkspaceAccess,
        isLoading: isLoadingWorkspaceAccess,
    } = useUserWorkspaceAccess(workspace);
    const [hasReconfigAccess, isLoadingReconfigAccess] = useCanReconfigure({
        functionId: name, workspace, installedVersion: version, installedBuildId,
    });
    const [selectedVersion, setSelectedVersion] = useState();
    const [selectedBuild, setSelectedBuild] = useState();
    const [lastVersionFunction, isLoadingFunction] = useFunction(name, workspace);
    const [, closeModal] = useOpenModal(RECONFIG_FUNCTION_DRAWER);

    const [installableVersions, isLoadingInstallableVersions] = useUserInstallableVersions({
        functionId: name,
        workspace,
    });
    const [installableBuilds, isLoadingInstallableBuilds] = useUserInstallableBuilds({
        functionId: name,
        workspace,
        version: selectedVersion?.name,
    });

    const isLoading = isLoadingInstalled
        || isLoadingInstallableVersions
        || isLoadingFunction
        || isLoadingWorkspaceAccess
        || isLoadingReconfigAccess;

    const { mutate } = useMutationFlow({
        mutation: (config) => firebaseCallable('functions-reconfig', {
            projectId,
            functionId,
            ...config,
        }),
        submitSpinnerMessage: 'Sending reconfig request',
        successMessage: null,
        onSuccess: () => {
            logEvent(events.functions.RECONFIGURED);
            return closeModal();
        },
    });

    useEffect(() => {
        setSelectedVersion(getVersionInitialValue(version, lastVersionFunction));
    }, [version, lastVersionFunction]);
    useEffect(() => {
        setSelectedBuild(installableBuilds[0]);
    }, [installableBuilds]);

    const handleVersionChange = (params) => {
        const {
            value: { version: versionValue, isLastVersion },
        } = params;
        const label = isLastVersion ? getVersionLabel(versionValue) : `v ${versionValue}`;
        setSelectedVersion({ name: versionValue, label });
    };

    const { env, trigger } = selectedBuild || {};
    const initialValues = getReconfigInitialsValues(
        { installedFunctionData, selectedBuild },
    );
    return (
        <Container>
            <Header>
                <FunctionDrawerHeader
                    name={name}
                    description={description}
                    icon={(
                        <FunctionIcon
                            url={iconUrl}
                            functionType="marketplace"
                            width="48px"
                            height="48px"
                        />
                    )}
                    title={<FunctionTitle mode="config" projectId={projectId} />}
                />
            </Header>
            <RenderIf isTrue={isLoading}>
                <Loading />
            </RenderIf>
            <Content>
                <RenderIf isTrue={!isLoading && hasReconfigAccess}>
                    <FormProject>
                        <FormHeader>
                            <FormTitle>Function Version</FormTitle>
                        </FormHeader>
                        <Label
                            name="Select Version"
                            description="The version of the function you want to reconfigure."
                        />
                        <PickerContainer>
                            <VersionsPicker
                                versions={installableVersions}
                                lastVersionFunction={lastVersionFunction}
                                onChange={handleVersionChange}
                                value={selectedVersion}
                            />
                            <RenderIf isTrue={hasWorkspaceAccess}>
                                <BuildPicker
                                    isLoading={isLoadingInstallableBuilds}
                                    builds={installableBuilds}
                                    onChange={setSelectedBuild}
                                    value={selectedBuild}
                                />
                            </RenderIf>
                        </PickerContainer>
                    </FormProject>
                    <RenderIf isTrue={selectedBuild}>
                        <Form
                            id={FORM_ID}
                            projectId={projectId}
                            fields={{ trigger, env }}
                            onSubmit={(values) => mutate({
                                ...values,
                                version: selectedVersion?.name,
                                buildId: selectedBuild?.buildId,
                            })}
                            initialValues={initialValues}
                        />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={!isLoading && !hasReconfigAccess}>
                    <Message
                        className="rainbow-m-bottom_xx-large"
                        icon={<MessageIcon />}
                        title="You don’t have access to reconfigure"
                        description={NO_RECONFIGURE_ACCESS_INFO}
                    />
                </RenderIf>
            </Content>
            <BottomGradient />
            <Footer>
                <Button
                    label="Cancel"
                    variant="base"
                    className="rainbow-m-right_medium"
                    onClick={closeModal}
                />
                <RenderIf isTrue={!isLoading && hasReconfigAccess}>
                    <Button label="Update config" variant="brand" type="submit" form={FORM_ID} />
                </RenderIf>
            </Footer>
        </Container>
    );
}

FunctionConfigMode.propTypes = {
    id: PropTypes.string,
};

FunctionConfigMode.defaultProps = {
    id: undefined,
};
