import PropTypes from 'prop-types';
import { RecordField, RecordDetails } from '@rainbow-modules/record';
import { Avatar } from '@rainbow-modules/icons';
import CopyUidToClipboard from '../copyUidToClipboard';
import formatDate from '../../../../helpers/formatDate';
import TextValue from '../../../../components/TextValue';
import getIconProvider from '../../../../helpers/getIconProvider';
import getNameProvider from '../../../../helpers/getNameProvider';
import { StyledAvatar } from '../styled';

const Details = (props) => {
    const {
        uid, displayName, email, creationTime, lastSignInTime, providers, isLoading,
    } = props;
    const Icon = getIconProvider(providers);
    const provider = getNameProvider(providers);
    const formattedCreationTime = formatDate(creationTime);
    const formattedLastSignInTime = formatDate(lastSignInTime);
    return (
        <RecordDetails>
            <RecordField label="uid" value={uid} isLoading={isLoading} component={CopyUidToClipboard} />
            <RecordField
                label="displayName"
                value={<TextValue>{displayName}</TextValue>}
                isLoading={isLoading}
                icon={<StyledAvatar size="small" icon={<Avatar />} backgroundColor="transparent" />}
            />
            <RecordField label="email" value={<TextValue>{email}</TextValue>} isLoading={isLoading} />
            <RecordField label="creationTime" value={formattedCreationTime} isLoading={isLoading} />
            <RecordField label="lastSignInTime" value={formattedLastSignInTime} isLoading={isLoading} />
            <RecordField label="providers" value={provider} isLoading={isLoading} icon={<Icon />} />
        </RecordDetails>
    );
};

Details.propTypes = {
    uid: PropTypes.string,
    displayName: PropTypes.string,
    email: PropTypes.string,
    creationTime: PropTypes.string,
    lastSignInTime: PropTypes.string,
    providers: PropTypes.string,
    isLoading: PropTypes.bool,
};

Details.defaultProps = {
    uid: undefined,
    displayName: undefined,
    email: undefined,
    creationTime: undefined,
    lastSignInTime: undefined,
    providers: undefined,
    isLoading: undefined,
};

export default Details;
