import PropTypes from 'prop-types';

const FailedIcon = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            width="121px"
            height="120px"
            viewBox="0 0 121 120"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="install-functions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="install-function/fail-to-install"
                    transform="translate(-767.000000, -248.000000)"
                    fill="#F2707A"
                    fillRule="nonzero"
                >
                    <g id="Group-10" transform="translate(546.000000, 248.000000)">
                        <path
                            d="M334.163065,5.61328761e-13 C338.329322,5.61328761e-13 341.707269,3.37794695 341.707269,7.54420432 L341.707269,90.5304519 C341.707269,94.6967092 338.329322,98.0746562 334.163065,98.0746562 L326.618861,98.0746562 L326.618861,119.953033 L291.586935,98.0746562 L228.544204,98.0746562 C224.377947,98.0746562 221,94.6967092 221,90.5304519 L221,7.54420432 C221,3.37794695 224.377947,5.61328761e-13 228.544204,5.61328761e-13 L334.163065,5.61328761e-13 Z M280.572139,63.7363017 C277.632552,63.7363017 275.168486,66.2003674 275.168486,69.1399546 C275.168486,72.0795418 277.632552,74.5436075 280.572139,74.5436075 C283.403653,74.5436075 285.975792,72.0795418 285.846104,69.2696423 C285.975792,66.1787528 283.533341,63.7363017 280.572139,63.7363017 Z M285.478656,27.8503188 C284.37631,25.4294823 281.804172,24.1974495 279.232033,24.8026586 C276.659894,25.5375554 275.060413,27.8719334 275.060413,30.7034475 C275.190101,32.4110018 275.298174,34.1401708 275.427861,35.8477251 C275.79531,42.3537231 276.162758,48.7300335 276.530206,55.2360316 C276.659894,57.4407219 278.367448,59.0402032 280.572139,59.0402032 C282.776829,59.0402032 284.505998,57.3326489 284.614071,55.1063439 C284.614071,53.766238 284.614071,52.5342051 284.743759,51.1724846 C284.98152,47.0008646 285.240895,42.8292446 285.478656,38.6576246 C285.608343,35.9557981 285.846104,33.2539717 285.975792,30.5521453 C285.975792,29.5794877 285.846104,28.7149033 285.478656,27.8503188 Z"
                            id="Combined-Shape"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

FailedIcon.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

FailedIcon.defaultProps = {
    style: {},
    className: undefined,
};

export default FailedIcon;
