/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import FunctionConfigMode from './configMode';
import FunctionInstallMode from './installMode';
import FunctionPreviewMode from './previewMode';

export default function FunctionInstallForm(props) {
    const { mode, ...rest } = props;

    if (mode === 'preview') {
        return <FunctionPreviewMode {...rest} />;
    }

    if (mode === 'config') {
        return <FunctionConfigMode {...rest} />;
    }

    return <FunctionInstallMode {...rest} />;
}

FunctionInstallForm.propTypes = {
    mode: PropTypes.string,
    id: PropTypes.string,
};

FunctionInstallForm.defaultProps = {
    mode: 'install',
    id: undefined,
};
