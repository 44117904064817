import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tab, Tabset } from 'react-rainbow-components';
import { Roles, Lock } from '@rainbow-modules/icons';
import {
    StyledTabLabel, StyledIcon,
} from './styled';

const AuthTabset = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { projectId } = useParams();
    const splittedPathName = pathname.split('/');
    const selected = splittedPathName[5] || 'users';

    const handleOnSelect = (event, name) => {
        history.push(`/app/project/${projectId}/auth/${name}`);
    };

    return (
        <Tabset variant="line" onSelect={handleOnSelect} activeTabName={selected}>
            <Tab
                name="users"
                label={(
                    <StyledTabLabel>
                        <StyledIcon />
                        Users
                    </StyledTabLabel>
                )}
            />
            <Tab
                name="roles"
                label={(
                    <StyledTabLabel>
                        <StyledIcon as={Roles} />
                        Roles
                    </StyledTabLabel>
                )}
            />
            <Tab
                name="permissions"
                label={(
                    <StyledTabLabel>
                        <StyledIcon as={Lock} />
                        Permissions
                    </StyledTabLabel>
                )}
            />
        </Tabset>
    );
};

export default AuthTabset;
