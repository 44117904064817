import { useReduxForm } from 'react-rainbow-components/libs/hooks';
import SecretLookup from '../SecretLookup';

const FirestoreDocumentInput = (props) => {
    const {
        value, onChange, ...rest
    } = useReduxForm(props);

    const onChangeMiddleware = (newValue) => {
        if (newValue) {
            if (newValue?.type === 'secret') {
                return onChange(newValue);
            }
            return onChange({ ...newValue, type: 'firestoreDocumentPath' });
        }
        return onChange(newValue);
    };

    return (
        <SecretLookup
            value={value}
            onChange={onChangeMiddleware}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        />
    );
};

export default FirestoreDocumentInput;
