import styled from 'styled-components';
import Http from '../Icons/http';
import { DARK_WHITE } from '../../colors';

export const StyledIcon = styled(Http)`
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    color: ${DARK_WHITE};
`;

export const TriggerContent = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-right: 8px;
    min-width: 0;
`;

export const TriggerDescription = styled.span`
    text-align: left;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TriggerTitle = styled.span`
    text-align: left;
    margin-bottom: 6px;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const IconContainer = styled.div`
    display: flex;
    margin-right: 8px;
`;
