import MarkdownOutputDark from '../../../components/MarkdownOutputDark';
import { TabsetContent } from './styled';

// eslint-disable-next-line react/prop-types
export default function Documentation({ docs }) {
    return (
        <TabsetContent>
            <MarkdownOutputDark value={docs} />
        </TabsetContent>
    );
}
