import { useRef, useState } from 'react';
import {
    ButtonIcon,
} from 'react-rainbow-components';
import PropTypes from 'prop-types';
import InternalTooltip from 'react-rainbow-components/components/InternalTooltip';
import { TextTooltip } from './styled';

const ButtonIconTooltip = (props) => {
    const {
        text, onClick, icon, ...rest
    } = props;
    const triggerRef = useRef();
    const tooltipRef = useRef();
    const [isVisible, setVisible] = useState(false);

    const onMouseEnter = () => {
        setVisible(true);
    };

    const onMouseLeave = () => {
        setVisible(false);
    };

    return (
        <>
            <ButtonIcon
                ref={triggerRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                icon={icon}
                onClick={onClick}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            />
            <InternalTooltip
                triggerElementRef={() => triggerRef.current.htmlElementRef}
                isVisible={isVisible}
                ref={tooltipRef}
                preferredPosition="top"
            >
                <TextTooltip>
                    {text}
                </TextTooltip>
            </InternalTooltip>
        </>

    );
};

export default ButtonIconTooltip;

ButtonIconTooltip.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

ButtonIconTooltip.defaultProps = {
    onClick: () => {},
};
