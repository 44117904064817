import PropTypes from 'prop-types';
import { StatusInProgressIcon } from './styled';

const ProgressIcon = ({ size, sizeInPx, color }) => (
    <StatusInProgressIcon type="arc" variant="brand" size={size} height={sizeInPx} width={sizeInPx} color={color} />
);

ProgressIcon.propTypes = {
    size: PropTypes.string,
    sizeInPx: PropTypes.string,
    color: PropTypes.string,
};

ProgressIcon.defaultProps = {
    size: 'xx-small',
    sizeInPx: undefined,
    color: undefined,
};

export default ProgressIcon;
