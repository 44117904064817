import { useCallback } from 'react';
import { useUpdateDoc } from '@rainbow-modules/firebase-hooks';
import { useMutationFlow } from '@rainbow-modules/hooks';

const useDisableDeployment = () => {
    const { mutate: updateDoc } = useUpdateDoc();
    const { mutate: disableDeployment } = useMutationFlow({
        mutation: updateDoc,
        successMessage: 'The flow was disable successfully',
    });
    return useCallback(
        async ({ id, workspace }) => disableDeployment({ path: `workspaces/${workspace}/deployments/${id}`, data: { status: 'disabled' } }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};

export default useDisableDeployment;
