import styled from 'styled-components';
import { Spinner } from 'react-rainbow-components';

export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    top: unset;
    left: unset;
    display: inline-block;
    align-self: center;
    box-sizing: border-box;
    transform: unset;
    margin-bottom: 8px;
`;

export const MessageContainer = styled.div`
    font-size: 24px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;
