import {
    MenuItem,
    RenderIf,
} from 'react-rainbow-components';
import {
    PencilFilled, TrashFilled, ArrowLink, Disabled,
} from '@rainbow-modules/icons';
import PropTypes from 'prop-types';

import {
    OnIcon,
} from './styled';

const MenuAction = ({
    onViewDetails,
    onEdit,
    onEnable,
    onDisable,
    onDelete,
    status,
}) => (
    <>
        <MenuItem
            label="View Details"
            onClick={onViewDetails}
            icon={<ArrowLink className="rainbow-m-bottom_xx-small" />}
        />
        <MenuItem
            label="Edit"
            icon={<PencilFilled className="rainbow-m-bottom_xx-small" />}
            onClick={onEdit}
        />
        <RenderIf isTrue={status !== 'active'}>
            <MenuItem
                label="Enable"
                icon={<OnIcon />}
                onClick={onEnable}
            />
        </RenderIf>
        <RenderIf isTrue={status !== 'disabled'}>
            <MenuItem
                label="Disable"
                icon={<Disabled className="rainbow-m-bottom_xx-small" />}
                onClick={onDisable}
            />
        </RenderIf>
        <MenuItem label="Delete" onClick={onDelete} icon={<TrashFilled className="rainbow-m-bottom_xx-small" />} />
    </>
);

MenuAction.propTypes = {
    onViewDetails: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onEnable: PropTypes.func.isRequired,
    onDisable: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
};

export default MenuAction;
