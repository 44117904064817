/* eslint-disable react/prop-types */
import {
    FunctionInfoHeaderContainer,
    FunctionIcon,
    TestHeaderSecondary,
    TestHeaderRowTitle,
} from './styled';

export default function FunctionInfoHeader(props) {
    const { functionName } = props;

    return (
        <FunctionInfoHeaderContainer>
            <FunctionIcon />
            <div>
                <TestHeaderSecondary>
                    FUNCTION
                </TestHeaderSecondary>
                <TestHeaderRowTitle data-cy="function-name">{functionName}</TestHeaderRowTitle>
            </div>
        </FunctionInfoHeaderContainer>
    );
}
