import PropTypes from 'prop-types';
import { MarkdownOutput } from 'react-rainbow-components';
import styled from 'styled-components';

const StyledMarkdownOutput = styled(MarkdownOutput)`
    font-size: 0.875rem;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

const MarkdownLabel = ({ value }) => <StyledMarkdownOutput value={value} variant="inline" />;

MarkdownLabel.propTypes = {
    value: PropTypes.string,
};

MarkdownLabel.defaultProps = {
    value: undefined,
};

export default MarkdownLabel;
