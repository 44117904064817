import useCallableQuery from './useCallableQuery';

const useAccountOnboardingLink = ({ disabledReason }) => {
    const { data = {}, isLoading } = useCallableQuery('xStripe_getAccountOnboardingLink', {}, {
        enabled: typeof disabledReason === 'string',
    });
    return [data, isLoading];
};

export default useAccountOnboardingLink;
