import styled from 'styled-components';
import { ResizableColumns } from '@rainbow-modules/layout';
import { Spinner, Badge } from 'react-rainbow-components';
import {
    CheckCircleFilled, ErrorCircleFilled, Point, Function, ClockFilled,
} from '@rainbow-modules/icons';
import InfoCard from '../../../../../components/InfoCard';
import Skip from '../../../../../components/Icons/skip';
import Build from '../../../../../components/Icons/build';
import Minus from '../../../../../components/Icons/minus';
import {
    RED, GREEN, MADISON, ROMAN_SILVER, WHITE, DARK_GRAY, ORANGE,
} from '../../../../../colors';
import Clock from '../../../../../components/Icons/clock';

const getPropValue = (props, propName, fallback) => (props[propName] || fallback);

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100%;
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    padding: 22px 0 12px 20px;
`;

export const BuildIcon = styled(Build)`
    width: 32px;
    height: 32px;
    color: ${DARK_GRAY};
    margin-right: 12px;
`;

export const SecondaryText = styled.h2`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const PrimaryText = styled.h2`
    font-family: 'Lato Bold', Arial, sans-serif;
    font-size: 18px;
    line-height: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 12px;
`;

export const PrimaryContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const LoadingContainer = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

export const Gradient = styled.div`
    width: 10px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.8) 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 370px;
`;

export const StyledResizableColumns = styled(ResizableColumns)`
    display: flex;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    margin-top: 12px;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const StatusFailedIcon = styled(ErrorCircleFilled)`
    color: ${(props) => getPropValue(props, 'color', RED)};
    width: ${(props) => getPropValue(props, 'sizeInPx', '18px')};
    height: ${(props) => getPropValue(props, 'sizeInPx', '18px')};
`;

export const StatusSuccessIcon = styled(CheckCircleFilled)`
    color: ${(props) => getPropValue(props, 'color', GREEN)};
    width: ${(props) => getPropValue(props, 'sizeInPx', '18px')};
    height: ${(props) => getPropValue(props, 'sizeInPx', '18px')};
`;

export const StatusPendingIcon = styled(Point)`
    color: ${(props) => getPropValue(props, 'color', ROMAN_SILVER)};
    width: ${(props) => getPropValue(props, 'sizeInPx', '18px')};
    height: ${(props) => getPropValue(props, 'sizeInPx', '18px')};
`;

export const FailedIcon = styled(ErrorCircleFilled)`
    color: ${RED};
    width: 18px;
    height: 18px;

    ${(props) => props.size === 'medium' && `
        width: 22px;
        height: 22px;
    `};

    ${(props) => props.size === 'x-small' && `
        width: 14px;
        height: 14px;
    `};
`;

export const SuccessIcon = styled(CheckCircleFilled)`
    color: ${GREEN};
    width: 18px;
    height: 18px;

    ${(props) => props.size === 'medium' && `
        width: 22px;
        height: 22px;
    `};

    ${(props) => props.size === 'x-small' && `
        width: 14px;
        height: 14px;
    `};
`;

export const QueuedIcon = styled(ClockFilled)`
    color: ${ROMAN_SILVER};
    width: 18px;
    height: 18px;

    ${(props) => props.size === 'medium' && `
        width: 22px;
        height: 22px;
    `};
`;

export const SkipIcon = styled(Skip)`
    color: ${ORANGE};
    width: 18px;
    height: 18px;

    ${(props) => props.size === 'medium' && `
        width: 22px;
        height: 22px;
    `};

    ${(props) => props.size === 'x-small' && `
        width: 14px;
        height: 14px;
    `};
`;

export const MinusIcon = styled(Minus)`
    color: ${ROMAN_SILVER};
    width: 18px;
    height: 18px;

    ${(props) => props.size === 'medium' && `
        width: 22px;
        height: 22px;
    `};

    ${(props) => props.size === 'x-small' && `
        width: 14px;
        height: 14px;
    `};
`;

export const StatusInProgressIcon = styled(Spinner)`
    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    transform: unset;

    svg {
        width: ${(props) => getPropValue(props, 'width', '18px')};
        height: ${(props) => getPropValue(props, 'height', '18px')};
    }
`;

export const StatusIconContainer = styled.div`
    margin-right: 8px;

    ${(props) => props.size === 'medium' && `
        margin-right: 12px;
    `};
`;

export const LeftColumn = styled.div`
    background: ${WHITE};
    padding-top: 8px;
    height: 100%;
    overflow: auto;
`;

export const TreeLabel = styled.h5`
    font-size: 12px;
    text-transform: uppercase;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 8px 12px 8px 20px;
`;

export const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 12px 20px 16px;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    background: ${WHITE};
`;

export const Title = styled.h3`
    margin: 0 13px 0 0;
    display: flex;
    flex: 1;
    font-weight: bold;
    color: ${(props) => props.theme.rainbow.palette.text.label};

    p {
        font-size: 18px;
    }
`;

export const EllapsedTimeContainer = styled.span`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: ${MADISON};

    svg {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        color: ${ROMAN_SILVER};
    }
`;

export const CenteredContainer = styled.div`
    display: flex;
    flex: 1;
    max-height: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

export const CenteredMessage = styled.span`
    font-size: 24px;
    margin-top: 24px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${MADISON};
`;

export const CheckDetailsContainer = styled.div`
    overflow: auto;
    box-sizing: border-box;
    margin: 5px 12px 20px 20px;
`;

export const StyledInfoCard = styled(InfoCard)`
    box-shadow: 0 0 0 transparent;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    margin: 10px;
`;

export const CheckDetails = styled.div`
    margin: 0 0 36px;
`;

export const CheckTitle = styled.h4`
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0 8px 8px;
    color: ${MADISON};
`;

export const StatusBadgeContainer = styled(Badge)`
    margin-left: 4px;
    padding: 0 4px 0 2px;
    position: relative;
    line-height: 15px;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        width: 12px;
        height: 12px;
        margin-right: 4px;
    }

    ${(props) => (['success', 'succeeded'].includes(props.label))
        && `
            color: ${WHITE};
            background: ${GREEN};
        `};

    ${(props) => props.label === 'failed'
        && `
            color: ${WHITE};
            background: ${RED};
        `};

    ${(props) => props.label === 'running'
        && `
            color: ${WHITE};
            background: ${props.theme.rainbow.palette.brand.main};
            padding-left: 2px;
        `};
 
    ${(props) => props.label === 'queued'
        && `
            color: ${WHITE};
            background: ${props.theme.rainbow.palette.border.main};
        `};
`;

export const StyledSpinner = styled(Spinner)`
    position: unset;
    top: unset;
    left: unset;
    z-index: unset;
    display: inline-block;
    align-self: center;
    box-sizing: border-box;
    transform: unset;
`;

export const StyledFunctionIcon = styled(Function)`
    width: 72px;
    height: 72px;
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 8px;
`;

export const StyledQueuedIcon = styled(Clock)`
    width: 72px;
    height: 72px;
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 8px;
`;

export const TilesContainer = styled.header`
    display: flex;
    margin: 12px 16px 0 16px;
    flex-wrap: wrap;
`;

export const StyledBadge = styled(Badge)`
    background: ${(props) => (props.variant === 'error' ? RED : GREEN)};
    color: ${WHITE};
    margin-right: 12px;
    flex-shrink: 0;
`;
