const getNormalizedRuntime = (runtimeConfig) => {
    const {
        memory, minInstances, maxInstances, timeoutSeconds,
    } = runtimeConfig;
    const normalizedRuntime = {
        memory: memory.name,
        timeoutSeconds: Number(timeoutSeconds),
    };
    if (minInstances !== undefined && minInstances !== null) {
        normalizedRuntime.minInstances = Number(minInstances);
    }
    if (maxInstances !== undefined && maxInstances !== null) {
        normalizedRuntime.maxInstances = Number(maxInstances);
    }
    return normalizedRuntime;
};

export default getNormalizedRuntime;
