import { RenderIf } from 'react-rainbow-components';
import BadgeText from '../../../../../../components/BadgeText';
import { WORKSPACE_CI_PATH } from '../../../../../../constants';
import {
    TestRow,
    StyledBadge,
    SecondaryCodeText,
    PrimaryCodeTextBold,
    StyledCodeBlock,
    SecondaryCodeTextLight,
    FileTestResults,
    TestItem,
} from './styled';

const variantMap = {
    failed: 'error',
    passed: 'success',
};

const SuiteText = (props) => {
    const {
        // eslint-disable-next-line react/prop-types
        ancestorTitles = [], title, duration, status, hideAncestorTitles,
    } = props;
    const indentationFactor = ancestorTitles.length;

    return (
        <>
            <RenderIf isTrue={!hideAncestorTitles}>
                {ancestorTitles.map((text, index) => {
                    const key = `${text}-${index}`;
                    return (
                        <PrimaryCodeTextBold
                            key={key}
                            indentationFactor={index}
                        >
                            {text}
                        </PrimaryCodeTextBold>
                    );
                })}
            </RenderIf>
            <TestItem indentationFactor={indentationFactor} data-cy="test-item">
                <BadgeText
                    variant={variantMap[status]}
                    lassName="rainbow-m-right_xx-small rainbow-m-top_xx-small"
                />
                <SecondaryCodeTextLight>{`${title} (${duration} ms)`}</SecondaryCodeTextLight>
            </TestItem>
        </>
    );
};

const TestList = ({ data, variant = 'all' }) => data.map((item) => {
    const { testFilePath, testResults, failureMessage } = item;
    const filePath = testFilePath.replace(WORKSPACE_CI_PATH, '');
    const label = failureMessage ? 'FAIL' : 'PASS';
    const variantBadge = failureMessage ? 'error' : 'success';
    const onlyFailed = variant === 'failed';
    const isAll = variant === 'all';

    return (
        <TestRow key={testFilePath} className="rainbow-m-bottom_medium rainbow-m-horizontal_x-large" data-cy="test-row">
            <StyledBadge size="small" label={label} variant={variantBadge} />
            <FileTestResults>
                <SecondaryCodeText className="rainbow-m-bottom_x-small" data-cy="file-path">
                    {filePath}
                </SecondaryCodeText>
                {testResults.reduce((acc, result, index) => {
                    const {
                        status, ancestorTitles, title, duration, fullName,
                    } = result;
                    const key = `${fullName}-${index}`;
                    const hideAncestorTitles = acc.some((testResult) => {
                        if (
                            testResult
                            && testResult.props
                            && Array.isArray(testResult.props.ancestorTitles)
                        ) {
                            return testResult.props.ancestorTitles.join() === ancestorTitles.join();
                        }
                        return false;
                    });
                    if (isAll || (onlyFailed && status === 'failed')) {
                        acc.push((
                            <SuiteText
                                key={key}
                                ancestorTitles={ancestorTitles}
                                title={title}
                                duration={duration}
                                status={status}
                                hideAncestorTitles={hideAncestorTitles}
                            />
                        ));
                    }
                    return acc;
                }, [])}
                <RenderIf isTrue={onlyFailed && failureMessage}>
                    <StyledCodeBlock>{failureMessage}</StyledCodeBlock>
                </RenderIf>
            </FileTestResults>
        </TestRow>
    );
});

export default TestList;
