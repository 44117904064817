import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import {
    WHITE,
    GREEN,
    GRAY,
    LIGHT_GRAY,
} from '../../colors';
import Logo from '../Icons/logo';
import Folder from '../Icons/fileTree/folderClose';

export const DocumentationContainer = styled.div`
    width: 70%;
    margin-bottom: 32px;
`;

export const SecondaryInformation = styled.div`
    width: 30%;
    padding: 24px 0 0 70px;
`;

export const SecondaryInformationTitle = styled.h3`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const SecondaryInformationDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;
    text-transform: capitalize;
`;

export const WorkspaceName = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;
`;

export const FreeBadge = styled(Badge)`
    color: ${WHITE};
    background: ${GREEN};
`;

export const BoughtBadge = styled(Badge)`
    color: ${WHITE};
    background: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const LanguageBadge = styled(Badge)`
    color: ${GRAY};
    background-color: ${LIGHT_GRAY};
`;

export const ConditionBadge = styled(Badge)`
    background-color: ${GRAY};
    color: ${WHITE};
`;

export const TagsBadge = styled(Badge)`
    margin-left: 0 !important;
    margin-right: 4px;

    :hover {
        cursor: pointer;
    }
`;

export const SecondaryInformationCode = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Menlo', Arial, Helvetica, sans-serif;
`;

export const StyledLogo = styled(Logo)`
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
`;

export const WorkspaceIcon = styled(Folder)`
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
`;
