import styled from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';
import { BORDER_RADIUS_1 } from 'react-rainbow-components/styles/borderRadius';
import { FONT_SIZE_TEXT_SMALL } from 'react-rainbow-components/styles/fontSizes';
import { ErrorCircleFilled, Plus } from '@rainbow-modules/icons';
import { Draggable } from 'react-beautiful-dnd';
import { WHITE } from '../../colors';
import Policy from '../Icons/policyIcon';

export const Icon = styled(Policy)`
    height: 64px;
    width: 64px;
    flex-shrink: 0;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const Label = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 6px;
    margin-left: 4px;
`;

export const PoliciesContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: rgba(82, 95, 127, 0.05);
    border-radius: 8px;
    box-sizing: content-box;
    padding: 12px 12px 4px 16px;
    overflow-x: scroll;
    margin-bottom: 10px;
`;

export const DraggableContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`;

export const StyledDraggable = styled(Draggable)`
    outline: none;

    &:hover,
    &:focus,
    &:active {
        color: ${(props) => props.theme.rainbow.palette.brand.dark};
        border-color: ${(props) => props.theme.rainbow.palette.brand.dark};
    }
`;

export const ItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none;
    border: 1px solid transparent;
    border-radius: ${BORDER_RADIUS_1};
    padding: 0.5rem 0.25rem;
    width: 75px;
    margin-left: -8px;

    &:focus {
        outline: 0;
        border-color: ${(props) => props.theme.rainbow.palette.brand.dark};
        box-shadow: ${(props) => props.theme.rainbow.shadows.brand};
    }
`;

export const AddItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    padding: 0.4rem 0.85rem 1.75rem 0.4rem;
`;

export const ItemIconContainer = styled.div`
    font: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    background: transparent;
    background-clip: border-box;
    border-radius: ${BORDER_RADIUS_1};
    line-height: 1.875rem;
    text-decoration: none;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    cursor: ${(props) => (props.readOnly ? 'pointer' : 'grab')};
    white-space: normal;
    user-select: none;
    vertical-align: middle;
    overflow: visible;
    text-transform: none;
    appearance: none;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 0 3px transparent;
    border: 2px solid transparent;

    ::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    svg {
        width: 100%;
        height: 100%;
    }

    ${(props) => props.isActive && `
        box-shadow: 0 0 0 3px ${props.theme.rainbow.palette.brand.main};
        border: 2px solid transparent;
        border-radius: 12px;
    `}
`;

export const ItemButton = styled(ButtonIcon)`
    width: 32px;
    height: 32px;
    border: 2px solid ${(props) => props.theme.rainbow.palette.border.disabled};
`;

export const ItemText = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: ${FONT_SIZE_TEXT_SMALL};
    max-width: calc(100% + 0.45rem);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
    margin-left: 2px;

    ${(props) => props.isActive && `
        color: ${props.theme.rainbow.palette.brand.main};
        font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    `}
`;

export const StyledPlus = styled(Plus)`
    width: 75%;
    height: 75%;
`;

export const DividerContainer = styled.div`
    width: 62px;
    margin: 0 0 20px -8px;
    display: flex;
    align-items: center;
    z-index: 10;
`;

export const Dot = styled.div`
    width: 16px;
    height: 16px;
    background: rgb(201, 207, 214);
    border-radius: 20px;
    flex-shrink: 0;
    border: 4px solid ${(props) => props.theme.rainbow.palette.background.secondary};
    z-index: 20;
    position: absolute;
    top: 17px;

    ${(props) => props.isActive && `
        background: ${props.theme.rainbow.palette.brand.main};
    `}
`;

export const LeftDot = styled(Dot)`
    left: -22px;
`;

export const RightDot = styled(Dot)`
    right: -22px;
`;

export const Line = styled.div`
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, rgba(233, 238, 242, 1) 0%, rgba(191, 197, 204, 1) 100%);
    margin: 0 4px;
`;

export const RemoveButton = styled(ButtonIcon)`
    position: absolute;
    top: 4px;
    right: 5px;
    background: ${(props) => props.theme.rainbow.palette.border.main};
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.main};

    :hover {
        background: ${(props) => props.theme.rainbow.palette.border.main};
        border: 1px solid ${(props) => props.theme.rainbow.palette.border.main};
    }
`;

export const ErrorIcon = styled(ErrorCircleFilled)`
    color: ${WHITE};
    background-color: ${(props) => props.theme.rainbow.palette.error.main};
    position: absolute;
    top: 2px;
    left: 5px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: ${(props) => props.theme.rainbow.shadows.error};
`;

export const ErrorText = styled.div`
    font-size: 0.875rem;
    margin-top: 0.5rem;
    align-self: start;
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;
