import isValidAccordingToSchema from '../../helpers/isValidAccordingToSchema';

/* eslint-disable import/prefer-default-export */
export const validateConfig = (newValue, configSchema) => {
    try {
        const valueObj = typeof newValue === 'string' ? JSON.parse(newValue) : newValue;
        if (configSchema && !isValidAccordingToSchema(valueObj, configSchema)) {
            return 'Schema validation failed';
        }
    } catch (e) {
        return e.message;
    }
    return false;
};
