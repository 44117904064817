import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import { useParams } from 'react-router-dom';
import { Step } from '@rainbow-modules/content';
import CopyToClipboardServiceAccount from '../../components/CopyToClipboardServiceAccount';
import Step2 from './images/step2.png';
import Step3 from './images/step3.png';
import Step4 from './images/console-step4.png';
import Step5 from './images/console-step5.png';
import Step6 from './images/console-step6.png';
import Step7 from './images/console-step7.png';
import {
    Content,
    Header,
    ContentTitle,
    ContentDescription,
    StepDescription,
    StyledImage,
    ItemContainer,
    Line,
    ItemLabel,
    ItemDescription,
    ButtonLink,
    StyledCopyToClipboardButton,
    Highlight,
} from './styled';

export default function Console({ onCheck }) {
    const { projectId } = useParams();

    return (
        <Content>
            <Header>
                <div>
                    <ContentTitle>
                        Setting Up your project in the GCP Console
                    </ContentTitle>
                    <ContentDescription>
                        The following steps will guide you to grant IAM roles to
                        your service account using the GCP Console.
                        <br />
                        These are required to begin installing functions.store functions.
                    </ContentDescription>
                </div>
                <Button
                    label="Check"
                    variant="brand"
                    onClick={onCheck}
                />
            </Header>

            <Step
                number="1"
                header="In the GCP Console, go to the IAM page."
            >
                <ButtonLink
                    href={`https://console.cloud.google.com/iam-admin/iam?project=${projectId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Go to the IAM page
                </ButtonLink>
            </Step>

            <Step
                number="2"
                header="Go to the project selector."
            >
                <StepDescription>
                    Click the drop-down menu if you don&apos;t see the
                    name of your project at the top of the page.
                </StepDescription>
                <StyledImage src={Step2} width={600} height={600} />
            </Step>

            <Step
                number="3"
                header="Select the project or organization to which you want to grant access."
            >
                <StepDescription>
                    In the project selector, select the project name and click the
                    <Highlight>
                        Open
                    </Highlight>
                    button.
                </StepDescription>
                <StyledImage src={Step3} width={600} height={600} />
            </Step>

            <Step
                number="4"
                header="In the main content area, click the Add button."
            >
                <StepDescription>
                    In this section, you can add principals and then roles
                    to grant them access to your resources.
                    <br />
                    Note that in this case, you will be adding a service account as a principal.
                </StepDescription>
                <StyledImage src={Step4} width={600} height={600} />
            </Step>

            <Step
                number="5"
                header="Add the Service Account."
            >
                <StepDescription>
                    You can find the Service account info in the upper right
                    corner of this page, or copy it from below.
                </StepDescription>
                <CopyToClipboardServiceAccount />
                <StepDescription className="rainbow-m-top_large">
                    Paste it in the
                    <Highlight>
                        New principals
                    </Highlight>
                    box.
                </StepDescription>
                <StyledImage src={Step5} width={600} height={600} />
            </Step>

            <Step
                number="6"
                header="Assign Roles to the Service account."
            >
                <StepDescription>
                    The
                    <Highlight>
                        Select a role
                    </Highlight>
                    drop-down menu, contains the roles with a short description
                    of the permissions they grant.
                </StepDescription>
                <StyledImage src={Step6} width={600} height={600} />
                <StepDescription className="rainbow-m-top_large">
                    Assign the following roles to the service account:
                </StepDescription>
                <ItemContainer>
                    <Line />
                    <div>
                        <ItemLabel>
                            Cloud Functions Admin
                            <StyledCopyToClipboardButton value="Cloud Functions Admin" />
                        </ItemLabel>
                        <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                            Full access to functions, operations and locations.
                        </ItemDescription>
                    </div>
                </ItemContainer>
                <ItemContainer>
                    <Line />
                    <div>
                        <ItemLabel>
                            Firebase Authentication Admin
                            <StyledCopyToClipboardButton value="Firebase Authentication Admin" />
                        </ItemLabel>
                        <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                            Full read/write access to Firebase Authentication resources.
                        </ItemDescription>
                    </div>
                </ItemContainer>
                <ItemContainer>
                    <Line />
                    <div>
                        <ItemLabel>
                            Secret Manager Admin
                            <StyledCopyToClipboardButton value="Secret Manager Admin" />
                        </ItemLabel>
                        <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                            Full access to administer Secret Manager resources.
                        </ItemDescription>
                    </div>
                </ItemContainer>
                <ItemContainer>
                    <Line />
                    <div>
                        <ItemLabel>
                            Service Account User
                            <StyledCopyToClipboardButton value="Service Account User" />
                        </ItemLabel>
                        <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                            Run operations as the service account.
                        </ItemDescription>
                    </div>
                </ItemContainer>
                <ItemContainer>
                    <Line />
                    <div>
                        <ItemLabel>
                            Service Usage Admin
                            <StyledCopyToClipboardButton value="Service Usage Admin" />
                        </ItemLabel>
                        <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                            Ability to enable, disable, and inspect service states,
                            inspect operations, and consume quota and billing for
                            a consumer project.
                        </ItemDescription>
                    </div>
                </ItemContainer>
            </Step>

            <Step
                number="7"
                header="Click the Save button."
            >
                <StepDescription>
                    Verify that the principal and the corresponding role
                    are listed in the IAM page.
                </StepDescription>
                <StyledImage src={Step7} width={600} height={600} />
            </Step>
            <Step
                number="8"
                header="Click the Check button."
            >
                <StepDescription>
                    Then click the
                    <Highlight>
                        Check
                    </Highlight>
                    button and wait until the system verifies the permissions again.
                </StepDescription>
                <Button
                    label="Check"
                    variant="brand"
                    onClick={onCheck}
                    className="rainbow-m-top_medium"
                />
            </Step>
        </Content>
    );
}

Console.propTypes = {
    onCheck: PropTypes.func,
};

Console.defaultProps = {
    onCheck: () => {},
};
