/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
    RenderIf, MenuItem, ButtonMenu, Badge, Button,
} from 'react-rainbow-components';
import { More } from '@rainbow-modules/icons';
import useCheckProject from '../../data/hooks/useCheckProject';
import useProjectActions from '../../data/hooks/useProjectActions';
import On from '../../components/Icons/on';
import Disable from '../../components/Icons/disable';
import {
    StyledCard,
    CardButton,
    ProjectId,
    Link,
    ProjectTitle,
    ProjectContent,
    ProjectIcon,
    ProjectCardHeader,
    ProjectInfo,
    StyledHelpText,
    ProjectCardHeaderRight,
    ProjectCardFooterRight,
    HelpTextBody,
    HelpTextContent,
    HelpTextTitle,
    MoreOptionIcon,
} from './styled';
import CheckingMessage from './checkingMessage';

const ProjectCard = ({
    // eslint-disable-next-line react/prop-types
    name, projectId, isDisabled,
}) => (
    <>
        <ProjectCardHeader>
            <ProjectIcon isDisabled={isDisabled} />
        </ProjectCardHeader>
        <ProjectContent>
            <ProjectTitle isDisabled={isDisabled} data-cy="project-title">{name}</ProjectTitle>
            <ProjectInfo>
                <ProjectId isDisabled={isDisabled}>{projectId}</ProjectId>
            </ProjectInfo>
        </ProjectContent>
    </>
);

const Item = (props) => {
    const {
        // eslint-disable-next-line react/prop-types
        name, projectId, setup, isDisabled,
    } = props;
    const checkProject = useCheckProject(projectId);

    if (setup || isDisabled) {
        return (
            <CardButton
                type="button"
                disabled={isDisabled}
                onClick={() => checkProject({
                    spinnerMessage: <CheckingMessage projectId={projectId} />,
                })}
                data-cy="project-item"
            >
                <ProjectCard
                    isDisabled={isDisabled}
                    name={name}
                    projectId={projectId}
                    setup={setup}
                />
            </CardButton>
        );
    }

    return (
        <Link
            to={{
                pathname: `/app/project/${projectId}/setup`,
                state: {
                    projectId,
                    projectName: name,
                },
            }}
            disabled={isDisabled}
            data-cy="project-item"
        >
            <ProjectCard
                name={name}
                projectId={projectId}
                setup={setup}
                isDisabled={isDisabled}
            />
        </Link>
    );
};

const ProjectItem = (props) => {
    const {
        setup, active, projectId, hasReachedLimit,
    } = props;
    const { push } = useHistory();
    const { activate, deactivate } = useProjectActions({ projectId });

    const isInactive = active === false;

    return (
        <StyledCard isInactive={isInactive}>
            <div data-cy="project-card-content">
                <ProjectCardHeaderRight>
                    <RenderIf isTrue={isInactive}>
                        <Badge label="ARCHIVED" />
                    </RenderIf>
                    <ButtonMenu
                        menuSize="x-small"
                        buttonVariant="base"
                        menuAlignment="right"
                        className="rainbow-m-right_xx-small"
                        icon={<More />}
                    >
                        <MenuItem
                            label="Project Settings"
                            variant="header"
                        />
                        <RenderIf isTrue={!setup}>
                            <MenuItem
                                icon={<MoreOptionIcon />}
                                label="Set up Project"
                                onClick={() => push(`/app/project/${projectId}/setup`)}
                            />
                        </RenderIf>
                        <RenderIf isTrue={isInactive}>
                            <MenuItem
                                icon={<MoreOptionIcon as={On} />}
                                label="Activate Project"
                                onClick={() => activate()}
                                disabled={hasReachedLimit}
                            />
                        </RenderIf>
                        <RenderIf isTrue={active}>
                            <MenuItem
                                icon={<MoreOptionIcon as={Disable} />}
                                label="Archive Project"
                                onClick={() => deactivate()}
                            />
                        </RenderIf>
                    </ButtonMenu>
                </ProjectCardHeaderRight>
                <Item {...props} isDisabled={isInactive} />
                <RenderIf isTrue={!setup}>
                    <ProjectCardFooterRight>
                        <StyledHelpText
                            id="help-text-need-setup-warning"
                            className="rainbow-m-left_small"
                            variant="warning"
                            text={(
                                <HelpTextBody>
                                    <HelpTextTitle>
                                        This project needs “Set Up”
                                    </HelpTextTitle>
                                    <HelpTextContent>
                                        To use Function Store functions, you will need to
                                        grant us some permissions for your project and enable
                                        some APIs in the GCP Console.
                                        <Button
                                            variant="outline-brand"
                                            size="small"
                                            label="Set Up Project Now"
                                            className="rainbow-m-top_x-small"
                                            onClick={() => push(`/app/project/${projectId}/setup`)}
                                        />
                                    </HelpTextContent>
                                </HelpTextBody>
                            )}
                        />
                    </ProjectCardFooterRight>
                </RenderIf>
            </div>
        </StyledCard>
    );
};

ProjectItem.propTypes = {
    name: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    setup: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    hasReachedLimit: PropTypes.bool.isRequired,
};

export default ProjectItem;
