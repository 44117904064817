import PropTypes from 'prop-types';

export default function Tag(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            height="14px"
            width="14px"
            viewBox="0 0 14 14"
        >
            <g fill="none" fillOpacity=".61" fillRule="evenodd">
                <g fill="#061C3F" fillRule="nonzero">
                    <path
                        d="M362.828 151.008h-4.905c-.31 0-.609.124-.829.343l-6.385 6.385c-.457.457-.457 1.199 0 1.657l4.906 4.906c.457.457 1.199.457 1.656 0l6.386-6.385c.22-.22.343-.518.343-.83v-4.904c0-.647-.524-1.172-1.172-1.172zm-2.05 4.1c-.485 0-.879-.393-.879-.878s.394-.879.879-.879.879.394.879.88c0 .484-.394.878-.879.878z"
                        transform="translate(-350 -151) matrix(-1 0 0 1 714.366 0)"
                    />
                </g>
            </g>
        </svg>
    );
}

Tag.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Tag.defaultProps = {
    style: {},
    className: undefined,
};
