import PropTypes from 'prop-types';
import {
    ServicesContainer,
    ServiceContainer,
    Highlight,
    ItemContainer,
    ItemLabel,
    ItemDescription,
    StyledGranted,
    StyledNotGranted,
    StateLabel,
    Line,
} from './styled';

const requiredServices = {
    'cloudfunctions.googleapis.com': {
        name:
    <span>
        Enable
        <Highlight>&quot;Cloud Functions API &quot;</Highlight>
    </span>,
        description:
            'Your project needs the Cloud Function API enabled to install the functions executed in response to events.',
    },
    'cloudbuild.googleapis.com': {
        name:
    <span>
        Enable
        <Highlight>&quot;Cloud Build API &quot;</Highlight>
    </span>,
        description:
            'Your project needs the Cloud Build API enabled to deploy the installed functions.',
    },
    'secretmanager.googleapis.com': {
        name:
    <span>
        Enable
        <Highlight>&quot;Secret Manager API&quot;</Highlight>
    </span>,
        description:
            'Your project needs the Secret API enabled to manage and use Secrets within a project in a very simple, fast, and secure way.',
    },
};

// eslint-disable-next-line react/prop-types
const StateIcon = ({ state }) => {
    if (state === 'enabled') return <StyledGranted />;
    return <StyledNotGranted />;
};

const variantMap = {
    enabled: 'success',
    disabled: 'error',
};

const Service = ({
    // eslint-disable-next-line react/prop-types
    id, name, description, state,
}) => (
    <ServiceContainer data-id={id}>
        <ItemContainer>
            <Line variant={variantMap[state]} />
            <StateIcon state={state} />
            <div>
                <ItemLabel>{name}</ItemLabel>
                <ItemDescription>{description}</ItemDescription>
            </div>
        </ItemContainer>

        <StateLabel state={state} data-cy="state-label">{state}</StateLabel>
    </ServiceContainer>
);

export default function ServicesList({ services }) {
    const list = services.map(({ serviceId, state }, index) => {
        const key = `service${index}`;
        const service = {
            ...requiredServices[serviceId],
            state: state.toLowerCase(),
        };
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Service key={key} id={serviceId} {...service} />;
    });
    return (
        <ServicesContainer>
            {list}
        </ServicesContainer>
    );
}

ServicesList.propTypes = {
    services: PropTypes.array,
};

ServicesList.defaultProps = {
    services: [],
};
