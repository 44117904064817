import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Content = styled.div`
    text-align: center;
    width: 100%;
`;

export const CellContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const FunctionLastDeployedColumn = ({ value }) => (
    <CellContainer>
        <Content>{value}</Content>
    </CellContainer>
);

FunctionLastDeployedColumn.propTypes = {
    value: PropTypes.string.isRequired,
};

export default FunctionLastDeployedColumn;
