const reducer = (acc, envVariable) => {
    const { default: defaultValue, name, type } = envVariable;
    if (defaultValue) {
        return ({
            ...acc,
            [name]: {
                value: defaultValue,
                type: type || 'text',
            },
        });
    }
    if (type === 'boolean') {
        return ({
            ...acc,
            [name]: {
                value: false,
                type,
            },
        });
    }
    return acc;
};

const getEnvInitialValues = (envVariables = []) => envVariables.reduce(reducer, {});

export default getEnvInitialValues;
