import { useState } from 'react';
import { isEmpty } from '@rainbow-modules/validation';
import useCallableQuery from './useCallableQuery';

const compareStrings = (strA, strB) => {
    if (strA < strB) return -1;
    if (strA > strB) return 1;
    return 0;
};

const sortProjects = (projectA, projectB) => {
    const { isNew: isNewA, name: nameA } = projectA;
    const { isNew: isNewB, name: nameB } = projectB;
    if (isNewA && isNewB) {
        return compareStrings(nameA.toLowerCase(), nameB.toLowerCase());
    }

    if (isNewA) {
        return -1;
    }

    if (isNewB) {
        return 1;
    }

    return compareStrings(nameA.toLowerCase(), nameB.toLowerCase());
};

const projectMatch = (project, searchTerm) => (
    project.projectId.indexOf(searchTerm) !== -1 || project.name.indexOf(searchTerm) !== -1
);

const filterProjects = (projects, filter) => {
    if (isEmpty(filter)) {
        return projects;
    }
    return projects.filter((project) => projectMatch(project, filter));
};

export default function useGCPProjectsList() {
    const {
        data, isLoading: isLoadingProjects, isFetching,
    } = useCallableQuery(
        ['projects-listAll'],
        {},
        { staleTime: 60000 },
    );
    const [searchValue, setSearchValue] = useState();

    const search = async (searchTerm) => {
        if (searchTerm === searchValue) return;
        setSearchValue(searchTerm);
    };

    const isLoading = isLoadingProjects || isFetching;
    const projects = data || [];
    const projectsList = filterProjects(projects, searchValue);

    return {
        projects: projectsList.sort(sortProjects),
        isLoading,
        search,
        searchValue,
    };
}
