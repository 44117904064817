import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    Header,
    FunctionTitle,
    FunctionInfoContainer,
    DetailsContainer,
    FunctionName,
    FunctionDescription,
    FunctionCreator,
} from './styled';

const FunctionDrawerHeader = (props) => {
    const {
        title, name, icon, description, creator,
    } = props;
    const isTitleString = typeof title === 'string';
    return (
        <Header>
            <RenderIf isTrue={isTitleString}>
                <FunctionTitle title={title}>{title}</FunctionTitle>
            </RenderIf>
            <RenderIf isTrue={!isTitleString}>{title}</RenderIf>
            <FunctionInfoContainer>
                {icon}
                <DetailsContainer>
                    <FunctionName>{name}</FunctionName>
                    <FunctionCreator>{creator}</FunctionCreator>
                    <FunctionDescription>{description}</FunctionDescription>
                </DetailsContainer>
            </FunctionInfoContainer>
        </Header>
    );
};

FunctionDrawerHeader.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.node,
    description: PropTypes.string,
    creator: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FunctionDrawerHeader.defaultProps = {
    name: undefined,
    icon: undefined,
    description: undefined,
    creator: undefined,
    title: undefined,
};

export default FunctionDrawerHeader;
