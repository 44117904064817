import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Field } from 'react-final-form';
import { UniversalForm, composeValidators, isRequired } from '@rainbow-modules/forms';
import { Textarea, Button } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import firebaseCallable from '../../../data/services/firebaseCallable';
import { ADD_SECRET_VERSION_DRAWER } from '../../../constants';
import LabelWithDescription from '../../../components/LabelWithDescription';
import useMutationFlow from '../../../data/hooks/useMutationFlow';
import {
    Container, FormContainer, Footer, ItemContainer, Item,
} from './styled';
import encodeBase64 from './helpers/encodeBase64';

const FORM_ID = 'add-new-secret-version';

export default function SecretFormAddVersionMode({ secretName }) {
    const { projectId } = useParams();
    const [, closeModal] = useOpenModal(ADD_SECRET_VERSION_DRAWER);

    const { mutate } = useMutationFlow({
        mutation: (data) => firebaseCallable('secrets-addVersion', {
            projectId,
            ...data,
            secretValue: encodeBase64(data.secretValue),
        }),
        submitSpinnerMessage: 'Adding version',
        successMessage: null,
        onSuccess: closeModal,
    });

    return (
        <Container>
            <FormContainer>
                <UniversalForm
                    id={FORM_ID}
                    keepDirtyOnReinitialize
                    onSubmit={mutate}
                    initialValues={{ secretName }}
                >
                    <ItemContainer>
                        <LabelWithDescription
                            name="Name"
                            description="The name should be identifiable and unique within this project."
                            className="rainbow-m-left_medium"
                        />
                        <Item className="rainbow-p-left_small">
                            {secretName}
                        </Item>
                    </ItemContainer>
                    <Field
                        label={(
                            <LabelWithDescription
                                name="Secret Value"
                                description="Input your secret value or import it directly from a file."
                            />
                        )}
                        validate={composeValidators(
                            isRequired('Secret value is required'),
                        )}
                        component={Textarea}
                        placeholder="Enter the secret value"
                        name="secretValue"
                        labelAlignment="left"
                        required
                        id="secret-value-input"
                    />
                </UniversalForm>
            </FormContainer>
            <Footer>
                <Button
                    className="rainbow-m-right_medium"
                    label="Cancel"
                    variant="base"
                    onClick={closeModal}
                />
                <Button label="Add" variant="brand" type="submit" form={FORM_ID} />
            </Footer>
        </Container>
    );
}

SecretFormAddVersionMode.propTypes = {
    secretName: PropTypes.string,
};

SecretFormAddVersionMode.defaultProps = {
    secretName: undefined,
};
