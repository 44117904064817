import firebase from '../firebase';

const analytics = firebase.analytics();

const logEvent = (...params) => {
    const { currentUser } = firebase.auth();
    if (currentUser && currentUser.uid) {
        analytics.setUserId(currentUser.uid);
    }
    return analytics.logEvent(...params);
};

export default logEvent;
