import styled from 'styled-components';
import { Badge } from 'react-rainbow-components';
import BuildIcon from '../../Icons/build';

export const StyledBuildIcon = styled(BuildIcon)`
    width: 20px;
    height: 20px;
`;

export const StyledBadge = styled(Badge)`
    height: 16px;
    line-height: 16px;
    margin-bottom: 4px;
    padding: 0 5px;
    margin-left: 8px;
`;

export const OptionContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: calc(100% - 2rem);
`;

export const BuildIdContainer = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const OptionDateContainer = styled.span`
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    color: #8592a0;
    text-overflow: ellipsis;
    overflow: hidden;
`;
