import getNormalizedPolicy from './getNormalizedPolicy';

const getNormalizedPolicies = (policies) => {
    if (Array.isArray(policies)) {
        return policies.map(getNormalizedPolicy);
    }
    return undefined;
};

export default getNormalizedPolicies;
