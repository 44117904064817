import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { RenderIf, Button } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { isEmpty } from '@rainbow-modules/validation';
import { FUNCTION_INSTALL_PREVIEW_DRAWER } from '../../constants';
import FunctionDrawerHeader from '../FunctionDrawerHeader';
import FunctionIcon from '../FunctionIcon';
import Label from '../LabelWithDescription';
import ProjectPicker from '../ProjectPicker';
import logSecretsUse from '../../analytics/logSecretsUse';
import firebaseCallable from '../../data/services/firebaseCallable';
import useMutationFlow from '../../data/hooks/useMutationFlow';
import logEvent from '../../analytics/logEvent';
import events from '../../analytics/events';
import getTriggerInitialValue from './helpers/getTriggerInitialValue';
import getEnvInitialValues from './helpers/getEnvInitialValues';
import getRuntimeInitialValues from './helpers/getRuntimeInitialValues';
import Form from './form';
import Loading from './loading';
import RegionPicker from '../RegionPicker';
import {
    Container,
    Header,
    Content,
    FormHeader,
    FormTitle,
    FormProject,
    PickerContainer,
    BottomGradient,
    Footer,
    SmallDocsLink,
} from './styled';

const FORM_ID = 'install-function';
const DEFAULT_INSTALL_REGION = 'us-central1';

const FunctionPreviewMode = ({ preview }) => {
    const {
        buildId, functionId, workspaceId, versionId,
    } = preview;
    const previewPath = `workspaces/${workspaceId}/functions/${functionId}/versions/${versionId}/builds/${buildId}`;
    const { push } = useHistory();
    const [projectId, setProjectId] = useState('');
    const [projectError, setProjectError] = useState();
    const [functionData, isLoading] = useDoc({
        path: previewPath,
        flat: true,
    });
    const [region, setRegion] = useState(DEFAULT_INSTALL_REGION);
    const [, closeModal] = useOpenModal(FUNCTION_INSTALL_PREVIEW_DRAWER);
    const {
        name, description, env, iconUrl, trigger,
    } = functionData || {};
    const initialValues = {
        ...getTriggerInitialValue(trigger),
        env: getEnvInitialValues(env),
        runtimeConfig: getRuntimeInitialValues(),
    };

    const { mutate } = useMutationFlow({
        mutation: (config) => {
            logSecretsUse(config.env);
            return firebaseCallable('functions-install', {
                functionId,
                isPreview: true,
                version: versionId,
                workspaceName: workspaceId,
                buildId,
                ...config,
            });
        },
        submitSpinnerMessage: 'Sending preview install request',
        successMessage: null,
        onSuccess: (res) => {
            logEvent(events.previews.INSTALLED);
            closeModal();
            push(`/app/project/${res.projectId}/functions/installed/${res.id}`, {
                iconUrl,
            });
        },
    });

    const handleInstallClick = () => {
        if (isEmpty(projectId)) {
            setProjectError('Project is required');
        }
    };

    const handleSwitchProject = ({ name: projectName }) => {
        setProjectError();
        setProjectId(projectName);
    };

    return (
        <Container>
            <Header>
                <FunctionDrawerHeader
                    name={name}
                    description={description}
                    icon={(
                        <FunctionIcon
                            url={iconUrl}
                            functionType="marketplace"
                            width="48px"
                            height="48px"
                        />
                    )}
                    title="Install Function Preview"
                />
            </Header>
            <Content>
                <FormProject>
                    <FormHeader>
                        <FormTitle>Project and Region</FormTitle>
                    </FormHeader>
                    <PickerContainer>
                        <div>
                            <Label
                                name="Select Project"
                                description={(
                                    <>
                                        Where do you want to install the function?
                                        Note that you can only install functions
                                        in projects that are already set up.
                                        <SmallDocsLink
                                            title=""
                                            href="https://docs.functions.store/functions/reference/projects/how-to-set-up-a-project"
                                            target="_blank"
                                        >
                                            How to set up projects?
                                        </SmallDocsLink>
                                    </>
                                )}
                            />
                            <ProjectPicker
                                value={projectId}
                                onChange={handleSwitchProject}
                                hideAllProjectsOption
                                showCheckedProjectsOnly
                                error={projectError}
                            />
                        </div>
                        <div>
                            <Label
                                name="Select Region"
                                description={(
                                    <>
                                        Where do you want to deploy this function?
                                        You usually want a region close to you.
                                        For help selecting a region, refer to the
                                        <SmallDocsLink
                                            title=""
                                            href="https://cloud.google.com/functions/docs/locations"
                                            target="_blank"
                                        >
                                            Region selection guide
                                        </SmallDocsLink>
                                    </>
                                )}
                            />
                            <RegionPicker
                                value={region}
                                onChange={setRegion}
                                projectId={projectId}
                                disabled={!projectId}
                            />
                        </div>
                    </PickerContainer>
                </FormProject>
                <RenderIf isTrue={isLoading && projectId}>
                    <Loading />
                </RenderIf>
                <RenderIf isTrue={!isLoading && projectId}>
                    <Form
                        id={FORM_ID}
                        projectId={projectId}
                        fields={{ trigger, env }}
                        onSubmit={(config) => mutate({ ...config, projectId, region })}
                        initialValues={initialValues}
                        keepDirtyOnReinitialize
                    />
                </RenderIf>
            </Content>
            <BottomGradient />
            <Footer>
                <Button
                    label="Cancel"
                    variant="base"
                    className="rainbow-m-right_medium"
                    onClick={closeModal}
                />
                <RenderIf isTrue={!isLoading && projectId}>
                    <Button
                        label="Install"
                        variant="brand"
                        type="submit"
                        form={FORM_ID}
                        onClick={handleInstallClick}
                    />
                </RenderIf>
            </Footer>
        </Container>
    );
};

FunctionPreviewMode.propTypes = {
    preview: PropTypes.object,
};

FunctionPreviewMode.defaultProps = {
    preview: undefined,
};

export default FunctionPreviewMode;
