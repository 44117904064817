import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Message from '../../../../components/Message';
import SourceCodeExplorer from '../../../../components/SourceCodeExplorer';
import {
    TabContent,
    MessageContainer,
    SourceCodeContainer,
    LockIcon,
} from '../styled';

const Source = ({ data: functionData, isPurchased }) => (
    <TabContent className="rainbow-m-top_medium">
        <SourceCodeContainer>
            <RenderIf isTrue={!isPurchased}>
                <MessageContainer>
                    <Message
                        icon={<LockIcon />}
                        title="If you want to see the Source Code of this function you first need to buy the function."
                    />
                </MessageContainer>
            </RenderIf>
            <RenderIf isTrue={isPurchased}>
                <SourceCodeExplorer functionData={functionData} />
            </RenderIf>
        </SourceCodeContainer>
    </TabContent>
);

Source.propTypes = {
    data: PropTypes.object,
    isPurchased: PropTypes.bool,
};

Source.defaultProps = {
    data: undefined,
    isPurchased: false,
};

export default Source;
