import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import useGCPProjectsList from '../../data/hooks/useGCPProjectsList';
import Message from '../../components/Message';
import ProjectCard from './projectCard';
import {
    ModalDescription,
    ModalContent,
    MessageContainer,
    StyledInputSearch,
    StyledSpinner,
    ProjectsListContainer,
} from './styled';

const ProjectList = ({ data, onCheck }) => data
// eslint-disable-next-line react/jsx-props-no-spreading
    .map((item) => <ProjectCard {...item} key={item.projectId} onCheck={onCheck} />);

export default function AvailableProjectsList({ onCheck }) {
    const {
        projects, isLoading, search, searchValue,
    } = useGCPProjectsList();
    const isEmptyProjectsList = isEmpty(projects);
    const shouldRenderSearch = (searchValue && isEmptyProjectsList) || !isEmptyProjectsList;

    return (
        <>
            <ModalDescription className="rainbow-m-right_large rainbow-m-left_large">
                <div>
                    List of projects availables to your Service Account.
                    Click &quot;Check&quot; to verify the required permissions to
                    use Function Store.
                </div>
                <RenderIf isTrue={shouldRenderSearch}>
                    <StyledInputSearch
                        id="all-projects-search"
                        className="rainbow-m-top_small"
                        onChange={search}
                        variant="realtime"
                        placeholder="Search for project"
                    />
                </RenderIf>
            </ModalDescription>
            <ModalContent>
                <RenderIf isTrue={isLoading}>
                    <MessageContainer>
                        <StyledSpinner type="arc" size="large" />
                    </MessageContainer>
                </RenderIf>
                <RenderIf isTrue={!isLoading && !isEmptyProjectsList}>
                    <ProjectsListContainer>
                        <ProjectList data={projects} onCheck={onCheck} />
                    </ProjectsListContainer>
                </RenderIf>
                <RenderIf isTrue={!isLoading && isEmptyProjectsList}>
                    <MessageContainer>
                        <Message title="No projects were found" />
                    </MessageContainer>
                </RenderIf>
            </ModalContent>
        </>
    );
}

AvailableProjectsList.propTypes = {
    onCheck: PropTypes.func,
};

AvailableProjectsList.defaultProps = {
    onCheck: () => {},
};
