import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Field, FormSpy, useForm } from 'react-final-form';
import { RadioGroup, Picklist, Option } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import { UniversalFormModal, isRequired } from '@rainbow-modules/forms';
import firebaseCallable from '../../../../data/services/firebaseCallable';
import useMutationFlow from '../../../../data/hooks/useMutationFlow';
import LabelWithDescription from '../../../../components/LabelWithDescription';
import formatBuildsData from '../../helpers/formatBuildsData';
import getFirstFib from '../../helpers/getFirstFib';
import { PUBLISH_WORKSPACE_FUNCTION_MODAL } from '../../../../constants';

const typeOptions = [
    { value: 'free', label: 'Free' },
    { value: 'paid', label: 'Paid' },
];

const firstFibNumbers = getFirstFib(10);

const Fields = ({
    // eslint-disable-next-line react/prop-types
    builds, functionType: initialFunctionType, price,
}) => {
    const form = useForm();
    const intl = useIntl();
    const [functionType, setFunctionType] = useState(initialFunctionType);
    const buildOptions = formatBuildsData(builds);
    const isFree = functionType === 'free';

    const formatCurrency = (value) => intl.formatNumber(value, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    useEffect(() => {
        if (isFree) {
            return form.change('amount', { name: 0, label: formatCurrency(0) });
        }
        return form.change('amount', { name: 300, label: formatCurrency(firstFibNumbers[0]) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFree, form]);

    useEffect(() => {
        form.change('amount', { name: price, label: formatCurrency(price / 100) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [price]);

    return (
        <div>
            <Field
                name="type"
                component={RadioGroup}
                label={(
                    <LabelWithDescription
                        name="Select Pricing"
                        description="Select if the function will be free or paid"
                        required
                    />
                )}
                required
                orientation="horizontal"
                options={typeOptions}
                validate={isRequired()}
                className="rainbow-m-bottom_large rainbow-m-left_small"
                initialValue={functionType}
                id="pricing-radiogroup"
            />
            <FormSpy onChange={({ values }) => setFunctionType(values.type)} />
            <Field
                name="amount"
                component={Picklist}
                label={(
                    <LabelWithDescription
                        name="Select Amount"
                        description="Amount that the function will cost"
                        required
                    />
                )}
                disabled={isFree}
                required
                labelAlignment="left"
                validate={isRequired()}
                className="rainbow-m-bottom_large"
                id="amount"
            >
                {firstFibNumbers.map((item) => (
                    <Option key={`item-${item}`} name={item * 100} label={formatCurrency(item)} />
                ))}
            </Field>
            <Field
                name="build"
                component={Picklist}
                label={(
                    <LabelWithDescription
                        name="Select Build"
                        description="The build to submit"
                        required
                    />
                )}
                required
                labelAlignment="left"
                validate={isRequired()}
                className="rainbow-m-bottom_large"
                initialValue={buildOptions[0]}
            >
                {buildOptions.map((item) => (
                    <Option key={`item-${item}`} name={item.name} label={item.label} />
                ))}
            </Field>
        </div>
    );
};

const publishFunction = (params) => {
    const {
        functionId, workspace, version, values,
    } = params;
    const { amount, build } = values;
    const data = {
        price: amount.name,
        buildId: build.name,
        workspace,
        functionId,
        version,
    };
    return firebaseCallable('functions-publish', data);
};

const PublishModal = (props) => {
    const {
        connectedPublishModalProps: { price, ...otherPublishModalProps },
        builds,
        workspace,
        functionId,
        version,
    } = props;
    const [, closeModal] = useOpenModal(PUBLISH_WORKSPACE_FUNCTION_MODAL);

    const functionType = price && price > 0 ? 'paid' : 'free';

    const { mutate } = useMutationFlow({
        mutation: publishFunction,
        submitSpinnerMessage: 'The function is being published',
        successMessage: 'The function has been published successfully',
        onSuccess: () => closeModal(),
    });

    const ModalBody = () => <Fields builds={builds} functionType={functionType} price={price} />;

    const initialValues = {
        type: functionType,
        amount: price / 100,
    };

    return (
        <UniversalFormModal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherPublishModalProps}
            fields={ModalBody}
            initialValues={initialValues}
            onSubmit={(values) => mutate({
                functionId,
                workspace,
                version,
                values,
            })}
            id="publish-modal"
        />
    );
};

PublishModal.propTypes = {
    connectedPublishModalProps: PropTypes.object,
    builds: PropTypes.array,
    workspace: PropTypes.string,
    functionId: PropTypes.string,
    version: PropTypes.string,
};
PublishModal.defaultProps = {
    connectedPublishModalProps: undefined,
    builds: [],
    workspace: undefined,
    functionId: undefined,
    version: undefined,
};

export default PublishModal;
