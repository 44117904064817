import PropTypes from 'prop-types';
import FunctionOverview from '../../../../../../components/FunctionOverview';
import { TabContent } from '../styled';

const Overview = ({ data }) => (
    <TabContent className="rainbow-flex rainbow-m-top_medium">
        <FunctionOverview data={data} hiddenSections={['cost', 'updatedAt', 'condition']} />
    </TabContent>
);

Overview.propTypes = {
    data: PropTypes.object,
};

Overview.defaultProps = {
    data: undefined,
};
export default Overview;
