/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import EslintSummary from '../../../../../components/EslintSummary';
import TestCoverage from '../../../../../components/TestCoverage';
import getMessageForStatus from './helpers/getMessageForStatus';
import getVariantForStatus from './helpers/getVariantForStatus';
import StatusIcon from './statusIcon';
import BuildSummary from './buildSummary';
import TestCheck from './runTestCheck';
import {
    CheckDetailsContainer, CheckDetails, CheckTitle, StyledInfoCard,
} from './styled';

const normalizeArray = (details) => ((Array.isArray(details)) ? details : [details]);

// eslint-disable-next-line react/prop-types
const CheckMessagesList = ({ status, conclusion, messages = [] }) => {
    // eslint-disable-next-line react/prop-types
    const variant = getVariantForStatus({ status, conclusion });

    const list = messages.map((message, index) => {
        const { title, description } = message;
        const key = `check-message__${index}`;
        return (
            <StyledInfoCard
                key={key}
                variant={variant}
                label={title}
                description={description}
                size="small"
                hideIcon
            />
        );
    });

    return (
        <div>{list}</div>
    );
};

const ChecksDetailedInfo = ({ details }) => {
    const checks = details.map((checkDetails, index) => {
        const {
            messages, status, name, conclusion,
        } = checkDetails;
        const key = `check-${index}`;
        return (
            <CheckDetails key={key}>
                <RenderIf isTrue={details.length > 0}>
                    <CheckTitle>
                        <StatusIcon status={status} conclusion={conclusion} size="small" />
                        {name}
                    </CheckTitle>
                </RenderIf>
                <CheckMessagesList status={status} conclusion={conclusion} messages={messages} />
            </CheckDetails>
        );
    });
    return <CheckDetailsContainer>{checks}</CheckDetailsContainer>;
};

export default function JobDetails(props) {
    const {
        status,
        conclusion,
        isLoading,
        details: detailsProp,
        checksResults,
        description,
        checkType,
    } = props;
    const details = normalizeArray(detailsProp || []);

    if (isLoading) return null;

    const hasDetails = details && details.length > 0;
    const isLinterCheck = Array.isArray(description) && description.every((item) => typeof item === 'string');
    const isEslintCheck = checkType === 'eslint' && Array.isArray(description) && !isLinterCheck;
    const isTestsCheck = checkType === 'tests';
    const isCoverageCheck = checkType === 'coverage';
    const isBuildSummary = checkType === 'summary';
    const message = description || getMessageForStatus({ status, conclusion });
    const messageStatus = getVariantForStatus({ status, conclusion });
    const linterMessages = isLinterCheck ? description.map((title) => ({ title })) : [];
    const showInfo = !isEslintCheck && !isLinterCheck && !isTestsCheck && !isCoverageCheck;

    return (
        <>
            <RenderIf isTrue={isBuildSummary}>
                <BuildSummary
                    status={status}
                    conclusion={conclusion}
                    checksResults={checksResults}
                />
            </RenderIf>
            <RenderIf isTrue={isLinterCheck}>
                <CheckMessagesList
                    status={status}
                    conclusion={conclusion}
                    messages={linterMessages}
                />
            </RenderIf>
            <RenderIf isTrue={isEslintCheck}>
                <EslintSummary errors={description} />
            </RenderIf>
            <RenderIf isTrue={isTestsCheck}>
                <TestCheck results={description} status={status} conclusion={conclusion} />
            </RenderIf>
            <RenderIf isTrue={isCoverageCheck}>
                <TestCoverage data={description} checksResults={checksResults} />
            </RenderIf>
            <RenderIf isTrue={showInfo}>
                <RenderIf isTrue={!isBuildSummary && hasDetails}>
                    <ChecksDetailedInfo details={details} />
                </RenderIf>
                <RenderIf isTrue={!isBuildSummary && !hasDetails}>
                    <CheckDetailsContainer>
                        <StyledInfoCard
                            variant={messageStatus}
                            label={message}
                            size="small"
                            hideIcon
                        />
                    </CheckDetailsContainer>
                </RenderIf>
            </RenderIf>
        </>
    );
}

JobDetails.propTypes = {
    status: PropTypes.oneOf(['success', 'failed', 'in-progress']),
    isLoading: PropTypes.bool,
    details: PropTypes.array,
    checksResults: PropTypes.array,
};

JobDetails.defaultProps = {
    isLoading: false,
    status: undefined,
    details: [],
    checksResults: undefined,
};
