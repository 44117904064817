/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { Drawer } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import NavigationBar from '../../layouts/ProjectNavigationBar';
import Auth from '../auth';
import Functions from '../functions';
import FunctionInstallForm from '../../components/FunctionInstallForm';
import ShowSecretValueModal from '../secrets/showSecretValueModal';
import {
    RECONFIG_FUNCTION_DRAWER,
    CREATE_SECRET_DRAWER,
    ADD_SECRET_VERSION_DRAWER,
    SHOW_SECRET_VALUE_MODAL,
} from '../../constants';
import Secrets from '../secrets';
import {
    Container,
    Content,
} from './styled';
import CreateSecretForm from '../secrets/createSecretForm';

export default function Project() {
    const connectedReconfigProps = useConnectModal(RECONFIG_FUNCTION_DRAWER, { size: 'medium', slideFrom: 'right' });
    const connectedCreateSecretProps = useConnectModal(CREATE_SECRET_DRAWER, {});
    const connectedAddSecretVersionProps = useConnectModal(ADD_SECRET_VERSION_DRAWER, {});
    const connectedSecretValueModalProps = useConnectModal(SHOW_SECRET_VALUE_MODAL, { title: 'Secret value' });
    const [, closeReconfigModal] = useOpenModal(RECONFIG_FUNCTION_DRAWER);
    const [, closeCreateSecretDrawer] = useOpenModal(CREATE_SECRET_DRAWER);
    const [, closeAddSecretVersionDrawer] = useOpenModal(ADD_SECRET_VERSION_DRAWER);
    const [, closeSecretValueModal] = useOpenModal(SHOW_SECRET_VALUE_MODAL);
    const { functionData: functionReconfigData = {} } = connectedReconfigProps;
    const { secretData = {} } = connectedAddSecretVersionProps;

    return (
        <Container>
            <NavigationBar />
            <Content>
                <Switch>
                    <Route path="/app/project/:projectId/auth" component={Auth} />
                    <Route path="/app/project/:projectId/functions" component={Functions} />
                    <Route path="/app/project/:projectId/secrets" component={Secrets} />
                    <Redirect from="/app/project/:projectId" to="/app/project/:projectId/functions" />
                </Switch>
            </Content>
            <Drawer {...connectedReconfigProps} onRequestClose={closeReconfigModal}>
                <FunctionInstallForm mode="config" {...functionReconfigData} />
            </Drawer>
            <Drawer
                header="Create Secret"
                slideFrom="right"
                size="medium"
                {...connectedCreateSecretProps}
                onRequestClose={closeCreateSecretDrawer}
            >
                <CreateSecretForm />
            </Drawer>
            <Drawer
                header="Add Version"
                slideFrom="right"
                size="medium"
                {...connectedAddSecretVersionProps}
                onRequestClose={closeAddSecretVersionDrawer}
            >
                <CreateSecretForm mode="add-version" {...secretData} />
            </Drawer>
            <ShowSecretValueModal
                id="secret-value-modal"
                {...connectedSecretValueModalProps}
                onRequestClose={closeSecretValueModal}
            />
        </Container>
    );
}

Project.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
