/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';
import { Sidebar, ButtonIcon } from 'react-rainbow-components';
import { SideBarOption } from '@rainbow-modules/app';
import {
    Docs, Store, StoreFilled,
} from '@rainbow-modules/icons';
import Logo from '../../components/Icons/logo';
import { WHITE, LIGHT_WHITE, LIGHTER_BLACK } from '../../colors';

export const Container = styled.div`
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    height: 100vh;
`;

export const StyledLogo = styled(Logo)`
    width: 32px;
    height: 42px;
    color: ${WHITE};
`;

export const Bar = styled(Sidebar)`
    width: 100%;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const StyledSideBarOption = styled(SideBarOption)`
    > button {
        width: 56px;
        height: 56px;
    }
`;

export const IconBar = styled(Store)`
    width: 28px;
    height: 28px;
    color: ${LIGHT_WHITE};
    opacity: 0.6;
`;

export const IconBarSelected = styled(StoreFilled)`
    width: 28px;
    height: 28px;
    color: ${LIGHT_WHITE};
`;

export const StyledStore = styled(Store)`
    width: 32px;
    height: 32px;
    color: ${LIGHT_WHITE};
    opacity: 0.6;
`;

export const StyledStoreFilled = styled(StoreFilled)`
    width: 32px;
    height: 32px;
    color: ${LIGHT_WHITE};
`;

export const Top = styled.div`
    padding-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Bottom = styled.div`
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StyledLink = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 24px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover,
    :focus {
        background-color: ${LIGHTER_BLACK};

        > svg {
            color: ${(props) => props.theme.rainbow.palette.text.main};
            opacity: 0.6;
        }
    }
`;

export const DocsIcon = styled(Docs)`
    height: 20px;
    width: 20px;
`;

export const TextTooltip = styled.p`
    font-size: 14px;
    text-align: start;
    text-overflow: ellipsis;
    margin-bottom: 2px;
`;

export const PayoutButton = styled(ButtonIcon)`
    margin-bottom: 20px;

    svg {
        width: 22px !important;
        height: 22px !important;
        color: ${WHITE};
    }

    :hover,
    :focus {
        svg {
            opacity: 0.6;
        }
    }
`;
