import { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import {
    RenderIf, Spinner,
} from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import useLocationHash from '../../../data/hooks/useLocationHash';
import useVersions from '../../../data/hooks/useVersions';
import useFunction from '../../../data/hooks/useFunction';
import usePurchasedFunction from '../../../data/hooks/usePurchasedFunction';
import Overview from './tabsContent/overview';
import Pricing from './tabsContent/pricing';
import Terms from './tabsContent/terms';
import Source from './tabsContent/source';
import Test from './tabsContent/test';
import Zip from './tabsContent/zip';
import FunctionIcon from '../../../components/FunctionIcon';
import BackButton from '../../../components/BackButton';
import firebaseCallable from '../../../data/services/firebaseCallable';
import logEvent from '../../../analytics/logEvent';
import events from '../../../analytics/events';
import { INSTALL_FUNCTION_DRAWER } from '../../../constants';
import VersionsButtonGroup from '../../../components/VersionsButtonGroup';
import FunctionTabset from './tabset';
import FunctionHeader from './header';
import {
    Container,
    Content,
    StyledFloatingBar,
} from './styled';

const tabContentMap = {
    overview: Overview,
    pricing: Pricing,
    terms: Terms,
    source: Source,
    test: Test,
    zip: Zip,
};

export default function FunctionDetails() {
    const { state } = useLocation();
    const zipSelectedVersion = state?.selectedVersion;
    const [isLoadingBuyButton, setIsLoadingBuyButton] = useState(false);
    const { functionId, workspace } = useParams();
    const locationHash = useLocationHash();
    const { push, replace } = useHistory();
    const [selectedVersion, setSelectedVersion] = useState(zipSelectedVersion);
    const [versionsData, isLoadingVersions] = useVersions(functionId, workspace);
    const [lastVersionFunction, isLoadingLastVersionFunction] = useFunction(functionId, workspace);
    const [openInstallModal] = useOpenModal(INSTALL_FUNCTION_DRAWER);
    const [isPurchased, isLoadingPurchased] = usePurchasedFunction({
        functionId,
        workspace,
    });
    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });
    const [selected, setSelected] = useState(locationHash || 'overview');
    const TabContent = tabContentMap[selected];

    const functionData = useMemo(
        () => {
            const data = versionsData.find((item) => item.version === selectedVersion?.name);
            if (data) {
                return { ...data, functionId };
            }
            return undefined;
        },
        [versionsData, selectedVersion, functionId],
    );

    useEffect(() => {
        firebaseCallable('functions-incrementViewsCount', { functionId, workspace });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isPurchased) {
            setIsLoadingBuyButton(false);
        }
    }, [isPurchased]);

    useEffect(() => {
        if (locationHash !== selected) {
            setSelected(locationHash || 'overview');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationHash]);

    if (!isLoadingLastVersionFunction && !lastVersionFunction) {
        replace('/app/marketplace');
    }

    const handleOnSelect = (event, value) => {
        push(`#${value}`);
        setSelected(value);
    };

    const isLoading = isLoadingVersions || isLoadingPurchased || isLoadingLastVersionFunction;
    const finalData = functionData || lastVersionFunction;
    const { price } = lastVersionFunction || {};
    const { iconUrl, description } = finalData || {};

    const handleOnInstall = () => {
        logEvent(events.functions.OPEN_INSTALL_DRAWER);
        return openInstallModal({ functionData: { ...finalData, functionId } });
    };

    const installButtonLabel = selectedVersion ? `Install v${finalData?.version}` : 'Install latest version';

    const installButton = (
        <VersionsButtonGroup
            versions={versionsData}
            onSelectVersion={setSelectedVersion}
            onInstallFunction={handleOnInstall}
            selectedVersion={selectedVersion}
            installButtonLabel={installButtonLabel}
        />
    );
    return (
        <Container onScroll={handleScrollEvent}>
            <StyledFloatingBar isVisible={showBar}>
                <FunctionHeader
                    functionData={finalData}
                    isPurchased={isPurchased}
                    isLoadingBuyButton={isLoadingBuyButton}
                    onSubmitBuyModal={setIsLoadingBuyButton}
                    price={price}
                    icon={<FunctionIcon url={iconUrl} width="35px" height="35px" functionType="marketplace" />}
                    installButton={installButton}
                    backButton={(
                        <BackButton
                            to="/app/marketplace"
                            className="rainbow-m-top_small rainbow-m-left_x-small"
                        />
                    )}
                />
                <FunctionTabset
                    onSelect={handleOnSelect}
                    activeTabName={selected}
                />
            </StyledFloatingBar>
            <RenderIf isTrue={isLoading}>
                <Spinner />
            </RenderIf>
            <RenderIf isTrue={!isLoading}>
                <FunctionHeader
                    functionData={finalData}
                    isPurchased={isPurchased}
                    isLoadingBuyButton={isLoadingBuyButton}
                    onSubmitBuyModal={setIsLoadingBuyButton}
                    price={price}
                    icon={<FunctionIcon url={iconUrl} functionType="marketplace" />}
                    description={description}
                    installButton={installButton}
                    backButton={(
                        <BackButton
                            to="/app/marketplace"
                            className="rainbow-m-top_large rainbow-m-left_x-small"
                        />
                    )}
                />
                <Content>
                    <FunctionTabset
                        onSelect={handleOnSelect}
                        activeTabName={selected}
                        id="function-tabset-1"
                    />
                    <TabContent
                        data={finalData}
                        isPurchased={isPurchased}
                        price={price}
                    />
                </Content>
            </RenderIf>
        </Container>
    );
}
