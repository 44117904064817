import PropTypes from 'prop-types';
import BackButton from '../../../components/BackButton';
import FailedIcon from '../../../components/Icons/failed';
import {
    FailedContainer, FailedInfo, FailedTitle, Highlight, FailedDescription, HighlightDescription,
} from './styled';

const Failed = ({ funcName }) => (
    <>
        <BackButton className="rainbow-m-left_x-large" to="/" label="Back to Marketplace" />
        <FailedContainer>
            <FailedIcon />
            <FailedInfo>
                <FailedTitle>
                    The
                    <Highlight>
                        {funcName}
                    </Highlight>
                    installation failed.
                </FailedTitle>
                <FailedDescription>
                    An error occurred that caused the installation to fail.
                    Contact our support team at
                    <br />
                    <HighlightDescription>support@function.store</HighlightDescription>
                </FailedDescription>
            </FailedInfo>
        </FailedContainer>
    </>
);

Failed.propTypes = {
    funcName: PropTypes.string,
};

Failed.defaultProps = {
    funcName: undefined,
};
export default Failed;
