import PropTypes from 'prop-types';

export default function Minus(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="18px" height="18px" viewBox="0 0 18 18">
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="developer/workspace-CI-push-detail" transform="translate(-285.000000, -370.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M287.652632,372.652632 C291.133342,369.115789 296.859632,369.115789 300.340342,372.652632 C303.821053,376.133342 303.821053,381.859632 300.340342,385.340342 C296.859632,388.877184 291.133342,388.821053 287.652632,385.340342 C284.115789,381.859632 284.115789,376.189474 287.652632,372.652632 Z M297.364934,377.761395 L290.571947,377.761395 C289.89825,377.761395 289.336855,378.322789 289.336855,378.996487 C289.336855,379.670184 289.89825,380.231579 290.571947,380.231579 L290.571947,380.231579 L297.421066,380.231579 C298.094763,380.231579 298.656158,379.670184 298.656158,378.996487 C298.656158,378.322789 298.094724,377.761395 297.364934,377.761395 L297.364934,377.761395 Z" id="not-run" />
                </g>
            </g>
        </svg>
    );
}

Minus.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Minus.defaultProps = {
    style: {},
    className: undefined,
};
