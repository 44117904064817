import styled from 'styled-components';
import { ButtonIcon, Modal } from 'react-rainbow-components';
import {
    Plus, CheckCircleFilled, PencilFilled, Function,
} from '@rainbow-modules/icons';
import StepFlow from '../../../../../../components/Icons/stepFlow';
import Build from '../../../../../../components/Icons/build';
import DisconnectedPlug from '../../../../../../components/Icons/disconnectedPlug';
import PlayCircle from '../../../../../../components/Icons/playCircle';
import {
    GRAY, WHITE, GREEN, DARK_RED,
} from '../../../../../../colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding-top: 36px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const TriggerContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`;

export const Trigger = styled.div`
    width: 54px;
    height: 54px;
    background-color: #ccd2d6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TriggerInfo = styled.div`
    position: absolute;
    left: 64px;
    width: 300%;
`;

export const TriggerName = styled.h5`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const TriggerDescription = styled.p`
    font-size: 12px;
    color: ${GRAY};
`;

export const BuildIcon = styled(Build)`
    width: 36px;
    height: 36px;
    color: #909aa1;
`;

export const Line = styled.div`
    color: #ccd2d6;
    margin: 3px 0;
`;

export const EnvironmentContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 3px 0;
`;

export const EnvironmentCard = styled.div`
    width: 240px;
    margin: 0;
    padding: 8px 14px;
    border-radius: 14px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    background-color: ${WHITE};
    display: flex;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    border: solid 3px transparent;
    box-sizing: border-box;

    ${(props) => props.isSelected
    && `
        border: solid 3px ${props.theme.rainbow.palette.brand.main};
    `}
`;

export const PlusIcon = styled(Plus)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    flex-shrink: 0;
    margin-bottom: 36px;
`;

export const EnvironmentLeftContent = styled.div`
    display: flex;
    align-items: center;
`;

export const EnvironmentRightContent = styled.div`
    display: flex;
    align-items: center;
`;

export const EnvironmentName = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const EnvironmentProject = styled.p`
    font-size: 12px;
    color: ${GRAY};
`;

export const StepFlowIcon = styled(StepFlow)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    flex-shrink: 0;
`;

export const DisconnectedPlugIcon = styled(DisconnectedPlug)`
    width: 24px;
    height: 24px;
    margin-left: 12px;
    flex-shrink: 0;
    color: ${GRAY};
`;

export const CheckCircleFilledIcon = styled(CheckCircleFilled)`
    width: 24px;
    height: 24px;
    margin-left: 12px;
    flex-shrink: 0;
    color: ${GREEN};
`;

export const Notification = styled.div`
    width: 24px;
    height: 24px;
    background: ${DARK_RED};
    position: absolute;
    right: -10px;
    top: -10px;
    border-radius: 20px;
    color: ${WHITE};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;
    line-height: 14px;
`;

export const ButtonsContainer = styled.div`
    position: absolute;
    right: -64px;
`;

export const Icon = styled(PencilFilled)`
    width: 16px;
    height: 16px;
`;

export const FunctionsCounter = styled.h3`
    position: absolute;
    right: 8px;
    bottom: -22px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 13px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
`;

export const FunctionIcon = styled(Function)`
    width: 14px;
    height: 14px;
    margin-right: 4px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StyledDeployButton = styled.button`
    background: ${(props) => props.theme.rainbow.palette.brand.main};
    color: ${WHITE};
    font-size: 18px;
    border: none;
    border-radius: 12px;
    padding: 12px 0 12px 12px;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const DeployIcon = styled(PlayCircle)`
    width: 30px;
    height: 30px;
    margin: 0 8px 0;
    position: absolute;
    top: 10.5px;
    left: 5px;
`;

export const DeployModal = styled(Modal)`
    height: 100%;

    section {
        height: 100%;
    }

    > div {
        height: 100%;
        padding: 0;
    }
`;
