/* eslint-disable react/prop-types */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tag } from '@rainbow-modules/icons';
import { RecordDetails, RecordField } from '@rainbow-modules/record';
import { RenderIf } from 'react-rainbow-components';
import ReactJson from 'react-json-view';
import {
    FunctionCompareContainer,
    ArrowNextIcon,
    ItemIcon,
    ItemLabel,
    ItemValue,
    FunctionInfoContainer,
    FunctionIcon,
    FunctionName,
    ChangesContainer,
    Bold,
    Changes,
    StyledChevronRight,
    Row,
    ConfigurationContainer,
    ConfigurationHeader,
    ConfigurationSectionHeader,
    StyledRecordSection,
} from './styled';

const DataRecords = ({ data = {} }) => Object.keys(data)
    .map((key) => <RecordField label={key} value={String(data[key])} />);

const EnvVariables = ({ data }) => <ReactJson src={data} theme="hopscotch" collapsed={false} name={false} />;

export default function FunctionCompareItem(props) {
    const {
        functionName,
        functionFromVersion,
        functionToVersion,
        functionFromBuild,
        functionToBuild,
        trigger,
        env,
        runtimeConfig,
        targetProjectName,
    } = props;
    const [isOpen, setOpen] = useState(false);

    return (
        <FunctionCompareContainer>
            <Row onClick={() => setOpen(!isOpen)}>
                <FunctionInfoContainer>
                    <StyledChevronRight isOpen={isOpen} />
                    <FunctionIcon />
                    <FunctionName>
                        {functionName}
                    </FunctionName>
                </FunctionInfoContainer>
                <Changes>
                    <ChangesContainer>
                        <ItemLabel size="small">
                            Current:
                        </ItemLabel>
                        <ItemIcon size="small" as={Tag} />
                        <ItemValue size="small">
                            <Bold>
                                {functionFromVersion}
                            </Bold>
                        </ItemValue>
                        <ItemValue size="small">
                            {functionFromBuild}
                        </ItemValue>
                    </ChangesContainer>
                    <ArrowNextIcon size="small" />
                    <ChangesContainer>
                        <ItemLabel size="small">
                            Promote:
                        </ItemLabel>
                        <ItemIcon size="small" as={Tag} />
                        <ItemValue size="small">
                            <Bold>
                                {functionToVersion}
                            </Bold>
                        </ItemValue>
                        <ItemValue size="small">
                            {functionToBuild}
                        </ItemValue>
                    </ChangesContainer>
                </Changes>
            </Row>
            <RenderIf isTrue={isOpen}>
                <ConfigurationContainer>
                    <ConfigurationHeader>
                        Configuration to deploy in “
                        {targetProjectName}
                        ”
                    </ConfigurationHeader>
                    <StyledRecordSection
                        label={<ConfigurationSectionHeader>Trigger</ConfigurationSectionHeader>}
                    >
                        <RecordDetails>
                            <DataRecords data={trigger} />
                        </RecordDetails>
                    </StyledRecordSection>
                    <StyledRecordSection
                        label={(
                            <ConfigurationSectionHeader>
                                Environment Variables
                            </ConfigurationSectionHeader>
                        )}
                    >
                        <RecordDetails>
                            <EnvVariables data={env} />
                        </RecordDetails>
                    </StyledRecordSection>
                    <RenderIf isTrue={runtimeConfig}>
                        <StyledRecordSection
                            label={<ConfigurationSectionHeader>Runtime</ConfigurationSectionHeader>}
                        >
                            <RecordDetails>
                                <DataRecords data={runtimeConfig} />
                            </RecordDetails>
                        </StyledRecordSection>
                    </RenderIf>
                </ConfigurationContainer>
            </RenderIf>
        </FunctionCompareContainer>
    );
}

FunctionCompareItem.propTypes = {
    functionName: PropTypes.string,
    functionFromVersion: PropTypes.string,
    functionFromBuild: PropTypes.string,
    functionToBuild: PropTypes.string,
    functionToVersion: PropTypes.string,
    trigger: PropTypes.object.isRequired,
    env: PropTypes.object.isRequired,
    runtimeConfig: PropTypes.object,
};

FunctionCompareItem.defaultProps = {
    functionName: undefined,
    functionFromVersion: undefined,
    functionFromBuild: undefined,
    functionToBuild: undefined,
    functionToVersion: undefined,
    runtimeConfig: undefined,
};
