/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Drawer } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import FunctionInstallForm from '../../components/FunctionInstallForm';
import BuyFunctionModal from './buyFunctionModal';
import { INSTALL_FUNCTION_DRAWER, BUY_FUNCTION_MODAL } from '../../constants';
import Functions from './functions';
import FunctionDetails from './functionDetails';

export default function Marketplace() {
    const connectedInstallProps = useConnectModal(INSTALL_FUNCTION_DRAWER, {
        size: 'medium',
        slideFrom: 'right',
    });
    const connectedBuyProps = useConnectModal(BUY_FUNCTION_MODAL, {});
    const [, closeInstallModal] = useOpenModal(INSTALL_FUNCTION_DRAWER);
    const [, closeBuyModal] = useOpenModal(BUY_FUNCTION_MODAL);
    const { functionData = {} } = connectedInstallProps;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const closeBuyModalCallback = useCallback(() => closeBuyModal(), []);

    return (
        <>
            <Switch>
                <Route path="/app/marketplace/:workspace/:functionId" component={FunctionDetails} />
                <Route path="/app/marketplace" component={Functions} />
            </Switch>
            <Drawer {...connectedInstallProps} onRequestClose={closeInstallModal}>
                <FunctionInstallForm {...functionData} />
            </Drawer>
            <BuyFunctionModal {...connectedBuyProps} onRequestClose={closeBuyModalCallback} />
        </>
    );
}
