import PropTypes from 'prop-types';
import {
    InfoContainer,
    DetailsContainer,
    WorkspacesName,
    WorkspaceUserRole,
    StyledFolderClose,
} from './styled';

const WorkspaceInfo = (props) => {
    const { name, userRole, className } = props;
    return (
        <InfoContainer className={className} data-cy="workspace-info">
            <StyledFolderClose />
            <DetailsContainer>
                <WorkspacesName data-cy="workspace-name">{name}</WorkspacesName>
                <WorkspaceUserRole>{userRole}</WorkspaceUserRole>
            </DetailsContainer>
        </InfoContainer>
    );
};

WorkspaceInfo.propTypes = {
    name: PropTypes.string,
    userRole: PropTypes.string,
    className: PropTypes.string,
};

WorkspaceInfo.defaultProps = {
    name: undefined,
    userRole: undefined,
    className: undefined,
};

export default WorkspaceInfo;
