import { useCurrentUser, useDoc } from '@rainbow-modules/firebase-hooks';
import getUserRoleInWorkspace from '../../helpers/getUserRoleInWorkspace';

const useCurrentUserRoleInWorkspace = ({ workspace }) => {
    const { uid: loggedInUserId } = useCurrentUser();
    const [workspaceData, isLoading] = useDoc({
        path: `/workspaces/${workspace}`,
        flat: true,
    });
    return [getUserRoleInWorkspace({
        uid: loggedInUserId,
        workspace: workspaceData || {},
    }), isLoading];
};

export default useCurrentUserRoleInWorkspace;
