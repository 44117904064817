import { useIsFetching } from 'react-query';
import useCallableQuery from './useCallableQuery';

export default function useSecretsList({ projectId }) {
    const {
        data, isLoading: isLoadingQuery, ...rest
    } = useCallableQuery(['secrets-list', projectId], { projectId }, { retry: 0 });

    const queriesBeingRefetched = useIsFetching(['secrets-list', projectId]);
    const isLoading = Boolean(isLoadingQuery || queriesBeingRefetched > 0);

    return {
        isLoading,
        data,
        ...rest,
    };
}
