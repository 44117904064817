import PropTypes from 'prop-types';
import { HeaderSectionContainer, HeaderSectionTitle } from './styled';

const HeaderSection = (props) => {
    const { title, actions } = props;
    return (
        <HeaderSectionContainer>
            <HeaderSectionTitle>{title}</HeaderSectionTitle>
            {actions}
        </HeaderSectionContainer>
    );
};

HeaderSection.propTypes = {
    title: PropTypes.string.isRequired,
    actions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

HeaderSection.defaultProps = {
    actions: undefined,
};

export default HeaderSection;
