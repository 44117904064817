import styled from 'styled-components';
import { UsersFilled } from '@rainbow-modules/icons';
import { Application, VerticalNavigation, VerticalItem } from 'react-rainbow-components';
import { LIGHT_WHITE } from '../../../colors';

export const Container = styled(Application)`
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
`;

export const Content = styled.div`
    padding: 0 0 16px 0;
    width: 100%;
    height: 100%;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const Bar = styled(VerticalNavigation)`
    width: 100%;
`;

export const StyledOption = styled(VerticalItem)`
    width: 100%;

    &::before {
        display: none;
    }

    button {
        padding-left: 56px;
    }

    > button ~ span {
        color: ${LIGHT_WHITE};
        opacity: 0.6;
    }

    > button {
        :focus {
            font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
            color: ${LIGHT_WHITE};
        }

        :focus, :hover {
            ~ span {
                color: ${LIGHT_WHITE};
                opacity: 1;
            }
        }
    }
`;

export const IconBar = styled(UsersFilled)`
    height: 24px;
    width: 24px;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: ${(props) => props.theme.rainbow.palette.border.divider};
`;
