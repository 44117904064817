import firebase from '../firebase';

const analytics = firebase.analytics();

const logPageView = () => {
    analytics.setCurrentScreen(window.location.pathname);
    analytics.logEvent('page_view', {
        page_title: window.location.pathname,
        page_location: window.location.pathname,
    });
};

export default logPageView;
