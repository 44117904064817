import useCallableQuery from './useCallableQuery';

const useAccountLoginLink = ({ isVerifiedAccount }) => {
    const { data = {}, isLoading } = useCallableQuery('xStripe_getAccountLoginLink', {}, {
        enabled: isVerifiedAccount,
    });
    return [data, isLoading];
};

export default useAccountLoginLink;
