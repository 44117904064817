import { UsersFilled } from '@rainbow-modules/icons';
import Firestore from '../Icons/firestore';
import Http from '../Icons/http';
import PubSub from '../Icons/pubSub';
import GoogleCloudStorage from '../Icons/googleCloudStorage';
import { StyledTriggerIcon } from './styled';

const iconMap = {
    firestore: Firestore,
    auth: UsersFilled,
    https: Http,
    pubsub: PubSub,
    storage: GoogleCloudStorage,
    unknown: StyledTriggerIcon,
};

export default iconMap;
