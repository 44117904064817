import PropTypes from 'prop-types';
import { RenderIf, Button } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import usePublishedMarketplaceFunction from '../../../../data/hooks/usePublishedMarketplaceFunction';
import { PUBLISH_WORKSPACE_FUNCTION_MODAL } from '../../../../constants';
import isPublishedVersion from '../../helpers/isPublishedVersion';
import canPublishVersion from '../../helpers/canPublishVersion';
import Builds from './builds';
import {
    VersionsTitle,
    VersionsContainer,
    VersionsHeaderContainer,
    VersionsTitleContainer,
    VersionsBodyContainer,
    PublishedBadge,
} from './styled';

const Version = (props) => {
    const { workspace, selectedNode } = props;
    const {
        functionId,
    } = selectedNode;
    const [marketplaceData] = usePublishedMarketplaceFunction(workspace, functionId);
    const [openModal] = useOpenModal(PUBLISH_WORKSPACE_FUNCTION_MODAL);

    const publishedVersion = (marketplaceData && marketplaceData.version) || null;
    const publishedBuild = (marketplaceData && marketplaceData.buildId) || null;
    const isPublished = isPublishedVersion(selectedNode.id, publishedVersion);
    const canBePublished = canPublishVersion(selectedNode.id, publishedVersion);

    const publishedPrice = (marketplaceData && marketplaceData.price) || 0;

    const handlePublish = () => {
        openModal({
            title: 'Submit for Review',
            submitButtonLabel: 'Submit',
            price: publishedPrice,
        });
    };

    return (
        <VersionsContainer>
            <VersionsHeaderContainer>
                <VersionsTitleContainer>
                    <div className="rainbow-flex rainbow-align_center">
                        <VersionsTitle>{`Version: ${selectedNode.id}`}</VersionsTitle>
                        <RenderIf isTrue={isPublished}>
                            <PublishedBadge
                                className="rainbow-m-left_large"
                                label="Published"
                                size="small"
                            />
                        </RenderIf>
                    </div>
                    <Button
                        id="submit-version-button"
                        variant="brand"
                        disabled={!canBePublished}
                        onClick={handlePublish}
                    >
                        Submit
                    </Button>
                </VersionsTitleContainer>
            </VersionsHeaderContainer>
            <VersionsBodyContainer>
                <Builds
                    workspace={workspace}
                    selectedNode={selectedNode}
                    isPublished={isPublished}
                    publishedBuild={publishedBuild}
                />
            </VersionsBodyContainer>
        </VersionsContainer>
    );
};

Version.propTypes = {
    workspace: PropTypes.string,
    selectedNode: PropTypes.object,
};

Version.defaultProps = {
    workspace: undefined,
    selectedNode: undefined,
};

export default Version;
