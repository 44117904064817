import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import { StepDescription } from '../styled';

const BrowseBucket = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header='Click "Browse" in the "Stage bucket" field to select a stage bucket.'
        className="rainbow-m-top_xx-large"
    >
        <StepDescription>
            Your code will be staged in this Cloud Storage bucket as part of the deployment.
        </StepDescription>
    </Step>
);

BrowseBucket.propTypes = {
    stepNumber: PropTypes.number,
};

BrowseBucket.defaultProps = {
    stepNumber: undefined,
};

export default BrowseBucket;
