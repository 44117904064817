import PropTypes from 'prop-types';
import AlignRightContent from '../../../components/AlignRightContent';
import FormattedCurrency from '../../../components/FormattedCurrency';

const BillingCurrency = (props) => {
    const { value } = props;
    const currency = (
        <FormattedCurrency
            value={value}
        />
    );
    return (
        <AlignRightContent value={currency} />
    );
};

BillingCurrency.propTypes = {
    value: PropTypes.string,
};
BillingCurrency.defaultProps = {
    value: undefined,
};

export default BillingCurrency;
