import PropTypes from 'prop-types';
import { RenderIf, Input } from 'react-rainbow-components';
import { isRequired } from '@rainbow-modules/forms';
import Label from '../../LabelWithDescription';
import {
    ItemContainer, Item, StyledField, StyledText, StyledAnchor,
} from '../styled';

const HelpText = () => (
    <StyledText>
        You must enter a
        {' '}
        <StyledAnchor
            target="_blank"
            rel="noopener noreferrer"
            href="https://cloud.google.com/storage/docs/naming-buckets"
        >
            valid bucket name

        </StyledAnchor>
        .
        Invalid bucket names can cause the
        installation to fail.
    </StyledText>
);

const Storage = ({ resource, renderInput }) => (
    <>
        <RenderIf isTrue={!renderInput}>
            <ItemContainer>
                <Label
                    name="Bucket Name"
                    description="The name of the bucket that you'd like this function to listen to for changes to the object. e.g. 'users'. Copy and paste only the text that appears after '//'."
                    className="rainbow-m-left_medium"
                    helpText={<HelpText />}
                />
                <Item>
                    {resource}
                </Item>
            </ItemContainer>
        </RenderIf>
        <RenderIf isTrue={renderInput}>
            <StyledField
                id="resource"
                name="trigger.resource"
                component={Input}
                validate={isRequired('The bucket name is required')}
                label={(
                    <Label
                        name="Bucket Name"
                        description="The name of the bucket that you'd like this function to listen to for changes to the object. e.g. 'users'. Copy and paste only the text that appears after '//'."
                        helpText={<HelpText />}
                    />
                )}
                initialValue={resource}
                required
                labelAlignment="left"
                placeholder="myProject_icons"
            />
        </RenderIf>
    </>
);

Storage.propTypes = {
    resource: PropTypes.string,
    renderInput: PropTypes.bool,
};
Storage.defaultProps = {
    resource: undefined,
    renderInput: false,
};

export default Storage;
