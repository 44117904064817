import PropTypes from 'prop-types';
import {
    UsageItemContainer,
    UsageItemIcon,
    UsageItemTitle,
    UsageItemDescription,
    Counter,
    UsageItemLeft,
} from './styled';

const UsageItem = (props) => {
    const {
        itemTitle, itemDescription, itemCounter, icon,
    } = props;

    return (
        <UsageItemContainer>
            <UsageItemLeft>
                {icon}
                <div>
                    <UsageItemTitle>
                        {itemTitle}
                    </UsageItemTitle>
                    <UsageItemDescription>
                        {itemDescription}
                    </UsageItemDescription>
                </div>
            </UsageItemLeft>
            <Counter>
                {itemCounter}
            </Counter>
        </UsageItemContainer>
    );
};

UsageItem.propTypes = {
    itemTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    itemDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    itemCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.node,
};

UsageItem.defaultProps = {
    itemTitle: undefined,
    itemDescription: undefined,
    itemCounter: undefined,
    icon: <UsageItemIcon />,
};

export default UsageItem;
