import { LoadingShape } from 'react-rainbow-components';
import { MetadataLoadingTitle, MetadataLoadingSubTitle, MetadataLoadingReactJson } from './styled';

const MetadataLoading = () => (
    <>
        <MetadataLoadingTitle>
            <LoadingShape />
        </MetadataLoadingTitle>
        <MetadataLoadingSubTitle>
            <LoadingShape />
        </MetadataLoadingSubTitle>
        <MetadataLoadingReactJson>
            <LoadingShape shape="rect" />
        </MetadataLoadingReactJson>
    </>
);

export default MetadataLoading;
