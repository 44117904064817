import PropTypes from 'prop-types';
import { CodeViewer } from '@rainbow-modules/content';
import useSourceCodeFolderTree from '../../data/hooks/useSourceCodeFolderTree';
import useDownloadFunctionCode from '../../data/hooks/useDownloadFunctionCode';
import FunctionIcon from '../FunctionIcon';

export default function SourceCodeExplorer({ functionData }) {
    const {
        name, workspace, iconUrl, buildId,
    } = functionData;

    const {
        getFolderContents,
        getFile,
    } = useSourceCodeFolderTree({
        functionId: name,
        workspace,
        buildId,
        path: '/',
    });

    const { download } = useDownloadFunctionCode({
        functionId: name,
        workspace,
        buildId,
    });

    const onFileSelect = ({ filePath }) => getFile(filePath);

    const onExpandCollapseFolder = async ({ folderPath }) => {
        const formattedPath = `${folderPath}/`.replace('//', '/');
        const files = await getFolderContents(formattedPath);
        return {
            files: files.map(({ type, name: fileName, path }) => ({
                fileName,
                type,
                filePath: path,
            })),
        };
    };

    return (
        <CodeViewer
            key={`${workspace}:${name}:${buildId}`}
            icon={<FunctionIcon url={iconUrl} functionType="marketplace" width="20px" height="20px" />}
            name={name}
            onDownload={download}
            onFolderExpand={onExpandCollapseFolder}
            onFileSelect={onFileSelect}
        />
    );
}

SourceCodeExplorer.propTypes = {
    functionData: PropTypes.object,
};

SourceCodeExplorer.defaultProps = {
    functionData: undefined,
};
