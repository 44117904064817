import styled from 'styled-components';
import { LIGHT_WHITE, LIGHT_BLACK } from '../../colors';

export const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow: auto;
    background-color: ${LIGHT_BLACK};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    background: ${LIGHT_WHITE};
    border-radius: 22px 0 0 0;
    padding-top: 20px;
`;
