/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { RecordSection, RecordDetails } from '@rainbow-modules/record';
import PolicyComposer from '../../../components/PolicyComposer';
import Trigger from './trigger';
import getFunctionTrigger from '../../../helpers/getFunctionTrigger';
import isCallableFunction from '../../../helpers/isCallableFunction';
import EnvironmentsVariables from './environmentsVariables';
import Runtime from './runtime';
import Authentication from './authentication';
import { TabsetContent, StyledRecordSection } from './styled';

const Config = ({
    // eslint-disable-next-line react/prop-types
    details, installedFunction, trigger, policies, isLoading,
}) => {
    // eslint-disable-next-line react/prop-types
    const { environmentVariables: env } = details;
    const { env: installEnvironmentVariables } = installedFunction || {};
    const { description: resource } = getFunctionTrigger(details);
    const isCallable = isCallableFunction(trigger);
    const hasPolicies = !!policies;

    const triggerData = {
        ...trigger,
        resource,
    };

    const environmentVariables = {
        ...installEnvironmentVariables,
        ...env,
    };
    // eslint-disable-next-line react/prop-types
    const isHttps = trigger?.provider === 'https';

    if (environmentVariables) {
        return (
            <>
                <Trigger {...triggerData} />
                <RenderIf isTrue={isHttps}>
                    <Authentication trigger={trigger} />
                </RenderIf>
                <RecordSection label="Environment Variables">
                    <RecordDetails>
                        <EnvironmentsVariables
                            environmentsVariables={environmentVariables}
                            isLoading={isLoading}
                        />
                    </RecordDetails>
                </RecordSection>
                <RenderIf isTrue={isCallable}>
                    <RecordSection label="Policies">
                        <RenderIf isTrue={hasPolicies}>
                            <PolicyComposer readOnly value={policies} />
                        </RenderIf>
                        <RenderIf isTrue={!hasPolicies}>
                            <span>You haven&apos;t added a policy yet.</span>
                        </RenderIf>
                    </RecordSection>
                </RenderIf>
                <StyledRecordSection label="Runtime">
                    <RecordDetails>
                        <Runtime
                            isLoading={isLoading}
                            details={details}
                        />
                    </RecordDetails>
                </StyledRecordSection>
            </>
        );
    }
    return null;
};

export default function Configuration({
    details, isLoading, installedFunction, policies,
}) {
    return (
        <TabsetContent>
            <Config
                isLoading={isLoading}
                details={details}
                policies={policies}
                installedFunction={installedFunction}
                trigger={installedFunction.trigger}
            />
        </TabsetContent>
    );
}

Configuration.propTypes = {
    details: PropTypes.object,
    isLoading: PropTypes.bool,
    installedFunction: PropTypes.object,
    policies: PropTypes.array,
};

Configuration.defaultProps = {
    details: undefined,
    installedFunction: undefined,
    policies: undefined,
    isLoading: false,
};
