import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import useDeleteWorkspaceImportedFunction from '../../../../data/hooks/useDeleteWorkspaceImportedFunction';
import FunctionIcon from '../../../../components/FunctionIcon';
import ImportedBuild from './importedBuild';
import {
    VersionsTitle,
    VersionsDescription,
    VersionsContainer,
    VersionsHeaderContainer,
    VersionsTitleContainer,
    VersionsTitleButtonContainer,
    VersionsBodyContainer,
} from './styled';

const VersionImported = (props) => {
    const { selectedNode, workspace, onDeleted } = props;
    const {
        importId,
        functionId,
        workspace: functionWorkspace,
        marketplaceData,
    } = selectedNode;

    const { description, iconUrl } = marketplaceData || {};

    const removeFunction = useDeleteWorkspaceImportedFunction({
        workspace,
        functionId: importId,
        onSuccess: onDeleted,
    });

    return (
        <VersionsContainer>
            <VersionsHeaderContainer>
                <VersionsTitleContainer>
                    <div className="rainbow-flex rainbow-align_center">
                        <FunctionIcon url={iconUrl} functionType="marketplace" width="36px" height="36px" />
                        <div>
                            <VersionsTitle>{`${functionId} - ${functionWorkspace}`}</VersionsTitle>
                            <VersionsDescription>{description}</VersionsDescription>
                        </div>
                    </div>
                    <VersionsTitleButtonContainer>
                        <Button
                            id="remove-imported-function-button"
                            variant="outline-brand"
                            onClick={() => removeFunction({
                                children: (
                                    <div className="rainbow-flex rainbow-align_center">
                                        <FunctionIcon url={iconUrl} functionType="marketplace" width="36px" height="36px" />
                                        <div>
                                            <VersionsTitle>{`${functionId} - ${functionWorkspace}`}</VersionsTitle>
                                            <VersionsDescription>{description}</VersionsDescription>
                                        </div>
                                    </div>
                                ),
                            })}
                        >
                            Remove Function
                        </Button>
                    </VersionsTitleButtonContainer>
                </VersionsTitleContainer>
            </VersionsHeaderContainer>
            <VersionsBodyContainer>
                <VersionsTitle>{`Version: ${selectedNode.id}`}</VersionsTitle>
                <ImportedBuild
                    workspace={functionWorkspace}
                    selectedNode={selectedNode}
                />
            </VersionsBodyContainer>
        </VersionsContainer>
    );
};

VersionImported.propTypes = {
    workspace: PropTypes.string,
    selectedNode: PropTypes.object,
    onDeleted: PropTypes.func,
};

VersionImported.defaultProps = {
    workspace: undefined,
    selectedNode: undefined,
    onDeleted: () => {},
};

export default VersionImported;
