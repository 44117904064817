import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { RenderIf, Spinner, Button } from 'react-rainbow-components';
import { Search } from '@rainbow-modules/icons';
import { CategoriesPicker } from '@rainbow-modules/search';
import { useOpenModal } from '@rainbow-modules/hooks';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import { useCollection, useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { INSTALL_FUNCTION_DRAWER } from '../../../constants';
import searchFunctionsAlgolia from '../../../data/services/searchFunctionsAlgolia';
import CardFunctions from './cardFunctions';
import InformationText from './informationText';
import useCategoriesOptions from '../../../data/hooks/useCategoriesOptions';
import formatPickerPriceValue from '../../../helpers/formatPickerPriceValue';
import formatPurchasedFunctions from '../../../helpers/formatPurchasedFunctions';
import logFilterUse from '../../../analytics/logFilterUse';
import logEvent from '../../../analytics/logEvent';
import events from '../../../analytics/events';
import {
    Content,
    CategoriesContainer,
    RightContent,
    FunctionsContainer,
    StyledInput,
    StyledFooter,
    FooterQuestion,
    FooterCard,
    StyledLifePreserver,
    StyledBarContainer,
    StyledInputContainer,
    Title,
    FloatingBarTitle,
    MainContainer,
    MainDescription,
    StyledFloatingBar,
    RequestLink,
} from './styled';

const filterOptions = ['Purchased', 'Paid', 'Free'];

const formStyles = { width: 0, height: 0 };

export default function Functions() {
    const [categoriesOptions, isLoadingCategories] = useCategoriesOptions();
    const { uid } = useCurrentUser();
    const [purchasedFunctions, isLoadingPurchasedFunctions] = useCollection({
        path: `/users/${uid}/functions`,
    });
    const [isLoadingSearchData, setIsLoadingSearchData] = useState(true);
    const [searchedData, setSearchedData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeCategoryName, setActiveCategoryName] = useState([]);
    const [activeFilterName, setActiveFilterName] = useState([]);
    const [openModal] = useOpenModal(INSTALL_FUNCTION_DRAWER);
    const { push } = useHistory();
    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 120,
    });
    const formattedPurchasedFunctions = formatPurchasedFunctions(purchasedFunctions);
    const isLoading = isLoadingSearchData || isLoadingCategories || isLoadingPurchasedFunctions;
    const showInformationText = searchQuery || activeCategoryName.length || activeFilterName.length;
    const isPurchasedPicked = activeFilterName.includes('Purchased');
    const formattedPickerPriceArray = formatPickerPriceValue(
        activeFilterName.filter((item) => item !== 'Purchased'),
    );
    const requestFunctionRef = useRef();

    useEffect(() => {
        setIsLoadingSearchData(true);
        (async () => {
            const resp = await searchFunctionsAlgolia(searchQuery, {
                categories: activeCategoryName,
                filter: formattedPickerPriceArray,
                purchasedFunctions: isPurchasedPicked ? formattedPurchasedFunctions : null,
            });
            setSearchedData(
                resp.hits.map((item) => {
                    const { objectID } = item;
                    const array = objectID.split('/');
                    return {
                        ...item,
                        objectID: array[array.length - 1],
                    };
                }),
            );
        })();
        setIsLoadingSearchData(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, activeCategoryName, activeFilterName, isPurchasedPicked]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleDelete = (value) => {
        const isCategoryFilter = activeCategoryName.includes(value);
        if (isCategoryFilter) {
            const newActiveCategoryName = activeCategoryName.filter(
                (category) => category !== value,
            );
            setActiveCategoryName(newActiveCategoryName);
        } else {
            const newActiveFilterName = activeFilterName.filter((filter) => filter !== value);
            setActiveFilterName(newActiveFilterName);
        }
    };
    return (
        <MainContainer onScroll={handleScrollEvent}>
            <Title>Discover functions</Title>
            <MainDescription>
                Add functions to help you build and grow your project. Save time with these
                easy-to-use solutions.
            </MainDescription>
            <ReactTypeformEmbed
                url="https://form.typeform.com/to/gCHIy6hZ"
                ref={requestFunctionRef}
                style={formStyles}
                popup
            />
            <StyledFloatingBar isVisible={showBar}>
                <StyledBarContainer>
                    <FloatingBarTitle>Discover functions</FloatingBarTitle>
                    <StyledInputContainer>
                        <StyledInput
                            variant="shaded"
                            placeholder="Search for functions"
                            icon={<Search />}
                            onChange={handleSearchChange}
                            value={searchQuery}
                            height="36px"
                            width="460px"
                            className="rainbow-m-bottom_xx-small"
                            type="search"
                        />
                        <RenderIf isTrue={showInformationText}>
                            <InformationText
                                data={searchedData}
                                query={searchQuery}
                                categories={activeCategoryName}
                                filter={activeFilterName}
                                onDelete={handleDelete}
                            />
                        </RenderIf>
                    </StyledInputContainer>
                </StyledBarContainer>
            </StyledFloatingBar>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Spinner type="arc" size="x-large" variant="brand" />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <CategoriesContainer data-cy="categories-container">
                        <CategoriesPicker
                            id="picker-categories"
                            label="Categories"
                            options={categoriesOptions}
                            value={activeCategoryName}
                            onChange={(...params) => {
                                logFilterUse(activeCategoryName, params[0]);
                                return setActiveCategoryName(...params);
                            }}
                            multiple
                            className="rainbow-m-bottom_x-large"
                        />
                        <CategoriesPicker
                            id="picker-filter"
                            multiple
                            label="Filter"
                            options={filterOptions}
                            value={activeFilterName}
                            onChange={(...params) => {
                                logFilterUse(activeFilterName, params[0]);
                                return setActiveFilterName(...params);
                            }}
                        />
                    </CategoriesContainer>
                    <RightContent>
                        <StyledInput
                            variant="shaded"
                            placeholder="Search for functions"
                            icon={<Search />}
                            onChange={handleSearchChange}
                            value={searchQuery}
                            className="rainbow-m-bottom_xx-small"
                            type="search"
                        />
                        <RenderIf isTrue={showInformationText}>
                            <InformationText
                                data={searchedData}
                                query={searchQuery}
                                categories={activeCategoryName}
                                filter={activeFilterName}
                                onDelete={handleDelete}
                            />
                        </RenderIf>
                        <FunctionsContainer>
                            <CardFunctions
                                data={searchedData}
                                onInstall={(functionData) => {
                                    logEvent(events.functions.OPEN_INSTALL_DRAWER);
                                    return openModal({ functionData });
                                }}
                                onLearnMore={(functionData) => {
                                    logEvent(events.marketplace.FUNCTION_LEARN_MORE);
                                    return push(
                                        `/app/marketplace/${functionData.workspace}/${functionData.id}`,
                                    );
                                }}
                            />
                            <StyledFooter>
                                <FooterCard>
                                    <StyledLifePreserver />
                                    <div>
                                        <FooterQuestion>
                                            Not finding a solution that meets your needs?
                                        </FooterQuestion>
                                        <RequestLink
                                            href="https://roadmap.functions.store/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button
                                                variant="outline-brand"
                                                size="small"
                                            >
                                                File a request
                                            </Button>
                                        </RequestLink>
                                    </div>
                                </FooterCard>
                            </StyledFooter>
                        </FunctionsContainer>
                    </RightContent>
                </RenderIf>
            </Content>
        </MainContainer>
    );
}
