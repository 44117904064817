import { useDocOnce } from '@rainbow-modules/firebase-hooks';

const useMarketplaceInstalledFunction = ({ projectId, functionId }) => {
    const [data, isLoading] = useDocOnce({
        path: `projects/${projectId}/functions/${functionId}`,
        disabled: !functionId || !projectId,
        flat: true,
    });
    return [data, isLoading];
};

export default useMarketplaceInstalledFunction;
