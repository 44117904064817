import PropTypes from 'prop-types';

export default function StartPoint(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="8px"
            height="45px"
            viewBox="0 0 8 45"
        >
            <title>initialPoint</title>
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="flow-details/new-promote-setted-hover" transform="translate(-538.000000, -379.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M543,419.836409 L543,423.836409 L541,423.836409 L541,419.836409 L543,419.836409 Z M543,409.836409 L543,413.836409 L541,413.836409 L541,409.836409 L543,409.836409 Z M543,399.836409 L543,403.836409 L541,403.836409 L541,399.836409 L543,399.836409 Z M543,389.836409 L543,393.836409 L541,393.836409 L541,389.836409 L543,389.836409 Z M542,379 C544.209139,379 546,380.790861 546,383 C546,385.209139 544.209139,387 542,387 C539.790861,387 538,385.209139 538,383 C538,380.790861 539.790861,379 542,379 Z" id="initialPoint" />
                </g>
            </g>
        </svg>
    );
}

StartPoint.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

StartPoint.defaultProps = {
    style: {},
    className: undefined,
};
