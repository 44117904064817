import extractLabelFromId from './extractLabelFromId';

const typesList = ['text', 'firestoreDocumentPath', 'firestoreCollectionPath'];

export default function getFormattedValue(value, icon) {
    if (!value) return null;

    if (value && typeof value === 'string') {
        return {
            label: value,
        };
    }

    if (value && typesList.includes(value.type)) {
        return {
            label: value.value,
        };
    }

    if (value && value.type === 'secret') {
        return {
            value: value.value,
            label: extractLabelFromId(value.value),
            icon,
        };
    }
    return {};
}
