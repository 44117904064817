import {
    DONE, SUCCEEDED, FAILED, IN_PROGRESS, QUEUED, SKIPPED, NOT_RUN,
} from '../../status';

const messagesMap = {
    [DONE]: {
        [SUCCEEDED]: 'This check passed successfully',
        [FAILED]: 'This check has failed',
        [SKIPPED]: 'This check passed successfully',
        [NOT_RUN]: 'This check was cancelled',
    },
    [IN_PROGRESS]: 'This check is currently InProgress',
    [QUEUED]: 'This check execution is currently pending',
    [NOT_RUN]: 'This check was not executed',
};

export default function getMessageForStatus(check = {}) {
    const { status, conclusion } = check;
    const mapResult = messagesMap[status];
    return (status === DONE ? mapResult[conclusion] : mapResult);
}
