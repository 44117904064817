import PropTypes from 'prop-types';

export default function Disable(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="10px"
            height="10px"
            viewBox="0 0 10 10"
        >
            <g id="billing-&amp;-subscriptions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="projects-list-limit-exceded-actions-with-setup" transform="translate(-175.000000, -303.000000)" fill="currentColor">
                    <path d="M183.202893,310.552984 C184.453184,309.024122 184.464043,306.631695 182.875224,305.078772 C181.159462,303.401757 178.757779,303.68693 177.449836,304.800278 C179.366129,306.716415 181.282695,308.632903 183.202893,310.552984 M182.543805,311.206721 C180.626888,309.289882 178.709072,307.372183 176.792037,305.455226 C175.582016,306.922336 175.500694,309.311677 177.110097,310.906511 C178.783753,312.565051 181.182976,312.351747 182.543805,311.206721 M179.995649,313.000024 C177.238603,312.995251 174.995227,310.747852 175.000023,307.995415 C175.004796,305.238525 177.252313,302.995266 180.004867,303.000024 C182.761913,303.004796 185.005289,305.252196 185.000531,308.004633 C184.995719,310.761522 182.748203,313.00482 179.995649,313.000024" id="disabled" />
                </g>
            </g>
        </svg>
    );
}

Disable.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Disable.defaultProps = {
    style: {},
    className: undefined,
};
