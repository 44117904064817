import resolveEnvInitialValue from './resolveEnvInitialValue';

const getReconfigInitialsEnvValues = ({ envSchemas, initialEnvValues }) => envSchemas?.reduce(
    (accumulator, envVariableDefinition) => {
        const { name } = envVariableDefinition;
        const currentEnvVariableValue = initialEnvValues[name];
        const newEnvInitialValue = resolveEnvInitialValue(
            { envVariableDefinition, currentEnvVariableValue },
        );
        if (newEnvInitialValue) {
            return { ...accumulator, [name]: newEnvInitialValue };
        }
        return accumulator;
    }, {},
);

export default getReconfigInitialsEnvValues;
