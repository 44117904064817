import { Button, RenderIf } from 'react-rainbow-components';
import GlobalSpinner from '../../components/GlobalSpinner';
import useChangePlan from '../../data/hooks/useChangePlan';
import useProducts from '../../data/hooks/useProducts';
import formatCurrencyPart from '../../helpers/formatCurrencyPart';
import getMoneySymbol from '../../helpers/getMoneySymbol';
import getPlanPrice from '../../helpers/getPlanPrice';
import {
    SectionTitleContainer,
    SectionTitle,
    CardContainer,
    PlanHeaderText,
    PlanInfoText,
    TextDescription,
    Divider,
    PriceContainer,
    DollarSymbol,
    Price,
    SectionContainer,
    ButtonContainer,
    RenewalDate,
} from './styled';

const intervalMap = {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Annually',
};

const Overview = () => {
    const [products, isLoadingProducts] = useProducts();
    const { isLoading: isLoadingSubscription, hasSubscription, changePlan } = useChangePlan();

    const currentPlan = products.find((item) => item.current);
    const {
        name, description, prices, metadata: { color },
    } = currentPlan || { prices: [], metadata: { color: null } };
    const [price] = prices;
    const planName = name || 'Pay as Go';
    const planDescription = description || 'Instead of paying a monthly recurring charge, you can buy functions as needed through our Pay As You Go plan. Free usage of free functions.';
    const priceUnitAmount = price?.unit_amount || 0;
    const planInterval = intervalMap[price?.recurring?.interval];
    const formattedCurrencyParts = formatCurrencyPart(priceUnitAmount);
    const moneySymbol = getMoneySymbol(formattedCurrencyParts);
    const planPrice = getPlanPrice(formattedCurrencyParts);
    const accountLabel = hasSubscription ? 'Change Plan' : 'Upgrade Account';

    if (isLoadingProducts || isLoadingSubscription) {
        return (<GlobalSpinner />);
    }
    return (
        <>
            <SectionTitleContainer>
                <SectionTitle>Plan Details</SectionTitle>
            </SectionTitleContainer>
            <CardContainer color={color}>
                <SectionContainer>
                    <PlanHeaderText>Current Plan</PlanHeaderText>
                    <PlanInfoText>{planName}</PlanInfoText>
                    <TextDescription>
                        {planDescription}
                    </TextDescription>
                    <ButtonContainer>
                        <Button variant="base" label={accountLabel} size="small" className="rainbow-m-around_small" onClick={changePlan} id="subscription-button" />
                    </ButtonContainer>
                </SectionContainer>
                <RenderIf isTrue={currentPlan}>
                    <div className="rainbow-m-horizontal_small">
                        <PlanHeaderText>Period</PlanHeaderText>
                        <PlanInfoText>{planInterval}</PlanInfoText>
                    </div>
                </RenderIf>
                <Divider />
                <div>
                    <PlanHeaderText>Renewal Amount</PlanHeaderText>
                    <PriceContainer>
                        <DollarSymbol>{moneySymbol}</DollarSymbol>
                        <div>
                            <Price>{planPrice}</Price>
                        </div>
                    </PriceContainer>
                    <RenewalDate>Feb 12, 2022</RenewalDate>
                </div>
            </CardContainer>
        </>
    );
};

export default Overview;
