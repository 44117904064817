import logEvent from './logEvent';
import events from './events';

const logFilterUse = (optionsBefore, optionsAfter) => {
    if (optionsAfter.length - 1 === optionsBefore.length) {
        logEvent(events.marketplace.FILTERS, {
            filter: optionsAfter[optionsAfter.length - 1],
        });
    }
};

export default logFilterUse;
