import FunctionInfoHeader from './functionInfoHeader';
import TestList from './testList';

export default function TestGroup({ results }) {
    return results.map((item) => {
        const { functionName, tests } = item;
        return (
            <div key={functionName} className="rainbow-m-bottom_medium rainbow-m-horizontal_xx-small">
                <FunctionInfoHeader functionName={functionName} />
                <TestList data={tests?.results?.testResults} variant="all" />
            </div>
        );
    });
}
