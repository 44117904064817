import isConvertibleType from './isConvertibleType';

const resolveEnvInitialValue = ({ envVariableDefinition, currentEnvVariableValue = {} }) => {
    const { type: envSchemaType, default: defaultValue } = envVariableDefinition;
    const { type: currentValueType } = currentEnvVariableValue;
    const isSameType = envSchemaType === currentValueType && !!currentValueType;
    const isConvertibleEnvType = isConvertibleType({ envSchemaType, currentValueType });

    if ((isSameType || isConvertibleEnvType) && currentValueType === 'secret') {
        return currentEnvVariableValue;
    }
    if ((isSameType || isConvertibleEnvType) && currentValueType !== 'secret') {
        return { ...currentEnvVariableValue, type: envSchemaType };
    }
    if (defaultValue) {
        return { value: defaultValue, type: envSchemaType };
    }
    if (envSchemaType === 'boolean') {
        return { type: envSchemaType, value: false };
    }
    if (envSchemaType === 'color') {
        return { type: 'color', value: '#000000' };
    }
    return undefined;
};

export default resolveEnvInitialValue;
