import {
    Switch, Route, Redirect,
} from 'react-router-dom';
import Installed from './installed';
import BuildingFunction from './installedFunctionDetails/building';
import InstalledFunctionDetails from './installedFunctionDetails';
import {
    Container, Title, Description,
} from './styled';

const Main = () => (
    <Container>
        <Title>Installed Functions</Title>
        <Description>
            In this section, you will find all the functions installed in your project.
        </Description>
        <Switch>
            <Route path="/app/project/:projectId/functions/installed" component={Installed} />
            <Redirect from="/app/project/:projectId/functions" to="/app/project/:projectId/functions/installed" />
        </Switch>
    </Container>
);

export default function Functions() {
    return (
        <Switch>
            <Route path="/app/project/:projectId/functions/installed/:functionId" exact component={BuildingFunction} />
            <Route path="/app/project/:projectId/functions/installed/:region/:name" exact component={InstalledFunctionDetails} />
            <Route path="/app/project/:projectId/functions" component={Main} />
        </Switch>
    );
}
