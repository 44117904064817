import useUserInstallableBuilds from './useUserInstallableBuilds';

const useCanReconfigure = ({
    functionId, workspace, installedVersion, installedBuildId,
}) => {
    const [installableBuilds, isLoadingInstallableBuilds] = useUserInstallableBuilds({
        functionId,
        workspace,
        version: installedVersion,
    });
    const hasAccessToInstalledBuild = !isLoadingInstallableBuilds
        && installableBuilds.some(({ buildId }) => buildId === installedBuildId);
    return [hasAccessToInstalledBuild, isLoadingInstallableBuilds];
};

export default useCanReconfigure;
