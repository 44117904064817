import styled from 'styled-components';
import { Spinner, MarkdownOutput } from 'react-rainbow-components';
import { darken } from 'react-rainbow-components/styles/helpers/color';
import { QuestionFilled, ErrorCircleFilled } from '@rainbow-modules/icons';
import { RecordSection } from '@rainbow-modules/record';
import { FloatingBar } from '@rainbow-modules/layout';
import {
    DARK_WHITE, LIGHT_WHITE, RED, ORANGE,
} from '../../../colors';
import Folder from '../../../components/Icons/fileTree/folderClose';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0 12px;
    height: 100%;
    overflow: auto;
`;

export const StyledHeader = styled.header`
    padding: 12px 0 0;
    margin: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const LeftContent = styled.div`
    display: flex;
`;

export const FunctionInfoContainer = styled.div`
    display: flex;
`;

export const LoadingContainer = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

export const LoadingTabsetContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const LoadingTabContainer = styled.div`
    width: ${(props) => props.width};
    height: 12px;
    margin: 15px 10px;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
    margin-right: 16px;
`;

export const FunctionName = styled.h2`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    word-wrap: anywhere;
    word-break: break-all;
`;

export const FunctionDescription = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Content = styled.div`
    margin: 12px 16px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const TabsetContent = styled.div`
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`;

export const StyledRecordSection = styled(RecordSection)`
    margin-top: -25px;
`;

export const RecordContainer = styled.div`
    display: flex;
`;

export const TypeContainer = styled.div`
    display: flex;
`;

export const StyledType = styled.span`
    margin-left: 8px;
    text-transform: capitalize;
`;

export const IconContainer = styled.div`
    display: flex;
    margin-right: 8px;

    svg {
        width: 1.65rem;
        height: 1.65rem;
    }
`;

export const TextEllipsisContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30vw;
`;

export const BuildingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const StyledBuildingSpinner = styled(Spinner)`
    z-index: unset;
    position: unset;
    transform: unset;
    top: unset;
    left: unset;
`;

export const BuildingInfo = styled.div`
    margin-top: 20px;
`;

export const BuildingTitle = styled.h2`
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: center;
`;

export const Highlight = styled.span`
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 24px 8px 0 8px;
`;

export const BuildingDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 16px;
    text-align: center;
    margin-top: 8px;
`;

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledMarkdownOutput = styled(MarkdownOutput)`
    blockquote {
        background: ${DARK_WHITE};
    }
`;

export const StyledSpinner = styled(Spinner)`
    z-index: 1;
    position: relative;
    top: 10px;
    left: unset;
`;

export const ReconfiguringLabel = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.brand.main};
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 600px;
`;

export const Item = styled.div`
    display: flex;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    line-height: 40px;
    height: 40px;
    background: ${(props) => darken(props.theme.rainbow.palette.action.hover)};
    border-radius: 40px;
    padding: 0 20px;
    align-items: center;
`;

export const StyledIcon = styled(QuestionFilled)`
    width: 22px;
    height: 22px;
    margin-right: 8px;
    color: ${(props) => props.theme.rainbow.palette.border.main};
`;

export const WorkspaceIcon = styled(Folder)`
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
`;

export const WorkspaceContainer = styled.div`
    display: flex;
    align-items: center;
    text-transform: capitalize;
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${LIGHT_WHITE};
    margin: 0 20px 0 330px;
    padding: 14px 0 0;
`;

export const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 8px;
    margin: 16px 4px;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    width: fit-content;
`;

export const Line = styled.div`
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
    position: absolute;
    top: 8px;
    bottom: 8px;
    border: 2px solid ${RED};
`;

export const ErrorIcon = styled(ErrorCircleFilled)`
    color: ${RED};
    margin-right: 15px;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    margin-left: 15px;
`;

export const ErrorMessageDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 14px;
`;

export const StyledErrorIcon = styled(ErrorCircleFilled)`
    width: 15px;
    height: 15px;
    margin: 0 5px 2px 0;
    color: ${ORANGE};
`;

export const Description = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const TermsLink = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const StyledDefaultText = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-style: italic;
`;

// export const StyledHeaderIcon = styled(StyledErrorIcon)`
//     width: 20px;
//     height: 20px;
//     margin: 0 10px  4px 0;
//     color: ${ORANGE};
// `;

// export const StyledTable = styled(Table)`
//     margin-top: 20px;
// `;

// export const LogsFooter = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     padding: 1rem 0 0 1.3125rem;
//     margin: 0 0 10px;
// `;

// export const StyledHederContainer = styled.div`
//     margin-top: 10px;
//     padding: 10px 20px;
//     font-size: 24px;
//     color: ${(props) => props.theme.rainbow.palette.text.main};
// `;
