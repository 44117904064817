import PropTypes from 'prop-types';

export default function Skip(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="18px" height="18px" viewBox="0 0 18 18">
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="developer/workspace-CI-push-detail" transform="translate(-285.000000, -394.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M287.652632,396.652632 C291.133342,393.115789 296.859632,393.115789 300.340342,396.652632 C303.821053,400.133342 303.821053,405.859632 300.340342,409.340342 C296.859632,412.877184 291.133342,412.821053 287.652632,409.340342 C284.115789,405.859632 284.115789,400.189474 287.652632,396.652632 Z M295.051889,398.720728 C294.731923,398.415941 294.225992,398.428426 293.920762,398.748077 C293.616082,399.067951 293.628324,399.574208 293.948066,399.879144 L293.948066,399.879144 L296.280172,402.099955 L289.3,402.099957 C288.892263,402.099957 288.555614,402.405243 288.506235,402.799631 L288.506235,402.799631 L288.5,402.899959 C288.5,403.341672 288.858287,403.699957 289.3,403.699957 L289.3,403.699957 L296.289172,403.699955 L293.947749,405.921072 C293.657386,406.198353 293.620876,406.641672 293.845676,406.960443 L293.845676,406.960443 L293.920962,407.052047 C294.073752,407.212224 294.283019,407.299959 294.5,407.299959 C294.703894,407.299959 294.901505,407.222576 295.051941,407.079137 L295.051941,407.079137 L298.541199,403.754043 C298.773031,403.521626 298.9,403.220476 298.9,402.899959 C298.9,402.581155 298.774235,402.278906 298.547843,402.052514 L298.547843,402.052514 Z" id="skip" />
                </g>
            </g>
        </svg>
    );
}

Skip.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Skip.defaultProps = {
    style: {},
    className: undefined,
};
