import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RenderIf, Tree } from 'react-rainbow-components';
import BackButton from '../../../../../components/BackButton';
import GlobalSpinner from '../../../../../components/GlobalSpinner';
import Message from '../../../../../components/Message';
import getPercentValueFromCoverage from '../../../../../helpers/getPercentValueFromCoverage';
import useCIChecksTree from '../../../../../data/hooks/useCIChecksTree';
import buildFunctionsTree from './helpers/buildFunctionsTree';
import buildFunctionSummary from './helpers/buildFunctionSummary';
import getDetailsForJob from './helpers/getDetailsForJob';
import JobTitle from './jobTitle';
import JobDetails from './jobDetails';
import StatusBadge from './statusBadge';
import {
    Container,
    Header,
    PrimaryText,
    PrimaryContainer,
    StyledQueuedIcon,
    StyledFunctionIcon,
    TreeLabel,
    BuildIcon,
    SecondaryText,
    StyledResizableColumns,
    RightColumn,
    LeftColumn,
} from './styled';
import { QUEUED } from '../status';

const TEST_COVERAGE = 'Test Coverage';

const checkTypeMap = {
    'Run ESLint': 'eslint',
    'Run Tests': 'tests',
    [TEST_COVERAGE]: 'coverage',
    BuildSummary: 'summary',
};

const getCheckType = (name, parentName) => checkTypeMap[name] || checkTypeMap[parentName] || 'default';

const computePercent = (array) => {
    const percentSum = array.reduce((acc, item) => {
        const percent = getPercentValueFromCoverage(item?.description?.total?.lines?.pct);
        return acc + percent;
    }, 0);
    return (percentSum / array.length);
};

const getCoveragePercent = (job) => {
    if (job && Array.isArray(job.checksResults)) {
        const testCoverageCheck = job.checksResults.find((item) => item.name === TEST_COVERAGE);
        if (testCoverageCheck && Array.isArray(testCoverageCheck.checksResults)) {
            return computePercent(testCoverageCheck.checksResults);
        }
    }
    if (job && Array.isArray(job.children)) {
        return computePercent(job.children);
    }
    if (job && job.description && job.description.total) {
        return getPercentValueFromCoverage(job.description.total.lines?.pct);
    }
    return undefined;
};

const PushDetails = () => {
    const { workspace, push } = useParams();
    const { push: navigate } = useHistory();
    const [treeData, setTreeData] = useState([]);
    const [selectedNode, selectNode] = useState('node-1');
    const [selectedJob, setSelectedJob] = useState();

    const { data, pushData, isLoading } = useCIChecksTree({ workspace, push });
    const { status: pushStatus, conclusion: pushConclusion } = pushData || {};
    const listPath = `/app/developer/workspaces/${workspace}/ci/list`;
    const isEmpty = data.length === 0;
    const isQueued = pushStatus === QUEUED;

    useEffect(() => {
        if (pushData) {
            const initialTreeData = [buildFunctionSummary(pushData), ...buildFunctionsTree(data)];
            setTreeData(initialTreeData);
            const child = Tree.getNode(initialTreeData, [0]);
            setSelectedJob(child);
        }
    }, [data, pushData]);

    if (!isLoading && !pushData) {
        navigate(listPath);
        return null;
    }

    const updateNodeChild = (nodePath, child) => {
        const newData = [...treeData];
        newData[nodePath] = child;
        setTreeData(newData);
    };

    const expandCollapseNode = async (node) => {
        const { nodePath } = node;
        const child = Tree.getNode(treeData, nodePath);
        child.isExpanded = !child.isExpanded;
        updateNodeChild(nodePath, child);
    };

    const onNodeSelect = (node) => {
        const { name, nodePath } = node;
        const child = Tree.getNode(treeData, nodePath);
        selectNode(name);
        setSelectedJob(child);
        if (child && Array.isArray(child.children)) {
            expandCollapseNode(node);
        }
    };

    const {
        label,
        status,
        conclusion: jobConclusion,
        duration,
        checksResults,
        description,
        name,
        parentName,
        children,
    } = selectedJob || {};
    const checkType = getCheckType(name, parentName);
    const details = getDetailsForJob(selectedJob);
    const coveragePercent = getCoveragePercent(selectedJob);

    return (
        <Container>
            <Header>
                <BackButton to={listPath} />
                <PrimaryContainer>
                    <BuildIcon />
                    <div>
                        <SecondaryText>BUILD</SecondaryText>
                        <div className="rainbow-flex rainbow-align_center" data-cy="header-build-id">
                            <PrimaryText data-cy="build-id">{push}</PrimaryText>
                            <StatusBadge status={pushStatus} conclusion={pushConclusion} />
                        </div>
                    </div>
                </PrimaryContainer>
            </Header>
            <RenderIf isTrue={isLoading}>
                <GlobalSpinner />
            </RenderIf>
            <RenderIf isTrue={!isLoading && !isQueued && isEmpty}>
                <Message
                    icon={<StyledFunctionIcon />}
                    title="We don't find any data in your push"
                />
            </RenderIf>
            <RenderIf isTrue={!isLoading && isQueued}>
                <Message
                    icon={<StyledQueuedIcon />}
                    title="This push is currently in Queued"
                    description="Building process should take ~ 1 minute."
                />
            </RenderIf>
            <RenderIf isTrue={!isLoading && !isEmpty}>
                <StyledResizableColumns
                    initialDividerPosition={340}
                    minLeftWidth={{ px: 160 }}
                    minRightWidth={{ px: 360 }}
                    leftColumn={(
                        <LeftColumn>
                            <TreeLabel>Steps</TreeLabel>
                            <Tree
                                data={treeData}
                                selectedNode={selectedNode}
                                onNodeExpand={expandCollapseNode}
                                onNodeSelect={onNodeSelect}
                                ariaLabel="functions-tree"
                                id="functions-tree"
                            />
                        </LeftColumn>
                    )}
                    rightColumn={(
                        <RightColumn>
                            <RenderIf isTrue={!!selectedJob}>
                                <JobTitle
                                    name={label}
                                    status={status}
                                    conclusion={jobConclusion}
                                    ellapsedTime={duration}
                                    checkType={checkType}
                                    coveragePercent={coveragePercent}
                                />
                                <JobDetails
                                    status={status}
                                    conclusion={jobConclusion}
                                    details={details}
                                    checksResults={checksResults || children}
                                    description={description}
                                    checkType={checkType}
                                />
                            </RenderIf>
                        </RightColumn>
                    )}
                />
            </RenderIf>
        </Container>
    );
};

export default PushDetails;
