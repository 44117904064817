import PropTypes from 'prop-types';
import { Badge, RenderIf } from 'react-rainbow-components';
import { isNumber } from '@rainbow-modules/validation';
import formatDate from '../../helpers/formatDate';
import MarkdownOutputDark from '../MarkdownOutputDark';
import FormattedCurrency from '../FormattedCurrency';
import useWorkspaceAccess from '../../data/hooks/useWorkspaceAccess';
import Tags from './tags';
import Dependencies from './dependencies';
import FunctionCoverageBadge from './functionCoverageBadge';
import { LIGHT_GRAY, GRAY } from '../../colors';
import {
    DocumentationContainer,
    SecondaryInformation,
    SecondaryInformationTitle,
    SecondaryInformationDescription,
    FreeBadge,
    BoughtBadge,
    WorkspaceName,
    WorkspaceIcon,
    LanguageBadge,
    ConditionBadge,
} from './styled';

const badgeStyles = { color: GRAY, backgroundColor: LIGHT_GRAY };

const FunctionOverview = ({
    data, isPurchased, hiddenSections, price,
}) => {
    const {
        docs,
        dependencies = {},
        version,
        tags,
        createdAt,
        updatedAt,
        workspace,
    } = data;
    const dependenciesAmount = Object.keys(dependencies).length;
    const formatedCreatedAt = formatDate(createdAt);
    const formatedUpdatedAt = formatDate(updatedAt);
    const hasPrice = isNumber(price);
    const isFree = price === 0;
    const workspaceName = workspace || 'Function Store';
    const [hasWorkspaceAccess] = useWorkspaceAccess(workspace);

    const showCondition = hasWorkspaceAccess && !hiddenSections.includes('condition');
    const showCost = hasPrice && !hiddenSections.includes('cost');
    const showBoughtBadge = !isFree && isPurchased && !hasWorkspaceAccess;
    const showPrice = (!isFree && !isPurchased) || (!isFree && hasWorkspaceAccess);

    return (
        <>
            <DocumentationContainer className="rainbow-p-left_xx-small">
                <MarkdownOutputDark value={docs} />
            </DocumentationContainer>
            <SecondaryInformation data-cy="function-info">
                <RenderIf isTrue={showCost}>
                    <SecondaryInformationTitle>Cost</SecondaryInformationTitle>
                    <RenderIf isTrue={isFree}>
                        <FreeBadge size="small" label="free" className="rainbow-m-top_xx-small rainbow-m-bottom_large" />
                    </RenderIf>
                    <RenderIf isTrue={showPrice}>
                        <SecondaryInformationDescription className="rainbow-m-top_xx-small rainbow-m-bottom_large">
                            <FormattedCurrency
                                value={price}
                            />
                        </SecondaryInformationDescription>
                    </RenderIf>
                    <RenderIf isTrue={showBoughtBadge}>
                        <BoughtBadge size="small" label="Bought" className="rainbow-m-top_xx-small rainbow-m-bottom_large" />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={showCondition}>
                    <SecondaryInformationTitle>Condition</SecondaryInformationTitle>
                    <ConditionBadge
                        className="rainbow-m-top_x-small rainbow-m-bottom_large"
                        label="OWNER"
                        size="small"
                    />
                </RenderIf>
                <RenderIf isTrue={version}>
                    <SecondaryInformationTitle>Version</SecondaryInformationTitle>
                    <SecondaryInformationDescription className="rainbow-m-top_xx-small rainbow-m-bottom_large">
                        {version}
                    </SecondaryInformationDescription>
                </RenderIf>
                <FunctionCoverageBadge data={data} />
                <SecondaryInformationTitle className="rainbow-m-top_large">Language</SecondaryInformationTitle>
                <LanguageBadge
                    className="rainbow-m-top_x-small"
                    label="JAVASCRIPT"
                    size="small"
                />
                <RenderIf isTrue={formatedUpdatedAt && !hiddenSections.includes('updatedAt')}>
                    <SecondaryInformationTitle className="rainbow-m-top_large rainbow-m-bottom_xx-small">
                        Updated At
                    </SecondaryInformationTitle>
                    <SecondaryInformationDescription>
                        {formatedUpdatedAt}
                    </SecondaryInformationDescription>
                </RenderIf>
                <RenderIf isTrue={formatedCreatedAt}>
                    <SecondaryInformationTitle className="rainbow-m-bottom_xx-small rainbow-m-top_large">
                        Created At
                    </SecondaryInformationTitle>
                    <SecondaryInformationDescription>
                        {formatedCreatedAt}
                    </SecondaryInformationDescription>
                </RenderIf>
                <SecondaryInformationTitle className="rainbow-m-top_large rainbow-m-bottom_xx-small">
                    Workspace
                </SecondaryInformationTitle>
                <WorkspaceName className="rainbow-flex rainbow-align_center">
                    <WorkspaceIcon />
                    {workspaceName}
                </WorkspaceName>
                <RenderIf isTrue={tags}>
                    <SecondaryInformationTitle className="rainbow-m-top_large">Tags</SecondaryInformationTitle>
                    <Tags tags={tags} />
                </RenderIf>
                <RenderIf isTrue={dependencies}>
                    <SecondaryInformationTitle className="rainbow-m-top_large">
                        Dependencies
                        {' '}
                        <Badge
                            className="rainbow-m-top_x-small rainbow-m-bottom_x-small rainbow-m-left_xx-small"
                            label={dependenciesAmount}
                            size="small"
                            style={badgeStyles}
                        />
                    </SecondaryInformationTitle>
                    <Dependencies dependencies={dependencies} />
                </RenderIf>
            </SecondaryInformation>
        </>
    );
};

FunctionOverview.propTypes = {
    data: PropTypes.object,
    isPurchased: PropTypes.bool,
    hiddenSections: PropTypes.arrayOf(
        PropTypes.oneOf(['cost', 'updatedAt', 'condition']),
    ),
    price: PropTypes.number,
};

FunctionOverview.defaultProps = {
    data: undefined,
    price: undefined,
    isPurchased: false,
    hiddenSections: [],
};

export default FunctionOverview;
