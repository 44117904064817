export const WHITE = '#fff';
export const LIGHT_WHITE = '#f4f6f9';
export const DARK_WHITE = '#e3e5ec';
export const GREEN = '#7bbf91';
export const DARK_GREEN = '#00ab02';
export const LIGHT_BLACK = '#20252c';
export const LIGHTER_BLACK = '#2C3037';
export const GRAY = '#7e898b';
export const LIGHT_GRAY = '#d2dddf';
export const DARK_GRAY = '#3F4955';
export const YELLOW = '#f4d87a';
export const RED = '#f2707a';
export const DARK_RED = '#f06372';
export const BLACK_RUSSIAN = '#1e2128';
export const EBONY = '#272c35';
export const BLUE = '#01B6F5';
export const ROMAN_SILVER = '#8592a0';
export const MADISON = '#2a3a52';
export const ORANGE = '#f3a211';
export const DARK_BLUE = '#061c3f';
export const UPGRADE_GREEN = '#8bc570';
