import tryParseJson from '../../../helpers/tryParseJson';
import typesArray from './typesArray';

const isDefined = (value) => value !== undefined && value !== null;

const getNormalizedEnv = ({ env = [], envDefinition = [] }) => envDefinition.reduce(
    (accEnv, currentEnvDefinition) => {
        const { name, type } = currentEnvDefinition;
        const lowerCaseType = String(type).toLowerCase();
        const currentEnvValue = env[name];
        if (isDefined(currentEnvValue)) {
            if (type && lowerCaseType === 'json') {
                return {
                    ...accEnv,
                    [name]: {
                        type: 'json',
                        value: tryParseJson(currentEnvValue),
                    },
                };
            }
            if (type && typesArray.includes(lowerCaseType)) {
                const newCurrentEnvValue = lowerCaseType === 'number' ? Number(currentEnvValue) : currentEnvValue;
                return {
                    ...accEnv,
                    [name]: {
                        type: lowerCaseType,
                        value: newCurrentEnvValue,
                    },
                };
            }
            if (type && lowerCaseType === 'color') {
                return {
                    ...accEnv,
                    [name]: {
                        type: 'color',
                        value: currentEnvValue.hex,
                    },
                };
            }
            if (typeof currentEnvValue === 'string') {
                return {
                    ...accEnv,
                    [name]: {
                        type: 'text',
                        value: currentEnvValue,
                    },
                };
            }
            return {
                ...accEnv,
                [name]: currentEnvValue,
            };
        }
        return accEnv;
    }, {},
);

export default getNormalizedEnv;
