import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import { ButtonLink } from '../styled';

const GoToCloudFunctions = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header='In the GCP Console, go to the "Cloud Functions" page.'
        className="rainbow-m-top_xx-large"
    >
        <ButtonLink
            href="https://console.cloud.google.com/functions/"
            target="_blank"
            rel="noopener noreferrer"
        >
            Go to Cloud Functions
        </ButtonLink>
    </Step>
);

GoToCloudFunctions.propTypes = {
    stepNumber: PropTypes.number,
};

GoToCloudFunctions.defaultProps = {
    stepNumber: undefined,
};

export default GoToCloudFunctions;
