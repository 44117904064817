import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import Step2 from '../images/2-select-project.png';
import { StepDescription, StyledImage } from '../styled';

const SelectProject = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header="Select the project to which you want to install the ZIP."
        className="rainbow-m-top_xx-large"
    >
        <StepDescription>
            In the resource selector, select the project name or organization.
            Make sure that the project to which you want to install the ZIP is selected.
        </StepDescription>
        <StyledImage src={Step2} />
    </Step>
);

SelectProject.propTypes = {
    stepNumber: PropTypes.number,
};

SelectProject.defaultProps = {
    stepNumber: undefined,
};

export default SelectProject;
