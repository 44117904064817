import styled from 'styled-components';
import { CopyToClipboardButton } from '@rainbow-modules/record';
import { DARK_WHITE } from '../../colors';

export const Container = styled.span`
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-right: 5px;
`;

export const Content = styled.span`
    background-color: ${DARK_WHITE};
    border-radius: 50px 5px 5px 50px;
    padding: 1px 7px 3px 32px;
    white-space: nowrap;
    max-width: 560px;
    overflow-x: auto;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    position: absolute;

    > svg {
        width: 15px !important;
        height: 15px !important;
    }
`;
