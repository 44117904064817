import styled, { keyframes } from 'styled-components';
import { InputSearch } from '@rainbow-modules/search';
import { FloatingBar } from '@rainbow-modules/layout';
import { Picklist, Spinner, Badge } from 'react-rainbow-components';
import {
    GREEN, WHITE, GRAY, ROMAN_SILVER,
} from '../../../../../colors';
import StepFlow from '../../../../../components/Icons/stepFlow';
import FxIcon from '../../../../../components/Icons/fxIcon';
import ProjectPicker from '../../../../../components/WorkspaceProjectPicker';

const fadeInOpacity = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

export const Container = styled.div`
    padding: 20px 32px 8px 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Content = styled.article`
    display: flex;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    background: ${(props) => props.theme.rainbow.palette.background.highlight};
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
`;

export const LeftContent = styled.div`
    display: flex;
    width: 50%;
`;

export const RightContent = styled.div`
    display: flex;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    width: 50%;
    background: ${WHITE};
    border-left: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
`;

export const Header = styled.header`
    display: flex;
    align-items: center;
    margin: 20px 0 8px 24px;

    ${(props) => props.size === 'small' && `
        margin: 16px 0 8px 24px;
    `}
`;

export const HeaderLabel = styled.h2`
    font-size: 24px;
    letter-spacing: 0.25px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-left: 12px;

    ${(props) => props.size === 'small' && `
        font-size: 18px;
    `}
`;

export const SectionContent = styled.article`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const StepIcon = styled(StepFlow)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    width: 20px;
    height: 20px;
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    padding: 20px;
    width: 100%;
    justify-content: flex-end;
`;

export const SectionInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 48px 20px 60px;
`;

export const AddFunctionsFilter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0 8px 0;
`;

export const FunctionColumnContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
`;

export const StyledFlowIcon = styled(FxIcon)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 12px;
    width: 34px;
    height: 34px;
    flex-shrink: 0;
`;

export const StyledLink = styled.p`
    font-size: 15px;
    line-height: 1.5;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: none;
    text-align: left;
    margin-right: 6px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const FlowDescription = styled.p`
    text-align: left;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Bold = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const TriggerColumnContainer = styled.div`
    margin: 8px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const FunctionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledInputSearch = styled(InputSearch)`
    flex-grow: 1;
`;

export const StyledProjectPicker = styled(ProjectPicker)`
    width: 100%;
`;

export const StyledPicklist = styled(Picklist)`
    width: 180px;
`;

export const StyledSpinner = styled(Spinner)`
    display: flex;
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${WHITE};
    padding: 0 0 8px 0;
`;

export const SectionContentContainer = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
`;

export const StyledBadge = styled(Badge)`
    color: ${WHITE};
    margin-left: 12px;
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    background-color: ${GRAY};

    ${(props) => props.label === 'active' && `
        background-color: ${GREEN};
    `}
`;

export const FunctionInfoContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    align-items: start;
    padding: 0 8px;
    overflow: hidden;
`;

export const FunctionName = styled.span`
    font-size: 15px;
    line-height: 1.5em;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: left;
    margin-right: 6px;
    display: block;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const WorkspaceName = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0;
    line-height: 1.1;
`;

export const ImportedBadge = styled(Badge)`
    color: ${WHITE};
    background-color: ${ROMAN_SILVER};
    animation: ${fadeInOpacity} 0.5s ease-in-out;
    font-size: 10px;
    margin: 0;
`;

export const FunctionCheckInfo = styled.div`
    font-size: 10px;
    display: flex;
    margin: 0 8px;
    align-items: center;
`;
