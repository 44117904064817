import styled from 'styled-components';
import { FloatingBar } from '@rainbow-modules/layout';
import { LIGHT_WHITE } from '../../colors';
import FxIcon from '../../components/Icons/fxIcon';

export const DeveloperContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: ${LIGHT_WHITE};
    height: 100vh;
    border-radius: 22px 0 0 0;
    padding: 32px 28px;
    overflow: auto;
    box-sizing: border-box;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 0 0 12px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const FloatingBarTitle = styled(Title)`
    font-size: 20px;
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 20px 12px;
`;

export const DocContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    margin-left: 20px;
`;

export const DocContent = styled.div`
    margin: 30px 0 0 10px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
`;

export const TopGradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.3) 100%);
    height: 36px;
    position: absolute;
    pointer-events: none;
    top: 160px;
    right: 0;
    left: 0;
`;

export const Icon = styled(FxIcon)`
    height: 20px;
    width: 20px;
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${LIGHT_WHITE};
    margin: 0 20px 0 90px;
    padding: 14px 0 0;
`;
