import PropTypes from 'prop-types';
import {
    ColumnContainer,
    StyledEnvironmentIcon,
    StyledValue,
} from '../styled';

const EnvironmentColumn = ({ value }) => (
    <ColumnContainer>
        <StyledEnvironmentIcon />
        <StyledValue>
            {value}
        </StyledValue>
    </ColumnContainer>
);

EnvironmentColumn.propTypes = {
    value: PropTypes.string.isRequired,
};

export default EnvironmentColumn;
