import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { RainbowThemeContainer, Drawer } from 'react-rainbow-components';
import InternalTooltip from 'react-rainbow-components/components/InternalTooltip';
import { Apps, AppsBorder } from '@rainbow-modules/icons';
import { PAYOUT_DRAWER } from '../../constants';
import { LIGHTER_BLACK, WHITE } from '../../colors';
import UserMenu from '../../components/UserMenu';
import Code from '../../components/Icons/code';
import extractSectionName from './helpers/extractSectionName';
import Payout from '../../pages/payout';
import {
    Container,
    StyledLogo,
    Top,
    Bottom,
    Bar,
    IconBar,
    IconBarSelected,
    StyledSideBarOption,
    StyledLink,
    DocsIcon,
    StyledStore,
    StyledStoreFilled,
    TextTooltip,
} from './styled';

const theme = {
    rainbow: {
        palette: {
            mainBackground: LIGHTER_BLACK,
            brand: LIGHTER_BLACK,
        },
    },
};

const lightTheme = {
    rainbow: {
        palette: {
            mainBackground: WHITE,
        },
    },
};

const MainNavigationBar = () => {
    const { pathname } = useLocation();
    const triggerRef = useRef();
    const tooltipRef = useRef();
    const [isVisible, setVisible] = useState(false);
    const activeSection = extractSectionName(pathname);
    const connectedPayoutProps = useConnectModal(PAYOUT_DRAWER, {});
    const [, closePayoutDrawer] = useOpenModal(PAYOUT_DRAWER);
    const [openPayoutDrawer] = useOpenModal(PAYOUT_DRAWER);

    const onMouseEnter = () => {
        setVisible(true);
    };

    const onMouseLeave = () => {
        setVisible(false);
    };

    return (
        <Container>
            <RainbowThemeContainer theme={theme}>
                <Top>
                    <a href="https://www.functions.store/">
                        <StyledLogo />
                    </a>
                    <Bar selectedItem={activeSection} hideSelectedItemIndicator>
                        <StyledSideBarOption
                            name="marketplace"
                            path="/app/marketplace/"
                            className="rainbow-m-bottom_medium rainbow-m-top_large"
                            icon={<IconBar as={StyledStore} />}
                            selectedIcon={<IconBarSelected as={StyledStoreFilled} />}
                            tooltip="Marketplace"
                        />
                        <StyledSideBarOption
                            name="projects"
                            path="/app/projects"
                            className="rainbow-m-bottom_medium"
                            icon={<IconBar as={AppsBorder} />}
                            selectedIcon={<IconBarSelected as={Apps} />}
                            tooltip="Projects"
                        />
                        <StyledSideBarOption
                            name="developer"
                            path="/app/developer"
                            className="rainbow-m-bottom_medium"
                            icon={<IconBar as={Code} />}
                            selectedIcon={<IconBarSelected as={Code} />}
                            tooltip="Workspaces"
                        />
                    </Bar>
                </Top>
                <Bottom>
                    <StyledLink
                        href="https://docs.functions.store/functions/"
                        target="blank"
                        rel="noopener"
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        ref={triggerRef}
                    >
                        <DocsIcon />
                    </StyledLink>
                    <RainbowThemeContainer theme={lightTheme}>
                        <UserMenu onShowPayout={() => openPayoutDrawer()} />
                    </RainbowThemeContainer>
                </Bottom>
                <InternalTooltip
                    triggerElementRef={triggerRef}
                    isVisible={isVisible}
                    ref={tooltipRef}
                    preferredPosition="right"
                >
                    <TextTooltip>Documentation</TextTooltip>
                </InternalTooltip>
            </RainbowThemeContainer>
            <Drawer
                header="Payouts"
                slideFrom="right"
                size="medium"
                onRequestClose={closePayoutDrawer}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...connectedPayoutProps}
            >
                <Payout />
            </Drawer>
        </Container>
    );
};

export default MainNavigationBar;
