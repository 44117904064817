import PropTypes from 'prop-types';
import { RenderIf, Column } from 'react-rainbow-components';
import Message from '../../components/Message';
import useSecretsList from '../../data/hooks/useSecretsList';
import formatData from './helpers/formatData';
import Actions from './actions';
import ErrorMessage from './errorMessage';
import { MessageIcon, CreateButton, StyledTable } from './styled';

export default function SecretsList({ projectId, onCreateSecret }) {
    const {
        data, isLoading, refetch, isError, error,
    } = useSecretsList({ projectId });
    const secretsData = isLoading ? [] : formatData(data);

    if (isError) return <ErrorMessage error={error} />;

    const hasSecrets = secretsData.length > 0;

    return (
        <>
            <RenderIf isTrue={!isLoading && !hasSecrets}>
                <Message
                    className="rainbow-m-bottom_xx-large"
                    icon={<MessageIcon />}
                    title="Start creating and managing Secrets for your functions"
                    description="Secrets store API keys, passwords, certificates, and other sensitive data."
                    action={(
                        <CreateButton
                            className="rainbow-m-top_large"
                            variant="brand"
                            label="Create Secret"
                            onClick={onCreateSecret}
                        />
                    )}
                />
            </RenderIf>
            <RenderIf isTrue={isLoading || hasSecrets}>
                <StyledTable id={`secrets-${projectId}`} data={secretsData} keyField="name" variant="listview" isLoading={isLoading}>
                    <Column header="Secret Name" field="name" cellAlignment="left" />
                    <Column header="Location" field="location" cellAlignment="left" width={250} />
                    <Column header="Encryption" field="encryption" cellAlignment="left" width={230} />
                    <Column header="Created At" field="createTime" cellAlignment="left" width={200} />
                    <Column component={Actions} onDelete={refetch} width={50} />
                </StyledTable>
            </RenderIf>
        </>
    );
}

SecretsList.propTypes = {
    projectId: PropTypes.string.isRequired,
    onCreateSecret: PropTypes.func,
};

SecretsList.defaultProps = {
    onCreateSecret: () => {},
};
