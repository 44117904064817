import styled from 'styled-components';

export const ServiceAccountValue = styled.div`
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.rainbow.palette.border.disabled};
    padding-left: 8px;
    border-radius: 8px 20px 20px 8px;
    height: auto;

    & > span {
        white-space: nowrap;
        max-width: 35vw;
        overflow-x: auto;
    }
`;

export const LoadingShapeStyle = styled.div`
    margin: 8px 0;
    height: 25px;
    width: 400px;
`;
