import PropTypes from 'prop-types';
import { Drawer } from 'react-rainbow-components';
import FunctionInstallForm from '../../../../components/FunctionInstallForm';

const InstallDrawer = (props) => {
    const {
        onRequestClose, buildId, functionId, workspaceId, versionId, ...functionInstallProps
    } = props;
    const preview = {
        buildId, functionId, workspaceId, versionId,
    };
    return (
        <Drawer
            id="preview-install-drawer"
            size="medium"
            slideFrom="right"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...functionInstallProps}
            onRequestClose={onRequestClose}
        >
            <FunctionInstallForm mode="preview" preview={preview} />
        </Drawer>
    );
};

InstallDrawer.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    buildId: PropTypes.string.isRequired,
    functionId: PropTypes.string.isRequired,
    workspaceId: PropTypes.string.isRequired,
    versionId: PropTypes.string.isRequired,
};

export default InstallDrawer;
