import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { StyledGCPLogo, StyledLogo } from './styled';

const FunctionLogo = ({ functionType }) => (
    <>
        <RenderIf isTrue={functionType !== 'marketplace'}>
            <StyledGCPLogo className="rainbow-m-right_small" />
        </RenderIf>
        <RenderIf isTrue={functionType === 'marketplace'}>
            <StyledLogo className="rainbow-m-right_x-small" />
        </RenderIf>
    </>
);

FunctionLogo.propTypes = {
    functionType: PropTypes.string,
};

FunctionLogo.defaultProps = {
    functionType: undefined,
};

export default FunctionLogo;
