import PropTypes from 'prop-types';
import { RecordField } from '@rainbow-modules/record';
import { memoryMap } from '../../../constants';
import { StyledDefaultText } from './styled';

const MIN_INSTANCES_DEFAULT = 0;
const MAX_INSTANCES_DEFAULT = 'Parameter not defined';

// eslint-disable-next-line react/prop-types
const MaxInstances = ({ value }) => {
    if (value) {
        return value;
    }
    return <StyledDefaultText>{MAX_INSTANCES_DEFAULT}</StyledDefaultText>;
};

const Runtime = ({ isLoading, details }) => {
    const {
        maxInstances, minInstances, timeout, availableMemoryMb,
    } = details || {};
    const timeoutSeconds = timeout.slice(0, -1);
    return (
        <>
            <RecordField
                label="Memory Allocated"
                value={memoryMap[availableMemoryMb]}
                isLoading={isLoading}
            />
            <RecordField
                label="Timeout"
                value={`${timeoutSeconds} sec`}
                isLoading={isLoading}
            />
            <RecordField
                label="Minimum number of instances"
                value={minInstances || MIN_INSTANCES_DEFAULT}
                type="number"
                isLoading={isLoading}
            />
            <RecordField
                label="Maximum number of instances"
                value={maxInstances}
                component={MaxInstances}
                isLoading={isLoading}
            />
        </>
    );
};

Runtime.propTypes = {
    isLoading: PropTypes.bool,
    details: PropTypes.object,
};

Runtime.defaultProps = {
    isLoading: false,
    details: undefined,
};

export default Runtime;
