import PropTypes from 'prop-types';
import LinkColumn from '../../../../components/LinkColumn';

const FunctionLink = ({ value, to }) => <LinkColumn to={to} value={value} />;

FunctionLink.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    to: PropTypes.string.isRequired,
};

export default FunctionLink;
