import PropTypes from 'prop-types';

export default function Import(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="44px"
            height="44px"
            viewBox="0 0 44 44"
        >
            <title>Combined Shape</title>
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="developer/workspace-functions-with-import-section-empty" transform="translate(-382.000000, -509.000000)" fill="currentColor" fillRule="nonzero">
                    <g id="Group-21" transform="translate(252.000000, 509.000000)">
                        <path d="M151.968732,1.38555833e-12 C164.101728,1.38555833e-12 173.937464,9.83573631 173.937464,21.9687319 C173.937464,34.1017275 164.101728,43.9374638 151.968732,43.9374638 C139.835736,43.9374638 130,34.1017275 130,21.9687319 C130,9.83573631 139.835736,1.38555833e-12 151.968732,1.38555833e-12 Z M159.057227,31.6939805 L145.033052,31.6939805 C144.085694,31.6939805 143.317644,32.4620306 143.317644,33.409389 C143.317644,34.3567473 144.085694,35.1246366 145.033052,35.1247974 L145.033052,35.1247974 L159.057227,35.1247974 C160.004585,35.1247974 160.772635,34.3567473 160.772635,33.409389 C160.772635,32.4620306 160.004585,31.6939805 159.057227,31.6939805 L159.057227,31.6939805 Z M151.951522,9.09370589 C151.004164,9.09370589 150.236114,9.86175601 150.236114,10.8091143 L150.23556,23.1261617 L146.30436,19.1913871 C145.622989,18.5331733 144.537008,18.5521494 143.878795,19.2335205 C143.236662,19.8983277 143.236662,20.9521453 143.878795,21.6169525 L150.679067,28.4177512 C150.992969,28.7647329 151.446798,28.9827397 151.951522,28.9827397 C152.083298,28.9827397 152.211604,28.9678793 152.334856,28.939743 C152.335664,28.9376555 152.337661,28.9371993 152.339658,28.9367395 C152.650137,28.8665837 152.927354,28.7111436 153.147363,28.4971814 L153.156301,28.4879524 L153.156301,28.4879524 L160.027306,21.6169525 C160.696133,20.9460343 160.694525,19.8598931 160.023446,19.1910655 C159.702139,18.8705622 159.266814,18.6906107 158.812835,18.6904472 C158.357569,18.6896458 157.920636,18.8697581 157.598363,19.1913871 L153.66656,23.1251617 L153.666931,10.8091143 C153.66677,9.86175601 152.898881,9.09370589 151.951522,9.09370589 Z" id="Combined-Shape" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

Import.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Import.defaultProps = {
    style: {},
    className: undefined,
};
