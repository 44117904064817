import PropTypes from 'prop-types';

export default function GoogleCloudLogo(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="64px" height="53px" viewBox="0 0 64 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="functions-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="google-cloud" transform="translate(0.000000, 0.872433)" fillRule="nonzero">
                    <path d="M40.6048617,14.1813915 L42.5575859,14.1813915 L48.1207041,8.61827326 L48.3943001,6.25783738 C38.0432523,-2.87812238 22.2417435,-1.89371333 13.1057837,8.45733445 C10.5656329,11.3327745 8.72288349,14.7527242 7.71969824,18.4543168 C8.33931266,18.1994971 9.02598491,18.1592624 9.67242246,18.3362951 L20.7959765,16.5015926 C20.7959765,16.5015926 21.3619447,15.5654652 21.6543168,15.6244761 C26.6031852,10.190109 34.9317687,9.55708298 40.6450964,14.1813915 L40.6048617,14.1813915 Z" id="Path" fill="#EA4335" />
                    <path d="M56.0442582,18.4543168 C54.7647946,13.7468567 52.141492,9.51416597 48.4908634,6.27661358 L40.6853311,14.0821459 C43.9818944,16.7751886 45.8595138,20.8335289 45.7790444,25.0903604 L45.7790444,26.4771165 C49.617435,26.4771165 52.7262364,29.5886002 52.7262364,33.4243085 C52.7262364,37.2626991 49.6147527,40.3715004 45.7790444,40.3715004 L31.8819782,40.3715004 L30.4952221,41.7770327 L30.4952221,50.1109807 L31.8819782,51.4977368 L45.7790444,51.4977368 C53.7455155,51.55943 60.8134116,46.3959765 63.1792121,38.7889355 C65.5396479,31.1792121 62.6427494,22.9176865 56.0442582,18.4543168 L56.0442582,18.4543168 Z" id="Path" fill="#4285F4" />
                    <path d="M17.9661358,51.4199497 L31.863202,51.4199497 L31.863202,40.2963956 L17.9661358,40.2963956 C16.9763621,40.2963956 15.9973177,40.0844929 15.0960604,39.6714166 L13.1433361,40.2749371 L7.54266555,45.8380553 L7.05448449,47.7907795 C10.1954736,50.1619447 14.0284996,51.4360436 17.9661358,51.4199497 L17.9661358,51.4199497 Z" id="Path" fill="#34A853" />
                    <path d="M17.9661358,15.3320037 C10.2464376,15.3777033 3.40922045,20.321207 0.946856664,27.6358759 C-1.51550712,34.9505448 0.938809723,43.0243085 7.05716681,47.7290863 L15.1175189,39.6687343 C11.6197821,38.0888516 10.0667225,33.9741827 11.6466052,30.4764459 C13.2264878,26.9787091 17.3411567,25.4256496 20.8388935,27.0055323 C22.3785415,27.7029338 23.6124057,28.936798 24.3098072,30.4764459 L32.3701593,22.4160939 C28.9394803,17.933948 23.6097234,15.3133277 17.9661358,15.3320037 L17.9661358,15.3320037 Z" id="Path" fill="#FBBC05" />
                </g>
            </g>
        </svg>
    );
}

GoogleCloudLogo.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

GoogleCloudLogo.defaultProps = {
    style: {},
    className: undefined,
};
