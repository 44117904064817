import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px 28px 8px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 0 2px 0;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    display: flex;
    align-items: center;
`;

export const Description = styled.h2`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 20px 0;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;
