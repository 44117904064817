import { Tile } from '@rainbow-modules/dashboard';
import PropTypes from 'prop-types';
import getIconForStatus from './helpers/getIconForStatus';

const labelMap = {
    succeeded: 'Success',
    skipped: 'Skipped',
    failed: 'Failed',
    cancelled: 'Not-Run',
    total: 'Total',
};

// eslint-disable-next-line react/prop-types
const ConclusionTiles = ({ count, conclusion }) => {
    const icon = getIconForStatus(
        { status: 'done', conclusion },
        { size: 'x-small', className: 'rainbow-m-right_xx-small' },
    );
    return (
        <Tile
            className="rainbow-m-right_small rainbow-m-bottom_small"
            variant="flat"
            value={count}
            label={(
                <div className="rainbow-flex rainbow-align_center">
                    {icon}
                    {labelMap[conclusion]}
                </div>
            )}
        />
    );
};

const Tiles = ({ countCheckResults }) => {
    const {
        succeeded = 0, skipped = 0, failed = 0, cancelled = 0,
    } = countCheckResults;
    const total = succeeded + skipped + failed + cancelled;
    return (
        <>
            {Object.keys(countCheckResults).map((item) => (
                <ConclusionTiles
                    conclusion={item}
                    count={countCheckResults[item]}
                    key={`tile-${item}`}
                />
            ))}
            <ConclusionTiles conclusion="total" count={total} />
        </>
    );
};

Tiles.propTypes = {
    countCheckResults: PropTypes.exact({
        succeeded: PropTypes.number,
        skipped: PropTypes.number,
        failed: PropTypes.number,
        cancelled: PropTypes.number,
    }),
};

Tiles.defaultProps = {
    countCheckResults: undefined,
};

export default Tiles;
