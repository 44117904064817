import { useState } from 'react';
import firebaseCallable from '../services/firebaseCallable';
// import downloadFileXHR from '../../helpers/downloadFileXHR';
import createDownloadLink from '../../helpers/createDownloadLink';

export default function useDownloadFunctionCode({
    functionId, workspace, buildId,
}) {
    const [isDownloading, setIsDownloading] = useState(false);

    const download = async () => {
        if (isDownloading) return;
        try {
            setIsDownloading(true);
            const result = await firebaseCallable('functions-getFullSource', {
                functionId,
                workspace,
                buildId,
            });
            createDownloadLink(result);
        } catch (error) {
            // No throw
        }
        setIsDownloading(false);
    };

    return {
        isDownloading,
        download,
    };
}
