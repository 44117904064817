import PropTypes from 'prop-types';
import getCreditCardType from '../../../helpers/getCreditCardType';
import {
    BillingInfoContainer, Icon, CardType, CardNumber, StyledLoadingShape,
} from './styled';

const BillingInfo = ({ info = {}, isLoading }) => {
    const { brand, last4 } = info;
    const { icon, label } = getCreditCardType(brand);

    if (isLoading) {
        return (
            <BillingInfoContainer>
                <Icon as={icon} />
                <div className="rainbow-m-left_small">
                    <StyledLoadingShape width="4rem" />
                    <StyledLoadingShape width="8rem" />
                </div>
            </BillingInfoContainer>
        );
    }

    return (
        <BillingInfoContainer>
            <Icon as={icon} />
            <div className="rainbow-m-left_small">
                <CardType>{label}</CardType>
                <CardNumber>
                    ••••
                    {' '}
                    {last4}
                </CardNumber>
            </div>
        </BillingInfoContainer>
    );
};

BillingInfo.propTypes = {
    info: PropTypes.object,
    isLoading: PropTypes.bool,
};

BillingInfo.defaultProps = {
    info: undefined,
    isLoading: false,
};

export default BillingInfo;
