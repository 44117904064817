import PropTypes from 'prop-types';
import { CheckCircleFilled, ErrorCircleFilled, ClockFilled } from '@rainbow-modules/icons';
import {
    GREEN, RED, ROMAN_SILVER, WHITE,
} from '../../../../../../colors';
import {
    StatusColumnContainer, Label, IconContainer, SpinnerIcon,
} from './styled';
import {
    SUCCEEDED, FAILED, IN_PROGRESS, QUEUED, DONE, SKIPPED,
} from '../../status';

const infoMap = {
    [DONE]: {
        [SUCCEEDED]: {
            cellColors: {
                backgroundColor: GREEN, color: WHITE,
            },
            icon: <CheckCircleFilled />,
            label: 'Succeeded',
        },
        [FAILED]: {
            cellColors: {
                backgroundColor: RED, color: WHITE,
            },
            icon: <ErrorCircleFilled />,
            label: 'Failed',
        },
        // TODO: add skipped info
        [SKIPPED]: {
            cellColors: {
                backgroundColor: 'unset', color: 'unset',
            },
            icon: null,
        },
    },
    [IN_PROGRESS]: {
        cellColors: {
            backgroundColor: '#01b6f5', color: WHITE,
        },
        icon: <SpinnerIcon type="arc" variant="neutral" size="x-small" />,
        label: 'Running',
    },
    [QUEUED]: {
        cellColors: {
            backgroundColor: ROMAN_SILVER, color: WHITE,
        },
        icon: <ClockFilled />,
        label: 'Queued',
    },
    default: {
        cellColors: {
            backgroundColor: 'unset', color: 'unset',
        },
        icon: null,
    },
};

export default function StatusColumn({ value, row: { conclusion } }) {
    const mapResult = infoMap[value] || infoMap.default;
    const { cellColors, icon, label = value } = value === DONE ? mapResult[conclusion] : mapResult;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <StatusColumnContainer data-cy="build-status" {...cellColors}>
            <IconContainer>
                {icon}
            </IconContainer>
            <Label>{label}</Label>
        </StatusColumnContainer>
    );
}

StatusColumn.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
};
