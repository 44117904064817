import firebase from 'firebase';

const normalizeDate = (date) => {
    if (date) {
        if (date instanceof firebase.firestore.Timestamp) {
            return date.toDate();
        }
        return new Date(date);
    }
    return null;
};

export default normalizeDate;
