import algoliasearch from 'algoliasearch';
import buildAlgoliaFilters from '../../helpers/buildAlgoliaFilters';

const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
);
const index = client.initIndex(process.env.REACT_APP_ALGOLIA_INDEX_FUNCTIONS);

const ITEMS_PER_PAGE = 100;

const searchFunctionsAlgolia = async (
    query, functionFilters = {}, hitsPerPage = ITEMS_PER_PAGE,
// eslint-disable-next-line consistent-return
) => {
    const finalFilters = buildAlgoliaFilters(functionFilters);
    try {
        const resp = await index.search(query, {
            filters: finalFilters,
            hitsPerPage,
        });
        return resp;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export default searchFunctionsAlgolia;
