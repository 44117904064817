import styled from 'styled-components';

export const SectionTitle = styled.h2`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const UsagePlanCard = styled.div`
    margin-left: 5px;
    margin-bottom: 24px;
    border-radius: 0 0 14px 14px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0 0 16px;
    border-top: 10px solid ${(props) => props.color};
    display: flex;
    flex-direction: column;
    width: 60%;
`;

export const PlanInfoText = styled.h2`
    font-size: 24px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const TextDescription = styled.h4`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const HorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.rainbow.palette.border.disabled};
`;
export const Features = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: 8px;
`;
