import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import FunctionIcon from '../../../components/FunctionIcon';
import {
    FunctionNameContainer,
    FunctionNameContent,
    StyledFunctionName,
    FunctionWorkspace,
} from '../styled';

const FunctionName = ({ value }) => {
    const { name, workspace, iconUrl } = value;
    return (
        <FunctionNameContainer>
            <FunctionIcon url={iconUrl} functionType="marketplace" width="20px" height="20px" />
            <FunctionNameContent>
                <StyledFunctionName>{name}</StyledFunctionName>
                <RenderIf isTrue={!!workspace}>
                    <FunctionWorkspace>{workspace}</FunctionWorkspace>
                </RenderIf>
            </FunctionNameContent>
        </FunctionNameContainer>
    );
};

FunctionName.propTypes = {
    value: PropTypes.object,
};

FunctionName.defaultProps = {
    value: undefined,
};

export default FunctionName;
