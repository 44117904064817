import styled from 'styled-components';
import { UniversalPicker } from '@rainbow-modules/forms';
import { ChevronRight } from '@rainbow-modules/icons';
import Policy from '../Icons/policyIcon';

export const StyledUniversalPicker = styled(UniversalPicker)`
    > div span {
        margin: 0;
    }
`;

export const PolicyItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 6px 6px 6px 0;
    margin: 0;
    position: relative;

    :hover {
        background: ${(props) => props.theme.rainbow.palette.background.highlight};
        cursor: pointer;
    }

    ${(props) => props.isSelected && `
        font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
        background: ${props.theme.rainbow.palette.background.highlight};
    `}
`;

export const PolicyIcon = styled(Policy)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 12px;
`;

export const PolicyName = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const PolicyLabel = styled.h3`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-size: 18px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 4px;
`;

export const StyledChevronRight = styled(ChevronRight)`
    position: absolute;
    right: 8px;
    top: 11px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;
