import PropTypes from 'prop-types';

export default function PolicyIcon(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} viewBox="0 0 64 64">
            <defs>
                <linearGradient x1="36.7224809%" y1="31.773591%" x2="50%" y2="117.24606%" id="linearGradient-1">
                    <stop stopColor="#31383B" offset="0%" />
                    <stop stopColor="#0E171E" offset="100%" />
                </linearGradient>
            </defs>
            <g id="functions-icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="policy" fillRule="nonzero">
                    <path d="M52,0 C58.627417,0 64,5.372583 64,12 L64,52 C64,58.627417 58.627417,64 52,64 L12,64 C5.372583,64 0,58.627417 0,52 L0,12 C0,5.372583 5.372583,0 12,0 L52,0 Z" id="Path" fill="url(#linearGradient-1)" />
                    <path d="M31.3793958,15.7259526 C31.7869103,15.5236405 32.2652341,15.5236405 32.6727485,15.7259526 L32.6727485,15.7259526 L44.289078,21.4398254 C44.7861878,21.6934382 45.0939913,22.2107792 45.0780953,22.7685827 L45.0780953,22.7685827 L45.0780953,33.322051 C45.0780953,36.5178601 43.1265057,40.2866462 40.4841646,42.089392 L40.4841646,42.089392 L32.8779508,47.3039873 C32.3801183,47.6471954 31.7218815,47.6471954 31.224049,47.3039873 L31.224049,47.3039873 L23.5947138,42.0879468 C20.9530953,40.2866462 18.9971704,36.5178601 18.9971704,33.322051 L18.9971704,33.322051 L18.9971704,22.7526867 C18.9805519,22.1948834 19.2876328,21.6775422 19.7847426,21.4239296 L19.7847426,21.4239296 Z M32.0376329,18.7021087 L21.956708,23.6760972 L21.956708,33.322051 C21.956708,35.5510972 23.4097427,38.3856348 25.2529507,39.6435828 L25.2529507,39.6435828 L32.0202918,44.2895364 L38.814367,39.6428601 C40.657575,38.3863573 42.1185577,35.5510972 42.1185577,33.322051 L42.1185577,33.322051 L42.1185577,23.6898255 L32.0376329,18.7021087 Z M35.4263611,27.5785538 C36.0043958,27.0005191 36.9422571,27.0005191 37.5195693,27.5792763 C38.097604,28.1573109 38.097604,29.0951723 37.5188467,29.673207 L37.5188467,29.673207 L31.6322861,35.5619352 C31.3555519,35.840837 30.9791069,35.9983516 30.5860433,35.9997966 L30.5860433,35.9997966 C30.1879219,35.9976289 29.8107544,35.8379469 29.5347427,35.5561549 L29.5347427,35.5561549 L26.5549739,32.5460394 C25.9798294,31.9651145 25.9834421,31.0279758 26.5643669,30.4521086 C27.1452918,29.8762416 28.0824307,29.880577 28.6582977,30.4615018 L28.6582977,30.4615018 L30.5911012,32.4130914 Z" id="Combined-Shape" fill="#FFFFFF" />
                </g>
            </g>
        </svg>
    );
}

PolicyIcon.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

PolicyIcon.defaultProps = {
    style: {},
    className: undefined,
};
