import { useState } from 'react';
import { Button } from 'react-rainbow-components';
import { showAppNotification } from '@rainbow-modules/app';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import PropTypes from 'prop-types';
import AddPolicyModalContent from './addPolicyModalContent';
import { AddPolicyModalContainer } from './styled';

const AddPolicyModal = ({ isOpen, onCancel, onAddPolicy }) => {
    const [selectedPolicy, setSelectedPolicy] = useState('');
    const [policies, isLoading] = useCollection({
        path: 'policies',
        flat: true,
    });

    const policy = policies.find((item) => item.name === selectedPolicy);

    const handleOnAddPolicy = () => {
        if (selectedPolicy) {
            return onAddPolicy(policy);
        }
        return showAppNotification({
            title: 'Error!',
            description: 'You need to select some policy.',
            icon: 'error',
            timeout: 5000,
        });
    };

    return (
        <AddPolicyModalContainer
            id="add-policy-modal"
            title="Add Policy"
            isOpen={isOpen}
            onRequestClose={onCancel}
            size="large"
            onOpened={() => setSelectedPolicy('')}
            footer={(
                <div className="rainbow-flex rainbow-justify_end">
                    <Button
                        className="rainbow-align_end rainbow-m-right_small"
                        label="Cancel"
                        variant="base"
                        onClick={onCancel}
                    />
                    <Button label="Add" variant="brand" onClick={handleOnAddPolicy} />
                </div>
            )}
        >
            <AddPolicyModalContent
                value={selectedPolicy}
                onChange={setSelectedPolicy}
                policies={policies}
                isLoading={isLoading}
                policy={policy}
            />
        </AddPolicyModalContainer>
    );
};

AddPolicyModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onAddPolicy: PropTypes.func,
};

AddPolicyModal.defaultProps = {
    isOpen: false,
    onCancel: () => {},
    onAddPolicy: () => {},
};

export default AddPolicyModal;
