/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import ProjectPermissions from '../addProjects/projectPermissions';
import ModalFooter from '../addProjects/modalFooter';
import {
    ModalContainer,
    ModalTitle,
    StyledModal,
} from '../addProjects/styled';

export default function CheckModal({
    projectId, projectName, granted, ungranted, onRequestClose, onCheck, ...modalProps
}) {
    const { isOpen } = modalProps;
    return (
        <StyledModal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...modalProps}
            id="check-project-modal"
            size="large"
            title={<ModalTitle>Check Project Permission</ModalTitle>}
            onRequestClose={onRequestClose}
            footer={(
                <ModalFooter
                    primaryButtonLabel="Check Again"
                    secondaryButtonLabel="Close"
                    onPrimaryButtonClick={onCheck}
                    onSecondaryButtonClick={onRequestClose}
                />
            )}
        >
            <ModalContainer className="rainbow-flex rainbow-justify_end">
                <RenderIf isTrue={isOpen}>
                    <ProjectPermissions
                        projectId={projectId}
                        projectName={projectName}
                        permissions={{ granted, ungranted }}
                    />
                </RenderIf>
            </ModalContainer>
        </StyledModal>
    );
}

CheckModal.propTypes = {
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    granted: PropTypes.array,
    ungranted: PropTypes.array,
    onRequestClose: PropTypes.func,
    onCheck: PropTypes.func,
};

CheckModal.defaultProps = {
    projectId: undefined,
    projectName: undefined,
    granted: [],
    ungranted: [],
    onRequestClose: () => {},
    onCheck: () => {},
};
