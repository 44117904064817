import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    MessageContainer,
    MessageTitle,
    MessageDescription,
    StyledSpinner,
} from './styled';

export default function Loader({ hideMessage }) {
    return (
        <MessageContainer>
            <StyledSpinner size="large" type="arc" variant="brand" />
            <RenderIf isTrue={!hideMessage}>
                <MessageTitle>
                    Enabling Secret Manager API
                </MessageTitle>
                <MessageDescription>
                    This process could take ~ 1min
                </MessageDescription>
            </RenderIf>
        </MessageContainer>
    );
}

Loader.propTypes = {
    hideMessage: PropTypes.bool,
};

Loader.defaultProps = {
    hideMessage: false,
};
