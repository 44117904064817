import PropTypes from 'prop-types';
import { RainbowThemeContainer } from 'react-rainbow-components';
import 'highlight.js/styles/base16/darcula.css';
import { StyledMarkdownOutputDark } from './styled';

const theme = {
    rainbow: {
        palette: {
            mainBackground: '#f4f6f9',
        },
    },
};

const MarkdownOutputDark = (props) => {
    const {
        style,
        className,
        value,
    } = props;

    return (
        <RainbowThemeContainer theme={theme}>
            <StyledMarkdownOutputDark style={style} className={className} value={value} />
        </RainbowThemeContainer>
    );
};

export default MarkdownOutputDark;

MarkdownOutputDark.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    value: PropTypes.string,
};

MarkdownOutputDark.defaultProps = {
    style: undefined,
    className: undefined,
    value: undefined,
};
