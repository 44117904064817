/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { FirestoreTableWithCursors } from '@rainbow-modules/listview';
import { ChevronRight } from '@rainbow-modules/icons';
import Build from '../../../../../components/Icons/build';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    margin: 0 0 12px;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin: 20px 28px 0 24px;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 0 4px 0;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 0 8px;
`;

export const StyledIcon = styled(Build)`
    width: 72px;
    height: 72px;
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 12px;
`;

export const Link = styled.a`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    display: block;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
        text-decoration: underline;
    }
`;

export const StyledChevronRight = styled(ChevronRight)`
    width: 10px;
    height: 10px;
    margin-left: 2px;
`;

export const StyledTable = styled(FirestoreTableWithCursors)`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
`;
