import {
    BillingContainer,
    Line,
    ItemLabel,
    ItemDescription,
    BillingNotGranted,
    Highlight,
    StyledLink,
} from './styled';

// eslint-disable-next-line react/prop-types
export default function Billing() {
    return (
        <BillingContainer>
            <Line variant="warning" />
            <BillingNotGranted />
            <div>
                <ItemLabel data-cy="billing-label">
                    There is no
                    <Highlight>&quot;Billing Account&quot;</Highlight>
                    linked to the project
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Your GCP/Firebase project needs a Billing account linked
                    to it in order to run the functions. This is a GCP
                    requirement for you to pay for the set of resources
                    they provide you, and there is nothing we can do about it.
                </ItemDescription>
                <StyledLink
                    className="rainbow-m-top_small"
                    target="blank"
                    href="https://docs.functions.store/functions/reference/requirements/link-a-billing-account-to-your-project"
                >
                    How to link a Billing Account to your project?
                </StyledLink>
            </div>
        </BillingContainer>
    );
}
