import React from 'react';
import PropTypes from 'prop-types';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import Fields from './fields';
import useUserPrimaryCard from '../../../data/hooks/useUserPrimaryCard';
import createPaymentIntent from '../../../data/services/createPaymentIntent';
import getStripeInstance from './helpers/getStripeInstance';
import logEvent from '../../../analytics/logEvent';
import events from '../../../analytics/events';

const BuyFunctionModal = React.memo((props) => {
    const { functionData = {}, onSubmitModal, ...connectedModalProps } = props;
    const { id: functionId, workspace } = functionData;
    const { onRequestClose } = connectedModalProps;
    const { data: userPrimaryCard, isFetching: isCardLoading, refetch } = useUserPrimaryCard();
    const hasBillingInfo = Boolean(userPrimaryCard);

    const onSubmit = async (values) => {
        showAppSpinner({ message: 'Buying function' });
        onSubmitModal();
        try {
            const { clientSecret } = await createPaymentIntent(functionId, workspace);
            if (hasBillingInfo) {
                const { id: cardId } = userPrimaryCard;
                const stripe = await getStripeInstance();
                await stripe.confirmCardPayment(clientSecret, {
                    payment_method: cardId,
                });
            } else {
                const cardInformation = values['card-information'];
                const holderName = values['cardholder-name'];
                const { stripe, card } = cardInformation;
                await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card,
                        billing_details: {
                            name: holderName,
                        },
                    },
                    setup_future_usage: 'off_session',
                });
            }
            logEvent(events.functions.PURCHASED);
            showAppNotification({
                title: 'Success!',
                description: 'The function was paid successfully.',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            showAppNotification({
                title: 'Error!',
                description: error.message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
        onRequestClose();
    };

    const ModalBody = () => (
        <Fields
            functionData={functionData}
            billingInfo={userPrimaryCard && userPrimaryCard.card}
            isLoading={isCardLoading}
        />
    );

    return (
        <UniversalFormModal
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...connectedModalProps}
            fields={ModalBody}
            onSubmit={onSubmit}
            onOpened={refetch}
            disableSubmit={isCardLoading}
            id="buy-function-modal"
        />
    );
});

BuyFunctionModal.propTypes = {
    functionData: PropTypes.object,
    onRequestClose: PropTypes.func,
    onSubmitModal: PropTypes.func,
};

BuyFunctionModal.defaultProps = {
    functionData: undefined,
    onRequestClose: () => {},
    onSubmitModal: () => {},
};
export default BuyFunctionModal;
