export default function makeRequest({
    url, method, body, token,
}) {
    const headers = new Headers();

    if (token) {
        headers.append('authorization', `Bearer ${token}`);
    }

    headers.append('Content-Type', 'application/json');

    return fetch(url, {
        method,
        headers,
        body: JSON.stringify(body),
    });
}
