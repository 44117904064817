const getFirstFib = (number) => {
    let n1 = 1; let n2 = 2; let
        nextTerm;
    const fibs = [1, 2];
    for (let i = 1; i <= number; i += 1) {
        nextTerm = n1 + n2;
        fibs.push(nextTerm);
        n1 = n2;
        n2 = nextTerm;
    }
    return fibs.slice(2);
};

export default getFirstFib;
