import {
    Email, Google, FacebookCircle, Github, TwitterCircle, Microsoft, Yahoo, User,
} from '@rainbow-modules/icons';

export const providerIconMap = {
    password: Email,
    'google.com': Google,
    'facebook.com': FacebookCircle,
    'github.com': Github,
    'twitter.com': TwitterCircle,
    'microsoft.com': Microsoft,
    'yahoo.com': Yahoo,
    anonymous: User,
};

export const providerNameMap = {
    password: 'Email/Password',
    'google.com': 'Google',
    'facebook.com': 'Facebook',
    'github.com': 'Github',
    'twitter.com': 'Twitter',
    'microsoft.com': 'Microsoft',
    'yahoo.com': 'Yahoo',
    anonymous: 'Anonymous',
};

export const INSTALL_FUNCTION_DRAWER = 'install-function-drawer';
export const RECONFIG_FUNCTION_DRAWER = 'reconfig-function-drawer';
export const BUY_FUNCTION_MODAL = 'buy-function-modal';
export const ACTIVITY_DRAWER = 'activity-drawer';
export const BILLING_DRAWER = 'billing-drawer';
export const UPGRADE_ACCOUNT = 'upgrade-account';
export const UPGRADE_TOAST = 'upgrade-toast';
export const FUNCTION_DOC_PREVIEW_DRAWER = 'function-doc-preview-drawer';
export const FUNCTION_INSTALL_PREVIEW_DRAWER = 'function-install-preview-drawer';
export const PAYMENT_METHOD_MODAL = 'payment-method-modal';
export const CREATE_SECRET_DRAWER = 'create-secret-drawer';
export const ADD_SECRET_VERSION_DRAWER = 'add-secret-version-drawer';
export const SHOW_SECRET_VALUE_MODAL = 'show-secret-value-modal';
export const PUBLISH_WORKSPACE_FUNCTION_MODAL = 'publish-workspace-function-modal';
export const CHECK_PROJECT_PERMISSIONS_MODAL = 'check-project-permission-modal';
export const CHECK_PROJECTS_PERMISSIONS_MODAL = 'check-projects-permission-modal';
export const IMPORT_FUNCTIONS_MODAL = 'import-functions-modal';
export const PAYOUT_DRAWER = 'payout-drawer';

export const WORKSPACE_CI_PATH = '/workspace/unzippedFolder/';

export const memoryMap = {
    128: '128 MB',
    256: '256 MB',
    512: '512 MB',
    1024: '1 GB',
    2048: '2 GB',
    4096: '4 GB',
    8192: '8 GB',
};

export const authOptions = [
    { value: 'unauthenticated', label: 'Allow unauthenticated invocations', description: 'Check this if you are creating a public API or website.' },
    { value: 'authenticated', label: 'Require authentication', description: 'Manage authorized users with Cloud IAM.' },
];
