import {
    Amex, Visa, Mastercard, Discover, JCB, UnionPay, DinersClub, UnknownCard,
} from '@rainbow-modules/icons';

const cardMap = {
    amex: { icon: Amex, label: 'American Express' },
    visa: { icon: Visa, label: 'Visa' },
    mastercard: { icon: Mastercard, label: 'Mastercard' },
    discover: { icon: Discover, label: 'Discover' },
    jcb: { icon: JCB, label: 'JCB' },
    unionPay: { icon: UnionPay, label: 'Union Pay' },
    dinersClub: { icon: DinersClub, label: 'Diners Club' },
};

const getCreditCardType = (brand) => (
    cardMap[brand] || { icon: UnknownCard, label: brand }
);

export default getCreditCardType;
