import { useHttpAuthMutation } from '@rainbow-modules/firebase-hooks';
import useMutationFlow from './useMutationFlow';

const useProjectActions = ({ projectId }) => {
    const { mutateAsync: activateProject } = useHttpAuthMutation({
        functionName: 'projects-api',
        pathname: `${projectId}/activate`,
        method: 'POST',
        invalidateQueriesOnSuccess: ['projects-list'],
    });
    const { mutate: activate } = useMutationFlow({
        mutation: activateProject,
        successMessage: 'The project was actived sucessfully.',
    });
    const { mutateAsync: deactivateProject } = useHttpAuthMutation({
        functionName: 'projects-api',
        pathname: `${projectId}/deactivate`,
        method: 'POST',
        invalidateQueriesOnSuccess: ['projects-list'],
    });
    const { mutate: deactivate } = useMutationFlow({
        mutation: deactivateProject,
        successMessage: 'The project was archived sucessfully.',
    });
    return {
        activate,
        deactivate,
    };
};

export default useProjectActions;
