import PropTypes from 'prop-types';
import {
    ErrorMessage,
    Line,
    ErrorIcon,
    ErrorMessageDescription,
} from './styled';

const Error = ({ message }) => (
    <ErrorMessage>
        <Line />
        <ErrorIcon />
        <div>
            <ErrorMessageDescription>{message}</ErrorMessageDescription>
        </div>
    </ErrorMessage>
);

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Error;
