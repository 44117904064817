import styled from 'styled-components';
import { Google } from '@rainbow-modules/icons';
import { Button } from 'react-rainbow-components';
import Logo from '../../components/Icons/logo';
import { LIGHT_WHITE, LIGHT_BLACK } from '../../colors';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: ${LIGHT_WHITE};

    @media (max-width: 812px) {
        flex-direction: column;
    }
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    background: ${LIGHT_BLACK};
    width: 40%;
    box-sizing: border-box;
    padding: 40px 40px;

    @media (max-width: 812px) {
        width: 100%;
        background: transparent;
        height: none;
        padding-bottom: 0;
    }
`;

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: ${LIGHT_WHITE};
    flex-grow: 1;

    @media (max-width: 812px) {
        justify-content: flex-start;
        align-items: center;
    }
`;

export const Title = styled.h1`
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    letter-spacing: 1px;
    font-weight: 900;
    margin-bottom: 24px;

    @media (max-width: 812px) {
        display: none;
    }
`;

export const StyledLogo = styled(Logo)`
    width: 40px;
    height: 40px;
    margin: 0 8px 8px 0;

    @media (max-width: 812px) {
        display: none;
    }
`;

export const StyledLogoLarge = styled(Logo)`
    width: 72px;
    height: 72px;
    color: rgba(255, 255, 255, 0.9);

    @media (max-width: 812px) {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const StyledButton = styled(Button)`
    height: 56px;
    line-height: 56px;
    padding: 0 32px 0 40px;
    font-size: 18px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 320px;
    position: relative;
    font-family: 'Roboto', sans-serif;

    :hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        background: white;
    }
`;

export const GoogleIcon = styled(Google)`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    position: absolute;
    left: 16px;
`;

export const TermsConditions = styled.div`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 12px;
    max-width: 250px;
    text-align: center;
    margin-top: 48px;
`;

export const StyledLink = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const WelcomeMessage = styled.h1`
    width: 400px;
    margin-top: 50px;
    font-size: 38px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 1.32px;
    color: white;

    @media (max-width: 1024px) {
        font-size: 22px;
        width: 100%;
        margin-right: 12px;
    }

    @media (max-width: 812px) {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const SloganText = styled.h3`
    width: 500px;
    height: 75px;
    margin-top: 33px;
    opacity: 0.7;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.79px;
    color: white;

    @media (max-width: 1024px) {
        font-size: 14px;
        width: 100%;
        margin-right: 12px;
    }

    @media (max-width: 812px) {
        color: ${(props) => props.theme.rainbow.palette.text.main};
        opacity: 1;
    }
`;
