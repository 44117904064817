import PropTypes from 'prop-types';
import { StyledSpan, StyledCopyToClipboardButton } from './styled';

const CopyUidToClipboard = (props) => {
    const { value } = props;
    return (
        <>
            <StyledSpan>
                {value}
                <StyledCopyToClipboardButton variant="border-filled" size="small" value={value} shaded />
            </StyledSpan>
        </>
    );
};

CopyUidToClipboard.propTypes = {
    value: PropTypes.string.isRequired,
};

export default CopyUidToClipboard;
