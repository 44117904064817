/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { Step } from '@rainbow-modules/content';
import CloudFirestoreImg from '../images/5-cloud-firestore.png';
import FirebaseAuthenticationImg from '../images/5-firebase-authentication.png';
import CloudStorageImg from '../images/5-cloud-storage.png';
import CloudPubSubImg from '../images/5-cloud-pubsub.png';
import HttpImg from '../images/5-http.png';
import { StepDescription, StyledImage, Highlight } from '../styled';

const Https = () => (
    <>
        <StepDescription>
            In the
            <Highlight>Authentication</Highlight>
            field, select your desired authentication.
            If you want to create a public API, select the option
            <Highlight>
                Allow unauthenticated
                invocations
            </Highlight>
            . Otherwise, use the default settings to require authentication
            for your function. Click Save to save your changes.
        </StepDescription>
        <StyledImage src={HttpImg} />
    </>
);
const CloudPubSub = ({ trigger }) => {
    const { topic } = trigger;
    return (
        <>
            <StepDescription>
                In the
                <Highlight>Select a Cloud Pub/Sub topic</Highlight>
                field, select or create a topic.
                {' '}
                <RenderIf isTrue={topic}>
                    This function is configured to use the topic
                    <Highlight>
                        {topic}
                    </Highlight>
                    by default, but you can use another with a different name.
                    {' '}
                </RenderIf>
                Every message published to the selected topic will trigger function execution
                with message contents passed as input data.
                Click Save to save your changes.
            </StepDescription>
            <StyledImage src={CloudPubSubImg} />
        </>
    );
};
const CloudFirestore = ({ trigger }) => {
    const { event } = trigger;
    const newEvent = event.split('.')[1];
    return (
        <>
            <StepDescription>
                In the
                <Highlight>Event type</Highlight>
                field, select the type
                <Highlight>
                    {newEvent}
                    .
                </Highlight>
                In the
                <Highlight>Document Path</Highlight>
                field, write the document path that you would
                like this function to listen to. Click Save to save your changes.
            </StepDescription>
            <StyledImage src={CloudFirestoreImg} />
        </>
    );
};

const FirebaseAuthentication = ({ trigger }) => {
    const { event } = trigger;
    const newEvent = event.split('.')[1];
    return (
        <>
            <StepDescription>
                In the
                <Highlight>Event type</Highlight>
                field, select the type
                <Highlight>
                    {newEvent}
                    .
                </Highlight>
                Click Save to save your changes.
            </StepDescription>
            <StyledImage src={FirebaseAuthenticationImg} />
        </>
    );
};

const storageEventsMap = {
    finalize: 'Finalize/Create',
    metadataUpdate: 'Metadata Update',
    archive: 'Archive',
    delete: 'Delete',
};

const CloudStorage = ({ trigger }) => {
    const { event } = trigger;
    const newEvent = storageEventsMap[event.split('.')[1]];
    return (
        <>
            <StepDescription>
                In the
                <Highlight>Event type</Highlight>
                field, select the type
                <Highlight>
                    {newEvent}
                    .
                </Highlight>
                In the
                <Highlight>Bucket</Highlight>
                field, write the name of the bucket that you would
                like this function to listen to for changes to the object.
                Click Save to save your changes.
            </StepDescription>
            <StyledImage src={CloudStorageImg} />
        </>
    );
};

const triggersTypeMap = {
    firestore: 'Cloud Firestore',
    auth: 'Firebase Authentication',
    storage: 'Cloud Storage',
    pubsub: 'Cloud Pub/Sub',
    https: 'HTTP',
};

const triggersComponentsMap = {
    firestore: CloudFirestore,
    auth: FirebaseAuthentication,
    storage: CloudStorage,
    pubsub: CloudPubSub,
    https: Https,
};

const SelectTrigger = ({ functionData, stepNumber }) => {
    const { trigger } = functionData;
    const { provider } = trigger;
    const triggerType = triggersTypeMap[provider];
    const Trigger = triggersComponentsMap[provider];
    return (
        <Step
            number={stepNumber}
            header={`In the "Trigger" field, select the trigger type ${triggerType}`}
            className="rainbow-m-top_xx-large"
        >
            <Trigger trigger={trigger} />
        </Step>
    );
};

SelectTrigger.propTypes = {
    stepNumber: PropTypes.number,
    functionData: PropTypes.object,
};

SelectTrigger.defaultProps = {
    stepNumber: undefined,
    functionData: undefined,
};

export default SelectTrigger;
