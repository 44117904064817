import PropTypes from 'prop-types';
import { ProgressBar, RenderIf } from 'react-rainbow-components';
import {
    UsageContainer,
    UsageHeader,
    UsageHeaderTitle,
    UsageIcon,
    UsageCounter,
    Bold,
} from './styled';

const FeatureUsage = (props) => {
    const {
        feature, summaryCounter, counter, className, showFeatureIcon, limit, count,
    } = props;
    const usagePercent = (count * 100) / limit;

    return (
        <UsageContainer className={className}>
            <UsageHeader>
                <UsageHeaderTitle>
                    <RenderIf isTrue={showFeatureIcon}>
                        <UsageIcon />
                    </RenderIf>
                    {feature}
                </UsageHeaderTitle>
                <UsageCounter>
                    <Bold>{summaryCounter}</Bold>
                    used
                </UsageCounter>
            </UsageHeader>
            <ProgressBar value={usagePercent} size="small" className="rainbow-m-bottom_xx-small" />
            <UsageCounter className="rainbow-m-bottom_small">
                {counter}
            </UsageCounter>
        </UsageContainer>

    );
};

FeatureUsage.propTypes = {
    feature: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    counter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    summaryCounter: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    showFeatureIcon: PropTypes.bool,
    limit: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
};

FeatureUsage.defaultProps = {
    feature: undefined,
    summaryCounter: undefined,
    counter: undefined,
    className: null,
    showFeatureIcon: false,
};

export default FeatureUsage;
