import { Picklist, Option } from 'react-rainbow-components';
import { useReduxForm } from 'react-rainbow-components/libs/hooks';

const SelectInput = (props) => {
    const {
        value: { value }, onChange, label, className, labelAlignment, options, required, error,
    } = useReduxForm(props);

    const newValue = {
        label: value,
        name: value,
    };

    const handleChange = ({ name }) => onChange({ value: name, type: 'select' });

    return (
        <Picklist
            required={required}
            error={error}
            onChange={handleChange}
            value={newValue}
            label={label}
            labelAlignment={labelAlignment}
            className={className}
        >
            {options?.map((item) => <Option key={item} name={item} label={item} />)}
        </Picklist>
    );
};

export default SelectInput;
