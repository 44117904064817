import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import Step6 from '../images/6-add-variable.png';
import {
    StepDescription, StyledImage, Line, EnvContainer, Highlight, StyledAnchor,
    ItemContainer, ItemLabel, StyledCopyToClipboardButton, ItemDescription,
} from '../styled';

const AddEnvVariables = ({ functionData, stepNumber }) => {
    const { env = [] } = functionData;
    return (
        <Step
            number={stepNumber}
            header='Open the "Runtime, build, connections and security settings" to add environment variables.'
            className="rainbow-m-top_xx-large"
        >
            <StepDescription>
                In the
                <Highlight>Runtime environment variables</Highlight>
                , click the
                <Highlight>Add variable</Highlight>
                button. You must add the environment variables of the function.
            </StepDescription>
            <StyledImage src={Step6} />
            <StepDescription className="rainbow-m-top_large">
                The other environment variables are listed below and
                you can copy and paste the name.
            </StepDescription>
            <EnvContainer>
                <ItemContainer>
                    <div>
                        <Line />
                        <ItemLabel data-id="GCLOUD_PROJECT" data-cy="trigger-env-label">GCLOUD_PROJECT</ItemLabel>
                        <ItemDescription>
                            Its value must be the current GCP project ID.
                            Note that the project ID is not the name of the project.
                            If you need more info about how to obtain the project ID click
                            {' '}
                            <StyledAnchor
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://support.google.com/googleapi/answer/7014113?hl=en&ref_topic=7014522"
                            >
                                here

                            </StyledAnchor>
                            .
                        </ItemDescription>
                    </div>
                    <StyledCopyToClipboardButton
                        className="rainbow-m-left_large"
                        value="GCLOUD_PROJECT"
                    />
                </ItemContainer>
                {env.map(({ name, description }) => (
                    <ItemContainer key={name}>
                        <div>
                            <Line />
                            <ItemLabel data-id={name} data-cy="trigger-env-label">{name}</ItemLabel>
                            <ItemDescription>
                                {description}
                            </ItemDescription>
                        </div>
                        <StyledCopyToClipboardButton
                            className="rainbow-m-left_large"
                            value={name}
                        />
                    </ItemContainer>
                ))}
            </EnvContainer>
        </Step>
    );
};

AddEnvVariables.propTypes = {
    stepNumber: PropTypes.number,
    functionData: PropTypes.object,
};

AddEnvVariables.defaultProps = {
    stepNumber: undefined,
    functionData: {},
};

export default AddEnvVariables;
