import PropTypes from 'prop-types';
import {
    Button, LoadingShape, RenderIf,
} from 'react-rainbow-components';
import { useParams, useHistory } from 'react-router-dom';
import { useHttpAuthMutation, useHttpAuthQuery } from '@rainbow-modules/firebase-hooks';
import { showAppMessage } from '@rainbow-modules/app';
import FunctionCompareItem from './functionCompareItem';
import {
    ModalHeader,
    Bold,
    ModalDescription,
    CompareContainer,
    EvironmentItem,
    ArrowNextIcon,
    ItemIcon,
    ItemLabel,
    ItemValue,
    ModalFooter,
    ChangesTitle,
    ChangesHeader,
    StyledBadge,
    BadgeFunctionIcon,
    ChangesContent,
    RowContainer,
    LoadingImage,
    LoadingInfoContainer,
    StyledMessage,
    ModalContent,
    ModalContainer,
} from './styled';

const Changes = ({ data, targetProjectName }) => data.map((item) => {
    const {
        name, version, buildId, previous, trigger, env, runtimeConfig,
    } = item;
    if (previous) {
        const { version: prevVersion, buildId: prevBuildId } = previous;
        return (
            <FunctionCompareItem
                functionName={name}
                functionFromVersion={prevVersion}
                functionToVersion={version}
                functionFromBuild={prevBuildId}
                functionToBuild={buildId}
                trigger={trigger}
                env={env}
                runtimeConfig={runtimeConfig}
                targetProjectName={targetProjectName}
            />
        );
    }
    return (
        <FunctionCompareItem
            functionName={name}
            functionFromVersion="Not installed"
            functionToVersion={version}
            functionToBuild={buildId}
            trigger={trigger}
            env={env}
            runtimeConfig={runtimeConfig}
            targetProjectName={targetProjectName}
        />
    );
});

export default function ConfirmDeployment(props) {
    const {
        baseProjectName, targetProjectName, environmentIndex, onRequestClose,
    } = props;
    const { workspace, deploymentId } = useParams();
    const { data, isLoading } = useHttpAuthQuery({
        functionName: 'workspaces-api',
        pathname: `/${workspace}/deployments/${deploymentId}/deploy/${environmentIndex}`,
    });
    const { mutate: deploy, isLoading: isLoadingDeploy } = useHttpAuthMutation({
        functionName: 'workspaces-api',
        pathname: `/${workspace}/deployments/${deploymentId}/deploy/${environmentIndex}`,
        method: 'POST',
    });
    const hasData = data?.length > 0;
    const deployHistoryPath = `/app/developer/workspaces/${workspace}/deployments/history`;
    const { push: navigate } = useHistory();

    const deployChanges = () => {
        deploy(undefined, {
            onSuccess: () => {
                onRequestClose();
                navigate(deployHistoryPath);
            },
            onError: (error) => showAppMessage({
                message: error.message,
                variant: 'error',
            }),
        });
    };

    return (
        <ModalContainer>
            <ModalHeader>
                Deploy to
                <Bold>{targetProjectName}</Bold>
            </ModalHeader>
            <ModalDescription>
                Here you have all changes made in the
                {' '}
                {baseProjectName}
                {' '}
                environment. These changes will be mirrored in the
                {' '}
                {targetProjectName}
                {' '}
                environment when you click on the Deploy button.
            </ModalDescription>
            <ModalContent>
                <CompareContainer>
                    <EvironmentItem>
                        <ItemIcon />
                        <ItemLabel>
                            From:
                        </ItemLabel>
                        <ItemValue>
                            {baseProjectName}
                        </ItemValue>
                    </EvironmentItem>
                    <ArrowNextIcon />
                    <EvironmentItem>
                        <ItemIcon />
                        <ItemLabel>
                            To:
                        </ItemLabel>
                        <ItemValue>
                            {targetProjectName}
                        </ItemValue>
                    </EvironmentItem>
                </CompareContainer>
                <ChangesHeader>
                    <RenderIf isTrue={hasData || isLoading}>
                        <ChangesTitle>
                            Compare and Review changes
                        </ChangesTitle>
                    </RenderIf>
                    <RenderIf isTrue={hasData}>
                        <StyledBadge size="small" variant="success">
                            <BadgeFunctionIcon />
                            {data?.length === 1 ? '1 change' : `${data?.length} changes`}
                        </StyledBadge>
                    </RenderIf>
                </ChangesHeader>
                <RenderIf isTrue={!isLoading}>
                    <RenderIf isTrue={hasData}>
                        <ChangesContent>
                            <Changes data={data} targetProjectName={targetProjectName} />
                        </ChangesContent>
                    </RenderIf>
                    <RenderIf isTrue={!hasData}>
                        <StyledMessage
                            title="There isn’t anything to compare."
                            description="We did not find any changes in the functions of your deployment at this time."
                        />
                    </RenderIf>
                </RenderIf>
                <RenderIf isTrue={isLoading}>
                    <RowContainer>
                        <LoadingImage>
                            <LoadingShape />
                        </LoadingImage>
                        <LoadingInfoContainer>
                            <LoadingShape />
                        </LoadingInfoContainer>
                    </RowContainer>
                    <RowContainer>
                        <LoadingImage>
                            <LoadingShape />
                        </LoadingImage>
                        <LoadingInfoContainer size="large">
                            <LoadingShape />
                        </LoadingInfoContainer>
                    </RowContainer>
                </RenderIf>
            </ModalContent>
            <ModalFooter>
                <Button label="Cancel" variant="base" className="rainbow-m-right_large" onClick={onRequestClose} />
                <Button label="Deploy" variant="brand" onClick={deployChanges} isLoading={isLoadingDeploy} disabled={isLoading || !hasData} />
            </ModalFooter>
        </ModalContainer>
    );
}

ConfirmDeployment.propTypes = {
    baseProjectName: PropTypes.string.isRequired,
    targetProjectName: PropTypes.string.isRequired,
    environmentIndex: PropTypes.number.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};
