import PropTypes from 'prop-types';
import {
    FAILED, IN_PROGRESS, QUEUED, DONE, SUCCEEDED, SKIPPED, NOT_RUN,
} from '../status';
import {
    StatusIconContainer,
    StyledSpinner,
    SuccessIcon,
    SkipIcon,
    QueuedIcon,
    FailedIcon,
    MinusIcon,
} from './styled';

const Spinner = () => <StyledSpinner size="xx-small" type="arc" variant="brand" />;

const statusIconMap = {
    [DONE]: {
        [SUCCEEDED]: SuccessIcon,
        [FAILED]: FailedIcon,
        [SKIPPED]: SkipIcon,
        [NOT_RUN]: MinusIcon,
    },
    [IN_PROGRESS]: Spinner,
    [QUEUED]: QueuedIcon,
};

export default function StatusIcon(props) {
    const {
        status, conclusion, size, style, className,
    } = props;
    const mapResult = statusIconMap[status];
    const Icon = status === DONE ? mapResult[conclusion] : mapResult;

    return (
        <StatusIconContainer size={size} className={className} style={style}>
            <Icon size={size} />
        </StatusIconContainer>
    );
}

StatusIcon.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large']),
    status: PropTypes.oneOf(['done', 'queued', 'in_progress']),
    conclusion: PropTypes.oneOf(['succeeded', 'failed', 'skipped']),
};

StatusIcon.defaultProps = {
    style: {},
    className: undefined,
    status: undefined,
    conclusion: undefined,
    size: 'medium',
};
