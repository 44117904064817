import { useParams } from 'react-router-dom';
import { RecordHeader } from '@rainbow-modules/record';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import Permissions from './permissions';
import DescriptionInfo from '../descriptionInfo';
import {
    Container, Content, Description, StyledRolesFilled,
} from './styled';

export default function RoleDetails() {
    const { projectId, roleId } = useParams();
    const [role, isLoading] = useDoc({ path: `/projects/${projectId}/roles/${roleId}` });
    const roleName = role && role.data.name;
    const roleDescription = role && role.data.description;

    return (
        <Container>
            <Content>
                <RecordHeader
                    className="rainbow-m-bottom_large"
                    label="ROLE"
                    description={<DescriptionInfo description={roleName} projectId={projectId} />}
                    isLoading={isLoading}
                    icon={<StyledRolesFilled />}
                >
                    <Description>{roleDescription}</Description>
                </RecordHeader>
                <Permissions roleName={roleName} />
            </Content>
        </Container>
    );
}
