import PropTypes from 'prop-types';

export default function ReRun(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="20px" height="23px" viewBox="0 0 20 23">
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="deployments/history" transform="translate(-1342.000000, -253.000000)" fill="currentColor">
                    <path d="M1360.38959,265.722991 C1361.1258,265.723401 1361.59162,266.015509 1361.57973,266.888554 C1361.54573,269.340948 1361.54163,271.79457 1361.58178,274.246964 C1361.59612,275.116732 1361.1471,275.423178 1360.41376,275.435879 C1359.63699,275.449398 1359.1298,275.161388 1359.17446,274.22484 C1359.23263,273.001102 1359.18879,271.772857 1359.18879,270.54625 C1359.18879,269.319644 1359.21829,268.092218 1359.17896,266.867251 C1359.1511,265.982734 1359.66526,265.722582 1360.38959,265.722991 Z M1358.92987,256.477395 C1359.46329,255.823533 1359.90247,255.155741 1360.47071,254.626014 C1361.02584,254.108168 1361.54163,254.337594 1361.5527,255.083226 C1361.58547,257.327498 1361.56458,259.572589 1361.56458,261.937719 C1359.20313,261.937719 1356.92895,261.94878 1354.65519,261.920102 C1354.43354,261.917234 1354.05417,261.691086 1354.03205,261.52926 C1353.99723,261.27853 1354.13652,260.923741 1354.32498,260.735284 C1355.21195,259.849128 1356.14071,259.004761 1357.05186,258.148103 C1354.64208,255.714146 1351.19455,255.128292 1348.52461,256.559333 C1345.42491,258.220208 1343.8681,261.369891 1344.52032,264.660097 C1345.15411,267.858532 1347.83552,270.253979 1351.12122,270.587465 C1351.72346,270.648509 1352.35356,270.717746 1352.89885,270.951268 C1353.20243,271.081549 1353.54657,271.569488 1353.52813,271.875525 C1353.50888,272.187707 1353.12295,272.59166 1352.79848,272.743244 C1352.38715,272.935798 1351.8734,272.988647 1351.408,272.973899 C1346.37211,272.816578 1342.2867,268.763943 1342.01385,263.68299 C1341.74755,258.720847 1345.35076,254.32858 1350.2621,253.562873 C1353.50109,253.058137 1356.23248,254.070067 1358.92987,256.477395 Z" id="Combined-Shape" />
                </g>
            </g>
        </svg>
    );
}

ReRun.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

ReRun.defaultProps = {
    style: {},
    className: undefined,
};
