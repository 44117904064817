import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { RecordSection, RecordDetails, RecordField } from '@rainbow-modules/record';
import iconMap from '../../../components/FunctionInstallForm/iconMap';
import { TypeContainer, StyledType, IconContainer } from './styled';

// eslint-disable-next-line react/prop-types
const TriggerProvider = ({ value }) => {
    const Icon = iconMap[value] || iconMap.unknown;

    return (
        <TypeContainer>
            <IconContainer>
                <Icon />
            </IconContainer>
            <StyledType>
                {value}
            </StyledType>
        </TypeContainer>
    );
};

const labelsMap = {
    firestore: 'Document Path',
    auth: 'Project',
    https: 'Endpoint Url',
    pubsub: 'Topic',
};

export default function Trigger(props) {
    const {
        provider, event, resource,
    } = props;

    const label = labelsMap[provider] || 'Resource';

    return (
        <RecordSection label="Trigger">
            <RecordDetails>
                <RecordField label="Trigger Type" value={provider} component={TriggerProvider} />
                <RecordField label="Event" value={event} />
                <RenderIf isTrue={!!resource}>
                    <RecordField label={label} value={resource} />
                </RenderIf>
            </RecordDetails>
        </RecordSection>
    );
}

Trigger.propTypes = {
    provider: PropTypes.string,
    event: PropTypes.string,
    resource: PropTypes.string,
};

Trigger.defaultProps = {
    provider: undefined,
    event: undefined,
    resource: undefined,
};
