import { isEmail } from '@rainbow-modules/validation';
import isValidAccordingToSchema from '../../helpers/isValidAccordingToSchema';
import tryParseJson from '../../helpers/tryParseJson';
import validateFirestorePath from './helpers/validateFirestorePath';
import isUrl from './helpers/isUrl';

const validations = {
    hasValidSchema: (value, _allValues, _fieldState, keywordSchema) => {
        if (value) {
            const parsedJson = typeof value === 'string' ? tryParseJson(value) : value;
            if (parsedJson && isValidAccordingToSchema(parsedJson, keywordSchema)) {
                return undefined;
            }
        }
        return 'The json provided does not correspond to the required schema';
    },
    isValidCollectionPath: (params) => {
        if (params?.type !== 'secret') {
            return validateFirestorePath(params?.value, 'collection');
        }
        return undefined;
    },
    isValidDocumentPath: (params) => {
        if (params?.type !== 'secret') {
            return validateFirestorePath(params?.value, 'document');
        }
        return undefined;
    },
    isValidEmail: (params) => {
        if (!isEmail(params)) {
            return 'Please enter a valid email address';
        }
        return undefined;
    },
    isValidUrl: (params) => {
        if (!isUrl(params)) {
            return 'Please enter a valid url';
        }
        return undefined;
    },
};

export default validations;
