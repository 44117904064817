import useCallableQuery from './useCallableQuery';

const useCurrentUserPayments = ({ gte, lte }) => {
    const { data: response, isLoading } = useCallableQuery(
        'rainbow_listUserPayments',
        {
            gte,
            lte,
        },
    );
    const payments = response?.data || [];
    return { payments, isLoading };
};

export default useCurrentUserPayments;
