import { useState, useEffect } from 'react';
import { useHttpAuthMutation } from '@rainbow-modules/firebase-hooks';
import { isEmpty } from '@rainbow-modules/validation';
import searchFunctionsAlgolia from '../services/searchFunctionsAlgolia';

const mergeArrayValues = (arrayA, arrayB) => {
    if (isEmpty(arrayB)) return arrayA;
    return arrayA.map((value, index) => {
        const { result, reason } = arrayB[index] || { result: true };
        return {
            ...value,
            canImport: result,
            reason,
        };
    });
};

const useWorkpaceFunctionImportSearch = ({ workspace, importedFunctions, searchQuery }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [searchedData, setSearchedData] = useState();
    const { mutateAsync: checkFunctions } = useHttpAuthMutation({
        functionName: 'workspaces-api',
        pathname: `/${workspace}/canImport`,
        method: 'POST',
        invalidateQueriesOnSuccess: ['workspace', searchQuery],
    });

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const resp = await searchFunctionsAlgolia(searchQuery, {}, 20);
            const results = resp.hits.map(
                ({ name, workspace: functionWorkspace, iconUrl }) => {
                    const wasImported = Array.isArray(importedFunctions) && importedFunctions.some(
                        (func) => func.name === name && func.workspace === functionWorkspace,
                    );
                    return {
                        id: `${functionWorkspace}:${name}`,
                        name,
                        workspace: functionWorkspace,
                        iconUrl,
                        wasImported,
                    };
                },
            ).sort((item1, item2) => item1.name >= item2.name);

            let checkResults = [];
            if (results.length > 0) {
                checkResults = await checkFunctions({
                    from: 'marketplace',
                    functions: results.map(
                        ({ name, workspace: functionWorkspace }) => ({
                            name, workspace: functionWorkspace,
                        }),
                    ),
                });
            }

            setSearchedData(mergeArrayValues(results, checkResults));
            setIsLoading(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    return {
        data: searchedData,
        isLoading,
    };
};

export default useWorkpaceFunctionImportSearch;
