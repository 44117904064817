import { useCallback } from 'react';
import { useOpenModal } from '@rainbow-modules/hooks';
import useCurrentUserSubscription from './useCurrentUserSubscription';
import { UPGRADE_ACCOUNT } from '../../constants';

const useChangePlan = () => {
    const [userSubscription, isLoadingSubscription] = useCurrentUserSubscription();
    const [openUpgradeAccountDrawer] = useOpenModal(UPGRADE_ACCOUNT);

    const hasSubscription = Boolean(userSubscription && userSubscription.subscriptionStatus && userSubscription.subscriptionStatus !== 'canceled');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changePlan = useCallback(() => openUpgradeAccountDrawer(), []);

    return {
        isLoading: isLoadingSubscription,
        hasSubscription,
        changePlan,
    };
};

export default useChangePlan;
