const getUserProviders = (providerData, isAnonymous) => {
    if (isAnonymous) {
        return 'anonymous';
    }
    if (Array.isArray(providerData)) {
        return providerData.map((item) => item.providerId || '').join(',');
    }
    return '';
};

export default getUserProviders;
