import PropTypes from 'prop-types';
import { RenderIf, ButtonIcon } from 'react-rainbow-components';
import { TrashFilled } from '@rainbow-modules/icons';
import {
    EnvironmentCard,
    EnvironmentContainer,
    EnvironmentLeftContent,
    EnvironmentRightContent,
    EnvironmentName,
    EnvironmentProject,
    StepFlowIcon,
    DisconnectedPlugIcon,
    CheckCircleFilledIcon,
    Notification,
    ButtonsContainer,
    Icon,
    FunctionsCounter,
    FunctionIcon,
} from './styled';

const Environment = (props) => {
    const {
        isSelected,
        name,
        projectId,
        isBase,
        buildSinceLastDeployment,
        onClick,
        onEdit,
        onRemove,
        addedFunctions,
        disabled,
    } = props;
    const isActive = !!projectId;
    return (
        <EnvironmentContainer>
            <EnvironmentCard isSelected={isSelected} onClick={onClick}>
                <EnvironmentLeftContent>
                    <StepFlowIcon />
                    <div>
                        <EnvironmentName>
                            {name}
                        </EnvironmentName>
                        <RenderIf isTrue={projectId}>
                            <EnvironmentProject>
                                {projectId}
                            </EnvironmentProject>
                        </RenderIf>
                        <RenderIf isTrue={!projectId}>
                            <EnvironmentProject>
                                No project selected
                            </EnvironmentProject>
                        </RenderIf>
                    </div>
                </EnvironmentLeftContent>
                <EnvironmentRightContent>
                    <RenderIf isTrue={isActive}>
                        <CheckCircleFilledIcon />
                    </RenderIf>
                    <RenderIf isTrue={!isActive}>
                        <DisconnectedPlugIcon />
                    </RenderIf>
                </EnvironmentRightContent>
                <RenderIf isTrue={buildSinceLastDeployment > 0}>
                    <Notification>
                        {buildSinceLastDeployment}
                    </Notification>
                </RenderIf>
            </EnvironmentCard>
            <RenderIf isTrue={isBase}>
                <FunctionsCounter>
                    <FunctionIcon />
                    {addedFunctions}

                </FunctionsCounter>
            </RenderIf>
            <RenderIf isTrue={isSelected && !isBase && !disabled}>
                <ButtonsContainer>
                    <ButtonIcon
                        variant="border-filled"
                        size="small"
                        icon={<Icon />}
                        className="rainbow-m-right_xx-small"
                        onClick={onEdit}
                    />
                    <ButtonIcon
                        variant="border-filled"
                        size="small"
                        icon={<Icon as={TrashFilled} />}
                        onClick={onRemove}
                    />
                </ButtonsContainer>
            </RenderIf>
        </EnvironmentContainer>
    );
};

Environment.propTypes = {
    name: PropTypes.string.isRequired,
    projectId: PropTypes.string,
    isSelected: PropTypes.bool,
    isBase: PropTypes.bool,
    buildSinceLastDeployment: PropTypes.number,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    addedFunctions: PropTypes.number,
    disabled: PropTypes.bool,
};

Environment.defaultProps = {
    isSelected: false,
    isBase: false,
    projectId: null,
    buildSinceLastDeployment: null,
    onClick: () => {},
    onEdit: () => {},
    onRemove: () => {},
    addedFunctions: 0,
    disabled: false,
};

export default Environment;
