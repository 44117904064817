import styled from 'styled-components';
import { RolesFilled } from '@rainbow-modules/icons';
import ButtonIconTooltip from '../../../components/ButtonIconTooltip';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 12px;
`;

export const StyledRolesFilled = styled(RolesFilled)`
    width: 20px;
    height: 20px;
`;

export const Content = styled.div`
    margin: 0 16px;
`;

export const Description = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-left: 24px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

export const InfoContainer = styled.h3`
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 0 32px 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    display: flex;
    justify-content: center;
    text-align: center;
`;

export const Info = styled.span`
    margin: 0 4px;
`;

export const Highlight = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const StyledButtonIconTooltip = styled(ButtonIconTooltip)`
    > svg {
        width: 21px !important;
        height: 21px !important;
    }
`;
