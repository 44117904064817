import { useQueryClient } from 'react-query';
import useCallableQuery from './useCallableQuery';
import firebaseCallable from '../services/firebaseCallable';

const twentyFourHoursInMs = 1000 * 60 * 60;

export default function useSourceCodeFolderTree({
    functionId, path, workspace, buildId, ...rest
}) {
    const queryClient = useQueryClient();
    const {
        data, isLoading,
    } = useCallableQuery(
        ['functions-listSources', functionId, workspace, buildId, path],
        {
            functionId, workspace, buildId, path,
        },
        {
            ...rest,
            retry: 0,
            staleTime: twentyFourHoursInMs,
        },
    );

    const getFolderContents = (folderPath) => queryClient.fetchQuery(
        ['functions-listSources', functionId, workspace, buildId, folderPath],
        () => firebaseCallable('functions-listSources', {
            functionId,
            workspace,
            buildId,
            path: folderPath,
        }),
        { staleTime: twentyFourHoursInMs },
    );

    const getFile = (filePath) => queryClient.fetchQuery(
        ['functions-getSourceFiles', functionId, workspace, buildId, filePath],
        () => firebaseCallable('functions-getSourceFiles', {
            functionId,
            workspace,
            buildId,
            path: filePath,
        }),
        { staleTime: twentyFourHoursInMs },
    );

    return {
        data,
        isLoading,
        getFile,
        getFolderContents,
    };
}
