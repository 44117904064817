import { useCallback, useState } from 'react';
import { showAppMessage, hideAppMessage } from '@rainbow-modules/app';
import { useQueryClient } from 'react-query';
import useCallableMutation from './useCallableMutation';

const useSecretsApiStateChange = ({ projectId }) => {
    const [isEnablingApi, setIsEnablingApi] = useState(false);
    const queryClient = useQueryClient();
    const { mutateAsync: enableApiFunction } = useCallableMutation('secrets-enableApi');

    const enableApi = useCallback(async () => {
        try {
            hideAppMessage();
            setIsEnablingApi(true);
            await enableApiFunction({ projectId });
            await queryClient.invalidateQueries(
                ['secrets-isApiEnabled', projectId],
                { refetchInactive: false },
            );
        } catch (error) {
            showAppMessage({
                message: error.toString(),
                variant: 'error',
                timeout: null,
            });
        }
        setIsEnablingApi(false);
    }, [enableApiFunction, projectId, queryClient]);

    return {
        isLoading: isEnablingApi,
        enableApi,
    };
};

export default useSecretsApiStateChange;
