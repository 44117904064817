/* eslint-disable react/jsx-one-expression-per-line */
import { MagnifyingGlass } from '@rainbow-modules/icons';
import { OptionLabel, OptionTyping } from '../styled';

export default function getSearchForOption(searchValue) {
    return {
        label: (
            <OptionLabel>
                <span>Use: </span>
                <OptionTyping>{searchValue}</OptionTyping>
            </OptionLabel>
        ),
        data: searchValue,
        icon: <MagnifyingGlass />,
    };
}
