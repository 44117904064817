import PropTypes from 'prop-types';
import getCreditCardType from '../../../helpers/getCreditCardType';
import { Icon } from '../styled';

const PaymentMethod = (props) => {
    const { info } = props;
    const { brand, last4 } = info.card || { brand: '', last4: '' };
    const { icon, label } = getCreditCardType(brand);
    return (
        <>
            <span>
                <Icon as={icon} />
                {' '}
                {label}
                {' '}
                ending in
                {' '}
                {last4}
            </span>
        </>
    );
};

PaymentMethod.propTypes = {
    info: PropTypes.object,
};

PaymentMethod.defaultProps = {
    info: undefined,
};
export default PaymentMethod;
