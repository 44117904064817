import styled from 'styled-components';

export const ModalTitle = styled.h1`
    display: flex;
    align-items: flex-start;
    padding: 16px 0 8px 26px;
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    width: calc(100% + 32px);
    margin-top: 32px;
    padding: 12px 16px 0;
    margin-left: -16px;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
`;
