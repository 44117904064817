import { useState } from 'react';
import {
    Button,
    RenderIf,
    LoadingShape,
    // Column,
    // ButtonIcon,
} from 'react-rainbow-components';
// import { TrashFilled } from '@rainbow-modules/icons';
import { useOpenModal } from '@rainbow-modules/hooks';
import { showAppNotification } from '@rainbow-modules/app';
import createStripeSetupIntent from '../../data/services/createStripeSetupIntent';
import useUserPrimaryCard from '../../data/hooks/useUserPrimaryCard';
import { PAYMENT_METHOD_MODAL } from '../../constants';
import Payment from './payment';
// import Contact from './payment/contact';
import {
    SectionTitleContainerPaymentMethod,
    SectionTitle,
    PaymentContainer,
    PaymentHeaderLoading,
    PaymentInfoLoading,
    Information,
    HelpTextInformation,
    // TextDescription,
    // StyledTable,
} from './styled';

const clientSecretResolver = async () => {
    const { clientSecret } = await createStripeSetupIntent();
    return clientSecret;
};

// const data = [
//     { id: '123qwe', contact: { name: 'John Smith', email: 'josmith@gmail.com' } },
//     { id: '456asd', contact: { name: 'Jane Smith', email: 'jasmith@gmail.com' } },
// ];

// const ButtonAction = () => (
//     <ButtonIcon
//         variant="base"
//         icon={<TrashFilled />}
//         size="large"
//         className="rainbow-m-top_xx-small"
//     />
// );

const PaymentMethod = () => {
    const [openPaymentModal, closePaymentModal] = useOpenModal(PAYMENT_METHOD_MODAL);
    const { data: userPrimaryCard, isFetching: isCardFetching, refetch } = useUserPrimaryCard();
    const [isSubmitLoadingPayment, setIsSubmitLoadingPayment] = useState(false);
    const hasBillingInfo = Boolean(userPrimaryCard);
    const notificationPaymentText = hasBillingInfo ? 'updated' : 'added';
    const onSuccessSetupIntent = () => {
        setIsSubmitLoadingPayment(true);
        closePaymentModal();
        setTimeout(() => {
            showAppNotification({
                title: 'Success!',
                description: `The card was ${notificationPaymentText} successfully.`,
                icon: 'success',
                timeout: 5000,
            });
            refetch();
            setIsSubmitLoadingPayment(false);
        }, 5000);
    };
    const isCardLoading = isSubmitLoadingPayment || isCardFetching;
    const labelPaymentButton = hasBillingInfo ? 'Update Payment Method' : 'Add Payment Method';
    return (
        <>
            <SectionTitleContainerPaymentMethod>
                <SectionTitle>Payment Card</SectionTitle>
                <Button
                    variant="base"
                    label={labelPaymentButton}
                    size="small"
                    onClick={() => openPaymentModal({
                        variant: 'setupIntent',
                        clientSecretResolver,
                        onSuccess: onSuccessSetupIntent,
                        apiKey: process.env.REACT_APP_STRIPE_API_KEY,
                    })}
                    isLoading={isCardLoading}
                />
            </SectionTitleContainerPaymentMethod>
            <PaymentContainer>
                <RenderIf isTrue={isCardLoading}>
                    <PaymentHeaderLoading>
                        <LoadingShape />
                    </PaymentHeaderLoading>
                    <PaymentInfoLoading>
                        <LoadingShape />
                    </PaymentInfoLoading>
                </RenderIf>
                <RenderIf isTrue={!isCardLoading && hasBillingInfo}>
                    <Information>Payment Method</Information>
                    <Payment info={userPrimaryCard} />
                </RenderIf>
                <RenderIf isTrue={!isCardLoading && !hasBillingInfo}>
                    <HelpTextInformation>
                        There is no payment method. Please add one.
                    </HelpTextInformation>
                </RenderIf>
            </PaymentContainer>
            {/* <SectionTitleContainer>
                <div>
                    <SectionTitle>Billing Contact</SectionTitle>
                    <TextDescription>
                        Billing contacts will be notified by email regarding billing
                        changes in your account.
                    </TextDescription>
                </div>
                <Button
                    variant="base"
                    label="Add Billing Contact"
                    size="small"
                />
            </SectionTitleContainer>
            <StyledTable
                keyField="id"
                data={data}
                variant="listview"
                hideTableHeader
            >
                <Column field="contact" component={Contact} />
                <Column component={ButtonAction} width={60} />
            </StyledTable> */}
        </>
    );
};

export default PaymentMethod;
