import PropTypes from 'prop-types';

export default function StepFlow(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="22px"
            height="20px"
            viewBox="0 0 22 20"
        >
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="flow-details/new-promote-setted-hover" transform="translate(-861.000000, -179.000000)" fill="#081832">
                    <path d="M875.151826,179.486699 C879.380021,180.872484 882.247806,184.907542 882.094143,189.253098 C881.930612,193.879899 878.80731,197.656621 874.235488,198.756227 C870.416122,199.675032 865.726939,197.908967 864.05286,194.944614 C864.785931,194.699669 865.514419,194.411023 866.268988,194.23128 C866.49913,194.176652 866.855092,194.35992 867.060916,194.540368 C870.972268,197.965357 877.058162,196.528116 878.969431,191.715228 C880.239264,188.516504 879.14142,184.902256 876.293019,182.905696 C873.518278,180.960945 869.692567,181.147737 867.169114,183.398757 C866.850954,183.682346 866.602001,183.824644 866.271672,183.771214 C866.154118,183.7522 864.665749,183.258839 864.001757,183.065703 C866.284848,179.658336 871.056149,178.143911 875.151826,179.486699 Z M873.441623,185.615307 C874.81049,186.158061 875.721894,187.53574 875.71696,188.986373 C875.712026,190.465907 874.821768,191.80376 873.462417,192.375414 C872.082623,192.955526 870.470573,192.667232 869.490797,191.598289 C869.490797,191.613796 870.156914,190.619976 870.156914,189.000082 C870.156914,187.534463 869.651448,186.553709 869.555169,186.380192 L869.539433,186.352607 L869.539433,186.352607 C870.664767,185.285778 871.995571,185.042244 873.441623,185.615307 Z M863.590885,186.380802 C865.027773,186.361065 866.230643,187.544199 866.237368,188.983201 C866.244036,190.423967 865.06055,191.613091 863.619432,191.613796 C862.238229,191.614148 861.037827,190.441588 861.000821,189.055804 C860.96311,187.648168 862.171619,186.400186 863.590885,186.380802 Z" id="flowstep" />
                </g>
            </g>
        </svg>
    );
}

StepFlow.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

StepFlow.defaultProps = {
    style: {},
    className: undefined,
};
