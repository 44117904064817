import PropTypes from 'prop-types';
import {
    AccordionIcon, RuntimeLabelContainer, LabelTitle,
} from './styled';

const RuntimeLabel = ({ active }) => (
    <RuntimeLabelContainer>
        <AccordionIcon active={active} />
        <LabelTitle>Runtime</LabelTitle>
    </RuntimeLabelContainer>
);

RuntimeLabel.propTypes = {
    active: PropTypes.bool.isRequired,
};

export default RuntimeLabel;
