const getDeploymentHistoryFinishedAt = (deploymentHistory) => {
    const { installationsSummary = {}, installationsTotal } = deploymentHistory;
    const isFinished = Object.values(installationsSummary).length === installationsTotal
        && Object.values(installationsSummary).every(
            ({ status: installationStatus }) => (installationStatus === 'success' || installationStatus === 'error'),
        );
    if (isFinished) {
        const lastFunctionDeployUpdate = Object.values(installationsSummary)
            .reduce((greaterDate, { updatedAt }) => {
                if (updatedAt.toDate() > greaterDate) {
                    return updatedAt.toDate();
                }
                return greaterDate;
            }, 0);
        return lastFunctionDeployUpdate;
    }
    return undefined;
};

export default getDeploymentHistoryFinishedAt;
