import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import Chat from '../../components/Icons/chat';

export const UpgradeContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    overflow: auto;
    flex-wrap: wrap;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    min-width: 260px;
    max-width: 260px;
    margin: 12px 8px;
    padding: 32px 20px 24px;
    border-top: 10px solid ${(props) => props.color};

    ${(props) => props.isCurrentPlan && `
        border-left: 1px solid ${props.color};
        border-right: 1px solid ${props.color};
        border-bottom: 1px solid ${props.color};
    `};
`;

export const CardTitle = styled.h2`
    font-size: 28px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const CardDescription = styled.h2`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 30px;
`;

export const DollarSymbol = styled.h4`
    font-size: 20px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-right: 3px;
`;

export const Price = styled.h1`
    font-size: 48px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    line-height: 1;
`;

export const PriceContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const FeaturesTitle = styled.h3`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 5px;
`;

export const StyledButton = styled(Button)`
    width: 100%;
    margin: 16px 0 0;
`;

export const StyledCurrentText = styled.h2`
    font-size: 20px;
    line-height: 40px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.color};
    width: 100%;
    margin: 16px 0 0;
    text-align: center;
    align-self: flex-end;
`;

export const ChatIcon = styled(Chat)`
    width: 69px;
    height: 57px;
`;

export const LoadingPlanName = styled.div`
    width: 80%;
    height: 32px;
`;

export const LoadingPlanDescription = styled.div`
    width: 98%;
    height: 12px;
    margin-top: 20px;
`;

export const LoadingPlanPrice = styled.div`
    width: 100px;
    height: 36px;
    margin-top: 54px;
`;

export const LoadingFeatureHeader = styled.div`
    width: 100px;
    height: 16px;
    margin-top: 54px;
`;

export const LoadingFeatureItem = styled.div`
    width: 98%;
    height: 10px;
    margin-top: 12px;
`;

export const LoadingFeatureItemMedium = styled.div`
    width: 50%;
    height: 10px;
    margin-top: 12px;
`;

export const LoadingFeatureItemSmall = styled.div`
    width: 30%;
    height: 10px;
    margin-top: 12px;
`;
