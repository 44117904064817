import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import { CoverageColumnContainer } from './styled';

const CoverageColumn = ({ value }) => (
    <CoverageColumnContainer value={value}>
        <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="percent"
            value={value}
            minimumFractionDigits={0}
        />
    </CoverageColumnContainer>
);

CoverageColumn.propTypes = {
    value: PropTypes.string.isRequired,
};

export default CoverageColumn;
