import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Message from '../../../../components/Message';
import TestCoverage from '../../../../components/TestCoverageFunction';
import {
    MessageContainer,
    LockIcon,
    SourceCodeContainer,
} from '../styled';

const Test = ({ data, isPurchased }) => (
    <>
        <RenderIf isTrue={!isPurchased}>
            <SourceCodeContainer>
                <MessageContainer>
                    <Message
                        icon={<LockIcon />}
                        title="If you want to see the Test Coverage of this function you first need to buy the function."
                    />
                </MessageContainer>
            </SourceCodeContainer>
        </RenderIf>
        <RenderIf isTrue={isPurchased}>
            <TestCoverage data={data} />
        </RenderIf>
    </>
);

Test.propTypes = {
    data: PropTypes.object,
    isPurchased: PropTypes.bool,
};

Test.defaultProps = {
    data: undefined,
    isPurchased: false,
};

export default Test;
