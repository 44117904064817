import getSecretName from '../../../helpers/getSecretName';
import KeyIcon from '../keyIcon';

export default function formatData(data) {
    if (data && data.secrets) {
        return data.secrets.map((secret) => ({
            type: 'secret',
            value: secret.name,
            label: getSecretName(secret),
            icon: <KeyIcon />,
        }));
    }
    return [];
}
