/* eslint-disable react/jsx-props-no-spreading */
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import usePlanServiceCount from '../../data/hooks/usePlanServiceCount';
import { CHECK_PROJECTS_PERMISSIONS_MODAL } from '../../constants';
import AddProjectHeader from './header';
import Steps from './steps';
import CheckProjectsModal from './check';
import {
    Title, Container, StyledFloatingBar, FloatingBarTitle,
} from './styled';

// eslint-disable-next-line react/prop-types
export default function AddProjects({ history: { replace } }) {
    const {
        isLoading: isLoadingServiceCount, hasReachedLimit,
    } = usePlanServiceCount({ serviceName: 'projects' });
    const projectsListModalProps = useConnectModal(CHECK_PROJECTS_PERMISSIONS_MODAL);
    const [
        openProjectsModal, closeProjectsModal,
    ] = useOpenModal(CHECK_PROJECTS_PERMISSIONS_MODAL);

    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });

    if (!isLoadingServiceCount && hasReachedLimit) {
        replace('/app/projects');
        return null;
    }

    return (
        <Container onScroll={handleScrollEvent}>
            <StyledFloatingBar isVisible={showBar}>
                <AddProjectHeader
                    onCheck={() => openProjectsModal()}
                    title={<FloatingBarTitle>Add New Projects</FloatingBarTitle>}
                />
            </StyledFloatingBar>
            <AddProjectHeader title={<Title>Add New Projects</Title>} />
            <Steps onCheck={() => openProjectsModal()} />
            <CheckProjectsModal
                {...projectsListModalProps}
                onRequestClose={closeProjectsModal}
            />
        </Container>
    );
}
