import styled from 'styled-components';
import { CheckCircleFilled, CloseCircleFilled } from '@rainbow-modules/icons';
import {
    GREEN, RED, ORANGE, BLUE, ROMAN_SILVER,
} from '../../colors';

export const Container = styled.div`
    display: flex;
    padding: 12px 16px 12px 8px;
    margin: 8px 4px 16px;
    align-items: start;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
`;

export const Content = styled.div`
    margin-left: 20px;
`;

export const ItemContainer = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`;

export const ItemLabel = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 18px;

    ${(props) => props.size === 'small' && `
        font-size: 14px;
    `}
`;

export const ItemDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 16px;

    ${(props) => props.size === 'small' && `
        font-size: 12px;
    `}
`;

export const Line = styled.div`
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
    position: absolute;
    top: 8px;
    bottom: 8px;

    ${(props) => props.variant === 'success' && `
        border: 2px solid ${GREEN};
    `}

    ${(props) => props.variant === 'error' && `
        border: 2px solid ${RED};
    `}

    ${(props) => props.variant === 'warning' && `
        border: 2px solid ${ORANGE};
    `}

    ${(props) => props.variant === 'info' && `
        border: 2px solid ${BLUE};
    `}

    ${(props) => props.variant === 'default' && `
        border: 2px solid ${ROMAN_SILVER};
    `}
`;

export const StyledSuccessIcon = styled(CheckCircleFilled)`
    color: ${GREEN};
    margin-left: 20px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;

    ${(props) => props.size === 'small' && `
        margin-left: 12px;
        width: 22px;
        height: 22px;
    `}
`;

export const StyledErrorIcon = styled(CloseCircleFilled)`
    color: ${RED};
    margin-left: 20px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;

    ${(props) => props.size === 'small' && `
        margin-left: 12px;
        width: 22px;
        height: 22px;
    `}
`;
