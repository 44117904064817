import PropTypes from 'prop-types';
import { RecordField } from '@rainbow-modules/record';

const getValue = (collection, key) => {
    const value = collection[key];
    if (value && value.type) {
        if (value.type === 'text') {
            return value.value;
        }
        if (value.type === 'secret') {
            return '*********';
        }
        return value.value;
    }
    return value;
};

const EnvironmentsVariables = ({ environmentsVariables, isLoading }) => {
    const keys = Object.keys(environmentsVariables);
    return (
        keys.map((key) => (
            <RecordField
                key={key}
                label={key}
                value={getValue(environmentsVariables, key)}
                isLoading={isLoading}
            />
        ))
    );
};
EnvironmentsVariables.propTypes = {
    environmentsVariables: PropTypes.object,
    isLoading: PropTypes.bool,
};

EnvironmentsVariables.defaultProps = {
    environmentsVariables: {},
    isLoading: true,
};

export default EnvironmentsVariables;
