import PropTypes from 'prop-types';
import { Users } from '@rainbow-modules/icons';
import { RenderIf } from 'react-rainbow-components';
import WorkspaceInfo from '../../../components/WorkspaceInfo';
import Build from '../../../components/Icons/build';
import {
    CardContainer, StyledCard, StatsContainer, StatContent, StatIcon, StatValue,
} from './styled';

const WorkspaceCard = (props) => {
    const { name, userRole } = props;
    const showStats = false;

    return (
        <CardContainer>
            <StyledCard>
                <WorkspaceInfo name={name} userRole={userRole} className="rainbow-m-top_small rainbow-m-left_small rainbow-m-right_small" />
                <RenderIf isTrue={showStats}>
                    <StatsContainer>
                        <StatContent>
                            <StatIcon />
                            <StatValue>
                                12
                            </StatValue>
                        </StatContent>
                        <StatContent>
                            <StatIcon as={Build} />
                            <StatValue>
                                12
                            </StatValue>
                        </StatContent>
                        <StatContent>
                            <StatIcon as={Users} />
                            <StatValue>
                                12
                            </StatValue>
                        </StatContent>
                        <StatContent>
                            <StatValue>
                                Updated 2 hours ago
                            </StatValue>
                        </StatContent>
                    </StatsContainer>
                </RenderIf>
            </StyledCard>
        </CardContainer>
    );
};

WorkspaceCard.propTypes = {
    name: PropTypes.string.isRequired,
    userRole: PropTypes.string.isRequired,
};

export default WorkspaceCard;
