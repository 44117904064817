import PropTypes from 'prop-types';
import getFunctionName from '../../../helpers/getFunctionName';
import generateFunctionLogsUrl from '../../../helpers/generateFunctionLogsUrl';
// import { useState } from 'react';
// import {
//     Column, ButtonGroup, Button, Drawer,
// } from 'react-rainbow-components';
// import { ChevronRight, ChevronLeft } from '@rainbow-modules/icons';
// import { CodeBlock } from '@rainbow-modules/content';
// import AlignLeftContent from '../../../components/AlignLeftContent';
// import formatDate from '../../../helpers/formatDate';
// import DateTime from './columns/dateTime';
// import mockData from './logsMockData';
import {
    /* StyledTable, LogsFooter, StyledHederContainer, StyledHeaderIcon, */ Description, TermsLink,
} from './styled';

// eslint-disable-next-line react/prop-types
// const DrawerHeader = ({ dateTime }) => {
//     const formattedDateTime = formatDate(dateTime);
//     return (
//         <StyledHederContainer>
//             <StyledHeaderIcon />
//             {formattedDateTime}
//         </StyledHederContainer>
//     );
// };

const Logs = ({ details, installedFunction }) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const [selectedLog, setSelectedLog] = useState({});

    // const handleOnClick = ({ row }) => {
    //     setSelectedLog(row);
    //     setIsOpen(true);
    // };

    const { name } = details;
    const { name: installedFunctionName, region, projectId } = installedFunction;
    const functionName = getFunctionName(name);
    const path = generateFunctionLogsUrl({ projectId, functionName, region });

    return (
        <div className="rainbow-m-top_medium">
            <Description>
                Go to
                {' '}
                <TermsLink href={path} target="_blank" rel="noopener noreferrer">
                    {`${installedFunctionName} logs`}
                </TermsLink>
                {' '}
                in GCP.
            </Description>
            {/* <StyledTable data={mockData} keyField="id" variant="listview">
                <Column
                    header="Date Time"
                    field="dateTime"
                    component={DateTime}
                    width={220}
                    cellAlignment="left"
                    onClick={handleOnClick}
                />
                <Column header="Description" field="description" component={AlignLeftContent} />
            </StyledTable>
            <LogsFooter>
                <ButtonGroup>
                    <Button variant="base" disabled>
                        <ChevronLeft className="rainbow-m-right_medium" />
                        Previous
                    </Button>
                    <Button variant="base" disabled={false}>
                        Next
                        <ChevronRight className="rainbow-m-left_medium" />
                    </Button>
                </ButtonGroup>
            </LogsFooter>
            <Drawer
                header={<DrawerHeader dateTime={selectedLog.dateTime} />}
                slideFrom="right"
                size="medium"
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
            >
                <CodeBlock className="rainbow-m-top_small" value={selectedLog.description} />
            </Drawer> */}
        </div>
    );
};

Logs.propTypes = {
    details: PropTypes.object.isRequired,
    installedFunction: PropTypes.object.isRequired,
};

export default Logs;
