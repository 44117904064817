import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Column, ButtonGroup, RenderIf } from 'react-rainbow-components';
import { Filter, Plus } from '@rainbow-modules/icons';
import { showAppNotification, showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { FloatingSearchButtonIcon, filterByFields } from '@rainbow-modules/listview';
import useRoles from '../../../data/hooks/useRoles';
import AlignLeftContent from '../../../components/AlignLeftContent';
import LinkCell from '../../../components/LinkCell';
import ButtonIconTooltip from '../../../components/ButtonIconTooltip';
import Message from '../../../components/Message';
import RoleFields from './RoleFields';
import firebaseCallable from '../../../data/services/firebaseCallable';
import events from '../../../analytics/events';
import logEvent from '../../../analytics/logEvent';
import {
    Container,
    Header,
    StyledText,
    StyledTable,
    CreateButton,
    RolesEmptyIcon,
} from './styled';

export default function Roles() {
    const [searchValue, setSearchValue] = useState('');
    const { projectId } = useParams();
    const [data, isLoading] = useRoles(projectId);
    const [isOpenModal, setOpenModal] = useState(false);

    const createRole = async (values) => {
        showAppSpinner({ message: 'Creating role' });
        try {
            await firebaseCallable('roles-create', { projectId, ...values });
            hideAppSpinner();
            setOpenModal(false);
            showAppNotification({
                title: 'Success!',
                description: `The "${values.name}" role was created successfully.`,
                icon: 'success',
                timeout: 5000,
            });
            logEvent(events.auth.CREATE_ROLE);
        } catch (error) {
            hideAppSpinner();
            setOpenModal(false);
            showAppNotification({
                title: 'Error!',
                description: `The "${values.name}" role could not be created. ${error.message}`,
                icon: 'error',
                timeout: 5000,
            });
        }
    };

    const filteredData = useMemo(
        () => filterByFields({
            data,
            query: searchValue,
            fields: ['name', 'description'],
        }),
        [searchValue, data],
    );

    const handleChange = (value) => {
        setSearchValue(value);
    };

    const hasRoles = data && data.length > 0;

    return (
        <Container>
            <RenderIf isTrue={isLoading || hasRoles}>
                <Header>
                    <StyledText>
                        Create and manage Roles for your applications. Roles contain collections of
                        Permissions and can be assigned to users.
                    </StyledText>
                    <ButtonGroup className="rainbow-m-left_medium" variant="shaded">
                        <FloatingSearchButtonIcon
                            id="roles-search-button"
                            icon={<Filter />}
                            onChange={handleChange}
                            value={searchValue}
                        />
                        <ButtonIconTooltip
                            id="role-add-button"
                            icon={<Plus />}
                            text="Create Role"
                            onClick={() => setOpenModal(true)}
                        />
                    </ButtonGroup>
                </Header>
                <StyledTable data={filteredData} isLoading={isLoading} keyField="id" variant="listview">
                    <Column header="Name" field="name" component={LinkCell} width={250} />
                    <Column header="Description" field="description" component={AlignLeftContent} />
                </StyledTable>
            </RenderIf>
            <RenderIf isTrue={!isLoading && !hasRoles}>
                <Message
                    className="rainbow-m-bottom_xx-large"
                    icon={<RolesEmptyIcon />}
                    title="Start creating and managing Roles for your applications"
                    description="Roles contain collections of
                    Permissions and can be assigned to users."
                    action={(
                        <CreateButton
                            variant="brand"
                            label="Create Role"
                            onClick={() => setOpenModal(true)}
                        />
                    )}
                />
            </RenderIf>
            <UniversalFormModal
                id="create-role-modal"
                title="Create Role"
                isOpen={isOpenModal}
                onRequestClose={() => setOpenModal(false)}
                onSubmit={createRole}
                fields={RoleFields}
                submitButtonLabel="Create"
            />
        </Container>
    );
}
