import styled from 'styled-components';
import { WHITE } from '../../colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    background-color: ${WHITE};
`;

export const Title = styled.div`
    padding: 15px;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Description = styled.h3`
    font-size: 14px;
    line-height: 1.5;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    padding: 12px 16px;
`;
