export default function formatElapsedTime(timeInMsSeconds) {
    if (typeof timeInMsSeconds === 'number') {
        if (Math.floor(timeInMsSeconds) === 0) return '0ms';

        const timeInSeconds = timeInMsSeconds / 1000;
        const result = [];

        const hours = Math.floor(timeInSeconds / 3600);
        if (hours > 0) result.push(`${hours}h`);
        const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
        if (minutes > 0) result.push(`${minutes}m`);
        const seconds = Math.floor(
            timeInSeconds - (hours * 3600 + minutes * 60),
        );
        if (seconds > 0) result.push(`${seconds}s`);

        const mseconds = Math.floor(
            timeInMsSeconds - (hours * 3600 + minutes * 60 + seconds) * 1000,
        );
        if (mseconds > 0) result.push(`${mseconds}ms`);

        return result.join(' ');
    }
    return timeInMsSeconds || '- -';
}
