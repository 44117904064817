import { useCurrentUser, useDoc } from '@rainbow-modules/firebase-hooks';

const useCurrentUserSubscription = () => {
    const { uid } = useCurrentUser();
    return useDoc({
        path: `subscriptions/${uid}`,
        flat: true,
        disabled: !uid,
    });
};

export default useCurrentUserSubscription;
