import styled from 'styled-components';
import { Card, Input, HelpText } from 'react-rainbow-components';
import { Link as RouterLink } from 'react-router-dom';
import { FloatingBar } from '@rainbow-modules/layout';
import { InputSearch } from '@rainbow-modules/search';
import Project from '../../../components/Icons/project';
import Setup from '../../../components/Icons/setup';
import { LIGHT_WHITE, GRAY } from '../../../colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    background-color: ${LIGHT_WHITE};
    height: 100vh;
    border-radius: 22px 0 0 0;
    padding: 32px 28px 0 28px;
`;

export const ProjectCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
    margin-right: 8px;
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Row = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const ButtonLink = styled(RouterLink)`
    color: white;
    font-size: 16px;
    line-height: 40px;
    width: max-content;
    height: 40px;
    background: ${(props) => props.theme.rainbow.palette.brand.main};
    padding: 0 14px;
    border-radius: 40px;

    :hover,
    :focus,
    :active {
        text-decoration: none;
        background: ${(props) => props.theme.rainbow.palette.brand.dark};
        color: white;
    }

    ${(props) => props.disabled && `
        background: ${props.theme.rainbow.palette.border.disabled};
        pointer-events: none;
    `}
`;

export const SearchInput = styled(Input)`
    width: 35%;
    padding: 4px 4px 0 4px;
    z-index: 1;
    margin-top: 12px;

    div {
        height: 50px;
        line-height: 50px;
    }

    input {
        height: 50px;
        line-height: 50px;
    }
`;

export const StyledInputSearch = styled(InputSearch)`
    margin: 0 16px 0 8px;
    width: 50%;
`;

export const ProjectSubheader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 4px;
    margin-right: 12px;
`;

export const Title = styled.h1`
    font-size: ${(props) => (props.fontSize ? props.fontSize : '24px')};
    letter-spacing: 0.25px;
    margin: 0 0 0 8px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 4px 0 0 8px;
`;

export const ProjectsContainer = styled.article`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-bottom: 32px;
`;

export const StyledCard = styled(Card)`
    width: 260px;
    margin: 8px;
    border: none;
    box-shadow: ${(props) => props.theme.rainbow.shadows.shadow_10};
    padding: 0 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    ${(props) => props.isInactive
        && `
            box-shadow: none;
            background: rgba(255, 255, 255, 0.8);

            :hover {
                box-shadow: none;
                cursor: default;
            }
        `};
`;

export const ProjectInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ProjectContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 15px;
`;

export const ProjectIcon = styled(Project)`
    width: 44px;
    height: 44px;
    color: ${GRAY};
    margin-top: 20px;

    ${(props) => props.isDisabled
        && `
            opacity: 0.3;
        `};
`;

export const ProjectCardHeaderRight = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-top: 8px;
    position: absolute;
    right: 0;
`;

export const ProjectCardFooterRight = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-right: 16px;
    margin-bottom: 21px;
    position: absolute;
    right: 0;
    bottom: 0;
`;

export const ProjectTitle = styled.h3`
    font-size: 17px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 4px;

    ${(props) => props.isDisabled
        && `
            opacity: 0.3;
        `};
`;

export const ProjectId = styled.h4`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 2px;

    ${(props) => props.isDisabled
        && `
            opacity: 0.3;
        `};
`;

export const StyledHelpText = styled(HelpText)`
    width: 20px;
    height: 20px;
    border-radius: 0;

    > svg {
        width: 18px;
        height: 18px;
    }
`;

export const HelpTextBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 0 4px;
`;

export const HelpTextTitle = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const HelpTextContent = styled.p`
    font-size: 12px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 4px;
`;

export const Link = styled(RouterLink)`
    &:hover,
    &:active,
    &:focus {
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
    }
`;

export const SpinnerContainer = styled.div`
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(5px);
    background-color: ${LIGHT_WHITE};
    border-radius: 22px 0 0 0;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const EmptyMessageTitle = styled.h4`
    text-align: center;
    font-size: 18px;
    letter-spacing: 0.5px;
    margin: 0 0 8px 0;
    font-weight: 900;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const EmptyMessageDescription = styled.p`
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.5px;
    width: 60%;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const CardButton = styled.button`
    background: none;
    border: none;
    outline: 0;
    cursor: pointer;
    appearance: button;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
        outline: 0;
    }
`;

export const MessageTitle = styled.h2`
    font-size: 24px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 8px;
    text-align: center;
`;

export const MessageDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 12px;
    max-width: 450px;
    text-align: center;
`;

export const StyledFloatingBar = styled(FloatingBar)`
    background-color: ${LIGHT_WHITE};
    margin: 0 32px 0 100px;
    padding: 14px 0 0;
`;

export const MoreOptionIcon = styled(Setup)`
    width: 18px;
    height: 18px;
    margin-bottom: 2px;
`;
