import { useCallback } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import useCallableMutation from './useCallableMutation';
import useMutationFlow from './useMutationFlow';

const useDisableAllSecretVersions = ({ onSuccess = () => {} }) => {
    const { mutateAsync: disableAll } = useCallableMutation('secrets-disableAllSecretVersions');

    const { mutate: disableAllSecretVersionsFlow } = useMutationFlow({
        mutation: disableAll,
        submitSpinnerMessage: 'Disabling versions',
        successMessage: null,
        onSuccess,
    });

    const remove = useCallback(async ({
        secretName, projectId, children,
    }) => {
        const result = await confirmModal({
            header: 'Disable All Secret Versions',
            okButtonLabel: 'Disable',
            children,
        });
        if (result) {
            await disableAllSecretVersionsFlow({ secretName, projectId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return remove;
};

export default useDisableAllSecretVersions;
