import PropTypes from 'prop-types';

export default function Code(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="33px" height="22px" viewBox="0 0 33 22">
            <g id="new-layout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="new-layout/projects/console-setup" transform="translate(-16.000000, -224.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M35.3602565,224.83364 C35.6593558,224.135711 36.4677399,223.812412 37.165669,224.111512 C37.8635981,224.410611 38.1869884,225.218903 37.8877974,225.916924 L37.8877974,225.916924 L29.6380446,245.166347 C29.3390368,245.864093 28.5308361,246.187667 27.832632,245.888476 C27.1347029,245.589376 26.8113126,244.780992 27.1105036,244.083063 L27.1105036,244.083063 Z M23.3905067,228.426499 C23.9832056,227.952138 24.8486047,228.048202 25.3230572,228.641176 C25.7974179,229.234241 25.7013542,230.099457 25.1082886,230.573818 L25.1082886,230.573818 L19.5756377,234.999994 L25.1082886,239.426261 C25.7013542,239.90053 25.7974179,240.765838 25.3230572,241.358812 C24.8488797,241.951694 23.9834806,242.047941 23.3905067,241.573488 L23.3905067,241.573488 L16.5157127,236.073653 C15.8278666,235.523486 15.8283249,234.476043 16.5157127,233.926334 L16.5157127,233.926334 Z M39.6752438,228.641176 C40.149513,228.048202 41.0148204,227.952047 41.6077943,228.426499 L41.6077943,228.426499 L48.4825883,233.926334 C49.1704344,234.476501 49.1699761,235.523853 48.4825883,236.073562 L48.4825883,236.073562 L41.6077943,241.573397 C41.0156454,242.047299 40.1502463,241.952335 39.6752438,241.35872 C39.2008831,240.765746 39.2969468,239.90053 39.8900124,239.426169 L39.8900124,239.426169 L45.4226633,234.999994 L39.8900124,230.573726 C39.2969468,230.099457 39.2008831,229.23415 39.6752438,228.641176 Z" id="Combined-Shape" />
                </g>
            </g>
        </svg>
    );
}

Code.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Code.defaultProps = {
    style: {},
    className: undefined,
};
