import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import Message from '../../../../../components/Message';
import Steps from './steps';
import { TabContent, MessageContainer, LockIcon } from '../../styled';
import {
    ZipHeader, ContentTitle, ContentDescription,
} from './styled';

const Zip = ({ data, isPurchased }) => (
    <TabContent className="rainbow-m-top_medium">
        <RenderIf isTrue={!isPurchased}>
            <MessageContainer>
                <Message
                    icon={<LockIcon />}
                    title="If you want to see the ZIP of the function, you first need to purchase the function."
                />
            </MessageContainer>
        </RenderIf>
        <RenderIf isTrue={isPurchased}>
            <ZipHeader>
                <ContentTitle>
                    Install function manually
                </ContentTitle>
                <ContentDescription>
                    If you want to install a function without granting your
                    Google Cloud project permissions directly in Function Store,
                    you can do so by downloading a ZIP file. This ZIP contains the
                    function code that allows you to install it manually in Google Cloud.
                    The following steps will guide you through the process.
                </ContentDescription>
            </ZipHeader>
            <Steps data={data} />
        </RenderIf>
    </TabContent>
);

Zip.propTypes = {
    isPurchased: PropTypes.bool,
    data: PropTypes.object,
};

Zip.defaultProps = {
    isPurchased: false,
    data: undefined,
};

export default Zip;
