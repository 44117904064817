import styled from 'styled-components';

export const ModalInfoContainer = styled.div`
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 10px;
    padding: 20px 10px;
    display: flex;
    margin-bottom: 10px;
`;

export const Title = styled.h2`
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 900;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ModalInfoDescription = styled.p`
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const ModalTextContainer = styled.div`
    min-width: 0;
    margin-left: 10px;
    margin-top: -5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const WarningText = styled.p`
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
`;
