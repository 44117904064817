import flattenDeep from 'lodash/flattenDeep';

const formatCheckResults = (checksResults) => {
    if (checksResults) {
        const mappedCheckResults = checksResults.map((item) => {
            if (item.checksResults) {
                return item.checksResults;
            }
            return [item];
        });
        return flattenDeep(mappedCheckResults);
    }
    return [];
};

export default formatCheckResults;
