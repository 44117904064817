import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDoc } from '@rainbow-modules/firebase-hooks';
import { RenderIf } from 'react-rainbow-components';
import FunctionIcon from '../../../components/FunctionIcon';
import Failed from '../failed';
import BackButton from '../../../components/BackButton';
import {
    BuildingContainer,
    Highlight,
    BuildingTitle,
    BuildingDescription,
    BuildingInfo,
    StyledBuildingSpinner,
} from './styled';

const errorStatusArray = ['UNKNOWN', 'OFFLINE'];

export default function BuildingFunction() {
    const { projectId, functionId } = useParams();
    const { state: locationState } = useLocation();
    const { push } = useHistory();
    const [data, isLoading] = useDoc({
        path: `projects/${projectId}/functions/${functionId}`,
        flat: true,
    });
    const installedFunctionsPath = `/app/project/${projectId}/functions/installed`;

    const functionDoesNotExists = !isLoading && !data;
    const isNotBuilding = data && data.status && data.status !== 'BUILDING';

    if (functionDoesNotExists) {
        push(`/app/project/${projectId}/functions/installed`);
        return null;
    }

    const {
        region, name, iconUrl, installationId, workspace, trigger = {}, status,
    } = data || {};

    const installationHasError = errorStatusArray.includes(status);
    if (isNotBuilding) {
        const isHttpsProvider = trigger.provider === 'https';
        const functionName = isHttpsProvider ? `${workspace}_${name}` : `${workspace}_${name}_${installationId}`;
        if (installationHasError) {
            return <Failed funcName={name} />;
        }
        push(`/app/project/${projectId}/functions/installed/${region}/${functionName}`);
        return null;
    }

    const { iconUrl: iconUrlInState } = locationState;
    const shouldRenderIcon = Boolean(iconUrlInState || data);
    const functionIcon = iconUrlInState || iconUrl;

    return (
        <>
            <BackButton className="rainbow-m-left_x-large" to={installedFunctionsPath} label="Back to Installed" />
            <BuildingContainer>
                <StyledBuildingSpinner type="arc" variant="brand" size="x-large">
                    <RenderIf isTrue={shouldRenderIcon}>
                        <FunctionIcon url={functionIcon} functionType="marketplace" width="48px" height="48px" />
                    </RenderIf>
                </StyledBuildingSpinner>
                <BuildingInfo>
                    <BuildingTitle>
                        Building
                        <Highlight>{name}</Highlight>
                        function
                    </BuildingTitle>
                    <BuildingDescription>
                        Building the function process should take ~ 2 minutes.
                    </BuildingDescription>
                </BuildingInfo>
            </BuildingContainer>
        </>
    );
}
