import styled from 'styled-components';
import { Table } from 'react-rainbow-components';
import {
    QuestionFilled, Users,
} from '@rainbow-modules/icons';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const StyledText = styled.p`
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 0 8px 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0 8px;
    max-width: 540px;
`;

export const StyledTable = styled(Table)`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 8px;
`;

export const Icon = styled(QuestionFilled)`
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StyledHeader = styled.span`
    text-transform: none;
`;

export const MessageIcon = styled(Users)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;
