import Step4 from './images/step4.png';
import {
    StyledImage,
    StepDescription,
    ItemContainer,
    Line,
    ItemLabel,
    StyledCopyToClipboardButton,
    ItemDescription,
} from './styled';

const SlowAssignRole = () => (
    <>
        <StyledImage src={Step4} width={600} height={600} className="rainbow-m-top_small" />
        <StepDescription className="rainbow-m-top_medium">
            Assign the following roles to the service account:
        </StepDescription>
        <ItemContainer>
            <Line />
            <div>
                <ItemLabel>
                    Cloud Functions Admin
                    <StyledCopyToClipboardButton value="Cloud Functions Admin" />
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Full access to functions, operations and locations.
                </ItemDescription>
            </div>
        </ItemContainer>
        <ItemContainer>
            <Line />
            <div>
                <ItemLabel>
                    Firebase Authentication Admin
                    <StyledCopyToClipboardButton value="Firebase Authentication Admin" />
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Full read/write access to Firebase Authentication resources.
                </ItemDescription>
            </div>
        </ItemContainer>
        <ItemContainer>
            <Line />
            <div>
                <ItemLabel>
                    Secret Manager Admin
                    <StyledCopyToClipboardButton value="Secret Manager Admin" />
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Full access to administer Secret Manager resources.
                </ItemDescription>
            </div>
        </ItemContainer>
        <ItemContainer>
            <Line />
            <div>
                <ItemLabel>
                    Service Account User
                    <StyledCopyToClipboardButton value="Service Account User" />
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Run operations as the service account.
                </ItemDescription>
            </div>
        </ItemContainer>
        <ItemContainer>
            <Line />
            <div>
                <ItemLabel>
                    Service Usage Admin
                    <StyledCopyToClipboardButton value="Service Usage Admin" />
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Ability to enable, disable, and inspect service states,
                    inspect operations, and consume quota and billing for
                    a consumer project.
                </ItemDescription>
            </div>
        </ItemContainer>
    </>
);

export default SlowAssignRole;
