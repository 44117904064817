import styled from 'styled-components';
import { UPGRADE_GREEN, WHITE } from '../../colors';
import Upgrade from '../Icons/upgrade';

export const Container = styled.div`
    position: fixed;
    bottom: 24px;
    right: 30px;
    width: calc(100% - 130px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${WHITE};
    border-radius: 24px;
    box-shadow: 0 18px 28px 0 rgba(0, 0, 0, 0.1);
    padding: 22px;
`;

export const LeftContent = styled.div`
    display: flex;
`;

export const UpgradeIcon = styled(Upgrade)`
    color: ${UPGRADE_GREEN};
    height: 44px;
    width: 44px;
    flex-shrink: 0;
`;

export const Title = styled.h2`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Description = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const RightContent = styled.div`
    display: flex;
    align-items: center;
`;
