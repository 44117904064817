import { useState } from 'react';
import PropTypes from 'prop-types';
import { InputSearch } from '@rainbow-modules/search';
import { Column, Button } from 'react-rainbow-components';
import useWorkpaceFunctionImportSearch from '../../../../data/hooks/useWorkpaceFunctionImportSearch';
import FunctionColumn from './columns/function';
import CheckboxColumn from './columns/checkbox';
import {
    ImportModalTitle,
    ImportModalDescription,
    ImportModalFooter,
    SelectedFunctionsCounter,
    Counter,
    TableContainer,
    Container,
    TopContent,
} from './styled';

const ImportFunctionsModal = ({
    workspace, importedFunctions, onRequestClose, onFinished,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFunctions, setSelectedFunctions] = useState([]);
    const { data: searchedData, isLoading } = useWorkpaceFunctionImportSearch({
        workspace,
        importedFunctions,
        searchQuery,
    });

    const handleSelect = (row) => {
        setSelectedFunctions([...selectedFunctions, row]);
    };

    const handleDeselect = (row) => {
        setSelectedFunctions(selectedFunctions.filter(
            (fn) => fn.name !== row.name && fn.workspace !== row.workspace,
        ));
    };

    return (
        <Container>
            <TopContent>
                <div className="rainbow-m-horizontal_x-large rainbow-m-top_large">
                    <ImportModalTitle>
                        Functions to import to workspace
                        &nbsp;
                        <strong>{`"${workspace}"`}</strong>
                    &nbsp;
                    </ImportModalTitle>
                    <ImportModalDescription>
                        To import the functions,
                        select them from the table and hit the Import Functions button.
                    </ImportModalDescription>
                    <div className="rainbow-flex rainbow-m-vertical_medium">
                        <InputSearch
                            placeholder="Search Function"
                            value={searchQuery}
                            variant="realtime"
                            onChange={setSearchQuery}
                        />
                    </div>
                </div>
                <TableContainer
                    keyField="id"
                    data={searchedData}
                    isLoading={isLoading}
                    variant="listview"
                >
                    <Column
                        header="Marketplace Functions"
                        field="function"
                        cellAlignment="left"
                        headerAlignment="left"
                        component={FunctionColumn}
                    />
                    <Column
                        width={50}
                        field="isSelected"
                        cellAlignment="center"
                        component={
                            (props) => (
                                <CheckboxColumn
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...props}
                                    selectedRows={selectedFunctions}
                                    onSelect={handleSelect}
                                    onDeselect={handleDeselect}
                                />
                            )
                        }
                    />
                </TableContainer>
            </TopContent>
            <ImportModalFooter>
                <SelectedFunctionsCounter>
                    <Counter>
                        {selectedFunctions.length}
                    </Counter>
                    Functions to import
                </SelectedFunctionsCounter>
                <div>
                    <Button
                        variant="base"
                        label="Cancel"
                        className="rainbow-m-right_medium"
                        onClick={onRequestClose}
                    />
                    <Button
                        variant="brand"
                        label="Import Functions"
                        onClick={() => onFinished(selectedFunctions)}
                    />
                </div>
            </ImportModalFooter>
        </Container>
    );
};

ImportFunctionsModal.propTypes = {
    workspace: PropTypes.string,
    importedFunctions: PropTypes.array,
    onRequestClose: PropTypes.func,
    onFinished: PropTypes.func,
};

ImportFunctionsModal.defaultProps = {
    workspace: undefined,
    importedFunctions: [],
    onRequestClose: () => {},
    onFinished: () => {},
};

export default ImportFunctionsModal;
