import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logPageView from '../../analytics/logPageView';

export default function usePageTracking() {
    const location = useLocation();
    useEffect(() => {
        logPageView();
    }, [location]);
}
