import { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Button } from 'react-rainbow-components';
import LabelWithDescription from '../../../../../../../components/LabelWithDescription';
import { ModalTitle, Footer } from './styled';
import useUpdateDeployment from '../../../hooks/useUpdateDeployment';

const CreateEditEnvModal = (props) => {
    const {
        isOpen, onRequestClose, index, targetProjects,
    } = props;
    const mode = index === -1 ? 'create' : 'edit';
    const [envName, setEnvName] = useState(mode === 'edit' ? targetProjects[index].name : '');
    const { updateDeployment, isLoading } = useUpdateDeployment();

    const label = mode === 'create' ? 'Add' : 'Edit';
    const title = mode === 'create' ? `${label} new environment` : `${label} environment`;

    const create = async () => {
        await updateDeployment({
            targetProjects: [...targetProjects, {
                name: envName,
            }],
        });
        onRequestClose();
    };
    const edit = async () => {
        const updatedProjects = [...targetProjects];
        updatedProjects[index].name = envName;
        await updateDeployment({
            targetProjects: updatedProjects,
        });
        onRequestClose();
    };
    const submit = (event) => {
        event.preventDefault();
        if (mode === 'create') {
            return create();
        }
        return edit();
    };
    return (
        <Modal
            isOpen={isOpen}
            title={<ModalTitle>{title}</ModalTitle>}
            onRequestClose={onRequestClose}
        >
            <form onSubmit={submit}>
                <Input
                    label={(
                        <LabelWithDescription
                            name="Environment Name"
                            description="Create new environments for your deployment flow. Possible names you can use for your deployments are `production`,  `qa`, `staging`."
                        />
                    )}
                    labelAlignment="left"
                    required
                    placeholder="production, staging or qa"
                    value={envName}
                    onChange={(event) => setEnvName(event.target.value)}
                    className="rainbow-m-right_medium rainbow-m-left_medium"
                />
                <Footer>
                    <Button
                        label="Cancel"
                        variant="base"
                        className="rainbow-m-right_medium"
                        onClick={onRequestClose}
                    />
                    <Button
                        label={label}
                        variant="brand"
                        disabled={!envName}
                        type="submit"
                        isLoading={isLoading}
                    />
                </Footer>
            </form>
        </Modal>
    );
};

CreateEditEnvModal.propTypes = {
    index: PropTypes.number,
    targetProjects: PropTypes.array,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
};

CreateEditEnvModal.defaultProps = {
    index: -1,
    targetProjects: [],
    isOpen: false,
    onRequestClose: () => {},
};

export default CreateEditEnvModal;
