import PropTypes from 'prop-types';
import { Table, Column, RenderIf } from 'react-rainbow-components';
import { FormattedNumber } from 'react-intl';
import { isEmpty } from '@rainbow-modules/validation';
import Message from '../Message';
import { WORKSPACE_CI_PATH } from '../../constants';
import getPercentValueFromCoverage from '../../helpers/getPercentValueFromCoverage';
import CoverageColumn from './coverageColumn';
import TotalRow from './totalRow';
import { Container, TestIcon } from './styled';

// eslint-disable-next-line react/prop-types
const Percent = ({ value }) => (
    <span className="rainbow-m-right_small">
        <FormattedNumber
        // eslint-disable-next-line react/style-prop-object
            style="percent"
            value={value}
            minimumFractionDigits={0}
        />
    </span>
);

const getCoverageData = (data) => {
    if (data) {
        const { functions = {}, lines = {} } = data;
        const { pct, total: linesTotal, covered: linesCovered } = lines;
        const linesPercent = getPercentValueFromCoverage(pct);
        return {
            coverage: linesPercent,
            funcs: getPercentValueFromCoverage(functions?.pct),
            lines: linesPercent,
            uncoveredLines: linesTotal - linesCovered,
        };
    }
    return null;
};

const getTotalCoverageData = (data = []) => {
    const coverageSum = data.reduce((acc, item) => {
        const {
            coverage = 0,
            funcs = 0,
            lines = 0,
            uncoveredLines = 0,
        } = getCoverageData(item?.description?.total) || {};
        return {
            coverage: acc.coverage + coverage,
            funcs: acc.funcs + funcs,
            lines: acc.lines + lines,
            uncoveredLines: acc.uncoveredLines + uncoveredLines,
        };
    }, {
        coverage: 0,
        funcs: 0,
        lines: 0,
        uncoveredLines: 0,
    });

    return {
        coverage: coverageSum.coverage / data.length,
        funcs: coverageSum.funcs / data.length,
        lines: coverageSum.lines / data.length,
        uncoveredLines: coverageSum.uncoveredLines,
    };
};

export default function TestCoverage({ data, checksResults, isLoading }) {
    const showFilesResults = isEmpty(checksResults) && !isEmpty(data);
    let tableData = [];
    if (showFilesResults) {
        tableData = Object.keys(data).filter((item) => item !== 'total').map((item) => {
            const filePath = item.replace(WORKSPACE_CI_PATH, '').split('/').slice(2).join('/');
            return {
                file: filePath,
                ...getCoverageData(data?.[item]),
            };
        });
    } else {
        tableData = checksResults.map((item) => {
            const { name, description } = item;
            return {
                function: name,
                ...getCoverageData(description?.total),
            };
        });
    }

    if (tableData && tableData.length === 0 && !isLoading) {
        return <Message className="rainbow-m-vertical_xx-large" icon={<TestIcon />} title="No coverage found" description="Seems this function does not have any test" />;
    }

    const {
        coverage,
        funcs,
        lines,
        uncoveredLines,
    } = getCoverageData(data?.total) || getTotalCoverageData(checksResults);
    const totalRowLabel = showFilesResults ? 'FUNCTION COVERAGE' : 'WORKSPACE COVERAGE';

    return (
        <Container>
            <Table id="test-coverage-table" isLoading={isLoading} data={tableData} keyField="id" variant="listview">
                {showFilesResults ? <Column header="File" field="file" cellAlignment="left" /> : <Column header="Function" field="function" cellAlignment="left" />}
                <Column header="Coverage" field="coverage" width={100} component={CoverageColumn} />
                <Column header="Funcs" field="funcs" cellAlignment="right" width={100} component={Percent} />
                <Column header="Lines" field="lines" cellAlignment="right" width={100} component={Percent} />
                <Column header="Uncovered Lines" field="uncoveredLines" cellAlignment="right" width={125} />
            </Table>
            <RenderIf isTrue={!isLoading}>
                <TotalRow
                    label={totalRowLabel}
                    coverage={coverage}
                    funcs={funcs}
                    lines={lines}
                    uncoveredLines={uncoveredLines}
                />
            </RenderIf>
        </Container>
    );
}

TestCoverage.propTypes = {
    data: PropTypes.object,
    checksResults: PropTypes.array,
    isLoading: PropTypes.bool,
};

TestCoverage.defaultProps = {
    data: {},
    checksResults: [],
    isLoading: false,
};
