import PropTypes from 'prop-types';

const Project = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="15px"
            height="16px"
            viewBox="0 0 15 16"
        >
            <g fill="currentColor">
                <polygon
                    transform="translate(4.000000, 4.000000) rotate(90.000000) translate(-4.000000, -4.000000)"
                    points="4 0 7.46410162 2 7.46410162 6 4 8 0.535898385 6 0.535898385 2"
                />
                <polygon
                    transform="translate(11.000000, 8.000000) rotate(90.000000) translate(-11.000000, -8.000000)"
                    points="11 4 14.4641016 6 14.4641016 10 11 12 7.53589838 10 7.53589838 6"
                />
                <polygon
                    transform="translate(4.000000, 12.000000) rotate(90.000000) translate(-4.000000, -12.000000)"
                    points="4 8 7.46410162 10 7.46410162 14 4 16 0.535898385 14 0.535898385 10"
                />
            </g>
        </svg>
    );
};

Project.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Project.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Project;
