import PropTypes from 'prop-types';
import { KeyFilled as KeyFilledIcon } from '@rainbow-modules/icons';
import { IconContainer } from './styled';

export default function KeyIcon({ shaded }) {
    return (
        <IconContainer shaded={shaded}>
            <KeyFilledIcon />
        </IconContainer>
    );
}

KeyIcon.propTypes = {
    shaded: PropTypes.bool,
};

KeyIcon.defaultProps = {
    shaded: false,
};
