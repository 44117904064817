import { useMemo } from 'react';
import { useCurrentUser, useCollection } from '@rainbow-modules/firebase-hooks';

const useWorkspaces = () => {
    const { uid } = useCurrentUser();
    const [owner, isLoadingOwner] = useCollection({
        path: 'workspaces',
        query: (query) => query.where('owners', 'array-contains', uid),
        flat: true,
    });
    const [editor, isLoadingEditor] = useCollection({
        path: 'workspaces',
        query: (query) => query.where('editors', 'array-contains', uid),
        flat: true,
    });
    const [viewer, isLoadingViewer] = useCollection({
        path: 'workspaces',
        query: (query) => query.where('viewers', 'array-contains', uid),
        flat: true,
    });
    const results = useMemo(() => ({
        owner,
        invited: [...editor, ...viewer],
    }), [owner, editor, viewer]);
    return [results, isLoadingOwner || isLoadingEditor || isLoadingViewer];
};

export default useWorkspaces;
