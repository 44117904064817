import styled from 'styled-components';
import { HelpText } from 'react-rainbow-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.h4`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const StyledHelpText = styled(HelpText)`
    width: 16px;
    height: 16px;
    display: flex;

    > svg {
        width: 16px;
        height: 16px;
    }
`;

export const HelpTextContent = styled.div`
    margin: -12px;
`;

export const Title = styled.h3`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 16px 16px 12px 16px;
`;

export const CurrentPlanLabel = styled.h4`
    font-size: 11px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-transform: uppercase;
    margin: 0 0 6px 16px;
`;

export const CurrentPlanContainer = styled.div`
    border-top: 4px solid ${(props) => props.color || '#000'};
    border-left: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-right: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    padding: 8px 0 0 0;
    border-radius: 0 0 4px 4px;
    margin: 0 16px 16px 16px;
`;

export const CurrentPlanName = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 0 12px 4px 12px;
`;

export const CurrentPlanDescription = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    margin: 0 12px 12px 12px;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const Bold = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 0 2px;
`;

export const UpgradeText = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    margin: 0 16px 8px 16px;
`;
