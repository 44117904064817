import firebase from '../../../firebase';

const getCollection = async ({ path, query }) => {
    const ref = firebase.firestore().collection(path);
    const finalQuery = query ? query(ref) : ref;
    const collection = await finalQuery.get();
    return collection.docs.map((item) => ({ ...item.data(), id: item.id }));
};

export default getCollection;
