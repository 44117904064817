import { useParams, useHistory } from 'react-router-dom';
import { VerticalSection, RainbowThemeContainer } from 'react-rainbow-components';
import {
    UsersFilled, Function, KeyFilled,
} from '@rainbow-modules/icons';
import ProjectPicker, { ALL_PROJECTS_OPTION } from '../../components/ProjectPicker';
import useCheckProject from '../../data/hooks/useCheckProject';
import { LIGHTER_BLACK, WHITE } from '../../colors';
import CheckingMessage from '../../pages/projects/checkingMessage';
import getRedirectUri from './helpers/getRedirectUri';
import getActiveMenuName from './helpers/getActiveMenuName';
import {
    Container, Bar, IconBar, StyledOption, Divider, Content,
} from './styled';

const theme = {
    rainbow: {
        palette: {
            mainBackground: LIGHTER_BLACK,
        },
    },
};

const lightTheme = {
    rainbow: {
        palette: {
            mainBackground: WHITE,
        },
    },
};

export default function ProjectNavigationBar() {
    const { push: navigate, location: { pathname } } = useHistory();
    const { projectId } = useParams();
    const checkProject = useCheckProject(projectId);

    const handleChange = ({ name, value }) => {
        if (name === ALL_PROJECTS_OPTION) {
            return navigate('/app/projects');
        }
        if (name === projectId) return pathname;

        const redirectUri = (value.setup)
            ? getRedirectUri(name, pathname)
            : `/app/project/${name}/setup`;

        return checkProject({
            projectId: name,
            spinnerMessage: <CheckingMessage projectId={name} />,
            redirectTo: redirectUri,
        });
    };

    const activeSection = getActiveMenuName(pathname);

    return (
        <Container theme={theme}>
            <Content>
                <RainbowThemeContainer theme={lightTheme}>
                    <ProjectPicker
                        value={projectId}
                        className="rainbow-m-left_x-small rainbow-m-right_small rainbow-m-top_medium rainbow-m-bottom_medium"
                        onChange={handleChange}
                        variant="inverse"
                    />
                </RainbowThemeContainer>
                <Divider className="rainbow-m-top_xx-small rainbow-m-bottom_medium" />
                <Bar selectedItem={activeSection}>
                    <VerticalSection>
                        <StyledOption
                            label="Functions"
                            name="functions"
                            onClick={() => navigate(`/app/project/${projectId}/functions/`)}
                            icon={<IconBar as={Function} />}
                        />
                        <StyledOption
                            label="Authentication"
                            name="auth"
                            onClick={() => navigate(`/app/project/${projectId}/auth/`)}
                            icon={<IconBar as={UsersFilled} />}
                        />
                        <StyledOption
                            label="Secrets"
                            name="secrets"
                            onClick={() => navigate(`/app/project/${projectId}/secrets`)}
                            icon={<IconBar as={KeyFilled} />}
                        />
                    </VerticalSection>
                </Bar>
            </Content>
        </Container>
    );
}
