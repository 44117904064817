import { useHistory } from 'react-router-dom';
import { showAppSpinner, hideAppSpinner } from '@rainbow-modules/app';
import { useQueryClient } from 'react-query';
import firebaseCallable from '../services/firebaseCallable';
import { checkPassed } from '../../helpers/projectRequirements';

const useCheckProject = (projectId) => {
    const queryClient = useQueryClient();
    const { push } = useHistory();

    const checkProject = async ({ projectId: id, spinnerMessage, redirectTo }) => {
        const projectIdentifier = id || projectId;
        try {
            showAppSpinner({ message: spinnerMessage });
            const data = await queryClient.fetchQuery(
                ['projects-checkRequirements', projectIdentifier],
                () => firebaseCallable('projects-checkRequirements', {
                    projectId: projectIdentifier,
                }),
                { retry: 0 },
            );
            hideAppSpinner();

            const services = data && data.services;
            const billingEnabled = Boolean(
                data && data.billingInfo && data.billingInfo.billingEnabled,
            );
            const isAlreadyChecked = Boolean(data && data.isChecked);
            const areAllServicesEnabled = checkPassed(services);
            const areAllChecksSuccess = areAllServicesEnabled && billingEnabled;

            if (isAlreadyChecked || areAllChecksSuccess) {
                const url = redirectTo || `/app/project/${projectIdentifier}`;
                return push(url);
            }

            return push(`/app/project/${projectIdentifier}/requirements`, {
                billingEnabled,
                services,
            });
        } catch (error) {
            hideAppSpinner();
            return push(`/app/project/${projectIdentifier}/setup`);
        }
    };

    return checkProject;
};

export default useCheckProject;
