import PropTypes from 'prop-types';
import { RenderIf, Input } from 'react-rainbow-components';
import { composeValidators, isRequired } from '@rainbow-modules/forms';
import { DocumentPath } from '@rainbow-modules/icons';
import Label from '../../LabelWithDescription';
import { isDoc } from '../../../helpers/pathValidators';
import {
    ItemContainer, Item, StyledField, StyledDocumentIcon,
} from '../styled';

const Firestore = ({ resource, renderInput }) => (
    <>
        <RenderIf isTrue={!renderInput}>
            <ItemContainer>
                <Label
                    name="Document Path"
                    description="The document path that you'd like this function to listen to (e.g. 'users/{uid}')"
                    className="rainbow-m-left_medium"
                />
                <Item className="rainbow-p-left_small">
                    <StyledDocumentIcon as={DocumentPath} />
                    {resource}
                </Item>
            </ItemContainer>
        </RenderIf>
        <RenderIf isTrue={renderInput}>
            <StyledField
                id="resource"
                name="trigger.resource"
                component={Input}
                validate={composeValidators(
                    isRequired('Document Path is required'),
                    isDoc('Invalid firestore document path'),
                )}
                label={(
                    <Label
                        name="Document Path"
                        description="The document path that you'd like this function to listen to (e.g. 'users/{uid}')"
                    />
                )}
                required
                labelAlignment="left"
                placeholder="users/{userId}"
                icon={<DocumentPath />}
            />
        </RenderIf>
    </>
);

Firestore.propTypes = {
    resource: PropTypes.string,
    renderInput: PropTypes.bool,
};
Firestore.defaultProps = {
    resource: undefined,
    renderInput: false,
};

export default Firestore;
