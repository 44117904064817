import PropTypes from 'prop-types';

export default function Solid(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="14px"
            height="47px"
            viewBox="0 0 14 47"
        >
            <title>arrow</title>
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="flow-details/new-promote-setted-hover" transform="translate(-535.000000, -258.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M549,295 L541.99386,304.333333 L535,295 L549,295 Z M543,268.836409 L543,292.419731 L541,292.419731 L541,268.836409 L543,268.836409 Z M542,258 C544.209139,258 546,259.790861 546,262 C546,264.209139 544.209139,266 542,266 C539.790861,266 538,264.209139 538,262 C538,259.790861 539.790861,258 542,258 Z" id="arrow" />
                </g>
            </g>
        </svg>
    );
}

Solid.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Solid.defaultProps = {
    style: {},
    className: undefined,
};
