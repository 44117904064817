import styled from 'styled-components';
import {
    UsersFilled, Trash, Roles, Lock,
} from '@rainbow-modules/icons';
import { Avatar } from 'react-rainbow-components';
import CopyToClipboardButton from '@rainbow-modules/record/lib/components/CopyToClipboardButton';
import ButtonIconTooltip from '../../../components/ButtonIconTooltip';
import { DARK_WHITE } from '../../../colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    margin: 0 12px;
`;

export const Content = styled.div`
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const StyledUsersFilled = styled(UsersFilled)`
    width: 20px;
    height: 20px;
`;

export const MetadataTitle = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-left: 24px;
    font-weight: 800;
    margin-bottom: 4px;
`;

export const MetadataSubtitle = styled.h3`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-left: 24px;
`;

export const MetadataLoadingTitle = styled.div`
    margin-left: 24px;
    margin-bottom: 8px;
    width: 25%;
`;

export const MetadataLoadingSubTitle = styled.div`
    margin-left: 24px;
    margin-bottom: 8px;
    width: 50%;
`;

export const MetadataLoadingReactJson = styled.div`
    margin-left: 24px;
    margin-bottom: 8px;
    width: 97%;
    height: 40px;
    border-radius: 7px;
    overflow: hidden;
`;

export const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px 16px 8px 16px;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 32px 160px 32px;
`;

export const InfoContainer = styled.h3`
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0 0 32px 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    display: flex;
    justify-content: center;
    text-align: center;
`;

export const Info = styled.span`
    margin: 0 4px;
`;

export const Highlight = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const TrashIcon = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${(props) => props.theme.rainbow.palette.error.main};
`;

export const StyledAvatar = styled(Avatar)`
    margin-right: 5px;
`;

export const StyledButtonIconTooltip = styled(ButtonIconTooltip)`
    > svg {
        width: 21px !important;
        height: 21px !important;
    }
`;

export const StyledSpan = styled.span`
    background-color: ${DARK_WHITE};
    border-radius: 5px 50px 50px 5px;
    padding: 0;
    height: auto;
    width: max-content;
    display: inline-block;
`;

export const StyledCopyToClipboardButton = styled(CopyToClipboardButton)`
    margin-left: 7px;

    > svg {
        width: 15px !important;
        height: 15px !important;
    }
`;

export const RolesEmptyIcon = styled(Roles)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 12px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;

export const MessageIcon = styled(Lock)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;
