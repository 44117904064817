import PropTypes from 'prop-types';
import { RoleOptionContainer, RoleOptionTitle, RoleOptionDescription } from './styled';

const RoleOption = (props) => {
    const { title, description } = props;

    return (
        <RoleOptionContainer>
            <RoleOptionTitle>
                {title}
            </RoleOptionTitle>
            <RoleOptionDescription>
                {description}
            </RoleOptionDescription>
        </RoleOptionContainer>
    );
};

RoleOption.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default RoleOption;
