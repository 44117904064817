import { Spinner } from 'react-rainbow-components';
import useCallableQuery from '../../../data/hooks/useCallableQuery';
import getThirtyDaysRange from '../helpers/getThirtyDaysRange';
import InstallationSummary from '../installation/installationSummary';
import Usage from '../usage';
import useCurrentPlan from '../../../data/hooks/useCurrentPlan';
import CurrentPlanInfo from './components/PlanInfo';
import usePlanServiceCount from '../../../data/hooks/usePlanServiceCount';
import useWorkspaces from '../../../data/hooks/useWorkspaces';
import useTeamMembersSummary from '../../../data/hooks/useTeamMembersSummary';

const isActive = (project) => project.setup && !(project.active === false);

const getActiveProjects = (projects) => projects.filter((project) => isActive(project));

const PlanUsage = () => {
    const { today, dateThirtyDaysAgo } = getThirtyDaysRange();
    const [currentPlan, isLoadingCurrentPlan] = useCurrentPlan();
    const { data: installationSummary, isFetching: isSummaryLoading } = useCallableQuery(
        'functions-getInstallationSummary',
        {
            startPeriod: dateThirtyDaysAgo,
            endPeriod: today,
        },
    );

    const {
        isLoading: isLoadingProjectsService, count: projectsCount, limit: projectsLimit,
    } = usePlanServiceCount({ serviceName: 'projects' });
    const {
        isLoading: isLoadingWorkspacesService, count: workspacesCount, limit: workspacesLimit,
    } = usePlanServiceCount({ serviceName: 'workspaces' });
    const {
        isLoading: isLoadingTeamMembersService,
        count: teamMembersCount,
        limit: teamMembersLimit,
        hasService: hasTeamMembersService,
    } = usePlanServiceCount({ serviceName: 'teamMembers' });
    const {
        isLoading: isLoadingPushesService,
        count: pushesCount,
        limit: pushesLimit,
    } = usePlanServiceCount({ serviceName: 'pushes' });
    const [teamMembers, isLoadingTeamMembers] = useTeamMembersSummary();

    const { data: projects = [], isLoading: isLoadingProjects } = useCallableQuery('projects-list');
    const [{ owner: workspacesList = [] }, isLoadingWorkspaces] = useWorkspaces();
    const isLoading = isLoadingCurrentPlan
        || isLoadingProjectsService
        || isLoadingProjects
        || isLoadingWorkspacesService
        || isLoadingWorkspaces
        || isLoadingTeamMembersService
        || isLoadingTeamMembers
        || isLoadingPushesService;
    if (isLoading) {
        return <Spinner size="x-large" type="arc" variant="brand" />;
    }
    return (
        <>
            <CurrentPlanInfo product={currentPlan} />
            <Usage
                installations={(
                    <InstallationSummary data={installationSummary} isLoading={isSummaryLoading} />
                )}
                projects={{
                    count: projectsCount,
                    limit: projectsLimit,
                    actives: getActiveProjects(projects),
                }}
                workspaces={{
                    count: workspacesCount,
                    limit: workspacesLimit,
                    list: workspacesList,
                }}
                teamMembers={hasTeamMembersService ? {
                    count: teamMembersCount,
                    limit: teamMembersLimit,
                    list: teamMembers,
                } : undefined}
                pushes={{
                    count: pushesCount,
                    limit: pushesLimit,
                }}
            />
        </>
    );
};

export default PlanUsage;
