import useCallableQuery from './useCallableQuery';

const useCheckSecretsApi = ({ projectId }) => {
    const {
        data: isEnabled = false,
        isLoading,
        isError,
        error,
    } = useCallableQuery(
        ['secrets-isApiEnabled', projectId],
        {
            projectId,
        },
    );

    return {
        isLoading,
        isEnabled,
        isError,
        error,
    };
};

export default useCheckSecretsApi;
