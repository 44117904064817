/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    PermissionContainer,
    StyledGranted,
    StyledNotGranted,
    ItemDescription,
    ItemLabel,
    Line,
} from './styled';

// eslint-disable-next-line react/prop-types
const PermissionStatus = ({ granted }) => {
    if (granted) return <StyledGranted />;
    return <StyledNotGranted />;
};

const descriptionMap = {
    'cloudfunctions.functions.create': 'This permission will allow you to create Cloud Functions, and you must be assigned a role that contains this permission.',
    'cloudfunctions.functions.setIamPolicy': 'This permission will allow you to set IAM policies, and you must be assigned a role that contains this permission.',
};

const getVariant = (granted) => {
    if (granted) return 'success';
    return 'error';
};

// eslint-disable-next-line react/prop-types
const Permission = ({ permission, granted }) => {
    const description = descriptionMap[permission];
    return (
        <PermissionContainer>
            <Line variant={getVariant(granted)} />
            <PermissionStatus granted={granted} />
            <div>
                <ItemLabel>{permission}</ItemLabel>
                <RenderIf isTrue={description}>
                    <ItemDescription>{ description }</ItemDescription>
                </RenderIf>
            </div>
        </PermissionContainer>
    );
};

export default function PermissionsList(props) {
    const { permissions } = props;
    const list = permissions.map((permission, index) => {
        const key = `permission_${index}`;
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Permission key={key} {...permission} />;
    });
    return (
        <>
            {list}
        </>
    );
}

PermissionsList.propTypes = {
    permissions: PropTypes.array,
};

PermissionsList.defaultProps = {
    permissions: [],
};
