import { ArrowLeft } from '@rainbow-modules/icons';
import PropTypes from 'prop-types';
import {
    StyledLink,
} from './styled';

const BackButton = (props) => {
    const {
        label, to, style, className,
    } = props;

    return (
        <StyledLink to={to} style={style} className={className}>
            <ArrowLeft className="rainbow-m-right_small" />
            {label}
        </StyledLink>
    );
};

export default BackButton;

BackButton.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
};

BackButton.defaultProps = {
    to: undefined,
    label: undefined,
    style: undefined,
    className: undefined,
};
