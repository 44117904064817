import PropTypes from 'prop-types';
import { RenderIf, LoadingShape } from 'react-rainbow-components';
import { Icon, StyledFunctionIcon, StyledIconContainer } from './styled';
import GoogleCloudLogo from '../Icons/googleCloudLogo';

const FunctionIcon = (props) => {
    const {
        url, functionType, width, height, isLoading,
    } = props;
    const loadingStyles = {
        width,
        height,
    };

    if (isLoading) {
        return (
            <StyledIconContainer style={loadingStyles}>
                <LoadingShape shape="square" />
                <StyledFunctionIcon />
            </StyledIconContainer>
        );
    }

    return (
        <>
            <RenderIf isTrue={functionType === 'marketplace'}>
                <RenderIf isTrue={url}>
                    <Icon as="img" src={url} width={width} height={height} />
                </RenderIf>
                <RenderIf isTrue={!url}>
                    <Icon width={width} height={height} />
                </RenderIf>
            </RenderIf>
            <RenderIf isTrue={functionType !== 'marketplace'}>
                <Icon as={GoogleCloudLogo} width={width} height={height} />
            </RenderIf>
        </>
    );
};

export default FunctionIcon;

FunctionIcon.propTypes = {
    url: PropTypes.string,
    functionType: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    isLoading: PropTypes.bool,
};

FunctionIcon.defaultProps = {
    url: undefined,
    functionType: undefined,
    width: '64px',
    height: '64px',
    isLoading: false,
};
