import PropTypes from 'prop-types';

export default function GoogleAnalitics(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="155px" height="170px" viewBox="0 0 155 170">
            <g id="functions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="analitics" transform="translate(-0.000020, -0.000011)">
                    <path d="M153.946641,85.2386018 C153.946641,106.344947 153.961057,127.451789 153.941174,148.558134 C153.929741,160.715619 144.441554,170.057169 132.29401,169.934126 C121.978191,169.827023 112.524799,160.780736 111.807519,150.315795 C111.745384,149.409128 111.733454,148.496994 111.733454,147.587345 C111.729975,105.788222 111.725004,63.9886018 111.734448,22.1899761 C111.736934,11.6057363 118.342577,3.04061938 128.352197,0.565677864 C140.990852,-2.5594391 153.913337,7.68480067 153.936203,20.9259118 C153.974478,42.3633094 153.946641,63.8012042 153.946641,85.2386018" id="Fill-1" fill="#F8AA01" />
                    <path d="M98.197925,117.066032 C98.197925,127.498166 98.25658,137.931295 98.1840069,148.362932 C98.0840946,162.787084 84.8116677,172.931909 71.2181297,168.966236 C64.3669309,166.967494 60.3679543,162.02954 58.0789192,155.519833 C56.7512291,151.744043 56.1597087,147.818634 56.1542408,143.828605 C56.1283929,124.371061 56.1278958,104.914511 56.1477788,85.4569673 C56.1597087,74.025213 65.7950303,64.2079615 77.0284514,64.1223449 C88.6063461,64.034482 98.1084514,73.4794235 98.1840069,85.2720551 C98.2516093,95.8697159 98.197925,106.468371 98.197925,117.066032" id="Fill-4" fill="#E27401" />
                    <path d="M21.0865712,169.971127 C9.47089873,169.966154 -0.0371714426,160.435219 2.82555006e-14,148.833961 C0.0368928849,137.263523 9.63692212,127.804166 21.2898753,127.854665 C32.7698461,127.905073 42.2505771,137.438494 42.2297343,148.910014 C42.2088227,160.591301 32.7877408,169.976096 21.0865712,169.971127" id="Fill-6" fill="#E27401" />
                </g>
            </g>
        </svg>
    );
}

GoogleAnalitics.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

GoogleAnalitics.defaultProps = {
    style: {},
    className: undefined,
};
