import {
    LoadingShape,
} from 'react-rainbow-components'; import {
    LoadingTabsetContainer, LoadingTabContainer,
} from './styled';

const TabsetLoading = () => (
    <LoadingTabsetContainer>
        <LoadingTabContainer>
            <LoadingShape />
        </LoadingTabContainer>
        <LoadingTabContainer width="120px">
            <LoadingShape />
        </LoadingTabContainer>
        <LoadingTabContainer width="200px">
            <LoadingShape />
        </LoadingTabContainer>
    </LoadingTabsetContainer>
);

export default TabsetLoading;
