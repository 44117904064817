import { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Column, RenderIf } from 'react-rainbow-components';
import { ColoredStatusColumn, filterByFields } from '@rainbow-modules/listview';
import useCallableQuery from '../../../data/hooks/useCallableQuery';
import AlignLeftContent from '../../../components/AlignLeftContent';
import AlignRightContent from '../../../components/AlignRightContent';
import FunctionNameColumn from './columns/functionName';
import FunctionLastDeployedColumn from './columns/functionLastDeployed';
import FunctionActionsColumn from './columns/functionActions';
import TriggerComponent from '../../../components/TriggerComponent';
import formatData from './helpers/formatData';
import logEvent from '../../../analytics/logEvent';
import events from '../../../analytics/events';
import {
    WHITE, RED, GREEN, YELLOW, LIGHT_GRAY, GRAY, BLUE, DARK_GRAY,
} from '../../../colors';
import {
    Container,
    StyledTable,
    StyledLink,
    StyledMessage,
    Highlight,
    MessageIcon,
    MarketplaceButton,
    ErrorMessage,
    Line,
    ErrorIcon,
    ErrorMessageLabel,
    ErrorMessageDescription,
    StyledInputSearch,
} from './styled';

const colors = {
    CREATED: { backgroundColor: BLUE, color: WHITE },
    BUILDING: { backgroundColor: YELLOW, color: WHITE },
    INSTALLING: { backgroundColor: YELLOW, color: WHITE },
    ACTIVE: { backgroundColor: GREEN, color: WHITE },
    DEPLOY_IN_PROGRESS: { backgroundColor: YELLOW, color: WHITE },
    DELETE_IN_PROGRESS: { backgroundColor: RED, color: WHITE },
    CLOUD_FUNCTION_STATUS_UNSPECIFIED: { backgroundColor: LIGHT_GRAY, color: WHITE },
    OFFLINE: { backgroundColor: DARK_GRAY, color: WHITE },
    UNKNOWN: { backgroundColor: LIGHT_GRAY, color: GRAY },
};

export default function Installed() {
    const [searchValue, setSearchValue] = useState('');
    const { projectId } = useParams();
    const { push } = useHistory();
    const {
        data, isLoading: isLoadingFunctions, refetch, isError, error,
    } = useCallableQuery(
        ['functions-list', projectId],
        { projectId, pageSize: 2000 },
        { retry: 0 },
    );
    const functionsData = formatData(data);
    const hasFunctions = functionsData && functionsData.length > 0;

    const filteredData = useMemo(
        () => filterByFields({
            data: functionsData,
            query: searchValue,
            fields: ['name', 'trigger.title', 'region', 'runtime', 'availableMemoryMb', 'timeout', 'status', 'updateTime'],
        }),
        [searchValue, functionsData],
    );

    const onDeleteFunction = () => {
        logEvent(events.functions.UNINSTALLED);
        return refetch();
    };

    return (
        <Container>
            <RenderIf isTrue={isError && error.code === 'failed-precondition'}>
                <ErrorMessage>
                    <Line />
                    <ErrorIcon />
                    <div>
                        <ErrorMessageLabel>
                            The
                            <Highlight>&quot;Cloud Functions API&quot;</Highlight>
                            is being enabled.
                        </ErrorMessageLabel>
                        <ErrorMessageDescription>
                            Wait a few minutes for the action to propagate and reload this page. In
                            case the API is not enabled, visit
                            {' '}
                            <StyledLink
                                href={`https://console.developers.google.com/apis/api/cloudfunctions.googleapis.com/overview?project=${projectId}`}
                                target="blank"
                                rel="noopener"
                            >
                                this link
                            </StyledLink>
                        </ErrorMessageDescription>
                    </div>
                </ErrorMessage>
            </RenderIf>
            <RenderIf isTrue={!isError}>
                <RenderIf isTrue={isLoadingFunctions || hasFunctions}>
                    <StyledInputSearch value={searchValue} onChange={setSearchValue} variant="realtime" placeholder="Search" />
                    <StyledTable
                        keyField="id"
                        data={filteredData}
                        variant="listview"
                        isLoading={isLoadingFunctions}
                        pageSize={50}
                    >
                        <Column
                            header="Function Name"
                            field="name"
                            component={FunctionNameColumn}
                        />
                        <Column header="Trigger" field="trigger" component={TriggerComponent} />
                        <Column
                            header="Region"
                            field="region"
                            component={AlignLeftContent}
                            width={190}
                        />
                        <Column
                            header="Runtime"
                            field="runtime"
                            width={100}
                            component={AlignLeftContent}
                        />
                        <Column
                            header="Memory"
                            field="availableMemoryMb"
                            width={100}
                            component={AlignRightContent}
                        />
                        <Column
                            header="Timeout"
                            field="timeout"
                            width={90}
                            component={AlignRightContent}
                        />
                        <Column
                            header="Status"
                            field="status"
                            width={130}
                            component={ColoredStatusColumn}
                            colors={colors}
                        />
                        <Column
                            header="Last Deployed"
                            field="updateTime"
                            width={200}
                            component={FunctionLastDeployedColumn}
                        />
                        <Column
                            component={FunctionActionsColumn}
                            onDelete={onDeleteFunction}
                            width={50}
                        />
                    </StyledTable>
                </RenderIf>
                <RenderIf isTrue={!isLoadingFunctions && !hasFunctions}>
                    <StyledMessage
                        icon={<MessageIcon />}
                        title="Start Installing Functions from the Marketplace"
                        action={(
                            <MarketplaceButton
                                variant="brand"
                                label="Go to Marketplace"
                                onClick={() => push('/app/marketplace/')}
                            />
                        )}
                    />
                </RenderIf>
            </RenderIf>
        </Container>
    );
}
