import PropTypes from 'prop-types';
import { Option, RenderIf } from 'react-rainbow-components';
import { GREEN, WHITE } from '../../../colors';
import {
    StyledBuildIcon, OptionContainer, OptionDateContainer, StyledBadge, BuildIdContainer,
} from './styled';
import formatDate from '../../../helpers/formatDate';

const badgeStyles = { color: GREEN, backgroundColor: WHITE, border: `1px solid ${GREEN}` };

// eslint-disable-next-line react/prop-types
const OptionLabelDate = ({ value }) => (
    <OptionDateContainer>
        {formatDate(value)}
    </OptionDateContainer>
);

// eslint-disable-next-line react/prop-types
const OptionLabel = ({ buildId, isPublished, createdAt }) => (
    <OptionContainer>
        <BuildIdContainer>
            {`${buildId}`}
            <RenderIf isTrue={isPublished}>
                <StyledBadge
                    label="Published"
                    size="small"
                    style={badgeStyles}
                />
            </RenderIf>
        </BuildIdContainer>
        <OptionLabelDate value={createdAt} />
    </OptionContainer>
);

const BuildsOptions = ({ builds }) => builds?.map((build) => {
    const { buildId, isPublished, createdAt } = build;
    return (
        <Option
            key={buildId}
            name={buildId}
            label={
                <OptionLabel buildId={buildId} isPublished={isPublished} createdAt={createdAt} />
            }
            icon={<StyledBuildIcon />}
            value={build}
            searchableText={buildId}
        />
    );
});

const BuildPickerOptions = ({ builds }) => (
    <>
        <Option name="header" label="Builds" variant="header" />
        <BuildsOptions builds={builds} />
    </>
);

BuildPickerOptions.propTypes = {
    builds: PropTypes.array.isRequired,
};

export default BuildPickerOptions;
