import { useDoc } from '@rainbow-modules/firebase-hooks';
import { isEmptyObject } from '@rainbow-modules/validation';
import useCallableQuery from './useCallableQuery';

const useInstalledFunction = (params) => {
    const {
        projectId, region, name,
    } = params;
    const { data = {}, isLoading: isLoadingCallable, isError } = useCallableQuery(
        ['functions-get', projectId, region, name],
        {
            projectId,
            region,
            functionId: name,
        },
    );
    const { environmentVariables } = data || {};
    const { FUNCTION_ID } = environmentVariables || {};
    const [installedFunction, isLoadingFirestore] = useDoc({
        path: `projects/${projectId}/functions/${FUNCTION_ID}`,
        disabled: !FUNCTION_ID,
        flat: true,
    });

    const isLoading = isLoadingFirestore
        || isLoadingCallable
        || (!installedFunction && !!FUNCTION_ID);

    const hasData = data && !isEmptyObject(data);

    return {
        isLoading,
        installedFunction,
        details: hasData ? data : undefined,
        isError,
    };
};

export default useInstalledFunction;
