import PropTypes from 'prop-types';
import getCreditCardType from '../../../helpers/getCreditCardType';
import { PaymentMethodContainer, Icon } from '../styled';

const PaymentMethod = (props) => {
    const { value } = props;
    const { brand, last4 } = value;
    const { icon } = getCreditCardType(brand);

    return (
        <PaymentMethodContainer>
            <Icon as={icon} />
            <div className="rainbow-m-left_small">
                ••••
                {' '}
                {last4}
            </div>
        </PaymentMethodContainer>
    );
};

PaymentMethod.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string, PropTypes.object,
    ]),
};

PaymentMethod.defaultProps = {
    value: undefined,
};

export default PaymentMethod;
