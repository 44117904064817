import styled from 'styled-components';
import { WHITE } from '../../colors';

export const Container = styled.div`
    display: flex;
    font-size: 12px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Label = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    border-radius: 3px 0 0 3px;
    background-image: linear-gradient(to bottom, #5f5f5f, #4c4c4c);
    color: ${WHITE};
    padding: 0 8px;
`;

export const Value = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 20px;
    border-radius: 0 3px 3px 0;
    background-image: linear-gradient(to bottom, #a5a1a1, #847d7c);
    color: ${WHITE};
    padding: 0 6px;

    ${(props) => props.value < 0.75
        && `
            background-image: linear-gradient(to bottom, #dab432, #c7a217);
        `};

    ${(props) => props.value > 0.75
        && `
            background-image: linear-gradient(to bottom, #7bbf91, #649c76);
        `};

    ${(props) => props.value < 0.5
        && `
            background-image: linear-gradient(to bottom, #dc6851, #c9543d);
        `};
`;
