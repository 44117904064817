import styled from 'styled-components';
import { Table } from 'react-rainbow-components';
import { InputSearch } from '@rainbow-modules/search';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    height: 100%;
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 24px;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Subtitle = styled.h2`
    font-size: 16px;
    letter-spacing: 0.25px;
    margin: 12px 0 4px 0;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Description = styled.p`
    font-size: 12px;
    margin: 0 0 16px 0;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledInputSearch = styled(InputSearch)`
    width: 50%;
`;

export const MemberColumnContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0;
`;

export const Name = styled.p`
    margin: 0;
    line-height: 16px;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-align: left;
`;

export const Email = styled.p`
    margin: 4px 0 0 0;
    line-height: 14px;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-align: left;
`;

export const RoleColumnContainer = styled.div`
    display: flex;
    height: 100%;
    margin: 0 16px;
    align-items: center;
`;

export const ActionsColumnContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
`;

export const FormContainer = styled.div`
    margin: 16px;
`;

export const RoleOptionContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const RoleOptionTitle = styled.h4`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    line-height: 16px;
`;

export const RoleOptionDescription = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const StyledTable = styled(Table)`
    th > div,
    td > div {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const BoldText = styled.span`
    font-family: 'Lato Black', Arial, sans-serif;
`;
