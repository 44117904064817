import PropTypes from 'prop-types';
import { useState } from 'react';
import { ChevronRight } from '@rainbow-modules/icons';
import { RenderIf } from 'react-rainbow-components';
import FeatureUsage from '../../../components/FeatureUsage';
import {
    FeatureCardContainer,
    FeatureHeader,
    FeatureIcon,
    FeatureTitle,
    FeatureDescription,
    ProgressContainer,
    StyledButtonIcon,
} from './styled';

const FeatureCard = (props) => {
    const {
        title,
        description,
        children,
        icon,
        count,
        limit,
        featureName,
        featureCounter,
        featureSummaryCounter,
    } = props;
    const [active, setActive] = useState(false);
    const toggleAccordion = () => {
        setActive(!active);
    };
    const isMeteredFeature = featureName && limit;
    const hasChildren = Boolean(children);

    return (
        <FeatureCardContainer>
            <FeatureHeader>
                {icon}
                <div>
                    <FeatureTitle>
                        {title}
                    </FeatureTitle>
                    <FeatureDescription>
                        {description}
                    </FeatureDescription>
                </div>
            </FeatureHeader>
            <RenderIf isTrue={isMeteredFeature}>
                <ProgressContainer>
                    <StyledButtonIcon
                        className="rainbow-m-right_xx-small"
                        icon={<ChevronRight />}
                        size="small"
                        onClick={toggleAccordion}
                        active={active}
                        disabled={!hasChildren}
                    />
                    <FeatureUsage
                        className="rainbow-m-right_small"
                        feature={featureName}
                        counter={featureCounter}
                        summaryCounter={featureSummaryCounter}
                        count={count}
                        limit={limit}
                    />
                </ProgressContainer>
            </RenderIf>
            <RenderIf isTrue={active || !isMeteredFeature}>
                {children}
            </RenderIf>
        </FeatureCardContainer>
    );
};

FeatureCard.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node,
    icon: PropTypes.node,
    count: PropTypes.number,
    limit: PropTypes.number,
    featureName: PropTypes.string,
    featureCounter: PropTypes.string,
    featureSummaryCounter: PropTypes.string,
};

FeatureCard.defaultProps = {
    title: undefined,
    description: undefined,
    children: undefined,
    icon: <FeatureIcon />,
    count: undefined,
    limit: undefined,
    featureName: undefined,
    featureCounter: undefined,
    featureSummaryCounter: undefined,
};

export default FeatureCard;
