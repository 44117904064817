import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { StyledBadge, StyledSpinner } from './styled';

export default function StatusBadge(props) {
    const { className, style, value } = props;
    return (
        <StyledBadge
            className={className}
            style={style}
            value={value}
            size="small"
        >
            <RenderIf isTrue={value === 'BUILDING' || value === 'INSTALLING' || value === 'DEPLOY_IN_PROGRESS'}>
                <StyledSpinner type="arc" size="xx-small" variant="inverse" />
            </RenderIf>
            {value}
        </StyledBadge>
    );
}

StatusBadge.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.string,
};

StatusBadge.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
};
