import useWorkspaces from './useWorkspaces';

const useUserWorkspaceAccess = (workspace) => {
    const [{ owner, invited }, isLoading] = useWorkspaces();
    const canAccess = [...owner, ...invited]
        .some((workspaceData) => workspaceData.id === workspace);
    return { canAccess, isLoading };
};

export default useUserWorkspaceAccess;
