import PropTypes from 'prop-types';
import StyledValue from './styled';

const TextValue = ({ children }) => {
    const newValue = children || 'None';
    return <StyledValue>{newValue}</StyledValue>;
};

TextValue.propTypes = {
    children: PropTypes.string,
};

TextValue.defaultProps = {
    children: undefined,
};

export default TextValue;
