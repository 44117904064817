import PropTypes from 'prop-types';
import { Badge } from 'react-rainbow-components';
import { RoleColumnContainer } from './styled';

const RoleColumn = ({ value }) => (
    <RoleColumnContainer>
        <Badge label={value} />
    </RoleColumnContainer>
);

RoleColumn.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default RoleColumn;
