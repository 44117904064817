import { useState } from 'react';
import {
    Container,
    Title,
    StyledButton,
    GoogleIcon,
    LeftContent,
    RightContent,
    TermsConditions,
    StyledLink,
    StyledLogo,
    StyledLogoLarge,
    WelcomeMessage,
    SloganText,
} from './styled';

const CLOUD_FUNCTIONS_URL = process.env.REACT_APP_CLOUD_FUNCTIONS_URL;

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const loginWithGoogle = async () => {
        setIsLoading(true);
        const redirectTo = window.location.origin;
        const googleAuthUrl = `${CLOUD_FUNCTIONS_URL}/auth-authenticate?redirectTo=${redirectTo}`;
        window.location.href = googleAuthUrl;
    };
    return (
        <Container>
            <LeftContent>
                <a href="https://www.functions.store/">
                    <StyledLogoLarge />
                </a>
                <WelcomeMessage>
                    Welcome to
                    {' '}
                    <br />
                    Function Store!
                </WelcomeMessage>
                <SloganText>
                    Save time writing functions by installing proven Google Cloud Functions.
                </SloganText>
            </LeftContent>
            <RightContent>
                <Title>
                    {' '}
                    <StyledLogo />
                    Function Store
                </Title>
                <StyledButton
                    variant="border-filled"
                    onClick={loginWithGoogle}
                    shaded
                    isLoading={isLoading}
                >
                    <GoogleIcon />
                    Sign in with Google
                </StyledButton>
                <TermsConditions>
                    By signing up, you have read and agree to our
                    {' '}
                    <StyledLink href="https://www.functions.store/terms/terms-of-use">Terms of use</StyledLink>
                    {' '}
                    and our
                    {' '}
                    <StyledLink href="https://www.functions.store/terms/privacy">Privacy Policy</StyledLink>
                    .
                </TermsConditions>
            </RightContent>
        </Container>
    );
};

export default Login;
