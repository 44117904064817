import PropTypes from 'prop-types';
import FormattedCurrency from '../FormattedCurrency';

const PayButtonLabel = ({ price, label }) => (
    <>
        {label}
        {' '}
        <FormattedCurrency value={price} />
    </>
);

PayButtonLabel.propTypes = {
    price: PropTypes.number,
    label: PropTypes.string,
};

PayButtonLabel.defaultProps = {
    price: 0,
    label: 'Pay',
};

export default PayButtonLabel;
