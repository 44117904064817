import styled from 'styled-components';
import { ErrorCircleFilled } from '@rainbow-modules/icons';
import { RED, GREEN } from '../../colors';

export const StyledContainer = styled.span`
    font-size: 14px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.rainbow.palette.text.main};

    ${(props) => props.variant === 'error'
        && `
            color: ${RED};
        `};

    ${(props) => props.variant === 'warning'
        && `
            color: ${props.theme.rainbow.palette.warning.main};
        `};

    ${(props) => props.variant === 'success'
        && `
            color: ${GREEN};
        `};
`;

export const Icon = styled(ErrorCircleFilled)`
    width: 14px;
    height: 14px;
    margin-right: 6px;
`;
