import PropTypes from 'prop-types';
import {
    Table, Column, RenderIf, ButtonIcon,
} from 'react-rainbow-components';
import { ArrowLink } from '@rainbow-modules/icons';
import { ColoredStatusColumn } from '@rainbow-modules/listview';
import PaymentMethod from '../columns/paymentMethod';
import FormattedDate from '../columns/formattedDate';
import BillingCurrency from '../columns/billingCurrency';
import { WHITE, RED, GREEN } from '../../../colors';
import { ColumnHeader, HelpTextInformation } from '../styled';

const colors = {
    SUCCEEDED: { backgroundColor: GREEN, color: WHITE },
    INCOMPLETE: { backgroundColor: RED, color: WHITE },
};

// eslint-disable-next-line react/prop-types
const ButtonAction = ({ row }) => {
    // eslint-disable-next-line react/prop-types
    const { receipt_url: receiptUrl } = row;
    const openReceipt = () => {
        window.open(receiptUrl, '_blank', 'noopener');
    };
    return <ButtonIcon variant="base" icon={<ArrowLink />} size="medium" onClick={openReceipt} />;
};

const Transactions = ({ data, isLoading }) => {
    const existData = data.length > 0;
    return (
        <div>
            <RenderIf isTrue={isLoading || existData}>
                <Table
                    keyField="id"
                    data={data}
                    variant="listview"
                    emptyDescription="Our robots did not find any transaction."
                    isLoading={isLoading}
                >
                    <Column header="ID" field="id" cellAlignment="left" />
                    <Column header={<ColumnHeader>Date</ColumnHeader>} field="created" width="200" component={FormattedDate} />
                    <Column header={<ColumnHeader>Payment Method</ColumnHeader>} field="payment_method_details.card" component={PaymentMethod} width="145" />
                    <Column header={<ColumnHeader>Description</ColumnHeader>} field="description" cellAlignment="left" />
                    <Column header={<ColumnHeader>Amount</ColumnHeader>} field="amount" component={BillingCurrency} width="80" />
                    <Column header={<ColumnHeader>Status</ColumnHeader>} field="status" component={ColoredStatusColumn} colors={colors} width="120" />
                    <Column component={ButtonAction} width="50" />
                </Table>
            </RenderIf>
            <RenderIf isTrue={!isLoading && !existData}>
                <HelpTextInformation>
                    There are no transactions to show.
                </HelpTextInformation>
            </RenderIf>
        </div>
    );
};

Transactions.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
};

Transactions.defaultProps = {
    data: undefined,
    isLoading: true,
};

export default Transactions;
