import { useCallback } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import useCallableMutation from './useCallableMutation';
import useMutationFlow from './useMutationFlow';

const useDeleteFunction = ({ onSuccess = () => {} }) => {
    const { mutateAsync: deleteFunction } = useCallableMutation('functions-delete');

    const { mutate: deleteFunctionFlow } = useMutationFlow({
        mutation: deleteFunction,
        submitSpinnerMessage: 'Sending function delete request',
        successMessage: null,
        onSuccess,
    });

    const remove = useCallback(async ({
        region, name, projectId, children,
    }) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Uninstall Function',
            okButtonLabel: 'Uninstall',
            children,
        });
        if (result) {
            await deleteFunctionFlow({ region, functionId: name, projectId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return remove;
};

export default useDeleteFunction;
