import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { ButtonIcon } from 'react-rainbow-components';
import { Close } from '@rainbow-modules/icons';
import UpgradePlanButton from '../UpgradePlanButton';
import {
    Container,
    LeftContent,
    RightContent,
    UpgradeIcon,
    Title,
    Description,
} from './styled';

const UpgradeToast = (props) => {
    const {
        title, description, className, isOpen, onRequestClose, serviceName,
    } = props;

    if (isOpen) {
        return createPortal(
            <Container className={className} data-cy="workspace-info">
                <LeftContent>
                    <UpgradeIcon className="rainbow-m-right_small" />
                    <div className="rainbow-m-right_small">
                        <Title>
                            {title}
                        </Title>
                        <Description>
                            {description}
                        </Description>
                    </div>
                </LeftContent>
                <RightContent>
                    <UpgradePlanButton />
                    <ButtonIcon icon={<Close />} onClick={() => onRequestClose(serviceName)} className="rainbow-m-left_small" />
                </RightContent>
            </Container>,
            document.body,
        );
    }
    return null;
};

UpgradeToast.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    serviceName: PropTypes.string.isRequired,
};

UpgradeToast.defaultProps = {
    title: undefined,
    description: undefined,
    className: undefined,
};

export default UpgradeToast;
