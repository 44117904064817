import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Field } from 'react-final-form';
import { Modal } from 'react-rainbow-components';
import {
    QuestionFilled, Open, DocumentPath, Lock, Memory,
} from '@rainbow-modules/icons';
import { WHITE } from '../../colors';

export const StyledText = styled.p`
    width: 210px;
`;
export const StyledAnchor = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 24px;
    margin-bottom: 10px;
`;

export const Title = styled.h1`
    font-size: 24px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

export const Highlight = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 0 8px;
`;

export const Content = styled.div`
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    position: relative;
`;

export const FormContainer = styled.div`
    box-sizing: border-box;
`;

export const FormProject = styled.div`
    box-sizing: border-box;
    padding-bottom: 16px;
`;

export const PickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    > div {
        padding-right: 20px;
    }
`;

export const TopGradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.3) 100%);
    height: 36px;
    position: absolute;
    pointer-events: none;
    top: 130px;
    right: 0;
    left: 0;
    z-index: 100;
`;

export const BottomGradient = styled.div`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 100%);
    height: 40px;
    position: absolute;
    pointer-events: none;
    bottom: 77px;
    right: 0;
    left: 0;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    padding: 20px 12px 0 12px;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 16px;
`;

export const LoadingSmall = styled.div`
    width: 20%;
    margin-bottom: 16px;
    height: 18px;
`;

export const LoadingHigh = styled.div`
    height: 40px;
    width: 40%;
    margin-top: 24px;
`;

export const StyledLink = styled(Link)`
    margin-top: 16px;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const NotificationDescription = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 8px;
`;

export const StyledNotificationLink = styled.a`
    font-size: 14px;
    padding: 0 8px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const FormHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: stretch;
    padding: 12px 0;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 20px;
    align-items: center;
    position: sticky;
    top: 0;
    background: ${WHITE};
    z-index: 10;
`;

export const FormTitle = styled.h2`
    flex: 1;
    font-size: 22px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const EnvironmentsFieldsContainer = styled.div`
    max-width: 90%;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 90%;
`;

export const Item = styled.div`
    display: flex;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    line-height: 40px;
    height: 40px;
    background: rgba(246, 247, 249, 0.7);
    border-radius: 40px;
    padding: 0 20px;
    align-items: center;
`;

export const StyledField = styled(Field)`
    max-width: 90%;
    margin-bottom: 24px;
`;

export const StyledDocumentIcon = styled(DocumentPath)`
    width: 18px;
    height: 18px;
    margin-right: 8px;
    margin-left: 2px;
    color: ${(props) => props.theme.rainbow.palette.border.main};
`;

export const StyledIcon = styled(QuestionFilled)`
    width: 22px;
    height: 22px;
    margin-right: 8px;
    color: ${(props) => props.theme.rainbow.palette.border.main};
`;

export const MessageLink = styled.a`
    color: inherit;
    text-decoration: underline;

    :hover,:active,:visited {
        color: inherit;
    }
`;

export const AddPolicyModalContainer = styled(Modal)`
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;

    > div {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: auto;
        height: 100%;
    }
`;

export const DocsLink = styled.a`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;
    text-decoration: underline;
    text-align: right;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const OpenIcon = styled(Open)`
    color: inherit;
    width: 14px;
    height: 14px;
    margin-left: 8px;
`;

export const StyledTriggerIcon = styled(QuestionFilled)`
    width: 20px !important;
    height: 20px !important;
`;

export const SmallDocsLink = styled(DocsLink)`
    display: unset;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: unset;
    font-size: unset;
    margin-left: 3px;
`;

export const Bottom = styled.div`
    height: 32px;
`;

export const MessageIcon = styled(Lock)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 16px;
    width: 80px;
    height: 80px;
    margin-right: 8px;
`;

export const StyledMemoryIcon = styled(Memory)`
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const PolicyContainer = styled.div`
    max-width: 90%;
`;
