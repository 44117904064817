import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import SpinnerMessage from './message';
import { Container, StyledSpinner } from './styled';

export default function GlobalSpinner({ message }) {
    return (
        <Container>
            <StyledSpinner type="arc" size="x-large" variant="brand" />
            <RenderIf isTrue={!!message}>
                <SpinnerMessage message={message} />
            </RenderIf>
        </Container>
    );
}

GlobalSpinner.propTypes = {
    message: PropTypes.node,
};

GlobalSpinner.defaultProps = {
    message: undefined,
};
