import PropTypes from 'prop-types';
import getIconProvider from '../../../../helpers/getIconProvider';
import getUserProviders from '../../../../helpers/getUserProviders';
import { Icon } from '../styled';

const ProviderColumn = ({ value, row }) => {
    const provider = getUserProviders(value, row.isAnonymous);
    const icon = getIconProvider(provider);

    return <Icon value={value} as={icon} />;
};

ProviderColumn.propTypes = {
    value: PropTypes.array.isRequired,
    row: PropTypes.object.isRequired,
};

export default ProviderColumn;
