import typesArray from './typesArray';
import normalizeColor from './normalizeColor';

const getNormalizedEnvFormValue = (env) => Object.entries(env).reduce(
    (newEnv, [key, objValue]) => {
        if (objValue.type && String(objValue.type).toLowerCase() === 'json') {
            return {
                ...newEnv,
                [key]: JSON.stringify(objValue.value, null, 1),
            };
        }
        if (typesArray.includes(objValue.type)) {
            return {
                ...newEnv,
                [key]: objValue.value,
            };
        }
        if (objValue.type === 'color') {
            return {
                ...newEnv,
                [key]: normalizeColor(objValue.value),
            };
        }
        const { type, value } = objValue;
        return {
            ...newEnv,
            [key]: {
                type: type || 'text',
                value,
            },
        };
    },
    {},
);

export default getNormalizedEnvFormValue;
