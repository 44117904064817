import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import useInstalledFunction from '../../../data/hooks/useInstalledFunction';
import getFunctionName from '../../../helpers/getFunctionName';
import getFunctionRegion from '../../../helpers/getFunctionRegion';
import getFunctionType from '../../../helpers/getFunctionType';
import FunctionIcon from '../../../components/FunctionIcon';
import Configuration from './configuration';
import Documentation from './documentation';
import Details from './details';
import Logs from './logs';
import Header from './header';
import TabsetInstalledFunctionDetails from './tabset';
import TabsetLoading from './tabsetLoading';
import { Container, Content, StyledFloatingBar } from './styled';

const tabContentMap = {
    configuration: Configuration,
    docs: Documentation,
    details: Details,
    logs: Logs,
};

export default function InstalledFunctionDetails() {
    const { projectId, region, name: funcName } = useParams();
    const { push } = useHistory();
    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });
    const [selected, setSelected] = useState('details');
    const TabContent = tabContentMap[selected];
    const {
        installedFunction, details, isLoading, isError,
    } = useInstalledFunction({
        projectId,
        region,
        name: funcName,
    });
    const installedPath = `/app/project/${projectId}/functions/installed`;
    if (isError) {
        push(installedPath);
        return null;
    }
    const {
        status, iconUrl, docs, policies, isPreview, buildId,
        workspace,
    } = installedFunction || {};
    const {
        name, status: gcpStatus, environmentVariables, description,
    } = details || {};
    const functionName = getFunctionName(name);
    const functionRegion = getFunctionRegion(name);
    const functionStatus = status || gcpStatus;
    const functionType = getFunctionType(environmentVariables);
    const handleSelect = (event, value) => {
        setSelected(value);
    };
    return (
        <Container onScroll={handleScrollEvent}>
            <StyledFloatingBar isVisible={showBar}>
                <Header
                    projectId={projectId}
                    functionId={installedFunction && installedFunction.id}
                    iconUrl={iconUrl}
                    icon={(
                        <FunctionIcon
                            url={iconUrl}
                            functionType={functionType}
                            isLoading={isLoading}
                            width="35px"
                            height="35px"
                        />
                    )}
                    type={functionType}
                    isLoading={isLoading}
                    region={functionRegion}
                    name={functionName}
                    status={functionStatus}
                    environmentVariables={environmentVariables}
                    isPreview={isPreview}
                />
                <TabsetInstalledFunctionDetails
                    onSelect={handleSelect}
                    activeTabName={selected}
                    details={details}
                    installedFunction={installedFunction}
                />
            </StyledFloatingBar>
            <Header
                projectId={projectId}
                functionId={installedFunction && installedFunction.id}
                iconUrl={iconUrl}
                icon={(
                    <FunctionIcon
                        url={iconUrl}
                        functionType={functionType}
                        isLoading={isLoading}
                        width="52px"
                        height="52px"
                    />
                )}
                type={functionType}
                isLoading={isLoading}
                region={functionRegion}
                name={functionName}
                status={functionStatus}
                description={description}
                environmentVariables={environmentVariables}
                isPreview={isPreview}
                buildId={buildId}
                workspace={workspace}
            />
            <Content>
                <RenderIf isTrue={isLoading}>
                    <TabsetLoading />
                </RenderIf>
                <RenderIf isTrue={functionType === 'marketplace'}>
                    <TabsetInstalledFunctionDetails
                        onSelect={handleSelect}
                        activeTabName={selected}
                        details={details}
                        installedFunction={installedFunction}
                    />
                </RenderIf>
                <TabContent
                    docs={docs}
                    details={details}
                    installedFunction={installedFunction}
                    isLoading={isLoading}
                    iconUrl={iconUrl}
                    status={functionStatus}
                    policies={policies}
                />
            </Content>
        </Container>
    );
}
