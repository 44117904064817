import {
    isFirestorePath,
    isFirestoreDocument,
    isFirestoreCollection,
} from '@rainbow-modules/validation';

export const isPath = (message) => (value) => (isFirestorePath(value) ? undefined : message);

export const isCollection = (message) => (value) => (
    isFirestoreCollection(value) ? undefined : message
);

export const isDoc = (message) => (value) => (isFirestoreDocument(value) ? undefined : message);
