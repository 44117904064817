import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { isEmpty } from '@rainbow-modules/validation';
import useCallableQuery from './useCallableQuery';
import firebaseCallable from '../services/firebaseCallable';

export default function useProjectsUsers({ projectId }) {
    const queryClient = useQueryClient();
    const { data, isLoading: isLoadingUsers, isFetching } = useCallableQuery(['users-list', projectId], { projectId });
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState(false);
    const [searchResults, setSearchResults] = useState(null);

    const search = async (searchTerm) => {
        if (searchTerm === searchValue) return;
        try {
            setIsSearching(true);
            setSearchValue(searchTerm);
            if (isEmpty(searchTerm)) {
                setSearchResults(null);
            } else {
                const result = await queryClient.fetchQuery(
                    ['users-list', projectId, searchTerm],
                    () => firebaseCallable('users-list', { projectId, searchTerm }),
                );
                setSearchResults(result.users);
            }
        } catch (error) {
            // No catch
        }
        setIsSearching(false);
    };

    const isLoading = isLoadingUsers || isSearching || isFetching;
    const users = (data && data.users) || [];
    const usersList = searchResults || users;

    return {
        users: usersList,
        isLoading,
        search,
        searchValue,
    };
}
