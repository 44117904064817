/* eslint-disable react/prop-types */
import Card from './card';
import { UpgradeContainer } from './styled';
import useProducts from '../../data/hooks/useProducts';
import useCreateSubscriptionSession from '../../data/hooks/useCreateSubscriptionSession';
import useCustomerPortal from '../../data/hooks/useCustomerPortal';
import productComparer from './helpers/productComparer';
import LoadingProduct from './loadingProducts';

const ProductCard = ({ product, hasSubscription, onSelect }) => {
    const {
        name: productName,
        prices = [],
        description,
        metadata: { color, freemium, custom },
        features,
        current,
    } = product;
    const [price = {}] = prices;
    const period = price?.recurring?.interval;
    const currency = price?.currency;

    return (
        <Card
            description={description}
            features={features}
            plan={productName}
            price={price.unit_amount}
            onSelect={() => onSelect(price.id)}
            period={period}
            currency={currency}
            color={color}
            isCurrentPlan={hasSubscription ? current : Boolean(freemium)}
            custom={custom}
        />
    );
};

const UpgradeAccount = () => {
    const [products, isLoadingProducts] = useProducts({ hideInternalPlans: true });
    const openCustomerPortal = useCustomerPortal();
    const openCreateSubscriptionPortal = useCreateSubscriptionSession();
    if (isLoadingProducts) {
        return (
            <UpgradeContainer>
                <LoadingProduct />
                <LoadingProduct />
                <LoadingProduct />
                <LoadingProduct />
            </UpgradeContainer>
        );
    }
    const hasSubscription = products.some((product) => product.current);

    const handleSelect = (priceId) => {
        if (hasSubscription) {
            return openCustomerPortal();
        }
        return openCreateSubscriptionPortal({ priceId });
    };

    return (
        <UpgradeContainer>
            {
                [...products].sort(productComparer)
                    .map((product) => (
                        <ProductCard
                            product={product}
                            hasSubscription={hasSubscription}
                            onSelect={handleSelect}
                        />
                    ))
            }
        </UpgradeContainer>
    );
};

export default UpgradeAccount;
