import logEvent from './logEvent';
import events from './events';

const isSecretUsed = (env) => Object.values(env).some((envVar) => envVar.type === 'secret');

const logSecretsUse = (env) => {
    if (isSecretUsed(env || {})) {
        logEvent(events.secrets.USED);
    }
};

export default logSecretsUse;
