import { useCallback } from 'react';
import { useUpdateDoc } from '@rainbow-modules/firebase-hooks';
import { useMutationFlow } from '@rainbow-modules/hooks';

const useRemoveDeployment = () => {
    const { mutate: updateDoc } = useUpdateDoc();
    const { mutate: removeDoc } = useMutationFlow({
        mutation: updateDoc,
        successMessage: 'The flow was removed successfully',
    });
    return useCallback(
        async ({ id, workspace }) => removeDoc({ path: `workspaces/${workspace}/deployments/${id}`, data: { removed: true } }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};

export default useRemoveDeployment;
