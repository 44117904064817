import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import InternalTooltip from 'react-rainbow-components/components/InternalTooltip';
import getFunctionType from '../../../../helpers/getFunctionType';
import FunctionLogo from './functionLogo';
import FunctionLink from './functionLink';
import { TextTooltip, FunctionNameContainer } from './styled';

const FunctionNameColumn = (props) => {
    const { value, row } = props;
    const { environmentVariables = {}, region, name } = row;
    const { projectId } = useParams();
    const triggerRef = useRef();
    const tooltipRef = useRef();
    const [isVisible, setVisible] = useState(false);
    const functionType = getFunctionType(environmentVariables);
    const tooltipText = functionType === 'marketplace' ? 'Marketplace function' : 'Firebase function';
    const to = `/app/project/${projectId}/functions/installed/${region}/${name}`;

    const onMouseEnter = () => {
        setVisible(true);
    };

    const onMouseLeave = () => {
        setVisible(false);
    };

    return (
        <>
            <FunctionNameContainer
                className="rainbow-flex rainbow-align_center"
            >
                <div
                    ref={triggerRef}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <FunctionLogo functionType={functionType} />
                </div>
                <FunctionLink
                    to={to}
                    value={value}
                />
            </FunctionNameContainer>
            <InternalTooltip
                triggerElementRef={triggerRef}
                isVisible={isVisible}
                ref={tooltipRef}
                preferredPosition="top"
            >
                <TextTooltip>
                    {tooltipText}
                </TextTooltip>
            </InternalTooltip>
        </>

    );
};

FunctionNameColumn.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    // to: PropTypes.string.isRequired,
    row: PropTypes.object,
};

FunctionNameColumn.defaultProps = {
    row: undefined,
};

export default FunctionNameColumn;
