/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { RecordPrimaryDetails } from '@rainbow-modules/record';
import useCallableQuery from '../../../data/hooks/useCallableQuery';
import { StyledRecordField, StyledModal } from './styled';
import StatusBadge from './statusBadge';
import SecretValue from './secretValue';
import getSecretValue from './helpers/getSecretValue';
import getSecretStatus from './helpers/getSecretStatus';
import getErrorMessage from './helpers/getErrorMessage';

export default function ShowSecretValueModal(props) {
    const {
        projectId, secretName, versionName, ...rest
    } = props;
    const {
        data, isLoading, isError, error,
    } = useCallableQuery(
        ['secrets-getValue', projectId, secretName, versionName],
        { projectId, secretName, versionName },
        { retry: 0, enabled: rest.isOpen },
    );

    if (secretName) {
        const state = getSecretStatus(data);
        const value = getSecretValue(data);
        const errorMessage = getErrorMessage(error, secretName);
        const isValueAvailable = Boolean(state === 'ENABLED' && !!value);

        return (
            <StyledModal {...rest}>
                <RenderIf isTrue={!isError}>
                    <RecordPrimaryDetails>
                        <StyledRecordField label="Secret Name" isLoading={isLoading} value={secretName} />
                        <StyledRecordField label="Version" isLoading={isLoading} value={versionName} />
                        <StyledRecordField
                            label="Status"
                            isLoading={isLoading}
                            component={StatusBadge}
                            value={state}
                        />
                    </RecordPrimaryDetails>
                    <div className="rainbow-p-vertical_large">
                        <StyledRecordField
                            label="Secret value"
                            isLoading={isLoading}
                            component={SecretValue}
                            value={value}
                            disabled={!isValueAvailable}
                        />
                    </div>
                </RenderIf>
                <RenderIf isTrue={isError}>
                    <div className="rainbow-p-vertical_medium">
                        {errorMessage}
                    </div>
                </RenderIf>
            </StyledModal>
        );
    }

    return null;
}

ShowSecretValueModal.propTypes = {
    projectId: PropTypes.string,
    secretName: PropTypes.string,
    versionName: PropTypes.string,
};

ShowSecretValueModal.defaultProps = {
    projectId: undefined,
    secretName: undefined,
    versionName: 'latest',
};
