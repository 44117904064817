import PropTypes from 'prop-types';
import { UniversalForm, Field, isRequired } from '@rainbow-modules/forms';
import firebase from 'firebase';
import {
    Input, Textarea, Button, Drawer,
} from 'react-rainbow-components';
import { useAddDoc, useUpdateDoc } from '@rainbow-modules/firebase-hooks';
import { useMutationFlow } from '@rainbow-modules/hooks';
import { useParams } from 'react-router-dom';
import Label from '../../../../../components/LabelWithDescription';
import { FormContainer, FormHeader, FormFooter } from './styled';

const AddFlow = (props) => {
    const {
        isOpen, onRequestClose, flow,
    } = props;
    const isCreate = flow === undefined;
    const { workspace } = useParams();
    const { mutate: create } = useAddDoc(`/workspaces/${workspace}/deployments`);
    const { mutate: update } = useUpdateDoc();
    const { mutate: createFlow } = useMutationFlow({
        mutation: create,
        successMessage: 'The flow was created successfully',
    });
    const { mutate: updateFlow } = useMutationFlow({
        mutation: update,
        successMessage: 'The flow was updated successfully',
    });
    const submit = async (values) => {
        if (flow) {
            await updateFlow({
                path: `workspaces/${workspace}/deployments/${flow.id}`,
                data: { ...values, updatedAt: firebase.firestore.FieldValue.serverTimestamp() },
            });
        } else {
            await createFlow({
                ...values,
                baseProject: { name: 'development' },
                targetProjects: [],
                removed: false,
                status: 'active',
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }
        onRequestClose();
    };
    const header = flow ? 'Edit Flow' : 'New Flow';
    return (
        <Drawer
            slideFrom="right"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <FormContainer>
                <div>
                    <FormHeader>
                        {header}
                    </FormHeader>
                    <UniversalForm id="add-flow" onSubmit={submit} initialValues={flow}>
                        <Field
                            name="name"
                            component={Input}
                            label={(
                                <Label
                                    name="Name"
                                    required
                                />
                            )}
                            placeholder="Enter the flow name"
                            required
                            labelAlignment="left"
                            className="rainbow-m-bottom_large"
                            validate={isOpen ? isRequired('The name of the deployment flow is required.') : undefined}
                        />
                        <Field
                            name="description"
                            component={Textarea}
                            label={(
                                <Label
                                    name="Description"
                                />
                            )}
                            placeholder="Enter the flow description"
                            labelAlignment="left"
                            className="rainbow-m-bottom_large"
                        />
                    </UniversalForm>
                </div>
                <FormFooter>
                    <Button
                        label="Cancel"
                        variant="base"
                        className="rainbow-m-right_medium"
                        onClick={onRequestClose}
                    />
                    <Button
                        label={isCreate ? 'Create' : 'Update'}
                        type="submit"
                        form="add-flow"
                        variant="brand"
                    />
                </FormFooter>
            </FormContainer>
        </Drawer>
    );
};

AddFlow.propTypes = {
    flow: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
    }),
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};

AddFlow.defaultProps = {
    flow: undefined,
};

export default AddFlow;
