import Step41 from './images/step41.png';
import {
    StyledImage,
    StepDescription,
    ItemContainer,
    Line,
    ItemLabel,
    StyledCopyToClipboardButton,
    ItemDescription,
    Highlight,
} from './styled';

const FastAssignRole = () => (
    <>
        <StyledImage
            src={Step41}
            width={600}
            height={600}
            className="rainbow-m-top_small"
        />
        <StepDescription className="rainbow-m-top_medium">
            Assign the
            <Highlight>Owner</Highlight>
            role to the service account:
        </StepDescription>
        <ItemContainer>
            <Line />
            <div>
                <ItemLabel>
                    Owner
                    <StyledCopyToClipboardButton value="Cloud Functions Admin" />
                </ItemLabel>
                <ItemDescription className="rainbow-m-bottom_xx-small rainbow-m-top_xx-small">
                    Full access to all resources.
                </ItemDescription>
            </div>
        </ItemContainer>
    </>
);

export default FastAssignRole;
