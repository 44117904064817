import { useMemo } from 'react';
import { useCollectionOnce } from '@rainbow-modules/firebase-hooks';

const useCategoriesOptions = () => {
    const [data, isLoading] = useCollectionOnce({
        path: '/configs/functions/tags',
        flat: true,
    });
    const resultData = useMemo(
        () => data.map((tag) => tag.name),
        [data],
    );
    return [resultData, isLoading];
};

export default useCategoriesOptions;
