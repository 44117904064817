import {
    DONE, SUCCEEDED, FAILED, IN_PROGRESS, QUEUED, SKIPPED, NOT_RUN,
} from '../../status';

const variantMap = {
    [DONE]: {
        [SUCCEEDED]: 'success',
        [FAILED]: 'error',
        [SKIPPED]: 'warning',
        [NOT_RUN]: 'default',
    },
    [IN_PROGRESS]: 'info',
    [QUEUED]: 'warning',
};

export default function getVariantForStatus(check = {}) {
    const { status, conclusion } = check;
    const mapResult = variantMap[status];
    return (status === DONE ? mapResult[conclusion] : mapResult);
}
