import {
    DONE, SKIPPED, SUCCEEDED,
} from '../../status';

const getJobMessages = (job) => {
    const { description } = job;
    if (typeof description === 'string') {
        return [{
            title: description,
            message: '',
        }];
    }

    if (Array.isArray(description)) {
        return description.map((item) => ({
            title: item,
            message: '',
        }));
    }
    return [];
};

export default function getDetailsForJob(job) {
    if (job) {
        const {
            status, conclusion, children, label: name,
        } = job;

        if (status === DONE && [SUCCEEDED, SKIPPED].includes(conclusion)) {
            return undefined;
        }

        if (children) {
            return children.map(
                (subCheck) => getDetailsForJob(subCheck),
            ).filter((details) => !!details);
        }

        return {
            name,
            status,
            conclusion,
            messages: getJobMessages(job),
        };
    }
    return undefined;
}
