import PropTypes from 'prop-types';
import { Tabset, Tab } from 'react-rainbow-components';

const FunctionTabset = ({ id, activeTabName, onSelect }) => (
    <Tabset
        variant="line"
        onSelect={onSelect}
        activeTabName={activeTabName}
        id={id}
    >
        <Tab name="overview" label="Overview" />
        <Tab name="source" label="Source Code" />
        <Tab name="test" label="Test Coverage" />
        <Tab name="pricing" label="Pricing" />
        <Tab name="terms" label="Terms of Use" />
        <Tab name="zip" label="ZIP" />
    </Tabset>
);

FunctionTabset.propTypes = {
    activeTabName: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    id: PropTypes.string,
};

FunctionTabset.defaultProps = {
    id: undefined,
};

export default FunctionTabset;
