import styled from 'styled-components';
import { ButtonIcon } from 'react-rainbow-components';
import Project from '../../../components/Icons/project';
import { GRAY } from '../../../colors';

export const FeatureCardContainer = styled.article`
    margin-left: 5px;
    border-radius: 14px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 8px;
`;

export const FeatureHeader = styled.header`
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${(props) => props.theme.rainbow.palette.border.disabled};
    padding-bottom: 16px;
    padding-left: 16px;
    width: 100%;
`;

export const FeatureIcon = styled(Project)`
    width: 32px;
    height: 32px;
    color: ${GRAY};
    margin-right: 12px;
`;

export const FeatureTitle = styled.h4`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const FeatureDescription = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ProgressContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 16px;
`;

export const UsageDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 28px 16px 56px;
`;

export const UsageDetailsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 4px;
    margin-bottom: 8px;
    border-bottom: 1px dashed ${(props) => props.theme.rainbow.palette.border.divider};
    font-size: 13px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    flex-shrink: 0;
    transition: transform 0.1s ease;
    transform: ${(props) => (props.active === true ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

export const UsageItemContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
`;

export const UsageItemLeft = styled.div`
    display: flex;
    align-items: center;
`;

export const Counter = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const UsageItemTitle = styled.h4`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const UsageItemDescription = styled.h4`
    font-size: 11px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const UsageItemIcon = styled(Project)`
    width: 24px;
    height: 24px;
    color: ${GRAY};
    margin-right: 12px;
`;

export const UsageDetailsFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    padding-top: 10px;
    margin-top: 8px;
    border-top: 1px dashed ${(props) => props.theme.rainbow.palette.border.divider};
`;
