import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import { isEmpty } from '@rainbow-modules/validation';
import firebaseCallable from '../../data/services/firebaseCallable';
import PayButtonLabel from '../PayButtonLabel';
import { INSTALL_FUNCTION_DRAWER, BUY_FUNCTION_MODAL } from '../../constants';
import Loading from './loading';
import ProjectPicker, { INSTALL_MANUALLY_ON_GCP } from '../ProjectPicker';
import FunctionDrawerHeader from '../FunctionDrawerHeader';
import useFunction from '../../data/hooks/useFunction';
import useVersions from '../../data/hooks/useVersions';
import useMutationFlow from '../../data/hooks/useMutationFlow';
import usePurchasedFunction from '../../data/hooks/usePurchasedFunction';
import Form from './form';
import FunctionIcon from '../FunctionIcon';
import Label from '../LabelWithDescription';
import logEvent from '../../analytics/logEvent';
import logSecretsUse from '../../analytics/logSecretsUse';
import events from '../../analytics/events';
import getTriggerInitialValue from './helpers/getTriggerInitialValue';
import getEnvInitialValues from './helpers/getEnvInitialValues';
import getVersionInitialValue from './helpers/getVersionInitialValue';
import VersionsPicker from '../VersionsPicker';
import getVersionLabel from '../../helpers/getVersionLabel';
import getRuntimeInitialValues from './helpers/getRuntimeInitialValues';
import RegionPicker from '../RegionPicker';
import {
    Content,
    Header,
    Footer,
    Container,
    BottomGradient,
    FormProject,
    FormHeader,
    FormTitle,
    PickerContainer,
    SmallDocsLink,
} from './styled';

const FORM_ID = 'install-function';
const DEFAULT_INSTALL_REGION = 'us-central1';

export default function FunctionInstallMode(props) {
    const { functionId, workspace, version } = props;
    const [projectId, setProjectId] = useState('');
    const [isLoadingBuyButton, setIsLoadingBuyButton] = useState(false);
    const [projectError, setProjectError] = useState();
    const [lastVersionFunction, isLoadingFunction] = useFunction(functionId, workspace);
    const [versionsData, isLoadingVersions] = useVersions(functionId, workspace);
    const [selectedVersion, setSelectedVersion] = useState();
    const [region, setRegion] = useState(DEFAULT_INSTALL_REGION);

    const resolvedFunctionData = useMemo(
        () => versionsData.find((item) => item.version === selectedVersion?.name),
        [versionsData, selectedVersion],
    );

    const { price } = lastVersionFunction || {};
    const {
        name, description, env, iconUrl, trigger,
    } = resolvedFunctionData || lastVersionFunction || {};

    const initialValues = {
        ...getTriggerInitialValue(trigger),
        env: getEnvInitialValues(env),
        runtimeConfig: getRuntimeInitialValues(),
    };
    const { push } = useHistory();
    const [, closeModal] = useOpenModal(INSTALL_FUNCTION_DRAWER);
    const [openBuyModal] = useOpenModal(BUY_FUNCTION_MODAL);
    const { mutate } = useMutationFlow({
        mutation: (config) => {
            logSecretsUse(config.env);
            return firebaseCallable('functions-install', {
                functionId,
                workspaceName: workspace,
                ...config,
            });
        },
        submitSpinnerMessage: 'Sending install request',
        successMessage: null,
        onSuccess: (res) => {
            const { id: installedFunctionId, projectId: project } = res;
            logEvent(events.functions.INSTALLED);
            closeModal();
            push(`/app/project/${project}/functions/installed/${installedFunctionId}`, {
                iconUrl,
            });
        },
    });

    const handleInstallClick = () => {
        if (isEmpty(projectId)) {
            setProjectError('Project is required');
        }
    };

    const handleBuyClick = () => {
        openBuyModal({
            title: 'Review your order',
            submitButtonLabel: <PayButtonLabel price={price} label="Pay" />,
            functionData: lastVersionFunction,
            onSubmitModal: () => setIsLoadingBuyButton(true),
        });
    };

    const handleSwitchProject = ({ name: projectName }) => {
        setProjectError();
        setProjectId(projectName);
        setRegion(DEFAULT_INSTALL_REGION);
    };

    const [isPurchased, isLoadingPurchased] = usePurchasedFunction({ functionId, workspace });
    const isLoading = Boolean(isLoadingFunction || isLoadingPurchased || isLoadingVersions);

    useEffect(() => {
        if (isPurchased) {
            setIsLoadingBuyButton(false);
        }
    }, [isPurchased]);

    useEffect(() => {
        setSelectedVersion(getVersionInitialValue(version, lastVersionFunction));
    }, [version, lastVersionFunction]);

    const handleVersionChange = (params) => {
        const {
            value: { version: versionValue, isLastVersion },
        } = params;
        const label = isLastVersion ? getVersionLabel(versionValue) : `v ${versionValue}`;
        setSelectedVersion({ name: versionValue, label });
    };
    const isInstallManuallyPicked = projectId === INSTALL_MANUALLY_ON_GCP;

    const handleDownloadZip = () => {
        closeModal();
        push(`/app/marketplace/${workspace}/${functionId}#zip`, {
            selectedVersion,
        });
    };

    return (
        <Container>
            <Header>
                <FunctionDrawerHeader
                    name={name}
                    description={description}
                    icon={(
                        <FunctionIcon
                            url={iconUrl}
                            functionType="marketplace"
                            width="48px"
                            height="48px"
                        />
                    )}
                    title="Install function"
                />
            </Header>
            <Content>
                <FormProject>
                    <FormHeader>
                        <FormTitle>Project and Region</FormTitle>
                    </FormHeader>
                    <PickerContainer>
                        <ProjectPicker
                            label={(
                                <Label
                                    name="Select Project"
                                    description={(
                                        <>
                                            Where do you want to install this function?
                                            You can only install functions
                                            in projects that are already set up.
                                            <SmallDocsLink
                                                title=""
                                                href="https://docs.functions.store/functions/reference/projects/how-to-set-up-a-project"
                                                target="_blank"
                                            >
                                                How to set up projects?
                                            </SmallDocsLink>
                                        </>
                                    )}
                                />
                            )}
                            className="rainbow-m-top_x-small"
                            value={projectId}
                            onChange={handleSwitchProject}
                            hideAllProjectsOption
                            showCheckedProjectsOnly
                            error={projectError}
                            showInstallManuallyOnGcp
                        />
                        <RegionPicker
                            label={(
                                <Label
                                    name="Select Region"
                                    description={(
                                        <>
                                            Where do you want to deploy this function?
                                            Usually you want a region close to you. For help,
                                            see the
                                            <SmallDocsLink
                                                title=""
                                                href="https://cloud.google.com/functions/docs/locations"
                                                target="_blank"
                                            >
                                                Location selection guide
                                            </SmallDocsLink>
                                            .
                                        </>
                                    )}
                                />
                            )}
                            className="rainbow-m-top_x-small"
                            value={region}
                            onChange={setRegion}
                            projectId={projectId}
                            disabled={isInstallManuallyPicked || !projectId}
                        />
                    </PickerContainer>
                </FormProject>
                <FormProject>
                    <FormHeader>
                        <FormTitle>Function Version</FormTitle>
                    </FormHeader>
                    <PickerContainer data-cy="versions-picker">
                        <VersionsPicker
                            label={(
                                <Label
                                    name="Select Version to install"
                                    description="The version of the function you want to install. You can select to install any of the released versions."
                                />
                            )}
                            versions={versionsData}
                            lastVersionFunction={lastVersionFunction}
                            onChange={handleVersionChange}
                            value={selectedVersion}
                        />
                    </PickerContainer>
                </FormProject>
                <RenderIf isTrue={!!projectId && !isInstallManuallyPicked}>
                    <RenderIf isTrue={isLoading}>
                        <Loading />
                    </RenderIf>
                    <RenderIf isTrue={!isLoading}>
                        <Form
                            id={FORM_ID}
                            projectId={projectId}
                            fields={{ trigger, env }}
                            onSubmit={(values) => mutate({
                                projectId,
                                region,
                                ...values,
                                version: selectedVersion?.name,
                            })}
                            initialValues={initialValues}
                            version={selectedVersion?.name}
                            keepDirtyOnReinitialize
                        />
                    </RenderIf>
                </RenderIf>
            </Content>
            <BottomGradient />
            <Footer>
                <Button
                    label="Cancel"
                    variant="base"
                    className="rainbow-m-right_medium"
                    onClick={closeModal}
                />
                <RenderIf isTrue={!isLoading && isInstallManuallyPicked}>
                    <Button
                        label="Install from ZIP"
                        variant="brand"
                        type="button"
                        onClick={handleDownloadZip}
                    />
                </RenderIf>
                <RenderIf isTrue={!isLoading && isPurchased && !isInstallManuallyPicked}>
                    <Button
                        label="Install"
                        variant="brand"
                        type="submit"
                        form={FORM_ID}
                        onClick={handleInstallClick}
                    />
                </RenderIf>
                <RenderIf isTrue={!isLoading && !isPurchased && !isInstallManuallyPicked}>
                    <Button id="install-function-buy" variant="brand" onClick={handleBuyClick} isLoading={isLoadingBuyButton}>
                        <PayButtonLabel label="Buy" price={price} />
                    </Button>
                </RenderIf>
            </Footer>
        </Container>
    );
}

FunctionInstallMode.propTypes = {
    functionId: PropTypes.string,
    workspace: PropTypes.string,
    version: PropTypes.string,
};

FunctionInstallMode.defaultProps = {
    functionId: undefined,
    workspace: undefined,
    version: undefined,
};
