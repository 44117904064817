import {
    Avatar, Logout, UpDirection, MoneyBorder,
} from '@rainbow-modules/icons';
import { useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { useOpenModal } from '@rainbow-modules/hooks';
import { MenuItem, MenuDivider, RenderIf } from 'react-rainbow-components';
import app from '../../firebase';
import { BILLING_DRAWER } from '../../constants';
import useChangePlan from '../../data/hooks/useChangePlan';
import {
    StyledAvatarMenu,
    UserFullName,
    UserEmail,
    StyledAvatar,
    UserInfoContainer,
    UserInfo,
    StyledIcon,
} from './styled';

const UserMenu = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onShowPayout } = props;
    const {
        displayName, email, photoURL,
    } = useCurrentUser();
    const [openBillingDrawer] = useOpenModal(BILLING_DRAWER);
    const { isLoading: isLoadingSubscription, hasSubscription, changePlan } = useChangePlan();

    const accountLabel = hasSubscription ? 'Change Plan' : 'Upgrade Account';
    return (
        <StyledAvatarMenu
            menuSize="small"
            icon={<Avatar />}
            avatarBackgroundColor="transparent"
            src={photoURL}
            id="avatar-user-menu"
        >
            <UserInfoContainer>
                <StyledAvatar
                    src={photoURL}
                    icon={<Avatar />}
                    size="medium"
                    backgroundColor="transparent"
                />
                <UserInfo>
                    <UserFullName>{displayName}</UserFullName>
                    <UserEmail>{email}</UserEmail>
                </UserInfo>
            </UserInfoContainer>
            <MenuDivider variant="space" />
            <RenderIf isTrue={!isLoadingSubscription}>
                <MenuItem
                    label={accountLabel}
                    icon={<StyledIcon as={UpDirection} />}
                    onClick={changePlan}
                />
            </RenderIf>
            <MenuItem label="Billing" icon={<StyledIcon />} onClick={() => openBillingDrawer()} />
            <MenuItem label="Payouts" icon={<StyledIcon as={MoneyBorder} />} onClick={onShowPayout} />
            <MenuItem label="Logout" icon={<Logout />} onClick={() => app.auth().signOut()} />
        </StyledAvatarMenu>
    );
};

export default UserMenu;
