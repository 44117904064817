/* eslint-disable react/jsx-props-no-spreading */
import { Redirect, Route, Switch } from 'react-router-dom';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { StripeCheckoutModal } from '@rainbow-modules/payment';
import {
    BILLING_DRAWER, PAYMENT_METHOD_MODAL, UPGRADE_ACCOUNT, UPGRADE_TOAST,
} from '../constants';
import UpgradeToast from '../components/UpgradeToast';
import Billing from './billing';
import UpgradeAccount from './upgradeAccount';
import Projects from './projects';
import Developer from './developer';
import Project from './project';
import Workspace from './developer/workspace';
import ProjectRequirements from './projectRequirements';
import {
    BillingDrawer, AppContainer, ModalTitle, ModalDescription, ModalTitleContainer, StyledModal,
} from './styled';
import MainNavigationBar from '../layouts/MainNavigationBar';
import Marketplace from './marketplace';
import ProjectSetup from './projectSetup';
import AddProjects from './addProjects';

const UpgradeModalTitle = () => (
    <ModalTitleContainer>
        <ModalTitle>Choose the plan that&apos;s right for you</ModalTitle>
        <ModalDescription>Pay by month or year and cancel at any time.</ModalDescription>
    </ModalTitleContainer>
);

const UpgradeToastPortal = () => {
    const connectedUpgradeToast = useConnectModal(UPGRADE_TOAST);
    const [, closeUpgradeToast] = useOpenModal(UPGRADE_TOAST);

    const handleClose = (serviceName) => {
        closeUpgradeToast();
        localStorage.setItem(`can-show-upgrade-toast-${serviceName}`, false);
    };

    return (
        <UpgradeToast
            {...connectedUpgradeToast}
            onRequestClose={handleClose}
        />
    );
};

export default function AuthRoutes() {
    const connectedBillingProps = useConnectModal(BILLING_DRAWER, {});
    const connectedUpgradeAccountProps = useConnectModal(UPGRADE_ACCOUNT, {});
    const connectedPaymentProps = useConnectModal(PAYMENT_METHOD_MODAL, {});
    const [, closeBillingDrawer] = useOpenModal(BILLING_DRAWER);
    const [, closePaymentModal] = useOpenModal(PAYMENT_METHOD_MODAL);
    const [, closeUpgradeAccountModal] = useOpenModal(UPGRADE_ACCOUNT);

    return (
        <AppContainer>
            <MainNavigationBar />
            <Switch>
                <Route path="/app/marketplace" component={Marketplace} />
                <Route path="/app/projects" component={Projects} />
                <Route path="/app/addProjects" component={AddProjects} />
                <Route
                    path="/app/project/:projectId/requirements"
                    component={ProjectRequirements}
                />
                <Route path="/app/project/:projectId/setup" component={ProjectSetup} />
                <Route path="/app/developer/workspaces/:workspace" component={Workspace} />
                <Route path="/app/developer" component={Developer} exact />
                <Route path="/app/project/:projectId" component={Project} />
                <Redirect from="/" to="/app/marketplace" />
            </Switch>
            <BillingDrawer
                slideFrom="right"
                size="large"
                {...connectedBillingProps}
                onRequestClose={closeBillingDrawer}
            >
                <Billing />
            </BillingDrawer>
            <StyledModal {...connectedUpgradeAccountProps} onCancel={closeUpgradeAccountModal} size="large" title={<UpgradeModalTitle />}>
                <UpgradeAccount />
            </StyledModal>
            <StripeCheckoutModal
                id="checkout-modal"
                {...connectedPaymentProps}
                onCancel={closePaymentModal}
            />
            <UpgradeToastPortal />
        </AppContainer>
    );
}
