import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';

export default function ModalFooter({
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}) {
    return (
        <div className="rainbow-flex rainbow-justify_end">
            <Button
                className="rainbow-m-right_medium"
                label={secondaryButtonLabel}
                variant="outline-brand"
                onClick={onSecondaryButtonClick}
            />
            <Button
                variant="brand"
                label={primaryButtonLabel}
                onClick={onPrimaryButtonClick}
            />
        </div>
    );
}

ModalFooter.propTypes = {
    primaryButtonLabel: PropTypes.string,
    secondaryButtonLabel: PropTypes.string,
    onPrimaryButtonClick: PropTypes.func,
    onSecondaryButtonClick: PropTypes.func,
};

ModalFooter.defaultProps = {
    primaryButtonLabel: undefined,
    secondaryButtonLabel: undefined,
    onPrimaryButtonClick: () => {},
    onSecondaryButtonClick: () => {},
};
