import React from 'react';
import ReactDOM from 'react-dom';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import GlobalSpinner from './components/GlobalSpinner';
import App from './App';
import app from './firebase';
import reportWebVitals from './reportWebVitals';
import logEvent from './analytics/logEvent';
import { GREEN } from './colors';

const theme = {
    rainbow: {
        palette: {
            success: GREEN,
        },
    },
};

ReactDOM.render(
    <React.StrictMode>
        <RainbowFirebaseApp
            theme={theme}
            app={app}
            spinner={<GlobalSpinner />}
            onError={(error) => logEvent('app_exception', {
                message: error.toString(),
            })}
        >
            <App />
        </RainbowFirebaseApp>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
