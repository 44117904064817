export default {
    functions: {
        OPEN_INSTALL_DRAWER: 'functions.open_install_drawer',
        OPEN_RECONFIGURE_DRAWER: 'functions.open_reconfigure_drawer',
        INSTALLED: 'functions.installed',
        RECONFIGURED: 'functions.reconfigured',
        UNINSTALLED: 'functions.uninstalled',
        PURCHASED: 'functions.purchased',
    },
    marketplace: {
        FUNCTION_LEARN_MORE: 'marketplace.function_learn_more',
        FILTERS: 'marketplace.filters',
    },
    secrets: {
        CREATED: 'secrets.created',
        DELETED: 'secrets.deleted',
        USED: 'secret.used',
    },
    auth: {
        CREATE_ROLE: 'auth.create_role',
        CREATE_PERMISSION: 'auth.create_permission',
    },
    previews: {
        INSTALLED: 'previews.installed',
    },
    workspaces: {
        ADD_MEMBER: 'workspaces.add_member',
        REMOVE_MEMBER: 'workspaces.remove_member',
    },
};
