import styled from 'styled-components';

export const OptionLabel = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const OptionTyping = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-weight: 900;
`;

export const IconContainer = styled.span`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    display: flex;

    svg {
        width: 1rem !important;
        height: 1rem !important;
        font-size: 1rem !important;

        ${(props) => props.shaded && `
            background: ${props.theme.rainbow.palette.background.highlight};
            box-sizing: border-box;
            padding: 5px;
            border-radius: 100%;
            width: 28px !important;
            height: 28px !important;
            font-size: 28px !important;
        `};
    }
`;
