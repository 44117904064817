import PropTypes from 'prop-types';

export default function Build(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="19px"
            height="21px"
            viewBox="0 0 19 21"
        >
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="developer/workspace-CI" transform="translate(-83.000000, -140.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M92.6193153,140.890725 L101.715372,145.772908 C101.80809,145.822684 101.865949,145.919397 101.865949,146.024632 L101.865949,155.93529 C101.865949,156.042733 101.805674,156.141092 101.709943,156.189871 L92.6138865,160.824903 C92.57371,160.845374 92.5292588,160.856047 92.4841673,160.856047 C92.4382094,160.856047 92.392927,160.844982 92.3521624,160.82376 L83.4618281,156.188728 C83.3673486,156.139511 83.3081081,156.041821 83.3081081,155.93529 L83.3081081,146.024632 C83.3081081,145.920397 83.3650144,145.824474 83.4563993,145.774337 L92.3464479,140.890725 C92.4315275,140.844487 92.5342356,140.844487 92.6193153,140.890725 Z M101.294108,146.493047 L97.2411081,148.588047 L97.241489,153.759136 L96.6609593,154.064278 L96.6601081,154.063047 L92.7691081,156.107047 L92.7691081,160.102047 L101.294499,155.759569 L101.294108,146.493047 Z M83.8791081,146.505047 L83.8795581,155.762141 L92.1981081,160.097047 L92.1981081,156.104047 L87.6987682,153.611804 L87.6981081,153.482047 L87.6965588,153.481995 L87.6961081,148.584047 L83.8791081,146.505047 Z M92.4771081,151.341047 L88.6291081,153.473047 L92.4878818,155.610707 L96.4221081,153.543047 L92.4771081,151.341047 Z M92.7691081,146.412047 L92.7691081,150.850047 L96.6621081,153.022047 L96.6691081,148.582047 L92.7691081,146.412047 Z M92.1981081,146.447047 L88.2671081,148.584047 L88.2671081,153.020047 L92.1984423,150.843956 L92.1981081,146.447047 Z M92.485596,141.466175 L84.1881081,146.023047 L87.9811409,148.089567 L92.5359386,145.61668 L92.5381081,145.618047 L92.5420625,145.61668 L96.9611928,148.090424 L100.965108,146.019047 L92.485596,141.466175 Z" id="Combined-Shape" />
                </g>
            </g>
        </svg>
    );
}

Build.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Build.defaultProps = {
    style: {},
    className: undefined,
};
