import { StyledMemoryIcon } from '../styled';
import { memoryMap } from '../../../constants';

const getRuntimeInitialValues = (runtimeConfig) => {
    const { memory = 256, timeoutSeconds = 60, minInstances = 0 } = runtimeConfig || {};
    return {
        ...runtimeConfig,
        timeoutSeconds,
        minInstances,
        memory: {
            name: memory,
            label: memoryMap[memory],
            icon: <StyledMemoryIcon />,
        },
    };
};

export default getRuntimeInitialValues;
