import { ErrorCircleFilled, CheckCircleFilled, ClockFilled } from '@rainbow-modules/icons';
import getStatusLabel from './helpers/getStatusLabel';
import {
    FAILED, IN_PROGRESS, QUEUED, DONE, SUCCEEDED, SKIPPED,
} from '../status';
import {
    StatusBadgeContainer, StyledSpinner, SkipIcon,
} from './styled';

const statusIconMap = {
    [DONE]: {
        [SUCCEEDED]: <CheckCircleFilled />,
        [FAILED]: <ErrorCircleFilled />,
        [SKIPPED]: <SkipIcon />,
    },
    [IN_PROGRESS]: <StyledSpinner size="xx-small" type="arc" variant="inverse" />,
    [QUEUED]: <ClockFilled />,
};

// eslint-disable-next-line react/prop-types
const StatusBadge = ({ status, conclusion }) => {
    const mapResult = statusIconMap[status];
    const icon = status === DONE ? mapResult[conclusion] : mapResult;
    const label = getStatusLabel(status, conclusion);

    return (
        <StatusBadgeContainer label={label} size="small">
            {icon}
            {label}
        </StatusBadgeContainer>
    );
};

export default StatusBadge;
