/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Drawer, Modal } from 'react-rainbow-components';
import { LIGHT_BLACK } from '../colors';

export const BillingDrawer = styled(Drawer)`
    background: #fbfdfd;
`;

export const AppContainer = styled.main`
    height: 100%;
    display: flex;
    box-sizing: border-box;
    overflow: auto;
    background: ${LIGHT_BLACK};
`;

export const ModalTitle = styled.h2`
    font-size: 32px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const ModalDescription = styled.h3`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ModalTitleContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px 0 0;
`;

export const StyledModal = styled(Modal)`
    padding: 8px 24px 0 24px;
    width: fit-content;
    overflow: auto;
`;
