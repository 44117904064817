import PropTypes from 'prop-types';
import VersionsPickerOptions from '../VersionsPickerOptions';
import StyledPicklist from './styled';

const VersionsPicker = ({
    versions, lastVersionFunction, onChange, value, label,
}) => (
    <StyledPicklist
        onChange={onChange}
        value={value}
        placeholder="Select Version"
        enableSearch
        label={label}
        labelAlignment="left"
    >
        <VersionsPickerOptions versions={versions} lastVersionFunction={lastVersionFunction} />
    </StyledPicklist>
);

VersionsPicker.propTypes = {
    versions: PropTypes.array.isRequired,
    lastVersionFunction: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.node.isRequired,
};

export default VersionsPicker;
