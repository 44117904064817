import { useMemo } from 'react';
import { useCollection, useDoc } from '@rainbow-modules/firebase-hooks';
import useUserWorkspaceAccess from './useUserWorkspaceAccess';
import useUserFunctionAccess from './usePurchasedFunction';

const composeBuilds = ({ publishedBuild, builds, workspace }) => {
    if (Array.isArray(builds) && builds.length > 0) {
        return builds.map(({ id, ...build }) => {
            const isPublishedBuild = publishedBuild?.buildId === id;
            if (isPublishedBuild) {
                return {
                    ...build,
                    ...publishedBuild,
                    isPublished: true,
                };
            }
            return {
                ...build,
                buildId: id,
                workspace,
            };
        });
    }
    if (publishedBuild) {
        return [publishedBuild];
    }
    return [];
};

const useUserInstallableBuilds = ({
    functionId,
    workspace,
    version,
}) => {
    const {
        canAccess: canAccessToWorkspace,
        isLoading: isLoadingWorkspaceAccess,
    } = useUserWorkspaceAccess(workspace);
    const [
        canAccessToFunction,
        isLoadingFunctionAccess,
    ] = useUserFunctionAccess({ functionId, workspace });

    const [publishedBuild, isLoadingPublishedBuild] = useDoc({
        path: `workspaces/${workspace}/marketplace/${functionId}/versions/${version}`,
        flat: true,
        disabled: !(functionId && workspace && version) || !canAccessToFunction,
    });
    const [builds, isLoadingBuilds] = useCollection({
        path: `workspaces/${workspace}/functions/${functionId}/versions/${version}/builds`,
        flat: true,
        query: (query) => query.orderBy('createdAt', 'desc'),
        disabled: !(functionId && workspace && version) || !canAccessToWorkspace,
    });
    const isLoading = isLoadingWorkspaceAccess
        || isLoadingFunctionAccess
        || isLoadingPublishedBuild
        || (canAccessToWorkspace && isLoadingBuilds);
    const data = useMemo(() => (
        isLoading ? [] : composeBuilds({ publishedBuild, builds, workspace })
    ), [publishedBuild, builds, workspace, isLoading]);
    return [data, isLoading];
};

export default useUserInstallableBuilds;
