import PropTypes from 'prop-types';
import { Button } from 'react-rainbow-components';
import {
    StyledCard,
    ProjectId,
    ProjectTitle,
    ProjectContent,
    ProjectIcon,
    ButtonCheckIcon,
} from './styled';

export default function ProjectCard({
    isNew, name, projectId, onCheck,
}) {
    const projectName = isNew ? `${name} (NEW)` : name;
    return (
        <StyledCard>
            <ProjectContent>
                <ProjectIcon />
                <div>
                    <ProjectTitle data-cy="project-title">{projectName}</ProjectTitle>
                    <ProjectId>{projectId}</ProjectId>
                </div>
            </ProjectContent>
            <Button size="small" variant={isNew ? 'brand' : 'outline-brand'} onClick={() => onCheck({ projectId, projectName })}>
                Check
                <ButtonCheckIcon isNew={isNew} />
            </Button>
        </StyledCard>
    );
}

ProjectCard.propTypes = {
    name: PropTypes.string,
    projectId: PropTypes.string,
    isNew: PropTypes.bool,
    onCheck: PropTypes.func,
};

ProjectCard.defaultProps = {
    name: undefined,
    projectId: undefined,
    isNew: false,
    onCheck: () => {},
};
