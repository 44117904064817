import { useParams } from 'react-router-dom';
import { Column, RenderIf } from 'react-rainbow-components';
import usePlanServiceCount from '../../../../../data/hooks/usePlanServiceCount';
import useUpgradeToast from '../../../../../data/hooks/useUpgradeToast';
import FormattedDate from '../../../../../components/FormattedDate';
import ElapsedTime from '../../../../../components/ElapsedTime';
import StatusColumn from './columns/status';
import PushLinkColumn from './columns/pushLink';
import AuthorColumn from './columns/author';
import ServiceLimiteInfo from '../../../../../components/ServiceLimiteInfo';
import useWorkspaceOwnership from '../../../../../data/hooks/useWorkspaceOwnership';
import {
    Container,
    Header,
    Title,
    StyledIcon,
    Link,
    StyledChevronRight,
    StyledTable,
} from './styled';

const PushList = () => {
    const { workspace } = useParams();
    const [isWorkspaceOwner] = useWorkspaceOwnership(workspace);

    const {
        isLoading: isLoadingServiceCount, count, limit, hasReachedLimit, product, hasService,
    } = usePlanServiceCount({ serviceName: 'pushes' });
    useUpgradeToast({
        hasReachedLimit,
        serviceName: 'pushes',
        isLoadingServiceCount,
    });

    return (
        <Container>
            <Header>
                <Title data-cy="page-title">Build History</Title>
                <RenderIf isTrue={isWorkspaceOwner && !isLoadingServiceCount && hasService}>
                    <ServiceLimiteInfo
                        label={`${count} of ${limit} Pushes`}
                        helpTextTitle="Pushes on your current plan"
                        featureUsageName="Pushes"
                        featureUsageSummaryCounter={`${count} of ${limit} pushes`}
                        featureUsageCounter={`${count} pushes`}
                        upgradeText="If you need more pushes you have to upgrade your plan."
                        limit={limit}
                        count={count}
                        product={product}
                    />
                </RenderIf>
            </Header>
            <StyledTable
                id="builds"
                className="rainbow-m-right_large rainbow-m-left_large"
                collection={`/workspaces/${workspace}/ci`}
                variant="listview"
                pageSize={20}
                query={(query) => query.orderBy('createdAt', 'desc')}
                emptyIcon={<StyledIcon />}
                emptyTitle="Start pushing your code from the CLI"
                emptyDescription={(
                    <div>
                        Here&apos;s a quick guide on how to get started.
                        <Link
                            href="https://docs.functions.store/functions/developers/start-developing"
                            className="rainbow-m-left_xx-small rainbow-m-top_x-small"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Getting Started
                            <StyledChevronRight className="rainbow-m-left_x-small" />
                        </Link>
                    </div>
                )}
            >
                <Column header="Build" field="id" headerAlignment="left" cellAlignment="left" component={PushLinkColumn} workspace={workspace} />
                <Column header="Status" field="status" width={160} component={StatusColumn} />
                <Column header="Author" field="author" width={280} component={AuthorColumn} />
                <Column header="Start Date" field="createdAt" width={220} component={FormattedDate} />
                <Column header="Duration" field="duration" width={130} component={ElapsedTime} />
            </StyledTable>
        </Container>
    );
};

export default PushList;
