import PropTypes from 'prop-types';
import PickerOption from './pickerOption';
import {
    PolicyLabel,
    StyledUniversalPicker,
} from './styled';

const PolicyPicker = (props) => {
    const {
        value, onChange, style, className, policies,
    } = props;

    return (
        <StyledUniversalPicker
            direction="vertical"
            labelAlignment="left"
            label={(
                <PolicyLabel>
                    Policies
                </PolicyLabel>
            )}
            value={value}
            onChange={onChange}
            style={style}
            className={className}
        >
            {
                policies.map((policy) => (
                    <PickerOption
                        key={`policy-${policy.name}`}
                        policyName={policy.name}
                        policyIcon={policy.iconUrl}
                    />
                ))
            }
        </StyledUniversalPicker>
    );
};

export default PolicyPicker;

PolicyPicker.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func,
    policies: PropTypes.array,
};

PolicyPicker.defaultProps = {
    style: {},
    className: undefined,
    onChange: () => {},
    value: undefined,
    policies: [],
};
