/* eslint-disable react/prop-types */
import { RenderIf } from 'react-rainbow-components';
import {
    Container, Name, Description, StyledHelpText,
} from './styled';

const Label = ({
    name, description, required, style, className, helpText,
}) => {
    const newTitle = typeof name === 'string' ? name : null;
    if (description) {
        return (
            <Container style={style} className={className}>
                <Name title={newTitle} data-cy="label-title">
                    {name}
                    <RenderIf isTrue={helpText}>
                        <StyledHelpText
                            variant="question"
                            text={helpText}
                        />
                    </RenderIf>
                </Name>
                <Description title={description} required={required} data-cy="label-description">
                    {description}
                </Description>
            </Container>
        );
    }
    return (
        <Name>
            {name}
        </Name>
    );
};

export default Label;
