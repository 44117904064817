import { useState } from 'react';
import BillingTabset from './tabset';
import PlanUsage from './planUsage';
import PaymentMethod from './paymentMethod';
import Payments from './payments';
import Overview from './overview';
import { BillingContainer, BillingContentContainer } from './styled';

const tabContentMap = {
    overview: Overview,
    payments: Payments,
    payment: PaymentMethod,
    usage: PlanUsage,
};

const Billing = () => {
    const [selected, setSelected] = useState('overview');

    const handleOnSelect = (event, value) => {
        setSelected(value);
    };

    const TabContent = tabContentMap[selected];

    return (
        <BillingContainer>
            <BillingTabset
                onSelect={handleOnSelect}
                activeTabName={selected}
            />
            <BillingContentContainer>
                <TabContent />
            </BillingContentContainer>
        </BillingContainer>
    );
};

export default Billing;
