import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import FormattedCurrency from '../../../../components/FormattedCurrency';
import {
    Description,
    HighLightedName,
    CardTitle,
    CardRow,
    AmountText,
    FreeBadge,
    PricingCard,
    TabContent,
} from '../styled';

const Pricing = ({ data }) => {
    const { name, price } = data;
    const isPaid = price > 0;

    return (
        <TabContent className="rainbow-m-top_medium">
            <Description>
                The
                {' '}
                <HighLightedName>
                    “
                    {name}
                    ”
                </HighLightedName>
                {' '}
                function will be available in all your projects once you buy it.
            </Description>
            <PricingCard>
                <CardTitle>Function Pricing Details</CardTitle>
                <CardRow>
                    <span>Function Cost</span>
                    <RenderIf isTrue={!isPaid}>
                        <FreeBadge size="small" label="free" className="rainbow-m-top_xx-small" />
                    </RenderIf>
                    <RenderIf isTrue={isPaid}>
                        <AmountText>
                            <FormattedCurrency
                                value={price}
                            />
                        </AmountText>
                    </RenderIf>
                </CardRow>
            </PricingCard>
        </TabContent>
    );
};

Pricing.propTypes = {
    data: PropTypes.object,
};

Pricing.defaultProps = {
    data: undefined,
};

export default Pricing;
