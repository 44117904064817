import PropTypes from 'prop-types';
import { RenderIf, Input } from 'react-rainbow-components';
import { isRequired } from '@rainbow-modules/forms';
import Label from '../../LabelWithDescription';
import {
    ItemContainer, Item, StyledField,
} from '../styled';

const Pubsub = ({ topic, renderInput }) => (
    <>
        <RenderIf isTrue={!renderInput}>
            <ItemContainer>
                <Label
                    name="Topic"
                    description="The topic that will be monitored to trigger your function"
                    className="rainbow-m-left_medium"
                />
                <Item>{topic}</Item>
            </ItemContainer>
        </RenderIf>
        <RenderIf isTrue={renderInput}>
            <StyledField
                id="topic"
                name="trigger.topic"
                component={Input}
                validate={isRequired('The topic is required')}
                label={(
                    <Label
                        name="Topic"
                        description="The topic that will be monitored to trigger your function"
                    />
                )}
                required
                initialValue={topic}
                labelAlignment="left"
                placeholder="SOME_RANDOM_TOPIC"
            />
        </RenderIf>
    </>
);

Pubsub.propTypes = {
    topic: PropTypes.string,
    renderInput: PropTypes.bool,
};

Pubsub.defaultProps = {
    topic: undefined,
    renderInput: false,
};

export default Pubsub;
