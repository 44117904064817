import { useCurrentUser, useDoc } from '@rainbow-modules/firebase-hooks';

const useServiceAccount = () => {
    const { uid } = useCurrentUser();
    const [doc, isLoading] = useDoc({
        path: `/users/${uid}`,
        flat: true,
    });
    return [doc && doc.serviceAccount, isLoading];
};

export default useServiceAccount;
