import { LoadingShape } from 'react-rainbow-components';
import {
    LoadingContainer,
    LoadingCircleShape,
} from './styled';

const randomSize = (min, max) => Math.floor(Math.random() * (max - min)) + min;

const shapeStyles = {
    title: {
        height: '15px',
    },
    description: {
        height: '10px',
        marginTop: '10px',
    },
};

const LoadingItem = () => (
    <div className="rainbow-flex rainbow-flex_row rainbow-m-bottom_large">
        <div>
            <LoadingCircleShape shape="circle" />
        </div>
        <div className="rainbow-m-left_small">
            <LoadingShape
                style={{
                    ...shapeStyles.title,
                    width: randomSize(100, 200),
                }}
            />
            <LoadingShape
                style={{
                    ...shapeStyles.description,
                    width: randomSize(300, 500),
                }}
            />
        </div>
    </div>
);

const PermisionsLoading = () => (
    <LoadingContainer>
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
    </LoadingContainer>
);

export default PermisionsLoading;
