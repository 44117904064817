import PropTypes from 'prop-types';
import { Avatar } from '@rainbow-modules/icons';
import { StyledAvatar, NameContainer } from './styled';
import TextValue from '../TextValue';

const UserColumn = (props) => {
    const {
        row: { photoURL },
        value,
    } = props;

    return (
        <NameContainer>
            <StyledAvatar
                src={photoURL}
                size="small"
                icon={<Avatar />}
                backgroundColor="transparent"
            />
            <TextValue>{value}</TextValue>
        </NameContainer>
    );
};

UserColumn.propTypes = {
    row: PropTypes.object,
    value: PropTypes.string,
};

UserColumn.defaultProps = {
    row: undefined,
    value: undefined,
};

export default UserColumn;
