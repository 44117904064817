import pick from 'lodash/pick';
import getReconfigInitialsEnvValues from './getReconfigInitialsEnvValues';
import getReconfigInitialsTriggerValues from './getReconfigInitialsTriggerValues';
import getRuntimeInitialValues from './getRuntimeInitialValues';

const getReconfigInitialsValues = ({ installedFunctionData, selectedBuild }) => {
    const initialValues = pick(installedFunctionData, ['trigger', 'env', 'auth', 'policies', 'runtimeConfig']);
    const formattedInitialEnv = getReconfigInitialsEnvValues(
        { envSchemas: selectedBuild?.env, initialEnvValues: initialValues?.env },
    );
    const formattedInitialRuntime = getRuntimeInitialValues(initialValues?.runtimeConfig);
    const formattedInitialTrigger = getReconfigInitialsTriggerValues(initialValues?.trigger);
    return {
        ...initialValues,
        env: formattedInitialEnv,
        runtimeConfig: formattedInitialRuntime,
        trigger: formattedInitialTrigger,
    };
};

export default getReconfigInitialsValues;
