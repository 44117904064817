import styled from 'styled-components';
import { Apps, SettingsFilled } from '@rainbow-modules/icons';
import Project from '../Icons/project';
import { GRAY, WHITE } from '../../colors';
import GoogleCloudLogo from '../Icons/googleCloudLogo';

export const StyledGoogleCloudLogo = styled(GoogleCloudLogo)`
    height: 18px;
    width: 18px;
`;

export const ProjectIcon = styled(Project)`
    width: 18px;
    height: 18px;
    color: ${GRAY};
`;

export const SettingsIcon = styled(SettingsFilled)`
    width: 18px;
    height: 18px;
    color: ${GRAY};
    opacity: 0.6;
`;

export const ProjectIconSelected = styled(Project)`
    width: 18px;
    height: 18px;

    ${(props) => props.variant === 'inverse' && `
        color: ${WHITE};
        opacity: 0.7;
    `}
`;

export const ProjectsList = styled(Apps)`
    width: 16px;
    height: 16px;
    color: ${GRAY};
`;

export const EmptyLabel = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: ${(props) => props.theme.rainbow.palette.getContrastText(props.theme.rainbow.palette.background.main)};
    padding: 36px;
`;
