import { useState } from 'react';
import firebaseCallable from '../services/firebaseCallable';

const useCheckProjectSAPermissions = (projectId) => {
    const [isLoading, setIsLoading] = useState(false);
    const [permissions, setPermissions] = useState();

    const checkPermissions = async (id) => {
        if (isLoading) return;
        try {
            setIsLoading(true);
            const projectIdentifier = id || projectId;
            const result = await firebaseCallable('projects-checkProjectPermissions', {
                projectId: projectIdentifier,
            });
            setPermissions(result);
        } catch (err) {
            // no catch
        }
        setIsLoading(false);
    };

    return {
        isLoading,
        checkPermissions,
        permissions,
    };
};

export default useCheckProjectSAPermissions;
