import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import SummaryError from '../../../../../components/SummaryError';
import getMessageForStatus from './helpers/getMessageForStatus';
import getVariantForStatus from './helpers/getVariantForStatus';
import getCountCheckResults from './helpers/getCountCheckResults';
import formatCheckResults from './helpers/formatCheckResults';
import { IN_PROGRESS } from '../status';
import Tiles from './tiles';
import {
    Container,
    TilesContainer,
    FailedIcon,
    StyledInfoCard,
} from './styled';

export default function BuildSummary({ status, conclusion, checksResults }) {
    const message = getMessageForStatus({ status, conclusion });
    const messageStatus = getVariantForStatus({ status, conclusion });
    const formattedCheckResults = formatCheckResults(checksResults);
    const countCheckResults = getCountCheckResults(formattedCheckResults);
    const failedChecks = formattedCheckResults.filter((item) => item.conclusion === 'failed');
    return (
        <Container>
            <RenderIf isTrue={status === IN_PROGRESS}>
                <StyledInfoCard
                    variant={messageStatus}
                    label={message}
                    size="small"
                    hideIcon
                />
            </RenderIf>
            <RenderIf isTrue={status !== IN_PROGRESS}>
                <TilesContainer>
                    <Tiles countCheckResults={countCheckResults} />
                </TilesContainer>
                {failedChecks.map((item, index) => (
                    <SummaryError
                        // eslint-disable-next-line react/no-array-index-key
                        key={`error-${index}`}
                        className="rainbow-m-right_medium rainbow-m-left_medium rainbow-m-bottom_x-small"
                        title={(
                            <div className="rainbow-flex rainbow-align_center">
                                <FailedIcon className="rainbow-m-right_x-small" />
                                {item.name}
                            </div>
                        )}
                        description={item.description}
                    />
                ))}
            </RenderIf>
        </Container>
    );
}

BuildSummary.propTypes = {
    status: PropTypes.oneOf(['done', 'queued', 'in_progress']),
    conclusion: PropTypes.oneOf(['success', 'failed', 'skipped', 'cancelled']),
    checksResults: PropTypes.array,
};

BuildSummary.defaultProps = {
    status: undefined,
    conclusion: undefined,
    checksResults: undefined,
};
