import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    Header,
    Description,
    ButtonLink,
    LeftContent,
    RightContent,
    Row,
    StyledInputSearch,
} from './styled';

const ProjectsHeader = ({
    query, onChangeQuery, description, title, id, showSearch, disableAddProject,
}) => (

    <Header id={id}>
        <LeftContent>
            <Row>
                {title}
                <RenderIf isTrue={showSearch}>
                    <StyledInputSearch
                        id="header-projects-search"
                        variant="realtime"
                        value={query}
                        placeholder="Search"
                        onChange={onChangeQuery}
                        className="rainbow-m-left_large"
                    />
                </RenderIf>
            </Row>
            <Description>
                {description}
            </Description>
        </LeftContent>
        <RightContent>
            <ButtonLink
                disabled={disableAddProject}
                to="/app/addProjects"
            >
                Add Project
            </ButtonLink>
        </RightContent>
    </Header>
);

ProjectsHeader.propTypes = {
    showSearch: PropTypes.bool,
    query: PropTypes.string,
    onChangeQuery: PropTypes.func,
    description: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    disableAddProject: PropTypes.bool,
    id: PropTypes.string,
};

ProjectsHeader.defaultProps = {
    id: undefined,
    query: undefined,
    onChangeQuery: () => {},
    showSearch: false,
    disableAddProject: false,
};

export default ProjectsHeader;
