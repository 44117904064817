import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Tab, Tabset } from 'react-rainbow-components';

const DeploymentsTabset = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { workspace } = useParams();
    const splittedPathName = pathname.split('/');
    const selected = splittedPathName[splittedPathName.length - 1] || 'flows';

    const handleOnSelect = (event, name) => {
        history.push(`/app/developer/workspaces/${workspace}/deployments/${name}`);
    };

    return (
        <Tabset variant="line" onSelect={handleOnSelect} activeTabName={selected}>
            <Tab
                name="flows"
                label="Flows"
            />
            <Tab
                name="history"
                label="History"
            />
        </Tabset>
    );
};

export default DeploymentsTabset;
