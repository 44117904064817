import PropTypes from 'prop-types';
import formatElapsedTime from '../../helpers/formatElapsedTime';

const ElapsedTime = ({ value }) => {
    const formattedElapsedTime = formatElapsedTime(value);
    return <span>{formattedElapsedTime}</span>;
};

ElapsedTime.propTypes = {
    value: PropTypes.number,
};

ElapsedTime.defaultProps = {
    value: undefined,
};

export default ElapsedTime;
