const productComparer = (productA, productB) => {
    const orderA = productA?.metadata?.order;
    const orderB = productB?.metadata?.order;
    const priceA = productA?.prices?.[0]?.unit_amount;
    const priceB = productB?.prices?.[0]?.unit_amount;
    if (orderA !== undefined && orderB !== undefined) {
        return orderA - orderB;
    }
    if (priceA !== undefined && priceB !== undefined) {
        return priceA - priceB;
    }
    return 1;
};

export default productComparer;
