import { useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { RenderIf, Table, Column } from 'react-rainbow-components';
import { useUpdateDoc, useCurrentUser } from '@rainbow-modules/firebase-hooks';
import { confirmModal } from '@rainbow-modules/app';
import { Plus, CloseCircleFilled } from '@rainbow-modules/icons';
import AlignLeftContent from '../../../../components/AlignLeftContent';
import LinkCell from '../../../../components/LinkCell';
import ButtonIconTooltip from '../../../../components/ButtonIconTooltip';
import Message from '../../../../components/Message';
import useRoles from '../../../../data/hooks/useRoles';
import useMutationFlow from '../../../../data/hooks/useMutationFlow';
import AssignRole from '../assignRole';
import {
    Header, TrashIcon, StyledButtonIconTooltip, RolesEmptyIcon,
} from '../styled';

// eslint-disable-next-line react/prop-types
const CancelButton = ({ onClick, row }) => (
    <StyledButtonIconTooltip
        icon={<CloseCircleFilled />}
        text="Remove Role"
        onClick={() => onClick(row)}
    />
);

const getUserRoles = ({ customClaims, projectRoles }) => {
    if (customClaims && Array.isArray(customClaims.roles)) {
        return projectRoles.filter((role) => !!customClaims.roles.includes(role.name));
    }
    return [];
};

const Roles = (props) => {
    const {
        isLoading: isLoadingUser, customClaims, displayName, email, uid,
    } = props;
    const { projectId } = useParams();
    const [isOpenAssignRoleModal, setOpenAssignRoleModal] = useState(false);
    const [projectRoles, isLoadingRoles] = useRoles(projectId);
    const userRoles = getUserRoles({ customClaims, projectRoles });
    const isLoading = isLoadingUser || isLoadingRoles;
    const userIdentifier = displayName || email || uid;
    const { uid: userId } = useCurrentUser();
    const queryClient = useQueryClient();

    const { mutate: removeRole } = useMutationFlow({
        mutation: useUpdateDoc().mutateAsync,
        submitSpinnerMessage: 'Removing roles',
        onSuccess: () => setTimeout(() => queryClient.invalidateQueries(['users-getUser', projectId, uid]), 2000),
        successMessage: 'The roles were removed sucessfully.',
    });

    const handleRemoveRole = async (role) => {
        const result = await confirmModal({
            icon: <TrashIcon />,
            variant: 'destructive',
            header: 'Remove Role',
            question: 'Are you sure you want to permanently remove the role from the user?',
            okButtonLabel: 'Remove',
        });

        if (result) {
            return removeRole({
                path: `projects/${projectId}/users/${uid}`,
                data: {
                    roles: firebase.firestore.FieldValue.arrayRemove(role.id),
                    userId,
                },
            });
        }
        return null;
    };

    return (
        <>
            <Header>
                <ButtonIconTooltip
                    icon={<Plus />}
                    text="Assign Role"
                    variant="border-filled"
                    shaded
                    onClick={() => setOpenAssignRoleModal(true)}
                />
            </Header>
            <RenderIf isTrue={isLoading || userRoles.length > 0}>
                <Table data={userRoles} keyField="id" variant="listview" isLoading={isLoading}>
                    <Column header="Name" field="name" component={LinkCell} width={250} />
                    <Column header="Description" field="description" component={AlignLeftContent} />
                    <Column field="status" component={CancelButton} onClick={handleRemoveRole} width={50} />
                </Table>
            </RenderIf>
            <RenderIf isTrue={!isLoading && userRoles.length === 0}>
                <Message
                    className="rainbow-m-bottom_xx-large"
                    icon={<RolesEmptyIcon />}
                    title="This project has no user roles to display."
                    description="Please, assign a role."
                />
            </RenderIf>
            <AssignRole
                isOpen={isOpenAssignRoleModal}
                projectRoles={projectRoles}
                userRoles={userRoles}
                isLoading={isLoadingRoles}
                userIdentifier={userIdentifier}
                uid={uid}
                onRequestClose={() => setOpenAssignRoleModal(false)}
            />
        </>
    );
};

Roles.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    customClaims: PropTypes.object,
    displayName: PropTypes.string,
    email: PropTypes.string,
    uid: PropTypes.string.isRequired,
};

Roles.defaultProps = {
    customClaims: {},
    displayName: undefined,
    email: undefined,
};

export default Roles;
