import { Field } from 'react-final-form';
import { Input, Textarea } from 'react-rainbow-components';
import { isRequired } from '@rainbow-modules/forms';
import { FieldsContainer } from './styled';

const RoleFields = () => (
    <FieldsContainer>
        <Field
            component={Input}
            label="Role Name"
            name="name"
            required
            placeholder="Enter the role name"
            className="rainbow-m-bottom_large"
            validate={isRequired()}
            id="role-name-input"
        />
        <Field
            component={Textarea}
            label="Role Description"
            name="description"
            required
            placeholder="Enter the role description"
            className="rainbow-m-bottom_xx-large"
            validate={isRequired()}
            id="role-description-input"
        />
    </FieldsContainer>

);

export default RoleFields;
