import PropTypes from 'prop-types';
import Item from './item';
import { StyledDraggable } from './styled';

const Items = ({
    value, errors, readOnly, ...props
}) => value.map((item, index) => (
    <StyledDraggable draggableId={`${item.name}-${index}`} index={index} isDragDisabled={readOnly}>
        {(provided, snapshot) => (
            <>
                <Item
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    readOnly={readOnly}
                    value={item}
                    index={index}
                    error={errors[index]}
                    provided={provided}
                    snapshot={snapshot}
                    isLastItem={index === value.length - 1}
                />
            </>
        )}
    </StyledDraggable>
));

Items.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            configSchema: PropTypes.object,
            defaultConfig: PropTypes.object,
            config: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
    ),
    errors: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    onRequestRemove: PropTypes.func,
};

Items.defaultProps = {
    value: [],
    errors: [],
    onClick: () => {},
    onRequestRemove: () => {},
};

export default Items;
