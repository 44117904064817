import styled from 'styled-components';
import { Button, TableWithBrowserPagination } from 'react-rainbow-components';
import { ErrorCircleFilled } from '@rainbow-modules/icons';
import { InputSearch } from '@rainbow-modules/search';
import Empty from '../../../components/Icons/empty';
import Message from '../../../components/Message';

export const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 36px 12px 8px;
    margin: 16px 4px;
    background: ${(props) => props.theme.rainbow.palette.background.main};
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    width: fit-content;
`;

export const Line = styled.div`
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
    position: absolute;
    top: 8px;
    bottom: 8px;
    border: 2px solid ${(props) => props.theme.rainbow.palette.warning.main};
`;

export const ErrorIcon = styled(ErrorCircleFilled)`
    color: ${(props) => props.theme.rainbow.palette.warning.main};
    margin-right: 24px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-left: 20px;
`;

export const ErrorMessageLabel = styled.h4`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    font-size: 16px;
`;

export const ErrorMessageDescription = styled.p`
    color: ${(props) => props.theme.rainbow.palette.text.header};
    font-size: 14px;
`;

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    margin: 28px 16px 16px 16px;
`;

export const StyledTable = styled(TableWithBrowserPagination)`
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    td > div {
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
    }
`;

export const StyledMessage = styled(Message)`
    margin: 0 auto 80px auto;
    justify-content: center;
    height: 100%;
`;

export const StyledLink = styled.a`
    margin-top: 16px;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    text-decoration: underline;
    padding: 0 8px;

    :hover,
    :focus {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const Highlight = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 0 8px;
`;

export const MessageTitle = styled.div`
    max-width: 420px;
`;

export const MessageIcon = styled(Empty)`
    color: ${(props) => props.theme.rainbow.palette.border.divider};
    margin-bottom: 12px;
    width: 80px;
    height: 80px;
`;

export const MarketplaceButton = styled(Button)`
    margin-top: 20px;
`;

export const StyledInputSearch = styled(InputSearch)`
    margin: 20px 0 8px 0;
    width: 50%;
`;
