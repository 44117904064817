import formatAlgoliaCategories from './formatAlgoliaCategories';
import formatAlgoliaFilter from './formatAlgoliaFilter';
import concatExpressions from './concatExpressions';

const isPurchasedFilterIncluded = (filters) => Array.isArray(filters.purchasedFunctions);
const hasPurchases = (purchasedFunctions) => purchasedFunctions.length > 0;

const buildAlgoliaFilters = (functionFilters) => {
    const formattedCategories = formatAlgoliaCategories('tags', functionFilters.categories || []);
    const formattedPriceFilter = formatAlgoliaFilter(functionFilters.filter || []);
    const expressions = [formattedCategories, formattedPriceFilter];

    if (isPurchasedFilterIncluded(functionFilters)) {
        if (hasPurchases(functionFilters.purchasedFunctions)) {
            expressions.push(
                formatAlgoliaCategories('objectID', functionFilters.purchasedFunctions || []),
            );
        } else if (functionFilters.filter.length === 0) {
            expressions.push('objectID');
        }
    }

    return concatExpressions(...expressions);
};

export default buildAlgoliaFilters;
