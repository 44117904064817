import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filterByFields } from '@rainbow-modules/listview';
import { useParams } from 'react-router-dom';
import { Table, Column, Option } from 'react-rainbow-components';
import LabelWithDescription from '../../../../../../components/LabelWithDescription';
import FunctionColumn from '../columns/functionColumn';
import {
    AddFunctionsFilter, FunctionsContainer, Row, StyledInputSearch, StyledPicklist,
} from '../styled';
import Action from '../columns/actionColumn';
import useWorkspaceFunctions from '../../../../../../data/hooks/useWorkspaceFunctions';
import useWorkspaceImportedFunctions from '../../../../../../data/hooks/useWorkspaceImportedFunctions';
import useDeploymentAddedFunctions from '../../../../../../data/hooks/deployments/useDeploymentAddedFunctions';

const AddFunctions = (props) => {
    const { hideActionColumn } = props;
    const { workspace, deploymentId } = useParams();
    const [normalizedFunctions, setNormalizedFunctions] = useState([]);

    const [optionFilter, setOptionFilter] = useState({ name: 'all', label: 'All functions' });
    useEffect(() => {
        if (hideActionColumn) {
            setOptionFilter({ name: 'added', label: 'Added functions' });
        } else {
            setOptionFilter({ name: 'all', label: 'All functions' });
        }
    }, [hideActionColumn]);

    const [query, setQuery] = useState('');
    const [functions, isLoading] = useWorkspaceFunctions(workspace);
    const [functionsImported, isLoadingImported] = useWorkspaceImportedFunctions(workspace);
    const [addedFunctions, isLoadingAdded] = useDeploymentAddedFunctions({
        workspace,
        deploymentId,
    });
    useEffect(
        () => {
            if (!isLoading && !isLoadingImported && !isLoadingAdded) {
                const allFunctions = [
                    ...functions.map((func) => ({
                        ...func,
                        name: func.id,
                        workspace,
                    })),
                    ...functionsImported.map(
                        ({ name: functionName, workspace: fuctionWorkspace, iconUrl }) => ({
                            id: `${fuctionWorkspace}:${functionName}`,
                            name: functionName,
                            workspace: fuctionWorkspace,
                            iconUrl,
                            isImported: true,
                        }),
                    ),
                ].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
                setNormalizedFunctions(
                    filterByFields({
                        data: allFunctions.map((func) => ({
                            ...func,
                            isAdded: !!addedFunctions.find((added) => added.id === func.id),
                            trigger: {
                                provider: 'http',
                            },
                        })).filter((func) => {
                            if (optionFilter.name === 'all') {
                                return true;
                            }
                            return func.isAdded;
                        }),
                        query,
                        fields: ['id'],
                    }),
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            addedFunctions,
            functions,
            functionsImported,
            isLoadingImported,
            isLoading,
            isLoadingAdded,
            optionFilter.name,
            query,
        ],
    );
    return (
        <FunctionsContainer>
            <AddFunctionsFilter>
                <LabelWithDescription
                    name="Workspace Functions"
                    description="Select the functions of the workspace you want to update during the deployment."
                />
                <Row className="rainbow-m-top_x-small">
                    <StyledPicklist
                        value={optionFilter}
                        className="rainbow-m-right_medium"
                        onChange={setOptionFilter}
                        disabled={hideActionColumn}
                    >
                        <Option name="all" label="All functions" />
                        <Option name="added" label="Added functions" />
                    </StyledPicklist>

                    <StyledInputSearch
                        value={query}
                        variant="realtime"
                        onChange={setQuery}
                        placeholder="Find function"
                    />
                </Row>
            </AddFunctionsFilter>
            <Table className="rainbow-m-bottom_large" data={normalizedFunctions} keyField="id" variant="listview" isLoading={isLoading}>
                <Column header="Functions" field="id" component={FunctionColumn} headerAlignment="left" />
                { hideActionColumn
                    ? null : (
                        <Column
                            defaultWidth="90"
                            field="status"
                            component={Action}
                            header="Selected?"
                        />
                    ) }
            </Table>
        </FunctionsContainer>
    );
};

AddFunctions.propTypes = {
    hideActionColumn: PropTypes.bool.isRequired,
};

export default AddFunctions;
