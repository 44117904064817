import PropTypes from 'prop-types';
import {
    SectionTitle,
    UsagePlanCard,
    PlanInfoText,
    TextDescription,
    HorizontalDivider,
    Features,
} from './styled';
import FeatureList from '../../../../../components/FeatureList';

const PlanInfo = ({ product }) => {
    const {
        name, description, metadata: { color }, features,
    } = product;
    return (
        <>
            <UsagePlanCard color={color}>
                <PlanInfoText className="rainbow-m-left_medium rainbow-m-top_medium">{name}</PlanInfoText>
                <TextDescription className="rainbow-m-left_medium rainbow-m-top_x-small">
                    {description}
                </TextDescription>
                <HorizontalDivider className="rainbow-m-bottom_medium rainbow-m-top_medium" />
                <SectionTitle className="rainbow-m-left_medium">Top Features:</SectionTitle>
                <Features>
                    <FeatureList features={features} />
                </Features>
            </UsagePlanCard>
        </>
    );
};

PlanInfo.propTypes = {
    product: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        metadata: PropTypes.shape({
            color: PropTypes.string,
        }),
        features: PropTypes.object,
    }).isRequired,
};

export default PlanInfo;
