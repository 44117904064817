import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const FormContainer = styled.div`
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    max-width: 600px;
    padding: 20px 8px;
`;

export const Footer = styled.footer`
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    margin-top: 20px;
    padding: 20px 12px 0 12px;
`;

export const MessageLink = styled.a`
    color: inherit;
    text-decoration: underline;

    :hover,:active,:visited {
        color: inherit;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 600px;
`;

export const Item = styled.div`
    display: flex;
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    line-height: 40px;
    height: 40px;
    background: ${(props) => props.theme.rainbow.palette.action.hover};
    border-radius: 40px;
    padding: 0 20px;
    align-items: center;
`;
