import PropTypes from 'prop-types';
import TestCoverage from '../TestCoverage';
import useFunctionCoverage from '../../data/hooks/useFunctionCoverage';

const TestCoverageFunction = ({ data }) => {
    const { buildId, workspace, name } = data;
    const [functionCoverage, isLoading] = useFunctionCoverage({
        buildId,
        workspace,
        name,
    });
    return <TestCoverage data={functionCoverage?.description} isLoading={isLoading} />;
};

TestCoverageFunction.propTypes = {
    data: PropTypes.object.isRequired,
};

export default TestCoverageFunction;
