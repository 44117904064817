import { CheckboxToggle } from 'react-rainbow-components';
import { useReduxForm } from 'react-rainbow-components/libs/hooks';

const BooleanInput = (props) => {
    const {
        value: { value }, onChange, label, className,
    } = useReduxForm(props);

    const handleChange = (event) => {
        const { checked } = event.target;
        return onChange({ value: checked, type: 'boolean' });
    };

    return (
        <div className={className}>
            {label}
            <CheckboxToggle
                value={value}
                onChange={handleChange}
            />
        </div>
    );
};

export default BooleanInput;
