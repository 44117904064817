import { useParams } from 'react-router-dom';
import { Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { UniversalForm, composeValidators, isRequired } from '@rainbow-modules/forms';
import { Input, Textarea, Button } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import firebaseCallable from '../../../data/services/firebaseCallable';
import { CREATE_SECRET_DRAWER } from '../../../constants';
import LabelWithDescription from '../../../components/LabelWithDescription';
import useMutationFlow from '../../../data/hooks/useMutationFlow';
import { Container, FormContainer, Footer } from './styled';
import { isValidId } from './validators';
import encodeBase64 from './helpers/encodeBase64';
import events from '../../../analytics/events';
import logEvent from '../../../analytics/logEvent';

const FORM_ID = 'create-new-secret';

export default function SecretFormCreateMode() {
    const { projectId } = useParams();
    const queryClient = useQueryClient();
    const [, closeModal] = useOpenModal(CREATE_SECRET_DRAWER);

    const { mutate } = useMutationFlow({
        mutation: (data) => firebaseCallable('secrets-create', {
            projectId,
            ...data,
            secretValue: encodeBase64(data.secretValue),
        }),
        submitSpinnerMessage: 'Creating secret',
        successMessage: null,
        onSuccess: () => {
            logEvent(events.secrets.CREATED);
            queryClient.invalidateQueries(
                ['secrets-list', projectId],
                { refetchInactive: true },
            );
            closeModal();
        },
    });

    return (
        <Container>
            <FormContainer>
                <UniversalForm id={FORM_ID} onSubmit={mutate}>
                    <Field
                        label={(
                            <LabelWithDescription
                                name="Name"
                                description="The name should be identifiable and unique within this project."
                            />
                        )}
                        component={Input}
                        validate={composeValidators(
                            isRequired('Secret name is required'),
                            isValidId('It can only contains letters, numbers, hyphen and underscore.'),
                        )}
                        placeholder="Enter the secret name"
                        name="secretName"
                        labelAlignment="left"
                        required
                        className="rainbow-m-bottom_x-large"
                        id="secret-name-input"
                    />
                    <Field
                        label={(
                            <LabelWithDescription
                                name="Secret Value"
                                description="Input your secret value or import it directly from a file."
                            />
                        )}
                        validate={composeValidators(
                            isRequired('Secret value is required'),
                        )}
                        component={Textarea}
                        placeholder="Enter the secret value"
                        name="secretValue"
                        labelAlignment="left"
                        required
                        id="secret-value-input"
                    />
                </UniversalForm>
            </FormContainer>
            <Footer>
                <Button
                    className="rainbow-m-right_medium"
                    label="Cancel"
                    variant="base"
                    onClick={closeModal}
                />
                <Button label="Create" variant="brand" type="submit" form={FORM_ID} />
            </Footer>
        </Container>
    );
}

SecretFormCreateMode.propTypes = {};

SecretFormCreateMode.defaultProps = {};
