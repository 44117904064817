/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import {
    TotalRowContainer, TotalCoverage, TotalFuncs, TotalLines, TotalUncoveredLines, TotalRowLabel,
} from './styled';

export default function TotalRow(props) {
    const {
        label, coverage, funcs, lines, uncoveredLines,
    } = props;
    return (
        <TotalRowContainer>
            <TotalRowLabel>
                {label}
            </TotalRowLabel>
            <TotalCoverage coverage={coverage}>
                <FormattedNumber
                    style="percent"
                    value={coverage}
                    minimumFractionDigits={0}
                />
            </TotalCoverage>
            <TotalFuncs>
                <FormattedNumber
                    style="percent"
                    value={funcs}
                    minimumFractionDigits={0}
                />
            </TotalFuncs>
            <TotalLines>
                <FormattedNumber
                    style="percent"
                    value={lines}
                    minimumFractionDigits={0}
                />
            </TotalLines>
            <TotalUncoveredLines>
                {uncoveredLines}
            </TotalUncoveredLines>
        </TotalRowContainer>
    );
}

TotalRow.propTypes = {
    label: PropTypes.string,
    coverage: PropTypes.number,
    funcs: PropTypes.number,
    lines: PropTypes.number,
    uncoveredLines: PropTypes.number,
};

TotalRow.defaultProps = {
    label: undefined,
    coverage: undefined,
    funcs: undefined,
    lines: undefined,
    uncoveredLines: undefined,
};
