import { isFirestoreCollection, isFirestoreDocument } from '@rainbow-modules/validation';

const validateFirestorePath = (value, type) => {
    const isCollection = type === 'collection';
    const result = isCollection ? isFirestoreCollection(value) : isFirestoreDocument(value);
    if (result) {
        return undefined;
    }
    const message = isCollection
        ? "The entered Firestore collection path is invalid. It must contain an odd number of slash-separated path elements, e.g., 'users/{userId}/notifications'"
        : "The entered Firestore document path is invalid. It must contain an even number of slash-separated path elements, e.g., 'users/{userId}'";

    return message;
};

export default validateFirestorePath;
