import PropTypes from 'prop-types';
import {
    RenderIf, Button,
} from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import PayButtonLabel from '../../../components/PayButtonLabel';
import { BUY_FUNCTION_MODAL } from '../../../constants';
import {
    Header, LeftContent, DetailsContainer, FunctionName, Description,
} from './styled';

const FunctionHeader = (props) => {
    const {
        isPurchased,
        functionData,
        isLoadingBuyButton,
        onSubmitBuyModal,
        icon,
        description,
        backButton,
        price,
        installButton,
    } = props;
    const { name } = functionData || {};
    const [openBuyModal] = useOpenModal(BUY_FUNCTION_MODAL);

    return (
        <Header data-cy="function-details-header">
            <LeftContent>
                {backButton}
                {icon}
                <DetailsContainer>
                    <FunctionName data-cy="function-name">{name}</FunctionName>
                    <Description data-cy="function-desc">{description}</Description>
                </DetailsContainer>
            </LeftContent>
            <RenderIf isTrue={isPurchased}>
                {installButton}
            </RenderIf>
            <RenderIf isTrue={!isPurchased}>
                <Button
                    variant="brand"
                    onClick={() => openBuyModal({
                        title: 'Review your order',
                        submitButtonLabel: <PayButtonLabel label="Pay" price={price} />,
                        functionData,
                        onSubmitModal: () => onSubmitBuyModal(true),
                    })}
                    isLoading={isLoadingBuyButton}
                >
                    <PayButtonLabel label="Buy" price={price} />
                </Button>
            </RenderIf>
        </Header>
    );
};

FunctionHeader.propTypes = {
    isPurchased: PropTypes.bool.isRequired,
    isLoadingBuyButton: PropTypes.bool.isRequired,
    functionData: PropTypes.object.isRequired,
    onSubmitBuyModal: PropTypes.func.isRequired,
    icon: PropTypes.node.isRequired,
    backButton: PropTypes.node.isRequired,
    installButton: PropTypes.node.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
};

export default FunctionHeader;
