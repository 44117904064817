/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */
import {
    useState,
    useImperativeHandle,
    useRef,
    useEffect,
    forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { Lookup } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import { useReduxForm, useUniqueIdentifier } from 'react-rainbow-components/libs/hooks';
import useCheckSecretsApi from '../../data/hooks/useCheckSecretsApi';
import useSecretsList from '../../data/hooks/useSecretsList';
import formatData from './helpers/formatData';
import getFormattedValue from './helpers/getFormattedValue';
import getSuggestions from './helpers/getSuggestions';
import KeyIcon from './keyIcon';
import useFocusOutside from './useFocusOutside';

const SecretLookup = forwardRef((props, ref) => {
    const {
        id,
        style,
        className,
        label,
        error,
        readOnly,
        placeholder,
        disabled,
        tabIndex,
        required,
        name,
        value,
        labelAlignment,
        hideLabel,
        variant,
        onClick,
        onChange,
        onBlur,
        projectId,
    } = useReduxForm(props);
    const lookupId = useUniqueIdentifier('secretlookup-input');
    const inputRef = useRef();
    const [secretsList, setSecretsList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const focusOutside = useFocusOutside(() => {
        const input = inputRef.current.fieldRef.current.inputRef.current;
        if (isEmpty(value) && !isEmpty(input.value)) {
            onChange({
                type: 'text',
                value: input.value,
            });
            inputRef.current.fieldRef.current.clearInput();
        }
    });
    const { isEnabled: isSecretsApiEnabled, isLoading: isCheckingApi } = useCheckSecretsApi({
        projectId,
    });
    const { data, isLoading: isLoadingSecretsList } = useSecretsList({
        projectId,
        enabled: Boolean(!isCheckingApi && isSecretsApiEnabled),
    });

    const isInitializing = Boolean(isCheckingApi || isLoadingSecretsList);
    const options = suggestions.length > 0 ? suggestions : secretsList;

    useEffect(() => {
        setSecretsList(formatData(data));
    }, [data]);

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus();
        },
        click: () => {
            inputRef.current.click();
        },
        blur: () => {
            inputRef.current.blur();
        },
    }));

    const clear = () => {
        setSuggestions([]);
    };

    const handleChange = (option) => {
        if (!option) {
            return onChange(null);
        }
        if (!option.value) {
            return onChange({
                type: 'text',
                value: option.data,
            });
        }
        return onChange({
            type: 'secret',
            value: option.value,
        });
    };

    const handleSearch = (needle) => {
        if (needle) {
            if (isInitializing) {
                return setSuggestions(getSuggestions([], needle));
            }

            const filteredSecrets = secretsList.filter(
                (secret) => secret.label.toLowerCase().includes(needle.toLowerCase()),
            );
            return setSuggestions(getSuggestions(filteredSecrets, needle));
        }

        return clear();
    };

    return (
        <div id={id} className={className} style={style} {...focusOutside}>
            <Lookup
                id={lookupId}
                ref={inputRef}
                name={name}
                label={label}
                icon={<KeyIcon shaded />}
                iconPosition="right"
                options={options}
                isLoading={isInitializing}
                placeholder={placeholder}
                labelAlignment={labelAlignment}
                hideLabel={hideLabel}
                readOnly={readOnly}
                required={required}
                tabIndex={tabIndex}
                disabled={disabled}
                value={getFormattedValue(value, <KeyIcon />)}
                onClick={onClick}
                onChange={handleChange}
                onSearch={handleSearch}
                onBlur={onBlur}
                error={error}
                preferredSelectedOption={0}
                variant={variant}
            />
        </div>
    );
});

SecretLookup.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelAlignment: PropTypes.oneOf(['left', 'center', 'right']),
    hideLabel: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            type: PropTypes.string,
            value: PropTypes.string,
        }),
    ]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    disabled: PropTypes.bool,
    tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    variant: PropTypes.oneOf(['default', 'shaded', 'bare']),
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    projectId: PropTypes.string.isRequired,
};

SecretLookup.defaultProps = {
    id: undefined,
    className: undefined,
    style: undefined,
    name: undefined,
    label: undefined,
    labelAlignment: 'center',
    hideLabel: false,
    disabled: false,
    placeholder: null,
    error: null,
    readOnly: false,
    required: false,
    tabIndex: undefined,
    value: undefined,
    variant: 'default',
    onChange: () => {},
    onClick: () => {},
    onBlur: () => {},
};

export default SecretLookup;
