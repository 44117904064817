import firebase from 'firebase';

const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

const formatDate = (date) => {
    if (date) {
        let value;
        if (date instanceof firebase.firestore.Timestamp) {
            value = date.toDate();
        } else {
            value = new Date(date);
        }
        return (new Intl.DateTimeFormat(undefined, options).format(value));
    }
    return null;
};

export default formatDate;
