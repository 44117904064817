import PropTypes from 'prop-types';
import BackButton from '../../components/BackButton';

const DescriptionInfo = ({ projectId, description }) => (
    <div className="rainbow-flex">
        <BackButton to={`/app/project/${projectId}/auth/users`} />
        <span>{description}</span>
    </div>
);

DescriptionInfo.propTypes = {
    projectId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,

};

export default DescriptionInfo;
