import PropTypes from 'prop-types';
import LinkColumn from '../../../../../../components/LinkColumn';
import { BuildIcon, LinkColumnContainer } from './styled';

const PushLinkColumn = (props) => {
    const { value, workspace } = props;
    const to = `/app/developer/workspaces/${workspace}/build/${value}`;

    return (
        <LinkColumn
            to={to}
            value={(
                <LinkColumnContainer data-cy="build-id" data-id={value}>
                    <BuildIcon />
                    {value}
                </LinkColumnContainer>
            )}
        />
    );
};

PushLinkColumn.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    workspace: PropTypes.string.isRequired,
};

export default PushLinkColumn;
