import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import LinkColumn from '../LinkColumn';

const LinkCell = (props) => {
    const { value, row: { id } } = props;
    const { projectId } = useParams();
    const to = `/app/project/${projectId}/auth/roles/${id}`;
    return <LinkColumn to={to} value={value} />;
};

LinkCell.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
};

export default LinkCell;
