import getDeploymentHistoryFinishedAt from './getDeploymentHistoryFinishedAt';

const getDeploymentHistoryDuration = (deploymentHistory) => {
    const { createdAt } = deploymentHistory;
    const finishedAt = getDeploymentHistoryFinishedAt(deploymentHistory);
    if (createdAt && typeof createdAt.toDate === 'function' && finishedAt instanceof Date) {
        return finishedAt.getTime() - createdAt.toDate().getTime();
    }

    return undefined;
};

export default getDeploymentHistoryDuration;
