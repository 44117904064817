import PropTypes from 'prop-types';
import { RainbowThemeContainer } from 'react-rainbow-components';
import useChangePlan from '../../data/hooks/useChangePlan';
import { UPGRADE_GREEN } from '../../colors';
import { StyledButton, UpgradeIcon } from './styled';

const upgradeTheme = {
    rainbow: {
        palette: {
            brand: UPGRADE_GREEN,
        },
    },
};

const UpgradePlanButton = ({ className, style, size }) => {
    const { changePlan } = useChangePlan();
    return (
        <RainbowThemeContainer theme={upgradeTheme}>
            <StyledButton variant="brand" size={size} onClick={changePlan} className={className} style={style}>
                <UpgradeIcon />
                Upgrade
            </StyledButton>
        </RainbowThemeContainer>
    );
};

UpgradePlanButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    size: PropTypes.string,
};

UpgradePlanButton.defaultProps = {
    className: undefined,
    style: undefined,
    size: undefined,
};

export default UpgradePlanButton;
