import { Redirect, Route, Switch } from 'react-router-dom';
import PushList from './pushList';
import PushDetails from './pushDetails';

const WorkspaceCi = () => (
    <Switch>
        <Route
            exact
            path="/app/developer/workspaces/:workspace/build/list"
            component={PushList}
        />
        <Route
            exact
            path="/app/developer/workspaces/:workspace/build/:push"
            component={PushDetails}
        />
        <Redirect
            from="/app/developer/workspaces/:workspace/build"
            to="/app/developer/workspaces/:workspace/build/list"
        />
    </Switch>
);

export default WorkspaceCi;
