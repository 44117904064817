import PropTypes from 'prop-types';
import { TextEllipsisContainer } from './styled';

const CropTextEllipsis = (props) => {
    const { value } = props;
    return (
        <TextEllipsisContainer>
            {value}
        </TextEllipsisContainer>
    );
};

CropTextEllipsis.propTypes = {
    value: PropTypes.string,
};

CropTextEllipsis.defaultProps = {
    value: undefined,
};

export default CropTextEllipsis;
