import getIconForStatus from './getIconForStatus';
import MarkdownLabel from '../../../../../../components/MarkdownLabel';

const buildFunctionSummary = (pushData) => {
    if (pushData) {
        const {
            checksResults, status, conclusion, duration,
        } = pushData;
        return {
            name: 'BuildSummary',
            label: <MarkdownLabel value="BUILD SUMMARY" />,
            icon: getIconForStatus({ status, conclusion }),
            status,
            conclusion,
            duration,
            checksResults,
        };
    }
    return undefined;
};

export default buildFunctionSummary;
