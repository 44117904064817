import { useCollection, useDoc } from '@rainbow-modules/firebase-hooks';

export default function useCIChecksTree({ workspace, push }) {
    const [data, isLoading] = useCollection({
        path: `workspaces/${workspace}/ci/${push}/checks`,
        query: (query) => query.orderBy('createdAt', 'asc'),
        flat: true,
    });

    const [pushData, isLoadingPush] = useDoc({
        path: `/workspaces/${workspace}/ci/${push}`,
        flat: true,
    });

    return {
        data,
        pushData,
        isLoading: isLoading || isLoadingPush,
    };
}
