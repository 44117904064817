import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const TabContent = styled.div`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
`;

export const SourceCodeContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    max-height: 400px;
`;
