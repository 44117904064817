import PropTypes from 'prop-types';
import { FormattedRelativeTime } from 'react-intl';
import normalizeDate from '../../helpers/normalizeDate';

const FormattedRelativeDate = ({ value }) => {
    const normalizedDate = normalizeDate(value);
    const delta = Math.round((normalizedDate - new Date()) / 1000);
    return (
        <FormattedRelativeTime value={delta} numeric="auto" updateIntervalInSeconds={1} />
    );
};

FormattedRelativeDate.propTypes = {
    value: PropTypes.string,
};

FormattedRelativeDate.defaultProps = {
    value: undefined,
};

export default FormattedRelativeDate;
