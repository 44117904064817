import PropTypes from 'prop-types';
import AlignLeftContent from '../AlignLeftContent';
import formatDate from '../../helpers/formatDate';

const FormattedDate = ({ value }) => {
    const formattedDate = formatDate(value);
    return (
        <AlignLeftContent value={formattedDate} />
    );
};

FormattedDate.propTypes = {
    value: PropTypes.string,
};

FormattedDate.defaultProps = {
    value: undefined,
};

export default FormattedDate;
