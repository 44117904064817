import PropTypes from 'prop-types';
import { RecordField } from '@rainbow-modules/record';
import { RenderIf, LoadingShape } from 'react-rainbow-components';
import Clock from '../../../../../components/Icons/clock';
import StatusIcon from './statusIcon';
import CoverageBadge from '../../../../../components/CoverageBadge';
import {
    TitleContainer,
    LoadingContainer,
    Title,
    EllapsedTimeContainer,
} from './styled';
import formatElapsedTime from '../../../../../helpers/formatElapsedTime';

export default function JobTitle(props) {
    const {
        name, status, conclusion, ellapsedTime, isLoading, checkType, coveragePercent,
    } = props;

    const formattedEllapsedTime = formatElapsedTime(ellapsedTime);
    const isCoverageType = checkType === 'coverage';
    const showCoverageBadge = (isCoverageType || checkType === 'summary') && typeof coveragePercent === 'number';

    return (
        <TitleContainer>
            <Title data-cy="job-title">
                <RenderIf isTrue={!isLoading}>
                    <StatusIcon
                        status={status}
                        conclusion={conclusion}
                        size="small"
                        style={{ marginTop: '2px' }}
                    />
                    {name}
                </RenderIf>
                <RenderIf isTrue={isLoading}>
                    <LoadingContainer width="120px" height="26px">
                        <LoadingShape />
                    </LoadingContainer>
                </RenderIf>
            </Title>
            <RenderIf isTrue={!isCoverageType}>
                <RecordField
                    isLoading={isLoading}
                    value={(
                        <EllapsedTimeContainer data-cy="elapsed-time">
                            <Clock />
                            {formattedEllapsedTime}
                        </EllapsedTimeContainer>
                    )}
                />
            </RenderIf>
            <RenderIf isTrue={showCoverageBadge}>
                <CoverageBadge className="rainbow-m-left_small" value={coveragePercent} />
            </RenderIf>
        </TitleContainer>
    );
}

JobTitle.propTypes = {
    name: PropTypes.string,
    isLoading: PropTypes.bool,
    status: PropTypes.oneOf(['done', 'queued', 'in_progress']),
    conclusion: PropTypes.oneOf(['succeeded', 'failed', 'skipped']),
    ellapsedTime: PropTypes.number,
    checkType: PropTypes.string,
    coveragePercent: PropTypes.number,
};

JobTitle.defaultProps = {
    name: undefined,
    isLoading: false,
    status: undefined,
    conclusion: undefined,
    ellapsedTime: undefined,
    checkType: undefined,
    coveragePercent: undefined,
};
