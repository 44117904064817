import PropTypes from 'prop-types';

export default function PlayCircle(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
        >
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="flow-details/select-development" transform="translate(-82.000000, -311.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M92.0344655,311.6 C97.3473388,311.606678 101.392708,315.684985 101.392233,321.031278 C101.391757,326.327406 97.290158,330.401426 91.9627575,330.400008 C86.7443388,330.399064 82.7411572,326.456846 82.6036535,321.247563 L82.6000004,320.967298 L82.6038569,320.689969 C82.7461367,315.534578 86.8083594,311.593473 92.0344655,311.6 Z M92.0334632,312.4 C87.2438149,312.394018 83.5381827,315.985739 83.4037774,320.704231 L83.4000003,320.967513 L83.4035803,321.233975 C83.5334711,326.008675 87.1829889,329.599143 91.9629363,329.600008 C96.8496286,329.601309 100.591797,325.884303 100.592233,321.031207 C100.592669,316.125171 96.9036559,312.406121 92.0334632,312.4 Z M89.9037056,317.876645 C91.7002878,318.956037 93.3218437,319.930262 95.1457619,321.026359 C93.3176674,322.117141 91.6964911,323.084532 89.9037056,324.154052 L89.9037056,317.876645 Z" id="launch" />
                </g>
            </g>
        </svg>
    );
}

PlayCircle.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

PlayCircle.defaultProps = {
    style: {},
    className: undefined,
};
