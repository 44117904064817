import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { RecordSection, RecordDetails, RecordField } from '@rainbow-modules/record';
import get from 'lodash/get';
import StatusBadge from '../../../components/StatusBadge';
import TriggerComponent from '../../../components/TriggerComponent';
import FunctionType from './functionType';
import CropTextEllipsis from './cropTextEllipsis';
import Workspace from './workspace';
import Error from './error';
import EnvironmentsVariables from './environmentsVariables';
import getFunctionName from '../../../helpers/getFunctionName';
import getFunctionRegion from '../../../helpers/getFunctionRegion';
import getFunctionRuntime from '../../../helpers/getFunctionRuntime';
import getFunctionMemory from '../../../helpers/getFunctionMemory';
import formatDate from '../../../helpers/formatDate';
import getFunctionTrigger from '../../../helpers/getFunctionTrigger';
import getFunctionType from '../../../helpers/getFunctionType';
import { TabsetContent, RecordContainer } from './styled';

export default function Details(props) {
    const {
        details, installedFunction, isLoading, iconUrl, status,
    } = props;
    const errorMessage = get(installedFunction, 'operation.data.error.message');
    const {
        name, runtime, availableMemoryMb, entryPoint, environmentVariables,
        updateTime, serviceAccountEmail, versionId,
    } = details || {};
    const { env: installEnvironmentVariables, version, workspace } = installedFunction || {};
    const functionName = getFunctionName(name);
    const functionRegion = getFunctionRegion(name);
    const functionRuntime = getFunctionRuntime(runtime);
    const functionMemory = getFunctionMemory(availableMemoryMb);
    const functionLastDeployed = formatDate(updateTime);
    const functionTrigger = getFunctionTrigger(details);
    const functionType = getFunctionType(environmentVariables);

    const isMarketPlaceFunction = Boolean(functionType === 'marketplace');

    return (
        <TabsetContent>
            <RenderIf isTrue={errorMessage}>
                <Error message={errorMessage} />
            </RenderIf>
            <RecordSection label="Function Info">
                <RecordContainer>
                    <RecordDetails className="rainbow-m-right_x-large">
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Name"
                            value={functionName}
                            isLoading={isLoading}
                        />
                        <RenderIf isTrue={isMarketPlaceFunction}>
                            <RecordField
                                className="rainbow-m-bottom_xx-small"
                                label="workspace"
                                value={workspace}
                                isLoading={isLoading}
                                component={Workspace}
                            />
                        </RenderIf>
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Function Type"
                            value={{ iconUrl, functionType }}
                            isLoading={isLoading}
                            component={FunctionType}
                        />
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Status"
                            component={StatusBadge}
                            isLoading={isLoading}
                            value={status}
                        />
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Runtime"
                            value={functionRuntime}
                            isLoading={isLoading}
                        />
                        <RecordField
                            label="entryPoint"
                            value={entryPoint}
                            isLoading={isLoading}
                        />
                    </RecordDetails>
                    <RecordDetails className="rainbow-m-left_x-large">
                        <RenderIf isTrue={isMarketPlaceFunction}>
                            <RecordField
                                className="rainbow-m-bottom_xx-small"
                                label="version"
                                value={version}
                                isLoading={isLoading}
                            />
                        </RenderIf>
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="versionId"
                            value={versionId}
                            isLoading={isLoading}
                        />
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Last Deployed"
                            value={functionLastDeployed}
                            isLoading={isLoading}
                        />
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Region"
                            value={functionRegion}
                            isLoading={isLoading}
                        />
                        <RecordField
                            className="rainbow-m-bottom_xx-small"
                            label="Memory"
                            value={functionMemory}
                            isLoading={isLoading}
                        />
                        <RecordField
                            label="serviceAccountEmail"
                            value={serviceAccountEmail}
                            isLoading={isLoading}
                            component={CropTextEllipsis}
                        />
                    </RecordDetails>
                </RecordContainer>
            </RecordSection>
            <RenderIf isTrue={!isLoading}>
                <RecordSection label="Trigger">
                    <TriggerComponent value={functionTrigger} />
                </RecordSection>
                <RecordSection label="Environment Variables">
                    <RecordDetails>
                        <EnvironmentsVariables
                            environmentsVariables={{
                                ...installEnvironmentVariables,
                                ...environmentVariables,
                            }}
                            isLoading={isLoading}
                        />
                    </RecordDetails>
                </RecordSection>
            </RenderIf>
        </TabsetContent>
    );
}

Details.propTypes = {
    details: PropTypes.object,
    installedFunction: PropTypes.object,
    isLoading: PropTypes.bool,
    iconUrl: PropTypes.string,
    status: PropTypes.string,
};

Details.defaultProps = {
    details: undefined,
    installedFunction: undefined,
    isLoading: true,
    iconUrl: undefined,
    status: undefined,
};
