import { BadgeOverlay } from 'react-rainbow-components';
import { Icon } from '../styled';

// eslint-disable-next-line react/prop-types
const IconWithBadge = ({ src: iconUrl, hideBadge }) => (
    <BadgeOverlay className="rainbow-m-around_medium" isHidden={hideBadge}>
        <Icon as={(iconUrl ? 'img' : '')} src={iconUrl} />
    </BadgeOverlay>
);

const formatFunctionsData = (data = [], showWorkspace = false) => data.map((item) => {
    const {
        id, name, iconUrl, workspace, needsAttention = false,
    } = item;

    const functioName = name || id;
    return {
        label: (showWorkspace ? `${functioName} - ${workspace}` : functioName),
        isLoading: false,
        icon: <IconWithBadge src={iconUrl} hideBadge={!needsAttention} />,
        type: 'function',
        functionId: id,
        children: [],
    };
});

export default formatFunctionsData;
