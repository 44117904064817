import PropTypes from 'prop-types';
import { Picklist, Option, RenderIf } from 'react-rainbow-components';
import { useHttpAuthQuery } from '@rainbow-modules/firebase-hooks';
import {
    ProjectIcon, EmptyLabel, ProjectIconSelected,
} from './styled';

function findGroup(value, projects, variant) {
    const projectSelected = projects.find(({ projectId }) => projectId === value);
    if (projectSelected) {
        const { projectId, name } = projectSelected;
        return {
            name: projectId,
            label: name,
            icon: <ProjectIconSelected variant={variant} />,
        };
    }
    return undefined;
}

const ProjectList = ({ data }) => data.map((project) => {
    const { projectId, name } = project;
    return (
        <Option
            key={projectId}
            name={projectId}
            label={name}
            icon={<ProjectIcon />}
            value={project}
        />
    );
});

export default function ProjectPicker(props) {
    const {
        label,
        onChange,
        value,
        workspace = 'rainbow',
        variant,
        className,
        style,
        error,
    } = props;
    const { data: projects, isLoading } = useHttpAuthQuery({
        functionName: 'workspaces-api',
        pathname: `/${workspace}/projects`,
    });
    const hasProjects = Array.isArray(projects) && projects.length > 0;
    const project = hasProjects
        ? findGroup(value, projects, variant)
        : undefined;
    const handleOnChange = (option) => {
        onChange({ name: option.name, value: option.value });
    };

    return (
        <Picklist
            label={label}
            id="project-picker"
            variant={variant}
            placeholder="Select Project"
            isLoading={isLoading}
            onChange={handleOnChange}
            value={project}
            enableSearch={hasProjects}
            className={className}
            style={style}
            error={error}
            labelAlignment="left"
        >
            <RenderIf isTrue={hasProjects}>
                <Option label="Workspace owner projects" variant="header" />
                <ProjectList data={projects} variant={variant} />
            </RenderIf>
            <RenderIf isTrue={!hasProjects}>
                <EmptyLabel>We couldn’t find any Firebase or GCP project.</EmptyLabel>
            </RenderIf>
        </Picklist>
    );
}

ProjectPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    workspace: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['default', 'shaded', 'bare']),
    className: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.string,
    label: PropTypes.node,
};

ProjectPicker.defaultProps = {
    onChange: () => {},
    value: undefined,
    variant: 'default',
    className: undefined,
    style: undefined,
    error: undefined,
    label: undefined,
};
