const formatInstallationsData = (array) => array.map(({
    name, installations, iconUrl, workspace,
}) => ({
    installations,
    function: {
        name,
        iconUrl,
        workspace,
    },
}));

export default formatInstallationsData;
