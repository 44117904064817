import { useCollection } from '@rainbow-modules/firebase-hooks';
import useUserWorkspaceAccess from './useUserWorkspaceAccess';

const composeVersions = (publishedVersions = [], unpublishedVersions = []) => {
    if (Array.isArray(unpublishedVersions) && unpublishedVersions.length > 0) {
        return unpublishedVersions.map((unpublishedVersion) => {
            const published = publishedVersions.find(
                (publishedVersion) => publishedVersion.version === unpublishedVersion.id,
            );
            const isPublished = !!published;
            return {
                version: unpublishedVersion.id,
                isPublished,
                publishedPrice: published?.price,
                publishedBuild: published?.buildId,
            };
        });
    }
    return publishedVersions.map(({ version, price, buildId }) => ({
        version,
        isPublished: true,
        publishedPrice: price,
        publishedBuild: buildId,
    }));
};

const useUserInstallableVersions = ({
    functionId,
    workspace,
}) => {
    const {
        canAccess: canAccessToWorkspace,
        isLoading: isLoadingAccess,
    } = useUserWorkspaceAccess(workspace);
    const [publishedVersions, isLoadingPublishedVersions] = useCollection({
        path: `workspaces/${workspace}/marketplace/${functionId}/versions`,
        flat: true,
        disabled: !functionId || !workspace,
    });
    const isEnabledUnpublishedVersions = functionId
        && workspace
        && canAccessToWorkspace
        && !isLoadingAccess;
    const [unpublishedVersions, isLoadingUnpublishedVersions] = useCollection({
        path: `workspaces/${workspace}/functions/${functionId}/versions`,
        flat: true,
        disabled: !isEnabledUnpublishedVersions,
    });
    const isLoading = isLoadingAccess
        || isLoadingPublishedVersions
        || (isEnabledUnpublishedVersions && isLoadingUnpublishedVersions);
    return [
        isLoading ? [] : composeVersions(publishedVersions, unpublishedVersions),
        isLoading,
    ];
};

export default useUserInstallableVersions;
