import PropTypes from 'prop-types';
import { Picklist, Option, RenderIf } from 'react-rainbow-components';
import useCallableQuery from '../../data/hooks/useCallableQuery';
import {
    ProjectIcon, SettingsIcon, ProjectsList, ProjectIconSelected, EmptyLabel, StyledGoogleCloudLogo,
} from './styled';

export const ALL_PROJECTS_OPTION = 'all-projects';
export const INSTALL_MANUALLY_ON_GCP = 'install-manually-on-gcp';

function findGroup(value, projects, variant) {
    if (value && typeof value === 'string') {
        if (value === INSTALL_MANUALLY_ON_GCP) {
            return {
                name: value,
                label: 'Install manually on GCP',
                icon: <StyledGoogleCloudLogo />,
            };
        }
        const projectSelected = projects.find(({ projectId }) => projectId === value);
        if (projectSelected) {
            const { projectId, name } = projectSelected;
            return {
                name: projectId,
                label: name,
                icon: <ProjectIconSelected variant={variant} />,
            };
        }
    }
    return undefined;
}

const ProjectList = ({ data }) => data.map((project) => {
    const { projectId, name, setup } = project;
    const Icon = setup ? ProjectIcon : SettingsIcon;
    return (
        <Option
            key={projectId}
            name={projectId}
            label={name}
            icon={<Icon />}
            value={project}
        />
    );
});

const getCheckedProjects = (projects) => projects.filter((project) => Boolean(project.setup));

export default function ProjectPicker(props) {
    const {
        label,
        onChange,
        value,
        hideAllProjectsOption,
        variant,
        className,
        style,
        showCheckedProjectsOnly,
        showInstallManuallyOnGcp,
        error,
    } = props;
    const { data: projects = [], isLoading } = useCallableQuery('projects-list');
    const projectsList = showCheckedProjectsOnly ? getCheckedProjects(projects) : projects;
    const project = findGroup(value, projectsList, variant);
    const hasProjects = projectsList.length > 0;

    const handleOnChange = (option) => {
        onChange({ name: option.name, value: option.value });
    };

    return (
        <Picklist
            label={label}
            id="project-picker"
            variant={variant}
            placeholder="Select Project"
            isLoading={isLoading}
            onChange={handleOnChange}
            value={project}
            enableSearch={hasProjects}
            className={className}
            style={style}
            error={error}
            labelAlignment="left"
        >
            <RenderIf isTrue={showInstallManuallyOnGcp}>
                <Option
                    label="Install manually on GCP"
                    name={INSTALL_MANUALLY_ON_GCP}
                    icon={<StyledGoogleCloudLogo />}
                />
            </RenderIf>
            <RenderIf isTrue={!hideAllProjectsOption}>
                <Option
                    label="See all projects"
                    name={ALL_PROJECTS_OPTION}
                    icon={<ProjectsList />}
                />
            </RenderIf>
            <RenderIf isTrue={hasProjects}>
                <Option label="Your Projects" variant="header" />
                <ProjectList data={projectsList} variant={variant} />
            </RenderIf>
            <RenderIf isTrue={!hasProjects}>
                <EmptyLabel>We couldn’t find any Firebase or GCP project.</EmptyLabel>
            </RenderIf>
        </Picklist>
    );
}

ProjectPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    hideAllProjectsOption: PropTypes.bool,
    showCheckedProjectsOnly: PropTypes.bool,
    showInstallManuallyOnGcp: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'shaded', 'bare']),
    className: PropTypes.string,
    style: PropTypes.object,
    error: PropTypes.string,
    label: PropTypes.node,
};

ProjectPicker.defaultProps = {
    onChange: () => {},
    value: undefined,
    hideAllProjectsOption: false,
    showCheckedProjectsOnly: false,
    showInstallManuallyOnGcp: false,
    variant: 'default',
    className: undefined,
    style: undefined,
    error: undefined,
    label: undefined,
};
