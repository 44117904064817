import {
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import Users from './users';
import Roles from './roles';
import Permissions from './permissions';
import AuthTabset from './authTabset';
import UserDetails from './userDetails';
import RoleDetails from './roleDetails';
import { Title, Container, Description } from './styled';

const Main = () => (
    <Container>
        <Title>Authentication</Title>
        <Description>
            An easy to use UI to help administrators manage user identities
            including password resets, creating and provisioning, blocking and deleting users.
        </Description>
        <AuthTabset />
        <Switch>
            <Route path="/app/project/:projectId/auth/users" component={Users} />
            <Route path="/app/project/:projectId/auth/roles" component={Roles} />
            <Route path="/app/project/:projectId/auth/permissions" component={Permissions} />
            <Redirect from="/app/project/:projectId/auth" to="/app/project/:projectId/auth/users" />
        </Switch>
    </Container>
);

export default function Auth() {
    return (
        <Switch>
            <Route exact path="/app/project/:projectId/auth/user/:userId" component={UserDetails} />
            <Route exact path="/app/project/:projectId/auth/roles/:roleId" component={RoleDetails} />
            <Route path="/app/project/:projectId/auth" component={Main} />
        </Switch>
    );
}
