import PropTypes from 'prop-types';
import FunctionInfoHeader from './functionInfoHeader';
import TestList from './testList';

const Results = ({ functionfailingTestResults = [] }) => functionfailingTestResults.map((item) => {
    const { functionName, tests } = item;
    const failingTestResults = tests?.results?.testResults
        .filter(({ failureMessage, numFailingTests }) => failureMessage && numFailingTests > 0);
    return (
        <div key={functionName} className="rainbow-m-horizontal_xx-small" data-cy="test-failed">
            <FunctionInfoHeader functionName={functionName} />
            <TestList data={failingTestResults} variant="failed" />
        </div>
    );
});

export default function TestFailed({ results }) {
    const functionfailingTestResults = results
        .filter((item) => item?.tests?.results?.success === false);
    return <Results functionfailingTestResults={functionfailingTestResults} />;
}

TestFailed.propTypes = {
    results: PropTypes.array.isRequired,
};
