import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { ButtonMenu, MenuItem } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import {
    More, TrashFilled, PencilFilled, Disabled, Open,
} from '@rainbow-modules/icons';
import { ADD_SECRET_VERSION_DRAWER, SHOW_SECRET_VALUE_MODAL } from '../../constants';
import useDeleteSecret from '../../data/hooks/useDeleteSecret';
import useDisableAllSecretVersions from '../../data/hooks/useDisableAllSecretVersions';
import { ActionsContainer, ActionMessage } from './styled';
import events from '../../analytics/events';
import logEvent from '../../analytics/logEvent';

export default function Actions({ row, onDelete }) {
    const { name } = row;
    const { projectId } = useParams();
    const [openAddVersionModal] = useOpenModal(ADD_SECRET_VERSION_DRAWER);
    const [openSecretValueModal] = useOpenModal(SHOW_SECRET_VALUE_MODAL);
    const deleteSecret = useDeleteSecret({
        onSuccess: (...params) => {
            logEvent(events.secrets.DELETED);
            return onDelete(...params);
        },
    });

    const disableAllSecretVersions = useDisableAllSecretVersions({});

    return (
        <ActionsContainer>
            <ButtonMenu
                icon={<More />}
                buttonSize="small"
            >
                <MenuItem
                    label="View secret value"
                    icon={<Open />}
                    onClick={() => openSecretValueModal({
                        projectId,
                        secretName: name,
                    })}
                />
                <MenuItem
                    label="Add new version"
                    icon={<PencilFilled />}
                    onClick={() => openAddVersionModal({
                        mode: 'add-version',
                        secretData: {
                            secretName: name,
                        },
                    })}
                />
                <MenuItem
                    label="Disable all versions"
                    icon={<Disabled />}
                    onClick={() => disableAllSecretVersions({
                        projectId,
                        secretName: name,
                        children: (
                            <ActionMessage>
                                This will disable all versions for secret
                                {' '}
                                <b>{name}</b>
                                . You can create a new version if you want the secret
                                to continue to function in existing workloads.
                            </ActionMessage>
                        ),
                    })}
                />
                <MenuItem
                    label="Delete secret"
                    icon={<TrashFilled />}
                    onClick={() => deleteSecret({
                        projectId,
                        secretName: name,
                        children: (
                            <ActionMessage>
                                You are about to delete secret
                                {' '}
                                <b>{name}</b>
                                . This action cannot be undone.
                            </ActionMessage>
                        ),
                    })}
                />
            </ButtonMenu>
        </ActionsContainer>
    );
}

Actions.propTypes = {
    row: PropTypes.object,
    onDelete: PropTypes.func,
};

Actions.defaultProps = {
    row: undefined,
    onDelete: () => {},
};
