const normalizeColor = (color) => {
    if (typeof color === 'string') {
        return {
            hex: color.trim(),
            alpha: 1,
        };
    }
    const { hex = '#000000', alpha = 1 } = color;
    return { hex, alpha };
};

export default normalizeColor;
