/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { Close } from '@rainbow-modules/icons';
import PolicyIcon from './icon';
import {
    ItemIconContainer,
    ItemContainer,
    ItemText,
    DividerContainer,
    Line,
    LeftDot,
    RightDot,
    RemoveButton,
    ErrorIcon,
} from './styled';

const Item = ({
    value,
    index,
    activeIndex,
    error,
    readOnly,
    provided,
    snapshot,
    onClick,
    onRequestRemove,
    isLastItem,
}) => {
    const [isHover, setIsHover] = useState(false);

    const { name, iconUrl } = value;
    const shouldRenderRemove = isHover && !readOnly;
    const isActive = index === activeIndex;
    const showDivider = !readOnly || (readOnly && !isLastItem);
    const child = (

        <>
            <ItemContainer
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                title={name}
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
                onClick={() => onClick(index)}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <ItemIconContainer
                    isDragging={snapshot.isDragging}
                    readOnly={readOnly}
                    isActive={isActive}
                >
                    <RenderIf isTrue={index !== 0}>
                        <LeftDot isActive={isActive} />
                    </RenderIf>
                    <PolicyIcon url={iconUrl} />
                    <RenderIf isTrue={showDivider}>
                        <RightDot isActive={isActive} />
                    </RenderIf>
                </ItemIconContainer>
                <ItemText isActive={isActive}>{name}</ItemText>
                <RenderIf isTrue={shouldRenderRemove}>
                    <RemoveButton
                        icon={<Close />}
                        shaded
                        variant="brand"
                        size="xx-small"
                        onClick={(event) => onRequestRemove(event, index)}
                    />
                </RenderIf>
                <RenderIf isTrue={error}>
                    <ErrorIcon />
                </RenderIf>
                {provided.placeholder}
            </ItemContainer>
            <RenderIf isTrue={showDivider}>
                <DividerContainer isDragging={snapshot.isDragging}>
                    <Line />
                </DividerContainer>
            </RenderIf>
        </>
    );

    return child;
};

export default Item;
