import getSecretName from '../../../helpers/getSecretName';
import getSecretLocation from '../../../helpers/getSecretLocation';
import getSecretEncryption from '../../../helpers/getSecretEncryption';
import formatDate from '../../../helpers/formatDate';

const formatData = (data) => {
    if (data && data.secrets) {
        return data.secrets.map((secret) => ({
            ...secret,
            id: secret.name,
            name: getSecretName(secret),
            location: getSecretLocation(secret),
            encryption: getSecretEncryption(secret),
            createTime: formatDate(secret.createTime),
        }));
    }
    return [];
};

export default formatData;
