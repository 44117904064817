import styled from 'styled-components';
import { Table } from 'react-rainbow-components';
import {
    InfoBorder, UnknownCard, Avatar, Check,
} from '@rainbow-modules/icons';
import { GREEN } from '../../colors';

export const Information = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const HelpTextInformation = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 57px;
`;

export const StyledInfoCircleFilled = styled(InfoBorder)`
    color: ${(props) => props.theme.rainbow.palette.brand.main};
    width: 12px;
    margin-left: 5px;
`;

export const TextTooltip = styled.p`
    font-size: 14px;
    text-align: start;
    text-overflow: ellipsis;
    margin-bottom: 4px;
`;

export const SectionTitleContainerPaymentMethod = styled.div`
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
`;

export const SectionTitleContainer = styled.div`
    margin: 0 0 10px;
    display: flex;
    flex-direction: column;
`;

export const SectionTitle = styled.h2`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const InstallationsContainer = styled.div`
    width: 100%;
`;

export const ColumnHeader = styled.span`
    text-transform: none;
`;

export const FunctionNameContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

export const FunctionNameContent = styled.div`
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    line-height: normal;
    min-width: 0;
`;

export const StyledFunctionName = styled.span`
    text-align: left;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const FunctionWorkspace = styled.div`
    text-align: left;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TotalContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
`;

export const CardFooterTitle = styled.div`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    display: flex;
`;

export const Icon = styled(UnknownCard)`
    width: 35px;
    height: 35px;
`;

export const PaymentMethodContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 12px;
`;

export const PaymentHeaderLoading = styled.div`
    height: 15px;
    width: 100px;
`;

export const PaymentInfoLoading = styled.div`
    height: 15px;
    width: 150px;
    margin: 10px 0 38px 0;
`;

export const InstallationContainer = styled.div`
    margin-bottom: 30px;
    width: 50%;
`;

export const PaymentContainer = styled.div`
    margin-bottom: 30px;
`;

export const BillingHeader = styled.h1`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin: 0 0.5rem 0 0;
    padding: 0.5rem 0 1rem;
    display: block;
    box-sizing: border-box;
    font-family: 'Lato Light', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
`;

export const BillingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
    padding: 0 20px;
`;

export const BillingContentContainer = styled.div`
    padding: 30px 4px 0 4px;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
`;

export const CardContainer = styled.div`
    margin-left: 5px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 20px 10px;
    border-top: 10px solid ${(props) => props.color};
    display: flex;
    justify-content: space-around;
    width: 80%;
`;

export const SectionContainer = styled.div`
    max-width: 70%;
`;

export const PlanHeaderText = styled.h3`
    font-size: 16px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 8px;
`;
export const PlanInfoText = styled.h2`
    font-size: 24px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const TextDescription = styled.h4`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const ButtonContainer = styled.div`
    margin-left: -25px;
`;

export const Divider = styled.div`
    width: 1px;
    background: ${(props) => props.theme.rainbow.palette.border.disabled};
    margin: 0 20px 15px;
`;

export const PriceContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
`;

export const DollarSymbol = styled.h4`
    font-size: 24px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin: 5px 3px 0 0;
`;

export const Price = styled.h1`
    font-size: 52px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    line-height: 1;
    margin: 5px 0;
`;

export const IconContainer = styled.div`
    display: flex;
    margin-right: 8px;
`;

export const StyledIcon = styled(Avatar)`
    flex-shrink: 0;
    height: 30px;
    width: 30px;
`;

export const ContactContent = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-right: 8px;
    min-width: 0;
`;

export const ContactTitle = styled.span`
    text-align: left;
    margin-bottom: 6px;
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ContactDescription = styled.span`
    text-align: left;
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const StyledTable = styled(Table)`
    width: 50%;
`;

export const SectionSubtitle = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-top: 4px;
`;

export const UsagePlanCard = styled.div`
    margin-left: 5px;
    margin-bottom: 24px;
    border-radius: 0 0 14px 14px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0 0 16px;
    border-top: 10px solid ${(props) => props.color};
    display: flex;
    flex-direction: column;
    width: 60%;
`;

export const HorizontalDivider = styled.div`
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.rainbow.palette.border.disabled};
`;

export const Features = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: 8px;
`;

export const FeatureLine = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 0;
`;

export const StyledFeature = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const StyledCheck = styled(Check)`
    color: ${GREEN};
    width: 12px;
    height: 12px;
    margin-right: 6px;
`;

export const RenewalDate = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    text-align: center;
    margin-top: 16px;
`;
