import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabset, Tab } from 'react-rainbow-components';
import FunctionInfo from '../../../../../components/FunctionInfo';
import TestCoverage from '../../../../../components/TestCoverageFunction';
import Overview from './tabsContent/overview';
import Source from './tabsContent/source';
import { Container, Content } from './styled';

const tabContentMap = {
    overview: Overview,
    source: Source,
    test: TestCoverage,
};

const BuildDetails = ({ data }) => {
    const [selected, setSelected] = useState('overview');
    const TabContent = tabContentMap[selected];

    const handleOnSelect = (event, value) => {
        setSelected(value);
    };

    return (
        <Container>
            <FunctionInfo data={data} />
            <Tabset
                id="build-details-tabset"
                variant="line"
                onSelect={handleOnSelect}
                activeTabName={selected}
            >
                <Tab name="overview" label="Overview" />
                <Tab name="source" label="Source Code" />
                <Tab name="test" label="Test Coverage" />
            </Tabset>
            <Content>
                <TabContent data={data} />
            </Content>
        </Container>
    );
};

BuildDetails.propTypes = {
    data: PropTypes.object,
};

BuildDetails.defaultProps = {
    data: undefined,
};
export default BuildDetails;
