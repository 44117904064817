import PropTypes from 'prop-types';
import UserColumn from '../../../../../../components/UserColumn';

export default function AuthorColumn({ value }) {
    if (value) {
        const { displayName, photoURL } = value;
        return <UserColumn value={displayName} row={{ photoURL }} />;
    }
    return null;
}

AuthorColumn.propTypes = {
    value: PropTypes.string.isRequired,
};
