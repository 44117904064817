import getFunctionName from '../../../../helpers/getFunctionName';
import getFunctionRegion from '../../../../helpers/getFunctionRegion';
import getFunctionRuntime from '../../../../helpers/getFunctionRuntime';
import getFunctionMemory from '../../../../helpers/getFunctionMemory';
import formatDate from '../../../../helpers/formatDate';
import getFunctionTrigger from '../../../../helpers/getFunctionTrigger';

const formatData = (data) => {
    if (data && data.functions) {
        return data.functions.map((item) => ({
            ...item,
            id: item.name,
            region: getFunctionRegion(item.name),
            name: getFunctionName(item.name),
            availableMemoryMb: getFunctionMemory(item.availableMemoryMb),
            updateTime: formatDate(item.updateTime),
            runtime: getFunctionRuntime(item.runtime),
            trigger: getFunctionTrigger(item),
        }));
    }
    return [];
};

export default formatData;
