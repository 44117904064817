import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-rainbow-components';
import firebase from 'firebase';
import app from '../../firebase';
import { Container } from './styled';

const Authenticate = () => {
    const { idToken } = useParams();

    useEffect(() => {
        (async () => {
            const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
            try {
                await app.auth().signInWithCredential(credential);
            } catch (error) {
                window.location.replace(window.location.origin);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Container><Spinner size="x-large" type="arc" variant="brand" /></Container>;
};

export default Authenticate;
