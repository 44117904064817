import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { FormattedNumber } from 'react-intl';
import { Container, Label, Value } from './styled';

export default function CoverageBadge(props) {
    const { className, style, value } = props;
    return (
        <Container
            className={className}
            style={style}
        >
            <Label>
                coverage
            </Label>
            <Value value={value}>
                <RenderIf isTrue={value === undefined}>
                    unknown
                </RenderIf>
                <RenderIf isTrue={value !== undefined}>
                    <FormattedNumber
                    // eslint-disable-next-line react/style-prop-object
                        style="percent"
                        value={value}
                        minimumFractionDigits={0}
                    />
                </RenderIf>
            </Value>

        </Container>
    );
}

CoverageBadge.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.number,
};

CoverageBadge.defaultProps = {
    className: undefined,
    style: undefined,
    value: undefined,
};
