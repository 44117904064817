import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { Field } from 'react-final-form';
import { FormattedNumber } from 'react-intl';
import { isRequired } from '@rainbow-modules/forms';
import FunctionIcon from '../../../components/FunctionIcon';
import BillingInfo from './billingInfo';
import {
    InformationContainer,
    DetailsContainer,
    Title,
    Description,
    StyledInput,
    StyledStripeCardInput,
    CardRow,
    AmountText,
    Header,
    HighLightedName,
    StyledLink,
    PaymentHelpText,
    StyledContainer,
} from './styled';
import isValidCard from './validators/isValidCard';

const Fields = ({ functionData, billingInfo, isLoading }) => {
    const {
        iconUrl, name, description, price,
    } = functionData;
    const hasBillingInfo = Boolean(billingInfo);
    const shouldRenderBillingInfo = hasBillingInfo || isLoading;

    return (
        <>
            <StyledContainer className="rainbow-m-bottom_small">
                <InformationContainer>
                    <FunctionIcon
                        url={iconUrl}
                        functionType="marketplace"
                        width="50px"
                        height="50px"
                    />
                    <DetailsContainer>
                        <Title>{name}</Title>
                        <Description>{description}</Description>
                    </DetailsContainer>
                </InformationContainer>
                <CardRow>
                    <Description>Function Cost</Description>
                    <AmountText>
                        <FormattedNumber
                            value={price / 100}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currency="USD"
                            minimumFractionDigits={2}
                        />
                    </AmountText>
                </CardRow>
            </StyledContainer>
            <StyledContainer className="rainbow-p-bottom_small" data-cy="billing-info">
                <Header>
                    <Title>Billing Info</Title>
                </Header>
                <RenderIf isTrue={shouldRenderBillingInfo}>
                    <BillingInfo info={billingInfo} isLoading={isLoading} />
                </RenderIf>
                <RenderIf isTrue={!shouldRenderBillingInfo}>
                    <Field
                        name="cardholder-name"
                        component={StyledInput}
                        label="Cardholder Name"
                        placeholder="Enter the name on your card"
                        required
                        labelAlignment="left"
                        validate={isRequired()}
                    />
                    <Field
                        id="card-information-input"
                        name="card-information"
                        label="Card Information"
                        labelAlignment="left"
                        required
                        apiKey={process.env.REACT_APP_STRIPE_API_KEY}
                        component={StyledStripeCardInput}
                        validate={isValidCard}
                    />
                </RenderIf>
            </StyledContainer>
            <PaymentHelpText className="rainbow-p-top_x-small">
                By clicking the
                {' '}
                <HighLightedName>“Pay”</HighLightedName>
                {' '}
                button, you are agreeing to
                {' '}
                <StyledLink>Functions.store Terms of Use</StyledLink>
                .
            </PaymentHelpText>
        </>
    );
};

Fields.propTypes = {
    functionData: PropTypes.object,
    billingInfo: PropTypes.object,
    isLoading: PropTypes.bool,
};

Fields.defaultProps = {
    functionData: undefined,
    billingInfo: undefined,
    isLoading: false,
};

export default Fields;
