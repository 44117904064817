import { useCallback } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import { useHttpAuthMutation } from '@rainbow-modules/firebase-hooks';
import useMutationFlow from './useMutationFlow';

const useDeleteWorkspaceImportedFunction = ({ workspace, functionId, onSuccess = () => {} }) => {
    const { mutateAsync } = useHttpAuthMutation({
        functionName: 'workspaces-api',
        pathname: `/${workspace}/importedFunctions/${functionId}`,
        method: 'DELETE',
    });

    const { mutate: removeFunctionFlow } = useMutationFlow({
        mutation: () => mutateAsync({}),
        submitSpinnerMessage: 'Sending function remove request',
        successMessage: null,
        onSuccess,
    });

    const remove = useCallback(async ({ children }) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Remove imported function',
            okButtonLabel: 'Remove',
            children,
        });
        if (result) {
            await removeFunctionFlow({ functionId, workspace });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return remove;
};

export default useDeleteWorkspaceImportedFunction;
