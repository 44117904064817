import {
    Description, TermsLink, TabContent,
} from '../styled';

const Terms = () => (
    <TabContent className="rainbow-m-top_medium">
        <Description>
            By using this product, you agree to the
            {' '}
            <TermsLink href="https://www.functions.store/terms/terms-of-use" target="_blank" rel="noopener noreferrer">
                Function Store Terms of Use
            </TermsLink>
            .
        </Description>
    </TabContent>
);

export default Terms;
