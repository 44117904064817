import { useDoc, useCurrentUser } from '@rainbow-modules/firebase-hooks';

const useWorkspaceOwnership = (workspaceName) => {
    const { uid } = useCurrentUser();
    const [data, isLoading] = useDoc({
        path: `workspaces/${workspaceName}`,
        flat: true,
    });
    const isOwner = !isLoading && Boolean(data?.owners?.[0] === uid);
    return [isOwner, isLoading];
};

export default useWorkspaceOwnership;
