import PropTypes from 'prop-types';
import { useFloatingBarScrollHandler } from '@rainbow-modules/layout';
import { Picklist, RenderIf } from 'react-rainbow-components';
import SectionHeader from '../sectionHeader';
import LabelWithDescription from '../../../../../../components/LabelWithDescription';
import AddFunctions from './addFunctions';
import {
    SectionContent,
    StepIcon,
    SectionInfo,
    StyledProjectPicker,
    StyledFloatingBar,
    SectionContentContainer,
} from '../styled';
import useUpdateDeployment from '../../hooks/useUpdateDeployment';

const BaseStepConfig = (props) => {
    const {
        selectedProject, projects, workspace, disabled,
    } = props;
    const { updateDeployment } = useUpdateDeployment();

    const [showBar, handleScrollEvent] = useFloatingBarScrollHandler({
        scrollTop: 50,
    });

    if (typeof selectedProject.index === 'number') {
        const isBaseProject = selectedProject.index === -1;
        const changeProject = async (project) => {
            const { projectId } = project.value;
            if (isBaseProject) {
                await updateDeployment({
                    baseProject: {
                        name: 'development',
                        projectId,
                    },
                });
            } else {
                const updatedProjects = [...projects];
                updatedProjects[selectedProject.index].projectId = projectId;
                await updateDeployment({
                    targetProjects: updatedProjects,
                });
            }
        };

        return (
            <SectionContentContainer>
                <StyledFloatingBar isVisible={showBar}>
                    <SectionHeader
                        label={`Configure "${selectedProject.name}"`}
                        icon={<StepIcon />}
                        className="rainbow-m-top_xx-small"
                        size="small"
                    />
                </StyledFloatingBar>
                <SectionContent onScroll={handleScrollEvent}>
                    <SectionHeader label={`Configure "${selectedProject.name}"`} icon={<StepIcon />} />
                    <SectionInfo>
                        <RenderIf isTrue={disabled}>
                            <LabelWithDescription
                                name="GCP Project"
                                description={`Select the GCP project you want to link to the environment "${selectedProject.name}". Below we list only the projects were added in the project tab.`}
                            />
                            <Picklist
                                className="rainbow-m-bottom_large"
                                value={{
                                    value: selectedProject.projectId,
                                    label: selectedProject.projectId,
                                    name: selectedProject.projectId,
                                }}
                                disabled
                            />
                        </RenderIf>
                        <RenderIf isTrue={!disabled}>
                            <StyledProjectPicker
                                value={selectedProject.projectId}
                                className="rainbow-m-bottom_large"
                                workspace={workspace}
                                label={(
                                    <LabelWithDescription
                                        name="GCP Project"
                                        description={`Select the GCP project you want to link to the environment "${selectedProject.name}". Below we list only the projects were added in the project tab.`}
                                    />
                                )}
                                hideAllProjectsOption
                                showCheckedProjectsOnly
                                onChange={changeProject}
                            />
                        </RenderIf>
                        <AddFunctions
                            hideActionColumn={selectedProject.index >= 0 || disabled}
                        />
                    </SectionInfo>
                </SectionContent>
            </SectionContentContainer>
        );
    }
    return <span />;
};

BaseStepConfig.propTypes = {
    selectedProject: PropTypes.shape({
        index: PropTypes.number,
        name: PropTypes.string,
        projectId: PropTypes.string,
    }),
    projects: PropTypes.array,
    workspace: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

BaseStepConfig.defaultProps = {
    selectedProject: {
        index: undefined,
        name: undefined,
        projectId: undefined,
    },
    projects: [],
    disabled: false,
};

export default BaseStepConfig;
