/* eslint-disable react/prop-types */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, RenderIf } from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { useDocOnce } from '@rainbow-modules/firebase-hooks';
import { FUNCTION_INSTALL_PREVIEW_DRAWER } from '../../../../constants';
import InstallDrawer from './installDrawer';
import { AccordionSection } from './builds';

const ImportedBuild = (props) => {
    const { workspace, selectedNode } = props;
    const [, closeFunctionInstallDrawer] = useOpenModal(
        FUNCTION_INSTALL_PREVIEW_DRAWER,
    );
    const { functionId, id: versionId } = selectedNode;
    const [data, isLoading] = useDocOnce({
        path: `/workspaces/${workspace}/marketplace/${functionId}/versions/${versionId}`,
        flat: true,
    });
    const normalizedData = data && {
        ...data,
        id: data.buildId,
        versionId: data.id,
        functionId: data.name,
    };
    const [sectionSelected, setSectionSelected] = useState();
    const functionInstallProps = useConnectModal(FUNCTION_INSTALL_PREVIEW_DRAWER);

    const handleToggle = (event, value) => {
        setSectionSelected(value || []);
    };

    return (
        <>
            <Accordion id="builds-accordion" onToggleSection={handleToggle} activeSectionNames={sectionSelected}>
                <RenderIf isTrue={!isLoading}>
                    <AccordionSection
                        data={normalizedData}
                        isLoading={isLoading}
                        sectionSelected={sectionSelected}
                        workspaceId={workspace}
                        versionId={versionId}
                        functionId={functionId}
                    />
                </RenderIf>
            </Accordion>
            <InstallDrawer
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...functionInstallProps}
                onRequestClose={closeFunctionInstallDrawer}
            />
        </>
    );
};

ImportedBuild.propTypes = {
    workspace: PropTypes.string,
    selectedNode: PropTypes.object,
};

ImportedBuild.defaultProps = {
    workspace: undefined,
    selectedNode: undefined,
};

export default ImportedBuild;
