import { useParams } from 'react-router-dom';
import { useUpdateDoc } from '@rainbow-modules/firebase-hooks';

const useUpdateDeployment = () => {
    const { workspace, deploymentId } = useParams();
    const { mutate, isLoading } = useUpdateDoc();
    return {
        updateDeployment: (data) => mutate({
            path: `workspaces/${workspace}/deployments/${deploymentId}`,
            data,
        }),
        isLoading,
    };
};

export default useUpdateDeployment;
