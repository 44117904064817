import PropTypes from 'prop-types';
import {
    MessageContainer,
    MessageTitle,
    MessageDescription,
    StyledSpinner,
} from './styled';

export default function Loader({ title, message }) {
    return (
        <MessageContainer>
            <StyledSpinner size="x-large" type="arc" variant="brand" />
            <MessageTitle>{title}</MessageTitle>
            <MessageDescription>{message}</MessageDescription>
        </MessageContainer>
    );
}

Loader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Loader.defaultProps = {
    title: undefined,
    message: undefined,
};
