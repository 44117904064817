import PropTypes from 'prop-types';

export default function Paid(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="22" height="22" viewBox="0 0 22 22">
            <g fill="none" fillRule="evenodd">
                <g fill="#FFB403" fillRule="nonzero">
                    <path
                        d="M377 593c-6.076 0-11 4.925-11 11s4.924 11 11 11 11-4.925 11-11-4.925-11-11-11zm.666 15.9v1.273c0 .199-.155.344-.354.344h-.849c-.199 0-.36-.145-.36-.344v-1.161c-.78-.035-1.568-.202-2.165-.429-.323-.122-.503-.469-.417-.804l.138-.539c.049-.189.175-.347.348-.435.173-.09.375-.1.557-.03.568.22 1.24.38 1.956.38.912 0 1.537-.353 1.537-.993 0-.609-.512-.993-1.697-1.394-1.714-.576-2.887-1.376-2.887-2.93 0-1.409.997-2.513 2.702-2.85v-1.161c0-.199.17-.377.369-.377h.849c.199 0 .345.178.345.377v1.049c.745.032 1.283.142 1.733.289.34.11.541.467.452.815l-.122.482c-.047.184-.169.34-.335.43-.167.09-.364.107-.544.045-.408-.14-.942-.268-1.618-.268-1.04 0-1.377.449-1.377.897 0 .529.56.865 1.922 1.378 1.905.672 2.67 1.553 2.67 2.993 0 1.425-1.007 2.643-2.853 2.963z"
                        transform="translate(-366 -593)"
                    />
                </g>
            </g>
        </svg>
    );
}

Paid.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Paid.defaultProps = {
    style: {},
    className: undefined,
};
