/* eslint-disable react/jsx-props-no-spreading */
import {
    useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { RenderIf } from 'react-rainbow-components';
import Items from './items';
import Configuration from './configuration';
import {
    Container,
    PoliciesContainer,
    DraggableContainer,
    ItemButton,
    AddItemContainer,
    StyledPlus,
    ErrorText,
    Label,
} from './styled';
import { validateConfig } from './helpers';

const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const PolicyComposer = ({
    value, readOnly, error, onChange, onAdd,
}) => {
    const [activeIndex, setActiveIndex] = useState();
    const [errors, setErrors] = useState([]);
    const [itemCount, setItemCount] = useState(0);
    const configRef = useRef();
    const shouldScroll = useRef(false);
    const prevValue = useRef(value);

    useLayoutEffect(() => {
        if (Array.isArray(value)) {
            const newErrors = value.map((policy) => {
                const { config, defaultConfig, configSchema } = policy;
                const configValue = config || defaultConfig;
                return validateConfig(configValue, configSchema);
            });
            setErrors(newErrors);
        }
    }, [value]);

    useEffect(() => {
        shouldScroll.current = value !== prevValue.current;
        if (value.length > itemCount) {
            setActiveIndex(value.length - 1);
        }
        setItemCount(value.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, itemCount]);

    useEffect(() => {
        if (shouldScroll.current && activeIndex !== undefined && configRef.current) {
            if (!readOnly) {
                configRef.current.scrollIntoView({ behavior: 'smooth' });
                shouldScroll.current = false;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex]);

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const newData = reorder(value, result.source.index, result.destination.index);
        setActiveIndex(result.destination.index);
        onChange(newData);
    };

    const handleConfigChange = (config) => {
        const activePolicy = value[activeIndex];
        const newValue = [...value];
        newValue[activeIndex] = {
            ...activePolicy,
            config,
        };
        onChange(newValue);
    };

    const handleItemClick = (index) => {
        if (index !== activeIndex) {
            shouldScroll.current = true;
            setActiveIndex(index);
        }
    };

    const handleItemRemove = (event, index) => {
        event.stopPropagation();
        const newValue = value.filter((_, i) => i !== index);
        if (activeIndex === index) {
            setActiveIndex(undefined);
        } else if (activeIndex > index) {
            shouldScroll.current = true;
            setActiveIndex(activeIndex - 1);
        }
        onChange(newValue);
    };

    const shouldRenderConfig = activeIndex !== undefined && itemCount > 0;
    const currentError = error || errors[activeIndex];

    return (
        <Container>
            <RenderIf isTrue={!readOnly}>
                <Label>
                    Add Policies
                </Label>
            </RenderIf>
            <PoliciesContainer>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable
                        droppableId="policies"
                        direction="horizontal"
                        isDropDisabled={readOnly}
                    >
                        {(provided) => (
                            <DraggableContainer
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <Items
                                    value={value}
                                    errors={errors}
                                    readOnly={readOnly}
                                    activeIndex={activeIndex}
                                    onClick={handleItemClick}
                                    onRequestRemove={handleItemRemove}
                                />
                                {provided.placeholder}
                            </DraggableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
                <RenderIf isTrue={!readOnly}>
                    <AddItemContainer>
                        <ItemButton icon={<StyledPlus />} variant="neutral" shaded onClick={onAdd} />
                    </AddItemContainer>
                </RenderIf>
            </PoliciesContainer>
            <RenderIf isTrue={shouldRenderConfig}>
                <Configuration
                    {...value[activeIndex]}
                    readOnly={readOnly}
                    onChange={handleConfigChange}
                    ref={configRef}
                />
            </RenderIf>
            <RenderIf isTrue={currentError}>
                <ErrorText>{currentError}</ErrorText>
            </RenderIf>
        </Container>
    );
};

PolicyComposer.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            iconUrl: PropTypes.string,
            configSchema: PropTypes.object,
            defaultConfig: PropTypes.object,
            config: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        }),
    ),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
};

PolicyComposer.defaultProps = {
    value: [],
    readOnly: false,
    error: undefined,
    onChange: () => {},
    onAdd: () => {},
};

export default PolicyComposer;
