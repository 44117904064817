import styled from 'styled-components';
import { HelpText } from 'react-rainbow-components';

export const Name = styled.span`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 2px;
`;

export const Description = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 4px;
    white-space: normal;

    ${(props) => props.required && `
        margin-left: 12px;
    `}
`;

export const Container = styled.span`
    flex-direction: column;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const StyledHelpText = styled(HelpText)`
    margin-left: 5px;
    width: 14px;
    height: 14px;

    > svg {
        margin-bottom: 3px;
        width: 14px;
        height: 14px;
    }
`;
