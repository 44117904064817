/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import { UniversalForm, Field } from '@rainbow-modules/forms';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import Fields from './fields';
import Policies from './policies';
import Trigger from './trigger';
import Runtime from './runtime';
import isCallableFunction from '../../helpers/isCallableFunction';
import getNormalizedPolicies from './helpers/getNormalizedPolicies';
import getNormalizedEnv from './helpers/getNormalizedEnv';
import getNormalizedRuntime from './helpers/getNormalizedRuntime';
import getNormalizedFormInitialData from './helpers/getNormalizedFormInitialData';
import {
    FormHeader,
    FormTitle,
    FormContainer,
    EnvironmentsFieldsContainer,
    DocsLink,
    OpenIcon,
    Bottom,
} from './styled';

const getNormalizedSubmitData = ({ formValues, envDefinition }) => {
    const { policies, env } = formValues || {};
    const { runtimeConfig, ...fallbackValues } = formValues || {};
    const submitData = { ...fallbackValues };
    if (policies) {
        set(submitData, 'policies', getNormalizedPolicies(policies));
    }
    if (env) {
        set(submitData, 'env', getNormalizedEnv({ env, envDefinition }));
    }
    if (runtimeConfig) {
        set(submitData, 'runtimeConfig', getNormalizedRuntime(runtimeConfig));
    }
    return submitData;
};

const Form = (props) => {
    const {
        id, onSubmit, fields, initialValues, projectId, keepDirtyOnReinitialize, version,
    } = props;
    const { trigger, env } = fields;
    const submittedValues = useRef(null);
    const isCallable = isCallableFunction(trigger);
    const hasEnv = env && env.length > 0;
    const normalizedInitialValues = submittedValues.current
    || getNormalizedFormInitialData(initialValues);
    const onSubmitMiddleware = (formValues) => {
        submittedValues.current = formValues;
        onSubmit(getNormalizedSubmitData({ formValues, envDefinition: env }));
    };
    return (
        <FormContainer>
            <UniversalForm
                id={id}
                onSubmit={onSubmitMiddleware}
                initialValues={normalizedInitialValues}
                keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            >
                <FormHeader>
                    <FormTitle>Trigger</FormTitle>
                </FormHeader>
                <Trigger
                    provider={trigger.provider}
                    event={trigger.event}
                    editMode={Boolean(initialValues)}
                />
                <RenderIf isTrue={hasEnv}>
                    <FormHeader>
                        <FormTitle>Environment Variables</FormTitle>
                    </FormHeader>
                    <EnvironmentsFieldsContainer>
                        <Fields
                            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
                            env={env}
                            projectId={projectId}
                            version={version}
                            initialValues={normalizedInitialValues}
                        />
                    </EnvironmentsFieldsContainer>
                </RenderIf>
                <RenderIf isTrue={isCallable}>
                    <FormHeader>
                        <FormTitle>Policies</FormTitle>
                        <DocsLink
                            href="https://docs.functions.store/functions/reference/policies"
                            target="_blank"
                        >
                            View Docs
                            <OpenIcon />
                        </DocsLink>
                    </FormHeader>
                    <Field name="policies" component={Policies} />
                </RenderIf>
                <Runtime />
            </UniversalForm>
            <Bottom />
        </FormContainer>
    );
};

Form.propTypes = {
    id: PropTypes.string,
    projectId: PropTypes.string,
    version: PropTypes.string,
    onSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    fields: PropTypes.object,
    keepDirtyOnReinitialize: PropTypes.bool,
};

Form.defaultProps = {
    id: undefined,
    projectId: undefined,
    version: undefined,
    onSubmit: () => {},
    initialValues: undefined,
    fields: undefined,
    keepDirtyOnReinitialize: false,
};

export default React.memo(Form, isEqual);
