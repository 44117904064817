import { useParams } from 'react-router-dom';
import { Column, RenderIf } from 'react-rainbow-components';
import { InputSearch } from '@rainbow-modules/search';
import useProjectsUsers from '../../../data/hooks/useProjectsUsers';
import AlignLeftContent from '../../../components/AlignLeftContent';
import UserColumn from '../../../components/UserColumn';
import UidColumn from './columns/uid';
import ProviderColumn from './columns/provider';
import FormattedDateColumn from '../../../components/FormattedDate';
import Message from '../../../components/Message';
import {
    Container, Header, StyledTable, StyledHeader, MessageIcon,
} from './styled';

export default function Users() {
    const { projectId } = useParams();
    const {
        users, isLoading, search, searchValue,
    } = useProjectsUsers({ projectId });
    const shouldRenderSearch = users.length > 0 || (searchValue && users.length === 0);

    return (
        <Container>
            <RenderIf isTrue={shouldRenderSearch}>
                <Header data-cy="users-header">
                    <InputSearch
                        onSearch={search}
                        variant="default"
                        placeholder="Search by email address, phone number, or user UID"
                    />
                </Header>
            </RenderIf>
            <RenderIf isTrue={isLoading || users.length > 0}>
                <StyledTable
                    data={!isLoading && users}
                    isLoading={isLoading}
                    keyField="uid"
                    variant="listview"
                >
                    <Column
                        header={<StyledHeader>uid</StyledHeader>}
                        field="uid"
                        component={UidColumn}
                        projectId={projectId}
                    />
                    <Column
                        header={<StyledHeader>providers</StyledHeader>}
                        width={100}
                        field="providerData"
                        component={ProviderColumn}
                    />
                    <Column
                        header={<StyledHeader>displayName</StyledHeader>}
                        field="displayName"
                        component={UserColumn}
                    />
                    <Column
                        header={<StyledHeader>email</StyledHeader>}
                        field="email"
                        component={AlignLeftContent}
                    />
                    <Column
                        header={<StyledHeader>creationTime</StyledHeader>}
                        field="metadata.creationTime"
                        component={FormattedDateColumn}
                    />
                    <Column
                        header={<StyledHeader>lastSignInTime</StyledHeader>}
                        field="metadata.lastSignInTime"
                        component={FormattedDateColumn}
                    />
                </StyledTable>
            </RenderIf>
            <RenderIf isTrue={!isLoading && users.length === 0}>
                <Message
                    className="rainbow-m-bottom_xx-large"
                    icon={<MessageIcon />}
                    title="This project has no user information to display."
                    description="Manage all the users of your project."
                />
            </RenderIf>
        </Container>
    );
}
