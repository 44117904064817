import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import FunctionIcon from '../../../../../../components/FunctionIcon';
import {
    FunctionColumnContainer,
    FunctionInfoContainer,
    FunctionCheckInfo,
    FunctionName,
    WorkspaceName,
    ImportedBadge,
} from '../styled';

const FunctionColumn = ({ row }) => {
    const {
        id, isImported, name, workspace, iconUrl,
    } = row;
    const label = name || id;
    return (
        <FunctionColumnContainer>
            <FunctionIcon url={iconUrl} functionType="marketplace" width="30px" height="30px" />
            <FunctionInfoContainer>
                <FunctionName>
                    {label}
                </FunctionName>
                <WorkspaceName>
                    {workspace}
                </WorkspaceName>
            </FunctionInfoContainer>
            <RenderIf isTrue={isImported}>
                <FunctionCheckInfo>
                    <ImportedBadge size="small" label="Imported" />
                </FunctionCheckInfo>
            </RenderIf>
        </FunctionColumnContainer>
    );
};

FunctionColumn.propTypes = {
    row: PropTypes.object.isRequired,
};

export default FunctionColumn;
