/* eslint-disable react/jsx-props-no-spreading */
import {
    useCallback, useRef, useState, useMemo, useEffect,
} from 'react';
import {
    Picklist,
    Option,
    Column,
    ButtonIcon,
} from 'react-rainbow-components';
import { FirestoreTableWithCursors } from '@rainbow-modules/listview';
import { useParams } from 'react-router-dom';
import FlowColumn from './columns/flowColumn';
import EnvironmentColumn from './columns/environmentColumn';
// eslint-disable-next-line import/no-cycle
import StatusColumn from './columns/statusColumn';
import useDeploymentsOnce from '../../../../../data/hooks/useDeploymentsOnce';
import DurationColumn from './columns/durationColumn';
import ReRun from '../../../../../components/Icons/rerun';
import {
    Header, HistoryContent,
} from './styled';
import statusLabelsMap from './statusLabelsMap';
import StartDateColumn from './columns/startDateColumn';
import Context from './context';

const Action = () => <ButtonIcon disabled className="rainbow-m-top_xx-small" icon={<ReRun />} />;

export default function History() {
    const { workspace } = useParams();
    const tableRef = useRef();
    const [selectedFlow, setSelectedFlow] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [deployments] = useDeploymentsOnce({ workspace, includeDeleted: true });
    const [openRow, setOpenRow] = useState();
    const deploymentIdNameMap = useMemo(() => deployments.reduce(
        (acc, deployment) => ({
            ...acc,
            [deployment.id]: deployment.name,
        }),
        {},
    ), [deployments]);
    const openRowHandler = (id) => {
        if (openRow !== id) {
            return setOpenRow(id);
        }
        return setOpenRow(undefined);
    };
    const query = useCallback((ref) => {
        let internalRef = ref;
        if (selectedFlow) {
            internalRef = internalRef.where('deploymentId', '==', selectedFlow);
        }
        if (selectedStatus) {
            internalRef = internalRef.where('status', '==', selectedStatus);
        }
        return internalRef.where('workspace', '==', workspace).orderBy('createdAt', 'desc');
    }, [selectedFlow, selectedStatus, workspace]);
    useEffect(() => {
        if (tableRef.current
            && tableRef.current.refresh
        ) {
            tableRef.current.refresh();
        }
    }, [selectedFlow, selectedStatus]);
    return (
        <HistoryContent>
            <Header>
                <Picklist
                    variant="shaded"
                    label="Select Flow"
                    hideLabel
                    className="rainbow-m-right_small"
                    enableSearch
                    value={{
                        label: deploymentIdNameMap[selectedFlow] || 'All Flows',
                        name: selectedFlow || 'all-flows',
                        value: selectedFlow || 'all-flows',
                    }}
                    onChange={(newSelectedFlow) => setSelectedFlow(newSelectedFlow.value)}
                >
                    <Option label="All Flows" name="all-flows" />
                    {
                        deployments.map(
                            (deployment) => (
                                <Option
                                    label={deployment.name}
                                    name={deployment.id}
                                    value={deployment.id}
                                />
                            ),
                        )
                    }
                </Picklist>
                <Picklist
                    variant="shaded"
                    label="Select Status"
                    hideLabel
                    className="rainbow-m-right_small"
                    value={{
                        label: statusLabelsMap[selectedStatus] || 'All Status',
                        name: selectedStatus || 'all-status',
                        value: selectedStatus || 'all-status',
                    }}
                    onChange={(newSelectedStatus) => setSelectedStatus(newSelectedStatus.value)}
                >
                    <Option label="All Status" name="all-status" />
                    {
                        Object.keys(statusLabelsMap).map(
                            (statusValue) => (
                                <Option
                                    label={statusLabelsMap[statusValue]}
                                    name={statusValue}
                                    value={statusValue}
                                />
                            ),
                        )
                    }
                </Picklist>
            </Header>
            <Context.Provider value={openRow}>
                <FirestoreTableWithCursors
                    collection="deploymentHistory"
                    isCollectionGroup
                    variant="listview"
                    pageSize={10}
                    query={query}
                    ref={tableRef}
                >
                    <Column
                        header="Flow"
                        field="deploymentId"
                        headerAlignment="left"
                        defaultWidth={200}
                        cellAlignment="left"
                        component={
                            (props) => (
                                <FlowColumn
                                    {...props}
                                    deploymentIdNameMap={deploymentIdNameMap}
                                />
                            )
                        }
                    />
                    <Column
                        header="Status"
                        cellAlignment="left"
                        component={(props) => (
                            <StatusColumn
                                {...props}
                                onOpen={openRowHandler}
                            />
                        )}
                    />
                    <Column header="Environment" field="environmentName" defaultWidth={200} cellAlignment="left" component={EnvironmentColumn} />
                    <Column header="Start" field="createdAt" defaultWidth={140} component={StartDateColumn} />
                    <Column header="Duration" field="duration" defaultWidth={140} component={DurationColumn} />
                    <Column field="id" width={60} component={Action} />
                </FirestoreTableWithCursors>
            </Context.Provider>
        </HistoryContent>
    );
}
