import { useParams, useLocation, useHistory } from 'react-router-dom';
import { showAppMessage, hideAppMessage, showAppNotification } from '@rainbow-modules/app';
import { Button, RenderIf } from 'react-rainbow-components';
import useCallableMutation from '../../data/hooks/useCallableMutation';
import BackButton from '../../components/BackButton';
import Loader from './loader';
import Billing from './billing';
import {
    Container,
    Title,
    Content,
    SectionContainer,
    Description,
    Footer,
    SectionTitle,
    Highlight,
    StyledLink,
    InfoContainer,
    InfoIcon,
} from './styled';
import ServicesList from './servicesList';
import { getNotEnabledServices } from '../../helpers/projectRequirements';

const showError = (error) => showAppMessage({
    message: error.message,
    variant: 'error',
});

export default function ProjectRequirements() {
    const { projectId } = useParams();
    const { state: locationState } = useLocation();
    const { replace } = useHistory();

    const goToProject = () => {
        replace(`/app/project/${projectId}`);
    };

    const {
        mutate, isLoading, isError,
    } = useCallableMutation('projects-enableRequiredApi', {
        onMutate: hideAppMessage,
        onError: showError,
        onSuccess: () => {
            goToProject();
            return showAppNotification({
                icon: 'success',
                description: 'The APIs has been enabled successfully.',
                title: 'Success!',
                timeout: 5000,
            });
        },
    });

    if (!locationState) {
        replace('/app/projects');
        return null;
    }

    const { billingEnabled, services } = locationState;
    const disabledServices = getNotEnabledServices(services);
    const requestEnableApi = () => mutate({
        projectId,
        services: disabledServices,
    });

    const skipButtonLabel = isError ? 'Continue anyway' : 'Skip';

    return (
        <Container>
            <BackButton
                to="/app/projects"
                label="Back to Projects"
                className="rainbow-m-left_large rainbow-m-bottom_x-large"
            />
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Loader
                        title={(
                            <>
                                Enabling APIs in &quot;
                                <Highlight>{projectId}</Highlight>
                                &quot; project
                            </>
                        )}
                        message="We are enabling the necessary API, permissions and requirements in your project. The process should take some minutes."
                    />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    <Title>
                        Project
                        <Highlight>
                            &quot;
                            {projectId}
                            &quot;
                        </Highlight>
                        requirements

                    </Title>
                    <Description className="rainbow-m-bottom_medium">
                        We verified your project and noticed that your account
                        needs to meet some requirements to get started with functions.store.
                        You can enable everything now (recommended) to avoid drawbacks
                        or do it later.
                        <StyledLink
                            target="blank"
                            href="https://docs.functions.store/functions/reference/requirements"
                            className="rainbow-m-left_x-small"
                        >
                            Learn More
                        </StyledLink>
                    </Description>
                    <RenderIf isTrue={!billingEnabled}>
                        <SectionContainer>
                            <SectionTitle>Billing</SectionTitle>
                            <Billing />
                            <InfoContainer>
                                <InfoIcon />
                                If you linked a Billing account to your project recently,
                                wait a few minutes for the action to propagate to our
                                systems and retry.
                            </InfoContainer>
                        </SectionContainer>
                    </RenderIf>
                    <RenderIf isTrue={billingEnabled && disabledServices.length > 0}>
                        <SectionContainer>
                            <SectionTitle>APIs</SectionTitle>
                            <Description className="rainbow-m-bottom_medium">
                                Enable these APIs for your project to manage functions and
                                store sensitive data such as API keys, passwords, and certificates.
                            </Description>
                            <ServicesList services={services} />
                        </SectionContainer>
                    </RenderIf>
                    <Footer>
                        <Button
                            id="skip-button"
                            variant="outline-brand"
                            type="button"
                            label={skipButtonLabel}
                            onClick={goToProject}
                        />
                        <RenderIf isTrue={billingEnabled}>
                            <Button
                                id="enable-button"
                                variant="brand"
                                label="Enable APIs"
                                disabled={isError}
                                className="rainbow-m-left_small"
                                onClick={requestEnableApi}
                            />
                        </RenderIf>
                    </Footer>
                </RenderIf>
            </Content>
        </Container>
    );
}
