import { Redirect, Route, Switch } from 'react-router-dom';
import WorkspaceNavigationBar from '../../../layouts/WorkspaceNavigationBar';
import WorkspaceFunctions from './functions';
import WorkspaceCI from './ci';
import Settings from './settings';
import Deployments from './deployments';
import { Container, Content } from './styled';

const Workspace = () => (
    <Container>
        <WorkspaceNavigationBar />
        <Content>
            <Switch>
                <Route
                    path="/app/developer/workspaces/:workspace/build"
                    component={WorkspaceCI}
                />
                <Route
                    path="/app/developer/workspaces/:workspace/functions"
                    component={WorkspaceFunctions}
                />
                <Route
                    path="/app/developer/workspaces/:workspace/settings"
                    component={Settings}
                />
                <Route
                    path="/app/developer/workspaces/:workspace/deployments"
                    component={Deployments}
                />
                <Redirect
                    from="/app/developer/workspaces/:workspace"
                    to="/app/developer/workspaces/:workspace/build"
                />
            </Switch>
        </Content>
    </Container>
);

export default Workspace;
