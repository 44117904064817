import { useState } from 'react';
import useReduxForm from 'react-rainbow-components/libs/hooks/useReduxForm';
import PolicyComposer from '../PolicyComposer';
import AddPolicyModal from './addPolicyModal';
import getNormalizedPolicyFormValue from './helpers/getNormalizedPolicyFormValue';
import { PolicyContainer } from './styled';

const Policies = (props) => {
    const { value, onChange } = useReduxForm(props);
    const [isOpen, setOpenModal] = useState(false);
    const onCancelAddPolicy = () => setOpenModal(false);
    const finalValue = value || [];

    const onAddPolicy = (policy) => {
        onChange([...finalValue, getNormalizedPolicyFormValue(policy)]);
        setOpenModal(false);
    };

    return (
        <PolicyContainer>
            <PolicyComposer
                onAdd={() => setOpenModal(true)}
                value={finalValue}
                onChange={onChange}
            />
            <AddPolicyModal
                isOpen={isOpen}
                onCancel={onCancelAddPolicy}
                onAddPolicy={onAddPolicy}
            />
        </PolicyContainer>
    );
};

export default Policies;
