import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    text-decoration: underline;
    text-align: left;
    margin-right: 6px;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    :hover, :focus {
        color: ${(props) => props.theme.rainbow.palette.text.label};
    }
`;

const LinkColumn = ({ value, to }) => <StyledLink to={to}>{value}</StyledLink>;

LinkColumn.propTypes = {
    value: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default LinkColumn;
