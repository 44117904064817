import PropTypes from 'prop-types';
import { UniversalPickerOption } from '@rainbow-modules/forms';
import { RenderIf } from 'react-rainbow-components';
import {
    PolicyIcon,
    PolicyName,
    PolicyItem,
    StyledChevronRight,
} from './styled';

const Item = (props) => {
    // eslint-disable-next-line react/prop-types
    const { isSelected, children } = props;

    return (
        <PolicyItem isSelected={isSelected}>
            {children}
            <RenderIf isTrue={isSelected}>
                <StyledChevronRight />
            </RenderIf>
        </PolicyItem>
    );
};

const PickerOption = (props) => {
    const { policyName, policyIcon } = props;
    const hasPolicyIcon = policyIcon ? 'img' : undefined;

    return (
        <UniversalPickerOption name={policyName} component={Item}>
            <PolicyIcon as={hasPolicyIcon} src={policyIcon} />
            <PolicyName>
                {policyName}
            </PolicyName>
        </UniversalPickerOption>

    );
};

export default PickerOption;

PickerOption.propTypes = {
    policyName: PropTypes.string,
    policyIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PickerOption.defaultProps = {
    policyName: undefined,
    policyIcon: undefined,
};
