export const getNotEnabledServices = (services = []) => services.filter(
    ({ state }) => state !== 'ENABLED',
).reduce(
    (result, { serviceId }) => [...result, serviceId],
    [],
);

export const checkPassed = (services) => {
    if (services) {
        return services.every(({ state }) => state === 'ENABLED');
    }
    return false;
};
