/* eslint-disable react/prop-types */
import BadgeText from '../../../../../../components/BadgeText';
import {
    SummaryTestRow,
    TestSummaryRowTitle,
} from './styled';

export default function SummaryRow(props) {
    const {
        name,
        numFailed,
        numPassed,
        numTotal,
    } = props;

    return (
        <SummaryTestRow data-cy="summary-test-row">
            <TestSummaryRowTitle>
                {name}
            </TestSummaryRowTitle>
            <BadgeText
                label={`${numFailed} failed`}
                variant="error"
                className="rainbow-m-right_medium"
            />
            <BadgeText
                className="rainbow-m-right_medium"
                label={`${numPassed} passed`}
                variant="success"
            />
            <BadgeText label={`${numTotal} total`} />
        </SummaryTestRow>
    );
}
