import styled from 'styled-components';
import { Button } from 'react-rainbow-components';
import Project from '../Icons/project';
import Upgrade from '../Icons/upgrade';
import { WHITE, GRAY } from '../../colors';

export const UsageContainer = styled.div`
    width: 100%;
    padding: 12px 12px 0 12px;
`;

export const UsageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
`;

export const UsageHeaderTitle = styled.h1`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
`;

export const UsageIcon = styled(Project)`
    width: 18px;
    height: 18px;
    color: ${GRAY};
    margin-right: 8px;
`;

export const UsageCounter = styled.h5`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    display: flex;
    align-items: center;
`;

export const Bold = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin: 0 2px;
`;

export const UpgradeText = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    margin: 0 16px 8px 16px;
`;

export const UpgradeIcon = styled(Upgrade)`
    color: ${WHITE};
    margin-left: -4px;
    margin-right: 8px;
    height: 18px;
    width: 18px;
`;

export const StyledButton = styled(Button)`
    color: ${WHITE};

    :hover, :active, :focus {
        color: ${WHITE};
    }
`;
