/* eslint-disable react/prop-types */
import { RenderIf } from 'react-rainbow-components';
import {
    FeatureContainer,
    StyledCheck,
    StyledFeature,
} from './styled';

const FeatureList = ({ features }) => {
    if (features) {
        return Object.keys(features).map((key) => {
            const { name, limit } = features[key];
            return (
                <FeatureContainer key={key}>
                    <StyledCheck />
                    <RenderIf isTrue={typeof limit === 'number'}>
                        <StyledFeature>{limit}</StyledFeature>
                    </RenderIf>
                    <StyledFeature>{name}</StyledFeature>
                </FeatureContainer>
            );
        });
    }
    return null;
};

export default FeatureList;
