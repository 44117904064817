import { useMemo } from 'react';
import { useCollection, useHttpAuthQuery } from '@rainbow-modules/firebase-hooks';

const useProducts = ({ hideInternalPlans = false } = {}) => {
    const { data, isLoading: isLoadingProducts } = useHttpAuthQuery({
        functionName: 'xStripe_subscriptions',
        pathname: 'products',
        key: 'products',
    }, {
        staleTime: Infinity,
    });
    const [featuresByProduct, isLoadingFeatures] = useCollection({
        path: 'products',
        flat: true,
    });
    const products = useMemo(() => {
        if (data) {
            const productFeaturesMap = featuresByProduct.reduce((acc, { id, ...rest }) => ({
                ...acc,
                [id]: rest,
            }), {});
            if (hideInternalPlans) {
                return data.filter((item) => !item?.metadata?.internal).map((productData) => ({
                    ...productData,
                    features: productFeaturesMap[productData.id],
                }));
            }
            return data.map((productData) => ({
                ...productData,
                features: productFeaturesMap[productData.id],
            }));
        }
        return [];
    }, [data, featuresByProduct, hideInternalPlans]);

    return [products, isLoadingProducts || isLoadingFeatures];
};

export default useProducts;
