import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import styled from 'styled-components';

const MessageLink = styled.a`
    color: inherit;
    text-decoration: underline;

    :hover,:active,:visited {
        color: inherit;
    }
`;

const getErrorMessage = (error) => {
    if (typeof error === 'string') return error;
    return error.message || error.toString();
};

const ErrorMessage = ({ error }) => {
    const message = getErrorMessage(error);
    const url = error.details && error.details.url;
    return (
        <>
            <span>{message}</span>
            <RenderIf isTrue={url}>
                <MessageLink
                    className="rainbow-m-left_x-small"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    More Information
                </MessageLink>
            </RenderIf>
        </>
    );
};

ErrorMessage.propTypes = {
    error: PropTypes.object,
};

ErrorMessage.defaultProps = {
    error: {},
};

export default ErrorMessage;
