import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    Container,
    ItemContainer,
    ItemLabel,
    ItemDescription,
    Line,
    StyledSuccessIcon,
    StyledErrorIcon,
    Content,
} from './styled';

// eslint-disable-next-line react/prop-types
const StateIcon = ({ state, size }) => {
    if (state === 'success') return <StyledSuccessIcon size={size} />;
    return <StyledErrorIcon size={size} />;
};

function InfoCard({
    variant, label, description, hideIcon, size, className, style,
}) {
    return (
        <Container className={className} style={style} data-cy="info-card">
            <ItemContainer>
                <Line variant={variant} />
                <RenderIf isTrue={!hideIcon}>
                    <StateIcon state={variant} size={size} />
                </RenderIf>
                <Content>
                    <ItemLabel size={size}>{label}</ItemLabel>
                    <ItemDescription size={size}>{description}</ItemDescription>
                </Content>
            </ItemContainer>
        </Container>
    );
}

InfoCard.propTypes = {
    variant: PropTypes.oneOf(['default', 'success', 'error', 'warning', 'info']),
    size: PropTypes.oneOf(['small', 'medium']),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideIcon: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
};

InfoCard.defaultProps = {
    variant: 'default',
    label: undefined,
    description: undefined,
    hideIcon: false,
    size: 'medium',
    className: undefined,
    style: undefined,
};

export default InfoCard;
