import { RenderIf } from 'react-rainbow-components';
import {
    Redirect,
    Route,
    Switch,
    useParams,
} from 'react-router-dom';
import History from './history';
import Flows from './flows';
import DeploymentsTabset from './tabset';
import FlowDetails from './flowDetails';
import {
    Title, Container, Description, Header,
} from './styled';
import ServiceLimiteInfo from '../../../../components/ServiceLimiteInfo';
import useUpgradeToast from '../../../../data/hooks/useUpgradeToast';
import usePlanServiceCount from '../../../../data/hooks/usePlanServiceCount';
import useWorkspaceOwnership from '../../../../data/hooks/useWorkspaceOwnership';

const Main = () => {
    const { workspace } = useParams();
    const [isWorkspaceOwner] = useWorkspaceOwnership(workspace);
    const {
        isLoading: isLoadingServiceCount, count, limit, hasReachedLimit, product, hasService,
    } = usePlanServiceCount({ serviceName: 'deploymentsExecutions' });
    useUpgradeToast({
        hasReachedLimit,
        serviceName: 'deploymentsExecutions',
        isLoadingServiceCount,
    });
    return (
        <Container>
            <Header>
                <div>
                    <Title>Deployments</Title>
                    <Description>
                        Deploy your functions automatically
                        to your environments only when they changed.
                    </Description>
                </div>
                <RenderIf isTrue={isWorkspaceOwner && !isLoadingServiceCount && hasService}>
                    <ServiceLimiteInfo
                        label={`${count} of ${limit} deploys`}
                        helpTextTitle="Deploys on your current plan"
                        featureUsageName="Deploys"
                        featureUsageSummaryCounter={`${count} of ${limit} deploys`}
                        featureUsageCounter={`${count} deploys`}
                        upgradeText="If you need more deploys you have to upgrade your plan."
                        limit={limit}
                        count={count}
                        product={product}
                    />
                </RenderIf>
            </Header>
            <DeploymentsTabset />
            <Switch>
                <Route path="/app/developer/workspaces/:workspace/deployments/flows" component={Flows} />
                <Route path="/app/developer/workspaces/:workspace/deployments/history" component={History} />
                <Redirect from="/app/developer/workspaces/:workspace/deployments" to="/app/developer/workspaces/:workspace/deployments/flows" />
            </Switch>
        </Container>
    );
};

export default function Deployments() {
    return (
        <Switch>
            <Route exact path="/app/developer/workspaces/:workspace/deployments/flows/:deploymentId" component={FlowDetails} />
            <Route path="/app/developer/workspaces/:workspace/deployments" component={Main} />
        </Switch>
    );
}
