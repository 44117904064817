import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const EmptyLabel = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: ${(props) => props.theme.rainbow.palette.getContrastText(props.theme.rainbow.palette.background.main)};
    padding: 36px;
`;
