import styled from 'styled-components';
import { Button, Spinner, Table } from 'react-rainbow-components';
import { KeyFilled } from '@rainbow-modules/icons';

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    padding: 0 20px;
`;

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const Title = styled.h1`
    font-size: 24px;
    letter-spacing: 0.25px;
    margin: 0 0 4px 8px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.label};
`;

export const Description = styled.h2`
    font-size: 16px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin: 0 0 0 8px;
`;

export const Content = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 8px;
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 80px;
`;

export const MessageIcon = styled(KeyFilled)`
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const MessageTitle = styled.h2`
    font-size: 18px;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    margin-bottom: 8px;
`;

export const MessageDescription = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
    margin-bottom: 12px;
`;

export const StyledSpinner = styled(Spinner)`
    position: relative;
    z-index: 0;
    top: unset;
    left: unset;
    margin-left: 32px;
`;

export const ActionMessage = styled.div`
    color: ${(props) => props.theme.rainbow.palette.text.label};
    padding: 0 1rem;
    text-align: center;
`;

export const StyledTable = styled(Table)`
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    margin-bottom: 12px;
    padding: 0 4px;

    td[role=gridcell] {
        :last-child > div {
            border: none;
        }
    }
`;

export const PermissionsSectionContainer = styled.div`
    margin: 16px 0 80px 0;
    max-width: 100%;
    min-width: 100%;

    @media (max-width: 1024px) {
        padding: 0 24px;
    }
`;

export const ActionsContainer = styled.div`
    box-sizing: border-box;
    padding: 0;
    border: none;
    line-height: unset;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    overflow: hidden;

    :focus {
        outline: none;
    }
`;

export const CreateButton = styled(Button)`
    width: max-content;
`;
