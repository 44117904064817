/* eslint-disable react/prop-types */
import { RenderIf } from 'react-rainbow-components';
import CoverageBadge from '../CoverageBadge';
import useFunctionCoverage from '../../data/hooks/useFunctionCoverage';
import getPercentValueFromCoverage from '../../helpers/getPercentValueFromCoverage';
import { SecondaryInformationTitle } from './styled';

const FunctionCoverageBadge = ({ data }) => {
    const { buildId, workspace, name } = data;

    const [functionCoverage] = useFunctionCoverage({
        buildId,
        workspace,
        name,
    });

    const coveragePercent = getPercentValueFromCoverage(
        functionCoverage?.description?.total?.lines?.pct,
    );

    return (
        <RenderIf isTrue={functionCoverage}>
            <SecondaryInformationTitle>Test</SecondaryInformationTitle>
            <CoverageBadge className="rainbow-m-top_xx-small" value={coveragePercent} />
        </RenderIf>
    );
};

export default FunctionCoverageBadge;
