import PropTypes from 'prop-types';
import FunctionOverview from '../../../../components/FunctionOverview';
import { TabContent } from '../styled';

const Overview = ({ data, isPurchased, price }) => (
    <TabContent className="rainbow-flex rainbow-m-top_medium">
        <FunctionOverview data={data} isPurchased={isPurchased} price={price} />
    </TabContent>
);

Overview.propTypes = {
    data: PropTypes.object,
    isPurchased: PropTypes.bool,
    price: PropTypes.number,
};

Overview.defaultProps = {
    data: undefined,
    price: undefined,
    isPurchased: false,
};
export default Overview;
