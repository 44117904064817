import PropTypes from 'prop-types';
// TODO: move this this component to this folder (src/pages/secrets)
import PermissionsList from '../projectRequirements/permissionsList';
import {
    MessageContainer,
    MessageDescription,
    PermissionsSectionContainer,
} from './styled';
import {
    SectionTitle,
    DescriptionContainer,
    Description,
    StyledLink,
} from '../projectRequirements/styled';

export default function ErrorMessage({ error }) {
    if (error && error.code === 'permission-denied') {
        const { permissions } = error.details;
        return (
            <MessageContainer>
                <PermissionsSectionContainer>
                    <SectionTitle>Permissions required</SectionTitle>
                    <DescriptionContainer>
                        <Description>
                            Your account doesn&apos;t have required permissions to use Secrets.
                        </Description>
                        <StyledLink
                            target="blank"
                            href="https://docs.functions.store/functions/reference/requirements/secret-permissions"
                            className="rainbow-m-left_x-small"
                        >
                            Learn More
                        </StyledLink>
                    </DescriptionContainer>
                    <PermissionsList permissions={permissions} />
                </PermissionsSectionContainer>
            </MessageContainer>
        );
    }

    return (
        <MessageContainer>
            <MessageDescription>{error.toString()}</MessageDescription>
        </MessageContainer>
    );
}

ErrorMessage.propTypes = {
    error: PropTypes.object,
};

ErrorMessage.defaultProps = {
    error: undefined,
};
