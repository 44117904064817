import PropTypes from 'prop-types';
import { useRef } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import formatCurrencyPart from '../../helpers/formatCurrencyPart';
import getMoneySymbol from '../../helpers/getMoneySymbol';
import getPlanPrice from '../../helpers/getPlanPrice';
import FeatureList from '../../components/FeatureList';
import {
    CardContainer,
    CardTitle,
    CardDescription,
    DollarSymbol,
    Price,
    PriceContainer,
    FeaturesTitle,
    StyledButton,
    StyledCurrentText,
    ChatIcon,
} from './styled';

const formStyles = { width: 0, height: 0 };

const Card = ({
    features,
    plan,
    price,
    onSelect,
    description,
    period,
    currency,
    color,
    isCurrentPlan,
    custom: isCustom,
}) => {
    const formattedCurrencyParts = formatCurrencyPart(price);
    const moneySymbol = getMoneySymbol(formattedCurrencyParts);
    const planPrice = getPlanPrice(formattedCurrencyParts);
    const planCurrency = currency.toUpperCase();
    const requestDeveloperFormRef = useRef();

    return (
        <CardContainer color={color} isCurrentPlan={isCurrentPlan}>
            <ReactTypeformEmbed
                url="https://form.typeform.com/to/ywRmPzLZ?typeform-medium=embed-snippet"
                ref={requestDeveloperFormRef}
                style={formStyles}
                popup
            />
            <div>
                <CardTitle>{plan}</CardTitle>
                <CardDescription>{description}</CardDescription>
                <RenderIf isTrue={isCustom}>
                    <ChatIcon className="rainbow-m-bottom_large" />
                    <FeaturesTitle className="rainbow-m-top_medium">
                        Contact our Sales team for custom features your
                        organization&apos;s billing requirements.
                    </FeaturesTitle>
                </RenderIf>
                <PriceContainer>
                    <RenderIf isTrue={!isCustom}>
                        <DollarSymbol>{moneySymbol}</DollarSymbol>
                        <div>
                            <Price>{planPrice}</Price>
                            <CardDescription>
                                {planCurrency}
                                {' '}
                                /
                                {' '}
                                {period}
                            </CardDescription>
                        </div>
                    </RenderIf>
                </PriceContainer>
                <RenderIf isTrue={!isCustom}>
                    <FeaturesTitle>Top Features:</FeaturesTitle>
                    <FeatureList features={features} />
                </RenderIf>
            </div>
            <RenderIf isTrue={!isCurrentPlan && !isCustom}>
                <StyledButton
                    label="Change Plan"
                    variant="brand"
                    onClick={onSelect}
                    id={`Upgrade-Now-${plan}`}
                />
            </RenderIf>
            <RenderIf isTrue={isCurrentPlan && !isCustom}>
                <StyledCurrentText plan={plan} data-cy={plan}>Current Plan</StyledCurrentText>
            </RenderIf>
            <RenderIf isTrue={isCustom}>
                <StyledButton
                    id="contact-custom-button"
                    label="Contact us"
                    variant="brand"
                    onClick={() => requestDeveloperFormRef.current.typeform.open()}
                />
            </RenderIf>
        </CardContainer>
    );
};

Card.propTypes = {
    features: PropTypes.array,
    plan: PropTypes.string,
    price: PropTypes.string,
    onSelect: PropTypes.func,
    description: PropTypes.string,
    period: PropTypes.string,
    currency: PropTypes.string,
    color: PropTypes.string,
    isCurrentPlan: PropTypes.bool,
    custom: PropTypes.string,
};

Card.defaultProps = {
    features: [],
    plan: '',
    price: '',
    onSelect: () => {},
    description: '',
    period: '',
    currency: '',
    color: '',
    isCurrentPlan: false,
    custom: undefined,
};

export default Card;
