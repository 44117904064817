import PropTypes from 'prop-types';
import SourceCodeExplorer from '../../../../../../components/SourceCodeExplorer';
import {
    TabContent,
    SourceCodeContainer,
} from '../styled';

const Source = ({ data }) => (
    <TabContent className="rainbow-flex">
        <SourceCodeContainer className="rainbow-m-bottom_small rainbow-m-top_small">
            <SourceCodeExplorer functionData={data} />
        </SourceCodeContainer>
    </TabContent>
);

Source.propTypes = {
    data: PropTypes.object,
};

Source.defaultProps = {
    data: undefined,
};

export default Source;
