import { useState } from 'react';
import PropTypes from 'prop-types';
import { pick, map } from 'lodash';
import { Plus } from '@rainbow-modules/icons';
import {
    Button,
    RenderIf,
    ButtonIcon,
} from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { StackedAccordionSection } from '@rainbow-modules/layout';
import getDoc from '../../../../data/services/firestore/getDoc';
import getCollection from '../../../../data/services/firestore/getCollection';
import formatVersionsData from '../../helpers/formatVersionsData';
import { IMPORT_FUNCTIONS_MODAL } from '../../../../constants';
import FunctionsTree from './functionsTree';
import Version from './version';
import Message from '../../../../components/Message';
import ImportFunctionsModal from './importFunctionsModal';
import {
    StyledResizableColumns,
    RightColumn,
    StyledStackedAccordion,
    ImportIcon,
    AccordionHeaderContainer,
    StyledModal,
} from './styled';
import VersionImported from './versionImported';
import useWorkspaceFunctionsImport from '../../../../data/hooks/useWorkspaceImportFunctions';

const getPublishedMarketplaceFunction = ({ workspace, functionId }) => getDoc(`workspaces/${workspace}/marketplace/${functionId}`);

const getImportedFunction = ({ workspace, functionId }) => getDoc(`workspaces/${workspace}/importedFunctions/${functionId}`);

const getVersionsForFunction = ({ workspace, functionId }) => {
    const path = `workspaces/${workspace}/functions/${functionId}/versions`;
    return getCollection({
        path,
        query: (refs) => refs.orderBy('createdAt', 'desc'),
    });
};

const getMarketplaceVersionsForFunction = ({ workspace, functionId }) => {
    const path = `workspaces/${workspace}/marketplace/${functionId}/versions`;
    return getCollection({
        path,
        query: (refs) => refs.orderBy('createdAt', 'desc'),
    });
};

const mapFunctions = (functionsList) => map(
    functionsList,
    (func) => pick(func, ['name', 'workspace']),
);

const Functions = ({ workspace, functions, importedFunctions }) => {
    const [selectedNode, setSelectedNode] = useState('');
    const [selectedImportedNode, setSelectedImportedNode] = useState('');
    const importFunctionsModalProps = useConnectModal(IMPORT_FUNCTIONS_MODAL);
    const [
        openImportModal, closeImportModal,
    ] = useOpenModal(IMPORT_FUNCTIONS_MODAL);
    const { importFunctions } = useWorkspaceFunctionsImport({
        workspace,
        submitSpinnerMessage: `Importing function(s) into workspace "${workspace}"`,
        successMessage: `Functions were successfully imported to workspace "${workspace}"`,
        onSuccess: closeImportModal,
    });

    const handleExpandFunction = async (functionId) => {
        const marketplaceData = await getPublishedMarketplaceFunction(
            { workspace, functionId },
        );
        const versions = await getVersionsForFunction(
            { workspace, functionId },
        );
        return formatVersionsData({
            functionId,
            versions,
            marketplaceData,
        });
    };

    const handleExpandImportedFunction = async (functionId) => {
        const funcData = await getImportedFunction(
            { workspace, functionId },
        );
        const { name, workspace: funcWorkspace } = funcData;
        const marketplaceData = await getPublishedMarketplaceFunction(
            { workspace: funcWorkspace, functionId: name },
        );
        const versions = await getMarketplaceVersionsForFunction(
            { workspace: funcWorkspace, functionId: name },
        );
        return formatVersionsData({
            importId: functionId,
            functionId: name,
            workspace: funcWorkspace,
            versions,
            marketplaceData,
            hideBadges: true,
        });
    };

    const handleImportFunctions = async (functionsList) => {
        if (functionsList.length > 0) {
            await importFunctions(mapFunctions(functionsList));
        }
    };

    const handleSelectFunction = (node) => {
        setSelectedNode(node);
        setSelectedImportedNode('');
    };

    const handleSelectImportedFunction = (node) => {
        setSelectedNode('');
        setSelectedImportedNode(node);
    };

    const handleImportedFunctionRemove = () => setSelectedImportedNode('');

    const hasImports = importedFunctions && importedFunctions.length > 0;

    return (
        <>
            <StyledResizableColumns
                initialDividerPosition={280}
                minLeftWidth={{ px: 140 }}
                minRightWidth={{ px: 320 }}
                leftColumn={(
                    <StyledStackedAccordion activeSectionNames={['section-1', 'section-2']}>
                        <StackedAccordionSection label="Workspace Functions" name="section-1">
                            <FunctionsTree
                                functions={functions}
                                selectedNode={selectedNode}
                                onNodeExpand={handleExpandFunction}
                                onNodeSelect={handleSelectFunction}
                            />
                        </StackedAccordionSection>
                        <StackedAccordionSection
                            name="section-2"
                            label={(
                                <AccordionHeaderContainer>
                                    <div>Imported Functions</div>
                                    <ButtonIcon
                                        variant="border-filled"
                                        size="small"
                                        icon={<Plus />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openImportModal();
                                        }}
                                    />
                                </AccordionHeaderContainer>
                            )}
                        >
                            <RenderIf isTrue={hasImports}>
                                <FunctionsTree
                                    showWorkspaceName
                                    functions={importedFunctions}
                                    selectedNode={selectedImportedNode}
                                    onNodeExpand={handleExpandImportedFunction}
                                    onNodeSelect={handleSelectImportedFunction}
                                />
                            </RenderIf>
                            <RenderIf isTrue={!hasImports}>
                                <Message
                                    className="rainbow-m-right_large rainbow-m-left_large"
                                    title="Start importing functions"
                                    description="Add the functions you want to appear in the workspace."
                                    icon={<ImportIcon />}
                                    action={(
                                        <Button
                                            label="Add Function"
                                            size="small"
                                            variant="border-filled"
                                            className="rainbow-m-top_small"
                                            onClick={openImportModal}
                                        />
                                    )}
                                />
                            </RenderIf>
                        </StackedAccordionSection>
                    </StyledStackedAccordion>

                )}
                rightColumn={(
                    <>
                        <RenderIf isTrue={selectedNode}>
                            <RightColumn>
                                <Version workspace={workspace} selectedNode={selectedNode} />
                            </RightColumn>
                        </RenderIf>
                        <RenderIf isTrue={selectedImportedNode}>
                            <RightColumn>
                                <VersionImported
                                    workspace={workspace}
                                    selectedNode={selectedImportedNode}
                                    onDeleted={handleImportedFunctionRemove}
                                />
                            </RightColumn>
                        </RenderIf>
                    </>
                )}
            />
            <StyledModal
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...importFunctionsModalProps}
                onRequestClose={closeImportModal}
                size="medium"
                title={undefined}
            >
                <ImportFunctionsModal
                    workspace={workspace}
                    importedFunctions={importedFunctions}
                    onRequestClose={closeImportModal}
                    onFinished={handleImportFunctions}
                />
            </StyledModal>
        </>
    );
};

Functions.propTypes = {
    workspace: PropTypes.string.isRequired,
    functions: PropTypes.array,
    importedFunctions: PropTypes.array,
};

Functions.defaultProps = {
    functions: undefined,
    importedFunctions: undefined,
};

export default Functions;
