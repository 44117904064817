/* eslint-disable react/prop-types */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion, RenderIf, Button,
} from 'react-rainbow-components';
import { useConnectModal, useOpenModal } from '@rainbow-modules/hooks';
import { RecordDetails } from '@rainbow-modules/record';
import { useCollection } from '@rainbow-modules/firebase-hooks';
import Label from '../../../../components/LabelWithDescription';
import { PUBLISH_WORKSPACE_FUNCTION_MODAL, FUNCTION_INSTALL_PREVIEW_DRAWER } from '../../../../constants';
import formatDate from '../../../../helpers/formatDate';
import PublishModal from './publishModal';
import InstallDrawer from './installDrawer';
import BuildDetails from './buildDetails';
import {
    AccordionLabelContainer, StyledAccordionSection, PublishedBadge, StyledHelpText,
} from './styled';

export const AccordionSectionLabel = (props) => {
    const {
        sectionSelected,
        data: { id, createdAt },
        workspaceId,
        versionId,
        functionId,
        isPublished,
        showCannotPublishWarning,
    } = props;
    const [openFunctionInstallDrawer] = useOpenModal(
        FUNCTION_INSTALL_PREVIEW_DRAWER,
    );
    const buildName = `${id}`;
    const buildDate = formatDate(createdAt);

    const handleInstall = (e) => {
        e.stopPropagation();
        openFunctionInstallDrawer({
            buildId: id,
            functionId,
            workspaceId,
            versionId,
        });
    };

    return (
        <AccordionLabelContainer showCannotPublishWarning={showCannotPublishWarning}>
            <div className="rainbow-flex rainbow-align_start">
                <Label name={`Build: ${buildName}`} description={buildDate} />
                <RenderIf isTrue={isPublished}>
                    <PublishedBadge
                        className="rainbow-m-left_large rainbow-m-top_xx-small"
                        label="Published"
                        size="small"
                    />
                </RenderIf>
            </div>
            <div>
                <RenderIf isTrue={sectionSelected === id}>
                    <Button
                        label="Install"
                        variant="outline-brand"
                        size="small"
                        onClick={handleInstall}
                    />
                </RenderIf>
                <RenderIf isTrue={showCannotPublishWarning}>
                    <StyledHelpText
                        className="rainbow-m-left_large"
                        variant="warning"
                        title={`Version "${versionId}" is already published`}
                        text="New builds must be published with a version greater than the latest published version. You can upgrade the function version in the package.json."
                    />
                </RenderIf>
            </div>
        </AccordionLabelContainer>
    );
};

export const AccordionSection = (props) => {
    const {
        sectionSelected,
        data,
        workspaceId,
        versionId,
        functionId,
        isPublished,
        showCannotPublishWarning,
    } = props;
    const { id } = data;

    return (
        <StyledAccordionSection
            label={(
                <AccordionSectionLabel
                    sectionSelected={sectionSelected}
                    data={data}
                    workspaceId={workspaceId}
                    versionId={versionId}
                    functionId={functionId}
                    isPublished={isPublished}
                    showCannotPublishWarning={showCannotPublishWarning}
                />
            )}
            name={id}
        >
            <RecordDetails>
                <RenderIf isTrue={sectionSelected === id}>
                    <BuildDetails data={{ buildId: id, workspace: workspaceId, ...data }} />
                </RenderIf>
            </RecordDetails>
        </StyledAccordionSection>
    );
};

const findPublishedBuildDate = (data, publishedBuild) => {
    const publishedBuildData = data.find((item) => item.id === publishedBuild);
    if (publishedBuildData && publishedBuildData.createdAt && typeof publishedBuildData.createdAt.seconds === 'number') {
        return publishedBuildData.createdAt.seconds;
    }
    return undefined;
};

const Builds = (props) => {
    const {
        workspace, selectedNode, isPublished: isPublishedVersion, publishedBuild,
    } = props;
    const [, closeFunctionInstallDrawer] = useOpenModal(
        FUNCTION_INSTALL_PREVIEW_DRAWER,
    );
    const {
        functionId,
        id: versionId,
    } = selectedNode;
    const [data, isLoading] = useCollection({
        path: `/workspaces/${workspace}/functions/${functionId}/versions/${versionId}/builds`,
        flat: true,
        query: (query) => query.orderBy('createdAt', 'desc'),
    });
    const [sectionSelected, setSectionSelected] = useState();
    const connectedPublishModalProps = useConnectModal(PUBLISH_WORKSPACE_FUNCTION_MODAL);
    const functionInstallProps = useConnectModal(FUNCTION_INSTALL_PREVIEW_DRAWER);

    const publishedBuildDate = useMemo(
        () => findPublishedBuildDate(data, publishedBuild),
        [data, publishedBuild],
    );

    const handleToggle = (event, value) => {
        setSectionSelected(value || []);
    };

    return (
        <>
            <Accordion id="builds-accordion" onToggleSection={handleToggle} activeSectionNames={sectionSelected}>
                <RenderIf isTrue={!isLoading}>
                    {data.map((item) => {
                        const { id, createdAt } = item;
                        const isPublished = isPublishedVersion && publishedBuild === id;
                        const showCannotPublishWarning = createdAt
                            && createdAt.seconds > publishedBuildDate;
                        return (
                            <AccordionSection
                                key={id}
                                data={item}
                                isLoading={isLoading}
                                sectionSelected={sectionSelected}
                                workspaceId={workspace}
                                versionId={versionId}
                                functionId={functionId}
                                isPublished={isPublished}
                                showCannotPublishWarning={showCannotPublishWarning}
                            />
                        );
                    })}
                </RenderIf>
            </Accordion>
            <PublishModal
                // eslint-disable-next-line react/jsx-props-no-spreading
                connectedPublishModalProps={connectedPublishModalProps}
                builds={data}
                workspace={workspace}
                version={versionId}
                functionId={functionId}
            />
            <InstallDrawer
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...functionInstallProps}
                onRequestClose={closeFunctionInstallDrawer}
            />
        </>
    );
};

Builds.propTypes = {
    workspace: PropTypes.string,
    selectedNode: PropTypes.object,
    isPublished: PropTypes.bool,
    publishedBuild: PropTypes.string,
};

Builds.defaultProps = {
    workspace: undefined,
    selectedNode: undefined,
    isPublished: false,
    publishedBuild: undefined,
};

export default Builds;
