import { useState } from 'react';
import {
    Button,
    Column,
    RenderIf,
} from 'react-rainbow-components';
import { useParams, useHistory } from 'react-router-dom';
import { confirmModal } from '@rainbow-modules/app';
import { ColoredStatusColumn } from '@rainbow-modules/listview';
import useRemoveDeployment from '../../../../../data/hooks/deployments/useRemoveDeployment';
import useEnableDeployment from '../../../../../data/hooks/deployments/useEnableDeployment';
import useDisableDeployment from '../../../../../data/hooks/deployments/useDisableDeployment';
import FormattedDate from '../../../../../components/FormattedDate';
import NameColumn from './nameColumn';
import AddEditFlow from './addEditFlow';
import {
    Row, Subtitle, SmallDescription, Content, StyledTable, PlusIcon,
} from './styled';
import useDeployments from '../../../../../data/hooks/useDeployments';
import ButtonAction from './buttonAction';
import useCurrentUserRoleInWorkspace from '../../../../../data/hooks/useCurrentUserRoleInWorkspace';

const colors = {
    active: { backgroundColor: '#fff', color: '#8bc570' },
    disabled: { backgroundColor: '#fff', color: '#8592a0' },
};

export default function Flows() {
    const [isOpenDrawer, setOpenDrawer] = useState(false);
    const [flowEditing, setFlowEditing] = useState();
    const { workspace } = useParams();
    const [currentUserRole, isLoadingCurrentUserRole] = useCurrentUserRoleInWorkspace({
        workspace,
    });
    const currentUserIsViewer = currentUserRole === 'viewer';
    const { push } = useHistory();
    const [data, isLoadingDeployments] = useDeployments({
        workspace,
    });
    const removeDeployment = useRemoveDeployment();
    const enableDeployment = useEnableDeployment();
    const disableDeployment = useDisableDeployment();

    const handleRemove = async (item) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Flow',
            question: "This item will be deleted immediately. You can't undo this action.",
            okButtonLabel: 'Delete',
        });
        // eslint-disable-next-line no-alert
        if (result) { removeDeployment({ workspace, id: item.id }); }
    };
    const editFlow = ({ id, name, description }) => {
        setFlowEditing({ id, name, description });
        setOpenDrawer(true);
    };
    const closeDrawer = () => {
        setOpenDrawer(false);
        setFlowEditing(undefined);
    };
    const handleEnable = (item) => enableDeployment({ workspace, id: item.id });
    const handleDisable = (item) => disableDeployment({ workspace, id: item.id });

    const isLoading = isLoadingDeployments || isLoadingCurrentUserRole;

    return (
        <Content>
            <Row>
                <div>
                    <Subtitle>
                        Flows
                    </Subtitle>
                    <SmallDescription>
                        Find here all your deployment flows that you have been creating.
                    </SmallDescription>
                </div>
                <RenderIf isTrue={!isLoading && !currentUserIsViewer}>
                    <Button
                        className="rainbow-m-left_small"
                        variant="brand"
                        onClick={() => setOpenDrawer(true)}
                    >
                        <PlusIcon />
                        Flow
                    </Button>
                </RenderIf>
            </Row>
            <StyledTable data={data} keyField="id" variant="listview" isLoading={isLoading}>
                <Column
                    header="Flow Name"
                    field="name"
                    workspace={workspace}
                    component={NameColumn}
                    headerAlignment="left"
                />
                <Column
                    header="Status"
                    field="status"
                    defaultWidth={120}
                    colors={colors}
                    component={ColoredStatusColumn}
                />
                <Column
                    header="Created At"
                    field="createdAt"
                    cellAlignment="left"
                    defaultWidth={200}
                    component={FormattedDate}
                />
                <Column
                    width={60}
                    component={({ row }) => (
                        <ButtonAction
                            onViewDetails={() => push(`/app/developer/workspaces/${workspace}/deployments/flows/${row.id}`)}
                            onEdit={() => editFlow(row)}
                            onEnable={() => handleEnable(row)}
                            onDisable={() => handleDisable(row)}
                            onDelete={() => handleRemove(row)}
                            status={row.status}
                            disabled={currentUserIsViewer}
                        />
                    )}
                />
            </StyledTable>
            <AddEditFlow
                flow={flowEditing}
                isOpen={isOpenDrawer}
                onRequestClose={closeDrawer}
            />
        </Content>
    );
}
