import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Picklist, RenderIf } from 'react-rainbow-components';
import useFunctionSupportedRegions from '../../data/hooks/useFunctionSupportedRegions';
import getOptionLabel from './helpers/getOptionLabel';
import RegionOptions from './components/regionOptions';
import {
    EmptyLabel,
} from './styled';

export default function RegionPicker(props) {
    const {
        projectId,
        value,
        onChange,
        disabled,
        className,
        label,
    } = props;
    const [regions, isLoading] = useFunctionSupportedRegions({ projectId });
    const regionIdToLabelMap = useMemo(() => regions.reduce((acc, region) => {
        const { locationId } = region;
        return {
            ...acc,
            [locationId]: getOptionLabel(region),
        };
    }, {}), [regions]);
    const hasRegions = regions?.length > 0;
    return (
        <Picklist
            label={label}
            className={className}
            id="region-picker"
            placeholder="Select Region"
            isLoading={isLoading}
            onChange={(selectedOption) => onChange(selectedOption?.value)}
            value={{ value, label: regionIdToLabelMap[value] }}
            disabled={disabled}
            labelAlignment="left"
        >
            <RenderIf isTrue={hasRegions}>
                <RegionOptions regions={regions} />
            </RenderIf>
            <RenderIf isTrue={!hasRegions}>
                <EmptyLabel>We couldn’t find any region.</EmptyLabel>
            </RenderIf>
        </Picklist>
    );
}

RegionPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.node,
};

RegionPicker.defaultProps = {
    onChange: () => {},
    disabled: false,
    className: undefined,
    label: undefined,
};
