import { useCollection } from '@rainbow-modules/firebase-hooks';

const useFunctionCoverage = ({ buildId, workspace, name }) => {
    const [data, isLoading] = useCollection({
        path: `workspaces/${workspace}/ci/${buildId}/checks`,
        flat: true,
        query: (ref) => ref.where('name', '==', 'Test Coverage'),
    });
    const functionCoverage = data?.[0]?.checksResults.find((item) => item.name === name);

    return [functionCoverage, isLoading];
};

export default useFunctionCoverage;
