import { useCallback } from 'react';
import { confirmModal } from '@rainbow-modules/app';
import useCallableMutation from './useCallableMutation';
import useMutationFlow from './useMutationFlow';

const useDeleteSecret = ({ onSuccess = () => {} }) => {
    const { mutateAsync: deleteSecret } = useCallableMutation('secrets-delete');

    const { mutate: deleteSecretFlow } = useMutationFlow({
        mutation: deleteSecret,
        submitSpinnerMessage: 'Deleting secret',
        successMessage: null,
        onSuccess,
    });

    const remove = useCallback(async ({
        secretName, projectId, children,
    }) => {
        const result = await confirmModal({
            variant: 'destructive',
            header: 'Delete Secret',
            okButtonLabel: 'Delete',
            children,
        });
        if (result) {
            await deleteSecretFlow({ secretName, projectId });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return remove;
};

export default useDeleteSecret;
