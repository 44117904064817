import { useState, useRef } from 'react';
import InternalTooltip from 'react-rainbow-components/components/InternalTooltip';
import {
    StyledInfoCircleFilled, TextTooltip,
} from '../styled';

const InformationIcon = () => {
    const triggerRef = useRef();
    const tooltipRef = useRef();
    const [isVisible, setVisible] = useState(false);
    const onMouseEnter = () => {
        setVisible(true);
    };

    const onMouseLeave = () => {
        setVisible(false);
    };
    return (
        <>
            <div
                ref={triggerRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <StyledInfoCircleFilled />
            </div>
            <InternalTooltip
                triggerElementRef={triggerRef}
                isVisible={isVisible}
                ref={tooltipRef}
                preferredPosition="bottom"
            >
                <TextTooltip>
                    Month to date installations
                </TextTooltip>
            </InternalTooltip>

        </>
    );
};

export default InformationIcon;
