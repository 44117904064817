/* eslint-disable react/prop-types */
import FunctionIcon from '../FunctionIcon';
import Label from '../LabelWithDescription';
import { Container, LabelContainer } from './styled';

const FunctionInfo = ({ data }) => {
    const { iconUrl, name, description } = data;
    return (
        <Container>
            <FunctionIcon
                url={iconUrl}
                functionType="marketplace"
                width="52px"
                height="52px"
            />
            <LabelContainer>
                <Label name={name} description={description} />
            </LabelContainer>
        </Container>
    );
};

export default FunctionInfo;
