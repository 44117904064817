import PropTypes from 'prop-types';

export default function PubSub(props) {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="193px" height="171px" viewBox="0 0 193 171">
            <g id="functions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="pubSub" transform="translate(0.619204, 0.087472)">
                    <path d="M114.240939,169.112948 C95.3110262,169.115171 76.3811137,169.129741 57.4512012,169.106774 C50.5594022,169.098378 45.3861694,166.011429 41.9742264,160.05905 C29.1554852,137.694721 16.3569943,115.319033 3.57381478,92.9344538 C0.211509862,87.0470245 0.254233237,81.1010667 3.63110856,75.2358635 C16.3295822,53.1799829 29.0594193,31.142377 41.7682651,9.09242326 C45.2342915,3.07929343 50.4238234,0.0185216944 57.3551353,0.0135825759 C82.5814361,-0.00469216258 107.80749,-0.00419825073 133.033791,0.013088664 C139.901141,0.0180277825 145.122283,2.96939804 148.565343,8.95462185 C160.661738,29.9811902 172.758627,51.0072646 184.841933,72.0412416 C186.271314,74.5295695 187.932834,76.9109655 188.67222,79.743303 C189.760555,83.9114251 189.607936,88.047196 187.607593,91.8700737 C185.187425,96.4950643 182.551838,101.007077 179.852597,105.479306 L178.837768,107.154719 L178.837768,107.154719 C175.950113,112.220773 173.076286,117.294483 170.172332,122.350658 C163.071608,134.715494 155.919517,147.050696 148.863246,159.440722 C145.171996,165.92208 139.767118,169.242649 132.251015,169.128309 C126.700433,169.04385 121.148123,169.086326 115.596554,169.072497 C115.144551,169.086771 114.692868,169.112948 114.240939,169.112948 Z" id="Combined-Shape" fill="#4D87EC" />
                    <g id="Group-26" transform="translate(48.313725, 32.760632)" fill="#FEFEFE">
                        <path d="M46.8957144,38.6293896 C51.9893092,38.7085486 56.2319455,40.5328793 59.3146206,44.7099268 C62.7492704,49.3639068 62.9548009,56.005907 59.8291537,60.8928405 C56.5870299,65.9624049 50.7097616,68.5243274 44.865005,67.4161022 C39.0417344,66.3118351 34.4143291,61.6259088 33.3374849,55.8532433 C32.5164936,51.4520065 33.7542001,47.5969663 36.2440314,44.0300081 C39.0830101,40.8266155 42.6403561,39.0398853 46.8957144,38.6293896" id="Fill-6" />
                        <path d="M26.5516133,60.4221275 C27.5925533,59.8957206 28.773435,58.7911707 29.6359847,59.0003765 C30.5508359,59.2220215 30.5358522,60.9869831 31.1677101,61.9727945 C31.826991,63.001578 32.3692297,64.1163054 33.2450668,64.9845094 C33.8907776,65.6242832 33.8373453,65.9409189 33.0109825,66.3974964 C29.8426461,68.1483224 26.7201088,69.9819826 23.577499,71.7797386 C22.9891785,72.1161641 22.577552,72.4144237 22.6640614,73.2868682 C23.4604569,81.3116054 15.1773223,87.6002175 7.54244289,84.8132575 C7.01518784,84.6210143 6.47662436,84.4590212 5.94314967,84.2826099 C3.85533273,82.7248751 1.91028458,81.0458575 0.880652983,78.5486758 C-1.00135073,73.984315 0.202147815,68.6408041 3.83101962,65.5160051 C7.62923501,62.244762 12.9348627,61.7932734 17.3592818,64.4699763 C18.2105231,64.9850748 18.8155236,65.0390725 19.6449961,64.5273665 C21.9239254,63.1208818 24.246392,61.7853575 26.5516133,60.4221275" id="Fill-8" />
                        <path d="M57.601593,5.83994888 C59.522328,9.98080938 59.5076271,14.0091511 56.8727654,17.8692801 C55.8191034,19.4131621 54.4612449,20.6488896 52.7601759,21.425778 C51.8908412,21.8227035 51.6635419,22.3638114 51.6773947,23.2769663 C51.7305443,26.8046277 51.6720232,30.3339855 51.7161261,33.8619296 C51.7285653,34.8423696 51.4650792,35.0012529 50.4950995,34.8466102 C48.3606353,34.5059441 46.1973347,34.4050165 44.068242,34.9947505 C43.1533909,34.8802528 43.4872719,34.1599065 43.4835967,33.6764715 C43.4575873,30.1960227 43.4411901,26.7147258 43.4895336,23.2345597 C43.5014075,22.3737063 43.2506433,21.9063858 42.4689488,21.4871262 C39.0303411,19.642723 36.941676,16.8368214 36.3332831,12.8980989 C35.4503782,7.1856508 39.4643017,1.30838249 45.2406424,0.240584977 C50.0792324,-0.653628293 54.0055156,0.951601851 56.923936,4.94403935 C57.1447328,5.24625686 57.3754246,5.5414066 57.601593,5.83994888" id="Fill-10" />
                        <path d="M19.406134,28.1671792 C20.3433193,30.4998234 20.6701326,32.8514093 19.7804427,35.2940279 C18.2317547,39.5468417 13.816665,41.9784346 9.3899842,40.9776395 C8.70752108,40.8232796 8.01912105,40.6937982 7.33354812,40.5527256 C7.19162745,40.0961482 6.75144722,39.9694939 6.41502174,39.7339961 C3.28739563,37.5452515 1.81334315,33.9763143 2.57779231,30.4277323 C3.34111062,26.8848044 6.13315939,24.0449776 9.68767834,23.471358 C13.5673143,22.845437 16.6839148,24.208667 18.9204374,27.4821717 C19.0781899,27.7134289 19.2441409,27.9390318 19.406134,28.1671792" id="Fill-12" />
                        <path d="M91.8159229,28.1687058 C93.2484169,31.6389771 93.1491855,34.9478206 90.720137,37.9767808 C87.6007095,41.8665943 81.9154016,42.3836718 77.9930763,39.1582279 C74.2587535,36.0874266 73.7925639,30.2237284 76.986627,26.5015621 C77.229758,26.2182862 77.4740199,25.9355757 77.7177163,25.6522998 C83.0626408,21.8509746 87.9993313,22.7324659 91.8159229,28.1687058" id="Fill-14" />
                        <path d="M83.8341577,85.4906035 C77.3569777,85.59662 71.7448919,79.5726249 72.493792,73.2370829 C72.5986775,72.3479584 72.1717847,72.0791007 71.5828988,71.7432407 C68.4371792,69.9488772 65.313511,68.1146516 62.1443265,66.3626947 C61.3125922,65.9030075 61.3140058,65.6217105 61.9187235,64.9468806 C63.2802572,63.4275944 64.2855757,61.6646118 64.9539033,59.7362436 C65.2513147,58.8785 65.6097916,58.7832266 66.3753716,59.2344325 C69.4159229,61.0254034 72.4997289,62.7431523 75.5354741,64.5414737 C76.3231055,65.0082288 76.9032274,65.0195372 77.6976439,64.5027424 C81.7531258,61.8661844 87.0282208,62.1313668 90.7684805,65.0811681 C94.5935534,68.097689 96.1360218,73.1081669 94.6240861,77.6058081 C93.0516504,82.2829703 88.7725445,85.410031 83.8341577,85.4906035" id="Fill-16" />
                        <path d="M47.5853866,86.0938509 C52.6181987,86.094982 56.5181899,90.0059989 56.5060617,95.0399418 C56.4938768,100.022149 52.5249042,103.9326 47.4935057,103.919909 C42.5848035,103.907156 38.6644572,99.9559945 38.6596467,95.0164768 C38.654845,89.9689638 42.5325021,86.0927203 47.5853866,86.0938509" id="Fill-18" />
                    </g>
                </g>
            </g>
        </svg>
    );
}

PubSub.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

PubSub.defaultProps = {
    style: {},
    className: undefined,
};
