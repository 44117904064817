import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { FieldsGenerator } from '@rainbow-modules/forms';
import { Email, Link as LinkIcon } from '@rainbow-modules/icons';
import JsonInput from '@rainbow-modules/forms/lib/components/JsonInput';
import { ColorInput } from 'react-rainbow-components';
import PropTypes from 'prop-types';
import Label from '../LabelWithDescription';
import formatDefaultValue from './helpers/formatDefaultValue';
import SecretLookup from '../SecretLookup';
import FirestoreCollectionInput from '../FirestoreCollectionInput';
import FirestoreDocumentInput from '../FirestoreDocumentInput';
import BooleanInput from '../BooleanInput';
import SelectInput from '../SelectInput';
import MultiSelectInput from '../MultiSelectInput';
import validations from './validations';

export default function Fields({
    env, projectId, keepDirtyOnReinitialize, version, initialValues,
}) {
    const form = useForm();
    useEffect(() => {
        if (keepDirtyOnReinitialize) {
            form.setConfig('keepDirtyOnReinitialize', false);
        }
        form.restart(initialValues);
        form.setConfig('keepDirtyOnReinitialize', keepDirtyOnReinitialize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, projectId, version]);

    const types = {
        text: {
            component: SecretLookup,
            props: { projectId },
        },
        firestoreDocumentPath: {
            component: FirestoreDocumentInput,
            props: { projectId },
        },
        firestoreCollectionPath: {
            component: FirestoreCollectionInput,
            props: { projectId },
        },
        boolean: {
            component: BooleanInput,
            props: { className: 'rainbow-m-horizontal_medium' },
        },
        multiselect: {
            component: MultiSelectInput,
            props: { className: 'rainbow-m-horizontal_medium' },
        },
        select: {
            component: SelectInput,
        },
        json: {
            component: JsonInput,
            props: {
                labelAlignment: 'left',
            },
        },
        email: {
            props: {
                icon: <Email />,
            },
        },
        url: {
            props: {
                icon: <LinkIcon />,
            },
        },
        color: {
            component: ColorInput,
            props: { className: 'rainbow-inline-flex' },
        },
    };
    if (Array.isArray(env)) {
        const schema = env.map((field) => {
            const {
                name,
                description,
                required,
                default: defaultValue,
                type,
                schema: keywordSchema,
                options,
                minimum,
                maximum,
            } = field;
            const fieldSchema = {
                label: <Label name={name} description={description} required={required} />,
                name: `env.${name}`,
                type: type || 'text',
                min: minimum,
                max: maximum,
                required: required
                    ? {
                        errorMessage: `${name} is required`,
                    }
                    : false,
                default: formatDefaultValue(defaultValue, type),
                props: { options },
            };
            if (type === 'json' && keywordSchema) {
                fieldSchema.hasValidSchema = keywordSchema;
            }
            if (type === 'firestoreCollectionPath') {
                fieldSchema.isValidCollectionPath = true;
            }
            if (type === 'firestoreDocumentPath') {
                fieldSchema.isValidDocumentPath = true;
            }
            if (type === 'email') {
                fieldSchema.isValidEmail = true;
            }
            if (type === 'url') {
                fieldSchema.isValidUrl = true;
            }
            return fieldSchema;
        });
        return (
            <FieldsGenerator
                schema={schema}
                types={types}
                validations={validations}
                labelAlignment="left"
            />
        );
    }
    return null;
}

Fields.propTypes = {
    env: PropTypes.array,
    projectId: PropTypes.string.isRequired,
    version: PropTypes.string,
    keepDirtyOnReinitialize: PropTypes.bool,
    initialValues: PropTypes.object,
};

Fields.defaultProps = {
    env: [],
    keepDirtyOnReinitialize: false,
    version: undefined,
    initialValues: undefined,
};
