import styled from 'styled-components';
import { Tag } from '@rainbow-modules/icons';
import { LIGHT_GRAY, LIGHT_BLACK } from '../../colors';

const StyledVersionIcon = styled(Tag)`
    width: 20px;
    height: 20px;
    color: ${({ isPublished }) => (isPublished ? LIGHT_BLACK : LIGHT_GRAY)};
`;

export default StyledVersionIcon;
