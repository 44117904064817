import { Input, RadioGroup } from 'react-rainbow-components';
import {
    Field, composeValidators, isRequired, isEmail,
} from '@rainbow-modules/forms';
import RoleOption from './roleOption';
import { FormContainer } from './styled';

const options = [
    { value: 'editor', label: <RoleOption title="Editor" description="Full access to the workspace resources, including user management." /> },
    { value: 'viewer', label: <RoleOption title="Viewer" description="Access to install functions and read-only resources on the workspace." /> },
];

const AddMemberForm = () => (
    <FormContainer>
        <Field
            id="email-input"
            component={Input}
            name="email"
            placeholder="Enter email address"
            label="Email Address"
            required
            labelAlignment="left"
            validate={composeValidators(
                isRequired('Looks like you forgot the user email'),
                isEmail('The email enterd is invalid'),
            )}
        />
        <Field
            id="role-radiogroup"
            component={RadioGroup}
            name="role"
            className="rainbow-m-top_x-large"
            label="Roles"
            required
            options={options}
            validate={isRequired('Looks like you forgot to select a role')}
        />
    </FormContainer>
);

export default AddMemberForm;
