import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { useOpenModal } from '@rainbow-modules/hooks';
import InfoCard from '../../components/InfoCard';
import useCheckSecretsApi from '../../data/hooks/useCheckSecretsApi';
import useSecretsApiStateChange from '../../data/hooks/useSecretsApiStateChange';
import { CREATE_SECRET_DRAWER } from '../../constants';
import EnableApiMessage from './enableApiMessage';
import Loader from './loader';
import SecretsList from './secretsList';
import {
    Container,
    Content,
    Title,
    Description,
    Header,
    LeftContent,
    CreateButton,
} from './styled';

const Secrets = () => {
    const { projectId } = useParams();
    const [openModal] = useOpenModal(CREATE_SECRET_DRAWER);

    const {
        isEnabled: isApiEnabled,
        isLoading: isCheckingApi,
        isError,
        error,
    } = useCheckSecretsApi({
        projectId,
    });

    const {
        isLoading: isEnablingApi,
        enableApi,
    } = useSecretsApiStateChange({
        projectId,
    });

    const isLoading = Boolean(isCheckingApi || isEnablingApi);
    return (
        <Container>
            <Header>
                <LeftContent>
                    <Title>Secrets</Title>
                    <Description>
                        Secret Manager lets you store, manage,
                        and secure access to your application secrets.
                    </Description>
                </LeftContent>
                <RenderIf isTrue={isApiEnabled && !isError}>
                    <div>
                        <CreateButton
                            id="create-secret-button"
                            label="Create Secret"
                            variant="outline-brand"
                            onClick={() => openModal()}
                        />
                    </div>
                </RenderIf>
            </Header>
            <Content>
                <RenderIf isTrue={isLoading}>
                    <Loader hideMessage={isCheckingApi} />
                </RenderIf>
                <RenderIf isTrue={!isApiEnabled && !isLoading && !isError}>
                    <EnableApiMessage onRequestEnableApi={() => enableApi()} />
                </RenderIf>
                <RenderIf isTrue={!isLoading && isApiEnabled && !isError}>
                    <SecretsList projectId={projectId} onCreateSecret={() => openModal()} />
                </RenderIf>
                <RenderIf isTrue={!isLoading && isError}>
                    <InfoCard
                        variant="error"
                        label="Check Secrets API failed"
                        description={error && error.toString()}
                    />
                </RenderIf>
            </Content>
        </Container>
    );
};

export default Secrets;
