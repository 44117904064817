import PropTypes from 'prop-types';
import { RenderIf } from 'react-rainbow-components';
import {
    MessageContainer, MessageTitle, MessageDescription, StyledInstallationError,
} from './styled';

export default function Message(props) {
    const {
        icon,
        title,
        description,
        action,
        style,
        className,
    } = props;

    return (
        <MessageContainer style={style} className={className}>
            <RenderIf isTrue={icon}>
                {icon}
            </RenderIf>
            <MessageTitle data-cy="message-title">
                {title}
            </MessageTitle>
            <MessageDescription>
                {description}
            </MessageDescription>
            {action}
        </MessageContainer>
    );
}

Message.propTypes = {
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    action: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    icon: PropTypes.node,
    style: PropTypes.object,
    className: PropTypes.string,
};

Message.defaultProps = {
    title: undefined,
    description: undefined,
    icon: <StyledInstallationError />,
    action: undefined,
    style: undefined,
    className: undefined,
};
