import PropTypes from 'prop-types';

export default function Folders(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="16px"
            height="12px"
            viewBox="0 0 16 12"
        >
            <g id="developers" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="developer/workspace-picker-opened" transform="translate(-82.000000, -114.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M87.6354261,115.585975 C87.6926656,115.584281 87.7475619,115.608976 87.7841966,115.653013 L87.7841966,115.653013 L88.8731966,116.947316 L94.3479505,116.947316 C95.0246331,116.948134 95.5730011,117.496502 95.5738194,118.173185 L95.5738194,118.173185 L95.5738194,124.774131 C95.5730011,125.450814 95.0246331,125.999182 94.3479505,126 L94.3479505,126 L83.2258688,126 C82.5491863,125.999182 82.0008182,125.450814 82,124.774131 L82,124.774131 L82,116.813422 C82,116.139046 82.5440537,115.590975 83.2184303,115.585975 L83.2184303,115.585975 Z M89.2421474,114.000082 C89.2993868,113.998387 89.3542831,114.023083 89.3909179,114.067119 L89.3909179,114.067119 L90.4799178,115.361422 L95.9561595,115.361422 C96.629346,115.366332 97.1729905,115.91258 97.1745898,116.585803 L97.1745898,116.585803 L97.1745898,123.18675 C97.1741063,123.804743 96.7136989,124.3257 96.1004669,124.402205 L96.1004669,124.402205 L96.1004669,118.171697 C96.0988304,117.204503 95.3151447,116.420817 94.3479505,116.419181 L94.3479505,116.419181 L89.1186679,116.419181 L88.1888523,115.313816 C88.0530621,115.152102 87.8525566,115.058898 87.6413769,115.059418 L87.6413769,115.059418 L83.6141598,115.059418 C83.6973225,114.453216 84.2147463,114.001177 84.8266393,114.000082 L84.8266393,114.000082 Z" id="folders" />
                </g>
            </g>
        </svg>
    );
}

Folders.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Folders.defaultProps = {
    style: {},
    className: undefined,
};
