import PropTypes from 'prop-types';
import { Header, HeaderLabel } from './styled';

export default function SectionHeader(props) {
    const { icon, label, size } = props;

    return (
        <Header size={size}>
            {icon}
            <HeaderLabel size={size}>
                {label}
            </HeaderLabel>
        </Header>
    );
}

SectionHeader.propTypes = {
    icon: PropTypes.node,
    label: PropTypes.node,
    size: PropTypes.arrayOf(
        PropTypes.oneOf(['medium', 'small']),
    ),
};

SectionHeader.defaultProps = {
    icon: undefined,
    label: undefined,
    size: 'medium',
};
