export default function getActiveMenuName(path) {
    const matches = /^\/app\/([^/]+)/.exec(path);
    if (matches) {
        const [, sectionName] = matches;
        if (['marketplace', 'developer'].includes(sectionName)) {
            return sectionName;
        }
        return 'projects';
    }
    return null;
}
