import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
    Column, Table, Modal, Button,
} from 'react-rainbow-components';
import {
    RecordSection,
} from '@rainbow-modules/record';
import {
    useCollection, useBatch, useCurrentUser, useUpdateDoc,
} from '@rainbow-modules/firebase-hooks';
import { Plus, CloseCircleFilled } from '@rainbow-modules/icons';
import AssignPermission from './assignPermission';
import AlignLeftContent from '../../../components/AlignLeftContent';
import ButtonIconTooltip from '../../../components/ButtonIconTooltip';
import useMutationFlow from '../../../data/hooks/useMutationFlow';
import {
    StyledButtonIconTooltip,
} from './styled';

// eslint-disable-next-line react/prop-types
const CancelButton = ({ value, onRemove }) => (
    <StyledButtonIconTooltip
        size="small"
        icon={<CloseCircleFilled />}
        text="Remove Permission"
        onClick={() => onRemove(value)}
    />
);

const Permissions = ({ roleName }) => {
    const { projectId, roleId } = useParams();
    const rolePermissionsCollection = `projects/${projectId}/roles/${roleId}/permissions`;
    const [currentPerms, isLoadingPerms] = useCollection({
        path: rolePermissionsCollection,
        query: (ref) => ref.where('removed', '==', false),
    });
    const [selectedPerms, setSelectedPerms] = useState([]);
    const [isOpenAssignPermissionModal, setOpenAssignPermissionModal] = useState(false);
    const currentPermsIds = currentPerms.map((perm) => perm.id);
    const handlePermSelection = (perms) => setSelectedPerms(perms);
    const { batchSet } = useBatch({ collection: rolePermissionsCollection });
    const { mutate: addPerms } = useMutationFlow({
        mutation: batchSet,
        submitSpinnerMessage: 'Adding permissions',
        onSuccess: () => setOpenAssignPermissionModal(false),
        successMessage: 'Permissions were added sucessfully.',
    });
    const { mutate: removePerm } = useMutationFlow({
        mutation: useUpdateDoc().mutate,
        submitSpinnerMessage: 'Removing permissions',
        successMessage: 'The permission was removed sucessfully.',
    });
    const { uid } = useCurrentUser();

    const assignPermission = () => {
        const perms = selectedPerms.map(
            (perm) => ({
                id: perm.id,
                data: {
                    name: perm.name,
                    description: perm.description,
                    userId: uid,
                    removed: false,
                },
            }),
        );
        addPerms(perms);
    };

    const removePermission = (id) => removePerm({
        path: `${rolePermissionsCollection}/${id}`,
        data: {
            userId: uid,
            removed: true,
        },
    });

    return (
        <RecordSection
            label="Permissions"
            actions={(
                <ButtonIconTooltip
                    id="add-permission-button"
                    icon={<Plus />}
                    text="Add Permission"
                    onClick={() => setOpenAssignPermissionModal(true)}
                    shaded
                    variant="border-filled"
                />
            )}
        >
            <Table data={currentPerms} keyField="id" variant="listview" isLoading={isLoadingPerms}>
                <Column header="Permission" field="data.name" width={250} component={AlignLeftContent} />
                <Column header="Description" field="data.description" component={AlignLeftContent} />
                <Column component={CancelButton} field="id" width={50} onRemove={removePermission} />
            </Table>
            <Modal
                id="add-permission-modal"
                title={`Assign permissions to “${roleName}”`}
                size="medium"
                isOpen={isOpenAssignPermissionModal}
                onRequestClose={() => setOpenAssignPermissionModal(false)}
                footer={(
                    <div className="rainbow-flex rainbow-justify_end">
                        <Button
                            className="rainbow-m-right_large"
                            label="Cancel"
                            variant="base"
                            onClick={() => setOpenAssignPermissionModal(false)}
                        />
                        <Button label="Assign" variant="brand" disabled={selectedPerms.length === 0} onClick={assignPermission} />
                    </div>
                )}
            >
                <AssignPermission
                    roleName={roleName}
                    currentPermsIds={currentPermsIds}
                    onPermSelection={handlePermSelection}
                />
            </Modal>
        </RecordSection>
    );
};

Permissions.propTypes = {
    roleName: PropTypes.string,
};

Permissions.defaultProps = {
    roleName: undefined,
};

export default Permissions;
