import PropTypes from 'prop-types';

export default function On(props) {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="10px"
            height="10px"
            viewBox="0 0 10 10"
        >
            <g id="billing-&amp;-subscriptions" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="projects-list-limit-exceded-actions-with-setup" transform="translate(-194.000000, -288.000000)" fill="currentColor" fillRule="nonzero">
                    <path d="M196.197134,290.408274 C196.381885,290.209692 196.649587,290.193375 196.835133,290.369194 C197.018121,290.542582 197.018649,290.804682 196.834441,291.000524 C196.511044,291.344441 196.268818,291.696881 196.102811,292.080624 C195.886264,292.581206 195.787557,293.100916 195.806955,293.642892 C195.829981,294.28587 196.011368,294.87728 196.352042,295.417437 C196.708532,295.982678 197.187757,296.414439 197.78689,296.709977 C198.425662,297.0251 199.089523,297.142268 199.768381,297.052402 C201.128127,296.872385 202.093506,296.142759 202.662735,294.87694 C202.831612,294.501369 202.930937,294.008432 202.934379,293.588457 C202.922025,292.695065 202.673836,291.953716 202.170427,291.315473 L201.973273,291.085212 L201.932355,291.040486 C201.909566,291.015347 201.894192,290.997784 201.876861,290.977124 C201.71416,290.783069 201.725031,290.526871 201.904402,290.362845 C202.08281,290.199654 202.341922,290.211662 202.519712,290.390735 C202.78689,290.659797 203.012276,290.951038 203.194363,291.26596 C204.134381,292.8913 203.986639,294.86371 202.81085,296.311111 C202.062285,297.232528 201.0876,297.772134 199.910696,297.914489 C197.724142,298.17895 195.688525,296.84326 195.111134,294.754641 C194.660719,293.125264 195.029649,291.662899 196.197134,290.408274 Z M199.224007,287.971977 C199.502989,287.876884 199.791189,288.072946 199.797113,288.366781 L199.800839,288.70892 L199.799097,292.013221 C199.797989,292.336224 199.514275,292.548634 199.221322,292.442925 C199.052251,292.381972 198.953641,292.254819 198.93579,292.074885 L198.931968,291.972327 L198.932007,288.443156 C198.93205,288.200575 199.030948,288.037832 199.224007,287.971977 Z" id="on" />
                </g>
            </g>
        </svg>
    );
}

On.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

On.defaultProps = {
    style: {},
    className: undefined,
};
