import PropTypes from 'prop-types';
import { More, TrashFilled } from '@rainbow-modules/icons';
import { useParams } from 'react-router-dom';
import { ButtonMenu, MenuItem } from 'react-rainbow-components';
import { ActionsColumnContainer } from './styled';

const ActionsColumn = ({ onRemove, row, currentUserIsViewer }) => {
    const { uid, role } = row;
    const { workspace } = useParams();
    const isOwner = role === 'owner';

    return (
        <ActionsColumnContainer>
            <ButtonMenu icon={<More />} buttonSize="small" disabled={isOwner || currentUserIsViewer}>
                {/* <MenuItem label="Edit" /> */}
                <MenuItem
                    label="Remove"
                    onClick={() => onRemove({ uid, role, workspace })}
                    icon={<TrashFilled className="rainbow-m-bottom_xx-small" />}
                />
            </ButtonMenu>
        </ActionsColumnContainer>
    );
};

ActionsColumn.propTypes = {
    onRemove: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
    currentUserIsViewer: PropTypes.bool.isRequired,
};

export default ActionsColumn;
