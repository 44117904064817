import { useDoc } from '@rainbow-modules/firebase-hooks';

const useFunction = (functionId, workspace) => {
    const [data, isLoading] = useDoc({
        path: `workspaces/${workspace}/marketplace/${functionId}`,
        disabled: !functionId,
        flat: true,
    });
    return [data, isLoading];
};

export default useFunction;
