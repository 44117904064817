import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UnknownCard } from '@rainbow-modules/icons';
import {
    Input, LoadingShape, StripeCardInput,
} from 'react-rainbow-components';

export const StyledContainer = styled.div`
    border: 1px solid ${(props) => props.theme.rainbow.palette.border.divider};
    border-radius: 10px;
`;

export const InformationContainer = styled.div`
    display: flex;
    margin: 10px 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px dashed ${(props) => props.theme.rainbow.palette.border.divider};
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    min-width: 0;
`;

export const Title = styled.h2`
    font-size: 20px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Description = styled.h3`
    font-size: 15px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const CardRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
`;

export const AmountText = styled.p`
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const Header = styled.header`
    margin: 10px 15px;
`;

export const HighLightedName = styled.span`
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const StyledLink = styled(Link)`
    color: ${(props) => props.theme.rainbow.palette.text.main};
    text-decoration: underline;
    font-family: 'Lato Medium', Arial, Helvetica, sans-serif;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
    }
`;

export const PaymentHelpText = styled.p`
    font-size: 13px;
    color: ${(props) => props.theme.rainbow.palette.text.label};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin-top: 8px;
`;

export const Icon = styled(UnknownCard)`
    width: 60px;
    height: 60px;
    margin-left: 5px;
`;

export const CardType = styled.p`
    font-size: 14px;
    color: ${(props) => props.theme.rainbow.palette.text.header};
`;

export const BillingInfoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

export const CardNumber = styled.span`
    font-size: 18px;
    color: ${(props) => props.theme.rainbow.palette.text.main};
`;

export const StyledInput = styled(Input)`
    margin: 15px;
`;

export const StyledStripeCardInput = styled(StripeCardInput)`
    margin: 15px;
`;

export const StyledLoadingShape = styled(LoadingShape)`
    height: 16px;
    margin-bottom: 4px;
    width: ${(props) => `${props.width}`};
`;
