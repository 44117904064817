import getNormalizedPoliciesFormValue from './getNormalizedPoliciesFormValue';
import getNormalizedEnvFormValue from './getNormalizedEnvFormValue';

const getNormalizedFormInitialData = (initialData) => {
    if (initialData) {
        const returnData = { ...initialData };
        if (initialData.policies) {
            returnData.policies = getNormalizedPoliciesFormValue(initialData.policies);
        }

        if (initialData.env) {
            returnData.env = getNormalizedEnvFormValue(initialData.env);
        }
        return returnData;
    }
    return initialData;
};

export default getNormalizedFormInitialData;
