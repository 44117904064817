import styled from 'styled-components';
import FxIcon from '../Icons/fxIcon';
import Fx from '../Icons/fx';
import { WHITE } from '../../colors';

export const Icon = styled(FxIcon)`
    height: ${(props) => props.height || '64px'};
    width: ${(props) => props.width || '64px'};
    flex-shrink: 0;
`;

export const StyledFunctionIcon = styled(Fx)`
    width: 36px;
    height: 36px;
    color: ${WHITE};
    position: absolute;
    top: 8px;
    left: 6px;
`;

export const StyledIconContainer = styled.div`
    position: relative;
    border-radius: 10px;
    overflow: hidden;
`;
