import PropTypes from 'prop-types';
import { Step } from '@rainbow-modules/content';
import Step11 from '../images/11-deploy.png';
import { StepDescription, StyledImage } from '../styled';

const Deploy = ({ stepNumber }) => (
    <Step
        number={stepNumber}
        header='Click "Deploy".'
        className="rainbow-m-vertical_xx-large"
    >
        <StepDescription>
            Your new function is listed on the Functions page. When the deployment is complete,
            a checkmark appears next to the function.
        </StepDescription>
        <StyledImage src={Step11} />
    </Step>
);

Deploy.propTypes = {
    stepNumber: PropTypes.number,
};

Deploy.defaultProps = {
    stepNumber: undefined,
};

export default Deploy;
