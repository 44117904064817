import PropTypes from 'prop-types';
import { SecondaryInformationCode } from './styled';

const Dependencies = (props) => {
    const { dependencies } = props;

    return (Object.keys(dependencies).sort().map(
        (item, index) => (
            <SecondaryInformationCode
                className="rainbow-m-bottom_x-small"
                // eslint-disable-next-line react/no-array-index-key
                key={`dependency-${index}`}
            >
                {`${item}: "${dependencies[item]}"`}
            </SecondaryInformationCode>
        ),
    ));
};

Dependencies.propTypes = {
    dependencies: PropTypes.object,
};

Dependencies.defaultProps = {
    dependencies: undefined,
};

export default Dependencies;
